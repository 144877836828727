const DataWard = [
  {
    ID: 1,
    Code: "00001",
    Name: "Phường Phúc Xá",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 2,
    Code: "00004",
    Name: "Phường Trúc Bạch",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 3,
    Code: "00006",
    Name: "Phường Vĩnh Phúc",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 4,
    Code: "00007",
    Name: "Phường Cống Vị",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 5,
    Code: "00008",
    Name: "Phường Liễu Giai",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 6,
    Code: "00010",
    Name: "Phường Nguyễn Trung Trực",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 7,
    Code: "00013",
    Name: "Phường Quán Thánh",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 8,
    Code: "00016",
    Name: "Phường Ngọc Hà",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 9,
    Code: "00019",
    Name: "Phường Điện Biên",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 10,
    Code: "00022",
    Name: "Phường Đội Cấn",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 11,
    Code: "00025",
    Name: "Phường Ngọc Khánh",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 12,
    Code: "00028",
    Name: "Phường Kim Mã",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 13,
    Code: "00031",
    Name: "Phường Giảng Võ",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 14,
    Code: "00034",
    Name: "Phường Thành Công",
    LevelUp: "Phường",
    DistrictCode: "1A01",
    Active: -1,
  },
  {
    ID: 15,
    Code: "00037",
    Name: "Phường Phúc Tân",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 16,
    Code: "00040",
    Name: "Phường Đồng Xuân",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 17,
    Code: "00043",
    Name: "Phường Hàng Mã",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 18,
    Code: "00046",
    Name: "Phường Hàng Buồm",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 19,
    Code: "00049",
    Name: "Phường Hàng Đào",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 20,
    Code: "00052",
    Name: "Phường Hàng Bồ",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 21,
    Code: "00055",
    Name: "Phường Cửa Đông",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 22,
    Code: "00058",
    Name: "Phường Lý Thái Tổ",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 23,
    Code: "00061",
    Name: "Phường Hàng Bạc",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 24,
    Code: "00064",
    Name: "Phường Hàng Gai",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 25,
    Code: "00067",
    Name: "Phường Chương Dương",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 26,
    Code: "00070",
    Name: "Phường Hàng Trống",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 27,
    Code: "00073",
    Name: "Phường Cửa Nam",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 28,
    Code: "00076",
    Name: "Phường Hàng Bông",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 29,
    Code: "00079",
    Name: "Phường Tràng Tiền",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 30,
    Code: "00082",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 31,
    Code: "00085",
    Name: "Phường Phan Chu Trinh",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 32,
    Code: "00088",
    Name: "Phường Hàng Bài",
    LevelUp: "Phường",
    DistrictCode: "1A02",
    Active: -1,
  },
  {
    ID: 33,
    Code: "00091",
    Name: "Phường Phú Thượng",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 34,
    Code: "00094",
    Name: "Phường Nhật Tân",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 35,
    Code: "00097",
    Name: "Phường Tứ Liên",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 36,
    Code: "00100",
    Name: "Phường Quảng An",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 37,
    Code: "00103",
    Name: "Phường Xuân La",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 38,
    Code: "00106",
    Name: "Phường Yên Phụ",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 39,
    Code: "00109",
    Name: "Phường Bưởi",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 40,
    Code: "00112",
    Name: "Phường Thụy Khuê",
    LevelUp: "Phường",
    DistrictCode: "1A05",
    Active: -1,
  },
  {
    ID: 41,
    Code: "00115",
    Name: "Phường Thượng Thanh",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 42,
    Code: "00118",
    Name: "Phường Ngọc Thụy",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 43,
    Code: "00121",
    Name: "Phường Giang Biên",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 44,
    Code: "00124",
    Name: "Phường Đức Giang",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 45,
    Code: "00127",
    Name: "Phường Việt Hưng",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 46,
    Code: "00130",
    Name: "Phường Gia Thụy",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 47,
    Code: "00133",
    Name: "Phường Ngọc Lâm",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 48,
    Code: "00136",
    Name: "Phường Phúc Lợi",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 49,
    Code: "00139",
    Name: "Phường Bồ Đề",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 50,
    Code: "00142",
    Name: "Phường Sài Đồng",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 51,
    Code: "00145",
    Name: "Phường Long Biên",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 52,
    Code: "00148",
    Name: "Phường Thạch Bàn",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 53,
    Code: "00151",
    Name: "Phường Phúc Đồng",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 54,
    Code: "00154",
    Name: "Phường Cự Khối",
    LevelUp: "Phường",
    DistrictCode: "1A09",
    Active: -1,
  },
  {
    ID: 55,
    Code: "00157",
    Name: "Phường Nghĩa Đô",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 56,
    Code: "00160",
    Name: "Phường Nghĩa Tân",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 57,
    Code: "00163",
    Name: "Phường Mai Dịch",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 58,
    Code: "00166",
    Name: "Phường Dịch Vọng",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 59,
    Code: "00167",
    Name: "Phường Dịch Vọng Hậu",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 60,
    Code: "00169",
    Name: "Phường Quan Hoa",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 61,
    Code: "00172",
    Name: "Phường Yên Hoà",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 62,
    Code: "00175",
    Name: "Phường Trung Hoà",
    LevelUp: "Phường",
    DistrictCode: "1A06",
    Active: -1,
  },
  {
    ID: 63,
    Code: "00178",
    Name: "Phường Cát Linh",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 64,
    Code: "00181",
    Name: "Phường Văn Miếu",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 65,
    Code: "00184",
    Name: "Phường Quốc Tử Giám",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 66,
    Code: "00187",
    Name: "Phường Láng Thượng",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 67,
    Code: "00190",
    Name: "Phường Ô Chợ Dừa",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 68,
    Code: "00193",
    Name: "Phường Văn Chương",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 69,
    Code: "00196",
    Name: "Phường Hàng Bột",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 70,
    Code: "00199",
    Name: "Phường Láng Hạ",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 71,
    Code: "00202",
    Name: "Phường Khâm Thiên",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 72,
    Code: "00205",
    Name: "Phường Thổ Quan",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 73,
    Code: "00208",
    Name: "Phường Nam Đồng",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 74,
    Code: "00211",
    Name: "Phường Trung Phụng",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 75,
    Code: "00214",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 76,
    Code: "00217",
    Name: "Phường Trung Liệt",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 77,
    Code: "00220",
    Name: "Phường Phương Liên",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 78,
    Code: "00223",
    Name: "Phường Thịnh Quang",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 79,
    Code: "00226",
    Name: "Phường Trung Tự",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 80,
    Code: "00229",
    Name: "Phường Kim Liên",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 81,
    Code: "00232",
    Name: "Phường Phương Mai",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 82,
    Code: "00235",
    Name: "Phường Ngã Tư Sở",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 83,
    Code: "00238",
    Name: "Phường Khương Thượng",
    LevelUp: "Phường",
    DistrictCode: "1A04",
    Active: -1,
  },
  {
    ID: 84,
    Code: "00241",
    Name: "Phường Nguyễn Du",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 85,
    Code: "00244",
    Name: "Phường Bạch Đằng",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 86,
    Code: "00247",
    Name: "Phường Phạm Đình Hổ",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 87,
    Code: "00256",
    Name: "Phường Lê Đại Hành",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 88,
    Code: "00259",
    Name: "Phường Đồng Nhân",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 89,
    Code: "00262",
    Name: "Phường Phố Huế",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 90,
    Code: "00265",
    Name: "Phường Đống Mác",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 91,
    Code: "00268",
    Name: "Phường Thanh Lương",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 92,
    Code: "00271",
    Name: "Phường Thanh Nhàn",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 93,
    Code: "00274",
    Name: "Phường Cầu Dền",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 94,
    Code: "00277",
    Name: "Phường Bách Khoa",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 95,
    Code: "00280",
    Name: "Phường Đồng Tâm",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 96,
    Code: "00283",
    Name: "Phường Vĩnh Tuy",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 97,
    Code: "00286",
    Name: "Phường Bạch Mai",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 98,
    Code: "00289",
    Name: "Phường Quỳnh Mai",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 99,
    Code: "00292",
    Name: "Phường Quỳnh Lôi",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 100,
    Code: "00295",
    Name: "Phường Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 101,
    Code: "00298",
    Name: "Phường Trương Định",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 102,
    Code: "00301",
    Name: "Phường Thanh Trì",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 103,
    Code: "00304",
    Name: "Phường Vĩnh Hưng",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 104,
    Code: "00307",
    Name: "Phường Định Công",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 105,
    Code: "00310",
    Name: "Phường Mai Động",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 106,
    Code: "00313",
    Name: "Phường Tương Mai",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 107,
    Code: "00316",
    Name: "Phường Đại Kim",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 108,
    Code: "00319",
    Name: "Phường Tân Mai",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 109,
    Code: "00322",
    Name: "Phường Hoàng Văn Thụ",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 110,
    Code: "00325",
    Name: "Phường Giáp Bát",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 111,
    Code: "00328",
    Name: "Phường Lĩnh Nam",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 112,
    Code: "00331",
    Name: "Phường Thịnh Liệt",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 113,
    Code: "00334",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 114,
    Code: "00337",
    Name: "Phường Hoàng Liệt",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 115,
    Code: "00340",
    Name: "Phường Yên Sở",
    LevelUp: "Phường",
    DistrictCode: "1A08",
    Active: -1,
  },
  {
    ID: 116,
    Code: "00343",
    Name: "Phường Nhân Chính",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 117,
    Code: "00346",
    Name: "Phường Thượng Đình",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 118,
    Code: "00349",
    Name: "Phường Khương Trung",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 119,
    Code: "00352",
    Name: "Phường Khương Mai",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 120,
    Code: "00355",
    Name: "Phường Thanh Xuân Trung",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 121,
    Code: "00358",
    Name: "Phường Phương Liệt",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 122,
    Code: "00361",
    Name: "Phường Hạ Đình",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 123,
    Code: "00364",
    Name: "Phường Khương Đình",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 124,
    Code: "00367",
    Name: "Phường Thanh Xuân Bắc",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 125,
    Code: "00370",
    Name: "Phường Thanh Xuân Nam",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 126,
    Code: "00373",
    Name: "Phường Kim Giang",
    LevelUp: "Phường",
    DistrictCode: "1A07",
    Active: -1,
  },
  {
    ID: 127,
    Code: "00376",
    Name: "Thị trấn Sóc Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 128,
    Code: "00379",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 129,
    Code: "00382",
    Name: "Xã Minh Trí",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 130,
    Code: "00385",
    Name: "Xã Hồng Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 131,
    Code: "00388",
    Name: "Xã Nam Sơn",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 132,
    Code: "00391",
    Name: "Xã Trung Giã",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 133,
    Code: "00394",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 134,
    Code: "00397",
    Name: "Xã Minh Phú",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 135,
    Code: "00400",
    Name: "Xã Phù Linh",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 136,
    Code: "00403",
    Name: "Xã Bắc Phú",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 137,
    Code: "00406",
    Name: "Xã Tân Minh",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 138,
    Code: "00409",
    Name: "Xã Quang Tiến",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 139,
    Code: "00412",
    Name: "Xã Hiền Ninh",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 140,
    Code: "00415",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 141,
    Code: "00418",
    Name: "Xã Tiên Dược",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 142,
    Code: "00421",
    Name: "Xã Việt Long",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 143,
    Code: "00424",
    Name: "Xã Xuân Giang",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 144,
    Code: "00427",
    Name: "Xã Mai Đình",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 145,
    Code: "00430",
    Name: "Xã Đức Hoà",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 146,
    Code: "00433",
    Name: "Xã Thanh Xuân",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 147,
    Code: "00436",
    Name: "Xã Đông Xuân",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 148,
    Code: "00439",
    Name: "Xã Kim Lũ",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 149,
    Code: "00442",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 150,
    Code: "00445",
    Name: "Xã Phú Minh",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 151,
    Code: "00448",
    Name: "Xã Phù Lỗ",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 152,
    Code: "00451",
    Name: "Xã Xuân Thu",
    LevelUp: "Xã",
    DistrictCode: "1A14",
    Active: -1,
  },
  {
    ID: 153,
    Code: "00454",
    Name: "Thị trấn Đông Anh",
    LevelUp: "Thị trấn",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 154,
    Code: "00457",
    Name: "Xã Xuân Nộn",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 155,
    Code: "00460",
    Name: "Xã Thuỵ Lâm",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 156,
    Code: "00463",
    Name: "Xã Bắc Hồng",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 157,
    Code: "00466",
    Name: "Xã Nguyên Khê",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 158,
    Code: "00469",
    Name: "Xã Nam Hồng",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 159,
    Code: "00472",
    Name: "Xã Tiên Dương",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 160,
    Code: "00475",
    Name: "Xã Vân Hà",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 161,
    Code: "00478",
    Name: "Xã Uy Nỗ",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 162,
    Code: "00481",
    Name: "Xã Vân Nội",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 163,
    Code: "00484",
    Name: "Xã Liên Hà",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 164,
    Code: "00487",
    Name: "Xã Việt Hùng",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 165,
    Code: "00490",
    Name: "Xã Kim Nỗ",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 166,
    Code: "00493",
    Name: "Xã Kim Chung",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 167,
    Code: "00496",
    Name: "Xã Dục Tú",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 168,
    Code: "00499",
    Name: "Xã Đại Mạch",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 169,
    Code: "00502",
    Name: "Xã Vĩnh Ngọc",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 170,
    Code: "00505",
    Name: "Xã Cổ Loa",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 171,
    Code: "00508",
    Name: "Xã Hải Bối",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 172,
    Code: "00511",
    Name: "Xã Xuân Canh",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 173,
    Code: "00514",
    Name: "Xã Võng La",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 174,
    Code: "00517",
    Name: "Xã Tàm Xá",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 175,
    Code: "00520",
    Name: "Xã Mai Lâm",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 176,
    Code: "00523",
    Name: "Xã Đông Hội",
    LevelUp: "Xã",
    DistrictCode: "1A13",
    Active: -1,
  },
  {
    ID: 177,
    Code: "00526",
    Name: "Thị trấn Yên Viên",
    LevelUp: "Thị trấn",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 178,
    Code: "00529",
    Name: "Xã Yên Thường",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 179,
    Code: "00532",
    Name: "Xã Yên Viên",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 180,
    Code: "00535",
    Name: "Xã Ninh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 181,
    Code: "00538",
    Name: "Xã Đình Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 182,
    Code: "00541",
    Name: "Xã Dương Hà",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 183,
    Code: "00544",
    Name: "Xã Phù Đổng",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 184,
    Code: "00547",
    Name: "Xã Trung Mầu",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 185,
    Code: "00550",
    Name: "Xã Lệ Chi",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 186,
    Code: "00553",
    Name: "Xã Cổ Bi",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 187,
    Code: "00556",
    Name: "Xã Đặng Xá",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 188,
    Code: "00559",
    Name: "Xã Phú Thị",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 189,
    Code: "00562",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 190,
    Code: "00565",
    Name: "Thị trấn Trâu Quỳ",
    LevelUp: "Thị trấn",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 191,
    Code: "00568",
    Name: "Xã Dương Quang",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 192,
    Code: "00571",
    Name: "Xã Dương Xá",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 193,
    Code: "00574",
    Name: "Xã Đông Dư",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 194,
    Code: "00577",
    Name: "Xã Đa Tốn",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 195,
    Code: "00580",
    Name: "Xã Kiêu Kỵ",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 196,
    Code: "00583",
    Name: "Xã Bát Tràng",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 197,
    Code: "00586",
    Name: "Xã Kim Lan",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 198,
    Code: "00589",
    Name: "Xã Văn Đức",
    LevelUp: "Xã",
    DistrictCode: "1A12",
    Active: -1,
  },
  {
    ID: 199,
    Code: "00592",
    Name: "Phường Cầu Diễn",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 200,
    Code: "00622",
    Name: "Phường Xuân Phương",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 201,
    Code: "00623",
    Name: "Phường Phương Canh",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 202,
    Code: "00625",
    Name: "Phường Mỹ Đình 1",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 203,
    Code: "00626",
    Name: "Phường Mỹ Đình 2",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 204,
    Code: "00628",
    Name: "Phường Tây Mỗ",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 205,
    Code: "00631",
    Name: "Phường Mễ Trì",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 206,
    Code: "00632",
    Name: "Phường Phú Đô",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 207,
    Code: "00634",
    Name: "Phường Đại Mỗ",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 208,
    Code: "00637",
    Name: "Phường Trung Văn",
    LevelUp: "Phường",
    DistrictCode: "1A10",
    Active: -1,
  },
  {
    ID: 209,
    Code: "00640",
    Name: "Thị trấn Văn Điển",
    LevelUp: "Thị trấn",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 210,
    Code: "00643",
    Name: "Xã Tân Triều",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 211,
    Code: "00646",
    Name: "Xã Thanh Liệt",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 212,
    Code: "00649",
    Name: "Xã Tả Thanh Oai",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 213,
    Code: "00652",
    Name: "Xã Hữu Hoà",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 214,
    Code: "00655",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 215,
    Code: "00658",
    Name: "Xã Tứ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 216,
    Code: "00661",
    Name: "Xã Yên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 217,
    Code: "00664",
    Name: "Xã Vĩnh Quỳnh",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 218,
    Code: "00667",
    Name: "Xã Ngũ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 219,
    Code: "00670",
    Name: "Xã Duyên Hà",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 220,
    Code: "00673",
    Name: "Xã Ngọc Hồi",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 221,
    Code: "00676",
    Name: "Xã Vạn Phúc",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 222,
    Code: "00679",
    Name: "Xã Đại áng",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 223,
    Code: "00682",
    Name: "Xã Liên Ninh",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 224,
    Code: "00685",
    Name: "Xã Đông Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1A11",
    Active: -1,
  },
  {
    ID: 225,
    Code: "00595",
    Name: "Phường Thượng Cát",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 226,
    Code: "00598",
    Name: "Phường Liên Mạc",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 227,
    Code: "00601",
    Name: "Phường Đông Ngạc",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 228,
    Code: "00602",
    Name: "Phường Đức Thắng",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 229,
    Code: "00604",
    Name: "Phường Thụy Phương",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 230,
    Code: "00607",
    Name: "Phường Tây Tựu",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 231,
    Code: "00610",
    Name: "Phường Xuân Đỉnh",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 232,
    Code: "00611",
    Name: "Phường Xuân Tảo",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 233,
    Code: "00613",
    Name: "Phường Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 234,
    Code: "00616",
    Name: "Phường Cổ Nhuế 1",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 235,
    Code: "00617",
    Name: "Phường Cổ Nhuế 2",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 236,
    Code: "00619",
    Name: "Phường Phú Diễn",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 237,
    Code: "00620",
    Name: "Phường Phúc Diễn",
    LevelUp: "Phường",
    DistrictCode: "1B30",
    Active: -1,
  },
  {
    ID: 238,
    Code: "08973",
    Name: "Thị trấn Chi Đông",
    LevelUp: "Thị trấn",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 239,
    Code: "08974",
    Name: "Xã Đại Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 240,
    Code: "08977",
    Name: "Xã Kim Hoa",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 241,
    Code: "08980",
    Name: "Xã Thạch Đà",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 242,
    Code: "08983",
    Name: "Xã Tiến Thắng",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 243,
    Code: "08986",
    Name: "Xã Tự Lập",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 244,
    Code: "08989",
    Name: "Thị trấn Quang Minh",
    LevelUp: "Thị trấn",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 245,
    Code: "08992",
    Name: "Xã Thanh Lâm",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 246,
    Code: "08995",
    Name: "Xã Tam Đồng",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 247,
    Code: "08998",
    Name: "Xã Liên Mạc",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 248,
    Code: "09001",
    Name: "Xã Vạn Yên",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 249,
    Code: "09004",
    Name: "Xã Chu Phan",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 250,
    Code: "09007",
    Name: "Xã Tiến Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 251,
    Code: "09010",
    Name: "Xã Mê Linh",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 252,
    Code: "09013",
    Name: "Xã Văn Khê",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 253,
    Code: "09016",
    Name: "Xã Hoàng Kim",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 254,
    Code: "09019",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 255,
    Code: "09022",
    Name: "Xã Tráng Việt",
    LevelUp: "Xã",
    DistrictCode: "1B29",
    Active: -1,
  },
  {
    ID: 256,
    Code: "09538",
    Name: "Phường Nguyễn Trãi",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 257,
    Code: "09541",
    Name: "Phường Mộ Lao",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 258,
    Code: "09542",
    Name: "Phường Văn Quán",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 259,
    Code: "09544",
    Name: "Phường Vạn Phúc",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 260,
    Code: "09547",
    Name: "Phường Yết Kiêu",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 261,
    Code: "09550",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 262,
    Code: "09551",
    Name: "Phường La Khê",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 263,
    Code: "09552",
    Name: "Phường Phú La",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 264,
    Code: "09553",
    Name: "Phường Phúc La",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 265,
    Code: "09556",
    Name: "Phường Hà Cầu",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 266,
    Code: "09562",
    Name: "Phường Yên Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 267,
    Code: "09565",
    Name: "Phường Kiến Hưng",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 268,
    Code: "09568",
    Name: "Phường Phú Lãm",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 269,
    Code: "09571",
    Name: "Phường Phú Lương",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 270,
    Code: "09886",
    Name: "Phường Dương Nội",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 271,
    Code: "10117",
    Name: "Phường Đồng Mai",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 272,
    Code: "10123",
    Name: "Phường Biên Giang",
    LevelUp: "Phường",
    DistrictCode: "1B15",
    Active: -1,
  },
  {
    ID: 273,
    Code: "09574",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 274,
    Code: "09577",
    Name: "Phường Phú Thịnh",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 275,
    Code: "09580",
    Name: "Phường Ngô Quyền",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 276,
    Code: "09583",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 277,
    Code: "09586",
    Name: "Phường Sơn Lộc",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 278,
    Code: "09589",
    Name: "Phường Xuân Khanh",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 279,
    Code: "09592",
    Name: "Xã Đường Lâm",
    LevelUp: "Xã",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 280,
    Code: "09595",
    Name: "Phường Viên Sơn",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 281,
    Code: "09598",
    Name: "Xã Xuân Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 282,
    Code: "09601",
    Name: "Phường Trung Hưng",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 283,
    Code: "09604",
    Name: "Xã Thanh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 284,
    Code: "09607",
    Name: "Phường Trung Sơn Trầm",
    LevelUp: "Phường",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 285,
    Code: "09610",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 286,
    Code: "09613",
    Name: "Xã Sơn Đông",
    LevelUp: "Xã",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 287,
    Code: "09616",
    Name: "Xã Cổ Đông",
    LevelUp: "Xã",
    DistrictCode: "1B16",
    Active: -1,
  },
  {
    ID: 288,
    Code: "09619",
    Name: "Thị trấn Tây Đằng",
    LevelUp: "Thị trấn",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 289,
    Code: "09625",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 290,
    Code: "09628",
    Name: "Xã Cổ Đô",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 291,
    Code: "09631",
    Name: "Xã Tản Hồng",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 292,
    Code: "09634",
    Name: "Xã Vạn Thắng",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 293,
    Code: "09637",
    Name: "Xã Châu Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 294,
    Code: "09640",
    Name: "Xã Phong Vân",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 295,
    Code: "09643",
    Name: "Xã Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 296,
    Code: "09646",
    Name: "Xã Phú Phương",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 297,
    Code: "09649",
    Name: "Xã Phú Châu",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 298,
    Code: "09652",
    Name: "Xã Thái Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 299,
    Code: "09655",
    Name: "Xã Đồng Thái",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 300,
    Code: "09658",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 301,
    Code: "09661",
    Name: "Xã Minh Châu",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 302,
    Code: "09664",
    Name: "Xã Vật Lại",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 303,
    Code: "09667",
    Name: "Xã Chu Minh",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 304,
    Code: "09670",
    Name: "Xã Tòng Bạt",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 305,
    Code: "09673",
    Name: "Xã Cẩm Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 306,
    Code: "09676",
    Name: "Xã Sơn Đà",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 307,
    Code: "09679",
    Name: "Xã Đông Quang",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 308,
    Code: "09682",
    Name: "Xã Tiên Phong",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 309,
    Code: "09685",
    Name: "Xã Thụy An",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 310,
    Code: "09688",
    Name: "Xã Cam Thượng",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 311,
    Code: "09691",
    Name: "Xã Thuần Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 312,
    Code: "09694",
    Name: "Xã Tản Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 313,
    Code: "09697",
    Name: "Xã Ba Trại",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 314,
    Code: "09700",
    Name: "Xã Minh Quang",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 315,
    Code: "09703",
    Name: "Xã Ba Vì",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 316,
    Code: "09706",
    Name: "Xã Vân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 317,
    Code: "09709",
    Name: "Xã Yên Bài",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 318,
    Code: "09712",
    Name: "Xã Khánh Thượng",
    LevelUp: "Xã",
    DistrictCode: "1B17",
    Active: -1,
  },
  {
    ID: 319,
    Code: "09715",
    Name: "Thị trấn Phúc Thọ",
    LevelUp: "Thị trấn",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 320,
    Code: "09718",
    Name: "Xã Vân Hà",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 321,
    Code: "09721",
    Name: "Xã Vân Phúc",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 322,
    Code: "09724",
    Name: "Xã Vân Nam",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 323,
    Code: "09727",
    Name: "Xã Xuân Đình",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 324,
    Code: "09733",
    Name: "Xã Sen Phương",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 325,
    Code: "09739",
    Name: "Xã Võng Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 326,
    Code: "09742",
    Name: "Xã Thọ Lộc",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 327,
    Code: "09745",
    Name: "Xã Long Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 328,
    Code: "09748",
    Name: "Xã Thượng Cốc",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 329,
    Code: "09751",
    Name: "Xã Hát Môn",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 330,
    Code: "09754",
    Name: "Xã Tích Giang",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 331,
    Code: "09757",
    Name: "Xã Thanh Đa",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 332,
    Code: "09760",
    Name: "Xã Trạch Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 333,
    Code: "09763",
    Name: "Xã Phúc Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 334,
    Code: "09766",
    Name: "Xã Ngọc Tảo",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 335,
    Code: "09769",
    Name: "Xã Phụng Thượng",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 336,
    Code: "09772",
    Name: "Xã Tam Thuấn",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 337,
    Code: "09775",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 338,
    Code: "09778",
    Name: "Xã Hiệp Thuận",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 339,
    Code: "09781",
    Name: "Xã Liên Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1B18",
    Active: -1,
  },
  {
    ID: 340,
    Code: "09784",
    Name: "Thị trấn Phùng",
    LevelUp: "Thị trấn",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 341,
    Code: "09787",
    Name: "Xã Trung Châu",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 342,
    Code: "09790",
    Name: "Xã Thọ An",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 343,
    Code: "09793",
    Name: "Xã Thọ Xuân",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 344,
    Code: "09796",
    Name: "Xã Hồng Hà",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 345,
    Code: "09799",
    Name: "Xã Liên Hồng",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 346,
    Code: "09802",
    Name: "Xã Liên Hà",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 347,
    Code: "09805",
    Name: "Xã Hạ Mỗ",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 348,
    Code: "09808",
    Name: "Xã Liên Trung",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 349,
    Code: "09811",
    Name: "Xã Phương Đình",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 350,
    Code: "09814",
    Name: "Xã Thượng Mỗ",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 351,
    Code: "09817",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 352,
    Code: "09820",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 353,
    Code: "09823",
    Name: "Xã Đan Phượng",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 354,
    Code: "09826",
    Name: "Xã Đồng Tháp",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 355,
    Code: "09829",
    Name: "Xã Song Phượng",
    LevelUp: "Xã",
    DistrictCode: "1B22",
    Active: -1,
  },
  {
    ID: 356,
    Code: "09832",
    Name: "Thị trấn Trạm Trôi",
    LevelUp: "Thị trấn",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 357,
    Code: "09835",
    Name: "Xã Đức Thượng",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 358,
    Code: "09838",
    Name: "Xã Minh Khai",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 359,
    Code: "09841",
    Name: "Xã Dương Liễu",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 360,
    Code: "09844",
    Name: "Xã Di Trạch",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 361,
    Code: "09847",
    Name: "Xã Đức Giang",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 362,
    Code: "09850",
    Name: "Xã Cát Quế",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 363,
    Code: "09853",
    Name: "Xã Kim Chung",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 364,
    Code: "09856",
    Name: "Xã Yên Sở",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 365,
    Code: "09859",
    Name: "Xã Sơn Đồng",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 366,
    Code: "09862",
    Name: "Xã Vân Canh",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 367,
    Code: "09865",
    Name: "Xã Đắc Sở",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 368,
    Code: "09868",
    Name: "Xã Lại Yên",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 369,
    Code: "09871",
    Name: "Xã Tiền Yên",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 370,
    Code: "09874",
    Name: "Xã Song Phương",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 371,
    Code: "09877",
    Name: "Xã An Khánh",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 372,
    Code: "09880",
    Name: "Xã An Thượng",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 373,
    Code: "09883",
    Name: "Xã Vân Côn",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 374,
    Code: "09889",
    Name: "Xã La Phù",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 375,
    Code: "09892",
    Name: "Xã Đông La",
    LevelUp: "Xã",
    DistrictCode: "1B23",
    Active: -1,
  },
  {
    ID: 376,
    Code: "04939",
    Name: "Xã Đông Xuân",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 377,
    Code: "09895",
    Name: "Thị trấn Quốc Oai",
    LevelUp: "Thị trấn",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 378,
    Code: "09898",
    Name: "Xã Sài Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 379,
    Code: "09901",
    Name: "Xã Phượng Cách",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 380,
    Code: "09904",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 381,
    Code: "09907",
    Name: "Xã Ngọc Liệp",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 382,
    Code: "09910",
    Name: "Xã Ngọc Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 383,
    Code: "09913",
    Name: "Xã Liệp Tuyết",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 384,
    Code: "09916",
    Name: "Xã Thạch Thán",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 385,
    Code: "09919",
    Name: "Xã Đồng Quang",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 386,
    Code: "09922",
    Name: "Xã Phú Cát",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 387,
    Code: "09925",
    Name: "Xã Tuyết Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 388,
    Code: "09928",
    Name: "Xã Nghĩa Hương",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 389,
    Code: "09931",
    Name: "Xã Cộng Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 390,
    Code: "09934",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 391,
    Code: "09937",
    Name: "Xã Đại Thành",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 392,
    Code: "09940",
    Name: "Xã Phú Mãn",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 393,
    Code: "09943",
    Name: "Xã Cấn Hữu",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 394,
    Code: "09946",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 395,
    Code: "09949",
    Name: "Xã Hòa Thạch",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 396,
    Code: "09952",
    Name: "Xã Đông Yên",
    LevelUp: "Xã",
    DistrictCode: "1B20",
    Active: -1,
  },
  {
    ID: 397,
    Code: "04927",
    Name: "Xã Yên Trung",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 398,
    Code: "04930",
    Name: "Xã Yên Bình",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 399,
    Code: "04936",
    Name: "Xã Tiến Xuân",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 400,
    Code: "09955",
    Name: "Thị trấn Liên Quan",
    LevelUp: "Thị trấn",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 401,
    Code: "09958",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 402,
    Code: "09961",
    Name: "Xã Cẩm Yên",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 403,
    Code: "09964",
    Name: "Xã Lại Thượng",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 404,
    Code: "09967",
    Name: "Xã Phú Kim",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 405,
    Code: "09970",
    Name: "Xã Hương Ngải",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 406,
    Code: "09973",
    Name: "Xã Canh Nậu",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 407,
    Code: "09976",
    Name: "Xã Kim Quan",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 408,
    Code: "09979",
    Name: "Xã Dị Nậu",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 409,
    Code: "09982",
    Name: "Xã Bình Yên",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 410,
    Code: "09985",
    Name: "Xã Chàng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 411,
    Code: "09988",
    Name: "Xã Thạch Hoà",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 412,
    Code: "09991",
    Name: "Xã Cần Kiệm",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 413,
    Code: "09994",
    Name: "Xã Hữu Bằng",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 414,
    Code: "09997",
    Name: "Xã Phùng Xá",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 415,
    Code: "10000",
    Name: "Xã Tân Xã",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 416,
    Code: "10003",
    Name: "Xã Thạch Xá",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 417,
    Code: "10006",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 418,
    Code: "10009",
    Name: "Xã Hạ Bằng",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 419,
    Code: "10012",
    Name: "Xã Đồng Trúc",
    LevelUp: "Xã",
    DistrictCode: "1B19",
    Active: -1,
  },
  {
    ID: 420,
    Code: "10015",
    Name: "Thị trấn Chúc Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 421,
    Code: "10018",
    Name: "Thị trấn Xuân Mai",
    LevelUp: "Thị trấn",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 422,
    Code: "10021",
    Name: "Xã Phụng Châu",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 423,
    Code: "10024",
    Name: "Xã Tiên Phương",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 424,
    Code: "10027",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 425,
    Code: "10030",
    Name: "Xã Đông Phương Yên",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 426,
    Code: "10033",
    Name: "Xã Phú Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 427,
    Code: "10039",
    Name: "Xã Trường Yên",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 428,
    Code: "10042",
    Name: "Xã Ngọc Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 429,
    Code: "10045",
    Name: "Xã Thủy Xuân Tiên",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 430,
    Code: "10048",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 431,
    Code: "10051",
    Name: "Xã Trung Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 432,
    Code: "10054",
    Name: "Xã Đại Yên",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 433,
    Code: "10057",
    Name: "Xã Thụy Hương",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 434,
    Code: "10060",
    Name: "Xã Tốt Động",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 435,
    Code: "10063",
    Name: "Xã Lam Điền",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 436,
    Code: "10066",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 437,
    Code: "10069",
    Name: "Xã Nam Phương Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 438,
    Code: "10072",
    Name: "Xã Hợp Đồng",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 439,
    Code: "10075",
    Name: "Xã Hoàng Văn Thụ",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 440,
    Code: "10078",
    Name: "Xã Hoàng Diệu",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 441,
    Code: "10081",
    Name: "Xã Hữu Văn",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 442,
    Code: "10084",
    Name: "Xã Quảng Bị",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 443,
    Code: "10087",
    Name: "Xã Mỹ Lương",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 444,
    Code: "10090",
    Name: "Xã Thượng Vực",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 445,
    Code: "10093",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 446,
    Code: "10096",
    Name: "Xã Đồng Phú",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 447,
    Code: "10099",
    Name: "Xã Trần Phú",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 448,
    Code: "10102",
    Name: "Xã Văn Võ",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 449,
    Code: "10105",
    Name: "Xã Đồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 450,
    Code: "10108",
    Name: "Xã Hòa Chính",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 451,
    Code: "10111",
    Name: "Xã Phú Nam An",
    LevelUp: "Xã",
    DistrictCode: "1B21",
    Active: -1,
  },
  {
    ID: 452,
    Code: "10114",
    Name: "Thị trấn Kim Bài",
    LevelUp: "Thị trấn",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 453,
    Code: "10120",
    Name: "Xã Cự Khê",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 454,
    Code: "10126",
    Name: "Xã Bích Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 455,
    Code: "10129",
    Name: "Xã Mỹ Hưng",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 456,
    Code: "10132",
    Name: "Xã Cao Viên",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 457,
    Code: "10135",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 458,
    Code: "10138",
    Name: "Xã Tam Hưng",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 459,
    Code: "10141",
    Name: "Xã Thanh Cao",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 460,
    Code: "10144",
    Name: "Xã Thanh Thùy",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 461,
    Code: "10147",
    Name: "Xã Thanh Mai",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 462,
    Code: "10150",
    Name: "Xã Thanh Văn",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 463,
    Code: "10153",
    Name: "Xã Đỗ Động",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 464,
    Code: "10156",
    Name: "Xã Kim An",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 465,
    Code: "10159",
    Name: "Xã Kim Thư",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 466,
    Code: "10162",
    Name: "Xã Phương Trung",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 467,
    Code: "10165",
    Name: "Xã Tân Ước",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 468,
    Code: "10168",
    Name: "Xã Dân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 469,
    Code: "10171",
    Name: "Xã Liên Châu",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 470,
    Code: "10174",
    Name: "Xã Cao Dương",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 471,
    Code: "10177",
    Name: "Xã Xuân Dương",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 472,
    Code: "10180",
    Name: "Xã Hồng Dương",
    LevelUp: "Xã",
    DistrictCode: "1B24",
    Active: -1,
  },
  {
    ID: 473,
    Code: "10183",
    Name: "Thị trấn Thường Tín",
    LevelUp: "Thị trấn",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 474,
    Code: "10186",
    Name: "Xã Ninh Sở",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 475,
    Code: "10189",
    Name: "Xã Nhị Khê",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 476,
    Code: "10192",
    Name: "Xã Duyên Thái",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 477,
    Code: "10195",
    Name: "Xã Khánh Hà",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 478,
    Code: "10198",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 479,
    Code: "10201",
    Name: "Xã Văn Bình",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 480,
    Code: "10204",
    Name: "Xã Hiền Giang",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 481,
    Code: "10207",
    Name: "Xã Hồng Vân",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 482,
    Code: "10210",
    Name: "Xã Vân Tảo",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 483,
    Code: "10213",
    Name: "Xã Liên Phương",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 484,
    Code: "10216",
    Name: "Xã Văn Phú",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 485,
    Code: "10219",
    Name: "Xã Tự Nhiên",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 486,
    Code: "10222",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 487,
    Code: "10225",
    Name: "Xã Hà Hồi",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 488,
    Code: "10228",
    Name: "Xã Thư Phú",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 489,
    Code: "10231",
    Name: "Xã Nguyễn Trãi",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 490,
    Code: "10234",
    Name: "Xã Quất Động",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 491,
    Code: "10237",
    Name: "Xã Chương Dương",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 492,
    Code: "10240",
    Name: "Xã Tân Minh",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 493,
    Code: "10243",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 494,
    Code: "10246",
    Name: "Xã Thắng Lợi",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 495,
    Code: "10249",
    Name: "Xã Dũng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 496,
    Code: "10252",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 497,
    Code: "10255",
    Name: "Xã Nghiêm Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 498,
    Code: "10258",
    Name: "Xã Tô Hiệu",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 499,
    Code: "10261",
    Name: "Xã Văn Tự",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 500,
    Code: "10264",
    Name: "Xã Vạn Điểm",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 501,
    Code: "10267",
    Name: "Xã Minh Cường",
    LevelUp: "Xã",
    DistrictCode: "1B27",
    Active: -1,
  },
  {
    ID: 502,
    Code: "10270",
    Name: "Thị trấn Phú Minh",
    LevelUp: "Thị trấn",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 503,
    Code: "10273",
    Name: "Thị trấn Phú Xuyên",
    LevelUp: "Thị trấn",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 504,
    Code: "10276",
    Name: "Xã Hồng Minh",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 505,
    Code: "10279",
    Name: "Xã Phượng Dực",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 506,
    Code: "10282",
    Name: "Xã Nam Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 507,
    Code: "10288",
    Name: "Xã Tri Trung",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 508,
    Code: "10291",
    Name: "Xã Đại Thắng",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 509,
    Code: "10294",
    Name: "Xã Phú Túc",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 510,
    Code: "10297",
    Name: "Xã Văn Hoàng",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 511,
    Code: "10300",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 512,
    Code: "10303",
    Name: "Xã Hoàng Long",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 513,
    Code: "10306",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 514,
    Code: "10309",
    Name: "Xã Nam Phong",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 515,
    Code: "10312",
    Name: "Xã Nam Triều",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 516,
    Code: "10315",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 517,
    Code: "10318",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 518,
    Code: "10321",
    Name: "Xã Chuyên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 519,
    Code: "10324",
    Name: "Xã Khai Thái",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 520,
    Code: "10327",
    Name: "Xã Phúc Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 521,
    Code: "10330",
    Name: "Xã Vân Từ",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 522,
    Code: "10333",
    Name: "Xã Tri Thủy",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 523,
    Code: "10336",
    Name: "Xã Đại Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 524,
    Code: "10339",
    Name: "Xã Phú Yên",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 525,
    Code: "10342",
    Name: "Xã Bạch Hạ",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 526,
    Code: "10345",
    Name: "Xã Quang Lãng",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 527,
    Code: "10348",
    Name: "Xã Châu Can",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 528,
    Code: "10351",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 529,
    Code: "10354",
    Name: "Thị trấn Vân Đình",
    LevelUp: "Thị trấn",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 530,
    Code: "10357",
    Name: "Xã Viên An",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 531,
    Code: "10360",
    Name: "Xã Viên Nội",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 532,
    Code: "10363",
    Name: "Xã Hoa Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 533,
    Code: "10366",
    Name: "Xã Quảng Phú Cầu",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 534,
    Code: "10369",
    Name: "Xã Trường Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 535,
    Code: "10372",
    Name: "Xã Cao Thành",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 536,
    Code: "10375",
    Name: "Xã Liên Bạt",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 537,
    Code: "10378",
    Name: "Xã Sơn Công",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 538,
    Code: "10381",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 539,
    Code: "10384",
    Name: "Xã Phương Tú",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 540,
    Code: "10387",
    Name: "Xã Trung Tú",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 541,
    Code: "10390",
    Name: "Xã Đồng Tân",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 542,
    Code: "10393",
    Name: "Xã Tảo Dương Văn",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 543,
    Code: "10396",
    Name: "Xã Vạn Thái",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 544,
    Code: "10399",
    Name: "Xã Minh Đức",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 545,
    Code: "10402",
    Name: "Xã Hòa Lâm",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 546,
    Code: "10405",
    Name: "Xã Hòa Xá",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 547,
    Code: "10408",
    Name: "Xã Trầm Lộng",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 548,
    Code: "10411",
    Name: "Xã Kim Đường",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 549,
    Code: "10414",
    Name: "Xã Hòa Nam",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 550,
    Code: "10417",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 551,
    Code: "10420",
    Name: "Xã Đội Bình",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 552,
    Code: "10423",
    Name: "Xã Đại Hùng",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 553,
    Code: "10426",
    Name: "Xã Đông Lỗ",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 554,
    Code: "10429",
    Name: "Xã Phù Lưu",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 555,
    Code: "10432",
    Name: "Xã Đại Cường",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 556,
    Code: "10435",
    Name: "Xã Lưu Hoàng",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 557,
    Code: "10438",
    Name: "Xã Hồng Quang",
    LevelUp: "Xã",
    DistrictCode: "1B26",
    Active: -1,
  },
  {
    ID: 558,
    Code: "10441",
    Name: "Thị trấn Đại Nghĩa",
    LevelUp: "Thị trấn",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 559,
    Code: "10444",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 560,
    Code: "10447",
    Name: "Xã Thượng Lâm",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 561,
    Code: "10450",
    Name: "Xã Tuy Lai",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 562,
    Code: "10453",
    Name: "Xã Phúc Lâm",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 563,
    Code: "10456",
    Name: "Xã Mỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 564,
    Code: "10459",
    Name: "Xã Bột Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 565,
    Code: "10462",
    Name: "Xã An Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 566,
    Code: "10465",
    Name: "Xã Hồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 567,
    Code: "10468",
    Name: "Xã Lê Thanh",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 568,
    Code: "10471",
    Name: "Xã Xuy Xá",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 569,
    Code: "10474",
    Name: "Xã Phùng Xá",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 570,
    Code: "10477",
    Name: "Xã Phù Lưu Tế",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 571,
    Code: "10480",
    Name: "Xã Đại Hưng",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 572,
    Code: "10483",
    Name: "Xã Vạn Kim",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 573,
    Code: "10486",
    Name: "Xã Đốc Tín",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 574,
    Code: "10489",
    Name: "Xã Hương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 575,
    Code: "10492",
    Name: "Xã Hùng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 576,
    Code: "10495",
    Name: "Xã An Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 577,
    Code: "10498",
    Name: "Xã Hợp Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 578,
    Code: "10501",
    Name: "Xã Hợp Thanh",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 579,
    Code: "10504",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 580,
    Code: "00688",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 581,
    Code: "00691",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 582,
    Code: "00692",
    Name: "Phường Ngọc Hà",
    LevelUp: "Phường",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 583,
    Code: "00694",
    Name: "Phường Nguyễn Trãi",
    LevelUp: "Phường",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 584,
    Code: "00697",
    Name: "Phường Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 585,
    Code: "00700",
    Name: "Xã Ngọc Đường",
    LevelUp: "Xã",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 586,
    Code: "00946",
    Name: "Xã Phương Độ",
    LevelUp: "Xã",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 587,
    Code: "00949",
    Name: "Xã Phương Thiện",
    LevelUp: "Xã",
    DistrictCode: "0501",
    Active: -1,
  },
  {
    ID: 588,
    Code: "00712",
    Name: "Thị trấn Phó Bảng",
    LevelUp: "Thị trấn",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 589,
    Code: "00715",
    Name: "Xã Lũng Cú",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 590,
    Code: "00718",
    Name: "Xã Má Lé",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 591,
    Code: "00721",
    Name: "Thị trấn Đồng Văn",
    LevelUp: "Thị trấn",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 592,
    Code: "00724",
    Name: "Xã Lũng Táo",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 593,
    Code: "00727",
    Name: "Xã Phố Là",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 594,
    Code: "00730",
    Name: "Xã Thài Phìn Tủng",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 595,
    Code: "00733",
    Name: "Xã Sủng Là",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 596,
    Code: "00736",
    Name: "Xã Xà Phìn",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 597,
    Code: "00739",
    Name: "Xã Tả Phìn",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 598,
    Code: "00742",
    Name: "Xã Tả Lủng",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 599,
    Code: "00745",
    Name: "Xã Phố Cáo",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 600,
    Code: "00748",
    Name: "Xã Sính Lủng",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 601,
    Code: "00751",
    Name: "Xã Sảng Tủng",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 602,
    Code: "00754",
    Name: "Xã Lũng Thầu",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 603,
    Code: "00757",
    Name: "Xã Hố Quáng Phìn",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 604,
    Code: "00760",
    Name: "Xã Vần Chải",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 605,
    Code: "00763",
    Name: "Xã Lũng Phìn",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 606,
    Code: "00766",
    Name: "Xã Sủng Trái",
    LevelUp: "Xã",
    DistrictCode: "0502",
    Active: -1,
  },
  {
    ID: 607,
    Code: "00769",
    Name: "Thị trấn Mèo Vạc",
    LevelUp: "Thị trấn",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 608,
    Code: "00772",
    Name: "Xã Thượng Phùng",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 609,
    Code: "00775",
    Name: "Xã Pải Lủng",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 610,
    Code: "00778",
    Name: "Xã Xín Cái",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 611,
    Code: "00781",
    Name: "Xã Pả Vi",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 612,
    Code: "00784",
    Name: "Xã Giàng Chu Phìn",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 613,
    Code: "00787",
    Name: "Xã Sủng Trà",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 614,
    Code: "00790",
    Name: "Xã Sủng Máng",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 615,
    Code: "00793",
    Name: "Xã Sơn Vĩ",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 616,
    Code: "00796",
    Name: "Xã Tả Lủng",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 617,
    Code: "00799",
    Name: "Xã Cán Chu Phìn",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 618,
    Code: "00802",
    Name: "Xã Lũng Pù",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 619,
    Code: "00805",
    Name: "Xã Lũng Chinh",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 620,
    Code: "00808",
    Name: "Xã Tát Ngà",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 621,
    Code: "00811",
    Name: "Xã Nậm Ban",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 622,
    Code: "00814",
    Name: "Xã Khâu Vai",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 623,
    Code: "00815",
    Name: "Xã Niêm Tòng",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 624,
    Code: "00817",
    Name: "Xã Niêm Sơn",
    LevelUp: "Xã",
    DistrictCode: "0503",
    Active: -1,
  },
  {
    ID: 625,
    Code: "00820",
    Name: "Thị trấn Yên Minh",
    LevelUp: "Thị trấn",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 626,
    Code: "00823",
    Name: "Xã Thắng Mố",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 627,
    Code: "00826",
    Name: "Xã Phú Lũng",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 628,
    Code: "00829",
    Name: "Xã Sủng Tráng",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 629,
    Code: "00832",
    Name: "Xã Bạch Đích",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 630,
    Code: "00835",
    Name: "Xã Na Khê",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 631,
    Code: "00838",
    Name: "Xã Sủng Thài",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 632,
    Code: "00841",
    Name: "Xã Hữu Vinh",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 633,
    Code: "00844",
    Name: "Xã Lao Và Chải",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 634,
    Code: "00847",
    Name: "Xã Mậu Duệ",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 635,
    Code: "00850",
    Name: "Xã Đông Minh",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 636,
    Code: "00853",
    Name: "Xã Mậu Long",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 637,
    Code: "00856",
    Name: "Xã Ngam La",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 638,
    Code: "00859",
    Name: "Xã Ngọc Long",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 639,
    Code: "00862",
    Name: "Xã Đường Thượng",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 640,
    Code: "00865",
    Name: "Xã Lũng Hồ",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 641,
    Code: "00868",
    Name: "Xã Du Tiến",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 642,
    Code: "00871",
    Name: "Xã Du Già",
    LevelUp: "Xã",
    DistrictCode: "0504",
    Active: -1,
  },
  {
    ID: 643,
    Code: "00874",
    Name: "Thị trấn Tam Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 644,
    Code: "00877",
    Name: "Xã Bát Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 645,
    Code: "00880",
    Name: "Xã Nghĩa Thuận",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 646,
    Code: "00883",
    Name: "Xã Cán Tỷ",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 647,
    Code: "00886",
    Name: "Xã Cao Mã Pờ",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 648,
    Code: "00889",
    Name: "Xã Thanh Vân",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 649,
    Code: "00892",
    Name: "Xã Tùng Vài",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 650,
    Code: "00895",
    Name: "Xã Đông Hà",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 651,
    Code: "00898",
    Name: "Xã Quản Bạ",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 652,
    Code: "00901",
    Name: "Xã Lùng Tám",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 653,
    Code: "00904",
    Name: "Xã Quyết Tiến",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 654,
    Code: "00907",
    Name: "Xã Tả Ván",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 655,
    Code: "00910",
    Name: "Xã Thái An",
    LevelUp: "Xã",
    DistrictCode: "0505",
    Active: -1,
  },
  {
    ID: 656,
    Code: "00703",
    Name: "Xã Kim Thạch",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 657,
    Code: "00706",
    Name: "Xã Phú Linh",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 658,
    Code: "00709",
    Name: "Xã Kim Linh",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 659,
    Code: "00913",
    Name: "Thị trấn Vị Xuyên",
    LevelUp: "Thị trấn",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 660,
    Code: "00916",
    Name: "Thị trấn Nông Trường Việt Lâm",
    LevelUp: "Thị trấn",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 661,
    Code: "00919",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 662,
    Code: "00922",
    Name: "Xã Thuận Hoà",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 663,
    Code: "00925",
    Name: "Xã Tùng Bá",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 664,
    Code: "00928",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 665,
    Code: "00931",
    Name: "Xã Thanh Đức",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 666,
    Code: "00934",
    Name: "Xã Phong Quang",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 667,
    Code: "00937",
    Name: "Xã Xín Chải",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 668,
    Code: "00940",
    Name: "Xã Phương Tiến",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 669,
    Code: "00943",
    Name: "Xã Lao Chải",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 670,
    Code: "00952",
    Name: "Xã Cao Bồ",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 671,
    Code: "00955",
    Name: "Xã Đạo Đức",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 672,
    Code: "00958",
    Name: "Xã Thượng Sơn",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 673,
    Code: "00961",
    Name: "Xã Linh Hồ",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 674,
    Code: "00964",
    Name: "Xã Quảng Ngần",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 675,
    Code: "00967",
    Name: "Xã Việt Lâm",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 676,
    Code: "00970",
    Name: "Xã Ngọc Linh",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 677,
    Code: "00973",
    Name: "Xã Ngọc Minh",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 678,
    Code: "00976",
    Name: "Xã Bạch Ngọc",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 679,
    Code: "00979",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "0506",
    Active: -1,
  },
  {
    ID: 680,
    Code: "00982",
    Name: "Xã Minh Sơn",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 681,
    Code: "00985",
    Name: "Xã Giáp Trung",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 682,
    Code: "00988",
    Name: "Xã Yên Định",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 683,
    Code: "00991",
    Name: "Thị trấn Yên Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 684,
    Code: "00994",
    Name: "Xã Minh Ngọc",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 685,
    Code: "00997",
    Name: "Xã Yên Phong",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 686,
    Code: "01000",
    Name: "Xã Lạc Nông",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 687,
    Code: "01003",
    Name: "Xã Phú Nam",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 688,
    Code: "01006",
    Name: "Xã Yên Cường",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 689,
    Code: "01009",
    Name: "Xã Thượng Tân",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 690,
    Code: "01012",
    Name: "Xã Đường Âm",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 691,
    Code: "01015",
    Name: "Xã Đường Hồng",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 692,
    Code: "01018",
    Name: "Xã Phiêng Luông",
    LevelUp: "Xã",
    DistrictCode: "0507",
    Active: -1,
  },
  {
    ID: 693,
    Code: "01021",
    Name: "Thị trấn Vinh Quang",
    LevelUp: "Thị trấn",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 694,
    Code: "01024",
    Name: "Xã Bản Máy",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 695,
    Code: "01027",
    Name: "Xã Thàng Tín",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 696,
    Code: "01030",
    Name: "Xã Thèn Chu Phìn",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 697,
    Code: "01033",
    Name: "Xã Pố Lồ",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 698,
    Code: "01036",
    Name: "Xã Bản Phùng",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 699,
    Code: "01039",
    Name: "Xã Túng Sán",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 700,
    Code: "01042",
    Name: "Xã Chiến Phố",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 701,
    Code: "01045",
    Name: "Xã Đản Ván",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 702,
    Code: "01048",
    Name: "Xã Tụ Nhân",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 703,
    Code: "01051",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 704,
    Code: "01054",
    Name: "Xã Nàng Đôn",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 705,
    Code: "01057",
    Name: "Xã Pờ Ly Ngài",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 706,
    Code: "01060",
    Name: "Xã Sán Xả Hồ",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 707,
    Code: "01063",
    Name: "Xã Bản Luốc",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 708,
    Code: "01066",
    Name: "Xã Ngàm Đăng Vài",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 709,
    Code: "01069",
    Name: "Xã Bản Nhùng",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 710,
    Code: "01072",
    Name: "Xã Tả Sử Choóng",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 711,
    Code: "01075",
    Name: "Xã Nậm Dịch",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 712,
    Code: "01081",
    Name: "Xã Hồ Thầu",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 713,
    Code: "01084",
    Name: "Xã Nam Sơn",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 714,
    Code: "01087",
    Name: "Xã Nậm Tỵ",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 715,
    Code: "01090",
    Name: "Xã Thông Nguyên",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 716,
    Code: "01093",
    Name: "Xã Nậm Khòa",
    LevelUp: "Xã",
    DistrictCode: "0508",
    Active: -1,
  },
  {
    ID: 717,
    Code: "01096",
    Name: "Thị trấn Cốc Pài",
    LevelUp: "Thị trấn",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 718,
    Code: "01099",
    Name: "Xã Nàn Xỉn",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 719,
    Code: "01102",
    Name: "Xã Bản Díu",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 720,
    Code: "01105",
    Name: "Xã Chí Cà",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 721,
    Code: "01108",
    Name: "Xã Xín Mần",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 722,
    Code: "01114",
    Name: "Xã Thèn Phàng",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 723,
    Code: "01117",
    Name: "Xã Trung Thịnh",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 724,
    Code: "01120",
    Name: "Xã Pà Vầy Sủ",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 725,
    Code: "01123",
    Name: "Xã Cốc Rế",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 726,
    Code: "01126",
    Name: "Xã Thu Tà",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 727,
    Code: "01129",
    Name: "Xã Nàn Ma",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 728,
    Code: "01132",
    Name: "Xã Tả Nhìu",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 729,
    Code: "01135",
    Name: "Xã Bản Ngò",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 730,
    Code: "01138",
    Name: "Xã Chế Là",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 731,
    Code: "01141",
    Name: "Xã Nấm Dẩn",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 732,
    Code: "01144",
    Name: "Xã Quảng Nguyên",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 733,
    Code: "01147",
    Name: "Xã Nà Chì",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 734,
    Code: "01150",
    Name: "Xã Khuôn Lùng",
    LevelUp: "Xã",
    DistrictCode: "0509",
    Active: -1,
  },
  {
    ID: 735,
    Code: "01153",
    Name: "Thị trấn Việt Quang",
    LevelUp: "Thị trấn",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 736,
    Code: "01156",
    Name: "Thị trấn Vĩnh Tuy",
    LevelUp: "Thị trấn",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 737,
    Code: "01159",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 738,
    Code: "01162",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 739,
    Code: "01165",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 740,
    Code: "01168",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 741,
    Code: "01171",
    Name: "Xã Tân Quang",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 742,
    Code: "01174",
    Name: "Xã Thượng Bình",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 743,
    Code: "01177",
    Name: "Xã Hữu Sản",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 744,
    Code: "01180",
    Name: "Xã Kim Ngọc",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 745,
    Code: "01183",
    Name: "Xã Việt Vinh",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 746,
    Code: "01186",
    Name: "Xã Bằng Hành",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 747,
    Code: "01189",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 748,
    Code: "01192",
    Name: "Xã Liên Hiệp",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 749,
    Code: "01195",
    Name: "Xã Vô Điếm",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 750,
    Code: "01198",
    Name: "Xã Việt Hồng",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 751,
    Code: "01201",
    Name: "Xã Hùng An",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 752,
    Code: "01204",
    Name: "Xã Đức Xuân",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 753,
    Code: "01207",
    Name: "Xã Tiên Kiều",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 754,
    Code: "01210",
    Name: "Xã Vĩnh Hảo",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 755,
    Code: "01213",
    Name: "Xã Vĩnh Phúc",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 756,
    Code: "01216",
    Name: "Xã Đồng Yên",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 757,
    Code: "01219",
    Name: "Xã Đông Thành",
    LevelUp: "Xã",
    DistrictCode: "0510",
    Active: -1,
  },
  {
    ID: 758,
    Code: "01222",
    Name: "Xã Xuân Minh",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 759,
    Code: "01225",
    Name: "Xã Tiên Nguyên",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 760,
    Code: "01228",
    Name: "Xã Tân Nam",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 761,
    Code: "01231",
    Name: "Xã Bản Rịa",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 762,
    Code: "01234",
    Name: "Xã Yên Thành",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 763,
    Code: "01237",
    Name: "Thị trấn Yên Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 764,
    Code: "01240",
    Name: "Xã Tân Trịnh",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 765,
    Code: "01243",
    Name: "Xã Tân Bắc",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 766,
    Code: "01246",
    Name: "Xã Bằng Lang",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 767,
    Code: "01249",
    Name: "Xã Yên Hà",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 768,
    Code: "01252",
    Name: "Xã Hương Sơn",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 769,
    Code: "01255",
    Name: "Xã Xuân Giang",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 770,
    Code: "01258",
    Name: "Xã Nà Khương",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 771,
    Code: "01261",
    Name: "Xã Tiên Yên",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 772,
    Code: "01264",
    Name: "Xã Vĩ Thượng",
    LevelUp: "Xã",
    DistrictCode: "0511",
    Active: -1,
  },
  {
    ID: 773,
    Code: "01267",
    Name: "Phường Sông Hiến",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 774,
    Code: "01270",
    Name: "Phường Sông Bằng",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 775,
    Code: "01273",
    Name: "Phường Hợp Giang",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 776,
    Code: "01276",
    Name: "Phường Tân Giang",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 777,
    Code: "01279",
    Name: "Phường Ngọc Xuân",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 778,
    Code: "01282",
    Name: "Phường Đề Thám",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 779,
    Code: "01285",
    Name: "Phường Hoà Chung",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 780,
    Code: "01288",
    Name: "Phường Duyệt Trung",
    LevelUp: "Phường",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 781,
    Code: "01693",
    Name: "Xã Vĩnh Quang",
    LevelUp: "Xã",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 782,
    Code: "01705",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 783,
    Code: "01720",
    Name: "Xã Chu Trinh",
    LevelUp: "Xã",
    DistrictCode: "0601",
    Active: -1,
  },
  {
    ID: 784,
    Code: "01290",
    Name: "Thị trấn Pác Miầu",
    LevelUp: "Thị trấn",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 785,
    Code: "01291",
    Name: "Xã Đức Hạnh",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 786,
    Code: "01294",
    Name: "Xã Lý Bôn",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 787,
    Code: "01296",
    Name: "Xã Nam Cao",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 788,
    Code: "01297",
    Name: "Xã Nam Quang",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 789,
    Code: "01300",
    Name: "Xã Vĩnh Quang",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 790,
    Code: "01303",
    Name: "Xã Quảng Lâm",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 791,
    Code: "01304",
    Name: "Xã Thạch Lâm",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 792,
    Code: "01309",
    Name: "Xã Vĩnh Phong",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 793,
    Code: "01312",
    Name: "Xã Mông Ân",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 794,
    Code: "01315",
    Name: "Xã Thái Học",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 795,
    Code: "01316",
    Name: "Xã Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 796,
    Code: "01318",
    Name: "Xã Yên Thổ",
    LevelUp: "Xã",
    DistrictCode: "0612",
    Active: -1,
  },
  {
    ID: 797,
    Code: "01321",
    Name: "Thị trấn Bảo Lạc",
    LevelUp: "Thị trấn",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 798,
    Code: "01324",
    Name: "Xã Cốc Pàng",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 799,
    Code: "01327",
    Name: "Xã Thượng Hà",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 800,
    Code: "01330",
    Name: "Xã Cô Ba",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 801,
    Code: "01333",
    Name: "Xã Bảo Toàn",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 802,
    Code: "01336",
    Name: "Xã Khánh Xuân",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 803,
    Code: "01339",
    Name: "Xã Xuân Trường",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 804,
    Code: "01342",
    Name: "Xã Hồng Trị",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 805,
    Code: "01343",
    Name: "Xã Kim Cúc",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 806,
    Code: "01345",
    Name: "Xã Phan Thanh",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 807,
    Code: "01348",
    Name: "Xã Hồng An",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 808,
    Code: "01351",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 809,
    Code: "01352",
    Name: "Xã Hưng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 810,
    Code: "01354",
    Name: "Xã Huy Giáp",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 811,
    Code: "01357",
    Name: "Xã Đình Phùng",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 812,
    Code: "01359",
    Name: "Xã Sơn Lập",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 813,
    Code: "01360",
    Name: "Xã Sơn Lộ",
    LevelUp: "Xã",
    DistrictCode: "0602",
    Active: -1,
  },
  {
    ID: 814,
    Code: "01363",
    Name: "Thị trấn Thông Nông",
    LevelUp: "Thị trấn",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 815,
    Code: "01366",
    Name: "Xã Cần Yên",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 816,
    Code: "01367",
    Name: "Xã Cần Nông",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 817,
    Code: "01372",
    Name: "Xã Lương Thông",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 818,
    Code: "01375",
    Name: "Xã Đa Thông",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 819,
    Code: "01378",
    Name: "Xã Ngọc Động",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 820,
    Code: "01381",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 821,
    Code: "01384",
    Name: "Xã Lương Can",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 822,
    Code: "01387",
    Name: "Xã Thanh Long",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 823,
    Code: "01392",
    Name: "Thị trấn Xuân Hòa",
    LevelUp: "Thị trấn",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 824,
    Code: "01393",
    Name: "Xã Lũng Nặm",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 825,
    Code: "01399",
    Name: "Xã Trường Hà",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 826,
    Code: "01402",
    Name: "Xã Cải Viên",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 827,
    Code: "01411",
    Name: "Xã Nội Thôn",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 828,
    Code: "01414",
    Name: "Xã Tổng Cọt",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 829,
    Code: "01417",
    Name: "Xã Sóc Hà",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 830,
    Code: "01420",
    Name: "Xã Thượng Thôn",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 831,
    Code: "01429",
    Name: "Xã Hồng Sỹ",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 832,
    Code: "01432",
    Name: "Xã Quý Quân",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 833,
    Code: "01435",
    Name: "Xã Mã Ba",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 834,
    Code: "01438",
    Name: "Xã Ngọc Đào",
    LevelUp: "Xã",
    DistrictCode: "0604",
    Active: -1,
  },
  {
    ID: 835,
    Code: "01447",
    Name: "Thị trấn Trà Lĩnh",
    LevelUp: "Thị trấn",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 836,
    Code: "01453",
    Name: "Xã Tri Phương",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 837,
    Code: "01456",
    Name: "Xã Quang Hán",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 838,
    Code: "01462",
    Name: "Xã Xuân Nội",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 839,
    Code: "01465",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 840,
    Code: "01468",
    Name: "Xã Quang Vinh",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 841,
    Code: "01471",
    Name: "Xã Cao Chương",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 842,
    Code: "01477",
    Name: "Thị trấn Trùng Khánh",
    LevelUp: "Thị trấn",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 843,
    Code: "01480",
    Name: "Xã Ngọc Khê",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 844,
    Code: "01481",
    Name: "Xã Ngọc Côn",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 845,
    Code: "01483",
    Name: "Xã Phong Nậm",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 846,
    Code: "01489",
    Name: "Xã Đình Phong",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 847,
    Code: "01495",
    Name: "Xã Đàm Thuỷ",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 848,
    Code: "01498",
    Name: "Xã Khâm Thành",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 849,
    Code: "01501",
    Name: "Xã Chí Viễn",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 850,
    Code: "01504",
    Name: "Xã Lăng Hiếu",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 851,
    Code: "01507",
    Name: "Xã Phong Châu",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 852,
    Code: "01516",
    Name: "Xã Trung Phúc",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 853,
    Code: "01519",
    Name: "Xã Cao Thăng",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 854,
    Code: "01522",
    Name: "Xã Đức Hồng",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 855,
    Code: "01525",
    Name: "Xã Đoài Dương",
    LevelUp: "Xã",
    DistrictCode: "0606",
    Active: -1,
  },
  {
    ID: 856,
    Code: "01534",
    Name: "Xã Minh Long",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 857,
    Code: "01537",
    Name: "Xã Lý Quốc",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 858,
    Code: "01540",
    Name: "Xã Thắng Lợi",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 859,
    Code: "01543",
    Name: "Xã Đồng Loan",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 860,
    Code: "01546",
    Name: "Xã Đức Quang",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 861,
    Code: "01549",
    Name: "Xã Kim Loan",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 862,
    Code: "01552",
    Name: "Xã Quang Long",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 863,
    Code: "01555",
    Name: "Xã An Lạc",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 864,
    Code: "01558",
    Name: "Thị trấn Thanh Nhật",
    LevelUp: "Thị trấn",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 865,
    Code: "01561",
    Name: "Xã Vinh Quý",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 866,
    Code: "01564",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 867,
    Code: "01567",
    Name: "Xã Cô Ngân",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 868,
    Code: "01573",
    Name: "Xã Thị Hoa",
    LevelUp: "Xã",
    DistrictCode: "0611",
    Active: -1,
  },
  {
    ID: 869,
    Code: "01474",
    Name: "Xã Quốc Toản",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 870,
    Code: "01576",
    Name: "Thị trấn Quảng Uyên",
    LevelUp: "Thị trấn",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 871,
    Code: "01579",
    Name: "Xã Phi Hải",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 872,
    Code: "01582",
    Name: "Xã Quảng Hưng",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 873,
    Code: "01594",
    Name: "Xã Độc Lập",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 874,
    Code: "01597",
    Name: "Xã Cai Bộ",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 875,
    Code: "01603",
    Name: "Xã Phúc Sen",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 876,
    Code: "01606",
    Name: "Xã Chí Thảo",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 877,
    Code: "01609",
    Name: "Xã Tự Do",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 878,
    Code: "01615",
    Name: "Xã Hồng Quang",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 879,
    Code: "01618",
    Name: "Xã Ngọc Động",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 880,
    Code: "01624",
    Name: "Xã Hạnh Phúc",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 881,
    Code: "01627",
    Name: "Thị trấn Tà Lùng",
    LevelUp: "Thị trấn",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 882,
    Code: "01630",
    Name: "Xã Bế Văn Đàn",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 883,
    Code: "01636",
    Name: "Xã Cách Linh",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 884,
    Code: "01639",
    Name: "Xã Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 885,
    Code: "01645",
    Name: "Xã Tiên Thành",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 886,
    Code: "01648",
    Name: "Thị trấn Hoà Thuận",
    LevelUp: "Thị trấn",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 887,
    Code: "01651",
    Name: "Xã Mỹ Hưng",
    LevelUp: "Xã",
    DistrictCode: "0614",
    Active: -1,
  },
  {
    ID: 888,
    Code: "01654",
    Name: "Thị trấn Nước Hai",
    LevelUp: "Thị trấn",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 889,
    Code: "01657",
    Name: "Xã Dân Chủ",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 890,
    Code: "01660",
    Name: "Xã Nam Tuấn",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 891,
    Code: "01666",
    Name: "Xã Đại Tiến",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 892,
    Code: "01669",
    Name: "Xã Đức Long",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 893,
    Code: "01672",
    Name: "Xã Ngũ Lão",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 894,
    Code: "01675",
    Name: "Xã Trương Lương",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 895,
    Code: "01687",
    Name: "Xã Hồng Việt",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 896,
    Code: "01696",
    Name: "Xã Hoàng Tung",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 897,
    Code: "01699",
    Name: "Xã Nguyễn Huệ",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 898,
    Code: "01702",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 899,
    Code: "01708",
    Name: "Xã Bạch Đằng",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 900,
    Code: "01711",
    Name: "Xã Bình Dương",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 901,
    Code: "01714",
    Name: "Xã Lê Chung",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 902,
    Code: "01723",
    Name: "Xã Hồng Nam",
    LevelUp: "Xã",
    DistrictCode: "0608",
    Active: -1,
  },
  {
    ID: 903,
    Code: "01726",
    Name: "Thị trấn Nguyên Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 904,
    Code: "01729",
    Name: "Thị trấn Tĩnh Túc",
    LevelUp: "Thị trấn",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 905,
    Code: "01732",
    Name: "Xã Yên Lạc",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 906,
    Code: "01735",
    Name: "Xã Triệu Nguyên",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 907,
    Code: "01738",
    Name: "Xã Ca Thành",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 908,
    Code: "01744",
    Name: "Xã Vũ Nông",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 909,
    Code: "01747",
    Name: "Xã Minh Tâm",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 910,
    Code: "01750",
    Name: "Xã Thể Dục",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 911,
    Code: "01756",
    Name: "Xã Mai Long",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 912,
    Code: "01762",
    Name: "Xã Vũ Minh",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 913,
    Code: "01765",
    Name: "Xã Hoa Thám",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 914,
    Code: "01768",
    Name: "Xã Phan Thanh",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 915,
    Code: "01771",
    Name: "Xã Quang Thành",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 916,
    Code: "01774",
    Name: "Xã Tam Kim",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 917,
    Code: "01777",
    Name: "Xã Thành Công",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 918,
    Code: "01780",
    Name: "Xã Thịnh Vượng",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 919,
    Code: "01783",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "0607",
    Active: -1,
  },
  {
    ID: 920,
    Code: "01786",
    Name: "Thị trấn Đông Khê",
    LevelUp: "Thị trấn",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 921,
    Code: "01789",
    Name: "Xã Canh Tân",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 922,
    Code: "01792",
    Name: "Xã Kim Đồng",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 923,
    Code: "01795",
    Name: "Xã Minh Khai",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 924,
    Code: "01801",
    Name: "Xã Đức Thông",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 925,
    Code: "01804",
    Name: "Xã Thái Cường",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 926,
    Code: "01807",
    Name: "Xã Vân Trình",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 927,
    Code: "01810",
    Name: "Xã Thụy Hùng",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 928,
    Code: "01813",
    Name: "Xã Quang Trọng",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 929,
    Code: "01816",
    Name: "Xã Trọng Con",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 930,
    Code: "01819",
    Name: "Xã Lê Lai",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 931,
    Code: "01822",
    Name: "Xã Đức Long",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 932,
    Code: "01828",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 933,
    Code: "01831",
    Name: "Xã Đức Xuân",
    LevelUp: "Xã",
    DistrictCode: "0610",
    Active: -1,
  },
  {
    ID: 934,
    Code: "01834",
    Name: "Phường Nguyễn Thị Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 935,
    Code: "01837",
    Name: "Phường Sông Cầu",
    LevelUp: "Phường",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 936,
    Code: "01840",
    Name: "Phường Đức Xuân",
    LevelUp: "Phường",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 937,
    Code: "01843",
    Name: "Phường Phùng Chí Kiên",
    LevelUp: "Phường",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 938,
    Code: "01846",
    Name: "Phường Huyền Tụng",
    LevelUp: "Phường",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 939,
    Code: "01849",
    Name: "Xã Dương Quang",
    LevelUp: "Xã",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 940,
    Code: "01852",
    Name: "Xã Nông Thượng",
    LevelUp: "Xã",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 941,
    Code: "01855",
    Name: "Phường Xuất Hóa",
    LevelUp: "Phường",
    DistrictCode: "1101",
    Active: -1,
  },
  {
    ID: 942,
    Code: "01858",
    Name: "Xã Bằng Thành",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 943,
    Code: "01861",
    Name: "Xã Nhạn Môn",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 944,
    Code: "01864",
    Name: "Xã Bộc Bố",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 945,
    Code: "01867",
    Name: "Xã Công Bằng",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 946,
    Code: "01870",
    Name: "Xã Giáo Hiệu",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 947,
    Code: "01873",
    Name: "Xã Xuân La",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 948,
    Code: "01876",
    Name: "Xã An Thắng",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 949,
    Code: "01879",
    Name: "Xã Cổ Linh",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 950,
    Code: "01882",
    Name: "Xã Nghiên Loan",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 951,
    Code: "01885",
    Name: "Xã Cao Tân",
    LevelUp: "Xã",
    DistrictCode: "1108",
    Active: -1,
  },
  {
    ID: 952,
    Code: "01888",
    Name: "Thị trấn Chợ Rã",
    LevelUp: "Thị trấn",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 953,
    Code: "01891",
    Name: "Xã Bành Trạch",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 954,
    Code: "01894",
    Name: "Xã Phúc Lộc",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 955,
    Code: "01897",
    Name: "Xã Hà Hiệu",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 956,
    Code: "01900",
    Name: "Xã Cao Thượng",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 957,
    Code: "01906",
    Name: "Xã Khang Ninh",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 958,
    Code: "01909",
    Name: "Xã Nam Mẫu",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 959,
    Code: "01912",
    Name: "Xã Thượng Giáo",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 960,
    Code: "01915",
    Name: "Xã Địa Linh",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 961,
    Code: "01918",
    Name: "Xã Yến Dương",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 962,
    Code: "01921",
    Name: "Xã Chu Hương",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 963,
    Code: "01924",
    Name: "Xã Quảng Khê",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 964,
    Code: "01927",
    Name: "Xã Mỹ Phương",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 965,
    Code: "01930",
    Name: "Xã Hoàng Trĩ",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 966,
    Code: "01933",
    Name: "Xã Đồng Phúc",
    LevelUp: "Xã",
    DistrictCode: "1106",
    Active: -1,
  },
  {
    ID: 967,
    Code: "01936",
    Name: "Thị trấn Nà Phặc",
    LevelUp: "Thị trấn",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 968,
    Code: "01939",
    Name: "Xã Thượng Ân",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 969,
    Code: "01942",
    Name: "Xã Bằng Vân",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 970,
    Code: "01945",
    Name: "Xã Cốc Đán",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 971,
    Code: "01948",
    Name: "Xã Trung Hoà",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 972,
    Code: "01951",
    Name: "Xã Đức Vân",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 973,
    Code: "01954",
    Name: "Xã Vân Tùng",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 974,
    Code: "01957",
    Name: "Xã Thượng Quan",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 975,
    Code: "01960",
    Name: "Xã Hiệp Lực",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 976,
    Code: "01963",
    Name: "Xã Thuần Mang",
    LevelUp: "Xã",
    DistrictCode: "1105",
    Active: -1,
  },
  {
    ID: 977,
    Code: "01969",
    Name: "Thị trấn Phủ Thông",
    LevelUp: "Thị trấn",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 978,
    Code: "01975",
    Name: "Xã Vi Hương",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 979,
    Code: "01978",
    Name: "Xã Sĩ Bình",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 980,
    Code: "01981",
    Name: "Xã Vũ Muộn",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 981,
    Code: "01984",
    Name: "Xã Đôn Phong",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 982,
    Code: "01990",
    Name: "Xã Lục Bình",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 983,
    Code: "01993",
    Name: "Xã Tân Tú",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 984,
    Code: "01999",
    Name: "Xã Nguyên Phúc",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 985,
    Code: "02002",
    Name: "Xã Cao Sơn",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 986,
    Code: "02005",
    Name: "Xã Quân Hà",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 987,
    Code: "02008",
    Name: "Xã Cẩm Giàng",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 988,
    Code: "02011",
    Name: "Xã Mỹ Thanh",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 989,
    Code: "02014",
    Name: "Xã Dương Phong",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 990,
    Code: "02017",
    Name: "Xã Quang Thuận",
    LevelUp: "Xã",
    DistrictCode: "1103",
    Active: -1,
  },
  {
    ID: 991,
    Code: "02020",
    Name: "Thị trấn Bằng Lũng",
    LevelUp: "Thị trấn",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 992,
    Code: "02023",
    Name: "Xã Xuân Lạc",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 993,
    Code: "02026",
    Name: "Xã Nam Cường",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 994,
    Code: "02029",
    Name: "Xã Đồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 995,
    Code: "02032",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 996,
    Code: "02035",
    Name: "Xã Bản Thi",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 997,
    Code: "02038",
    Name: "Xã Quảng Bạch",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 998,
    Code: "02041",
    Name: "Xã Bằng Phúc",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 999,
    Code: "02044",
    Name: "Xã Yên Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1000,
    Code: "02047",
    Name: "Xã Yên Thượng",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1001,
    Code: "02050",
    Name: "Xã Phương Viên",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1002,
    Code: "02053",
    Name: "Xã Ngọc Phái",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1003,
    Code: "02059",
    Name: "Xã Đồng Thắng",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1004,
    Code: "02062",
    Name: "Xã Lương Bằng",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1005,
    Code: "02065",
    Name: "Xã Bằng Lãng",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1006,
    Code: "02068",
    Name: "Xã Đại Sảo",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1007,
    Code: "02071",
    Name: "Xã Nghĩa Tá",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1008,
    Code: "02077",
    Name: "Xã Yên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1009,
    Code: "02080",
    Name: "Xã Bình Trung",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1010,
    Code: "02083",
    Name: "Xã Yên Phong",
    LevelUp: "Xã",
    DistrictCode: "1102",
    Active: -1,
  },
  {
    ID: 1011,
    Code: "02086",
    Name: "Thị trấn Đồng Tâm",
    LevelUp: "Thị trấn",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1012,
    Code: "02089",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1013,
    Code: "02092",
    Name: "Xã Thanh Vận",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1014,
    Code: "02095",
    Name: "Xã Mai Lạp",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1015,
    Code: "02098",
    Name: "Xã Hoà Mục",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1016,
    Code: "02101",
    Name: "Xã Thanh Mai",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1017,
    Code: "02104",
    Name: "Xã Cao Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1018,
    Code: "02107",
    Name: "Xã Nông Hạ",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1019,
    Code: "02110",
    Name: "Xã Yên Cư",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1020,
    Code: "02113",
    Name: "Xã Thanh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1021,
    Code: "02116",
    Name: "Xã Yên Hân",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1022,
    Code: "02122",
    Name: "Xã Như Cố",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1023,
    Code: "02125",
    Name: "Xã Bình Văn",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1024,
    Code: "02131",
    Name: "Xã Quảng Chu",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 1025,
    Code: "02137",
    Name: "Xã Văn Vũ",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1026,
    Code: "02140",
    Name: "Xã Văn Lang",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1027,
    Code: "02143",
    Name: "Xã Lương Thượng",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1028,
    Code: "02146",
    Name: "Xã Kim Hỷ",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1029,
    Code: "02152",
    Name: "Xã Cường Lợi",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1030,
    Code: "02155",
    Name: "Thị trấn Yến Lạc",
    LevelUp: "Thị trấn",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1031,
    Code: "02158",
    Name: "Xã Kim Lư",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1032,
    Code: "02161",
    Name: "Xã Sơn Thành",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1033,
    Code: "02170",
    Name: "Xã Văn Minh",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1034,
    Code: "02173",
    Name: "Xã Côn Minh",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1035,
    Code: "02176",
    Name: "Xã Cư Lễ",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1036,
    Code: "02179",
    Name: "Xã Trần Phú",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1037,
    Code: "02185",
    Name: "Xã Quang Phong",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1038,
    Code: "02188",
    Name: "Xã Dương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1039,
    Code: "02191",
    Name: "Xã Xuân Dương",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1040,
    Code: "02194",
    Name: "Xã Đổng Xá",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1041,
    Code: "02197",
    Name: "Xã Liêm Thuỷ",
    LevelUp: "Xã",
    DistrictCode: "1104",
    Active: -1,
  },
  {
    ID: 1042,
    Code: "02200",
    Name: "Phường Phan Thiết",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1043,
    Code: "02203",
    Name: "Phường Minh Xuân",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1044,
    Code: "02206",
    Name: "Phường Tân Quang",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1045,
    Code: "02209",
    Name: "Xã Tràng Đà",
    LevelUp: "Xã",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1046,
    Code: "02212",
    Name: "Phường Nông Tiến",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1047,
    Code: "02215",
    Name: "Phường Ỷ La",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1048,
    Code: "02216",
    Name: "Phường Tân Hà",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1049,
    Code: "02218",
    Name: "Phường Hưng Thành",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1050,
    Code: "02497",
    Name: "Xã Kim Phú",
    LevelUp: "Xã",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1051,
    Code: "02503",
    Name: "Xã An Khang",
    LevelUp: "Xã",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1052,
    Code: "02509",
    Name: "Phường Mỹ Lâm",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1053,
    Code: "02512",
    Name: "Phường An Tường",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1054,
    Code: "02515",
    Name: "Xã Lưỡng Vượng",
    LevelUp: "Xã",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1055,
    Code: "02521",
    Name: "Xã Thái Long",
    LevelUp: "Xã",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1056,
    Code: "02524",
    Name: "Phường Đội Cấn",
    LevelUp: "Phường",
    DistrictCode: "0901",
    Active: -1,
  },
  {
    ID: 1057,
    Code: "02233",
    Name: "Xã Phúc Yên",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1058,
    Code: "02242",
    Name: "Xã Xuân Lập",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1059,
    Code: "02251",
    Name: "Xã Khuôn Hà",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1060,
    Code: "02266",
    Name: "Xã Lăng Can",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1061,
    Code: "02269",
    Name: "Xã Thượng Lâm",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1062,
    Code: "02290",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1063,
    Code: "02293",
    Name: "Xã Hồng Quang",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1064,
    Code: "02296",
    Name: "Xã Thổ Bình",
    LevelUp: "Xã",
    DistrictCode: "0902",
    Active: -1,
  },
  {
    ID: 1065,
    Code: "02221",
    Name: "Thị trấn Na Hang",
    LevelUp: "Thị trấn",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1066,
    Code: "02227",
    Name: "Xã Sinh Long",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1067,
    Code: "02230",
    Name: "Xã Thượng Giáp",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1068,
    Code: "02239",
    Name: "Xã Thượng Nông",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1069,
    Code: "02245",
    Name: "Xã Côn Lôn",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1070,
    Code: "02248",
    Name: "Xã Yên Hoa",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1071,
    Code: "02254",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1072,
    Code: "02260",
    Name: "Xã Đà Vị",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1073,
    Code: "02263",
    Name: "Xã Khau Tinh",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1074,
    Code: "02275",
    Name: "Xã Sơn Phú",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1075,
    Code: "02281",
    Name: "Xã Năng Khả",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1076,
    Code: "02284",
    Name: "Xã Thanh Tương",
    LevelUp: "Xã",
    DistrictCode: "0903",
    Active: -1,
  },
  {
    ID: 1077,
    Code: "02287",
    Name: "Thị trấn Vĩnh Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1078,
    Code: "02299",
    Name: "Xã Phúc Sơn",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1079,
    Code: "02302",
    Name: "Xã Minh Quang",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1080,
    Code: "02305",
    Name: "Xã Trung Hà",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1081,
    Code: "02308",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1082,
    Code: "02311",
    Name: "Xã Hà Lang",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1083,
    Code: "02314",
    Name: "Xã Hùng Mỹ",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1084,
    Code: "02317",
    Name: "Xã Yên Lập",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1085,
    Code: "02320",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1086,
    Code: "02323",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1087,
    Code: "02326",
    Name: "Xã Xuân Quang",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1088,
    Code: "02329",
    Name: "Xã Ngọc Hội",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1089,
    Code: "02332",
    Name: "Xã Phú Bình",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1090,
    Code: "02335",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1091,
    Code: "02338",
    Name: "Xã Phúc Thịnh",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1092,
    Code: "02341",
    Name: "Xã Kiên Đài",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1093,
    Code: "02344",
    Name: "Xã Tân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1094,
    Code: "02347",
    Name: "Xã Trung Hòa",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1095,
    Code: "02350",
    Name: "Xã Kim Bình",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1096,
    Code: "02353",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1097,
    Code: "02356",
    Name: "Xã Vinh Quang",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1098,
    Code: "02359",
    Name: "Xã Tri Phú",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1099,
    Code: "02362",
    Name: "Xã Nhân Lý",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1100,
    Code: "02365",
    Name: "Xã Yên Nguyên",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1101,
    Code: "02368",
    Name: "Xã Linh Phú",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1102,
    Code: "02371",
    Name: "Xã Bình Nhân",
    LevelUp: "Xã",
    DistrictCode: "0904",
    Active: -1,
  },
  {
    ID: 1103,
    Code: "02374",
    Name: "Thị trấn Tân Yên",
    LevelUp: "Thị trấn",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1104,
    Code: "02377",
    Name: "Xã Yên Thuận",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1105,
    Code: "02380",
    Name: "Xã Bạch Xa",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1106,
    Code: "02383",
    Name: "Xã Minh Khương",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1107,
    Code: "02386",
    Name: "Xã Yên Lâm",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1108,
    Code: "02389",
    Name: "Xã Minh Dân",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1109,
    Code: "02392",
    Name: "Xã Phù Lưu",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1110,
    Code: "02395",
    Name: "Xã Minh Hương",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1111,
    Code: "02398",
    Name: "Xã Yên Phú",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1112,
    Code: "02401",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1113,
    Code: "02404",
    Name: "Xã Bình Xa",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1114,
    Code: "02407",
    Name: "Xã Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1115,
    Code: "02410",
    Name: "Xã Nhân Mục",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1116,
    Code: "02413",
    Name: "Xã Thành Long",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1117,
    Code: "02416",
    Name: "Xã Bằng Cốc",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1118,
    Code: "02419",
    Name: "Xã Thái Hòa",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1119,
    Code: "02422",
    Name: "Xã Đức Ninh",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1120,
    Code: "02425",
    Name: "Xã Hùng Đức",
    LevelUp: "Xã",
    DistrictCode: "0905",
    Active: -1,
  },
  {
    ID: 1121,
    Code: "02431",
    Name: "Xã Quí Quân",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1122,
    Code: "02434",
    Name: "Xã Lực Hành",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1123,
    Code: "02437",
    Name: "Xã Kiến Thiết",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1124,
    Code: "02440",
    Name: "Xã Trung Minh",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1125,
    Code: "02443",
    Name: "Xã Chiêu Yên",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1126,
    Code: "02446",
    Name: "Xã Trung Trực",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1127,
    Code: "02449",
    Name: "Xã Xuân Vân",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1128,
    Code: "02452",
    Name: "Xã Phúc Ninh",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1129,
    Code: "02455",
    Name: "Xã Hùng Lợi",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1130,
    Code: "02458",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1131,
    Code: "02461",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1132,
    Code: "02464",
    Name: "Xã Tứ Quận",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1133,
    Code: "02467",
    Name: "Xã Đạo Viện",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1134,
    Code: "02470",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1135,
    Code: "02473",
    Name: "Xã Thắng Quân",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1136,
    Code: "02476",
    Name: "Xã Kim Quan",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1137,
    Code: "02479",
    Name: "Xã Lang Quán",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1138,
    Code: "02482",
    Name: "Xã Phú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1139,
    Code: "02485",
    Name: "Xã Công Đa",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1140,
    Code: "02488",
    Name: "Xã Trung Môn",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1141,
    Code: "02491",
    Name: "Xã Chân Sơn",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1142,
    Code: "02494",
    Name: "Xã Thái Bình",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1143,
    Code: "02500",
    Name: "Xã Tiến Bộ",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1144,
    Code: "02506",
    Name: "Xã Mỹ Bằng",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1145,
    Code: "02518",
    Name: "Xã Hoàng Khai",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1146,
    Code: "02527",
    Name: "Xã Nhữ Hán",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1147,
    Code: "02530",
    Name: "Xã Nhữ Khê",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1148,
    Code: "02533",
    Name: "Xã Đội Bình",
    LevelUp: "Xã",
    DistrictCode: "0906",
    Active: -1,
  },
  {
    ID: 1149,
    Code: "02536",
    Name: "Thị trấn Sơn Dương",
    LevelUp: "Thị trấn",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1150,
    Code: "02539",
    Name: "Xã Trung Yên",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1151,
    Code: "02542",
    Name: "Xã Minh Thanh",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1152,
    Code: "02545",
    Name: "Xã Tân Trào",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1153,
    Code: "02548",
    Name: "Xã Vĩnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1154,
    Code: "02551",
    Name: "Xã Thượng Ấm",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1155,
    Code: "02554",
    Name: "Xã Bình Yên",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1156,
    Code: "02557",
    Name: "Xã Lương Thiện",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1157,
    Code: "02560",
    Name: "Xã Tú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1158,
    Code: "02563",
    Name: "Xã Cấp Tiến",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1159,
    Code: "02566",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1160,
    Code: "02569",
    Name: "Xã Phúc Ứng",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1161,
    Code: "02572",
    Name: "Xã Đông Thọ",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1162,
    Code: "02575",
    Name: "Xã Kháng Nhật",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1163,
    Code: "02578",
    Name: "Xã Hợp Hòa",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1164,
    Code: "02584",
    Name: "Xã Quyết Thắng",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1165,
    Code: "02587",
    Name: "Xã Đồng Quý",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1166,
    Code: "02590",
    Name: "Xã Tân Thanh",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1167,
    Code: "02593",
    Name: "Xã Vân Sơn",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1168,
    Code: "02596",
    Name: "Xã Văn Phú",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1169,
    Code: "02599",
    Name: "Xã Chi Thiết",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1170,
    Code: "02602",
    Name: "Xã Đông Lợi",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1171,
    Code: "02605",
    Name: "Xã Thiện Kế",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1172,
    Code: "02608",
    Name: "Xã Hồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1173,
    Code: "02611",
    Name: "Xã Phú Lương",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1174,
    Code: "02614",
    Name: "Xã Ninh Lai",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1175,
    Code: "02617",
    Name: "Xã Đại Phú",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1176,
    Code: "02620",
    Name: "Xã Sơn Nam",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1177,
    Code: "02623",
    Name: "Xã Hào Phú",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1178,
    Code: "02626",
    Name: "Xã Tam Đa",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1179,
    Code: "02632",
    Name: "Xã Trường Sinh",
    LevelUp: "Xã",
    DistrictCode: "0907",
    Active: -1,
  },
  {
    ID: 1180,
    Code: "02635",
    Name: "Phường Duyên Hải",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1181,
    Code: "02641",
    Name: "Phường Lào Cai",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1182,
    Code: "02644",
    Name: "Phường Cốc Lếu",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1183,
    Code: "02647",
    Name: "Phường Kim Tân",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1184,
    Code: "02650",
    Name: "Phường Bắc Lệnh",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1185,
    Code: "02653",
    Name: "Phường Pom Hán",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1186,
    Code: "02656",
    Name: "Phường Xuân Tăng",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1187,
    Code: "02658",
    Name: "Phường Bình Minh",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1188,
    Code: "02659",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1189,
    Code: "02662",
    Name: "Xã Đồng Tuyển",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1190,
    Code: "02665",
    Name: "Xã Vạn Hoà",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1191,
    Code: "02668",
    Name: "Phường Bắc Cường",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1192,
    Code: "02671",
    Name: "Phường Nam Cường",
    LevelUp: "Phường",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1193,
    Code: "02674",
    Name: "Xã Cam Đường",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1194,
    Code: "02677",
    Name: "Xã Tả Phời",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1195,
    Code: "02680",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1196,
    Code: "02746",
    Name: "Xã Cốc San",
    LevelUp: "Xã",
    DistrictCode: "0801",
    Active: -1,
  },
  {
    ID: 1197,
    Code: "02683",
    Name: "Thị trấn Bát Xát",
    LevelUp: "Thị trấn",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1198,
    Code: "02686",
    Name: "Xã A Mú Sung",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1199,
    Code: "02689",
    Name: "Xã Nậm Chạc",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1200,
    Code: "02692",
    Name: "Xã A Lù",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1201,
    Code: "02695",
    Name: "Xã Trịnh Tường",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1202,
    Code: "02701",
    Name: "Xã Y Tý",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1203,
    Code: "02704",
    Name: "Xã Cốc Mỳ",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1204,
    Code: "02707",
    Name: "Xã Dền Sáng",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1205,
    Code: "02710",
    Name: "Xã Bản Vược",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1206,
    Code: "02713",
    Name: "Xã Sàng Ma Sáo",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1207,
    Code: "02716",
    Name: "Xã Bản Qua",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1208,
    Code: "02719",
    Name: "Xã Mường Vi",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1209,
    Code: "02722",
    Name: "Xã Dền Thàng",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1210,
    Code: "02725",
    Name: "Xã Bản Xèo",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1211,
    Code: "02728",
    Name: "Xã Mường Hum",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1212,
    Code: "02731",
    Name: "Xã Trung Lèng Hồ",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1213,
    Code: "02734",
    Name: "Xã Quang Kim",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1214,
    Code: "02737",
    Name: "Xã Pa Cheo",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1215,
    Code: "02740",
    Name: "Xã Nậm Pung",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1216,
    Code: "02743",
    Name: "Xã Phìn Ngan",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1217,
    Code: "02749",
    Name: "Xã Tòng Sành",
    LevelUp: "Xã",
    DistrictCode: "0803",
    Active: -1,
  },
  {
    ID: 1218,
    Code: "02752",
    Name: "Xã Pha Long",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1219,
    Code: "02755",
    Name: "Xã Tả Ngải Chồ",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1220,
    Code: "02758",
    Name: "Xã Tung Chung Phố",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1221,
    Code: "02761",
    Name: "Thị trấn Mường Khương",
    LevelUp: "Thị trấn",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1222,
    Code: "02764",
    Name: "Xã Dìn Chin",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1223,
    Code: "02767",
    Name: "Xã Tả Gia Khâu",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1224,
    Code: "02770",
    Name: "Xã Nậm Chảy",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1225,
    Code: "02773",
    Name: "Xã Nấm Lư",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1226,
    Code: "02776",
    Name: "Xã Lùng Khấu Nhin",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1227,
    Code: "02779",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1228,
    Code: "02782",
    Name: "Xã Cao Sơn",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1229,
    Code: "02785",
    Name: "Xã Lùng Vai",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1230,
    Code: "02788",
    Name: "Xã Bản Lầu",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1231,
    Code: "02791",
    Name: "Xã La Pan Tẩn",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1232,
    Code: "02794",
    Name: "Xã Tả Thàng",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1233,
    Code: "02797",
    Name: "Xã Bản Sen",
    LevelUp: "Xã",
    DistrictCode: "0809",
    Active: -1,
  },
  {
    ID: 1234,
    Code: "02800",
    Name: "Xã Nàn Sán",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1235,
    Code: "02803",
    Name: "Xã Thào Chư Phìn",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1236,
    Code: "02806",
    Name: "Xã Bản Mế",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1237,
    Code: "02809",
    Name: "Thị trấn Si Ma Cai",
    LevelUp: "Thị trấn",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1238,
    Code: "02812",
    Name: "Xã Sán Chải",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1239,
    Code: "02818",
    Name: "Xã Lùng Thẩn",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1240,
    Code: "02821",
    Name: "Xã Cán Cấu",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1241,
    Code: "02824",
    Name: "Xã Sín Chéng",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1242,
    Code: "02827",
    Name: "Xã Quan Hồ Thẩn",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1243,
    Code: "02836",
    Name: "Xã Nàn Xín",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 1244,
    Code: "02839",
    Name: "Thị trấn Bắc Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1245,
    Code: "02842",
    Name: "Xã Lùng Cải",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1246,
    Code: "02848",
    Name: "Xã Lùng Phình",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1247,
    Code: "02851",
    Name: "Xã Tả Van Chư",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1248,
    Code: "02854",
    Name: "Xã Tả Củ Tỷ",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1249,
    Code: "02857",
    Name: "Xã Thải Giàng Phố",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1250,
    Code: "02863",
    Name: "Xã Hoàng Thu Phố",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1251,
    Code: "02866",
    Name: "Xã Bản Phố",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1252,
    Code: "02869",
    Name: "Xã Bản Liền",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1253,
    Code: "02872",
    Name: "Xã Tà Chải",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1254,
    Code: "02875",
    Name: "Xã Na Hối",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1255,
    Code: "02878",
    Name: "Xã Cốc Ly",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1256,
    Code: "02881",
    Name: "Xã Nậm Mòn",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1257,
    Code: "02884",
    Name: "Xã Nậm Đét",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1258,
    Code: "02887",
    Name: "Xã Nậm Khánh",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1259,
    Code: "02890",
    Name: "Xã Bảo Nhai",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1260,
    Code: "02893",
    Name: "Xã Nậm Lúc",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1261,
    Code: "02896",
    Name: "Xã Cốc Lầu",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1262,
    Code: "02899",
    Name: "Xã Bản Cái",
    LevelUp: "Xã",
    DistrictCode: "0808",
    Active: -1,
  },
  {
    ID: 1263,
    Code: "02902",
    Name: "Thị trấn N.T Phong Hải",
    LevelUp: "Thị trấn",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1264,
    Code: "02905",
    Name: "Thị trấn Phố Lu",
    LevelUp: "Thị trấn",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1265,
    Code: "02908",
    Name: "Thị trấn Tằng Loỏng",
    LevelUp: "Thị trấn",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1266,
    Code: "02911",
    Name: "Xã Bản Phiệt",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1267,
    Code: "02914",
    Name: "Xã Bản Cầm",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1268,
    Code: "02917",
    Name: "Xã Thái Niên",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1269,
    Code: "02920",
    Name: "Xã Phong Niên",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1270,
    Code: "02923",
    Name: "Xã Gia Phú",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1271,
    Code: "02926",
    Name: "Xã Xuân Quang",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1272,
    Code: "02929",
    Name: "Xã Sơn Hải",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1273,
    Code: "02932",
    Name: "Xã Xuân Giao",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1274,
    Code: "02935",
    Name: "Xã Trì Quang",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1275,
    Code: "02938",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1276,
    Code: "02944",
    Name: "Xã Phú Nhuận",
    LevelUp: "Xã",
    DistrictCode: "0804",
    Active: -1,
  },
  {
    ID: 1277,
    Code: "02947",
    Name: "Thị trấn Phố Ràng",
    LevelUp: "Thị trấn",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1278,
    Code: "02950",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1279,
    Code: "02953",
    Name: "Xã Nghĩa Đô",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1280,
    Code: "02956",
    Name: "Xã Vĩnh Yên",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1281,
    Code: "02959",
    Name: "Xã Điện Quan",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1282,
    Code: "02962",
    Name: "Xã Xuân Hoà",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1283,
    Code: "02965",
    Name: "Xã Tân Dương",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1284,
    Code: "02968",
    Name: "Xã Thượng Hà",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1285,
    Code: "02971",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1286,
    Code: "02974",
    Name: "Xã Cam Cọn",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1287,
    Code: "02977",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1288,
    Code: "02980",
    Name: "Xã Xuân Thượng",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1289,
    Code: "02983",
    Name: "Xã Việt Tiến",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1290,
    Code: "02986",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1291,
    Code: "02989",
    Name: "Xã Bảo Hà",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1292,
    Code: "02992",
    Name: "Xã Lương Sơn",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1293,
    Code: "02998",
    Name: "Xã Phúc Khánh",
    LevelUp: "Xã",
    DistrictCode: "0807",
    Active: -1,
  },
  {
    ID: 1294,
    Code: "03001",
    Name: "Phường Sa Pa",
    LevelUp: "Phường",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1295,
    Code: "03002",
    Name: "Phường Sa Pả",
    LevelUp: "Phường",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1296,
    Code: "03003",
    Name: "Phường Ô Quý Hồ",
    LevelUp: "Phường",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1297,
    Code: "03004",
    Name: "Xã Ngũ Chỉ Sơn",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1298,
    Code: "03006",
    Name: "Phường Phan Si Păng",
    LevelUp: "Phường",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1299,
    Code: "03010",
    Name: "Xã Trung Chải",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1300,
    Code: "03013",
    Name: "Xã Tả Phìn",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1301,
    Code: "03016",
    Name: "Phường Hàm Rồng",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1302,
    Code: "03019",
    Name: "Xã Hoàng Liên",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1303,
    Code: "03022",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1304,
    Code: "03028",
    Name: "Phường Cầu Mây",
    LevelUp: "Phường",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1305,
    Code: "03037",
    Name: "Xã Mường Hoa",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1306,
    Code: "03040",
    Name: "Xã Tả Van",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1307,
    Code: "03043",
    Name: "Xã Mường Bo",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1308,
    Code: "03046",
    Name: "Xã Bản Hồ",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1309,
    Code: "03052",
    Name: "Xã Liên Minh",
    LevelUp: "Xã",
    DistrictCode: "0805",
    Active: -1,
  },
  {
    ID: 1310,
    Code: "03055",
    Name: "Thị trấn Khánh Yên",
    LevelUp: "Thị trấn",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1311,
    Code: "03061",
    Name: "Xã Võ Lao",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1312,
    Code: "03064",
    Name: "Xã Sơn Thuỷ",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1313,
    Code: "03067",
    Name: "Xã Nậm Mả",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1314,
    Code: "03070",
    Name: "Xã Tân Thượng",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1315,
    Code: "03073",
    Name: "Xã Nậm Rạng",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1316,
    Code: "03076",
    Name: "Xã Nậm Chầy",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1317,
    Code: "03079",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1318,
    Code: "03082",
    Name: "Xã Khánh Yên Thượng",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1319,
    Code: "03085",
    Name: "Xã Nậm Xé",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1320,
    Code: "03088",
    Name: "Xã Dần Thàng",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1321,
    Code: "03091",
    Name: "Xã Chiềng Ken",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1322,
    Code: "03094",
    Name: "Xã Làng Giàng",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1323,
    Code: "03097",
    Name: "Xã Hoà Mạc",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1324,
    Code: "03100",
    Name: "Xã Khánh Yên Trung",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1325,
    Code: "03103",
    Name: "Xã Khánh Yên Hạ",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1326,
    Code: "03106",
    Name: "Xã Dương Quỳ",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1327,
    Code: "03109",
    Name: "Xã Nậm Tha",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1328,
    Code: "03112",
    Name: "Xã Minh Lương",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1329,
    Code: "03115",
    Name: "Xã Thẩm Dương",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1330,
    Code: "03118",
    Name: "Xã Liêm Phú",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1331,
    Code: "03121",
    Name: "Xã Nậm Xây",
    LevelUp: "Xã",
    DistrictCode: "0806",
    Active: -1,
  },
  {
    ID: 1332,
    Code: "03124",
    Name: "Phường Noong Bua",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1333,
    Code: "03127",
    Name: "Phường Him Lam",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1334,
    Code: "03130",
    Name: "Phường Thanh Bình",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1335,
    Code: "03133",
    Name: "Phường Tân Thanh",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1336,
    Code: "03136",
    Name: "Phường Mường Thanh",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1337,
    Code: "03139",
    Name: "Phường Nam Thanh",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1338,
    Code: "03142",
    Name: "Phường Thanh Trường",
    LevelUp: "Phường",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1339,
    Code: "03145",
    Name: "Xã Thanh Minh",
    LevelUp: "Xã",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1340,
    Code: "03316",
    Name: "Xã Nà Tấu",
    LevelUp: "Xã",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1341,
    Code: "03317",
    Name: "Xã Nà Nhạn",
    LevelUp: "Xã",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1342,
    Code: "03325",
    Name: "Xã Mường Phăng",
    LevelUp: "Xã",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1343,
    Code: "03326",
    Name: "Xã Pá Khoang",
    LevelUp: "Xã",
    DistrictCode: "6201",
    Active: -1,
  },
  {
    ID: 1344,
    Code: "03148",
    Name: "Phường Sông Đà",
    LevelUp: "Phường",
    DistrictCode: "6202",
    Active: -1,
  },
  {
    ID: 1345,
    Code: "03151",
    Name: "Phường Na Lay",
    LevelUp: "Phường",
    DistrictCode: "6202",
    Active: -1,
  },
  {
    ID: 1346,
    Code: "03184",
    Name: "Xã Lay Nưa",
    LevelUp: "Xã",
    DistrictCode: "6202",
    Active: -1,
  },
  {
    ID: 1347,
    Code: "03154",
    Name: "Xã Sín Thầu",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1348,
    Code: "03155",
    Name: "Xã Sen Thượng",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1349,
    Code: "03157",
    Name: "Xã Chung Chải",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1350,
    Code: "03158",
    Name: "Xã Leng Su Sìn",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1351,
    Code: "03159",
    Name: "Xã Pá Mỳ",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1352,
    Code: "03160",
    Name: "Xã Mường Nhé",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1353,
    Code: "03161",
    Name: "Xã Nậm Vì",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1354,
    Code: "03162",
    Name: "Xã Nậm Kè",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1355,
    Code: "03163",
    Name: "Xã Mường Toong",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1356,
    Code: "03164",
    Name: "Xã Quảng Lâm",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1357,
    Code: "03177",
    Name: "Xã Huổi Lếnh",
    LevelUp: "Xã",
    DistrictCode: "6208",
    Active: -1,
  },
  {
    ID: 1358,
    Code: "03172",
    Name: "Thị Trấn Mường Chà",
    LevelUp: "Thị trấn",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1359,
    Code: "03178",
    Name: "Xã Xá Tổng",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1360,
    Code: "03181",
    Name: "Xã Mường Tùng",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1361,
    Code: "03190",
    Name: "Xã Hừa Ngài",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1362,
    Code: "03191",
    Name: "Xã Huổi Mí",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1363,
    Code: "03193",
    Name: "Xã Pa Ham",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1364,
    Code: "03194",
    Name: "Xã Nậm Nèn",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1365,
    Code: "03196",
    Name: "Xã Huổi Lèng",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1366,
    Code: "03197",
    Name: "Xã Sa Lông",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1367,
    Code: "03200",
    Name: "Xã Ma Thì Hồ",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1368,
    Code: "03201",
    Name: "Xã Na Sang",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1369,
    Code: "03202",
    Name: "Xã Mường Mươn",
    LevelUp: "Xã",
    DistrictCode: "6205",
    Active: -1,
  },
  {
    ID: 1370,
    Code: "03217",
    Name: "Thị trấn Tủa Chùa",
    LevelUp: "Thị trấn",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1371,
    Code: "03220",
    Name: "Xã Huổi Só",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1372,
    Code: "03223",
    Name: "Xã Xín Chải",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1373,
    Code: "03226",
    Name: "Xã Tả Sìn Thàng",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1374,
    Code: "03229",
    Name: "Xã Lao Xả Phình",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1375,
    Code: "03232",
    Name: "Xã Tả Phìn",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1376,
    Code: "03235",
    Name: "Xã Tủa Thàng",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1377,
    Code: "03238",
    Name: "Xã Trung Thu",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1378,
    Code: "03241",
    Name: "Xã Sính Phình",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1379,
    Code: "03244",
    Name: "Xã Sáng Nhè",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1380,
    Code: "03247",
    Name: "Xã Mường Đun",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1381,
    Code: "03250",
    Name: "Xã Mường Báng",
    LevelUp: "Xã",
    DistrictCode: "6206",
    Active: -1,
  },
  {
    ID: 1382,
    Code: "03253",
    Name: "Thị trấn Tuần Giáo",
    LevelUp: "Thị trấn",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1383,
    Code: "03259",
    Name: "Xã Phình Sáng",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1384,
    Code: "03260",
    Name: "Xã Rạng Đông",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1385,
    Code: "03262",
    Name: "Xã Mùn Chung",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1386,
    Code: "03263",
    Name: "Xã Nà Tòng",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1387,
    Code: "03265",
    Name: "Xã Ta Ma",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1388,
    Code: "03268",
    Name: "Xã Mường Mùn",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1389,
    Code: "03269",
    Name: "Xã Pú Xi",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1390,
    Code: "03271",
    Name: "Xã Pú Nhung",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1391,
    Code: "03274",
    Name: "Xã Quài Nưa",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1392,
    Code: "03277",
    Name: "Xã Mường Thín",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1393,
    Code: "03280",
    Name: "Xã Tỏa Tình",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1394,
    Code: "03283",
    Name: "Xã Nà Sáy",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1395,
    Code: "03284",
    Name: "Xã Mường Khong",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1396,
    Code: "03289",
    Name: "Xã Quài Cang",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1397,
    Code: "03295",
    Name: "Xã Quài Tở",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1398,
    Code: "03298",
    Name: "Xã Chiềng Sinh",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1399,
    Code: "03299",
    Name: "Xã Chiềng Đông",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1400,
    Code: "03304",
    Name: "Xã Tênh Phông",
    LevelUp: "Xã",
    DistrictCode: "6204",
    Active: -1,
  },
  {
    ID: 1401,
    Code: "03319",
    Name: "Xã Mường Pồn",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1402,
    Code: "03322",
    Name: "Xã Thanh Nưa",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1403,
    Code: "03323",
    Name: "Xã Hua Thanh",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1404,
    Code: "03328",
    Name: "Xã Thanh Luông",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1405,
    Code: "03331",
    Name: "Xã Thanh Hưng",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1406,
    Code: "03334",
    Name: "Xã Thanh Xương",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1407,
    Code: "03337",
    Name: "Xã Thanh Chăn",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1408,
    Code: "03340",
    Name: "Xã Pa Thơm",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1409,
    Code: "03343",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1410,
    Code: "03346",
    Name: "Xã Thanh Yên",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1411,
    Code: "03349",
    Name: "Xã Noong Luống",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1412,
    Code: "03352",
    Name: "Xã Noọng Hẹt",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1413,
    Code: "03355",
    Name: "Xã Sam Mứn",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1414,
    Code: "03356",
    Name: "Xã Pom Lót",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1415,
    Code: "03358",
    Name: "Xã Núa Ngam",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1416,
    Code: "03359",
    Name: "Xã Hẹ Muông",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1417,
    Code: "03361",
    Name: "Xã Na Ư",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1418,
    Code: "03364",
    Name: "Xã Mường Nhà",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1419,
    Code: "03365",
    Name: "Xã Na Tông",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1420,
    Code: "03367",
    Name: "Xã Mường Lói",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1421,
    Code: "03368",
    Name: "Xã Phu Luông",
    LevelUp: "Xã",
    DistrictCode: "6203",
    Active: -1,
  },
  {
    ID: 1422,
    Code: "03203",
    Name: "Thị trấn Điện Biên Đông",
    LevelUp: "Thị trấn",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1423,
    Code: "03205",
    Name: "Xã Na Son",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1424,
    Code: "03208",
    Name: "Xã Phì Nhừ",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1425,
    Code: "03211",
    Name: "Xã Chiềng Sơ",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1426,
    Code: "03214",
    Name: "Xã Mường Luân",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1427,
    Code: "03370",
    Name: "Xã Pú Nhi",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1428,
    Code: "03371",
    Name: "Xã Nong U",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1429,
    Code: "03373",
    Name: "Xã Xa Dung",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1430,
    Code: "03376",
    Name: "Xã Keo Lôm",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1431,
    Code: "03379",
    Name: "Xã Luân Giới",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1432,
    Code: "03382",
    Name: "Xã Phình Giàng",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1433,
    Code: "03383",
    Name: "Xã Pú Hồng",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1434,
    Code: "03384",
    Name: "Xã Tìa Dình",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1435,
    Code: "03385",
    Name: "Xã Háng Lìa",
    LevelUp: "Xã",
    DistrictCode: "6207",
    Active: -1,
  },
  {
    ID: 1436,
    Code: "03256",
    Name: "Thị trấn Mường Ảng",
    LevelUp: "Thị trấn",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1437,
    Code: "03286",
    Name: "Xã Mường Đăng",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1438,
    Code: "03287",
    Name: "Xã Ngối Cáy",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1439,
    Code: "03292",
    Name: "Xã Ẳng Tở",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1440,
    Code: "03301",
    Name: "Xã Búng Lao",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1441,
    Code: "03302",
    Name: "Xã Xuân Lao",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1442,
    Code: "03307",
    Name: "Xã Ẳng Nưa",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1443,
    Code: "03310",
    Name: "Xã Ẳng Cang",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1444,
    Code: "03312",
    Name: "Xã Nặm Lịch",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1445,
    Code: "03313",
    Name: "Xã Mường Lạn",
    LevelUp: "Xã",
    DistrictCode: "6209",
    Active: -1,
  },
  {
    ID: 1446,
    Code: "03156",
    Name: "Xã Nậm Tin",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1447,
    Code: "03165",
    Name: "Xã Pa Tần",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1448,
    Code: "03166",
    Name: "Xã Chà Cang",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1449,
    Code: "03167",
    Name: "Xã Na Cô Sa",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1450,
    Code: "03168",
    Name: "Xã Nà Khoa",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1451,
    Code: "03169",
    Name: "Xã Nà Hỳ",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1452,
    Code: "03170",
    Name: "Xã Nà Bủng",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1453,
    Code: "03171",
    Name: "Xã Nậm Nhừ",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1454,
    Code: "03173",
    Name: "Xã Nậm Chua",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1455,
    Code: "03174",
    Name: "Xã Nậm Khăn",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1456,
    Code: "03175",
    Name: "Xã Chà Tở",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1457,
    Code: "03176",
    Name: "Xã Vàng Đán",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1458,
    Code: "03187",
    Name: "Xã Chà Nưa",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1459,
    Code: "03198",
    Name: "Xã Phìn Hồ",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1460,
    Code: "03199",
    Name: "Xã Si Pa Phìn",
    LevelUp: "Xã",
    DistrictCode: "6210",
    Active: -1,
  },
  {
    ID: 1461,
    Code: "03386",
    Name: "Phường Quyết Thắng",
    LevelUp: "Phường",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1462,
    Code: "03387",
    Name: "Phường Tân Phong",
    LevelUp: "Phường",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1463,
    Code: "03388",
    Name: "Phường Quyết Tiến",
    LevelUp: "Phường",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1464,
    Code: "03389",
    Name: "Phường Đoàn Kết",
    LevelUp: "Phường",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1465,
    Code: "03403",
    Name: "Xã Sùng Phài",
    LevelUp: "Xã",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1466,
    Code: "03408",
    Name: "Phường Đông Phong",
    LevelUp: "Phường",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1467,
    Code: "03409",
    Name: "Xã San Thàng",
    LevelUp: "Xã",
    DistrictCode: "0701",
    Active: -1,
  },
  {
    ID: 1468,
    Code: "03390",
    Name: "Thị trấn Tam Đường",
    LevelUp: "Thị trấn",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1469,
    Code: "03394",
    Name: "Xã Thèn Sin",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1470,
    Code: "03400",
    Name: "Xã Tả Lèng",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1471,
    Code: "03405",
    Name: "Xã Giang Ma",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1472,
    Code: "03406",
    Name: "Xã Hồ Thầu",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1473,
    Code: "03412",
    Name: "Xã Bình Lư",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1474,
    Code: "03413",
    Name: "Xã Sơn Bình",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1475,
    Code: "03415",
    Name: "Xã Nùng Nàng",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1476,
    Code: "03418",
    Name: "Xã Bản Giang",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1477,
    Code: "03421",
    Name: "Xã Bản Hon",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1478,
    Code: "03424",
    Name: "Xã Bản Bo",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1479,
    Code: "03427",
    Name: "Xã Nà Tăm",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1480,
    Code: "03430",
    Name: "Xã Khun Há",
    LevelUp: "Xã",
    DistrictCode: "0702",
    Active: -1,
  },
  {
    ID: 1481,
    Code: "03433",
    Name: "Thị trấn Mường Tè",
    LevelUp: "Thị trấn",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1482,
    Code: "03436",
    Name: "Xã Thu Lũm",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1483,
    Code: "03439",
    Name: "Xã Ka Lăng",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1484,
    Code: "03440",
    Name: "Xã Tá Bạ",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1485,
    Code: "03442",
    Name: "Xã Pa ủ",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1486,
    Code: "03445",
    Name: "Xã Mường Tè",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1487,
    Code: "03448",
    Name: "Xã Pa Vệ Sử",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1488,
    Code: "03451",
    Name: "Xã Mù Cả",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1489,
    Code: "03454",
    Name: "Xã Bum Tở",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1490,
    Code: "03457",
    Name: "Xã Nậm Khao",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1491,
    Code: "03463",
    Name: "Xã Tà Tổng",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1492,
    Code: "03466",
    Name: "Xã Bum Nưa",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1493,
    Code: "03467",
    Name: "Xã Vàng San",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1494,
    Code: "03469",
    Name: "Xã Kan Hồ",
    LevelUp: "Xã",
    DistrictCode: "0705",
    Active: -1,
  },
  {
    ID: 1495,
    Code: "03478",
    Name: "Thị trấn Sìn Hồ",
    LevelUp: "Thị trấn",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1496,
    Code: "03487",
    Name: "Xã Chăn Nưa",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1497,
    Code: "03493",
    Name: "Xã Pa Tần",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1498,
    Code: "03496",
    Name: "Xã Phìn Hồ",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1499,
    Code: "03499",
    Name: "Xã Hồng Thu",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1500,
    Code: "03505",
    Name: "Xã Phăng Sô Lin",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1501,
    Code: "03508",
    Name: "Xã Ma Quai",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1502,
    Code: "03509",
    Name: "Xã Lùng Thàng",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1503,
    Code: "03511",
    Name: "Xã Tả Phìn",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1504,
    Code: "03514",
    Name: "Xã Sà Dề Phìn",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1505,
    Code: "03517",
    Name: "Xã Nậm Tăm",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1506,
    Code: "03520",
    Name: "Xã Tả Ngảo",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1507,
    Code: "03523",
    Name: "Xã Pu Sam Cáp",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1508,
    Code: "03526",
    Name: "Xã Nậm Cha",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1509,
    Code: "03527",
    Name: "Xã Pa Khoá",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1510,
    Code: "03529",
    Name: "Xã Làng Mô",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1511,
    Code: "03532",
    Name: "Xã Noong Hẻo",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1512,
    Code: "03535",
    Name: "Xã Nậm Mạ",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1513,
    Code: "03538",
    Name: "Xã Căn Co",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1514,
    Code: "03541",
    Name: "Xã Tủa Sín Chải",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1515,
    Code: "03544",
    Name: "Xã Nậm Cuổi",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1516,
    Code: "03547",
    Name: "Xã Nậm Hăn",
    LevelUp: "Xã",
    DistrictCode: "0704",
    Active: -1,
  },
  {
    ID: 1517,
    Code: "03391",
    Name: "Xã Lả Nhì Thàng",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1518,
    Code: "03490",
    Name: "Xã Huổi Luông",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1519,
    Code: "03549",
    Name: "Thị trấn Phong Thổ",
    LevelUp: "Thị trấn",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1520,
    Code: "03550",
    Name: "Xã Sì Lở Lầu",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1521,
    Code: "03553",
    Name: "Xã Mồ Sì San",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1522,
    Code: "03559",
    Name: "Xã Pa Vây Sử",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1523,
    Code: "03562",
    Name: "Xã Vàng Ma Chải",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1524,
    Code: "03565",
    Name: "Xã Tông Qua Lìn",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1525,
    Code: "03568",
    Name: "Xã Mù Sang",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1526,
    Code: "03571",
    Name: "Xã Dào San",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1527,
    Code: "03574",
    Name: "Xã Ma Ly Pho",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1528,
    Code: "03577",
    Name: "Xã Bản Lang",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1529,
    Code: "03580",
    Name: "Xã Hoang Thèn",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1530,
    Code: "03583",
    Name: "Xã Khổng Lào",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1531,
    Code: "03586",
    Name: "Xã Nậm Xe",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1532,
    Code: "03589",
    Name: "Xã Mường So",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1533,
    Code: "03592",
    Name: "Xã Sin Suối Hồ",
    LevelUp: "Xã",
    DistrictCode: "0703",
    Active: -1,
  },
  {
    ID: 1534,
    Code: "03595",
    Name: "Thị trấn Than Uyên",
    LevelUp: "Thị trấn",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1535,
    Code: "03618",
    Name: "Xã Phúc Than",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1536,
    Code: "03619",
    Name: "Xã Mường Than",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1537,
    Code: "03625",
    Name: "Xã Mường Mít",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1538,
    Code: "03628",
    Name: "Xã Pha Mu",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1539,
    Code: "03631",
    Name: "Xã Mường Cang",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1540,
    Code: "03632",
    Name: "Xã Hua Nà",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1541,
    Code: "03634",
    Name: "Xã Tà Hừa",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1542,
    Code: "03637",
    Name: "Xã Mường Kim",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1543,
    Code: "03638",
    Name: "Xã Tà Mung",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1544,
    Code: "03640",
    Name: "Xã Tà Gia",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1545,
    Code: "03643",
    Name: "Xã Khoen On",
    LevelUp: "Xã",
    DistrictCode: "0706",
    Active: -1,
  },
  {
    ID: 1546,
    Code: "03598",
    Name: "Thị trấn Tân Uyên",
    LevelUp: "Thị trấn",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1547,
    Code: "03601",
    Name: "Xã Mường Khoa",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1548,
    Code: "03602",
    Name: "Xã Phúc Khoa",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1549,
    Code: "03604",
    Name: "Xã Thân Thuộc",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1550,
    Code: "03605",
    Name: "Xã Trung Đồng",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1551,
    Code: "03607",
    Name: "Xã Hố Mít",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1552,
    Code: "03610",
    Name: "Xã Nậm Cần",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1553,
    Code: "03613",
    Name: "Xã Nậm Sỏ",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1554,
    Code: "03616",
    Name: "Xã Pắc Ta",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1555,
    Code: "03622",
    Name: "Xã Tà Mít",
    LevelUp: "Xã",
    DistrictCode: "0707",
    Active: -1,
  },
  {
    ID: 1556,
    Code: "03434",
    Name: "Thị trấn Nậm Nhùn",
    LevelUp: "Thị trấn",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1557,
    Code: "03460",
    Name: "Xã Hua Bun",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1558,
    Code: "03472",
    Name: "Xã Mường Mô",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1559,
    Code: "03473",
    Name: "Xã Nậm Chà",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1560,
    Code: "03474",
    Name: "Xã Nậm Manh",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1561,
    Code: "03475",
    Name: "Xã Nậm Hàng",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1562,
    Code: "03481",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1563,
    Code: "03484",
    Name: "Xã Pú Đao",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1564,
    Code: "03488",
    Name: "Xã Nậm Pì",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1565,
    Code: "03502",
    Name: "Xã Nậm Ban",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1566,
    Code: "03503",
    Name: "Xã Trung Chải",
    LevelUp: "Xã",
    DistrictCode: "0708",
    Active: -1,
  },
  {
    ID: 1567,
    Code: "03646",
    Name: "Phường Chiềng Lề",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1568,
    Code: "03649",
    Name: "Phường Tô Hiệu",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1569,
    Code: "03652",
    Name: "Phường Quyết Thắng",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1570,
    Code: "03655",
    Name: "Phường Quyết Tâm",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1571,
    Code: "03658",
    Name: "Xã Chiềng Cọ",
    LevelUp: "Xã",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1572,
    Code: "03661",
    Name: "Xã Chiềng Đen",
    LevelUp: "Xã",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1573,
    Code: "03664",
    Name: "Xã Chiềng Xôm",
    LevelUp: "Xã",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1574,
    Code: "03667",
    Name: "Phường Chiềng An",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1575,
    Code: "03670",
    Name: "Phường Chiềng Cơi",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1576,
    Code: "03673",
    Name: "Xã Chiềng Ngần",
    LevelUp: "Xã",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1577,
    Code: "03676",
    Name: "Xã Hua La",
    LevelUp: "Xã",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1578,
    Code: "03679",
    Name: "Phường Chiềng Sinh",
    LevelUp: "Phường",
    DistrictCode: "1401",
    Active: -1,
  },
  {
    ID: 1579,
    Code: "03682",
    Name: "Xã Mường Chiên",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1580,
    Code: "03685",
    Name: "Xã Cà Nàng",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1581,
    Code: "03688",
    Name: "Xã Chiềng Khay",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1582,
    Code: "03694",
    Name: "Xã Mường Giôn",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1583,
    Code: "03697",
    Name: "Xã Pá Ma Pha Khinh",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1584,
    Code: "03700",
    Name: "Xã Chiềng Ơn",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1585,
    Code: "03703",
    Name: "Xã Mường Giàng",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1586,
    Code: "03706",
    Name: "Xã Chiềng Bằng",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1587,
    Code: "03709",
    Name: "Xã Mường Sại",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1588,
    Code: "03712",
    Name: "Xã Nậm ét",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1589,
    Code: "03718",
    Name: "Xã Chiềng Khoang",
    LevelUp: "Xã",
    DistrictCode: "1402",
    Active: -1,
  },
  {
    ID: 1590,
    Code: "03721",
    Name: "Thị trấn Thuận Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1591,
    Code: "03724",
    Name: "Xã Phổng Lái",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1592,
    Code: "03727",
    Name: "Xã Mường é",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1593,
    Code: "03730",
    Name: "Xã Chiềng Pha",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1594,
    Code: "03733",
    Name: "Xã Chiềng La",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1595,
    Code: "03736",
    Name: "Xã Chiềng Ngàm",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1596,
    Code: "03739",
    Name: "Xã Liệp Tè",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1597,
    Code: "03742",
    Name: "Xã é Tòng",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1598,
    Code: "03745",
    Name: "Xã Phổng Lập",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1599,
    Code: "03748",
    Name: "Xã Phổng Lăng",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1600,
    Code: "03751",
    Name: "Xã Chiềng Ly",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1601,
    Code: "03754",
    Name: "Xã Noong Lay",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1602,
    Code: "03757",
    Name: "Xã Mường Khiêng",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1603,
    Code: "03760",
    Name: "Xã Mường Bám",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1604,
    Code: "03763",
    Name: "Xã Long Hẹ",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1605,
    Code: "03766",
    Name: "Xã Chiềng Bôm",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1606,
    Code: "03769",
    Name: "Xã Thôm Mòn",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1607,
    Code: "03772",
    Name: "Xã Tông Lạnh",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1608,
    Code: "03775",
    Name: "Xã Tông Cọ",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1609,
    Code: "03778",
    Name: "Xã Bó Mười",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1610,
    Code: "03781",
    Name: "Xã Co Mạ",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1611,
    Code: "03784",
    Name: "Xã Púng Tra",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1612,
    Code: "03787",
    Name: "Xã Chiềng Pấc",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1613,
    Code: "03790",
    Name: "Xã Nậm Lầu",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1614,
    Code: "03793",
    Name: "Xã Bon Phặng",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1615,
    Code: "03796",
    Name: "Xã Co Tòng",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1616,
    Code: "03799",
    Name: "Xã Muổi Nọi",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1617,
    Code: "03802",
    Name: "Xã Pá Lông",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1618,
    Code: "03805",
    Name: "Xã Bản Lầm",
    LevelUp: "Xã",
    DistrictCode: "1404",
    Active: -1,
  },
  {
    ID: 1619,
    Code: "03808",
    Name: "Thị trấn Ít Ong",
    LevelUp: "Thị trấn",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1620,
    Code: "03811",
    Name: "Xã Nậm Giôn",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1621,
    Code: "03814",
    Name: "Xã Chiềng Lao",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1622,
    Code: "03817",
    Name: "Xã Hua Trai",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1623,
    Code: "03820",
    Name: "Xã Ngọc Chiến",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1624,
    Code: "03823",
    Name: "Xã Mường Trai",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1625,
    Code: "03826",
    Name: "Xã Nậm Păm",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1626,
    Code: "03829",
    Name: "Xã Chiềng Muôn",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1627,
    Code: "03832",
    Name: "Xã Chiềng Ân",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1628,
    Code: "03835",
    Name: "Xã Pi Toong",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1629,
    Code: "03838",
    Name: "Xã Chiềng Công",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1630,
    Code: "03841",
    Name: "Xã Tạ Bú",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1631,
    Code: "03844",
    Name: "Xã Chiềng San",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1632,
    Code: "03847",
    Name: "Xã Mường Bú",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1633,
    Code: "03850",
    Name: "Xã Chiềng Hoa",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1634,
    Code: "03853",
    Name: "Xã Mường Chùm",
    LevelUp: "Xã",
    DistrictCode: "1403",
    Active: -1,
  },
  {
    ID: 1635,
    Code: "03856",
    Name: "Thị trấn Bắc Yên",
    LevelUp: "Thị trấn",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1636,
    Code: "03859",
    Name: "Xã Phiêng Ban",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1637,
    Code: "03862",
    Name: "Xã Hang Chú",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1638,
    Code: "03865",
    Name: "Xã Xím Vàng",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1639,
    Code: "03868",
    Name: "Xã Tà Xùa",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1640,
    Code: "03869",
    Name: "Xã Háng Đồng",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1641,
    Code: "03871",
    Name: "Xã Pắc Ngà",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1642,
    Code: "03874",
    Name: "Xã Làng Chếu",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1643,
    Code: "03877",
    Name: "Xã Chim Vàn",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1644,
    Code: "03880",
    Name: "Xã Mường Khoa",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1645,
    Code: "03883",
    Name: "Xã Song Pe",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1646,
    Code: "03886",
    Name: "Xã Hồng Ngài",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1647,
    Code: "03889",
    Name: "Xã Tạ Khoa",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1648,
    Code: "03890",
    Name: "Xã Hua Nhàn",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1649,
    Code: "03892",
    Name: "Xã Phiêng Côn",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1650,
    Code: "03895",
    Name: "Xã Chiềng Sại",
    LevelUp: "Xã",
    DistrictCode: "1405",
    Active: -1,
  },
  {
    ID: 1651,
    Code: "03898",
    Name: "Thị trấn Phù Yên",
    LevelUp: "Thị trấn",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1652,
    Code: "03901",
    Name: "Xã Suối Tọ",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1653,
    Code: "03904",
    Name: "Xã Mường Thải",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1654,
    Code: "03907",
    Name: "Xã Mường Cơi",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1655,
    Code: "03910",
    Name: "Xã Quang Huy",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1656,
    Code: "03913",
    Name: "Xã Huy Bắc",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1657,
    Code: "03916",
    Name: "Xã Huy Thượng",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1658,
    Code: "03919",
    Name: "Xã Tân Lang",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1659,
    Code: "03922",
    Name: "Xã Gia Phù",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1660,
    Code: "03925",
    Name: "Xã Tường Phù",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1661,
    Code: "03928",
    Name: "Xã Huy Hạ",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1662,
    Code: "03931",
    Name: "Xã Huy Tân",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1663,
    Code: "03934",
    Name: "Xã Mường Lang",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1664,
    Code: "03937",
    Name: "Xã Suối Bau",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1665,
    Code: "03940",
    Name: "Xã Huy Tường",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1666,
    Code: "03943",
    Name: "Xã Mường Do",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1667,
    Code: "03946",
    Name: "Xã Sập Xa",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1668,
    Code: "03949",
    Name: "Xã Tường Thượng",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1669,
    Code: "03952",
    Name: "Xã Tường Tiến",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1670,
    Code: "03955",
    Name: "Xã Tường Phong",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1671,
    Code: "03958",
    Name: "Xã Tường Hạ",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1672,
    Code: "03961",
    Name: "Xã Kim Bon",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1673,
    Code: "03964",
    Name: "Xã Mường Bang",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1674,
    Code: "03967",
    Name: "Xã Đá Đỏ",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1675,
    Code: "03970",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1676,
    Code: "03973",
    Name: "Xã Nam Phong",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1677,
    Code: "03976",
    Name: "Xã Bắc Phong",
    LevelUp: "Xã",
    DistrictCode: "1406",
    Active: -1,
  },
  {
    ID: 1678,
    Code: "03979",
    Name: "Thị trấn Mộc Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1679,
    Code: "03982",
    Name: "Thị trấn NT Mộc Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1680,
    Code: "03985",
    Name: "Xã Chiềng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1681,
    Code: "03988",
    Name: "Xã Tân Hợp",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1682,
    Code: "03991",
    Name: "Xã Qui Hướng",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1683,
    Code: "03997",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1684,
    Code: "04000",
    Name: "Xã Nà Mường",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1685,
    Code: "04003",
    Name: "Xã Tà Lai",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1686,
    Code: "04012",
    Name: "Xã Chiềng Hắc",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1687,
    Code: "04015",
    Name: "Xã Hua Păng",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1688,
    Code: "04024",
    Name: "Xã Chiềng Khừa",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1689,
    Code: "04027",
    Name: "Xã Mường Sang",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1690,
    Code: "04030",
    Name: "Xã Đông Sang",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1691,
    Code: "04033",
    Name: "Xã Phiêng Luông",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1692,
    Code: "04045",
    Name: "Xã Lóng Sập",
    LevelUp: "Xã",
    DistrictCode: "1410",
    Active: -1,
  },
  {
    ID: 1693,
    Code: "04060",
    Name: "Thị trấn Yên Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1694,
    Code: "04063",
    Name: "Xã Chiềng Đông",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1695,
    Code: "04066",
    Name: "Xã Sập Vạt",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1696,
    Code: "04069",
    Name: "Xã Chiềng Sàng",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1697,
    Code: "04072",
    Name: "Xã Chiềng Pằn",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1698,
    Code: "04075",
    Name: "Xã Viêng Lán",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1699,
    Code: "04078",
    Name: "Xã Chiềng Hặc",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1700,
    Code: "04081",
    Name: "Xã Mường Lựm",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1701,
    Code: "04084",
    Name: "Xã Chiềng On",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1702,
    Code: "04087",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1703,
    Code: "04090",
    Name: "Xã Chiềng Khoi",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1704,
    Code: "04093",
    Name: "Xã Tú Nang",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1705,
    Code: "04096",
    Name: "Xã Lóng Phiêng",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1706,
    Code: "04099",
    Name: "Xã Phiêng Khoài",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1707,
    Code: "04102",
    Name: "Xã Chiềng Tương",
    LevelUp: "Xã",
    DistrictCode: "1408",
    Active: -1,
  },
  {
    ID: 1708,
    Code: "04105",
    Name: "Thị trấn Hát Lót",
    LevelUp: "Thị trấn",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1709,
    Code: "04108",
    Name: "Xã Chiềng Sung",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1710,
    Code: "04111",
    Name: "Xã Mường Bằng",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1711,
    Code: "04114",
    Name: "Xã Chiềng Chăn",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1712,
    Code: "04117",
    Name: "Xã Mương Chanh",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1713,
    Code: "04120",
    Name: "Xã Chiềng Ban",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1714,
    Code: "04123",
    Name: "Xã Chiềng Mung",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1715,
    Code: "04126",
    Name: "Xã Mường Bon",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1716,
    Code: "04129",
    Name: "Xã Chiềng Chung",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1717,
    Code: "04132",
    Name: "Xã Chiềng Mai",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1718,
    Code: "04135",
    Name: "Xã Hát Lót",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1719,
    Code: "04136",
    Name: "Xã Nà Pó",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1720,
    Code: "04138",
    Name: "Xã Cò  Nòi",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1721,
    Code: "04141",
    Name: "Xã Chiềng Nơi",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1722,
    Code: "04144",
    Name: "Xã Phiêng Cằm",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1723,
    Code: "04147",
    Name: "Xã Chiềng Dong",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1724,
    Code: "04150",
    Name: "Xã Chiềng Kheo",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1725,
    Code: "04153",
    Name: "Xã Chiềng Ve",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1726,
    Code: "04156",
    Name: "Xã Chiềng Lương",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1727,
    Code: "04159",
    Name: "Xã Phiêng Pằn",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1728,
    Code: "04162",
    Name: "Xã Nà Ơt",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1729,
    Code: "04165",
    Name: "Xã Tà Hộc",
    LevelUp: "Xã",
    DistrictCode: "1407",
    Active: -1,
  },
  {
    ID: 1730,
    Code: "04168",
    Name: "Thị trấn Sông Mã",
    LevelUp: "Thị trấn",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1731,
    Code: "04171",
    Name: "Xã Bó Sinh",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1732,
    Code: "04174",
    Name: "Xã Pú Pẩu",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1733,
    Code: "04177",
    Name: "Xã Chiềng Phung",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1734,
    Code: "04180",
    Name: "Xã Chiềng En",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1735,
    Code: "04183",
    Name: "Xã Mường Lầm",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1736,
    Code: "04186",
    Name: "Xã Nậm Ty",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1737,
    Code: "04189",
    Name: "Xã Đứa Mòn",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1738,
    Code: "04192",
    Name: "Xã Yên Hưng",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1739,
    Code: "04195",
    Name: "Xã Chiềng Sơ",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1740,
    Code: "04198",
    Name: "Xã Nà Nghịu",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1741,
    Code: "04201",
    Name: "Xã Nậm Mằn",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1742,
    Code: "04204",
    Name: "Xã Chiềng Khoong",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1743,
    Code: "04207",
    Name: "Xã Chiềng Cang",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1744,
    Code: "04210",
    Name: "Xã Huổi Một",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1745,
    Code: "04213",
    Name: "Xã Mường Sai",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1746,
    Code: "04216",
    Name: "Xã Mường Cai",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1747,
    Code: "04219",
    Name: "Xã Mường Hung",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1748,
    Code: "04222",
    Name: "Xã Chiềng Khương",
    LevelUp: "Xã",
    DistrictCode: "1409",
    Active: -1,
  },
  {
    ID: 1749,
    Code: "04225",
    Name: "Xã Sam Kha",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1750,
    Code: "04228",
    Name: "Xã Púng Bánh",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1751,
    Code: "04231",
    Name: "Xã Sốp Cộp",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1752,
    Code: "04234",
    Name: "Xã Dồm Cang",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1753,
    Code: "04237",
    Name: "Xã Nậm Lạnh",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1754,
    Code: "04240",
    Name: "Xã Mường Lèo",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1755,
    Code: "04243",
    Name: "Xã Mường Và",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1756,
    Code: "04246",
    Name: "Xã Mường Lạn",
    LevelUp: "Xã",
    DistrictCode: "1411",
    Active: -1,
  },
  {
    ID: 1757,
    Code: "03994",
    Name: "Xã Suối Bàng",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1758,
    Code: "04006",
    Name: "Xã Song Khủa",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1759,
    Code: "04009",
    Name: "Xã Liên Hoà",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1760,
    Code: "04018",
    Name: "Xã Tô Múa",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1761,
    Code: "04021",
    Name: "Xã Mường Tè",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1762,
    Code: "04036",
    Name: "Xã Chiềng Khoa",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1763,
    Code: "04039",
    Name: "Xã Mường Men",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1764,
    Code: "04042",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1765,
    Code: "04048",
    Name: "Xã Vân Hồ",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1766,
    Code: "04051",
    Name: "Xã Lóng Luông",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1767,
    Code: "04054",
    Name: "Xã Chiềng Yên",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1768,
    Code: "04056",
    Name: "Xã Chiềng Xuân",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1769,
    Code: "04057",
    Name: "Xã Xuân Nha",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1770,
    Code: "04058",
    Name: "Xã Tân Xuân",
    LevelUp: "Xã",
    DistrictCode: "1412",
    Active: -1,
  },
  {
    ID: 1771,
    Code: "04249",
    Name: "Phường Yên Thịnh",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1772,
    Code: "04252",
    Name: "Phường Yên Ninh",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1773,
    Code: "04255",
    Name: "Phường Minh Tân",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1774,
    Code: "04258",
    Name: "Phường Nguyễn Thái Học",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1775,
    Code: "04261",
    Name: "Phường Đồng Tâm",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1776,
    Code: "04264",
    Name: "Phường Nguyễn Phúc",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1777,
    Code: "04267",
    Name: "Phường Hồng Hà",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1778,
    Code: "04270",
    Name: "Xã Minh Bảo",
    LevelUp: "Xã",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1779,
    Code: "04273",
    Name: "Phường Nam Cường",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1780,
    Code: "04276",
    Name: "Xã Tuy Lộc",
    LevelUp: "Xã",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1781,
    Code: "04279",
    Name: "Xã Tân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1782,
    Code: "04540",
    Name: "Xã Âu Lâu",
    LevelUp: "Xã",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1783,
    Code: "04543",
    Name: "Xã Giới Phiên",
    LevelUp: "Xã",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1784,
    Code: "04546",
    Name: "Phường Hợp Minh",
    LevelUp: "Phường",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1785,
    Code: "04558",
    Name: "Xã Văn Phú",
    LevelUp: "Xã",
    DistrictCode: "1301",
    Active: -1,
  },
  {
    ID: 1786,
    Code: "04282",
    Name: "Phường Pú Trạng",
    LevelUp: "Phường",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1787,
    Code: "04285",
    Name: "Phường Trung Tâm",
    LevelUp: "Phường",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1788,
    Code: "04288",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1789,
    Code: "04291",
    Name: "Phường Cầu Thia",
    LevelUp: "Phường",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1790,
    Code: "04294",
    Name: "Xã Nghĩa Lợi",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1791,
    Code: "04297",
    Name: "Xã Nghĩa Phúc",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1792,
    Code: "04300",
    Name: "Xã Nghĩa An",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1793,
    Code: "04624",
    Name: "Xã Nghĩa Lộ",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1794,
    Code: "04660",
    Name: "Xã Sơn A",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1795,
    Code: "04663",
    Name: "Xã Phù Nham",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1796,
    Code: "04675",
    Name: "Xã Thanh Lương",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1797,
    Code: "04678",
    Name: "Xã Hạnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1798,
    Code: "04681",
    Name: "Xã Phúc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1799,
    Code: "04684",
    Name: "Xã Thạch Lương",
    LevelUp: "Xã",
    DistrictCode: "1302",
    Active: -1,
  },
  {
    ID: 1800,
    Code: "04303",
    Name: "Thị trấn Yên Thế",
    LevelUp: "Thị trấn",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1801,
    Code: "04306",
    Name: "Xã Tân Phượng",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1802,
    Code: "04309",
    Name: "Xã Lâm Thượng",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1803,
    Code: "04312",
    Name: "Xã Khánh Thiện",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1804,
    Code: "04315",
    Name: "Xã Minh Chuẩn",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1805,
    Code: "04318",
    Name: "Xã Mai Sơn",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1806,
    Code: "04321",
    Name: "Xã Khai Trung",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1807,
    Code: "04324",
    Name: "Xã Mường Lai",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1808,
    Code: "04327",
    Name: "Xã An Lạc",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1809,
    Code: "04330",
    Name: "Xã Minh Xuân",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1810,
    Code: "04333",
    Name: "Xã Tô Mậu",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1811,
    Code: "04336",
    Name: "Xã Tân Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1812,
    Code: "04339",
    Name: "Xã Yên Thắng",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1813,
    Code: "04342",
    Name: "Xã Khánh Hoà",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1814,
    Code: "04345",
    Name: "Xã Vĩnh Lạc",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1815,
    Code: "04348",
    Name: "Xã Liễu Đô",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1816,
    Code: "04351",
    Name: "Xã Động Quan",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1817,
    Code: "04354",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1818,
    Code: "04357",
    Name: "Xã Minh Tiến",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1819,
    Code: "04360",
    Name: "Xã Trúc Lâu",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1820,
    Code: "04363",
    Name: "Xã Phúc Lợi",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1821,
    Code: "04366",
    Name: "Xã Phan Thanh",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1822,
    Code: "04369",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1823,
    Code: "04372",
    Name: "Xã Trung Tâm",
    LevelUp: "Xã",
    DistrictCode: "1309",
    Active: -1,
  },
  {
    ID: 1824,
    Code: "04375",
    Name: "Thị trấn Mậu A",
    LevelUp: "Thị trấn",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1825,
    Code: "04378",
    Name: "Xã Lang Thíp",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1826,
    Code: "04381",
    Name: "Xã Lâm Giang",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1827,
    Code: "04384",
    Name: "Xã Châu Quế Thượng",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1828,
    Code: "04387",
    Name: "Xã Châu Quế Hạ",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1829,
    Code: "04390",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1830,
    Code: "04393",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1831,
    Code: "04396",
    Name: "Xã Đông An",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1832,
    Code: "04399",
    Name: "Xã Đông Cuông",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1833,
    Code: "04402",
    Name: "Xã Phong Dụ Hạ",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1834,
    Code: "04405",
    Name: "Xã Mậu Đông",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1835,
    Code: "04408",
    Name: "Xã Ngòi A",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1836,
    Code: "04411",
    Name: "Xã Xuân Tầm",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1837,
    Code: "04414",
    Name: "Xã Tân Hợp",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1838,
    Code: "04417",
    Name: "Xã An Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1839,
    Code: "04420",
    Name: "Xã Yên Thái",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1840,
    Code: "04423",
    Name: "Xã Phong Dụ Thượng",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1841,
    Code: "04426",
    Name: "Xã Yên Hợp",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1842,
    Code: "04429",
    Name: "Xã Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1843,
    Code: "04435",
    Name: "Xã Đại Phác",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1844,
    Code: "04438",
    Name: "Xã Yên Phú",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1845,
    Code: "04441",
    Name: "Xã Xuân Ái",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1846,
    Code: "04447",
    Name: "Xã Viễn Sơn",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1847,
    Code: "04450",
    Name: "Xã Mỏ Vàng",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1848,
    Code: "04453",
    Name: "Xã Nà Hẩu",
    LevelUp: "Xã",
    DistrictCode: "1303",
    Active: -1,
  },
  {
    ID: 1849,
    Code: "04456",
    Name: "Thị trấn Mù Căng Chải",
    LevelUp: "Thị trấn",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1850,
    Code: "04459",
    Name: "Xã Hồ Bốn",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1851,
    Code: "04462",
    Name: "Xã Nậm Có",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1852,
    Code: "04465",
    Name: "Xã Khao Mang",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1853,
    Code: "04468",
    Name: "Xã Mồ Dề",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1854,
    Code: "04471",
    Name: "Xã Chế Cu Nha",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1855,
    Code: "04474",
    Name: "Xã Lao Chải",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1856,
    Code: "04477",
    Name: "Xã Kim Nọi",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1857,
    Code: "04480",
    Name: "Xã Cao Phạ",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1858,
    Code: "04483",
    Name: "Xã La Pán Tẩn",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1859,
    Code: "04486",
    Name: "Xã Dế Su Phình",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1860,
    Code: "04489",
    Name: "Xã Chế Tạo",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1861,
    Code: "04492",
    Name: "Xã Púng Luông",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1862,
    Code: "04495",
    Name: "Xã Nậm Khắt",
    LevelUp: "Xã",
    DistrictCode: "1305",
    Active: -1,
  },
  {
    ID: 1863,
    Code: "04498",
    Name: "Thị trấn Cổ Phúc",
    LevelUp: "Thị trấn",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1864,
    Code: "04501",
    Name: "Xã Tân Đồng",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1865,
    Code: "04504",
    Name: "Xã Báo Đáp",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1866,
    Code: "04507",
    Name: "Xã Đào Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1867,
    Code: "04510",
    Name: "Xã Việt Thành",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1868,
    Code: "04513",
    Name: "Xã Hòa Cuông",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1869,
    Code: "04516",
    Name: "Xã Minh Quán",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1870,
    Code: "04519",
    Name: "Xã Quy Mông",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1871,
    Code: "04522",
    Name: "Xã Cường Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1872,
    Code: "04525",
    Name: "Xã Kiên Thành",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1873,
    Code: "04528",
    Name: "Xã Nga Quán",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1874,
    Code: "04531",
    Name: "Xã Y Can",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1875,
    Code: "04537",
    Name: "Xã Lương Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1876,
    Code: "04561",
    Name: "Xã Bảo Hưng",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1877,
    Code: "04564",
    Name: "Xã Việt Cường",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1878,
    Code: "04567",
    Name: "Xã Minh Quân",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1879,
    Code: "04570",
    Name: "Xã Hồng Ca",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1880,
    Code: "04573",
    Name: "Xã Hưng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1881,
    Code: "04576",
    Name: "Xã Hưng Khánh",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1882,
    Code: "04579",
    Name: "Xã Việt Hồng",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1883,
    Code: "04582",
    Name: "Xã Vân Hội",
    LevelUp: "Xã",
    DistrictCode: "1307",
    Active: -1,
  },
  {
    ID: 1884,
    Code: "04585",
    Name: "Thị trấn Trạm Tấu",
    LevelUp: "Thị trấn",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1885,
    Code: "04588",
    Name: "Xã Túc Đán",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1886,
    Code: "04591",
    Name: "Xã Pá Lau",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1887,
    Code: "04594",
    Name: "Xã Xà Hồ",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1888,
    Code: "04597",
    Name: "Xã Phình Hồ",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1889,
    Code: "04600",
    Name: "Xã Trạm Tấu",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1890,
    Code: "04603",
    Name: "Xã Tà Si Láng",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1891,
    Code: "04606",
    Name: "Xã Pá Hu",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1892,
    Code: "04609",
    Name: "Xã Làng Nhì",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1893,
    Code: "04612",
    Name: "Xã Bản Công",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1894,
    Code: "04615",
    Name: "Xã Bản Mù",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1895,
    Code: "04618",
    Name: "Xã Hát Lìu",
    LevelUp: "Xã",
    DistrictCode: "1308",
    Active: -1,
  },
  {
    ID: 1896,
    Code: "04621",
    Name: "Thị trấn NT Liên Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1897,
    Code: "04627",
    Name: "Thị trấn NT Trần Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1898,
    Code: "04630",
    Name: "Xã Tú Lệ",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1899,
    Code: "04633",
    Name: "Xã Nậm Búng",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1900,
    Code: "04636",
    Name: "Xã Gia Hội",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1901,
    Code: "04639",
    Name: "Xã Sùng Đô",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1902,
    Code: "04642",
    Name: "Xã Nậm Mười",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1903,
    Code: "04645",
    Name: "Xã An Lương",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1904,
    Code: "04648",
    Name: "Xã Nậm Lành",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1905,
    Code: "04651",
    Name: "Xã Sơn Lương",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1906,
    Code: "04654",
    Name: "Xã Suối Quyền",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1907,
    Code: "04657",
    Name: "Xã Suối Giàng",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1908,
    Code: "04666",
    Name: "Xã Nghĩa Sơn",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1909,
    Code: "04669",
    Name: "Xã Suối Bu",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1910,
    Code: "04672",
    Name: "Thị trấn Sơn Thịnh",
    LevelUp: "Thị trấn",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1911,
    Code: "04687",
    Name: "Xã Đại Lịch",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1912,
    Code: "04690",
    Name: "Xã Đồng Khê",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1913,
    Code: "04693",
    Name: "Xã Cát Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1914,
    Code: "04696",
    Name: "Xã Tân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1915,
    Code: "04699",
    Name: "Xã Chấn Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1916,
    Code: "04702",
    Name: "Xã Bình Thuận",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1917,
    Code: "04705",
    Name: "Xã Thượng Bằng La",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1918,
    Code: "04708",
    Name: "Xã Minh An",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1919,
    Code: "04711",
    Name: "Xã Nghĩa Tâm",
    LevelUp: "Xã",
    DistrictCode: "1306",
    Active: -1,
  },
  {
    ID: 1920,
    Code: "04714",
    Name: "Thị trấn Yên Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1921,
    Code: "04717",
    Name: "Thị trấn Thác Bà",
    LevelUp: "Thị trấn",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1922,
    Code: "04720",
    Name: "Xã Xuân Long",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1923,
    Code: "04726",
    Name: "Xã Cảm Nhân",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1924,
    Code: "04729",
    Name: "Xã Ngọc Chấn",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1925,
    Code: "04732",
    Name: "Xã Tân Nguyên",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1926,
    Code: "04735",
    Name: "Xã Phúc Ninh",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1927,
    Code: "04738",
    Name: "Xã Bảo Ái",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1928,
    Code: "04741",
    Name: "Xã Mỹ Gia",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1929,
    Code: "04744",
    Name: "Xã Xuân Lai",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1930,
    Code: "04747",
    Name: "Xã Mông Sơn",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1931,
    Code: "04750",
    Name: "Xã Cảm Ân",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1932,
    Code: "04753",
    Name: "Xã Yên Thành",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1933,
    Code: "04756",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1934,
    Code: "04759",
    Name: "Xã Phúc An",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1935,
    Code: "04762",
    Name: "Xã Bạch Hà",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1936,
    Code: "04765",
    Name: "Xã Vũ Linh",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1937,
    Code: "04768",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1938,
    Code: "04771",
    Name: "Xã Vĩnh Kiên",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1939,
    Code: "04774",
    Name: "Xã Yên Bình",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1940,
    Code: "04777",
    Name: "Xã Thịnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1941,
    Code: "04780",
    Name: "Xã Hán Đà",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1942,
    Code: "04783",
    Name: "Xã Phú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1943,
    Code: "04786",
    Name: "Xã Đại Minh",
    LevelUp: "Xã",
    DistrictCode: "1304",
    Active: -1,
  },
  {
    ID: 1944,
    Code: "04789",
    Name: "Phường Thái Bình",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1945,
    Code: "04792",
    Name: "Phường Tân Hòa",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1946,
    Code: "04795",
    Name: "Phường Thịnh Lang",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1947,
    Code: "04798",
    Name: "Phường Hữu Nghị",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1948,
    Code: "04801",
    Name: "Phường Tân Thịnh",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1949,
    Code: "04804",
    Name: "Phường Đồng Tiến",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1950,
    Code: "04807",
    Name: "Phường Phương Lâm",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1951,
    Code: "04813",
    Name: "Xã Yên Mông",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1952,
    Code: "04816",
    Name: "Xã Sủ Ngòi",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1953,
    Code: "04819",
    Name: "Phường Dân Chủ",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1954,
    Code: "04825",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1955,
    Code: "04828",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1956,
    Code: "04894",
    Name: "Phường Kỳ Sơn",
    LevelUp: "Phường",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1957,
    Code: "04897",
    Name: "Xã Thịnh Minh",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1958,
    Code: "04903",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1959,
    Code: "04906",
    Name: "Xã Quang Tiến",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1960,
    Code: "04912",
    Name: "Xã Mông Hóa",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1961,
    Code: "04918",
    Name: "Xã Trung Minh",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1962,
    Code: "04921",
    Name: "Xã Độc Lập",
    LevelUp: "Xã",
    DistrictCode: "2301",
    Active: -1,
  },
  {
    ID: 1963,
    Code: "04831",
    Name: "Thị trấn Đà Bắc",
    LevelUp: "Thị trấn",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1964,
    Code: "04834",
    Name: "Xã Nánh Nghê",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1965,
    Code: "04840",
    Name: "Xã Giáp Đắt",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1966,
    Code: "04846",
    Name: "Xã Mường Chiềng",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1967,
    Code: "04849",
    Name: "Xã Tân Pheo",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1968,
    Code: "04852",
    Name: "Xã Đồng Chum",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1969,
    Code: "04855",
    Name: "Xã Tân Minh",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1970,
    Code: "04858",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1971,
    Code: "04861",
    Name: "Xã Đồng Ruộng",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1972,
    Code: "04867",
    Name: "Xã Tú Lý",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1973,
    Code: "04870",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1974,
    Code: "04873",
    Name: "Xã Yên Hòa",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1975,
    Code: "04876",
    Name: "Xã Cao Sơn",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1976,
    Code: "04879",
    Name: "Xã Toàn Sơn",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1977,
    Code: "04885",
    Name: "Xã Hiền Lương",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1978,
    Code: "04888",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1979,
    Code: "04891",
    Name: "Xã Vầy Nưa",
    LevelUp: "Xã",
    DistrictCode: "2302",
    Active: -1,
  },
  {
    ID: 1980,
    Code: "04924",
    Name: "Thị trấn Lương Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1981,
    Code: "04942",
    Name: "Xã Lâm Sơn",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1982,
    Code: "04945",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1983,
    Code: "04951",
    Name: "Xã Tân Vinh",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1984,
    Code: "04954",
    Name: "Xã Nhuận Trạch",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1985,
    Code: "04957",
    Name: "Xã Cao Sơn",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1986,
    Code: "04960",
    Name: "Xã Cư Yên",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1987,
    Code: "04969",
    Name: "Xã Liên Sơn",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1988,
    Code: "05008",
    Name: "Xã Cao Dương",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1989,
    Code: "05041",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1990,
    Code: "05047",
    Name: "Xã Thanh Cao",
    LevelUp: "Xã",
    DistrictCode: "2307",
    Active: -1,
  },
  {
    ID: 1991,
    Code: "04978",
    Name: "Thị trấn Bo",
    LevelUp: "Thị trấn",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1992,
    Code: "04984",
    Name: "Xã Đú Sáng",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1993,
    Code: "04987",
    Name: "Xã Hùng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1994,
    Code: "04990",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1995,
    Code: "04999",
    Name: "Xã Tú Sơn",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1996,
    Code: "05005",
    Name: "Xã Vĩnh Tiến",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1997,
    Code: "05014",
    Name: "Xã Đông Bắc",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1998,
    Code: "05017",
    Name: "Xã Xuân Thủy",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 1999,
    Code: "05026",
    Name: "Xã Vĩnh Đồng",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2000,
    Code: "05035",
    Name: "Xã Kim Lập",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2001,
    Code: "05038",
    Name: "Xã Hợp Tiến",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2002,
    Code: "05065",
    Name: "Xã Kim Bôi",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2003,
    Code: "05068",
    Name: "Xã Nam Thượng",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2004,
    Code: "05077",
    Name: "Xã Cuối Hạ",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2005,
    Code: "05080",
    Name: "Xã Sào Báy",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2006,
    Code: "05083",
    Name: "Xã Mi Hòa",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2007,
    Code: "05086",
    Name: "Xã Nuông Dăm",
    LevelUp: "Xã",
    DistrictCode: "2308",
    Active: -1,
  },
  {
    ID: 2008,
    Code: "05089",
    Name: "Thị trấn Cao Phong",
    LevelUp: "Thị trấn",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2009,
    Code: "05092",
    Name: "Xã Bình Thanh",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2010,
    Code: "05095",
    Name: "Xã Thung Nai",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2011,
    Code: "05098",
    Name: "Xã Bắc Phong",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2012,
    Code: "05101",
    Name: "Xã Thu Phong",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2013,
    Code: "05104",
    Name: "Xã Hợp Phong",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2014,
    Code: "05110",
    Name: "Xã Tây Phong",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2015,
    Code: "05116",
    Name: "Xã Dũng Phong",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2016,
    Code: "05119",
    Name: "Xã Nam Phong",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2017,
    Code: "05125",
    Name: "Xã Thạch Yên",
    LevelUp: "Xã",
    DistrictCode: "2311",
    Active: -1,
  },
  {
    ID: 2018,
    Code: "05128",
    Name: "Thị trấn Mãn Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2019,
    Code: "05134",
    Name: "Xã Suối Hoa",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2020,
    Code: "05137",
    Name: "Xã Phú Vinh",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2021,
    Code: "05140",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2022,
    Code: "05143",
    Name: "Xã Mỹ Hòa",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2023,
    Code: "05152",
    Name: "Xã Quyết Chiến",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2024,
    Code: "05158",
    Name: "Xã Phong Phú",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2025,
    Code: "05164",
    Name: "Xã Tử Nê",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2026,
    Code: "05167",
    Name: "Xã Thanh Hối",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2027,
    Code: "05170",
    Name: "Xã Ngọc Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2028,
    Code: "05173",
    Name: "Xã Đông Lai",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2029,
    Code: "05176",
    Name: "Xã Vân Sơn",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2030,
    Code: "05182",
    Name: "Xã Nhân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2031,
    Code: "05191",
    Name: "Xã Lỗ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2032,
    Code: "05194",
    Name: "Xã Ngổ Luông",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2033,
    Code: "05197",
    Name: "Xã Gia Mô",
    LevelUp: "Xã",
    DistrictCode: "2304",
    Active: -1,
  },
  {
    ID: 2034,
    Code: "04882",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2035,
    Code: "05200",
    Name: "Thị trấn Mai Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2036,
    Code: "05206",
    Name: "Xã Sơn Thủy",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2037,
    Code: "05209",
    Name: "Xã Pà Cò",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2038,
    Code: "05212",
    Name: "Xã Hang Kia",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2039,
    Code: "05221",
    Name: "Xã Đồng Tân",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2040,
    Code: "05224",
    Name: "Xã Cun Pheo",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2041,
    Code: "05227",
    Name: "Xã Bao La",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2042,
    Code: "05233",
    Name: "Xã Tòng Đậu",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2043,
    Code: "05242",
    Name: "Xã Nà Phòn",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2044,
    Code: "05245",
    Name: "Xã Săm Khóe",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2045,
    Code: "05248",
    Name: "Xã Chiềng Châu",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2046,
    Code: "05251",
    Name: "Xã Mai Hạ",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2047,
    Code: "05254",
    Name: "Xã Thành Sơn",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2048,
    Code: "05257",
    Name: "Xã Mai Hịch",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2049,
    Code: "05263",
    Name: "Xã Vạn Mai",
    LevelUp: "Xã",
    DistrictCode: "2303",
    Active: -1,
  },
  {
    ID: 2050,
    Code: "05266",
    Name: "Thị trấn Vụ Bản",
    LevelUp: "Thị trấn",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2051,
    Code: "05269",
    Name: "Xã Quý Hòa",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2052,
    Code: "05272",
    Name: "Xã Miền Đồi",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2053,
    Code: "05275",
    Name: "Xã Mỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2054,
    Code: "05278",
    Name: "Xã Tuân Đạo",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2055,
    Code: "05281",
    Name: "Xã Văn Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2056,
    Code: "05284",
    Name: "Xã Văn Sơn",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2057,
    Code: "05287",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2058,
    Code: "05290",
    Name: "Xã Nhân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2059,
    Code: "05293",
    Name: "Xã Thượng Cốc",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2060,
    Code: "05299",
    Name: "Xã Quyết Thắng",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2061,
    Code: "05302",
    Name: "Xã Xuất Hóa",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2062,
    Code: "05305",
    Name: "Xã Yên Phú",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2063,
    Code: "05308",
    Name: "Xã Bình Hẻm",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2064,
    Code: "05320",
    Name: "Xã Định Cư",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2065,
    Code: "05323",
    Name: "Xã Chí Đạo",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2066,
    Code: "05329",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2067,
    Code: "05332",
    Name: "Xã Hương Nhượng",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2068,
    Code: "05335",
    Name: "Xã Vũ Bình",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2069,
    Code: "05338",
    Name: "Xã Tự Do",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2070,
    Code: "05341",
    Name: "Xã Yên Nghiệp",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2071,
    Code: "05344",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2072,
    Code: "05347",
    Name: "Xã Ân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2073,
    Code: "05350",
    Name: "Xã Ngọc Lâu",
    LevelUp: "Xã",
    DistrictCode: "2305",
    Active: -1,
  },
  {
    ID: 2074,
    Code: "05353",
    Name: "Thị trấn Hàng Trạm",
    LevelUp: "Thị trấn",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2075,
    Code: "05356",
    Name: "Xã Lạc Sỹ",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2076,
    Code: "05362",
    Name: "Xã Lạc Lương",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2077,
    Code: "05365",
    Name: "Xã Bảo Hiệu",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2078,
    Code: "05368",
    Name: "Xã Đa Phúc",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2079,
    Code: "05371",
    Name: "Xã Hữu Lợi",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2080,
    Code: "05374",
    Name: "Xã Lạc Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2081,
    Code: "05380",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2082,
    Code: "05383",
    Name: "Xã Phú Lai",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2083,
    Code: "05386",
    Name: "Xã Yên Trị",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2084,
    Code: "05389",
    Name: "Xã Ngọc Lương",
    LevelUp: "Xã",
    DistrictCode: "2310",
    Active: -1,
  },
  {
    ID: 2085,
    Code: "04981",
    Name: "Thị trấn Ba Hàng Đồi",
    LevelUp: "Thị trấn",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2086,
    Code: "05392",
    Name: "Thị trấn Chi Nê",
    LevelUp: "Thị trấn",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2087,
    Code: "05395",
    Name: "Xã Phú Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2088,
    Code: "05398",
    Name: "Xã Phú Thành",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2089,
    Code: "05404",
    Name: "Xã Hưng Thi",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2090,
    Code: "05413",
    Name: "Xã Khoan Dụ",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2091,
    Code: "05419",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2092,
    Code: "05422",
    Name: "Xã Yên Bồng",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2093,
    Code: "05425",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2094,
    Code: "05428",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 2095,
    Code: "05431",
    Name: "Phường Quán Triều",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2096,
    Code: "05434",
    Name: "Phường Quang Vinh",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2097,
    Code: "05437",
    Name: "Phường Túc Duyên",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2098,
    Code: "05440",
    Name: "Phường Hoàng Văn Thụ",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2099,
    Code: "05443",
    Name: "Phường Trưng Vương",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2100,
    Code: "05446",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2101,
    Code: "05449",
    Name: "Phường Phan Đình Phùng",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2102,
    Code: "05452",
    Name: "Phường Tân Thịnh",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2103,
    Code: "05455",
    Name: "Phường Thịnh Đán",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2104,
    Code: "05458",
    Name: "Phường Đồng Quang",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2105,
    Code: "05461",
    Name: "Phường Gia Sàng",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2106,
    Code: "05464",
    Name: "Phường Tân Lập",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2107,
    Code: "05467",
    Name: "Phường Cam Giá",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2108,
    Code: "05470",
    Name: "Phường Phú Xá",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2109,
    Code: "05473",
    Name: "Phường Hương Sơn",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2110,
    Code: "05476",
    Name: "Phường Trung Thành",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2111,
    Code: "05479",
    Name: "Phường Tân Thành",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2112,
    Code: "05482",
    Name: "Phường Tân Long",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2113,
    Code: "05485",
    Name: "Xã Phúc Hà",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2114,
    Code: "05488",
    Name: "Xã Phúc Xuân",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2115,
    Code: "05491",
    Name: "Xã Quyết Thắng",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2116,
    Code: "05494",
    Name: "Xã Phúc Trìu",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2117,
    Code: "05497",
    Name: "Xã Thịnh Đức",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2118,
    Code: "05500",
    Name: "Phường Tích Lương",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2119,
    Code: "05503",
    Name: "Xã Tân Cương",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2120,
    Code: "05653",
    Name: "Xã Sơn Cẩm",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2121,
    Code: "05659",
    Name: "Phường Chùa Hang",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2122,
    Code: "05695",
    Name: "Xã Cao Ngạn",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2123,
    Code: "05701",
    Name: "Xã Linh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2124,
    Code: "05710",
    Name: "Phường Đồng Bẩm",
    LevelUp: "Phường",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2125,
    Code: "05713",
    Name: "Xã Huống Thượng",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2126,
    Code: "05914",
    Name: "Xã Đồng Liên",
    LevelUp: "Xã",
    DistrictCode: "1201",
    Active: -1,
  },
  {
    ID: 2127,
    Code: "05506",
    Name: "Phường Lương Sơn",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2128,
    Code: "05509",
    Name: "Phường Châu Sơn",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2129,
    Code: "05512",
    Name: "Phường Mỏ Chè",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2130,
    Code: "05515",
    Name: "Phường Cải Đan",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2131,
    Code: "05518",
    Name: "Phường Thắng Lợi",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2132,
    Code: "05521",
    Name: "Phường Phố Cò",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2133,
    Code: "05527",
    Name: "Xã Tân Quang",
    LevelUp: "Xã",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2134,
    Code: "05528",
    Name: "Phường Bách Quang",
    LevelUp: "Phường",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2135,
    Code: "05530",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2136,
    Code: "05533",
    Name: "Xã Bá Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1202",
    Active: -1,
  },
  {
    ID: 2137,
    Code: "05536",
    Name: "Thị trấn Chợ Chu",
    LevelUp: "Thị trấn",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2138,
    Code: "05539",
    Name: "Xã Linh Thông",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2139,
    Code: "05542",
    Name: "Xã Lam Vỹ",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2140,
    Code: "05545",
    Name: "Xã Quy Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2141,
    Code: "05548",
    Name: "Xã Tân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2142,
    Code: "05551",
    Name: "Xã Kim Phượng",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2143,
    Code: "05554",
    Name: "Xã Bảo Linh",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2144,
    Code: "05560",
    Name: "Xã Phúc Chu",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2145,
    Code: "05563",
    Name: "Xã Tân Dương",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2146,
    Code: "05566",
    Name: "Xã Phượng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2147,
    Code: "05569",
    Name: "Xã Bảo Cường",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2148,
    Code: "05572",
    Name: "Xã Đồng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2149,
    Code: "05575",
    Name: "Xã Định Biên",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2150,
    Code: "05578",
    Name: "Xã Thanh Định",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2151,
    Code: "05581",
    Name: "Xã Trung Hội",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2152,
    Code: "05584",
    Name: "Xã Trung Lương",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2153,
    Code: "05587",
    Name: "Xã Bình Yên",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2154,
    Code: "05590",
    Name: "Xã Điềm Mặc",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2155,
    Code: "05593",
    Name: "Xã Phú Tiến",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2156,
    Code: "05596",
    Name: "Xã Bộc Nhiêu",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2157,
    Code: "05599",
    Name: "Xã Sơn Phú",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2158,
    Code: "05602",
    Name: "Xã Phú Đình",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2159,
    Code: "05605",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "1203",
    Active: -1,
  },
  {
    ID: 2160,
    Code: "05608",
    Name: "Thị trấn Giang Tiên",
    LevelUp: "Thị trấn",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2161,
    Code: "05611",
    Name: "Thị trấn Đu",
    LevelUp: "Thị trấn",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2162,
    Code: "05614",
    Name: "Xã Yên Ninh",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2163,
    Code: "05617",
    Name: "Xã Yên Trạch",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2164,
    Code: "05620",
    Name: "Xã Yên Đổ",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2165,
    Code: "05623",
    Name: "Xã Yên Lạc",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2166,
    Code: "05626",
    Name: "Xã Ôn Lương",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2167,
    Code: "05629",
    Name: "Xã Động Đạt",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2168,
    Code: "05632",
    Name: "Xã Phủ Lý",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2169,
    Code: "05635",
    Name: "Xã Phú Đô",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2170,
    Code: "05638",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2171,
    Code: "05641",
    Name: "Xã Tức Tranh",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2172,
    Code: "05644",
    Name: "Xã Phấn Mễ",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2173,
    Code: "05647",
    Name: "Xã Vô Tranh",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2174,
    Code: "05650",
    Name: "Xã Cổ Lũng",
    LevelUp: "Xã",
    DistrictCode: "1204",
    Active: -1,
  },
  {
    ID: 2175,
    Code: "05656",
    Name: "Thị trấn Sông Cầu",
    LevelUp: "Thị trấn",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2176,
    Code: "05662",
    Name: "Thị trấn Trại Cau",
    LevelUp: "Thị trấn",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2177,
    Code: "05665",
    Name: "Xã Văn Lăng",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2178,
    Code: "05668",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2179,
    Code: "05671",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2180,
    Code: "05674",
    Name: "Xã Quang Sơn",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2181,
    Code: "05677",
    Name: "Xã Minh Lập",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2182,
    Code: "05680",
    Name: "Xã Văn Hán",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2183,
    Code: "05683",
    Name: "Xã Hóa Trung",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2184,
    Code: "05686",
    Name: "Xã Khe Mo",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2185,
    Code: "05689",
    Name: "Xã Cây Thị",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2186,
    Code: "05692",
    Name: "Xã Hóa Thượng",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2187,
    Code: "05698",
    Name: "Xã Hợp Tiến",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2188,
    Code: "05704",
    Name: "Xã Tân Lợi",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2189,
    Code: "05707",
    Name: "Xã Nam Hòa",
    LevelUp: "Xã",
    DistrictCode: "1207",
    Active: -1,
  },
  {
    ID: 2190,
    Code: "05716",
    Name: "Thị trấn Đình Cả",
    LevelUp: "Thị trấn",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2191,
    Code: "05719",
    Name: "Xã Sảng Mộc",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2192,
    Code: "05722",
    Name: "Xã Nghinh Tường",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2193,
    Code: "05725",
    Name: "Xã Thần Xa",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2194,
    Code: "05728",
    Name: "Xã Vũ Chấn",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2195,
    Code: "05731",
    Name: "Xã Thượng Nung",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2196,
    Code: "05734",
    Name: "Xã Phú Thượng",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2197,
    Code: "05737",
    Name: "Xã Cúc Đường",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2198,
    Code: "05740",
    Name: "Xã La Hiên",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2199,
    Code: "05743",
    Name: "Xã Lâu Thượng",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2200,
    Code: "05746",
    Name: "Xã Tràng Xá",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2201,
    Code: "05749",
    Name: "Xã Phương Giao",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2202,
    Code: "05752",
    Name: "Xã Liên Minh",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2203,
    Code: "05755",
    Name: "Xã Dân Tiến",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2204,
    Code: "05758",
    Name: "Xã Bình Long",
    LevelUp: "Xã",
    DistrictCode: "1205",
    Active: -1,
  },
  {
    ID: 2205,
    Code: "05761",
    Name: "Thị trấn Hùng Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2206,
    Code: "05764",
    Name: "Thị trấn Quân Chu",
    LevelUp: "Thị trấn",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2207,
    Code: "05767",
    Name: "Xã Phúc Lương",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2208,
    Code: "05770",
    Name: "Xã Minh Tiến",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2209,
    Code: "05773",
    Name: "Xã Yên Lãng",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2210,
    Code: "05776",
    Name: "Xã Đức Lương",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2211,
    Code: "05779",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2212,
    Code: "05782",
    Name: "Xã Na Mao",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2213,
    Code: "05785",
    Name: "Xã Phú Lạc",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2214,
    Code: "05788",
    Name: "Xã Tân Linh",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2215,
    Code: "05791",
    Name: "Xã Phú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2216,
    Code: "05794",
    Name: "Xã Phục Linh",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2217,
    Code: "05797",
    Name: "Xã Phú Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2218,
    Code: "05800",
    Name: "Xã Bản Ngoại",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2219,
    Code: "05803",
    Name: "Xã Tiên Hội",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2220,
    Code: "05809",
    Name: "Xã Cù Vân",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2221,
    Code: "05812",
    Name: "Xã Hà Thượng",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2222,
    Code: "05815",
    Name: "Xã La Bằng",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2223,
    Code: "05818",
    Name: "Xã Hoàng Nông",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2224,
    Code: "05821",
    Name: "Xã Khôi Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2225,
    Code: "05824",
    Name: "Xã An Khánh",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2226,
    Code: "05827",
    Name: "Xã Tân Thái",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2227,
    Code: "05830",
    Name: "Xã Bình Thuận",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2228,
    Code: "05833",
    Name: "Xã Lục Ba",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2229,
    Code: "05836",
    Name: "Xã Mỹ Yên",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2230,
    Code: "05839",
    Name: "Xã Vạn Thọ",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2231,
    Code: "05842",
    Name: "Xã Văn Yên",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2232,
    Code: "05845",
    Name: "Xã Ký Phú",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2233,
    Code: "05848",
    Name: "Xã Cát Nê",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2234,
    Code: "05851",
    Name: "Xã Quân Chu",
    LevelUp: "Xã",
    DistrictCode: "1206",
    Active: -1,
  },
  {
    ID: 2235,
    Code: "05854",
    Name: "Phường Bãi Bông",
    LevelUp: "Phường",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2236,
    Code: "05857",
    Name: "Phường Bắc Sơn",
    LevelUp: "Phường",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2237,
    Code: "05860",
    Name: "Phường Ba Hàng",
    LevelUp: "Phường",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2238,
    Code: "05863",
    Name: "Xã Phúc Tân",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2239,
    Code: "05866",
    Name: "Xã Phúc Thuận",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2240,
    Code: "05869",
    Name: "Xã Hồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2241,
    Code: "05872",
    Name: "Xã Minh Đức",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2242,
    Code: "05875",
    Name: "Xã Đắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2243,
    Code: "05878",
    Name: "Phường Đồng Tiến",
    LevelUp: "Phường",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2244,
    Code: "05881",
    Name: "Xã Thành Công",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2245,
    Code: "05884",
    Name: "Xã Tiên Phong",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2246,
    Code: "05887",
    Name: "Xã Vạn Phái",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2247,
    Code: "05890",
    Name: "Xã Nam Tiến",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2248,
    Code: "05893",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2249,
    Code: "05896",
    Name: "Xã Đông Cao",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2250,
    Code: "05899",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2251,
    Code: "05902",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2252,
    Code: "05905",
    Name: "Xã Thuận Thành",
    LevelUp: "Xã",
    DistrictCode: "1209",
    Active: -1,
  },
  {
    ID: 2253,
    Code: "05908",
    Name: "Thị trấn Hương Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2254,
    Code: "05911",
    Name: "Xã Bàn Đạt",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2255,
    Code: "05917",
    Name: "Xã Tân Khánh",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2256,
    Code: "05920",
    Name: "Xã Tân Kim",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2257,
    Code: "05923",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2258,
    Code: "05926",
    Name: "Xã Đào Xá",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2259,
    Code: "05929",
    Name: "Xã Bảo Lý",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2260,
    Code: "05932",
    Name: "Xã Thượng Đình",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2261,
    Code: "05935",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2262,
    Code: "05938",
    Name: "Xã Nhã Lộng",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2263,
    Code: "05941",
    Name: "Xã Điềm Thụy",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2264,
    Code: "05944",
    Name: "Xã Xuân Phương",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2265,
    Code: "05947",
    Name: "Xã Tân Đức",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2266,
    Code: "05950",
    Name: "Xã Úc Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2267,
    Code: "05953",
    Name: "Xã Lương Phú",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2268,
    Code: "05956",
    Name: "Xã Nga My",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2269,
    Code: "05959",
    Name: "Xã Kha Sơn",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2270,
    Code: "05962",
    Name: "Xã Thanh Ninh",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2271,
    Code: "05965",
    Name: "Xã Dương Thành",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2272,
    Code: "05968",
    Name: "Xã Hà Châu",
    LevelUp: "Xã",
    DistrictCode: "1208",
    Active: -1,
  },
  {
    ID: 2273,
    Code: "05971",
    Name: "Phường Hoàng Văn Thụ",
    LevelUp: "Phường",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2274,
    Code: "05974",
    Name: "Phường Tam Thanh",
    LevelUp: "Phường",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2275,
    Code: "05977",
    Name: "Phường Vĩnh Trại",
    LevelUp: "Phường",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2276,
    Code: "05980",
    Name: "Phường Đông Kinh",
    LevelUp: "Phường",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2277,
    Code: "05983",
    Name: "Phường Chi Lăng",
    LevelUp: "Phường",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2278,
    Code: "05986",
    Name: "Xã Hoàng Đồng",
    LevelUp: "Xã",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2279,
    Code: "05989",
    Name: "Xã Quảng Lạc",
    LevelUp: "Xã",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2280,
    Code: "05992",
    Name: "Xã Mai Pha",
    LevelUp: "Xã",
    DistrictCode: "1001",
    Active: -1,
  },
  {
    ID: 2281,
    Code: "05995",
    Name: "Thị trấn Thất Khê",
    LevelUp: "Thị trấn",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2282,
    Code: "05998",
    Name: "Xã Khánh Long",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2283,
    Code: "06001",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2284,
    Code: "06004",
    Name: "Xã Quốc Khánh",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2285,
    Code: "06007",
    Name: "Xã Vĩnh Tiến",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2286,
    Code: "06010",
    Name: "Xã Cao Minh",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2287,
    Code: "06013",
    Name: "Xã Chí Minh",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2288,
    Code: "06016",
    Name: "Xã Tri Phương",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2289,
    Code: "06019",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2290,
    Code: "06022",
    Name: "Xã Tân Yên",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2291,
    Code: "06025",
    Name: "Xã Đội Cấn",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2292,
    Code: "06028",
    Name: "Xã Tân Minh",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2293,
    Code: "06031",
    Name: "Xã Kim Đồng",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2294,
    Code: "06034",
    Name: "Xã Chi Lăng",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2295,
    Code: "06037",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2296,
    Code: "06040",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2297,
    Code: "06043",
    Name: "Xã Đào Viên",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2298,
    Code: "06046",
    Name: "Xã Đề Thám",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2299,
    Code: "06049",
    Name: "Xã Kháng Chiến",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2300,
    Code: "06055",
    Name: "Xã Hùng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2301,
    Code: "06058",
    Name: "Xã Quốc Việt",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2302,
    Code: "06061",
    Name: "Xã Hùng Việt",
    LevelUp: "Xã",
    DistrictCode: "1002",
    Active: -1,
  },
  {
    ID: 2303,
    Code: "06067",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2304,
    Code: "06070",
    Name: "Xã Vĩnh Yên",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2305,
    Code: "06073",
    Name: "Xã Hoa Thám",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2306,
    Code: "06076",
    Name: "Xã Quý Hòa",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2307,
    Code: "06079",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2308,
    Code: "06082",
    Name: "Xã Yên Lỗ",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2309,
    Code: "06085",
    Name: "Xã Thiện Hòa",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2310,
    Code: "06088",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2311,
    Code: "06091",
    Name: "Xã Thiện Thuật",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2312,
    Code: "06094",
    Name: "Xã Minh Khai",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2313,
    Code: "06097",
    Name: "Xã Thiện Long",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2314,
    Code: "06100",
    Name: "Xã Hoàng Văn Thụ",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2315,
    Code: "06103",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2316,
    Code: "06106",
    Name: "Xã Mông Ân",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2317,
    Code: "06109",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2318,
    Code: "06112",
    Name: "Thị trấn Bình Gia",
    LevelUp: "Thị trấn",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2319,
    Code: "06115",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2320,
    Code: "06118",
    Name: "Xã Bình La",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2321,
    Code: "06121",
    Name: "Xã Tân Văn",
    LevelUp: "Xã",
    DistrictCode: "1003",
    Active: -1,
  },
  {
    ID: 2322,
    Code: "06124",
    Name: "Thị trấn Na Sầm",
    LevelUp: "Thị trấn",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2323,
    Code: "06127",
    Name: "Xã Trùng Khánh",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2324,
    Code: "06133",
    Name: "Xã Bắc La",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2325,
    Code: "06136",
    Name: "Xã Thụy Hùng",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2326,
    Code: "06139",
    Name: "Xã Bắc Hùng",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2327,
    Code: "06142",
    Name: "Xã Tân Tác",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2328,
    Code: "06148",
    Name: "Xã Thanh Long",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2329,
    Code: "06151",
    Name: "Xã Hội Hoan",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2330,
    Code: "06154",
    Name: "Xã Bắc Việt",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2331,
    Code: "06157",
    Name: "Xã Hoàng Việt",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2332,
    Code: "06160",
    Name: "Xã Gia Miễn",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2333,
    Code: "06163",
    Name: "Xã Thành Hòa",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2334,
    Code: "06166",
    Name: "Xã Tân Thanh",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2335,
    Code: "06172",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2336,
    Code: "06175",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2337,
    Code: "06178",
    Name: "Xã  Hoàng Văn Thụ",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2338,
    Code: "06181",
    Name: "Xã Nhạc Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1004",
    Active: -1,
  },
  {
    ID: 2339,
    Code: "06184",
    Name: "Thị trấn Đồng Đăng",
    LevelUp: "Thị trấn",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2340,
    Code: "06187",
    Name: "Thị trấn Cao Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2341,
    Code: "06190",
    Name: "Xã Bảo Lâm",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2342,
    Code: "06193",
    Name: "Xã Thanh Lòa",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2343,
    Code: "06196",
    Name: "Xã Cao Lâu",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2344,
    Code: "06199",
    Name: "Xã Thạch Đạn",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2345,
    Code: "06202",
    Name: "Xã Xuất Lễ",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2346,
    Code: "06205",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2347,
    Code: "06208",
    Name: "Xã Thụy Hùng",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2348,
    Code: "06211",
    Name: "Xã Lộc Yên",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2349,
    Code: "06214",
    Name: "Xã Phú Xá",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2350,
    Code: "06217",
    Name: "Xã Bình Trung",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2351,
    Code: "06220",
    Name: "Xã Hải Yến",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2352,
    Code: "06223",
    Name: "Xã Hòa Cư",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2353,
    Code: "06226",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2354,
    Code: "06232",
    Name: "Xã Công Sơn",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2355,
    Code: "06235",
    Name: "Xã Gia Cát",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2356,
    Code: "06238",
    Name: "Xã Mẫu Sơn",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2357,
    Code: "06241",
    Name: "Xã Xuân Long",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2358,
    Code: "06244",
    Name: "Xã Tân Liên",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2359,
    Code: "06247",
    Name: "Xã Yên Trạch",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2360,
    Code: "06250",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "1007",
    Active: -1,
  },
  {
    ID: 2361,
    Code: "06253",
    Name: "Thị trấn Văn Quan",
    LevelUp: "Thị trấn",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2362,
    Code: "06256",
    Name: "Xã Trấn Ninh",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2363,
    Code: "06268",
    Name: "Xã Liên Hội",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2364,
    Code: "06274",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2365,
    Code: "06277",
    Name: "Xã Tú Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2366,
    Code: "06280",
    Name: "Xã Điềm He",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2367,
    Code: "06283",
    Name: "Xã An Sơn",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2368,
    Code: "06286",
    Name: "Xã Khánh Khê",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2369,
    Code: "06292",
    Name: "Xã Lương Năng",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2370,
    Code: "06295",
    Name: "Xã Đồng Giáp",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2371,
    Code: "06298",
    Name: "Xã Bình Phúc",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2372,
    Code: "06301",
    Name: "Xã Tràng Các",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2373,
    Code: "06307",
    Name: "Xã Tân Đoàn",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2374,
    Code: "06313",
    Name: "Xã Tri Lễ",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2375,
    Code: "06316",
    Name: "Xã Tràng Phái",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2376,
    Code: "06319",
    Name: "Xã Yên Phúc",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2377,
    Code: "06322",
    Name: "Xã Hữu Lễ",
    LevelUp: "Xã",
    DistrictCode: "1006",
    Active: -1,
  },
  {
    ID: 2378,
    Code: "06325",
    Name: "Thị trấn Bắc Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2379,
    Code: "06328",
    Name: "Xã Long Đống",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2380,
    Code: "06331",
    Name: "Xã Vạn Thủy",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2381,
    Code: "06337",
    Name: "Xã Đồng ý",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2382,
    Code: "06340",
    Name: "Xã Tân Tri",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2383,
    Code: "06343",
    Name: "Xã Bắc Quỳnh",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2384,
    Code: "06349",
    Name: "Xã Hưng Vũ",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2385,
    Code: "06352",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2386,
    Code: "06355",
    Name: "Xã Vũ Sơn",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2387,
    Code: "06358",
    Name: "Xã Chiêu Vũ",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2388,
    Code: "06361",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2389,
    Code: "06364",
    Name: "Xã Chiến Thắng",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2390,
    Code: "06367",
    Name: "Xã Vũ Lăng",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2391,
    Code: "06370",
    Name: "Xã Trấn Yên",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2392,
    Code: "06373",
    Name: "Xã Vũ Lễ",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2393,
    Code: "06376",
    Name: "Xã Nhất Hòa",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2394,
    Code: "06379",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2395,
    Code: "06382",
    Name: "Xã Nhất Tiến",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 2396,
    Code: "06385",
    Name: "Thị trấn Hữu Lũng",
    LevelUp: "Thị trấn",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2397,
    Code: "06388",
    Name: "Xã Hữu Liên",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2398,
    Code: "06391",
    Name: "Xã Yên Bình",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2399,
    Code: "06394",
    Name: "Xã Quyết Thắng",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2400,
    Code: "06397",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2401,
    Code: "06400",
    Name: "Xã Yên Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2402,
    Code: "06403",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2403,
    Code: "06406",
    Name: "Xã Thiện Tân",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2404,
    Code: "06412",
    Name: "Xã Yên Vượng",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2405,
    Code: "06415",
    Name: "Xã Minh Tiến",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2406,
    Code: "06418",
    Name: "Xã Nhật Tiến",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2407,
    Code: "06421",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2408,
    Code: "06424",
    Name: "Xã Đồng Tân",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2409,
    Code: "06427",
    Name: "Xã Cai Kinh",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2410,
    Code: "06430",
    Name: "Xã Hòa Lạc",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2411,
    Code: "06433",
    Name: "Xã Vân Nham",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2412,
    Code: "06436",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2413,
    Code: "06442",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2414,
    Code: "06445",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2415,
    Code: "06448",
    Name: "Xã Minh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2416,
    Code: "06451",
    Name: "Xã Hồ Sơn",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2417,
    Code: "06454",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2418,
    Code: "06457",
    Name: "Xã Minh Hòa",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2419,
    Code: "06460",
    Name: "Xã Hòa Thắng",
    LevelUp: "Xã",
    DistrictCode: "1011",
    Active: -1,
  },
  {
    ID: 2420,
    Code: "06463",
    Name: "Thị trấn Đồng Mỏ",
    LevelUp: "Thị trấn",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2421,
    Code: "06466",
    Name: "Thị trấn Chi Lăng",
    LevelUp: "Thị trấn",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2422,
    Code: "06469",
    Name: "Xã Vân An",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2423,
    Code: "06472",
    Name: "Xã Vân Thủy",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2424,
    Code: "06475",
    Name: "Xã Gia Lộc",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2425,
    Code: "06478",
    Name: "Xã Bắc Thủy",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2426,
    Code: "06481",
    Name: "Xã Chiến Thắng",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2427,
    Code: "06484",
    Name: "Xã Mai Sao",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2428,
    Code: "06487",
    Name: "Xã Bằng Hữu",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2429,
    Code: "06490",
    Name: "Xã Thượng Cường",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2430,
    Code: "06493",
    Name: "Xã Bằng Mạc",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2431,
    Code: "06496",
    Name: "Xã Nhân Lý",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2432,
    Code: "06499",
    Name: "Xã Lâm Sơn",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2433,
    Code: "06502",
    Name: "Xã Liên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2434,
    Code: "06505",
    Name: "Xã Vạn Linh",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2435,
    Code: "06508",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2436,
    Code: "06514",
    Name: "Xã Hữu Kiên",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2437,
    Code: "06517",
    Name: "Xã Quan Sơn",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2438,
    Code: "06520",
    Name: "Xã Y Tịch",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2439,
    Code: "06523",
    Name: "Xã Chi Lăng",
    LevelUp: "Xã",
    DistrictCode: "1009",
    Active: -1,
  },
  {
    ID: 2440,
    Code: "06526",
    Name: "Thị trấn Na Dương",
    LevelUp: "Thị trấn",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2441,
    Code: "06529",
    Name: "Thị trấn Lộc Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2442,
    Code: "06532",
    Name: "Xã Mẫu Sơn",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2443,
    Code: "06541",
    Name: "Xã Yên Khoái",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2444,
    Code: "06544",
    Name: "Xã Khánh Xuân",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2445,
    Code: "06547",
    Name: "Xã Tú Mịch",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2446,
    Code: "06550",
    Name: "Xã Hữu Khánh",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2447,
    Code: "06553",
    Name: "Xã Đồng Bục",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2448,
    Code: "06559",
    Name: "Xã Tam Gia",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2449,
    Code: "06562",
    Name: "Xã Tú Đoạn",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2450,
    Code: "06565",
    Name: "Xã Khuất Xá",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2451,
    Code: "06574",
    Name: "Xã Tĩnh Bắc",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2452,
    Code: "06577",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2453,
    Code: "06589",
    Name: "Xã Sàn Viên",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2454,
    Code: "06592",
    Name: "Xã Đông Quan",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2455,
    Code: "06595",
    Name: "Xã Minh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2456,
    Code: "06598",
    Name: "Xã Hữu Lân",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2457,
    Code: "06601",
    Name: "Xã Lợi Bác",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2458,
    Code: "06604",
    Name: "Xã Nam Quan",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2459,
    Code: "06607",
    Name: "Xã Xuân Dương",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2460,
    Code: "06610",
    Name: "Xã Ái Quốc",
    LevelUp: "Xã",
    DistrictCode: "1008",
    Active: -1,
  },
  {
    ID: 2461,
    Code: "06613",
    Name: "Thị trấn Đình Lập",
    LevelUp: "Thị trấn",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2462,
    Code: "06616",
    Name: "Thị trấn NT Thái Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2463,
    Code: "06619",
    Name: "Xã Bắc Xa",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2464,
    Code: "06622",
    Name: "Xã Bính Xá",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2465,
    Code: "06625",
    Name: "Xã Kiên Mộc",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2466,
    Code: "06628",
    Name: "Xã Đình Lập",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2467,
    Code: "06631",
    Name: "Xã Thái Bình",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2468,
    Code: "06634",
    Name: "Xã Cường Lợi",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2469,
    Code: "06637",
    Name: "Xã Châu Sơn",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2470,
    Code: "06640",
    Name: "Xã Lâm Ca",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2471,
    Code: "06643",
    Name: "Xã Đồng Thắng",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2472,
    Code: "06646",
    Name: "Xã Bắc Lãng",
    LevelUp: "Xã",
    DistrictCode: "1010",
    Active: -1,
  },
  {
    ID: 2473,
    Code: "06649",
    Name: "Phường Hà Khánh",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2474,
    Code: "06652",
    Name: "Phường Hà Phong",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2475,
    Code: "06655",
    Name: "Phường Hà Khẩu",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2476,
    Code: "06658",
    Name: "Phường Cao Xanh",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2477,
    Code: "06661",
    Name: "Phường Giếng Đáy",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2478,
    Code: "06664",
    Name: "Phường Hà Tu",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2479,
    Code: "06667",
    Name: "Phường Hà Trung",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2480,
    Code: "06670",
    Name: "Phường Hà Lầm",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2481,
    Code: "06673",
    Name: "Phường Bãi Cháy",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2482,
    Code: "06676",
    Name: "Phường Cao Thắng",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2483,
    Code: "06679",
    Name: "Phường Hùng Thắng",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2484,
    Code: "06682",
    Name: "Phường Yết Kiêu",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2485,
    Code: "06685",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2486,
    Code: "06688",
    Name: "Phường Hồng Hải",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2487,
    Code: "06691",
    Name: "Phường Hồng Gai",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2488,
    Code: "06694",
    Name: "Phường Bạch Đằng",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2489,
    Code: "06697",
    Name: "Phường Hồng Hà",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2490,
    Code: "06700",
    Name: "Phường Tuần Châu",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2491,
    Code: "06703",
    Name: "Phường Việt Hưng",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2492,
    Code: "06706",
    Name: "Phường Đại Yên",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2493,
    Code: "07030",
    Name: "Phường Hoành Bồ",
    LevelUp: "Phường",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2494,
    Code: "07033",
    Name: "Xã Kỳ Thượng",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2495,
    Code: "07036",
    Name: "Xã Đồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2496,
    Code: "07039",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2497,
    Code: "07042",
    Name: "Xã Đồng Lâm",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2498,
    Code: "07045",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2499,
    Code: "07048",
    Name: "Xã Vũ Oai",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2500,
    Code: "07051",
    Name: "Xã Dân Chủ",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2501,
    Code: "07054",
    Name: "Xã Quảng La",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2502,
    Code: "07057",
    Name: "Xã Bằng Cả",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2503,
    Code: "07060",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2504,
    Code: "07063",
    Name: "Xã Sơn Dương",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2505,
    Code: "07066",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "1701",
    Active: -1,
  },
  {
    ID: 2506,
    Code: "06709",
    Name: "Phường Ka Long",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2507,
    Code: "06712",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2508,
    Code: "06715",
    Name: "Phường Ninh Dương",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2509,
    Code: "06718",
    Name: "Phường Hoà Lạc",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2510,
    Code: "06721",
    Name: "Phường Trà Cổ",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2511,
    Code: "06724",
    Name: "Xã Hải Sơn",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2512,
    Code: "06727",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2513,
    Code: "06730",
    Name: "Xã Hải Đông",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2514,
    Code: "06733",
    Name: "Xã Hải Tiến",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2515,
    Code: "06736",
    Name: "Phường Hải Yên",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2516,
    Code: "06739",
    Name: "Xã Quảng Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2517,
    Code: "06742",
    Name: "Phường Hải Hoà",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2518,
    Code: "06745",
    Name: "Xã Hải Xuân",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2519,
    Code: "06748",
    Name: "Xã Vạn Ninh",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2520,
    Code: "06751",
    Name: "Phường Bình Ngọc",
    LevelUp: "Phường",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2521,
    Code: "06754",
    Name: "Xã Vĩnh Trung",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2522,
    Code: "06757",
    Name: "Xã Vĩnh Thực",
    LevelUp: "Xã",
    DistrictCode: "1704",
    Active: -1,
  },
  {
    ID: 2523,
    Code: "06760",
    Name: "Phường Mông Dương",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2524,
    Code: "06763",
    Name: "Phường Cửa Ông",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2525,
    Code: "06766",
    Name: "Phường Cẩm Sơn",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2526,
    Code: "06769",
    Name: "Phường Cẩm Đông",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2527,
    Code: "06772",
    Name: "Phường Cẩm Phú",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2528,
    Code: "06775",
    Name: "Phường Cẩm Tây",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2529,
    Code: "06778",
    Name: "Phường Quang Hanh",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2530,
    Code: "06781",
    Name: "Phường Cẩm Thịnh",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2531,
    Code: "06784",
    Name: "Phường Cẩm Thủy",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2532,
    Code: "06787",
    Name: "Phường Cẩm Thạch",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2533,
    Code: "06790",
    Name: "Phường Cẩm Thành",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2534,
    Code: "06793",
    Name: "Phường Cẩm Trung",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2535,
    Code: "06796",
    Name: "Phường Cẩm Bình",
    LevelUp: "Phường",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2536,
    Code: "06799",
    Name: "Xã Cộng Hòa",
    LevelUp: "Xã",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2537,
    Code: "06802",
    Name: "Xã Cẩm Hải",
    LevelUp: "Xã",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2538,
    Code: "06805",
    Name: "Xã Dương Huy",
    LevelUp: "Xã",
    DistrictCode: "1702",
    Active: -1,
  },
  {
    ID: 2539,
    Code: "06808",
    Name: "Phường Vàng Danh",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2540,
    Code: "06811",
    Name: "Phường Thanh Sơn",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2541,
    Code: "06814",
    Name: "Phường Bắc Sơn",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2542,
    Code: "06817",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2543,
    Code: "06820",
    Name: "Phường Trưng Vương",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2544,
    Code: "06823",
    Name: "Phường Nam Khê",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2545,
    Code: "06826",
    Name: "Phường Yên Thanh",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2546,
    Code: "06829",
    Name: "Xã Thượng Yên Công",
    LevelUp: "Xã",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2547,
    Code: "06832",
    Name: "Phường Phương Đông",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2548,
    Code: "06835",
    Name: "Phường Phương Nam",
    LevelUp: "Phường",
    DistrictCode: "1703",
    Active: -1,
  },
  {
    ID: 2549,
    Code: "06838",
    Name: "Thị trấn Bình Liêu",
    LevelUp: "Thị trấn",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2550,
    Code: "06841",
    Name: "Xã Hoành Mô",
    LevelUp: "Xã",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2551,
    Code: "06844",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2552,
    Code: "06847",
    Name: "Xã Đồng Văn",
    LevelUp: "Xã",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2553,
    Code: "06853",
    Name: "Xã Vô Ngại",
    LevelUp: "Xã",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2554,
    Code: "06856",
    Name: "Xã Lục Hồn",
    LevelUp: "Xã",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2555,
    Code: "06859",
    Name: "Xã Húc Động",
    LevelUp: "Xã",
    DistrictCode: "1705",
    Active: -1,
  },
  {
    ID: 2556,
    Code: "06862",
    Name: "Thị trấn Tiên Yên",
    LevelUp: "Thị trấn",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2557,
    Code: "06865",
    Name: "Xã Hà Lâu",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2558,
    Code: "06868",
    Name: "Xã Đại Dực",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2559,
    Code: "06871",
    Name: "Xã Phong Dụ",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2560,
    Code: "06874",
    Name: "Xã Điền Xá",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2561,
    Code: "06877",
    Name: "Xã Đông Ngũ",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2562,
    Code: "06880",
    Name: "Xã Yên Than",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2563,
    Code: "06883",
    Name: "Xã Đông Hải",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2564,
    Code: "06886",
    Name: "Xã Hải Lạng",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2565,
    Code: "06889",
    Name: "Xã Tiên Lãng",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2566,
    Code: "06892",
    Name: "Xã Đồng Rui",
    LevelUp: "Xã",
    DistrictCode: "1708",
    Active: -1,
  },
  {
    ID: 2567,
    Code: "06895",
    Name: "Thị trấn Đầm Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2568,
    Code: "06898",
    Name: "Xã Quảng Lâm",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2569,
    Code: "06901",
    Name: "Xã Quảng An",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2570,
    Code: "06904",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2571,
    Code: "06910",
    Name: "Xã Dực Yên",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2572,
    Code: "06913",
    Name: "Xã Quảng Tân",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2573,
    Code: "06916",
    Name: "Xã Đầm Hà",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2574,
    Code: "06917",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2575,
    Code: "06919",
    Name: "Xã Đại Bình",
    LevelUp: "Xã",
    DistrictCode: "1706",
    Active: -1,
  },
  {
    ID: 2576,
    Code: "06922",
    Name: "Thị trấn Quảng Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2577,
    Code: "06925",
    Name: "Xã Quảng Đức",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2578,
    Code: "06928",
    Name: "Xã Quảng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2579,
    Code: "06931",
    Name: "Xã Quảng Thành",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2580,
    Code: "06937",
    Name: "Xã Quảng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2581,
    Code: "06940",
    Name: "Xã Quảng Minh",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2582,
    Code: "06943",
    Name: "Xã Quảng Chính",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2583,
    Code: "06946",
    Name: "Xã Quảng Long",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2584,
    Code: "06949",
    Name: "Xã Đường Hoa",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2585,
    Code: "06952",
    Name: "Xã Quảng Phong",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2586,
    Code: "06967",
    Name: "Xã Cái Chiên",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 2587,
    Code: "06970",
    Name: "Thị trấn Ba Chẽ",
    LevelUp: "Thị trấn",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2588,
    Code: "06973",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2589,
    Code: "06976",
    Name: "Xã Thanh Lâm",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2590,
    Code: "06979",
    Name: "Xã Đạp Thanh",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2591,
    Code: "06982",
    Name: "Xã Nam Sơn",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2592,
    Code: "06985",
    Name: "Xã Lương Mông",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2593,
    Code: "06988",
    Name: "Xã Đồn Đạc",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2594,
    Code: "06991",
    Name: "Xã Minh Cầm",
    LevelUp: "Xã",
    DistrictCode: "1709",
    Active: -1,
  },
  {
    ID: 2595,
    Code: "06994",
    Name: "Thị trấn Cái Rồng",
    LevelUp: "Thị trấn",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2596,
    Code: "06997",
    Name: "Xã Đài Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2597,
    Code: "07000",
    Name: "Xã Bình Dân",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2598,
    Code: "07003",
    Name: "Xã Vạn Yên",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2599,
    Code: "07006",
    Name: "Xã Minh Châu",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2600,
    Code: "07009",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2601,
    Code: "07012",
    Name: "Xã Hạ Long",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2602,
    Code: "07015",
    Name: "Xã Đông Xá",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2603,
    Code: "07018",
    Name: "Xã Bản Sen",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2604,
    Code: "07021",
    Name: "Xã Thắng Lợi",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2605,
    Code: "07024",
    Name: "Xã Quan Lạn",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2606,
    Code: "07027",
    Name: "Xã Ngọc Vừng",
    LevelUp: "Xã",
    DistrictCode: "1713",
    Active: -1,
  },
  {
    ID: 2607,
    Code: "07069",
    Name: "Phường Mạo Khê",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2608,
    Code: "07072",
    Name: "Phường Đông Triều",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2609,
    Code: "07075",
    Name: "Xã An Sinh",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2610,
    Code: "07078",
    Name: "Xã Tràng Lương",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2611,
    Code: "07081",
    Name: "Xã Bình Khê",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2612,
    Code: "07084",
    Name: "Xã Việt Dân",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2613,
    Code: "07087",
    Name: "Xã Tân Việt",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2614,
    Code: "07090",
    Name: "Xã Bình Dương",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2615,
    Code: "07093",
    Name: "Phường Đức Chính",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2616,
    Code: "07096",
    Name: "Phường Tràng An",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2617,
    Code: "07099",
    Name: "Xã Nguyễn Huệ",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2618,
    Code: "07102",
    Name: "Xã Thủy An",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2619,
    Code: "07105",
    Name: "Phường Xuân Sơn",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2620,
    Code: "07108",
    Name: "Xã Hồng Thái Tây",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2621,
    Code: "07111",
    Name: "Xã Hồng Thái Đông",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2622,
    Code: "07114",
    Name: "Phường Hoàng Quế",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2623,
    Code: "07117",
    Name: "Phường Yên Thọ",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2624,
    Code: "07120",
    Name: "Phường Hồng Phong",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2625,
    Code: "07123",
    Name: "Phường Kim Sơn",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2626,
    Code: "07126",
    Name: "Phường Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2627,
    Code: "07129",
    Name: "Xã Yên Đức",
    LevelUp: "Xã",
    DistrictCode: "1710",
    Active: -1,
  },
  {
    ID: 2628,
    Code: "07132",
    Name: "Phường Quảng Yên",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2629,
    Code: "07135",
    Name: "Phường Đông Mai",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2630,
    Code: "07138",
    Name: "Phường Minh Thành",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2631,
    Code: "07144",
    Name: "Xã Sông Khoai",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2632,
    Code: "07147",
    Name: "Xã Hiệp Hòa",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2633,
    Code: "07150",
    Name: "Phường Cộng Hòa",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2634,
    Code: "07153",
    Name: "Xã Tiền An",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2635,
    Code: "07156",
    Name: "Xã Hoàng Tân",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2636,
    Code: "07159",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2637,
    Code: "07162",
    Name: "Phường Yên Giang",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2638,
    Code: "07165",
    Name: "Phường Nam Hoà",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2639,
    Code: "07168",
    Name: "Phường Hà An",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2640,
    Code: "07171",
    Name: "Xã Cẩm La",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2641,
    Code: "07174",
    Name: "Phường Phong Hải",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2642,
    Code: "07177",
    Name: "Phường Yên Hải",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2643,
    Code: "07180",
    Name: "Xã Liên Hòa",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2644,
    Code: "07183",
    Name: "Phường Phong Cốc",
    LevelUp: "Phường",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2645,
    Code: "07186",
    Name: "Xã Liên Vị",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2646,
    Code: "07189",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "1715",
    Active: -1,
  },
  {
    ID: 2647,
    Code: "07192",
    Name: "Thị trấn Cô Tô",
    LevelUp: "Thị trấn",
    DistrictCode: "1714",
    Active: -1,
  },
  {
    ID: 2648,
    Code: "07195",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1714",
    Active: -1,
  },
  {
    ID: 2649,
    Code: "07198",
    Name: "Xã Thanh Lân",
    LevelUp: "Xã",
    DistrictCode: "1714",
    Active: -1,
  },
  {
    ID: 2650,
    Code: "07201",
    Name: "Phường Thọ Xương",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2651,
    Code: "07204",
    Name: "Phường Trần Nguyên Hãn",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2652,
    Code: "07207",
    Name: "Phường Ngô Quyền",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2653,
    Code: "07210",
    Name: "Phường Hoàng Văn Thụ",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2654,
    Code: "07213",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2655,
    Code: "07216",
    Name: "Phường Mỹ Độ",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2656,
    Code: "07219",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2657,
    Code: "07222",
    Name: "Xã Song Mai",
    LevelUp: "Xã",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2658,
    Code: "07225",
    Name: "Phường Xương Giang",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2659,
    Code: "07228",
    Name: "Phường Đa Mai",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2660,
    Code: "07231",
    Name: "Phường Dĩnh Kế",
    LevelUp: "Phường",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2661,
    Code: "07441",
    Name: "Xã Dĩnh Trì",
    LevelUp: "Xã",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2662,
    Code: "07687",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2663,
    Code: "07696",
    Name: "Xã Đồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2664,
    Code: "07699",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2665,
    Code: "07705",
    Name: "Xã Song Khê",
    LevelUp: "Xã",
    DistrictCode: "1801",
    Active: -1,
  },
  {
    ID: 2666,
    Code: "07243",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2667,
    Code: "07246",
    Name: "Xã Canh Nậu",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2668,
    Code: "07249",
    Name: "Xã Xuân Lương",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2669,
    Code: "07252",
    Name: "Xã Tam Tiến",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2670,
    Code: "07255",
    Name: "Xã Đồng Vương",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2671,
    Code: "07258",
    Name: "Xã Đồng Hưu",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2672,
    Code: "07260",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2673,
    Code: "07261",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2674,
    Code: "07264",
    Name: "Xã Tiến Thắng",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2675,
    Code: "07267",
    Name: "Xã Hồng Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2676,
    Code: "07270",
    Name: "Xã Đồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2677,
    Code: "07273",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2678,
    Code: "07276",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2679,
    Code: "07279",
    Name: "Xã Hương Vĩ",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2680,
    Code: "07282",
    Name: "Xã Đồng Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2681,
    Code: "07285",
    Name: "Xã An Thượng",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2682,
    Code: "07288",
    Name: "Thị trấn Phồn Xương",
    LevelUp: "Thị trấn",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2683,
    Code: "07291",
    Name: "Xã Tân Sỏi",
    LevelUp: "Xã",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2684,
    Code: "07294",
    Name: "Thị trấn Bố Hạ",
    LevelUp: "Thị trấn",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 2685,
    Code: "07303",
    Name: "Xã Lan Giới",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2686,
    Code: "07306",
    Name: "Thị trấn Nhã Nam",
    LevelUp: "Thị trấn",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2687,
    Code: "07309",
    Name: "Xã Tân Trung",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2688,
    Code: "07312",
    Name: "Xã Đại Hóa",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2689,
    Code: "07315",
    Name: "Xã Quang Tiến",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2690,
    Code: "07318",
    Name: "Xã Phúc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2691,
    Code: "07321",
    Name: "Xã An Dương",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2692,
    Code: "07324",
    Name: "Xã Phúc Hòa",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2693,
    Code: "07327",
    Name: "Xã Liên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2694,
    Code: "07330",
    Name: "Xã Hợp Đức",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2695,
    Code: "07333",
    Name: "Xã Lam Cốt",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2696,
    Code: "07336",
    Name: "Xã Cao Xá",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2697,
    Code: "07339",
    Name: "Thị trấn Cao Thượng",
    LevelUp: "Thị trấn",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2698,
    Code: "07342",
    Name: "Xã Việt Ngọc",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2699,
    Code: "07345",
    Name: "Xã Song Vân",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2700,
    Code: "07348",
    Name: "Xã Ngọc Châu",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2701,
    Code: "07351",
    Name: "Xã Ngọc Vân",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2702,
    Code: "07354",
    Name: "Xã Việt Lập",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2703,
    Code: "07357",
    Name: "Xã Liên Chung",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2704,
    Code: "07360",
    Name: "Xã Ngọc Thiện",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2705,
    Code: "07363",
    Name: "Xã Ngọc Lý",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2706,
    Code: "07366",
    Name: "Xã Quế Nham",
    LevelUp: "Xã",
    DistrictCode: "1806",
    Active: -1,
  },
  {
    ID: 2707,
    Code: "07375",
    Name: "Thị trấn Vôi",
    LevelUp: "Thị trấn",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2708,
    Code: "07378",
    Name: "Xã Nghĩa Hòa",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2709,
    Code: "07381",
    Name: "Xã Nghĩa Hưng",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2710,
    Code: "07384",
    Name: "Xã Quang Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2711,
    Code: "07387",
    Name: "Xã Hương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2712,
    Code: "07390",
    Name: "Xã Đào Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2713,
    Code: "07393",
    Name: "Xã Tiên Lục",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2714,
    Code: "07396",
    Name: "Xã An Hà",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2715,
    Code: "07399",
    Name: "Thị trấn Kép",
    LevelUp: "Thị trấn",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2716,
    Code: "07402",
    Name: "Xã Mỹ Hà",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2717,
    Code: "07405",
    Name: "Xã Hương Lạc",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2718,
    Code: "07408",
    Name: "Xã Dương Đức",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2719,
    Code: "07411",
    Name: "Xã Tân Thanh",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2720,
    Code: "07414",
    Name: "Xã Yên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2721,
    Code: "07417",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2722,
    Code: "07420",
    Name: "Xã Mỹ Thái",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2723,
    Code: "07426",
    Name: "Xã Xương Lâm",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2724,
    Code: "07429",
    Name: "Xã Xuân Hương",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2725,
    Code: "07432",
    Name: "Xã Tân Dĩnh",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2726,
    Code: "07435",
    Name: "Xã Đại Lâm",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2727,
    Code: "07438",
    Name: "Xã Thái Đào",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 2728,
    Code: "07444",
    Name: "Thị trấn Đồi Ngô",
    LevelUp: "Thị trấn",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2729,
    Code: "07450",
    Name: "Xã Đông Hưng",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2730,
    Code: "07453",
    Name: "Xã Đông Phú",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2731,
    Code: "07456",
    Name: "Xã Tam Dị",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2732,
    Code: "07459",
    Name: "Xã Bảo Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2733,
    Code: "07462",
    Name: "Xã Bảo Đài",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2734,
    Code: "07465",
    Name: "Xã Thanh Lâm",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2735,
    Code: "07468",
    Name: "Xã Tiên Nha",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2736,
    Code: "07471",
    Name: "Xã Trường Giang",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2737,
    Code: "07477",
    Name: "Xã Phương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2738,
    Code: "07480",
    Name: "Xã Chu Điện",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2739,
    Code: "07483",
    Name: "Xã Cương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2740,
    Code: "07486",
    Name: "Xã Nghĩa Phương",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2741,
    Code: "07489",
    Name: "Xã Vô Tranh",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2742,
    Code: "07492",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2743,
    Code: "07495",
    Name: "Xã Lan Mẫu",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2744,
    Code: "07498",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2745,
    Code: "07501",
    Name: "Xã Khám Lạng",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2746,
    Code: "07504",
    Name: "Xã Huyền Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2747,
    Code: "07507",
    Name: "Xã Trường Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2748,
    Code: "07510",
    Name: "Xã Lục Sơn",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2749,
    Code: "07513",
    Name: "Xã Bắc Lũng",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2750,
    Code: "07516",
    Name: "Xã Vũ Xá",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2751,
    Code: "07519",
    Name: "Xã Cẩm Lý",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2752,
    Code: "07522",
    Name: "Xã Đan Hội",
    LevelUp: "Xã",
    DistrictCode: "1805",
    Active: -1,
  },
  {
    ID: 2753,
    Code: "07525",
    Name: "Thị trấn Chũ",
    LevelUp: "Thị trấn",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2754,
    Code: "07528",
    Name: "Xã Cấm Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2755,
    Code: "07531",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2756,
    Code: "07534",
    Name: "Xã Phong Minh",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2757,
    Code: "07537",
    Name: "Xã Phong Vân",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2758,
    Code: "07540",
    Name: "Xã Xa Lý",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2759,
    Code: "07543",
    Name: "Xã Hộ Đáp",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2760,
    Code: "07546",
    Name: "Xã Sơn Hải",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2761,
    Code: "07549",
    Name: "Xã Thanh Hải",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2762,
    Code: "07552",
    Name: "Xã Kiên Lao",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2763,
    Code: "07555",
    Name: "Xã Biên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2764,
    Code: "07558",
    Name: "Xã Kiên Thành",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2765,
    Code: "07561",
    Name: "Xã Hồng Giang",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2766,
    Code: "07564",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2767,
    Code: "07567",
    Name: "Xã Tân Hoa",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2768,
    Code: "07570",
    Name: "Xã Giáp Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2769,
    Code: "07573",
    Name: "Xã Biển Động",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2770,
    Code: "07576",
    Name: "Xã Quý Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2771,
    Code: "07579",
    Name: "Xã Trù Hựu",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2772,
    Code: "07582",
    Name: "Xã Phì Điền",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2773,
    Code: "07588",
    Name: "Xã Tân Quang",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2774,
    Code: "07591",
    Name: "Xã Đồng Cốc",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2775,
    Code: "07594",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2776,
    Code: "07597",
    Name: "Xã Phú Nhuận",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2777,
    Code: "07600",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2778,
    Code: "07603",
    Name: "Xã Nam Dương",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2779,
    Code: "07606",
    Name: "Xã Tân Mộc",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2780,
    Code: "07609",
    Name: "Xã Đèo Gia",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2781,
    Code: "07612",
    Name: "Xã Phượng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 2782,
    Code: "07615",
    Name: "Thị trấn An Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2783,
    Code: "07616",
    Name: "Thị trấn Tây Yên Tử",
    LevelUp: "Thị trấn",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2784,
    Code: "07621",
    Name: "Xã Vân Sơn",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2785,
    Code: "07624",
    Name: "Xã Hữu Sản",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2786,
    Code: "07627",
    Name: "Xã Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2787,
    Code: "07630",
    Name: "Xã Phúc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2788,
    Code: "07636",
    Name: "Xã Giáo Liêm",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2789,
    Code: "07642",
    Name: "Xã Cẩm Đàn",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2790,
    Code: "07645",
    Name: "Xã An Lạc",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2791,
    Code: "07648",
    Name: "Xã Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2792,
    Code: "07651",
    Name: "Xã Yên Định",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2793,
    Code: "07654",
    Name: "Xã Lệ Viễn",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2794,
    Code: "07660",
    Name: "Xã An Bá",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2795,
    Code: "07663",
    Name: "Xã Tuấn Đạo",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2796,
    Code: "07666",
    Name: "Xã Dương Hưu",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2797,
    Code: "07672",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2798,
    Code: "07678",
    Name: "Xã Thanh Luận",
    LevelUp: "Xã",
    DistrictCode: "1804",
    Active: -1,
  },
  {
    ID: 2799,
    Code: "07681",
    Name: "Thị trấn Nham Biền",
    LevelUp: "Thị trấn",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2800,
    Code: "07682",
    Name: "Thị trấn Tân An",
    LevelUp: "Thị trấn",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2801,
    Code: "07684",
    Name: "Xã Lão Hộ",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2802,
    Code: "07690",
    Name: "Xã Hương Gián",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2803,
    Code: "07702",
    Name: "Xã Quỳnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2804,
    Code: "07708",
    Name: "Xã Nội Hoàng",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2805,
    Code: "07711",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2806,
    Code: "07714",
    Name: "Xã Xuân Phú",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2807,
    Code: "07717",
    Name: "Xã Tân Liễu",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2808,
    Code: "07720",
    Name: "Xã Trí Yên",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2809,
    Code: "07723",
    Name: "Xã Lãng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2810,
    Code: "07726",
    Name: "Xã Yên Lư",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2811,
    Code: "07729",
    Name: "Xã Tiến Dũng",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2812,
    Code: "07735",
    Name: "Xã Đức Giang",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2813,
    Code: "07738",
    Name: "Xã Cảnh Thụy",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2814,
    Code: "07741",
    Name: "Xã Tư Mại",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2815,
    Code: "07747",
    Name: "Xã Đồng Việt",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2816,
    Code: "07750",
    Name: "Xã Đồng Phúc",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 2817,
    Code: "07759",
    Name: "Xã Thượng Lan",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2818,
    Code: "07762",
    Name: "Xã Việt Tiến",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2819,
    Code: "07765",
    Name: "Xã Nghĩa Trung",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2820,
    Code: "07768",
    Name: "Xã Minh Đức",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2821,
    Code: "07771",
    Name: "Xã Hương Mai",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2822,
    Code: "07774",
    Name: "Xã Tự Lạn",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2823,
    Code: "07777",
    Name: "Thị trấn Bích Động",
    LevelUp: "Thị trấn",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2824,
    Code: "07780",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2825,
    Code: "07783",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2826,
    Code: "07786",
    Name: "Xã Tiên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2827,
    Code: "07789",
    Name: "Xã Tăng Tiến",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2828,
    Code: "07792",
    Name: "Xã Quảng Minh",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2829,
    Code: "07795",
    Name: "Thị trấn Nếnh",
    LevelUp: "Thị trấn",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2830,
    Code: "07798",
    Name: "Xã Ninh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2831,
    Code: "07801",
    Name: "Xã Vân Trung",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2832,
    Code: "07804",
    Name: "Xã Vân Hà",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2833,
    Code: "07807",
    Name: "Xã Quang Châu",
    LevelUp: "Xã",
    DistrictCode: "1809",
    Active: -1,
  },
  {
    ID: 2834,
    Code: "07813",
    Name: "Xã Đồng Tân",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2835,
    Code: "07816",
    Name: "Xã Thanh Vân",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2836,
    Code: "07819",
    Name: "Xã Hoàng Lương",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2837,
    Code: "07822",
    Name: "Xã Hoàng Vân",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2838,
    Code: "07825",
    Name: "Xã Hoàng Thanh",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2839,
    Code: "07828",
    Name: "Xã Hoàng An",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2840,
    Code: "07831",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2841,
    Code: "07834",
    Name: "Xã Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2842,
    Code: "07837",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2843,
    Code: "07840",
    Name: "Thị trấn Thắng",
    LevelUp: "Thị trấn",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2844,
    Code: "07843",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2845,
    Code: "07846",
    Name: "Xã Lương Phong",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2846,
    Code: "07849",
    Name: "Xã Hùng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2847,
    Code: "07852",
    Name: "Xã Đại Thành",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2848,
    Code: "07855",
    Name: "Xã Thường Thắng",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2849,
    Code: "07858",
    Name: "Xã Hợp Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2850,
    Code: "07861",
    Name: "Xã Danh Thắng",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2851,
    Code: "07864",
    Name: "Xã Mai Trung",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2852,
    Code: "07867",
    Name: "Xã Đoan Bái",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2853,
    Code: "07870",
    Name: "Xã Bắc Lý",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2854,
    Code: "07873",
    Name: "Xã Xuân Cẩm",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2855,
    Code: "07876",
    Name: "Xã Hương Lâm",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2856,
    Code: "07879",
    Name: "Xã Đông Lỗ",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2857,
    Code: "07882",
    Name: "Xã Châu Minh",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2858,
    Code: "07885",
    Name: "Xã Mai Đình",
    LevelUp: "Xã",
    DistrictCode: "1807",
    Active: -1,
  },
  {
    ID: 2859,
    Code: "07888",
    Name: "Phường Dữu Lâu",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2860,
    Code: "07891",
    Name: "Phường Vân Cơ",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2861,
    Code: "07894",
    Name: "Phường Nông Trang",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2862,
    Code: "07897",
    Name: "Phường Tân Dân",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2863,
    Code: "07900",
    Name: "Phường Gia Cẩm",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2864,
    Code: "07903",
    Name: "Phường Tiên Cát",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2865,
    Code: "07906",
    Name: "Phường Thọ Sơn",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2866,
    Code: "07909",
    Name: "Phường Thanh Miếu",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2867,
    Code: "07912",
    Name: "Phường Bạch Hạc",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2868,
    Code: "07915",
    Name: "Phường Bến Gót",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2869,
    Code: "07918",
    Name: "Phường Vân Phú",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2870,
    Code: "07921",
    Name: "Xã Phượng Lâu",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2871,
    Code: "07924",
    Name: "Xã Thụy Vân",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2872,
    Code: "07927",
    Name: "Phường Minh Phương",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2873,
    Code: "07930",
    Name: "Xã Trưng Vương",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2874,
    Code: "07933",
    Name: "Phường Minh Nông",
    LevelUp: "Phường",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2875,
    Code: "07936",
    Name: "Xã Sông Lô",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2876,
    Code: "08281",
    Name: "Xã Kim Đức",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2877,
    Code: "08287",
    Name: "Xã Hùng Lô",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2878,
    Code: "08503",
    Name: "Xã Hy Cương",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2879,
    Code: "08506",
    Name: "Xã Chu Hóa",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2880,
    Code: "08515",
    Name: "Xã Thanh Đình",
    LevelUp: "Xã",
    DistrictCode: "1501",
    Active: -1,
  },
  {
    ID: 2881,
    Code: "07942",
    Name: "Phường Hùng Vương",
    LevelUp: "Phường",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2882,
    Code: "07945",
    Name: "Phường Phong Châu",
    LevelUp: "Phường",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2883,
    Code: "07948",
    Name: "Phường Âu Cơ",
    LevelUp: "Phường",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2884,
    Code: "07951",
    Name: "Xã Hà Lộc",
    LevelUp: "Xã",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2885,
    Code: "07954",
    Name: "Xã Phú Hộ",
    LevelUp: "Xã",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2886,
    Code: "07957",
    Name: "Xã Văn Lung",
    LevelUp: "Xã",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2887,
    Code: "07960",
    Name: "Xã Thanh Minh",
    LevelUp: "Xã",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2888,
    Code: "07963",
    Name: "Xã Hà Thạch",
    LevelUp: "Xã",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2889,
    Code: "07966",
    Name: "Phường Thanh Vinh",
    LevelUp: "Phường",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 2890,
    Code: "07969",
    Name: "Thị trấn Đoan Hùng",
    LevelUp: "Thị trấn",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2891,
    Code: "07975",
    Name: "Xã Hùng Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2892,
    Code: "07981",
    Name: "Xã Bằng Luân",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2893,
    Code: "07984",
    Name: "Xã Vân Du",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2894,
    Code: "07987",
    Name: "Xã Phú Lâm",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2895,
    Code: "07993",
    Name: "Xã Minh Lương",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2896,
    Code: "07996",
    Name: "Xã Bằng Doãn",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2897,
    Code: "07999",
    Name: "Xã Chí Đám",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2898,
    Code: "08005",
    Name: "Xã Phúc Lai",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2899,
    Code: "08008",
    Name: "Xã Ngọc Quan",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2900,
    Code: "08014",
    Name: "Xã Hợp Nhất",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2901,
    Code: "08017",
    Name: "Xã Sóc Đăng",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2902,
    Code: "08023",
    Name: "Xã Tây Cốc",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2903,
    Code: "08026",
    Name: "Xã Yên Kiện",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2904,
    Code: "08029",
    Name: "Xã Hùng Long",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2905,
    Code: "08032",
    Name: "Xã Vụ Quang",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2906,
    Code: "08035",
    Name: "Xã Vân Đồn",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2907,
    Code: "08038",
    Name: "Xã Tiêu Sơn",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2908,
    Code: "08041",
    Name: "Xã Minh Tiến",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2909,
    Code: "08044",
    Name: "Xã Minh Phú",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2910,
    Code: "08047",
    Name: "Xã Chân Mộng",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2911,
    Code: "08050",
    Name: "Xã Ca Đình",
    LevelUp: "Xã",
    DistrictCode: "1503",
    Active: -1,
  },
  {
    ID: 2912,
    Code: "08053",
    Name: "Thị trấn Hạ Hoà",
    LevelUp: "Thị trấn",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2913,
    Code: "08056",
    Name: "Xã Đại Phạm",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2914,
    Code: "08062",
    Name: "Xã Đan Thượng",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2915,
    Code: "08065",
    Name: "Xã Hà Lương",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2916,
    Code: "08071",
    Name: "Xã Tứ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2917,
    Code: "08080",
    Name: "Xã Hiền Lương",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2918,
    Code: "08089",
    Name: "Xã Phương Viên",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2919,
    Code: "08092",
    Name: "Xã Gia Điền",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2920,
    Code: "08095",
    Name: "Xã Ấm Hạ",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2921,
    Code: "08104",
    Name: "Xã Hương Xạ",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2922,
    Code: "08110",
    Name: "Xã Xuân Áng",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2923,
    Code: "08113",
    Name: "Xã Yên Kỳ",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2924,
    Code: "08119",
    Name: "Xã Minh Hạc",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2925,
    Code: "08122",
    Name: "Xã Lang Sơn",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2926,
    Code: "08125",
    Name: "Xã Bằng Giã",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2927,
    Code: "08128",
    Name: "Xã Yên Luật",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2928,
    Code: "08131",
    Name: "Xã Vô Tranh",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2929,
    Code: "08134",
    Name: "Xã Văn Lang",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2930,
    Code: "08140",
    Name: "Xã Minh Côi",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2931,
    Code: "08143",
    Name: "Xã Vĩnh Chân",
    LevelUp: "Xã",
    DistrictCode: "1505",
    Active: -1,
  },
  {
    ID: 2932,
    Code: "08152",
    Name: "Thị trấn Thanh Ba",
    LevelUp: "Thị trấn",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2933,
    Code: "08156",
    Name: "Xã Vân Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2934,
    Code: "08158",
    Name: "Xã Đông Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2935,
    Code: "08161",
    Name: "Xã Đại An",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2936,
    Code: "08164",
    Name: "Xã Hanh Cù",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2937,
    Code: "08170",
    Name: "Xã Đồng Xuân",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2938,
    Code: "08173",
    Name: "Xã Quảng Yên",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2939,
    Code: "08179",
    Name: "Xã Ninh Dân",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2940,
    Code: "08194",
    Name: "Xã Võ Lao",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2941,
    Code: "08197",
    Name: "Xã Khải Xuân",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2942,
    Code: "08200",
    Name: "Xã Mạn Lạn",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2943,
    Code: "08203",
    Name: "Xã Hoàng Cương",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2944,
    Code: "08206",
    Name: "Xã Chí Tiên",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2945,
    Code: "08209",
    Name: "Xã Đông Thành",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2946,
    Code: "08215",
    Name: "Xã Sơn Cương",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2947,
    Code: "08218",
    Name: "Xã Thanh Hà",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2948,
    Code: "08221",
    Name: "Xã Đỗ Sơn",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2949,
    Code: "08224",
    Name: "Xã Đỗ Xuyên",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2950,
    Code: "08227",
    Name: "Xã Lương Lỗ",
    LevelUp: "Xã",
    DistrictCode: "1504",
    Active: -1,
  },
  {
    ID: 2951,
    Code: "08230",
    Name: "Thị trấn Phong Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2952,
    Code: "08233",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2953,
    Code: "08234",
    Name: "Xã Lệ Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2954,
    Code: "08236",
    Name: "Xã Liên Hoa",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2955,
    Code: "08239",
    Name: "Xã Trạm Thản",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2956,
    Code: "08242",
    Name: "Xã Trị Quận",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2957,
    Code: "08245",
    Name: "Xã Trung Giáp",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2958,
    Code: "08248",
    Name: "Xã Tiên Phú",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2959,
    Code: "08251",
    Name: "Xã Hạ Giáp",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2960,
    Code: "08254",
    Name: "Xã Bảo Thanh",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2961,
    Code: "08257",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2962,
    Code: "08260",
    Name: "Xã Gia Thanh",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2963,
    Code: "08263",
    Name: "Xã Tiên Du",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2964,
    Code: "08266",
    Name: "Xã Phú Nham",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2965,
    Code: "08272",
    Name: "Xã An Đạo",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2966,
    Code: "08275",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2967,
    Code: "08278",
    Name: "Xã Phù Ninh",
    LevelUp: "Xã",
    DistrictCode: "1509",
    Active: -1,
  },
  {
    ID: 2968,
    Code: "08290",
    Name: "Thị trấn Yên Lập",
    LevelUp: "Thị trấn",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2969,
    Code: "08293",
    Name: "Xã Mỹ Lung",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2970,
    Code: "08296",
    Name: "Xã Mỹ Lương",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2971,
    Code: "08299",
    Name: "Xã Lương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2972,
    Code: "08302",
    Name: "Xã Xuân An",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2973,
    Code: "08305",
    Name: "Xã Xuân Viên",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2974,
    Code: "08308",
    Name: "Xã Xuân Thủy",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2975,
    Code: "08311",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2976,
    Code: "08314",
    Name: "Xã Hưng Long",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2977,
    Code: "08317",
    Name: "Xã Nga Hoàng",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2978,
    Code: "08320",
    Name: "Xã Đồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2979,
    Code: "08323",
    Name: "Xã Thượng Long",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2980,
    Code: "08326",
    Name: "Xã Đồng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2981,
    Code: "08329",
    Name: "Xã Phúc Khánh",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2982,
    Code: "08332",
    Name: "Xã Minh Hòa",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2983,
    Code: "08335",
    Name: "Xã Ngọc Lập",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2984,
    Code: "08338",
    Name: "Xã Ngọc Đồng",
    LevelUp: "Xã",
    DistrictCode: "1507",
    Active: -1,
  },
  {
    ID: 2985,
    Code: "08341",
    Name: "Thị trấn Cẩm Khê",
    LevelUp: "Thị trấn",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2986,
    Code: "08344",
    Name: "Xã Tiên Lương",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2987,
    Code: "08347",
    Name: "Xã Tuy Lộc",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2988,
    Code: "08350",
    Name: "Xã Ngô Xá",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2989,
    Code: "08353",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2990,
    Code: "08356",
    Name: "Xã Phượng Vĩ",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2991,
    Code: "08362",
    Name: "Xã Thụy Liễu",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2992,
    Code: "08374",
    Name: "Xã Tùng Khê",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2993,
    Code: "08377",
    Name: "Xã Tam Sơn",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2994,
    Code: "08380",
    Name: "Xã Văn Bán",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2995,
    Code: "08383",
    Name: "Xã Cấp Dẫn",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2996,
    Code: "08389",
    Name: "Xã Xương Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2997,
    Code: "08392",
    Name: "Xã Phú Khê",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2998,
    Code: "08395",
    Name: "Xã Sơn Tình",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 2999,
    Code: "08398",
    Name: "Xã Yên Tập",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3000,
    Code: "08401",
    Name: "Xã Hương Lung",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3001,
    Code: "08404",
    Name: "Xã Tạ Xá",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3002,
    Code: "08407",
    Name: "Xã Phú Lạc",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3003,
    Code: "08413",
    Name: "Xã Chương Xá",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3004,
    Code: "08416",
    Name: "Xã Hùng Việt",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3005,
    Code: "08419",
    Name: "Xã Văn Khúc",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3006,
    Code: "08422",
    Name: "Xã Yên Dưỡng",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3007,
    Code: "08428",
    Name: "Xã Điêu Lương",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3008,
    Code: "08431",
    Name: "Xã Đồng Lương",
    LevelUp: "Xã",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 3009,
    Code: "08434",
    Name: "Thị trấn Hưng Hoá",
    LevelUp: "Thị trấn",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3010,
    Code: "08440",
    Name: "Xã Hiền Quan",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3011,
    Code: "08443",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3012,
    Code: "08446",
    Name: "Xã Thanh Uyên",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3013,
    Code: "08461",
    Name: "Xã Lam Sơn",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3014,
    Code: "08467",
    Name: "Xã Vạn Xuân",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3015,
    Code: "08470",
    Name: "Xã Quang Húc",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3016,
    Code: "08473",
    Name: "Xã Hương Nộn",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3017,
    Code: "08476",
    Name: "Xã Tề Lễ",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3018,
    Code: "08479",
    Name: "Xã Thọ Văn",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3019,
    Code: "08482",
    Name: "Xã Dị Nậu",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3020,
    Code: "08491",
    Name: "Xã Dân Quyền",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 3021,
    Code: "08494",
    Name: "Thị trấn Lâm Thao",
    LevelUp: "Thị trấn",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3022,
    Code: "08497",
    Name: "Xã Tiên Kiên",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3023,
    Code: "08498",
    Name: "Thị trấn Hùng Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3024,
    Code: "08500",
    Name: "Xã Xuân Lũng",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3025,
    Code: "08509",
    Name: "Xã Xuân Huy",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3026,
    Code: "08512",
    Name: "Xã Thạch Sơn",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3027,
    Code: "08518",
    Name: "Xã Sơn Vi",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3028,
    Code: "08521",
    Name: "Xã Phùng Nguyên",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3029,
    Code: "08527",
    Name: "Xã Cao Xá",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3030,
    Code: "08533",
    Name: "Xã Vĩnh Lại",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3031,
    Code: "08536",
    Name: "Xã Tứ Xã",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3032,
    Code: "08539",
    Name: "Xã Bản Nguyên",
    LevelUp: "Xã",
    DistrictCode: "1510",
    Active: -1,
  },
  {
    ID: 3033,
    Code: "08542",
    Name: "Thị trấn Thanh Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3034,
    Code: "08563",
    Name: "Xã Sơn Hùng",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3035,
    Code: "08572",
    Name: "Xã Địch Quả",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3036,
    Code: "08575",
    Name: "Xã Giáp Lai",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3037,
    Code: "08581",
    Name: "Xã Thục Luyện",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3038,
    Code: "08584",
    Name: "Xã Võ Miếu",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3039,
    Code: "08587",
    Name: "Xã Thạch Khoán",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3040,
    Code: "08602",
    Name: "Xã Cự Thắng",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3041,
    Code: "08605",
    Name: "Xã Tất Thắng",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3042,
    Code: "08611",
    Name: "Xã Văn Miếu",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3043,
    Code: "08614",
    Name: "Xã Cự Đồng",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3044,
    Code: "08623",
    Name: "Xã Thắng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3045,
    Code: "08629",
    Name: "Xã Tân Minh",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3046,
    Code: "08632",
    Name: "Xã Hương Cần",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3047,
    Code: "08635",
    Name: "Xã Khả Cửu",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3048,
    Code: "08638",
    Name: "Xã Đông Cửu",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3049,
    Code: "08641",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3050,
    Code: "08644",
    Name: "Xã Yên Lãng",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3051,
    Code: "08647",
    Name: "Xã Yên Lương",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3052,
    Code: "08650",
    Name: "Xã Thượng Cửu",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3053,
    Code: "08653",
    Name: "Xã Lương Nha",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3054,
    Code: "08656",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3055,
    Code: "08659",
    Name: "Xã Tinh Nhuệ",
    LevelUp: "Xã",
    DistrictCode: "1508",
    Active: -1,
  },
  {
    ID: 3056,
    Code: "08662",
    Name: "Xã Đào Xá",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3057,
    Code: "08665",
    Name: "Xã Thạch Đồng",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3058,
    Code: "08668",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3059,
    Code: "08671",
    Name: "Xã Tân Phương",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3060,
    Code: "08674",
    Name: "Thị trấn Thanh Thủy",
    LevelUp: "Thị trấn",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3061,
    Code: "08677",
    Name: "Xã Sơn Thủy",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3062,
    Code: "08680",
    Name: "Xã Bảo Yên",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3063,
    Code: "08683",
    Name: "Xã Đoan Hạ",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3064,
    Code: "08686",
    Name: "Xã Đồng Trung",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3065,
    Code: "08689",
    Name: "Xã Hoàng Xá",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3066,
    Code: "08701",
    Name: "Xã Tu Vũ",
    LevelUp: "Xã",
    DistrictCode: "1512",
    Active: -1,
  },
  {
    ID: 3067,
    Code: "08545",
    Name: "Xã Thu Cúc",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3068,
    Code: "08548",
    Name: "Xã Thạch Kiệt",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3069,
    Code: "08551",
    Name: "Xã Thu Ngạc",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3070,
    Code: "08554",
    Name: "Xã Kiệt Sơn",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3071,
    Code: "08557",
    Name: "Xã Đồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3072,
    Code: "08560",
    Name: "Xã Lai Đồng",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3073,
    Code: "08566",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3074,
    Code: "08569",
    Name: "Xã Mỹ Thuận",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3075,
    Code: "08578",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3076,
    Code: "08590",
    Name: "Xã Xuân Đài",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3077,
    Code: "08593",
    Name: "Xã Minh Đài",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3078,
    Code: "08596",
    Name: "Xã Văn Luông",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3079,
    Code: "08599",
    Name: "Xã Xuân Sơn",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3080,
    Code: "08608",
    Name: "Xã Long Cốc",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3081,
    Code: "08617",
    Name: "Xã Kim Thượng",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3082,
    Code: "08620",
    Name: "Xã Tam Thanh",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3083,
    Code: "08626",
    Name: "Xã Vinh Tiền",
    LevelUp: "Xã",
    DistrictCode: "1513",
    Active: -1,
  },
  {
    ID: 3084,
    Code: "08707",
    Name: "Phường Tích Sơn",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3085,
    Code: "08710",
    Name: "Phường Liên Bảo",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3086,
    Code: "08713",
    Name: "Phường Hội Hợp",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3087,
    Code: "08716",
    Name: "Phường Đống Đa",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3088,
    Code: "08719",
    Name: "Phường Ngô Quyền",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3089,
    Code: "08722",
    Name: "Phường Đồng Tâm",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3090,
    Code: "08725",
    Name: "Xã Định Trung",
    LevelUp: "Xã",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3091,
    Code: "08728",
    Name: "Phường Khai Quang",
    LevelUp: "Phường",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3092,
    Code: "08731",
    Name: "Xã Thanh Trù",
    LevelUp: "Xã",
    DistrictCode: "1601",
    Active: -1,
  },
  {
    ID: 3093,
    Code: "08734",
    Name: "Phường Trưng Trắc",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3094,
    Code: "08737",
    Name: "Phường Hùng Vương",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3095,
    Code: "08740",
    Name: "Phường Trưng Nhị",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3096,
    Code: "08743",
    Name: "Phường Phúc Thắng",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3097,
    Code: "08746",
    Name: "Phường Xuân Hoà",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3098,
    Code: "08747",
    Name: "Phường Đồng Xuân",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3099,
    Code: "08749",
    Name: "Xã Ngọc Thanh",
    LevelUp: "Xã",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3100,
    Code: "08752",
    Name: "Xã Cao Minh",
    LevelUp: "Xã",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3101,
    Code: "08755",
    Name: "Phường Nam Viêm",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3102,
    Code: "08758",
    Name: "Phường Tiền Châu",
    LevelUp: "Phường",
    DistrictCode: "1608",
    Active: -1,
  },
  {
    ID: 3103,
    Code: "08761",
    Name: "Thị trấn Lập Thạch",
    LevelUp: "Thị trấn",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3104,
    Code: "08764",
    Name: "Xã Quang Sơn",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3105,
    Code: "08767",
    Name: "Xã Ngọc Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3106,
    Code: "08770",
    Name: "Xã Hợp Lý",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3107,
    Code: "08785",
    Name: "Xã Bắc Bình",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3108,
    Code: "08788",
    Name: "Xã Thái Hòa",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3109,
    Code: "08789",
    Name: "Thị trấn Hoa Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3110,
    Code: "08791",
    Name: "Xã Liễn Sơn",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3111,
    Code: "08794",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3112,
    Code: "08797",
    Name: "Xã Vân Trục",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3113,
    Code: "08812",
    Name: "Xã Liên Hòa",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3114,
    Code: "08815",
    Name: "Xã Tử Du",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3115,
    Code: "08833",
    Name: "Xã Bàn Giản",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3116,
    Code: "08836",
    Name: "Xã Xuân Lôi",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3117,
    Code: "08839",
    Name: "Xã Đồng Ích",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3118,
    Code: "08842",
    Name: "Xã Tiên Lữ",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3119,
    Code: "08845",
    Name: "Xã Văn Quán",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3120,
    Code: "08857",
    Name: "Xã Đình Chu",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3121,
    Code: "08863",
    Name: "Xã Triệu Đề",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3122,
    Code: "08866",
    Name: "Xã Sơn Đông",
    LevelUp: "Xã",
    DistrictCode: "1603",
    Active: -1,
  },
  {
    ID: 3123,
    Code: "08869",
    Name: "Thị trấn Hợp Hòa",
    LevelUp: "Thị trấn",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3124,
    Code: "08872",
    Name: "Xã Hoàng Hoa",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3125,
    Code: "08875",
    Name: "Xã Đồng Tĩnh",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3126,
    Code: "08878",
    Name: "Xã Kim Long",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3127,
    Code: "08881",
    Name: "Xã Hướng Đạo",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3128,
    Code: "08884",
    Name: "Xã Đạo Tú",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3129,
    Code: "08887",
    Name: "Xã An Hòa",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3130,
    Code: "08890",
    Name: "Xã Thanh Vân",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3131,
    Code: "08893",
    Name: "Xã Duy Phiên",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3132,
    Code: "08896",
    Name: "Xã Hoàng Đan",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3133,
    Code: "08899",
    Name: "Xã Hoàng Lâu",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3134,
    Code: "08902",
    Name: "Xã Vân Hội",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3135,
    Code: "08905",
    Name: "Xã Hợp Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1602",
    Active: -1,
  },
  {
    ID: 3136,
    Code: "08908",
    Name: "Thị trấn Tam Đảo",
    LevelUp: "Thị trấn",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3137,
    Code: "08911",
    Name: "Thị trấn Hợp Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3138,
    Code: "08914",
    Name: "Xã Đạo Trù",
    LevelUp: "Xã",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3139,
    Code: "08917",
    Name: "Xã Yên Dương",
    LevelUp: "Xã",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3140,
    Code: "08920",
    Name: "Xã Bồ Lý",
    LevelUp: "Xã",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3141,
    Code: "08923",
    Name: "Thị trấn Đại Đình",
    LevelUp: "Thị trấn",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3142,
    Code: "08926",
    Name: "Xã Tam Quan",
    LevelUp: "Xã",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3143,
    Code: "08929",
    Name: "Xã Hồ Sơn",
    LevelUp: "Xã",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3144,
    Code: "08932",
    Name: "Xã Minh Quang",
    LevelUp: "Xã",
    DistrictCode: "1609",
    Active: -1,
  },
  {
    ID: 3145,
    Code: "08935",
    Name: "Thị trấn Hương Canh",
    LevelUp: "Thị trấn",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3146,
    Code: "08936",
    Name: "Thị trấn Gia Khánh",
    LevelUp: "Thị trấn",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3147,
    Code: "08938",
    Name: "Xã Trung Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3148,
    Code: "08944",
    Name: "Thị trấn Bá Hiến",
    LevelUp: "Thị trấn",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3149,
    Code: "08947",
    Name: "Xã Thiện Kế",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3150,
    Code: "08950",
    Name: "Xã Hương Sơn",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3151,
    Code: "08953",
    Name: "Xã Tam Hợp",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3152,
    Code: "08956",
    Name: "Xã Quất Lưu",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3153,
    Code: "08959",
    Name: "Xã Sơn Lôi",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3154,
    Code: "08962",
    Name: "Thị trấn Đạo Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3155,
    Code: "08965",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3156,
    Code: "08968",
    Name: "Thị trấn Thanh Lãng",
    LevelUp: "Thị trấn",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3157,
    Code: "08971",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "1606",
    Active: -1,
  },
  {
    ID: 3158,
    Code: "09025",
    Name: "Thị trấn Yên Lạc",
    LevelUp: "Thị trấn",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3159,
    Code: "09028",
    Name: "Xã Đồng Cương",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3160,
    Code: "09031",
    Name: "Xã Đồng Văn",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3161,
    Code: "09034",
    Name: "Xã Bình Định",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3162,
    Code: "09037",
    Name: "Xã Trung Nguyên",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3163,
    Code: "09040",
    Name: "Xã Tề Lỗ",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3164,
    Code: "09043",
    Name: "Xã Tam Hồng",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3165,
    Code: "09046",
    Name: "Xã Yên Đồng",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3166,
    Code: "09049",
    Name: "Xã Văn Tiến",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3167,
    Code: "09052",
    Name: "Xã Nguyệt Đức",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3168,
    Code: "09055",
    Name: "Xã Yên Phương",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3169,
    Code: "09058",
    Name: "Xã Hồng Phương",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3170,
    Code: "09061",
    Name: "Xã Trung Kiên",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3171,
    Code: "09064",
    Name: "Xã Liên Châu",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3172,
    Code: "09067",
    Name: "Xã Đại Tự",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3173,
    Code: "09070",
    Name: "Xã Hồng Châu",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3174,
    Code: "09073",
    Name: "Xã Trung Hà",
    LevelUp: "Xã",
    DistrictCode: "1605",
    Active: -1,
  },
  {
    ID: 3175,
    Code: "09076",
    Name: "Thị trấn Vĩnh Tường",
    LevelUp: "Thị trấn",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3176,
    Code: "09079",
    Name: "Xã Kim Xá",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3177,
    Code: "09082",
    Name: "Xã Yên Bình",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3178,
    Code: "09085",
    Name: "Xã Chấn Hưng",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3179,
    Code: "09088",
    Name: "Xã Nghĩa Hưng",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3180,
    Code: "09091",
    Name: "Xã Yên Lập",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3181,
    Code: "09094",
    Name: "Xã Việt Xuân",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3182,
    Code: "09097",
    Name: "Xã Bồ Sao",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3183,
    Code: "09100",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3184,
    Code: "09103",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3185,
    Code: "09106",
    Name: "Xã Lũng Hoà",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3186,
    Code: "09109",
    Name: "Xã Cao Đại",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3187,
    Code: "09112",
    Name: "Thị Trấn Thổ Tang",
    LevelUp: "Thị trấn",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3188,
    Code: "09115",
    Name: "Xã Vĩnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3189,
    Code: "09118",
    Name: "Xã Bình Dương",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3190,
    Code: "09124",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3191,
    Code: "09127",
    Name: "Xã Thượng Trưng",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3192,
    Code: "09130",
    Name: "Xã Vũ Di",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3193,
    Code: "09133",
    Name: "Xã Lý Nhân",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3194,
    Code: "09136",
    Name: "Xã Tuân Chính",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3195,
    Code: "09139",
    Name: "Xã Vân Xuân",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3196,
    Code: "09142",
    Name: "Xã Tam Phúc",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3197,
    Code: "09145",
    Name: "Thị trấn Tứ Trưng",
    LevelUp: "Thị trấn",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3198,
    Code: "09148",
    Name: "Xã Ngũ Kiên",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3199,
    Code: "09151",
    Name: "Xã An Tường",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3200,
    Code: "09154",
    Name: "Xã Vĩnh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3201,
    Code: "09157",
    Name: "Xã Phú Đa",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3202,
    Code: "09160",
    Name: "Xã Vĩnh Ninh",
    LevelUp: "Xã",
    DistrictCode: "1604",
    Active: -1,
  },
  {
    ID: 3203,
    Code: "08773",
    Name: "Xã Lãng Công",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3204,
    Code: "08776",
    Name: "Xã Quang Yên",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3205,
    Code: "08779",
    Name: "Xã Bạch Lưu",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3206,
    Code: "08782",
    Name: "Xã Hải Lựu",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3207,
    Code: "08800",
    Name: "Xã Đồng Quế",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3208,
    Code: "08803",
    Name: "Xã Nhân Đạo",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3209,
    Code: "08806",
    Name: "Xã Đôn Nhân",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3210,
    Code: "08809",
    Name: "Xã Phương Khoan",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3211,
    Code: "08818",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3212,
    Code: "08821",
    Name: "Xã Nhạo Sơn",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3213,
    Code: "08824",
    Name: "Thị trấn Tam Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3214,
    Code: "08827",
    Name: "Xã Như Thụy",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3215,
    Code: "08830",
    Name: "Xã Yên Thạch",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3216,
    Code: "08848",
    Name: "Xã Đồng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3217,
    Code: "08851",
    Name: "Xã Tứ Yên",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3218,
    Code: "08854",
    Name: "Xã Đức Bác",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3219,
    Code: "08860",
    Name: "Xã Cao Phong",
    LevelUp: "Xã",
    DistrictCode: "1607",
    Active: -1,
  },
  {
    ID: 3220,
    Code: "09163",
    Name: "Phường Vũ Ninh",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3221,
    Code: "09166",
    Name: "Phường Đáp Cầu",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3222,
    Code: "09169",
    Name: "Phường Thị Cầu",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3223,
    Code: "09172",
    Name: "Phường Kinh Bắc",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3224,
    Code: "09175",
    Name: "Phường Vệ An",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3225,
    Code: "09178",
    Name: "Phường Tiền An",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3226,
    Code: "09181",
    Name: "Phường Đại Phúc",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3227,
    Code: "09184",
    Name: "Phường Ninh Xá",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3228,
    Code: "09187",
    Name: "Phường Suối Hoa",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3229,
    Code: "09190",
    Name: "Phường Võ Cường",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3230,
    Code: "09214",
    Name: "Phường Hòa Long",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3231,
    Code: "09226",
    Name: "Phường Vạn An",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3232,
    Code: "09235",
    Name: "Phường Khúc Xuyên",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3233,
    Code: "09244",
    Name: "Phường Phong Khê",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3234,
    Code: "09256",
    Name: "Phường Kim Chân",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3235,
    Code: "09271",
    Name: "Phường Vân Dương",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3236,
    Code: "09286",
    Name: "Phường Nam Sơn",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3237,
    Code: "09325",
    Name: "Phường Khắc Niệm",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3238,
    Code: "09331",
    Name: "Phường Hạp Lĩnh",
    LevelUp: "Phường",
    DistrictCode: "1901",
    Active: -1,
  },
  {
    ID: 3239,
    Code: "09193",
    Name: "Thị trấn Chờ",
    LevelUp: "Thị trấn",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3240,
    Code: "09196",
    Name: "Xã Dũng Liệt",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3241,
    Code: "09199",
    Name: "Xã Tam Đa",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3242,
    Code: "09202",
    Name: "Xã Tam Giang",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3243,
    Code: "09205",
    Name: "Xã Yên Trung",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3244,
    Code: "09208",
    Name: "Xã Thụy Hòa",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3245,
    Code: "09211",
    Name: "Xã Hòa Tiến",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3246,
    Code: "09217",
    Name: "Xã Đông Tiến",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3247,
    Code: "09220",
    Name: "Xã Yên Phụ",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3248,
    Code: "09223",
    Name: "Xã Trung Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3249,
    Code: "09229",
    Name: "Xã Đông Phong",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3250,
    Code: "09232",
    Name: "Xã Long Châu",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3251,
    Code: "09238",
    Name: "Xã Văn Môn",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3252,
    Code: "09241",
    Name: "Xã Đông Thọ",
    LevelUp: "Xã",
    DistrictCode: "1902",
    Active: -1,
  },
  {
    ID: 3253,
    Code: "09247",
    Name: "Thị trấn Phố Mới",
    LevelUp: "Thị trấn",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3254,
    Code: "09250",
    Name: "Xã Việt Thống",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3255,
    Code: "09253",
    Name: "Xã Đại Xuân",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3256,
    Code: "09259",
    Name: "Xã Nhân Hòa",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3257,
    Code: "09262",
    Name: "Xã Bằng An",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3258,
    Code: "09265",
    Name: "Xã Phương Liễu",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3259,
    Code: "09268",
    Name: "Xã Quế Tân",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3260,
    Code: "09274",
    Name: "Xã Phù Lương",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3261,
    Code: "09277",
    Name: "Xã Phù Lãng",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3262,
    Code: "09280",
    Name: "Xã Phượng Mao",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3263,
    Code: "09283",
    Name: "Xã Việt Hùng",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3264,
    Code: "09289",
    Name: "Xã Ngọc Xá",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3265,
    Code: "09292",
    Name: "Xã Châu Phong",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3266,
    Code: "09295",
    Name: "Xã Bồng Lai",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3267,
    Code: "09298",
    Name: "Xã Cách Bi",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3268,
    Code: "09301",
    Name: "Xã Đào Viên",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3269,
    Code: "09304",
    Name: "Xã Yên Giả",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3270,
    Code: "09307",
    Name: "Xã Mộ Đạo",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3271,
    Code: "09310",
    Name: "Xã Đức Long",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3272,
    Code: "09313",
    Name: "Xã Chi Lăng",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3273,
    Code: "09316",
    Name: "Xã Hán Quảng",
    LevelUp: "Xã",
    DistrictCode: "1903",
    Active: -1,
  },
  {
    ID: 3274,
    Code: "09319",
    Name: "Thị trấn Lim",
    LevelUp: "Thị trấn",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3275,
    Code: "09322",
    Name: "Xã Phú Lâm",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3276,
    Code: "09328",
    Name: "Xã Nội Duệ",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3277,
    Code: "09334",
    Name: "Xã Liên Bão",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3278,
    Code: "09337",
    Name: "Xã Hiên Vân",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3279,
    Code: "09340",
    Name: "Xã Hoàn Sơn",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3280,
    Code: "09343",
    Name: "Xã Lạc Vệ",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3281,
    Code: "09346",
    Name: "Xã Việt Đoàn",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3282,
    Code: "09349",
    Name: "Xã Phật Tích",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3283,
    Code: "09352",
    Name: "Xã Tân Chi",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3284,
    Code: "09355",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3285,
    Code: "09358",
    Name: "Xã Tri Phương",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3286,
    Code: "09361",
    Name: "Xã Minh Đạo",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3287,
    Code: "09364",
    Name: "Xã Cảnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "1904",
    Active: -1,
  },
  {
    ID: 3288,
    Code: "09367",
    Name: "Phường Đông Ngàn",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3289,
    Code: "09370",
    Name: "Xã Tam Sơn",
    LevelUp: "Xã",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3290,
    Code: "09373",
    Name: "Xã Hương Mạc",
    LevelUp: "Xã",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3291,
    Code: "09376",
    Name: "Xã Tương Giang",
    LevelUp: "Xã",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3292,
    Code: "09379",
    Name: "Xã Phù Khê",
    LevelUp: "Xã",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3293,
    Code: "09382",
    Name: "Phường Đồng Kỵ",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3294,
    Code: "09383",
    Name: "Phường Trang Hạ",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3295,
    Code: "09385",
    Name: "Phường Đồng Nguyên",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3296,
    Code: "09388",
    Name: "Phường Châu Khê",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3297,
    Code: "09391",
    Name: "Phường Tân Hồng",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3298,
    Code: "09394",
    Name: "Phường Đình Bảng",
    LevelUp: "Phường",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3299,
    Code: "09397",
    Name: "Xã Phù Chẩn",
    LevelUp: "Xã",
    DistrictCode: "1905",
    Active: -1,
  },
  {
    ID: 3300,
    Code: "09400",
    Name: "Thị trấn Hồ",
    LevelUp: "Thị trấn",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3301,
    Code: "09403",
    Name: "Xã Hoài Thượng",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3302,
    Code: "09406",
    Name: "Xã Đại Đồng Thành",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3303,
    Code: "09409",
    Name: "Xã Mão Điền",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3304,
    Code: "09412",
    Name: "Xã Song Hồ",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3305,
    Code: "09415",
    Name: "Xã Đình Tổ",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3306,
    Code: "09418",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3307,
    Code: "09421",
    Name: "Xã Trí Quả",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3308,
    Code: "09424",
    Name: "Xã Gia Đông",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3309,
    Code: "09427",
    Name: "Xã Thanh Khương",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3310,
    Code: "09430",
    Name: "Xã Trạm Lộ",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3311,
    Code: "09433",
    Name: "Xã Xuân Lâm",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3312,
    Code: "09436",
    Name: "Xã Hà Mãn",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3313,
    Code: "09439",
    Name: "Xã Ngũ Thái",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3314,
    Code: "09442",
    Name: "Xã Nguyệt Đức",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3315,
    Code: "09445",
    Name: "Xã Ninh Xá",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3316,
    Code: "09448",
    Name: "Xã Nghĩa Đạo",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3317,
    Code: "09451",
    Name: "Xã Song Liễu",
    LevelUp: "Xã",
    DistrictCode: "1906",
    Active: -1,
  },
  {
    ID: 3318,
    Code: "09454",
    Name: "Thị trấn Gia Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3319,
    Code: "09457",
    Name: "Xã Vạn Ninh",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3320,
    Code: "09460",
    Name: "Xã Thái Bảo",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3321,
    Code: "09463",
    Name: "Xã Giang Sơn",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3322,
    Code: "09466",
    Name: "Xã Cao Đức",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3323,
    Code: "09469",
    Name: "Xã Đại Lai",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3324,
    Code: "09472",
    Name: "Xã Song Giang",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3325,
    Code: "09475",
    Name: "Xã Bình Dương",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3326,
    Code: "09478",
    Name: "Xã Lãng Ngâm",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3327,
    Code: "09481",
    Name: "Xã Nhân Thắng",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3328,
    Code: "09484",
    Name: "Xã Xuân Lai",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3329,
    Code: "09487",
    Name: "Xã Đông Cứu",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3330,
    Code: "09490",
    Name: "Xã Đại Bái",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3331,
    Code: "09493",
    Name: "Xã Quỳnh Phú",
    LevelUp: "Xã",
    DistrictCode: "1907",
    Active: -1,
  },
  {
    ID: 3332,
    Code: "09496",
    Name: "Thị trấn Thứa",
    LevelUp: "Thị trấn",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3333,
    Code: "09499",
    Name: "Xã An Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3334,
    Code: "09502",
    Name: "Xã Trung Kênh",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3335,
    Code: "09505",
    Name: "Xã Phú Hòa",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3336,
    Code: "09508",
    Name: "Xã Mỹ Hương",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3337,
    Code: "09511",
    Name: "Xã Tân Lãng",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3338,
    Code: "09514",
    Name: "Xã Quảng Phú",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3339,
    Code: "09517",
    Name: "Xã Trừng Xá",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3340,
    Code: "09520",
    Name: "Xã Lai Hạ",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3341,
    Code: "09523",
    Name: "Xã Trung Chính",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3342,
    Code: "09526",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3343,
    Code: "09529",
    Name: "Xã Bình Định",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3344,
    Code: "09532",
    Name: "Xã Phú Lương",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3345,
    Code: "09535",
    Name: "Xã Lâm Thao",
    LevelUp: "Xã",
    DistrictCode: "1908",
    Active: -1,
  },
  {
    ID: 3346,
    Code: "10507",
    Name: "Phường Cẩm Thượng",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3347,
    Code: "10510",
    Name: "Phường Bình Hàn",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3348,
    Code: "10513",
    Name: "Phường Ngọc Châu",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3349,
    Code: "10514",
    Name: "Phường Nhị Châu",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3350,
    Code: "10516",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3351,
    Code: "10519",
    Name: "Phường Nguyễn Trãi",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3352,
    Code: "10522",
    Name: "Phường Phạm Ngũ Lão",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3353,
    Code: "10525",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3354,
    Code: "10528",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3355,
    Code: "10531",
    Name: "Phường Thanh Bình",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3356,
    Code: "10532",
    Name: "Phường Tân Bình",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3357,
    Code: "10534",
    Name: "Phường Lê Thanh Nghị",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3358,
    Code: "10537",
    Name: "Phường Hải Tân",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3359,
    Code: "10540",
    Name: "Phường Tứ Minh",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3360,
    Code: "10543",
    Name: "Phường Việt Hoà",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3361,
    Code: "10660",
    Name: "Phường Ái Quốc",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3362,
    Code: "10663",
    Name: "Xã An Thượng",
    LevelUp: "Xã",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3363,
    Code: "10672",
    Name: "Phường Nam Đồng",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3364,
    Code: "10822",
    Name: "Xã Quyết Thắng",
    LevelUp: "Xã",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3365,
    Code: "10837",
    Name: "Xã Tiền Tiến",
    LevelUp: "Xã",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3366,
    Code: "11002",
    Name: "Phường Thạch Khôi",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3367,
    Code: "11005",
    Name: "Xã Liên Hồng",
    LevelUp: "Xã",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3368,
    Code: "11011",
    Name: "Phường Tân Hưng",
    LevelUp: "Phường",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3369,
    Code: "11017",
    Name: "Xã Gia Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3370,
    Code: "11077",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2101",
    Active: -1,
  },
  {
    ID: 3371,
    Code: "10546",
    Name: "Phường Phả Lại",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3372,
    Code: "10549",
    Name: "Phường Sao Đỏ",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3373,
    Code: "10552",
    Name: "Phường Bến Tắm",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3374,
    Code: "10555",
    Name: "Xã Hoàng Hoa Thám",
    LevelUp: "Xã",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3375,
    Code: "10558",
    Name: "Xã Bắc An",
    LevelUp: "Xã",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3376,
    Code: "10561",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3377,
    Code: "10564",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3378,
    Code: "10567",
    Name: "Phường Hoàng Tiến",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3379,
    Code: "10570",
    Name: "Phường Cộng Hoà",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3380,
    Code: "10573",
    Name: "Phường Hoàng Tân",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3381,
    Code: "10576",
    Name: "Phường Cổ Thành",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3382,
    Code: "10579",
    Name: "Phường Văn An",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3383,
    Code: "10582",
    Name: "Phường Chí Minh",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3384,
    Code: "10585",
    Name: "Phường Văn Đức",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3385,
    Code: "10588",
    Name: "Phường Thái Học",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3386,
    Code: "10591",
    Name: "Xã Nhân Huệ",
    LevelUp: "Xã",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3387,
    Code: "10594",
    Name: "Phường An Lạc",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3388,
    Code: "10600",
    Name: "Phường Đồng Lạc",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3389,
    Code: "10603",
    Name: "Phường Tân Dân",
    LevelUp: "Phường",
    DistrictCode: "2102",
    Active: -1,
  },
  {
    ID: 3390,
    Code: "10606",
    Name: "Thị trấn Nam Sách",
    LevelUp: "Thị trấn",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3391,
    Code: "10609",
    Name: "Xã Nam Hưng",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3392,
    Code: "10612",
    Name: "Xã Nam Tân",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3393,
    Code: "10615",
    Name: "Xã Hợp Tiến",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3394,
    Code: "10618",
    Name: "Xã Hiệp Cát",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3395,
    Code: "10621",
    Name: "Xã Thanh Quang",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3396,
    Code: "10624",
    Name: "Xã Quốc Tuấn",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3397,
    Code: "10627",
    Name: "Xã Nam Chính",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3398,
    Code: "10630",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3399,
    Code: "10633",
    Name: "Xã Nam Trung",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3400,
    Code: "10636",
    Name: "Xã An Sơn",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3401,
    Code: "10639",
    Name: "Xã Cộng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3402,
    Code: "10642",
    Name: "Xã Thái Tân",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3403,
    Code: "10645",
    Name: "Xã An Lâm",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3404,
    Code: "10648",
    Name: "Xã Phú Điền",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3405,
    Code: "10651",
    Name: "Xã Nam Hồng",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3406,
    Code: "10654",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3407,
    Code: "10657",
    Name: "Xã Đồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3408,
    Code: "10666",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2103",
    Active: -1,
  },
  {
    ID: 3409,
    Code: "10675",
    Name: "Phường An Lưu",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3410,
    Code: "10678",
    Name: "Xã Bạch Đằng",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3411,
    Code: "10681",
    Name: "Phường Thất Hùng",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3412,
    Code: "10684",
    Name: "Xã Lê Ninh",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3413,
    Code: "10687",
    Name: "Xã Hoành Sơn",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3414,
    Code: "10693",
    Name: "Phường Phạm Thái",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3415,
    Code: "10696",
    Name: "Phường Duy Tân",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3416,
    Code: "10699",
    Name: "Phường Tân Dân",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3417,
    Code: "10702",
    Name: "Phường Minh Tân",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3418,
    Code: "10705",
    Name: "Xã Quang Thành",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3419,
    Code: "10708",
    Name: "Xã Hiệp Hòa",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3420,
    Code: "10714",
    Name: "Phường Phú Thứ",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3421,
    Code: "10717",
    Name: "Xã Thăng Long",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3422,
    Code: "10720",
    Name: "Xã Lạc Long",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3423,
    Code: "10723",
    Name: "Phường An Sinh",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3424,
    Code: "10726",
    Name: "Phường Hiệp Sơn",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3425,
    Code: "10729",
    Name: "Xã Thượng Quận",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3426,
    Code: "10732",
    Name: "Phường An Phụ",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3427,
    Code: "10735",
    Name: "Phường Hiệp An",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3428,
    Code: "10738",
    Name: "Phường Long Xuyên",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3429,
    Code: "10741",
    Name: "Phường Thái Thịnh",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3430,
    Code: "10744",
    Name: "Phường Hiến Thành",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3431,
    Code: "10747",
    Name: "Xã Minh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 3432,
    Code: "10750",
    Name: "Thị trấn Phú Thái",
    LevelUp: "Thị trấn",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3433,
    Code: "10753",
    Name: "Xã Lai Vu",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3434,
    Code: "10756",
    Name: "Xã Cộng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3435,
    Code: "10759",
    Name: "Xã Thượng Vũ",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3436,
    Code: "10762",
    Name: "Xã Cổ Dũng",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3437,
    Code: "10768",
    Name: "Xã Tuấn Việt",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3438,
    Code: "10771",
    Name: "Xã Kim Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3439,
    Code: "10774",
    Name: "Xã Phúc Thành A",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3440,
    Code: "10777",
    Name: "Xã Ngũ Phúc",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3441,
    Code: "10780",
    Name: "Xã Kim Anh",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3442,
    Code: "10783",
    Name: "Xã Kim Liên",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3443,
    Code: "10786",
    Name: "Xã Kim Tân",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3444,
    Code: "10792",
    Name: "Xã Kim Đính",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3445,
    Code: "10798",
    Name: "Xã Bình Dân",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3446,
    Code: "10801",
    Name: "Xã Tam Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3447,
    Code: "10804",
    Name: "Xã Đồng Cẩm",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3448,
    Code: "10807",
    Name: "Xã Liên Hòa",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3449,
    Code: "10810",
    Name: "Xã Đại Đức",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 3450,
    Code: "10813",
    Name: "Thị trấn Thanh Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3451,
    Code: "10816",
    Name: "Xã Hồng Lạc",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3452,
    Code: "10819",
    Name: "Xã Việt Hồng",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3453,
    Code: "10825",
    Name: "Xã Tân Việt",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3454,
    Code: "10828",
    Name: "Xã Cẩm Chế",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3455,
    Code: "10831",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3456,
    Code: "10834",
    Name: "Xã Thanh Lang",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3457,
    Code: "10840",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3458,
    Code: "10843",
    Name: "Xã Liên Mạc",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3459,
    Code: "10846",
    Name: "Xã Thanh Hải",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3460,
    Code: "10849",
    Name: "Xã Thanh Khê",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3461,
    Code: "10852",
    Name: "Xã Thanh Xá",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3462,
    Code: "10855",
    Name: "Xã Thanh Xuân",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3463,
    Code: "10861",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3464,
    Code: "10864",
    Name: "Xã An Phượng",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3465,
    Code: "10867",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3466,
    Code: "10876",
    Name: "Xã Thanh Quang",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3467,
    Code: "10879",
    Name: "Xã Thanh Hồng",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3468,
    Code: "10882",
    Name: "Xã Thanh Cường",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3469,
    Code: "10885",
    Name: "Xã Vĩnh Lập",
    LevelUp: "Xã",
    DistrictCode: "2110",
    Active: -1,
  },
  {
    ID: 3470,
    Code: "10888",
    Name: "Thị trấn Cẩm Giang",
    LevelUp: "Thị trấn",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3471,
    Code: "10891",
    Name: "Thị trấn Lai Cách",
    LevelUp: "Thị trấn",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3472,
    Code: "10894",
    Name: "Xã Cẩm Hưng",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3473,
    Code: "10897",
    Name: "Xã Cẩm Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3474,
    Code: "10900",
    Name: "Xã Cẩm Văn",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3475,
    Code: "10903",
    Name: "Xã Ngọc Liên",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3476,
    Code: "10906",
    Name: "Xã Thạch Lỗi",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3477,
    Code: "10909",
    Name: "Xã Cẩm Vũ",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3478,
    Code: "10912",
    Name: "Xã Đức Chính",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3479,
    Code: "10918",
    Name: "Xã Định Sơn",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3480,
    Code: "10924",
    Name: "Xã Lương Điền",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3481,
    Code: "10927",
    Name: "Xã Cao An",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3482,
    Code: "10930",
    Name: "Xã Tân Trường",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3483,
    Code: "10933",
    Name: "Xã Cẩm Phúc",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3484,
    Code: "10936",
    Name: "Xã Cẩm Điền",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3485,
    Code: "10939",
    Name: "Xã Cẩm Đông",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3486,
    Code: "10942",
    Name: "Xã Cẩm Đoài",
    LevelUp: "Xã",
    DistrictCode: "2109",
    Active: -1,
  },
  {
    ID: 3487,
    Code: "10945",
    Name: "Thị trấn Kẻ Sặt",
    LevelUp: "Thị trấn",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3488,
    Code: "10951",
    Name: "Xã Vĩnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3489,
    Code: "10954",
    Name: "Xã Hùng Thắng",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3490,
    Code: "10960",
    Name: "Xã Vĩnh Hồng",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3491,
    Code: "10963",
    Name: "Xã Long Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3492,
    Code: "10966",
    Name: "Xã Tân Việt",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3493,
    Code: "10969",
    Name: "Xã Thúc Kháng",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3494,
    Code: "10972",
    Name: "Xã Tân Hồng",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3495,
    Code: "10975",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3496,
    Code: "10978",
    Name: "Xã Hồng Khê",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3497,
    Code: "10981",
    Name: "Xã Thái Học",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3498,
    Code: "10984",
    Name: "Xã Cổ Bì",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3499,
    Code: "10987",
    Name: "Xã Nhân Quyền",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3500,
    Code: "10990",
    Name: "Xã Thái Dương",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3501,
    Code: "10993",
    Name: "Xã Thái Hòa",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3502,
    Code: "10996",
    Name: "Xã Bình Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 3503,
    Code: "10999",
    Name: "Thị trấn Gia Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3504,
    Code: "11008",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3505,
    Code: "11020",
    Name: "Xã Yết Kiêu",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3506,
    Code: "11029",
    Name: "Xã Gia Tân",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3507,
    Code: "11032",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3508,
    Code: "11035",
    Name: "Xã Gia Khánh",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3509,
    Code: "11038",
    Name: "Xã Gia Lương",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3510,
    Code: "11041",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3511,
    Code: "11044",
    Name: "Xã Toàn Thắng",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3512,
    Code: "11047",
    Name: "Xã Hoàng Diệu",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3513,
    Code: "11050",
    Name: "Xã Hồng Hưng",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3514,
    Code: "11053",
    Name: "Xã Phạm Trấn",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3515,
    Code: "11056",
    Name: "Xã Đoàn Thượng",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3516,
    Code: "11059",
    Name: "Xã Thống Kênh",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3517,
    Code: "11062",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3518,
    Code: "11065",
    Name: "Xã Đồng Quang",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3519,
    Code: "11068",
    Name: "Xã Nhật Tân",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3520,
    Code: "11071",
    Name: "Xã Đức Xương",
    LevelUp: "Xã",
    DistrictCode: "2105",
    Active: -1,
  },
  {
    ID: 3521,
    Code: "11074",
    Name: "Thị trấn Tứ Kỳ",
    LevelUp: "Thị trấn",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3522,
    Code: "11083",
    Name: "Xã Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3523,
    Code: "11086",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3524,
    Code: "11089",
    Name: "Xã Ngọc Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3525,
    Code: "11092",
    Name: "Xã Bình Lăng",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3526,
    Code: "11095",
    Name: "Xã Chí Minh",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3527,
    Code: "11098",
    Name: "Xã Tái Sơn",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3528,
    Code: "11101",
    Name: "Xã Quang Phục",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3529,
    Code: "11110",
    Name: "Xã Dân Chủ",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3530,
    Code: "11113",
    Name: "Xã Tân Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3531,
    Code: "11116",
    Name: "Xã Quang Khải",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3532,
    Code: "11119",
    Name: "Xã Đại Hợp",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3533,
    Code: "11122",
    Name: "Xã Quảng Nghiệp",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3534,
    Code: "11125",
    Name: "Xã An Thanh",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3535,
    Code: "11128",
    Name: "Xã Minh Đức",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3536,
    Code: "11131",
    Name: "Xã Văn Tố",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3537,
    Code: "11134",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3538,
    Code: "11137",
    Name: "Xã Phượng Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3539,
    Code: "11140",
    Name: "Xã Cộng Lạc",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3540,
    Code: "11143",
    Name: "Xã Tiên Động",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3541,
    Code: "11146",
    Name: "Xã Nguyên Giáp",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3542,
    Code: "11149",
    Name: "Xã Hà Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3543,
    Code: "11152",
    Name: "Xã Hà Thanh",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 3544,
    Code: "11155",
    Name: "Thị trấn Ninh Giang",
    LevelUp: "Thị trấn",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3545,
    Code: "11161",
    Name: "Xã Ứng Hoè",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3546,
    Code: "11164",
    Name: "Xã Nghĩa An",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3547,
    Code: "11167",
    Name: "Xã Hồng Đức",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3548,
    Code: "11173",
    Name: "Xã An Đức",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3549,
    Code: "11176",
    Name: "Xã Vạn Phúc",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3550,
    Code: "11179",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3551,
    Code: "11185",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3552,
    Code: "11188",
    Name: "Xã Đông Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3553,
    Code: "11197",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3554,
    Code: "11200",
    Name: "Xã Ninh Hải",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3555,
    Code: "11203",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3556,
    Code: "11206",
    Name: "Xã Tân Quang",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3557,
    Code: "11209",
    Name: "Xã Kiến Quốc",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3558,
    Code: "11215",
    Name: "Xã Hồng Dụ",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3559,
    Code: "11218",
    Name: "Xã Văn Hội",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3560,
    Code: "11224",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3561,
    Code: "11227",
    Name: "Xã Hiệp Lực",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3562,
    Code: "11230",
    Name: "Xã Hồng Phúc",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3563,
    Code: "11233",
    Name: "Xã Hưng Long",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 3564,
    Code: "11239",
    Name: "Thị trấn Thanh Miện",
    LevelUp: "Thị trấn",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3565,
    Code: "11242",
    Name: "Xã Thanh Tùng",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3566,
    Code: "11245",
    Name: "Xã Phạm Kha",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3567,
    Code: "11248",
    Name: "Xã Ngô Quyền",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3568,
    Code: "11251",
    Name: "Xã Đoàn Tùng",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3569,
    Code: "11254",
    Name: "Xã Hồng Quang",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3570,
    Code: "11257",
    Name: "Xã Tân Trào",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3571,
    Code: "11260",
    Name: "Xã Lam Sơn",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3572,
    Code: "11263",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3573,
    Code: "11266",
    Name: "Xã Lê Hồng",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3574,
    Code: "11269",
    Name: "Xã Tứ Cường",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3575,
    Code: "11275",
    Name: "Xã Ngũ Hùng",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3576,
    Code: "11278",
    Name: "Xã Cao Thắng",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3577,
    Code: "11281",
    Name: "Xã Chi Lăng Bắc",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3578,
    Code: "11284",
    Name: "Xã Chi Lăng Nam",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3579,
    Code: "11287",
    Name: "Xã Thanh Giang",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3580,
    Code: "11293",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "2107",
    Active: -1,
  },
  {
    ID: 3581,
    Code: "11296",
    Name: "Phường Quán Toan",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3582,
    Code: "11299",
    Name: "Phường Hùng Vương",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3583,
    Code: "11302",
    Name: "Phường Sở Dầu",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3584,
    Code: "11305",
    Name: "Phường Thượng Lý",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3585,
    Code: "11308",
    Name: "Phường Hạ Lý",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3586,
    Code: "11311",
    Name: "Phường Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3587,
    Code: "11314",
    Name: "Phường Trại Chuối",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3588,
    Code: "11320",
    Name: "Phường Hoàng Văn Thụ",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3589,
    Code: "11323",
    Name: "Phường Phan Bội Châu",
    LevelUp: "Phường",
    DistrictCode: "0301",
    Active: -1,
  },
  {
    ID: 3590,
    Code: "11329",
    Name: "Phường Máy Chai",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3591,
    Code: "11332",
    Name: "Phường Máy Tơ",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3592,
    Code: "11335",
    Name: "Phường Vạn Mỹ",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3593,
    Code: "11338",
    Name: "Phường Cầu Tre",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3594,
    Code: "11341",
    Name: "Phường Lạc Viên",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3595,
    Code: "11347",
    Name: "Phường Gia Viên",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3596,
    Code: "11350",
    Name: "Phường Đông Khê",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3597,
    Code: "11353",
    Name: "Phường Cầu Đất",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3598,
    Code: "11356",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3599,
    Code: "11359",
    Name: "Phường Đằng Giang",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3600,
    Code: "11362",
    Name: "Phường Lạch Tray",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3601,
    Code: "11365",
    Name: "Phường Đổng Quốc Bình",
    LevelUp: "Phường",
    DistrictCode: "0303",
    Active: -1,
  },
  {
    ID: 3602,
    Code: "11368",
    Name: "Phường Cát Dài",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3603,
    Code: "11371",
    Name: "Phường An Biên",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3604,
    Code: "11374",
    Name: "Phường Lam Sơn",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3605,
    Code: "11377",
    Name: "Phường An Dương",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3606,
    Code: "11380",
    Name: "Phường Trần Nguyên Hãn",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3607,
    Code: "11383",
    Name: "Phường Hồ Nam",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3608,
    Code: "11386",
    Name: "Phường Trại Cau",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3609,
    Code: "11389",
    Name: "Phường Dư Hàng",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3610,
    Code: "11392",
    Name: "Phường Hàng Kênh",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3611,
    Code: "11395",
    Name: "Phường Đông Hải",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3612,
    Code: "11398",
    Name: "Phường Niệm Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3613,
    Code: "11401",
    Name: "Phường Nghĩa Xá",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3614,
    Code: "11404",
    Name: "Phường Dư Hàng Kênh",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3615,
    Code: "11405",
    Name: "Phường Kênh Dương",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3616,
    Code: "11407",
    Name: "Phường Vĩnh Niệm",
    LevelUp: "Phường",
    DistrictCode: "0302",
    Active: -1,
  },
  {
    ID: 3617,
    Code: "11410",
    Name: "Phường Đông Hải 1",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3618,
    Code: "11411",
    Name: "Phường Đông Hải 2",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3619,
    Code: "11413",
    Name: "Phường Đằng Lâm",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3620,
    Code: "11414",
    Name: "Phường Thành Tô",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3621,
    Code: "11416",
    Name: "Phường Đằng Hải",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3622,
    Code: "11419",
    Name: "Phường Nam Hải",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3623,
    Code: "11422",
    Name: "Phường Cát Bi",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3624,
    Code: "11425",
    Name: "Phường Tràng Cát",
    LevelUp: "Phường",
    DistrictCode: "0305",
    Active: -1,
  },
  {
    ID: 3625,
    Code: "11428",
    Name: "Phường Quán Trữ",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3626,
    Code: "11429",
    Name: "Phường Lãm Hà",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3627,
    Code: "11431",
    Name: "Phường Đồng Hoà",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3628,
    Code: "11434",
    Name: "Phường Bắc Sơn",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3629,
    Code: "11437",
    Name: "Phường Nam Sơn",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3630,
    Code: "11440",
    Name: "Phường Ngọc Sơn",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3631,
    Code: "11443",
    Name: "Phường Trần Thành Ngọ",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3632,
    Code: "11446",
    Name: "Phường Văn Đẩu",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3633,
    Code: "11449",
    Name: "Phường Phù Liễn",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3634,
    Code: "11452",
    Name: "Phường Tràng Minh",
    LevelUp: "Phường",
    DistrictCode: "0304",
    Active: -1,
  },
  {
    ID: 3635,
    Code: "11455",
    Name: "Phường Ngọc Xuyên",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 3636,
    Code: "11458",
    Name: "Phường Hải Sơn",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 3637,
    Code: "11461",
    Name: "Phường Vạn Hương",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 3638,
    Code: "11465",
    Name: "Phường Minh Đức",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 3639,
    Code: "11467",
    Name: "Phường Bàng La",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 3640,
    Code: "11737",
    Name: "Phường Hợp Đức",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 3641,
    Code: "11683",
    Name: "Phường Đa Phúc",
    LevelUp: "Phường",
    DistrictCode: "0315",
    Active: -1,
  },
  {
    ID: 3642,
    Code: "11686",
    Name: "Phường Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "0315",
    Active: -1,
  },
  {
    ID: 3643,
    Code: "11689",
    Name: "Phường Anh Dũng",
    LevelUp: "Phường",
    DistrictCode: "0315",
    Active: -1,
  },
  {
    ID: 3644,
    Code: "11692",
    Name: "Phường Hải Thành",
    LevelUp: "Phường",
    DistrictCode: "0315",
    Active: -1,
  },
  {
    ID: 3645,
    Code: "11707",
    Name: "Phường Hoà Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "0315",
    Active: -1,
  },
  {
    ID: 3646,
    Code: "11740",
    Name: "Phường Tân Thành",
    LevelUp: "Phường",
    DistrictCode: "0315",
    Active: -1,
  },
  {
    ID: 3647,
    Code: "11470",
    Name: "Thị trấn Núi Đèo",
    LevelUp: "Thị trấn",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3648,
    Code: "11473",
    Name: "Thị trấn Minh Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3649,
    Code: "11476",
    Name: "Xã Lại Xuân",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3650,
    Code: "11479",
    Name: "Xã An Sơn",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3651,
    Code: "11482",
    Name: "Xã Kỳ Sơn",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3652,
    Code: "11485",
    Name: "Xã Liên Khê",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3653,
    Code: "11488",
    Name: "Xã Lưu Kiếm",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3654,
    Code: "11491",
    Name: "Xã Lưu Kỳ",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3655,
    Code: "11494",
    Name: "Xã Gia Minh",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3656,
    Code: "11497",
    Name: "Xã Gia Đức",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3657,
    Code: "11500",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3658,
    Code: "11503",
    Name: "Xã Phù Ninh",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3659,
    Code: "11506",
    Name: "Xã Quảng Thanh",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3660,
    Code: "11509",
    Name: "Xã Chính Mỹ",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3661,
    Code: "11512",
    Name: "Xã Kênh Giang",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3662,
    Code: "11515",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3663,
    Code: "11518",
    Name: "Xã Cao Nhân",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3664,
    Code: "11521",
    Name: "Xã Mỹ Đồng",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3665,
    Code: "11524",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3666,
    Code: "11527",
    Name: "Xã Hoà Bình",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3667,
    Code: "11530",
    Name: "Xã Trung Hà",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3668,
    Code: "11533",
    Name: "Xã An Lư",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3669,
    Code: "11536",
    Name: "Xã Thuỷ Triều",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3670,
    Code: "11539",
    Name: "Xã Ngũ Lão",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3671,
    Code: "11542",
    Name: "Xã Phục Lễ",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3672,
    Code: "11545",
    Name: "Xã Tam Hưng",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3673,
    Code: "11548",
    Name: "Xã Phả Lễ",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3674,
    Code: "11551",
    Name: "Xã Lập Lễ",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3675,
    Code: "11554",
    Name: "Xã Kiền Bái",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3676,
    Code: "11557",
    Name: "Xã Thiên Hương",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3677,
    Code: "11560",
    Name: "Xã Thuỷ Sơn",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3678,
    Code: "11563",
    Name: "Xã Thuỷ Đường",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3679,
    Code: "11566",
    Name: "Xã Hoàng Động",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3680,
    Code: "11569",
    Name: "Xã Lâm Động",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3681,
    Code: "11572",
    Name: "Xã Hoa Động",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3682,
    Code: "11575",
    Name: "Xã Tân Dương",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3683,
    Code: "11578",
    Name: "Xã Dương Quan",
    LevelUp: "Xã",
    DistrictCode: "0309",
    Active: -1,
  },
  {
    ID: 3684,
    Code: "11581",
    Name: "Thị trấn An Dương",
    LevelUp: "Thị trấn",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3685,
    Code: "11584",
    Name: "Xã Lê Thiện",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3686,
    Code: "11587",
    Name: "Xã Đại Bản",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3687,
    Code: "11590",
    Name: "Xã An Hoà",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3688,
    Code: "11593",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3689,
    Code: "11596",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3690,
    Code: "11599",
    Name: "Xã An Hưng",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3691,
    Code: "11602",
    Name: "Xã An Hồng",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3692,
    Code: "11605",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3693,
    Code: "11608",
    Name: "Xã Nam Sơn",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3694,
    Code: "11611",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3695,
    Code: "11614",
    Name: "Xã Đặng Cương",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3696,
    Code: "11617",
    Name: "Xã Đồng Thái",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3697,
    Code: "11620",
    Name: "Xã Quốc Tuấn",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3698,
    Code: "11623",
    Name: "Xã An Đồng",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3699,
    Code: "11626",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "0310",
    Active: -1,
  },
  {
    ID: 3700,
    Code: "11629",
    Name: "Thị trấn An Lão",
    LevelUp: "Thị trấn",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3701,
    Code: "11632",
    Name: "Xã Bát Trang",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3702,
    Code: "11635",
    Name: "Xã Trường Thọ",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3703,
    Code: "11638",
    Name: "Xã Trường Thành",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3704,
    Code: "11641",
    Name: "Xã An Tiến",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3705,
    Code: "11644",
    Name: "Xã Quang Hưng",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3706,
    Code: "11647",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3707,
    Code: "11650",
    Name: "Xã Quốc Tuấn",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3708,
    Code: "11653",
    Name: "Xã An Thắng",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3709,
    Code: "11656",
    Name: "Thị trấn Trường Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3710,
    Code: "11659",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3711,
    Code: "11662",
    Name: "Xã Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3712,
    Code: "11665",
    Name: "Xã Tân Viên",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3713,
    Code: "11668",
    Name: "Xã Mỹ Đức",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3714,
    Code: "11671",
    Name: "Xã Chiến Thắng",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3715,
    Code: "11674",
    Name: "Xã An Thọ",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3716,
    Code: "11677",
    Name: "Xã An Thái",
    LevelUp: "Xã",
    DistrictCode: "0307",
    Active: -1,
  },
  {
    ID: 3717,
    Code: "11680",
    Name: "Thị trấn Núi Đối",
    LevelUp: "Thị trấn",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3718,
    Code: "11695",
    Name: "Xã Đông Phương",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3719,
    Code: "11698",
    Name: "Xã Thuận Thiên",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3720,
    Code: "11701",
    Name: "Xã Hữu Bằng",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3721,
    Code: "11704",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3722,
    Code: "11710",
    Name: "Xã Ngũ Phúc",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3723,
    Code: "11713",
    Name: "Xã Kiến Quốc",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3724,
    Code: "11716",
    Name: "Xã Du Lễ",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3725,
    Code: "11719",
    Name: "Xã Thuỵ Hương",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3726,
    Code: "11722",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3727,
    Code: "11725",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3728,
    Code: "11728",
    Name: "Xã Đại Hà",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3729,
    Code: "11731",
    Name: "Xã Ngũ Đoan",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3730,
    Code: "11734",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3731,
    Code: "11743",
    Name: "Xã Tân Trào",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3732,
    Code: "11746",
    Name: "Xã Đoàn Xá",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3733,
    Code: "11749",
    Name: "Xã Tú Sơn",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3734,
    Code: "11752",
    Name: "Xã Đại Hợp",
    LevelUp: "Xã",
    DistrictCode: "0308",
    Active: -1,
  },
  {
    ID: 3735,
    Code: "11755",
    Name: "Thị trấn Tiên Lãng",
    LevelUp: "Thị trấn",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3736,
    Code: "11758",
    Name: "Xã Đại Thắng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3737,
    Code: "11761",
    Name: "Xã Tiên Cường",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3738,
    Code: "11764",
    Name: "Xã Tự Cường",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3739,
    Code: "11770",
    Name: "Xã Quyết Tiến",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3740,
    Code: "11773",
    Name: "Xã Khởi Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3741,
    Code: "11776",
    Name: "Xã Tiên Thanh",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3742,
    Code: "11779",
    Name: "Xã Cấp Tiến",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3743,
    Code: "11782",
    Name: "Xã Kiến Thiết",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3744,
    Code: "11785",
    Name: "Xã Đoàn Lập",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3745,
    Code: "11788",
    Name: "Xã Bạch Đằng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3746,
    Code: "11791",
    Name: "Xã Quang Phục",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3747,
    Code: "11794",
    Name: "Xã Toàn Thắng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3748,
    Code: "11797",
    Name: "Xã Tiên Thắng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3749,
    Code: "11800",
    Name: "Xã Tiên Minh",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3750,
    Code: "11803",
    Name: "Xã Bắc Hưng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3751,
    Code: "11806",
    Name: "Xã Nam Hưng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3752,
    Code: "11809",
    Name: "Xã Hùng Thắng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3753,
    Code: "11812",
    Name: "Xã Tây Hưng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3754,
    Code: "11815",
    Name: "Xã Đông Hưng",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3755,
    Code: "11821",
    Name: "Xã Vinh Quang",
    LevelUp: "Xã",
    DistrictCode: "0311",
    Active: -1,
  },
  {
    ID: 3756,
    Code: "11824",
    Name: "Thị trấn Vĩnh Bảo",
    LevelUp: "Thị trấn",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3757,
    Code: "11827",
    Name: "Xã Dũng Tiến",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3758,
    Code: "11830",
    Name: "Xã Giang Biên",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3759,
    Code: "11833",
    Name: "Xã Thắng Thuỷ",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3760,
    Code: "11836",
    Name: "Xã Trung Lập",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3761,
    Code: "11839",
    Name: "Xã Việt Tiến",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3762,
    Code: "11842",
    Name: "Xã Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3763,
    Code: "11845",
    Name: "Xã Vĩnh Long",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3764,
    Code: "11848",
    Name: "Xã Hiệp Hoà",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3765,
    Code: "11851",
    Name: "Xã Hùng Tiến",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3766,
    Code: "11854",
    Name: "Xã An Hoà",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3767,
    Code: "11857",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3768,
    Code: "11860",
    Name: "Xã Tân Liên",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3769,
    Code: "11863",
    Name: "Xã Nhân Hoà",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3770,
    Code: "11866",
    Name: "Xã Tam Đa",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3771,
    Code: "11869",
    Name: "Xã Hưng Nhân",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3772,
    Code: "11872",
    Name: "Xã Vinh Quang",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3773,
    Code: "11875",
    Name: "Xã Đồng Minh",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3774,
    Code: "11878",
    Name: "Xã Thanh Lương",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3775,
    Code: "11881",
    Name: "Xã Liên Am",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3776,
    Code: "11884",
    Name: "Xã Lý Học",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3777,
    Code: "11887",
    Name: "Xã Tam Cường",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3778,
    Code: "11890",
    Name: "Xã Hoà Bình",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3779,
    Code: "11893",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3780,
    Code: "11896",
    Name: "Xã Vĩnh Phong",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3781,
    Code: "11899",
    Name: "Xã Cộng Hiền",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3782,
    Code: "11902",
    Name: "Xã Cao Minh",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3783,
    Code: "11905",
    Name: "Xã Cổ Am",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3784,
    Code: "11908",
    Name: "Xã Vĩnh Tiến",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3785,
    Code: "11911",
    Name: "Xã Trấn Dương",
    LevelUp: "Xã",
    DistrictCode: "0312",
    Active: -1,
  },
  {
    ID: 3786,
    Code: "11914",
    Name: "Thị trấn Cát Bà",
    LevelUp: "Thị trấn",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3787,
    Code: "11917",
    Name: "Thị trấn Cát Hải",
    LevelUp: "Thị trấn",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3788,
    Code: "11920",
    Name: "Xã Nghĩa Lộ",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3789,
    Code: "11923",
    Name: "Xã Đồng Bài",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3790,
    Code: "11926",
    Name: "Xã Hoàng Châu",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3791,
    Code: "11929",
    Name: "Xã Văn Phong",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3792,
    Code: "11932",
    Name: "Xã Phù Long",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3793,
    Code: "11935",
    Name: "Xã Gia Luận",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3794,
    Code: "11938",
    Name: "Xã Hiền Hào",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3795,
    Code: "11941",
    Name: "Xã Trân Châu",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3796,
    Code: "11944",
    Name: "Xã Việt Hải",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3797,
    Code: "11947",
    Name: "Xã Xuân Đám",
    LevelUp: "Xã",
    DistrictCode: "0313",
    Active: -1,
  },
  {
    ID: 3798,
    Code: "11950",
    Name: "Phường Lam Sơn",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3799,
    Code: "11953",
    Name: "Phường Hiến Nam",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3800,
    Code: "11956",
    Name: "Phường An Tảo",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3801,
    Code: "11959",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3802,
    Code: "11962",
    Name: "Phường Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3803,
    Code: "11965",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3804,
    Code: "11968",
    Name: "Phường Hồng Châu",
    LevelUp: "Phường",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3805,
    Code: "11971",
    Name: "Xã Trung Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3806,
    Code: "11974",
    Name: "Xã Liên Phương",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3807,
    Code: "11977",
    Name: "Xã Hồng Nam",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3808,
    Code: "11980",
    Name: "Xã Quảng Châu",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3809,
    Code: "11983",
    Name: "Xã Bảo Khê",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3810,
    Code: "12331",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3811,
    Code: "12334",
    Name: "Xã Hùng Cường",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3812,
    Code: "12382",
    Name: "Xã Phương Chiểu",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3813,
    Code: "12385",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3814,
    Code: "12388",
    Name: "Xã Hoàng Hanh",
    LevelUp: "Xã",
    DistrictCode: "2201",
    Active: -1,
  },
  {
    ID: 3815,
    Code: "11986",
    Name: "Thị trấn Như Quỳnh",
    LevelUp: "Thị trấn",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3816,
    Code: "11989",
    Name: "Xã Lạc Đạo",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3817,
    Code: "11992",
    Name: "Xã Chỉ Đạo",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3818,
    Code: "11995",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3819,
    Code: "11998",
    Name: "Xã Việt Hưng",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3820,
    Code: "12001",
    Name: "Xã Tân Quang",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3821,
    Code: "12004",
    Name: "Xã Đình Dù",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3822,
    Code: "12007",
    Name: "Xã Minh Hải",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3823,
    Code: "12010",
    Name: "Xã Lương Tài",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3824,
    Code: "12013",
    Name: "Xã Trưng Trắc",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3825,
    Code: "12016",
    Name: "Xã Lạc Hồng",
    LevelUp: "Xã",
    DistrictCode: "2209",
    Active: -1,
  },
  {
    ID: 3826,
    Code: "12019",
    Name: "Thị trấn Văn Giang",
    LevelUp: "Thị trấn",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3827,
    Code: "12022",
    Name: "Xã Xuân Quan",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3828,
    Code: "12025",
    Name: "Xã Cửu Cao",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3829,
    Code: "12028",
    Name: "Xã Phụng Công",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3830,
    Code: "12031",
    Name: "Xã Nghĩa Trụ",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3831,
    Code: "12034",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3832,
    Code: "12037",
    Name: "Xã Vĩnh Khúc",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3833,
    Code: "12040",
    Name: "Xã Liên Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3834,
    Code: "12043",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3835,
    Code: "12046",
    Name: "Xã Thắng Lợi",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3836,
    Code: "12049",
    Name: "Xã Mễ Sở",
    LevelUp: "Xã",
    DistrictCode: "2210",
    Active: -1,
  },
  {
    ID: 3837,
    Code: "12052",
    Name: "Thị trấn Yên Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3838,
    Code: "12055",
    Name: "Xã Giai Phạm",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3839,
    Code: "12058",
    Name: "Xã Nghĩa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3840,
    Code: "12061",
    Name: "Xã Đồng Than",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3841,
    Code: "12064",
    Name: "Xã Ngọc Long",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3842,
    Code: "12067",
    Name: "Xã Liêu Xá",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3843,
    Code: "12070",
    Name: "Xã Hoàn Long",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3844,
    Code: "12073",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3845,
    Code: "12076",
    Name: "Xã Thanh Long",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3846,
    Code: "12079",
    Name: "Xã Yên Phú",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3847,
    Code: "12082",
    Name: "Xã Việt Cường",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3848,
    Code: "12085",
    Name: "Xã Trung Hòa",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3849,
    Code: "12088",
    Name: "Xã Yên Hòa",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3850,
    Code: "12091",
    Name: "Xã Minh Châu",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3851,
    Code: "12094",
    Name: "Xã Trung Hưng",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3852,
    Code: "12097",
    Name: "Xã Lý Thường Kiệt",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3853,
    Code: "12100",
    Name: "Xã Tân Việt",
    LevelUp: "Xã",
    DistrictCode: "2205",
    Active: -1,
  },
  {
    ID: 3854,
    Code: "12103",
    Name: "Phường Bần Yên Nhân",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3855,
    Code: "12106",
    Name: "Phường Phan Đình Phùng",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3856,
    Code: "12109",
    Name: "Xã Cẩm Xá",
    LevelUp: "Xã",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3857,
    Code: "12112",
    Name: "Xã Dương Quang",
    LevelUp: "Xã",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3858,
    Code: "12115",
    Name: "Xã Hòa Phong",
    LevelUp: "Xã",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3859,
    Code: "12118",
    Name: "Phường Nhân Hòa",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3860,
    Code: "12121",
    Name: "Phường Dị Sử",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3861,
    Code: "12124",
    Name: "Phường Bạch Sam",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3862,
    Code: "12127",
    Name: "Phường Minh Đức",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3863,
    Code: "12130",
    Name: "Phường Phùng Chí Kiên",
    LevelUp: "Phường",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3864,
    Code: "12133",
    Name: "Xã Xuân Dục",
    LevelUp: "Xã",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3865,
    Code: "12136",
    Name: "Xã Ngọc Lâm",
    LevelUp: "Xã",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3866,
    Code: "12139",
    Name: "Xã Hưng Long",
    LevelUp: "Xã",
    DistrictCode: "2208",
    Active: -1,
  },
  {
    ID: 3867,
    Code: "12142",
    Name: "Thị trấn Ân Thi",
    LevelUp: "Thị trấn",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3868,
    Code: "12145",
    Name: "Xã Phù Ủng",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3869,
    Code: "12148",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3870,
    Code: "12151",
    Name: "Xã Bãi Sậy",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3871,
    Code: "12154",
    Name: "Xã Đào Dương",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3872,
    Code: "12157",
    Name: "Xã Tân Phúc",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3873,
    Code: "12160",
    Name: "Xã Vân Du",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3874,
    Code: "12163",
    Name: "Xã Quang Vinh",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3875,
    Code: "12166",
    Name: "Xã Xuân Trúc",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3876,
    Code: "12169",
    Name: "Xã Hoàng Hoa Thám",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3877,
    Code: "12172",
    Name: "Xã Quảng Lãng",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3878,
    Code: "12175",
    Name: "Xã Văn Nhuệ",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3879,
    Code: "12178",
    Name: "Xã Đặng Lễ",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3880,
    Code: "12181",
    Name: "Xã Cẩm Ninh",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3881,
    Code: "12184",
    Name: "Xã Nguyễn Trãi",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3882,
    Code: "12187",
    Name: "Xã Đa Lộc",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3883,
    Code: "12190",
    Name: "Xã Hồ Tùng Mậu",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3884,
    Code: "12193",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3885,
    Code: "12196",
    Name: "Xã Hồng Vân",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3886,
    Code: "12199",
    Name: "Xã Hồng Quang",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3887,
    Code: "12202",
    Name: "Xã Hạ Lễ",
    LevelUp: "Xã",
    DistrictCode: "2203",
    Active: -1,
  },
  {
    ID: 3888,
    Code: "12205",
    Name: "Thị trấn Khoái Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3889,
    Code: "12208",
    Name: "Xã Đông Tảo",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3890,
    Code: "12211",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3891,
    Code: "12214",
    Name: "Xã Dạ Trạch",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3892,
    Code: "12217",
    Name: "Xã Hàm Tử",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3893,
    Code: "12220",
    Name: "Xã Ông Đình",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3894,
    Code: "12223",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3895,
    Code: "12226",
    Name: "Xã Tứ Dân",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3896,
    Code: "12229",
    Name: "Xã An Vĩ",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3897,
    Code: "12232",
    Name: "Xã Đông Kết",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3898,
    Code: "12235",
    Name: "Xã Bình Kiều",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3899,
    Code: "12238",
    Name: "Xã Dân Tiến",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3900,
    Code: "12241",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3901,
    Code: "12244",
    Name: "Xã Hồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3902,
    Code: "12247",
    Name: "Xã Tân Châu",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3903,
    Code: "12250",
    Name: "Xã Liên Khê",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3904,
    Code: "12253",
    Name: "Xã Phùng Hưng",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3905,
    Code: "12256",
    Name: "Xã Việt Hòa",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3906,
    Code: "12259",
    Name: "Xã Đông Ninh",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3907,
    Code: "12262",
    Name: "Xã Đại Tập",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3908,
    Code: "12265",
    Name: "Xã Chí Tân",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3909,
    Code: "12268",
    Name: "Xã Đại Hưng",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3910,
    Code: "12271",
    Name: "Xã Thuần Hưng",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3911,
    Code: "12274",
    Name: "Xã Thành Công",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3912,
    Code: "12277",
    Name: "Xã Nhuế Dương",
    LevelUp: "Xã",
    DistrictCode: "2204",
    Active: -1,
  },
  {
    ID: 3913,
    Code: "12280",
    Name: "Thị trấn Lương Bằng",
    LevelUp: "Thị trấn",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3914,
    Code: "12283",
    Name: "Xã Nghĩa Dân",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3915,
    Code: "12286",
    Name: "Xã Toàn Thắng",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3916,
    Code: "12289",
    Name: "Xã Vĩnh Xá",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3917,
    Code: "12292",
    Name: "Xã Phạm Ngũ Lão",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3918,
    Code: "12295",
    Name: "Xã Thọ Vinh",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3919,
    Code: "12298",
    Name: "Xã Đồng Thanh",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3920,
    Code: "12301",
    Name: "Xã Song Mai",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3921,
    Code: "12304",
    Name: "Xã Chính Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3922,
    Code: "12307",
    Name: "Xã Nhân La",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3923,
    Code: "12310",
    Name: "Xã Phú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3924,
    Code: "12313",
    Name: "Xã Mai Động",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3925,
    Code: "12316",
    Name: "Xã Đức Hợp",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3926,
    Code: "12319",
    Name: "Xã Hùng An",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3927,
    Code: "12322",
    Name: "Xã Ngọc Thanh",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3928,
    Code: "12325",
    Name: "Xã Vũ Xá",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3929,
    Code: "12328",
    Name: "Xã Hiệp Cường",
    LevelUp: "Xã",
    DistrictCode: "2202",
    Active: -1,
  },
  {
    ID: 3930,
    Code: "12337",
    Name: "Thị trấn Vương",
    LevelUp: "Thị trấn",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3931,
    Code: "12340",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3932,
    Code: "12343",
    Name: "Xã Ngô Quyền",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3933,
    Code: "12346",
    Name: "Xã Nhật Tân",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3934,
    Code: "12349",
    Name: "Xã Dị Chế",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3935,
    Code: "12352",
    Name: "Xã Lệ Xá",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3936,
    Code: "12355",
    Name: "Xã An Viên",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3937,
    Code: "12358",
    Name: "Xã Đức Thắng",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3938,
    Code: "12361",
    Name: "Xã Trung Dũng",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3939,
    Code: "12364",
    Name: "Xã Hải Triều",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3940,
    Code: "12367",
    Name: "Xã Thủ Sỹ",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3941,
    Code: "12370",
    Name: "Xã Thiện Phiến",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3942,
    Code: "12373",
    Name: "Xã Thụy Lôi",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3943,
    Code: "12376",
    Name: "Xã Cương Chính",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3944,
    Code: "12379",
    Name: "Xã Minh Phượng",
    LevelUp: "Xã",
    DistrictCode: "2206",
    Active: -1,
  },
  {
    ID: 3945,
    Code: "12391",
    Name: "Thị trấn Trần Cao",
    LevelUp: "Thị trấn",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3946,
    Code: "12394",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3947,
    Code: "12397",
    Name: "Xã Phan Sào Nam",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3948,
    Code: "12400",
    Name: "Xã Quang Hưng",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3949,
    Code: "12403",
    Name: "Xã Minh Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3950,
    Code: "12406",
    Name: "Xã Đoàn Đào",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3951,
    Code: "12409",
    Name: "Xã Tống Phan",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3952,
    Code: "12412",
    Name: "Xã Đình Cao",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3953,
    Code: "12415",
    Name: "Xã Nhật Quang",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3954,
    Code: "12418",
    Name: "Xã Tiền Tiến",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3955,
    Code: "12421",
    Name: "Xã Tam Đa",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3956,
    Code: "12424",
    Name: "Xã Minh Tiến",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3957,
    Code: "12427",
    Name: "Xã Nguyên Hòa",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3958,
    Code: "12430",
    Name: "Xã Tống Trân",
    LevelUp: "Xã",
    DistrictCode: "2207",
    Active: -1,
  },
  {
    ID: 3959,
    Code: "12433",
    Name: "Phường Lê Hồng Phong",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3960,
    Code: "12436",
    Name: "Phường Bồ Xuyên",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3961,
    Code: "12439",
    Name: "Phường Đề Thám",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3962,
    Code: "12442",
    Name: "Phường Kỳ Bá",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3963,
    Code: "12445",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3964,
    Code: "12448",
    Name: "Phường Phú Khánh",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3965,
    Code: "12451",
    Name: "Phường Tiền Phong",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3966,
    Code: "12452",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3967,
    Code: "12454",
    Name: "Phường Trần Lãm",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3968,
    Code: "12457",
    Name: "Xã Đông Hòa",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3969,
    Code: "12460",
    Name: "Phường Hoàng Diệu",
    LevelUp: "Phường",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3970,
    Code: "12463",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3971,
    Code: "12466",
    Name: "Xã Vũ Phúc",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3972,
    Code: "12469",
    Name: "Xã Vũ Chính",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3973,
    Code: "12817",
    Name: "Xã Đông Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3974,
    Code: "12820",
    Name: "Xã Đông Thọ",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3975,
    Code: "13084",
    Name: "Xã Vũ Đông",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3976,
    Code: "13108",
    Name: "Xã Vũ Lạc",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3977,
    Code: "13225",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "2601",
    Active: -1,
  },
  {
    ID: 3978,
    Code: "12472",
    Name: "Thị trấn Quỳnh Côi",
    LevelUp: "Thị trấn",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3979,
    Code: "12475",
    Name: "Xã An Khê",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3980,
    Code: "12478",
    Name: "Xã An Đồng",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3981,
    Code: "12481",
    Name: "Xã Quỳnh Hoa",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3982,
    Code: "12484",
    Name: "Xã Quỳnh Lâm",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3983,
    Code: "12487",
    Name: "Xã Quỳnh Thọ",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3984,
    Code: "12490",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3985,
    Code: "12493",
    Name: "Xã Quỳnh Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3986,
    Code: "12496",
    Name: "Xã Quỳnh Giao",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3987,
    Code: "12499",
    Name: "Xã An Thái",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3988,
    Code: "12502",
    Name: "Xã An Cầu",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3989,
    Code: "12505",
    Name: "Xã Quỳnh Hồng",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3990,
    Code: "12508",
    Name: "Xã Quỳnh Khê",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3991,
    Code: "12511",
    Name: "Xã Quỳnh Minh",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3992,
    Code: "12514",
    Name: "Xã An Ninh",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3993,
    Code: "12517",
    Name: "Xã Quỳnh Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3994,
    Code: "12520",
    Name: "Xã Quỳnh Hải",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3995,
    Code: "12523",
    Name: "Thị trấn An Bài",
    LevelUp: "Thị trấn",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3996,
    Code: "12526",
    Name: "Xã An Ấp",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3997,
    Code: "12529",
    Name: "Xã Quỳnh Hội",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3998,
    Code: "12532",
    Name: "Xã Châu Sơn",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 3999,
    Code: "12535",
    Name: "Xã Quỳnh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4000,
    Code: "12538",
    Name: "Xã An Quí",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4001,
    Code: "12541",
    Name: "Xã An Thanh",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4002,
    Code: "12547",
    Name: "Xã An Vũ",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4003,
    Code: "12550",
    Name: "Xã An Lễ",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4004,
    Code: "12553",
    Name: "Xã Quỳnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4005,
    Code: "12556",
    Name: "Xã Quỳnh Bảo",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4006,
    Code: "12559",
    Name: "Xã An Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4007,
    Code: "12562",
    Name: "Xã Quỳnh Nguyên",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4008,
    Code: "12565",
    Name: "Xã An Vinh",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4009,
    Code: "12568",
    Name: "Xã Quỳnh Xá",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4010,
    Code: "12571",
    Name: "Xã An Dục",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4011,
    Code: "12574",
    Name: "Xã Đông Hải",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4012,
    Code: "12577",
    Name: "Xã Quỳnh Trang",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4013,
    Code: "12580",
    Name: "Xã An Tràng",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4014,
    Code: "12583",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2602",
    Active: -1,
  },
  {
    ID: 4015,
    Code: "12586",
    Name: "Thị trấn Hưng Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4016,
    Code: "12589",
    Name: "Xã Điệp Nông",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4017,
    Code: "12592",
    Name: "Xã Tân Lễ",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4018,
    Code: "12595",
    Name: "Xã Cộng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4019,
    Code: "12598",
    Name: "Xã Dân Chủ",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4020,
    Code: "12601",
    Name: "Xã Canh Tân",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4021,
    Code: "12604",
    Name: "Xã Hòa Tiến",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4022,
    Code: "12607",
    Name: "Xã Hùng Dũng",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4023,
    Code: "12610",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4024,
    Code: "12613",
    Name: "Thị trấn Hưng Nhân",
    LevelUp: "Thị trấn",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4025,
    Code: "12616",
    Name: "Xã Đoan Hùng",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4026,
    Code: "12619",
    Name: "Xã Duyên Hải",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4027,
    Code: "12622",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4028,
    Code: "12625",
    Name: "Xã Văn Cẩm",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4029,
    Code: "12628",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4030,
    Code: "12631",
    Name: "Xã Đông Đô",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4031,
    Code: "12634",
    Name: "Xã Phúc Khánh",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4032,
    Code: "12637",
    Name: "Xã Liên Hiệp",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4033,
    Code: "12640",
    Name: "Xã Tây Đô",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4034,
    Code: "12643",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4035,
    Code: "12646",
    Name: "Xã Tiến Đức",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4036,
    Code: "12649",
    Name: "Xã Thái Hưng",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4037,
    Code: "12652",
    Name: "Xã Thái Phương",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4038,
    Code: "12655",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4039,
    Code: "12656",
    Name: "Xã Chi Lăng",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4040,
    Code: "12658",
    Name: "Xã Minh Khai",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4041,
    Code: "12661",
    Name: "Xã Hồng An",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4042,
    Code: "12664",
    Name: "Xã Kim Chung",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4043,
    Code: "12667",
    Name: "Xã Hồng Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4044,
    Code: "12670",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4045,
    Code: "12673",
    Name: "Xã Văn Lang",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4046,
    Code: "12676",
    Name: "Xã Độc Lập",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4047,
    Code: "12679",
    Name: "Xã Chí Hòa",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4048,
    Code: "12682",
    Name: "Xã Minh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4049,
    Code: "12685",
    Name: "Xã Hồng Minh",
    LevelUp: "Xã",
    DistrictCode: "2603",
    Active: -1,
  },
  {
    ID: 4050,
    Code: "12688",
    Name: "Thị trấn Đông Hưng",
    LevelUp: "Thị trấn",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4051,
    Code: "12691",
    Name: "Xã Đô Lương",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4052,
    Code: "12694",
    Name: "Xã Đông Phương",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4053,
    Code: "12697",
    Name: "Xã Liên Giang",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4054,
    Code: "12700",
    Name: "Xã An Châu",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4055,
    Code: "12703",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4056,
    Code: "12706",
    Name: "Xã Đông Cường",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4057,
    Code: "12709",
    Name: "Xã Phú Lương",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4058,
    Code: "12712",
    Name: "Xã Mê Linh",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4059,
    Code: "12715",
    Name: "Xã Lô Giang",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4060,
    Code: "12718",
    Name: "Xã Đông La",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4061,
    Code: "12721",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4062,
    Code: "12724",
    Name: "Xã Đông Xá",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4063,
    Code: "12727",
    Name: "Xã Chương Dương",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4064,
    Code: "12730",
    Name: "Xã Nguyên Xá",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4065,
    Code: "12733",
    Name: "Xã Phong Châu",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4066,
    Code: "12736",
    Name: "Xã Hợp Tiến",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4067,
    Code: "12739",
    Name: "Xã Hồng Việt",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4068,
    Code: "12745",
    Name: "Xã Hà Giang",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4069,
    Code: "12748",
    Name: "Xã Đông Kinh",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4070,
    Code: "12751",
    Name: "Xã Đông Hợp",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4071,
    Code: "12754",
    Name: "Xã Thăng Long",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4072,
    Code: "12757",
    Name: "Xã Đông Các",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4073,
    Code: "12760",
    Name: "Xã Phú Châu",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4074,
    Code: "12763",
    Name: "Xã Liên Hoa",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4075,
    Code: "12769",
    Name: "Xã Đông Tân",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4076,
    Code: "12772",
    Name: "Xã Đông Vinh",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4077,
    Code: "12775",
    Name: "Xã Đông Động",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4078,
    Code: "12778",
    Name: "Xã Hồng Bạch",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4079,
    Code: "12784",
    Name: "Xã Trọng Quan",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4080,
    Code: "12790",
    Name: "Xã Hồng Giang",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4081,
    Code: "12793",
    Name: "Xã Đông Quan",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4082,
    Code: "12796",
    Name: "Xã Đông Quang",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4083,
    Code: "12799",
    Name: "Xã Đông Xuân",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4084,
    Code: "12802",
    Name: "Xã Đông Á",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4085,
    Code: "12808",
    Name: "Xã Đông Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4086,
    Code: "12811",
    Name: "Xã Đông Dương",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4087,
    Code: "12823",
    Name: "Xã Minh Phú",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 4088,
    Code: "12826",
    Name: "Thị trấn Diêm Điền",
    LevelUp: "Thị trấn",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4089,
    Code: "12832",
    Name: "Xã Thụy Trường",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4090,
    Code: "12841",
    Name: "Xã Hồng Dũng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4091,
    Code: "12844",
    Name: "Xã Thụy Quỳnh",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4092,
    Code: "12847",
    Name: "Xã An Tân",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4093,
    Code: "12850",
    Name: "Xã Thụy Ninh",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4094,
    Code: "12853",
    Name: "Xã Thụy Hưng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4095,
    Code: "12856",
    Name: "Xã Thụy Việt",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4096,
    Code: "12859",
    Name: "Xã Thụy Văn",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4097,
    Code: "12862",
    Name: "Xã Thụy Xuân",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4098,
    Code: "12865",
    Name: "Xã Dương Phúc",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4099,
    Code: "12868",
    Name: "Xã Thụy Trình",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4100,
    Code: "12871",
    Name: "Xã Thụy Bình",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4101,
    Code: "12874",
    Name: "Xã Thụy Chính",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4102,
    Code: "12877",
    Name: "Xã Thụy Dân",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4103,
    Code: "12880",
    Name: "Xã Thụy Hải",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4104,
    Code: "12889",
    Name: "Xã Thụy Liên",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4105,
    Code: "12892",
    Name: "Xã Thụy Duyên",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4106,
    Code: "12898",
    Name: "Xã Thụy Thanh",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4107,
    Code: "12901",
    Name: "Xã Thụy Sơn",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4108,
    Code: "12904",
    Name: "Xã Thụy Phong",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4109,
    Code: "12907",
    Name: "Xã Thái Thượng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4110,
    Code: "12910",
    Name: "Xã Thái Nguyên",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4111,
    Code: "12916",
    Name: "Xã Dương Hồng  Thủy",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4112,
    Code: "12919",
    Name: "Xã Thái Giang",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4113,
    Code: "12922",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4114,
    Code: "12925",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4115,
    Code: "12934",
    Name: "Xã Thái Phúc",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4116,
    Code: "12937",
    Name: "Xã Thái Hưng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4117,
    Code: "12940",
    Name: "Xã Thái Đô",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4118,
    Code: "12943",
    Name: "Xã Thái Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4119,
    Code: "12949",
    Name: "Xã  Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4120,
    Code: "12958",
    Name: "Xã Tân Học",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4121,
    Code: "12961",
    Name: "Xã Thái Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4122,
    Code: "12964",
    Name: "Xã Thuần Thành",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4123,
    Code: "12967",
    Name: "Xã Thái Thọ",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 4124,
    Code: "12970",
    Name: "Thị trấn Tiền Hải",
    LevelUp: "Thị trấn",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4125,
    Code: "12976",
    Name: "Xã Đông Trà",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4126,
    Code: "12979",
    Name: "Xã Đông Long",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4127,
    Code: "12982",
    Name: "Xã Đông Quí",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4128,
    Code: "12985",
    Name: "Xã Vũ Lăng",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4129,
    Code: "12988",
    Name: "Xã Đông Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4130,
    Code: "12991",
    Name: "Xã Tây Lương",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4131,
    Code: "12994",
    Name: "Xã Tây Ninh",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4132,
    Code: "12997",
    Name: "Xã Đông Trung",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4133,
    Code: "13000",
    Name: "Xã Đông Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4134,
    Code: "13003",
    Name: "Xã Đông Minh",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4135,
    Code: "13009",
    Name: "Xã Đông Phong",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4136,
    Code: "13012",
    Name: "Xã An Ninh",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4137,
    Code: "13018",
    Name: "Xã Đông Cơ",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4138,
    Code: "13021",
    Name: "Xã Tây Giang",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4139,
    Code: "13024",
    Name: "Xã Đông Lâm",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4140,
    Code: "13027",
    Name: "Xã Phương Công",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4141,
    Code: "13030",
    Name: "Xã Tây Phong",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4142,
    Code: "13033",
    Name: "Xã Tây Tiến",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4143,
    Code: "13036",
    Name: "Xã Nam Cường",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4144,
    Code: "13039",
    Name: "Xã Vân Trường",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4145,
    Code: "13042",
    Name: "Xã Nam Thắng",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4146,
    Code: "13045",
    Name: "Xã Nam Chính",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4147,
    Code: "13048",
    Name: "Xã Bắc Hải",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4148,
    Code: "13051",
    Name: "Xã Nam Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4149,
    Code: "13054",
    Name: "Xã Nam Hà",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4150,
    Code: "13057",
    Name: "Xã Nam Thanh",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4151,
    Code: "13060",
    Name: "Xã Nam Trung",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4152,
    Code: "13063",
    Name: "Xã Nam Hồng",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4153,
    Code: "13066",
    Name: "Xã Nam Hưng",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4154,
    Code: "13069",
    Name: "Xã Nam Hải",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4155,
    Code: "13072",
    Name: "Xã Nam Phú",
    LevelUp: "Xã",
    DistrictCode: "2607",
    Active: -1,
  },
  {
    ID: 4156,
    Code: "13075",
    Name: "Thị trấn Kiến Xương",
    LevelUp: "Thị trấn",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4157,
    Code: "13078",
    Name: "Xã Trà Giang",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4158,
    Code: "13081",
    Name: "Xã Quốc Tuấn",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4159,
    Code: "13087",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4160,
    Code: "13090",
    Name: "Xã Tây Sơn",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4161,
    Code: "13093",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4162,
    Code: "13096",
    Name: "Xã Bình Nguyên",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4163,
    Code: "13102",
    Name: "Xã Lê Lợi",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4164,
    Code: "13111",
    Name: "Xã Vũ Lễ",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4165,
    Code: "13114",
    Name: "Xã Thanh Tân",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4166,
    Code: "13117",
    Name: "Xã Thượng Hiền",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4167,
    Code: "13120",
    Name: "Xã Nam Cao",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4168,
    Code: "13123",
    Name: "Xã Đình Phùng",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4169,
    Code: "13126",
    Name: "Xã Vũ Ninh",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4170,
    Code: "13129",
    Name: "Xã Vũ An",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4171,
    Code: "13132",
    Name: "Xã Quang Lịch",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4172,
    Code: "13135",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4173,
    Code: "13138",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4174,
    Code: "13141",
    Name: "Xã Vũ Quí",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4175,
    Code: "13144",
    Name: "Xã Quang Bình",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4176,
    Code: "13150",
    Name: "Xã Vũ Trung",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4177,
    Code: "13153",
    Name: "Xã Vũ Thắng",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4178,
    Code: "13156",
    Name: "Xã Vũ Công",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4179,
    Code: "13159",
    Name: "Xã Vũ Hòa",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4180,
    Code: "13162",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4181,
    Code: "13165",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4182,
    Code: "13171",
    Name: "Xã Minh Quang",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4183,
    Code: "13174",
    Name: "Xã Vũ Bình",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4184,
    Code: "13177",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4185,
    Code: "13180",
    Name: "Xã Nam Bình",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4186,
    Code: "13183",
    Name: "Xã Bình Thanh",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4187,
    Code: "13186",
    Name: "Xã Bình Định",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4188,
    Code: "13189",
    Name: "Xã Hồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 4189,
    Code: "13192",
    Name: "Thị trấn Vũ Thư",
    LevelUp: "Thị trấn",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4190,
    Code: "13195",
    Name: "Xã Hồng Lý",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4191,
    Code: "13198",
    Name: "Xã Đồng Thanh",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4192,
    Code: "13201",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4193,
    Code: "13204",
    Name: "Xã Hiệp Hòa",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4194,
    Code: "13207",
    Name: "Xã Phúc Thành",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4195,
    Code: "13210",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4196,
    Code: "13213",
    Name: "Xã Song Lãng",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4197,
    Code: "13216",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4198,
    Code: "13219",
    Name: "Xã Việt Hùng",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4199,
    Code: "13222",
    Name: "Xã Minh Lãng",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4200,
    Code: "13228",
    Name: "Xã Minh Khai",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4201,
    Code: "13231",
    Name: "Xã Dũng Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4202,
    Code: "13234",
    Name: "Xã Minh Quang",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4203,
    Code: "13237",
    Name: "Xã Tam Quang",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4204,
    Code: "13240",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4205,
    Code: "13243",
    Name: "Xã Bách Thuận",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4206,
    Code: "13246",
    Name: "Xã Tự Tân",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4207,
    Code: "13249",
    Name: "Xã Song An",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4208,
    Code: "13252",
    Name: "Xã Trung An",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4209,
    Code: "13255",
    Name: "Xã Vũ Hội",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4210,
    Code: "13258",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4211,
    Code: "13261",
    Name: "Xã Nguyên Xá",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4212,
    Code: "13264",
    Name: "Xã Việt Thuận",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4213,
    Code: "13267",
    Name: "Xã Vũ Vinh",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4214,
    Code: "13270",
    Name: "Xã Vũ Đoài",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4215,
    Code: "13273",
    Name: "Xã Vũ Tiến",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4216,
    Code: "13276",
    Name: "Xã Vũ Vân",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4217,
    Code: "13279",
    Name: "Xã Duy Nhất",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4218,
    Code: "13282",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "2605",
    Active: -1,
  },
  {
    ID: 4219,
    Code: "13285",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4220,
    Code: "13288",
    Name: "Phường Lương Khánh Thiện",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4221,
    Code: "13291",
    Name: "Phường Lê Hồng Phong",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4222,
    Code: "13294",
    Name: "Phường Minh Khai",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4223,
    Code: "13297",
    Name: "Phường Hai Bà Trưng",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4224,
    Code: "13300",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4225,
    Code: "13303",
    Name: "Phường Lam Hạ",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4226,
    Code: "13306",
    Name: "Xã Phù Vân",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4227,
    Code: "13309",
    Name: "Phường Liêm Chính",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4228,
    Code: "13312",
    Name: "Xã Liêm Chung",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4229,
    Code: "13315",
    Name: "Phường Thanh Châu",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4230,
    Code: "13318",
    Name: "Phường Châu Sơn",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4231,
    Code: "13366",
    Name: "Xã Tiên Tân",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4232,
    Code: "13372",
    Name: "Xã Tiên Hiệp",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4233,
    Code: "13381",
    Name: "Xã Tiên Hải",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4234,
    Code: "13426",
    Name: "Xã Kim Bình",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4235,
    Code: "13444",
    Name: "Xã Liêm Tuyền",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4236,
    Code: "13447",
    Name: "Xã Liêm Tiết",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4237,
    Code: "13459",
    Name: "Phường Thanh Tuyền",
    LevelUp: "Phường",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4238,
    Code: "13507",
    Name: "Xã Đinh Xá",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4239,
    Code: "13513",
    Name: "Xã Trịnh Xá",
    LevelUp: "Xã",
    DistrictCode: "2401",
    Active: -1,
  },
  {
    ID: 4240,
    Code: "13321",
    Name: "Phường Đồng Văn",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4241,
    Code: "13324",
    Name: "Phường Hòa Mạc",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4242,
    Code: "13327",
    Name: "Xã Mộc Bắc",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4243,
    Code: "13330",
    Name: "Phường Châu Giang",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4244,
    Code: "13333",
    Name: "Phường Bạch Thượng",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4245,
    Code: "13336",
    Name: "Phường Duy Minh",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4246,
    Code: "13339",
    Name: "Xã Mộc Nam",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4247,
    Code: "13342",
    Name: "Phường Duy Hải",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4248,
    Code: "13345",
    Name: "Xã Chuyên Ngoại",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4249,
    Code: "13348",
    Name: "Phường Yên Bắc",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4250,
    Code: "13351",
    Name: "Xã Trác Văn",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4251,
    Code: "13354",
    Name: "Phường Tiên Nội",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4252,
    Code: "13357",
    Name: "Phường Hoàng Đông",
    LevelUp: "Phường",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4253,
    Code: "13360",
    Name: "Xã Yên Nam",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4254,
    Code: "13363",
    Name: "Xã Tiên Ngoại",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4255,
    Code: "13369",
    Name: "Xã Tiên Sơn",
    LevelUp: "Xã",
    DistrictCode: "2402",
    Active: -1,
  },
  {
    ID: 4256,
    Code: "13384",
    Name: "Thị trấn Quế",
    LevelUp: "Thị trấn",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4257,
    Code: "13387",
    Name: "Xã Nguyễn Úy",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4258,
    Code: "13390",
    Name: "Xã Đại Cương",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4259,
    Code: "13393",
    Name: "Xã Lê Hồ",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4260,
    Code: "13396",
    Name: "Xã Tượng Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4261,
    Code: "13399",
    Name: "Xã Nhật Tựu",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4262,
    Code: "13402",
    Name: "Xã Nhật Tân",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4263,
    Code: "13405",
    Name: "Xã Đồng Hóa",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4264,
    Code: "13408",
    Name: "Xã Hoàng Tây",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4265,
    Code: "13411",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4266,
    Code: "13414",
    Name: "Xã Thụy Lôi",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4267,
    Code: "13417",
    Name: "Xã Văn Xá",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4268,
    Code: "13420",
    Name: "Xã Khả Phong",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4269,
    Code: "13423",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4270,
    Code: "13429",
    Name: "Thị trấn Ba Sao",
    LevelUp: "Thị trấn",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4271,
    Code: "13432",
    Name: "Xã Liên Sơn",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4272,
    Code: "13435",
    Name: "Xã Thi Sơn",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4273,
    Code: "13438",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2403",
    Active: -1,
  },
  {
    ID: 4274,
    Code: "13441",
    Name: "Thị trấn Kiện Khê",
    LevelUp: "Thị trấn",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4275,
    Code: "13450",
    Name: "Xã Liêm Phong",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4276,
    Code: "13453",
    Name: "Xã Thanh Hà",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4277,
    Code: "13456",
    Name: "Xã Liêm Cần",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4278,
    Code: "13465",
    Name: "Xã Liêm Thuận",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4279,
    Code: "13468",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4280,
    Code: "13471",
    Name: "Xã Thanh Phong",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4281,
    Code: "13474",
    Name: "Thị trấn Tân Thanh",
    LevelUp: "Thị trấn",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4282,
    Code: "13477",
    Name: "Xã Thanh Tân",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4283,
    Code: "13480",
    Name: "Xã Liêm Túc",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4284,
    Code: "13483",
    Name: "Xã Liêm Sơn",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4285,
    Code: "13486",
    Name: "Xã Thanh Hương",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4286,
    Code: "13489",
    Name: "Xã Thanh Nghị",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4287,
    Code: "13492",
    Name: "Xã Thanh Tâm",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4288,
    Code: "13495",
    Name: "Xã Thanh Nguyên",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4289,
    Code: "13498",
    Name: "Xã Thanh Hải",
    LevelUp: "Xã",
    DistrictCode: "2405",
    Active: -1,
  },
  {
    ID: 4290,
    Code: "13501",
    Name: "Thị trấn Bình Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4291,
    Code: "13504",
    Name: "Xã Bình Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4292,
    Code: "13510",
    Name: "Xã Tràng An",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4293,
    Code: "13516",
    Name: "Xã Đồng Du",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4294,
    Code: "13519",
    Name: "Xã Ngọc Lũ",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4295,
    Code: "13522",
    Name: "Xã Hưng Công",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4296,
    Code: "13525",
    Name: "Xã Đồn Xá",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4297,
    Code: "13528",
    Name: "Xã An Ninh",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4298,
    Code: "13531",
    Name: "Xã Bồ Đề",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4299,
    Code: "13534",
    Name: "Xã Bối Cầu",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4300,
    Code: "13540",
    Name: "Xã An Nội",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4301,
    Code: "13543",
    Name: "Xã Vũ Bản",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4302,
    Code: "13546",
    Name: "Xã Trung Lương",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4303,
    Code: "13552",
    Name: "Xã An Đổ",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4304,
    Code: "13555",
    Name: "Xã La Sơn",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4305,
    Code: "13558",
    Name: "Xã Tiêu Động",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4306,
    Code: "13561",
    Name: "Xã An Lão",
    LevelUp: "Xã",
    DistrictCode: "2406",
    Active: -1,
  },
  {
    ID: 4307,
    Code: "13567",
    Name: "Xã Hợp Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4308,
    Code: "13570",
    Name: "Xã Nguyên Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4309,
    Code: "13573",
    Name: "Xã Chính Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4310,
    Code: "13576",
    Name: "Xã Chân Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4311,
    Code: "13579",
    Name: "Xã Đạo Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4312,
    Code: "13582",
    Name: "Xã Công Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4313,
    Code: "13585",
    Name: "Xã Văn Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4314,
    Code: "13588",
    Name: "Xã Bắc Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4315,
    Code: "13591",
    Name: "Xã Đức Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4316,
    Code: "13594",
    Name: "Xã Trần Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4317,
    Code: "13597",
    Name: "Thị trấn Vĩnh Trụ",
    LevelUp: "Thị trấn",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4318,
    Code: "13600",
    Name: "Xã Nhân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4319,
    Code: "13606",
    Name: "Xã Nhân Khang",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4320,
    Code: "13609",
    Name: "Xã Nhân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4321,
    Code: "13612",
    Name: "Xã Nhân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4322,
    Code: "13615",
    Name: "Xã Nhân Chính",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4323,
    Code: "13618",
    Name: "Xã Nhân Bình",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4324,
    Code: "13621",
    Name: "Xã Phú Phúc",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4325,
    Code: "13624",
    Name: "Xã Xuân Khê",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4326,
    Code: "13627",
    Name: "Xã Tiến Thắng",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4327,
    Code: "13630",
    Name: "Xã Hòa Hậu",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 4328,
    Code: "13633",
    Name: "Phường Hạ Long",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4329,
    Code: "13636",
    Name: "Phường Trần Tế Xương",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4330,
    Code: "13639",
    Name: "Phường Vị Hoàng",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4331,
    Code: "13642",
    Name: "Phường Vị Xuyên",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4332,
    Code: "13645",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4333,
    Code: "13648",
    Name: "Phường Cửa Bắc",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4334,
    Code: "13651",
    Name: "Phường Nguyễn Du",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4335,
    Code: "13654",
    Name: "Phường Bà Triệu",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4336,
    Code: "13657",
    Name: "Phường Trường Thi",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4337,
    Code: "13660",
    Name: "Phường Phan Đình Phùng",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4338,
    Code: "13663",
    Name: "Phường Ngô Quyền",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4339,
    Code: "13666",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4340,
    Code: "13669",
    Name: "Phường Trần Đăng Ninh",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4341,
    Code: "13672",
    Name: "Phường Năng Tĩnh",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4342,
    Code: "13675",
    Name: "Phường Văn Miếu",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4343,
    Code: "13678",
    Name: "Phường Trần Quang Khải",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4344,
    Code: "13681",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4345,
    Code: "13684",
    Name: "Phường Lộc Hạ",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4346,
    Code: "13687",
    Name: "Phường Lộc Vượng",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4347,
    Code: "13690",
    Name: "Phường Cửa Nam",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4348,
    Code: "13693",
    Name: "Phường Lộc Hòa",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4349,
    Code: "13696",
    Name: "Xã Nam Phong",
    LevelUp: "Xã",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4350,
    Code: "13699",
    Name: "Phường Mỹ Xá",
    LevelUp: "Phường",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4351,
    Code: "13702",
    Name: "Xã Lộc An",
    LevelUp: "Xã",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4352,
    Code: "13705",
    Name: "Xã Nam Vân",
    LevelUp: "Xã",
    DistrictCode: "2501",
    Active: -1,
  },
  {
    ID: 4353,
    Code: "13708",
    Name: "Thị trấn Mỹ Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4354,
    Code: "13711",
    Name: "Xã Mỹ Hà",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4355,
    Code: "13714",
    Name: "Xã Mỹ Tiến",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4356,
    Code: "13717",
    Name: "Xã Mỹ Thắng",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4357,
    Code: "13720",
    Name: "Xã Mỹ Trung",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4358,
    Code: "13723",
    Name: "Xã Mỹ Tân",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4359,
    Code: "13726",
    Name: "Xã Mỹ Phúc",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4360,
    Code: "13729",
    Name: "Xã Mỹ Hưng",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4361,
    Code: "13732",
    Name: "Xã Mỹ Thuận",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4362,
    Code: "13735",
    Name: "Xã Mỹ Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4363,
    Code: "13738",
    Name: "Xã Mỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "2502",
    Active: -1,
  },
  {
    ID: 4364,
    Code: "13741",
    Name: "Thị trấn Gôi",
    LevelUp: "Thị trấn",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4365,
    Code: "13744",
    Name: "Xã Minh Thuận",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4366,
    Code: "13747",
    Name: "Xã Hiển Khánh",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4367,
    Code: "13750",
    Name: "Xã Tân Khánh",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4368,
    Code: "13753",
    Name: "Xã Hợp Hưng",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4369,
    Code: "13756",
    Name: "Xã Đại An",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4370,
    Code: "13759",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4371,
    Code: "13762",
    Name: "Xã Cộng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4372,
    Code: "13765",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4373,
    Code: "13768",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4374,
    Code: "13771",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4375,
    Code: "13774",
    Name: "Xã Liên Bảo",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4376,
    Code: "13777",
    Name: "Xã Thành Lợi",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4377,
    Code: "13780",
    Name: "Xã Kim Thái",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4378,
    Code: "13783",
    Name: "Xã Liên Minh",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4379,
    Code: "13786",
    Name: "Xã Đại Thắng",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4380,
    Code: "13789",
    Name: "Xã Tam Thanh",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4381,
    Code: "13792",
    Name: "Xã Vĩnh Hào",
    LevelUp: "Xã",
    DistrictCode: "2506",
    Active: -1,
  },
  {
    ID: 4382,
    Code: "13795",
    Name: "Thị trấn Lâm",
    LevelUp: "Thị trấn",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4383,
    Code: "13798",
    Name: "Xã Yên Trung",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4384,
    Code: "13801",
    Name: "Xã Yên Thành",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4385,
    Code: "13804",
    Name: "Xã Yên Tân",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4386,
    Code: "13807",
    Name: "Xã Yên Lợi",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4387,
    Code: "13810",
    Name: "Xã Yên Thọ",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4388,
    Code: "13813",
    Name: "Xã Yên Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4389,
    Code: "13816",
    Name: "Xã Yên Minh",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4390,
    Code: "13819",
    Name: "Xã Yên Phương",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4391,
    Code: "13822",
    Name: "Xã Yên Chính",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4392,
    Code: "13825",
    Name: "Xã Yên Bình",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4393,
    Code: "13828",
    Name: "Xã Yên Phú",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4394,
    Code: "13831",
    Name: "Xã Yên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4395,
    Code: "13834",
    Name: "Xã Yên Dương",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4396,
    Code: "13840",
    Name: "Xã Yên Hưng",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4397,
    Code: "13843",
    Name: "Xã Yên Khánh",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4398,
    Code: "13846",
    Name: "Xã Yên Phong",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4399,
    Code: "13849",
    Name: "Xã Yên Ninh",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4400,
    Code: "13852",
    Name: "Xã Yên Lương",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4401,
    Code: "13855",
    Name: "Xã Yên Hồng",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4402,
    Code: "13858",
    Name: "Xã Yên Quang",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4403,
    Code: "13861",
    Name: "Xã Yên Tiến",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4404,
    Code: "13864",
    Name: "Xã Yên Thắng",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4405,
    Code: "13867",
    Name: "Xã Yên Phúc",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4406,
    Code: "13870",
    Name: "Xã Yên Cường",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4407,
    Code: "13873",
    Name: "Xã Yên Lộc",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4408,
    Code: "13876",
    Name: "Xã Yên Bằng",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4409,
    Code: "13879",
    Name: "Xã Yên Đồng",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4410,
    Code: "13882",
    Name: "Xã Yên Khang",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4411,
    Code: "13885",
    Name: "Xã Yên Nhân",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4412,
    Code: "13888",
    Name: "Xã Yên Trị",
    LevelUp: "Xã",
    DistrictCode: "2505",
    Active: -1,
  },
  {
    ID: 4413,
    Code: "13891",
    Name: "Thị trấn Liễu Đề",
    LevelUp: "Thị trấn",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4414,
    Code: "13894",
    Name: "Thị trấn Rạng Đông",
    LevelUp: "Thị trấn",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4415,
    Code: "13897",
    Name: "Xã Nghĩa Đồng",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4416,
    Code: "13900",
    Name: "Xã Nghĩa Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4417,
    Code: "13903",
    Name: "Xã Nghĩa Minh",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4418,
    Code: "13906",
    Name: "Xã Nghĩa Thái",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4419,
    Code: "13909",
    Name: "Xã Hoàng Nam",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4420,
    Code: "13912",
    Name: "Xã Nghĩa Châu",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4421,
    Code: "13915",
    Name: "Xã Nghĩa Trung",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4422,
    Code: "13918",
    Name: "Xã Nghĩa Sơn",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4423,
    Code: "13921",
    Name: "Xã Nghĩa Lạc",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4424,
    Code: "13924",
    Name: "Xã Nghĩa Hồng",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4425,
    Code: "13927",
    Name: "Xã Nghĩa Phong",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4426,
    Code: "13930",
    Name: "Xã Nghĩa Phú",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4427,
    Code: "13933",
    Name: "Xã Nghĩa Bình",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4428,
    Code: "13936",
    Name: "Thị trấn Quỹ Nhất",
    LevelUp: "Thị trấn",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4429,
    Code: "13939",
    Name: "Xã Nghĩa Tân",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4430,
    Code: "13942",
    Name: "Xã Nghĩa Hùng",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4431,
    Code: "13945",
    Name: "Xã Nghĩa Lâm",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4432,
    Code: "13948",
    Name: "Xã Nghĩa Thành",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4433,
    Code: "13951",
    Name: "Xã Phúc Thắng",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4434,
    Code: "13954",
    Name: "Xã Nghĩa Lợi",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4435,
    Code: "13957",
    Name: "Xã Nghĩa Hải",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4436,
    Code: "13963",
    Name: "Xã Nam Điền",
    LevelUp: "Xã",
    DistrictCode: "2509",
    Active: -1,
  },
  {
    ID: 4437,
    Code: "13966",
    Name: "Thị trấn Nam Giang",
    LevelUp: "Thị trấn",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4438,
    Code: "13969",
    Name: "Xã Nam Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4439,
    Code: "13972",
    Name: "Xã Điền Xá",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4440,
    Code: "13975",
    Name: "Xã Nghĩa An",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4441,
    Code: "13978",
    Name: "Xã Nam Thắng",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4442,
    Code: "13981",
    Name: "Xã Nam Toàn",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4443,
    Code: "13984",
    Name: "Xã Hồng Quang",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4444,
    Code: "13987",
    Name: "Xã Tân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4445,
    Code: "13990",
    Name: "Xã Nam Cường",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4446,
    Code: "13993",
    Name: "Xã Nam Hồng",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4447,
    Code: "13996",
    Name: "Xã Nam Hùng",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4448,
    Code: "13999",
    Name: "Xã Nam Hoa",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4449,
    Code: "14002",
    Name: "Xã Nam Dương",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4450,
    Code: "14005",
    Name: "Xã Nam Thanh",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4451,
    Code: "14008",
    Name: "Xã Nam Lợi",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4452,
    Code: "14011",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4453,
    Code: "14014",
    Name: "Xã Đồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4454,
    Code: "14017",
    Name: "Xã Nam Tiến",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4455,
    Code: "14020",
    Name: "Xã Nam Hải",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4456,
    Code: "14023",
    Name: "Xã Nam Thái",
    LevelUp: "Xã",
    DistrictCode: "2507",
    Active: -1,
  },
  {
    ID: 4457,
    Code: "14026",
    Name: "Thị trấn Cổ Lễ",
    LevelUp: "Thị trấn",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4458,
    Code: "14029",
    Name: "Xã Phương Định",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4459,
    Code: "14032",
    Name: "Xã Trực Chính",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4460,
    Code: "14035",
    Name: "Xã Trung Đông",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4461,
    Code: "14038",
    Name: "Xã Liêm Hải",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4462,
    Code: "14041",
    Name: "Xã Trực Tuấn",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4463,
    Code: "14044",
    Name: "Xã Việt Hùng",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4464,
    Code: "14047",
    Name: "Xã Trực Đạo",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4465,
    Code: "14050",
    Name: "Xã Trực Hưng",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4466,
    Code: "14053",
    Name: "Xã Trực Nội",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4467,
    Code: "14056",
    Name: "Thị trấn Cát Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4468,
    Code: "14059",
    Name: "Xã Trực Thanh",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4469,
    Code: "14062",
    Name: "Xã Trực Khang",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4470,
    Code: "14065",
    Name: "Xã Trực Thuận",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4471,
    Code: "14068",
    Name: "Xã Trực Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4472,
    Code: "14071",
    Name: "Xã Trực Đại",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4473,
    Code: "14074",
    Name: "Xã Trực Cường",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4474,
    Code: "14077",
    Name: "Thị trấn Ninh Cường",
    LevelUp: "Thị trấn",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4475,
    Code: "14080",
    Name: "Xã Trực Thái",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4476,
    Code: "14083",
    Name: "Xã Trực Hùng",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4477,
    Code: "14086",
    Name: "Xã Trực Thắng",
    LevelUp: "Xã",
    DistrictCode: "2508",
    Active: -1,
  },
  {
    ID: 4478,
    Code: "14089",
    Name: "Thị trấn Xuân Trường",
    LevelUp: "Thị trấn",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4479,
    Code: "14092",
    Name: "Xã Xuân Châu",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4480,
    Code: "14095",
    Name: "Xã Xuân Hồng",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4481,
    Code: "14098",
    Name: "Xã Xuân Thành",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4482,
    Code: "14101",
    Name: "Xã Xuân Thượng",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4483,
    Code: "14104",
    Name: "Xã Xuân Phong",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4484,
    Code: "14107",
    Name: "Xã Xuân Đài",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4485,
    Code: "14110",
    Name: "Xã Xuân Tân",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4486,
    Code: "14113",
    Name: "Xã Xuân Thủy",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4487,
    Code: "14116",
    Name: "Xã Xuân Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4488,
    Code: "14119",
    Name: "Xã Xuân Bắc",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4489,
    Code: "14122",
    Name: "Xã Xuân Phương",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4490,
    Code: "14125",
    Name: "Xã Thọ Nghiệp",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4491,
    Code: "14128",
    Name: "Xã Xuân Phú",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4492,
    Code: "14131",
    Name: "Xã Xuân Trung",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4493,
    Code: "14134",
    Name: "Xã Xuân Vinh",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4494,
    Code: "14137",
    Name: "Xã Xuân Kiên",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4495,
    Code: "14140",
    Name: "Xã Xuân Tiến",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4496,
    Code: "14143",
    Name: "Xã Xuân Ninh",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4497,
    Code: "14146",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2503",
    Active: -1,
  },
  {
    ID: 4498,
    Code: "14149",
    Name: "Thị trấn Ngô Đồng",
    LevelUp: "Thị trấn",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4499,
    Code: "14152",
    Name: "Thị trấn Quất Lâm",
    LevelUp: "Thị trấn",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4500,
    Code: "14155",
    Name: "Xã Giao Hương",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4501,
    Code: "14158",
    Name: "Xã Hồng Thuận",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4502,
    Code: "14161",
    Name: "Xã Giao Thiện",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4503,
    Code: "14164",
    Name: "Xã Giao Thanh",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4504,
    Code: "14167",
    Name: "Xã Hoành Sơn",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4505,
    Code: "14170",
    Name: "Xã Bình Hòa",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4506,
    Code: "14173",
    Name: "Xã Giao Tiến",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4507,
    Code: "14176",
    Name: "Xã Giao Hà",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4508,
    Code: "14179",
    Name: "Xã Giao Nhân",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4509,
    Code: "14182",
    Name: "Xã Giao An",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4510,
    Code: "14185",
    Name: "Xã Giao Lạc",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4511,
    Code: "14188",
    Name: "Xã Giao Châu",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4512,
    Code: "14191",
    Name: "Xã Giao Tân",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4513,
    Code: "14194",
    Name: "Xã Giao Yến",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4514,
    Code: "14197",
    Name: "Xã Giao Xuân",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4515,
    Code: "14200",
    Name: "Xã Giao Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4516,
    Code: "14203",
    Name: "Xã Giao Hải",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4517,
    Code: "14206",
    Name: "Xã Bạch Long",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4518,
    Code: "14209",
    Name: "Xã Giao Long",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4519,
    Code: "14212",
    Name: "Xã Giao Phong",
    LevelUp: "Xã",
    DistrictCode: "2504",
    Active: -1,
  },
  {
    ID: 4520,
    Code: "14215",
    Name: "Thị trấn Yên Định",
    LevelUp: "Thị trấn",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4521,
    Code: "14218",
    Name: "Thị trấn Cồn",
    LevelUp: "Thị trấn",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4522,
    Code: "14221",
    Name: "Thị trấn Thịnh Long",
    LevelUp: "Thị trấn",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4523,
    Code: "14224",
    Name: "Xã Hải Nam",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4524,
    Code: "14227",
    Name: "Xã Hải Trung",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4525,
    Code: "14230",
    Name: "Xã Hải Vân",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4526,
    Code: "14233",
    Name: "Xã Hải Minh",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4527,
    Code: "14236",
    Name: "Xã Hải Anh",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4528,
    Code: "14239",
    Name: "Xã Hải Hưng",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4529,
    Code: "14242",
    Name: "Xã Hải Bắc",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4530,
    Code: "14245",
    Name: "Xã Hải Phúc",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4531,
    Code: "14248",
    Name: "Xã Hải Thanh",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4532,
    Code: "14251",
    Name: "Xã Hải Hà",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4533,
    Code: "14254",
    Name: "Xã Hải Long",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4534,
    Code: "14257",
    Name: "Xã Hải Phương",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4535,
    Code: "14260",
    Name: "Xã Hải Đường",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4536,
    Code: "14263",
    Name: "Xã Hải Lộc",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4537,
    Code: "14266",
    Name: "Xã Hải Quang",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4538,
    Code: "14269",
    Name: "Xã Hải Đông",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4539,
    Code: "14272",
    Name: "Xã Hải Sơn",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4540,
    Code: "14275",
    Name: "Xã Hải Tân",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4541,
    Code: "14281",
    Name: "Xã Hải Phong",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4542,
    Code: "14284",
    Name: "Xã Hải An",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4543,
    Code: "14287",
    Name: "Xã Hải Tây",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4544,
    Code: "14290",
    Name: "Xã Hải Lý",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4545,
    Code: "14293",
    Name: "Xã Hải Phú",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4546,
    Code: "14296",
    Name: "Xã Hải Giang",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4547,
    Code: "14299",
    Name: "Xã Hải Cường",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4548,
    Code: "14302",
    Name: "Xã Hải Ninh",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4549,
    Code: "14305",
    Name: "Xã Hải Chính",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4550,
    Code: "14308",
    Name: "Xã Hải Xuân",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4551,
    Code: "14311",
    Name: "Xã Hải Châu",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4552,
    Code: "14314",
    Name: "Xã Hải Triều",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4553,
    Code: "14317",
    Name: "Xã Hải Hòa",
    LevelUp: "Xã",
    DistrictCode: "2510",
    Active: -1,
  },
  {
    ID: 4554,
    Code: "14320",
    Name: "Phường Đông Thành",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4555,
    Code: "14323",
    Name: "Phường Tân Thành",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4556,
    Code: "14326",
    Name: "Phường Thanh Bình",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4557,
    Code: "14329",
    Name: "Phường Vân Giang",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4558,
    Code: "14332",
    Name: "Phường Bích Đào",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4559,
    Code: "14335",
    Name: "Phường Phúc Thành",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4560,
    Code: "14338",
    Name: "Phường Nam Bình",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4561,
    Code: "14341",
    Name: "Phường Nam Thành",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4562,
    Code: "14344",
    Name: "Phường Ninh Khánh",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4563,
    Code: "14347",
    Name: "Xã Ninh Nhất",
    LevelUp: "Xã",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4564,
    Code: "14350",
    Name: "Xã Ninh Tiến",
    LevelUp: "Xã",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4565,
    Code: "14353",
    Name: "Xã Ninh Phúc",
    LevelUp: "Xã",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4566,
    Code: "14356",
    Name: "Phường Ninh Sơn",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4567,
    Code: "14359",
    Name: "Phường Ninh Phong",
    LevelUp: "Phường",
    DistrictCode: "2701",
    Active: -1,
  },
  {
    ID: 4568,
    Code: "14362",
    Name: "Phường Bắc Sơn",
    LevelUp: "Phường",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4569,
    Code: "14365",
    Name: "Phường Trung Sơn",
    LevelUp: "Phường",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4570,
    Code: "14368",
    Name: "Phường Nam Sơn",
    LevelUp: "Phường",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4571,
    Code: "14369",
    Name: "Phường Tây Sơn",
    LevelUp: "Phường",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4572,
    Code: "14371",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4573,
    Code: "14374",
    Name: "Phường Yên Bình",
    LevelUp: "Phường",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4574,
    Code: "14375",
    Name: "Phường Tân Bình",
    LevelUp: "Phường",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4575,
    Code: "14377",
    Name: "Xã Quang Sơn",
    LevelUp: "Xã",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4576,
    Code: "14380",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "2702",
    Active: -1,
  },
  {
    ID: 4577,
    Code: "14383",
    Name: "Thị trấn Nho Quan",
    LevelUp: "Thị trấn",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4578,
    Code: "14386",
    Name: "Xã Xích Thổ",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4579,
    Code: "14389",
    Name: "Xã Gia Lâm",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4580,
    Code: "14392",
    Name: "Xã Gia Sơn",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4581,
    Code: "14395",
    Name: "Xã Thạch Bình",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4582,
    Code: "14398",
    Name: "Xã Gia Thủy",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4583,
    Code: "14401",
    Name: "Xã Gia Tường",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4584,
    Code: "14404",
    Name: "Xã Cúc Phương",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4585,
    Code: "14407",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4586,
    Code: "14410",
    Name: "Xã Đức Long",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4587,
    Code: "14413",
    Name: "Xã Lạc Vân",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4588,
    Code: "14416",
    Name: "Xã Đồng Phong",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4589,
    Code: "14419",
    Name: "Xã Yên Quang",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4590,
    Code: "14422",
    Name: "Xã Lạng Phong",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4591,
    Code: "14425",
    Name: "Xã Thượng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4592,
    Code: "14428",
    Name: "Xã Văn Phong",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4593,
    Code: "14431",
    Name: "Xã Văn Phương",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4594,
    Code: "14434",
    Name: "Xã Thanh Lạc",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4595,
    Code: "14437",
    Name: "Xã Sơn Lai",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4596,
    Code: "14440",
    Name: "Xã Sơn Thành",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4597,
    Code: "14443",
    Name: "Xã Văn Phú",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4598,
    Code: "14446",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4599,
    Code: "14449",
    Name: "Xã Kỳ Phú",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4600,
    Code: "14452",
    Name: "Xã Quỳnh Lưu",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4601,
    Code: "14455",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4602,
    Code: "14458",
    Name: "Xã Phú Long",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4603,
    Code: "14461",
    Name: "Xã Quảng Lạc",
    LevelUp: "Xã",
    DistrictCode: "2703",
    Active: -1,
  },
  {
    ID: 4604,
    Code: "14464",
    Name: "Thị trấn Me",
    LevelUp: "Thị trấn",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4605,
    Code: "14467",
    Name: "Xã Gia Hòa",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4606,
    Code: "14470",
    Name: "Xã Gia Hưng",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4607,
    Code: "14473",
    Name: "Xã Liên Sơn",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4608,
    Code: "14476",
    Name: "Xã Gia Thanh",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4609,
    Code: "14479",
    Name: "Xã Gia Vân",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4610,
    Code: "14482",
    Name: "Xã Gia Phú",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4611,
    Code: "14485",
    Name: "Xã Gia Xuân",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4612,
    Code: "14488",
    Name: "Xã Gia Lập",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4613,
    Code: "14491",
    Name: "Xã Gia Vượng",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4614,
    Code: "14494",
    Name: "Xã Gia Trấn",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4615,
    Code: "14497",
    Name: "Xã Gia Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4616,
    Code: "14500",
    Name: "Xã Gia Phương",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4617,
    Code: "14503",
    Name: "Xã Gia Tân",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4618,
    Code: "14506",
    Name: "Xã Gia Thắng",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4619,
    Code: "14509",
    Name: "Xã Gia Trung",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4620,
    Code: "14512",
    Name: "Xã Gia Minh",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4621,
    Code: "14515",
    Name: "Xã Gia Lạc",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4622,
    Code: "14518",
    Name: "Xã Gia Tiến",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4623,
    Code: "14521",
    Name: "Xã Gia Sinh",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4624,
    Code: "14524",
    Name: "Xã Gia Phong",
    LevelUp: "Xã",
    DistrictCode: "2704",
    Active: -1,
  },
  {
    ID: 4625,
    Code: "14527",
    Name: "Thị trấn Thiên Tôn",
    LevelUp: "Thị trấn",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4626,
    Code: "14530",
    Name: "Xã Ninh Giang",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4627,
    Code: "14533",
    Name: "Xã Trường Yên",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4628,
    Code: "14536",
    Name: "Xã Ninh Khang",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4629,
    Code: "14539",
    Name: "Xã Ninh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4630,
    Code: "14542",
    Name: "Xã Ninh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4631,
    Code: "14545",
    Name: "Xã Ninh Xuân",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4632,
    Code: "14548",
    Name: "Xã Ninh Hải",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4633,
    Code: "14551",
    Name: "Xã Ninh Thắng",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4634,
    Code: "14554",
    Name: "Xã Ninh Vân",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4635,
    Code: "14557",
    Name: "Xã Ninh An",
    LevelUp: "Xã",
    DistrictCode: "2705",
    Active: -1,
  },
  {
    ID: 4636,
    Code: "14560",
    Name: "Thị trấn Yên Ninh",
    LevelUp: "Thị trấn",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4637,
    Code: "14563",
    Name: "Xã Khánh Tiên",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4638,
    Code: "14566",
    Name: "Xã Khánh Phú",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4639,
    Code: "14569",
    Name: "Xã Khánh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4640,
    Code: "14572",
    Name: "Xã Khánh Lợi",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4641,
    Code: "14575",
    Name: "Xã Khánh An",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4642,
    Code: "14578",
    Name: "Xã Khánh Cường",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4643,
    Code: "14581",
    Name: "Xã Khánh Cư",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4644,
    Code: "14584",
    Name: "Xã Khánh Thiện",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4645,
    Code: "14587",
    Name: "Xã Khánh Hải",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4646,
    Code: "14590",
    Name: "Xã Khánh Trung",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4647,
    Code: "14593",
    Name: "Xã Khánh Mậu",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4648,
    Code: "14596",
    Name: "Xã Khánh Vân",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4649,
    Code: "14599",
    Name: "Xã Khánh Hội",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4650,
    Code: "14602",
    Name: "Xã Khánh Công",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4651,
    Code: "14608",
    Name: "Xã Khánh Thành",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4652,
    Code: "14611",
    Name: "Xã Khánh Nhạc",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4653,
    Code: "14614",
    Name: "Xã Khánh Thủy",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4654,
    Code: "14617",
    Name: "Xã Khánh Hồng",
    LevelUp: "Xã",
    DistrictCode: "2708",
    Active: -1,
  },
  {
    ID: 4655,
    Code: "14620",
    Name: "Thị trấn Phát Diệm",
    LevelUp: "Thị trấn",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4656,
    Code: "14623",
    Name: "Thị trấn Bình Minh",
    LevelUp: "Thị trấn",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4657,
    Code: "14629",
    Name: "Xã Hồi Ninh",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4658,
    Code: "14632",
    Name: "Xã Xuân Chính",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4659,
    Code: "14635",
    Name: "Xã Kim Định",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4660,
    Code: "14638",
    Name: "Xã Ân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4661,
    Code: "14641",
    Name: "Xã Hùng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4662,
    Code: "14647",
    Name: "Xã Quang Thiện",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4663,
    Code: "14650",
    Name: "Xã Như Hòa",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4664,
    Code: "14653",
    Name: "Xã Chất Bình",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4665,
    Code: "14656",
    Name: "Xã Đồng Hướng",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4666,
    Code: "14659",
    Name: "Xã Kim Chính",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4667,
    Code: "14662",
    Name: "Xã Thượng Kiệm",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4668,
    Code: "14665",
    Name: "Xã Lưu Phương",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4669,
    Code: "14668",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4670,
    Code: "14671",
    Name: "Xã Yên Lộc",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4671,
    Code: "14674",
    Name: "Xã Lai Thành",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4672,
    Code: "14677",
    Name: "Xã Định Hóa",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4673,
    Code: "14680",
    Name: "Xã Văn Hải",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4674,
    Code: "14683",
    Name: "Xã Kim Tân",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4675,
    Code: "14686",
    Name: "Xã Kim Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4676,
    Code: "14689",
    Name: "Xã Cồn Thoi",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4677,
    Code: "14692",
    Name: "Xã Kim Hải",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4678,
    Code: "14695",
    Name: "Xã Kim Trung",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4679,
    Code: "14698",
    Name: "Xã Kim Đông",
    LevelUp: "Xã",
    DistrictCode: "2707",
    Active: -1,
  },
  {
    ID: 4680,
    Code: "14701",
    Name: "Thị trấn Yên Thịnh",
    LevelUp: "Thị trấn",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4681,
    Code: "14704",
    Name: "Xã Khánh Thượng",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4682,
    Code: "14707",
    Name: "Xã Khánh Dương",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4683,
    Code: "14710",
    Name: "Xã Mai Sơn",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4684,
    Code: "14713",
    Name: "Xã Khánh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4685,
    Code: "14719",
    Name: "Xã Yên Phong",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4686,
    Code: "14722",
    Name: "Xã Yên Hòa",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4687,
    Code: "14725",
    Name: "Xã Yên Thắng",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4688,
    Code: "14728",
    Name: "Xã Yên Từ",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4689,
    Code: "14731",
    Name: "Xã Yên Hưng",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4690,
    Code: "14734",
    Name: "Xã Yên Thành",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4691,
    Code: "14737",
    Name: "Xã Yên Nhân",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4692,
    Code: "14740",
    Name: "Xã Yên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4693,
    Code: "14743",
    Name: "Xã Yên Mạc",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4694,
    Code: "14746",
    Name: "Xã Yên Đồng",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4695,
    Code: "14749",
    Name: "Xã Yên Thái",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4696,
    Code: "14752",
    Name: "Xã Yên Lâm",
    LevelUp: "Xã",
    DistrictCode: "2706",
    Active: -1,
  },
  {
    ID: 4697,
    Code: "14755",
    Name: "Phường Hàm Rồng",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4698,
    Code: "14758",
    Name: "Phường Đông Thọ",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4699,
    Code: "14761",
    Name: "Phường Nam Ngạn",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4700,
    Code: "14764",
    Name: "Phường Trường Thi",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4701,
    Code: "14767",
    Name: "Phường Điện Biên",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4702,
    Code: "14770",
    Name: "Phường Phú Sơn",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4703,
    Code: "14773",
    Name: "Phường Lam Sơn",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4704,
    Code: "14776",
    Name: "Phường Ba Đình",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4705,
    Code: "14779",
    Name: "Phường Ngọc Trạo",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4706,
    Code: "14782",
    Name: "Phường Đông Vệ",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4707,
    Code: "14785",
    Name: "Phường Đông Sơn",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4708,
    Code: "14788",
    Name: "Phường Tân Sơn",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4709,
    Code: "14791",
    Name: "Phường Đông Cương",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4710,
    Code: "14794",
    Name: "Phường Đông Hương",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4711,
    Code: "14797",
    Name: "Phường Đông Hải",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4712,
    Code: "14800",
    Name: "Phường Quảng Hưng",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4713,
    Code: "14803",
    Name: "Phường Quảng Thắng",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4714,
    Code: "14806",
    Name: "Phường Quảng Thành",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4715,
    Code: "15850",
    Name: "Xã Thiệu Vân",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4716,
    Code: "15856",
    Name: "Xã Thiệu Khánh",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4717,
    Code: "15859",
    Name: "Xã Thiệu Dương",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4718,
    Code: "15913",
    Name: "Phường Tào Xuyên",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4719,
    Code: "15922",
    Name: "Xã Long Anh",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4720,
    Code: "15925",
    Name: "Xã Hoằng Quang",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4721,
    Code: "15970",
    Name: "Xã Hoằng Đại",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4722,
    Code: "16396",
    Name: "Xã Đông Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4723,
    Code: "16429",
    Name: "Xã Đông Vinh",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4724,
    Code: "16432",
    Name: "Xã Đông Tân",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4725,
    Code: "16435",
    Name: "Phường An Hưng",
    LevelUp: "Phường",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4726,
    Code: "16441",
    Name: "Xã Quảng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4727,
    Code: "16459",
    Name: "Xã Quảng Đông",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4728,
    Code: "16507",
    Name: "Xã Quảng Cát",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4729,
    Code: "16522",
    Name: "Xã Quảng Phú",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4730,
    Code: "16525",
    Name: "Xã Quảng Tâm",
    LevelUp: "Xã",
    DistrictCode: "2801",
    Active: -1,
  },
  {
    ID: 4731,
    Code: "14809",
    Name: "Phường Bắc Sơn",
    LevelUp: "Phường",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4732,
    Code: "14812",
    Name: "Phường Ba Đình",
    LevelUp: "Phường",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4733,
    Code: "14815",
    Name: "Phường Lam Sơn",
    LevelUp: "Phường",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4734,
    Code: "14818",
    Name: "Phường Ngọc Trạo",
    LevelUp: "Phường",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4735,
    Code: "14821",
    Name: "Phường Đông Sơn",
    LevelUp: "Phường",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4736,
    Code: "14823",
    Name: "Phường Phú Sơn",
    LevelUp: "Phường",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4737,
    Code: "14824",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "2802",
    Active: -1,
  },
  {
    ID: 4738,
    Code: "14830",
    Name: "Phường Trung Sơn",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4739,
    Code: "14833",
    Name: "Phường Bắc Sơn",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4740,
    Code: "14836",
    Name: "Phường Trường Sơn",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4741,
    Code: "14839",
    Name: "Phường Quảng Cư",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4742,
    Code: "14842",
    Name: "Phường Quảng Tiến",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4743,
    Code: "16513",
    Name: "Xã Quảng Minh",
    LevelUp: "Xã",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4744,
    Code: "16516",
    Name: "Xã Quảng Hùng",
    LevelUp: "Xã",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4745,
    Code: "16528",
    Name: "Phường Quảng Thọ",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4746,
    Code: "16531",
    Name: "Phường Quảng Châu",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4747,
    Code: "16534",
    Name: "Phường Quảng Vinh",
    LevelUp: "Phường",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4748,
    Code: "16537",
    Name: "Xã Quảng Đại",
    LevelUp: "Xã",
    DistrictCode: "2803",
    Active: -1,
  },
  {
    ID: 4749,
    Code: "14845",
    Name: "Thị trấn Mường Lát",
    LevelUp: "Thị trấn",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4750,
    Code: "14848",
    Name: "Xã Tam Chung",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4751,
    Code: "14854",
    Name: "Xã Mường Lý",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4752,
    Code: "14857",
    Name: "Xã Trung Lý",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4753,
    Code: "14860",
    Name: "Xã Quang Chiểu",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4754,
    Code: "14863",
    Name: "Xã Pù Nhi",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4755,
    Code: "14864",
    Name: "Xã Nhi Sơn",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4756,
    Code: "14866",
    Name: "Xã Mường Chanh",
    LevelUp: "Xã",
    DistrictCode: "2806",
    Active: -1,
  },
  {
    ID: 4757,
    Code: "14869",
    Name: "Thị trấn Hồi Xuân",
    LevelUp: "Thị trấn",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4758,
    Code: "14872",
    Name: "Xã Thành Sơn",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4759,
    Code: "14875",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4760,
    Code: "14878",
    Name: "Xã Phú Thanh",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4761,
    Code: "14881",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4762,
    Code: "14884",
    Name: "Xã Phú Lệ",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4763,
    Code: "14887",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4764,
    Code: "14890",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4765,
    Code: "14896",
    Name: "Xã Hiền Chung",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4766,
    Code: "14899",
    Name: "Xã Hiền Kiệt",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4767,
    Code: "14902",
    Name: "Xã Nam Tiến",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4768,
    Code: "14908",
    Name: "Xã Thiên Phủ",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4769,
    Code: "14911",
    Name: "Xã Phú Nghiêm",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4770,
    Code: "14914",
    Name: "Xã Nam Xuân",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4771,
    Code: "14917",
    Name: "Xã Nam Động",
    LevelUp: "Xã",
    DistrictCode: "2804",
    Active: -1,
  },
  {
    ID: 4772,
    Code: "14923",
    Name: "Thị trấn Cành Nàng",
    LevelUp: "Thị trấn",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4773,
    Code: "14926",
    Name: "Xã Điền Thượng",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4774,
    Code: "14929",
    Name: "Xã Điền Hạ",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4775,
    Code: "14932",
    Name: "Xã Điền Quang",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4776,
    Code: "14935",
    Name: "Xã Điền Trung",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4777,
    Code: "14938",
    Name: "Xã Thành Sơn",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4778,
    Code: "14941",
    Name: "Xã Lương Ngoại",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4779,
    Code: "14944",
    Name: "Xã Ái Thượng",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4780,
    Code: "14947",
    Name: "Xã Lương Nội",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4781,
    Code: "14950",
    Name: "Xã Điền Lư",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4782,
    Code: "14953",
    Name: "Xã Lương Trung",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4783,
    Code: "14956",
    Name: "Xã Lũng Niêm",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4784,
    Code: "14959",
    Name: "Xã Lũng Cao",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4785,
    Code: "14962",
    Name: "Xã Hạ Trung",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4786,
    Code: "14965",
    Name: "Xã Cổ Lũng",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4787,
    Code: "14968",
    Name: "Xã Thành Lâm",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4788,
    Code: "14971",
    Name: "Xã Ban Công",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4789,
    Code: "14974",
    Name: "Xã Kỳ Tân",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4790,
    Code: "14977",
    Name: "Xã Văn Nho",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4791,
    Code: "14980",
    Name: "Xã Thiết Ống",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4792,
    Code: "14986",
    Name: "Xã Thiết Kế",
    LevelUp: "Xã",
    DistrictCode: "2807",
    Active: -1,
  },
  {
    ID: 4793,
    Code: "14995",
    Name: "Xã Trung Xuân",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4794,
    Code: "14998",
    Name: "Xã Trung Thượng",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4795,
    Code: "14999",
    Name: "Xã Trung Tiến",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4796,
    Code: "15001",
    Name: "Xã Trung Hạ",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4797,
    Code: "15004",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4798,
    Code: "15007",
    Name: "Xã Tam Thanh",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4799,
    Code: "15010",
    Name: "Xã Sơn Thủy",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4800,
    Code: "15013",
    Name: "Xã Na Mèo",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4801,
    Code: "15016",
    Name: "Thị trấn Sơn Lư",
    LevelUp: "Thị trấn",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4802,
    Code: "15019",
    Name: "Xã Tam Lư",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4803,
    Code: "15022",
    Name: "Xã Sơn Điện",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4804,
    Code: "15025",
    Name: "Xã Mường Mìn",
    LevelUp: "Xã",
    DistrictCode: "2805",
    Active: -1,
  },
  {
    ID: 4805,
    Code: "15031",
    Name: "Xã Yên Khương",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4806,
    Code: "15034",
    Name: "Xã Yên Thắng",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4807,
    Code: "15037",
    Name: "Xã Trí Nang",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4808,
    Code: "15040",
    Name: "Xã Giao An",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4809,
    Code: "15043",
    Name: "Xã Giao Thiện",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4810,
    Code: "15046",
    Name: "Xã Tân Phúc",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4811,
    Code: "15049",
    Name: "Xã Tam Văn",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4812,
    Code: "15052",
    Name: "Xã Lâm Phú",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4813,
    Code: "15055",
    Name: "Thị trấn Lang Chánh",
    LevelUp: "Thị trấn",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4814,
    Code: "15058",
    Name: "Xã Đồng Lương",
    LevelUp: "Xã",
    DistrictCode: "2811",
    Active: -1,
  },
  {
    ID: 4815,
    Code: "15061",
    Name: "Thị Trấn Ngọc Lặc",
    LevelUp: "Thị trấn",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4816,
    Code: "15064",
    Name: "Xã Lam Sơn",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4817,
    Code: "15067",
    Name: "Xã Mỹ Tân",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4818,
    Code: "15070",
    Name: "Xã Thúy Sơn",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4819,
    Code: "15073",
    Name: "Xã Thạch Lập",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4820,
    Code: "15076",
    Name: "Xã Vân Âm",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4821,
    Code: "15079",
    Name: "Xã Cao Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4822,
    Code: "15085",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4823,
    Code: "15088",
    Name: "Xã Đồng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4824,
    Code: "15091",
    Name: "Xã Ngọc Liên",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4825,
    Code: "15094",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4826,
    Code: "15097",
    Name: "Xã Lộc Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4827,
    Code: "15100",
    Name: "Xã Cao Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4828,
    Code: "15103",
    Name: "Xã Ngọc Trung",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4829,
    Code: "15106",
    Name: "Xã Phùng Giáo",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4830,
    Code: "15109",
    Name: "Xã Phùng Minh",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4831,
    Code: "15112",
    Name: "Xã Phúc Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4832,
    Code: "15115",
    Name: "Xã Nguyệt Ấn",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4833,
    Code: "15118",
    Name: "Xã Kiên Thọ",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4834,
    Code: "15121",
    Name: "Xã Minh Tiến",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4835,
    Code: "15124",
    Name: "Xã Minh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2812",
    Active: -1,
  },
  {
    ID: 4836,
    Code: "15127",
    Name: "Thị trấn Phong Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4837,
    Code: "15133",
    Name: "Xã Cẩm Thành",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4838,
    Code: "15136",
    Name: "Xã Cẩm Quý",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4839,
    Code: "15139",
    Name: "Xã Cẩm Lương",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4840,
    Code: "15142",
    Name: "Xã Cẩm Thạch",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4841,
    Code: "15145",
    Name: "Xã Cẩm Liên",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4842,
    Code: "15148",
    Name: "Xã Cẩm Giang",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4843,
    Code: "15151",
    Name: "Xã Cẩm Bình",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4844,
    Code: "15154",
    Name: "Xã Cẩm Tú",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4845,
    Code: "15160",
    Name: "Xã Cẩm Châu",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4846,
    Code: "15163",
    Name: "Xã Cẩm Tâm",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4847,
    Code: "15169",
    Name: "Xã Cẩm Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4848,
    Code: "15172",
    Name: "Xã Cẩm Long",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4849,
    Code: "15175",
    Name: "Xã Cẩm Yên",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4850,
    Code: "15178",
    Name: "Xã Cẩm Tân",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4851,
    Code: "15181",
    Name: "Xã Cẩm Phú",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4852,
    Code: "15184",
    Name: "Xã Cẩm Vân",
    LevelUp: "Xã",
    DistrictCode: "2814",
    Active: -1,
  },
  {
    ID: 4853,
    Code: "15187",
    Name: "Thị trấn Kim Tân",
    LevelUp: "Thị trấn",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4854,
    Code: "15190",
    Name: "Thị trấn Vân Du",
    LevelUp: "Thị trấn",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4855,
    Code: "15196",
    Name: "Xã Thạch Lâm",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4856,
    Code: "15199",
    Name: "Xã Thạch Quảng",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4857,
    Code: "15202",
    Name: "Xã Thạch Tượng",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4858,
    Code: "15205",
    Name: "Xã Thạch Cẩm",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4859,
    Code: "15208",
    Name: "Xã Thạch Sơn",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4860,
    Code: "15211",
    Name: "Xã Thạch Bình",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4861,
    Code: "15214",
    Name: "Xã Thạch Định",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4862,
    Code: "15217",
    Name: "Xã Thạch Đồng",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4863,
    Code: "15220",
    Name: "Xã Thạch Long",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4864,
    Code: "15223",
    Name: "Xã Thành Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4865,
    Code: "15226",
    Name: "Xã Thành Yên",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4866,
    Code: "15229",
    Name: "Xã Thành Vinh",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4867,
    Code: "15232",
    Name: "Xã Thành Minh",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4868,
    Code: "15235",
    Name: "Xã Thành Công",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4869,
    Code: "15238",
    Name: "Xã Thành Tân",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4870,
    Code: "15241",
    Name: "Xã Thành Trực",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4871,
    Code: "15247",
    Name: "Xã Thành Tâm",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4872,
    Code: "15250",
    Name: "Xã Thành An",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4873,
    Code: "15253",
    Name: "Xã Thành Thọ",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4874,
    Code: "15256",
    Name: "Xã Thành Tiến",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4875,
    Code: "15259",
    Name: "Xã Thành Long",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4876,
    Code: "15265",
    Name: "Xã Thành Hưng",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4877,
    Code: "15268",
    Name: "Xã Ngọc Trạo",
    LevelUp: "Xã",
    DistrictCode: "2813",
    Active: -1,
  },
  {
    ID: 4878,
    Code: "15271",
    Name: "Thị trấn Hà Trung",
    LevelUp: "Thị trấn",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4879,
    Code: "15274",
    Name: "Xã Hà Long",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4880,
    Code: "15277",
    Name: "Xã Hà Vinh",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4881,
    Code: "15280",
    Name: "Xã Hà Bắc",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4882,
    Code: "15283",
    Name: "Xã Hoạt Giang",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4883,
    Code: "15286",
    Name: "Xã Yên Dương",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4884,
    Code: "15292",
    Name: "Xã Hà Giang",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4885,
    Code: "15298",
    Name: "Xã Lĩnh Toại",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4886,
    Code: "15304",
    Name: "Xã Hà Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4887,
    Code: "15307",
    Name: "Xã Yến Sơn",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4888,
    Code: "15313",
    Name: "Xã Hà Sơn",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4889,
    Code: "15316",
    Name: "Xã Hà Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4890,
    Code: "15319",
    Name: "Xã Hà Đông",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4891,
    Code: "15322",
    Name: "Xã Hà Tân",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4892,
    Code: "15325",
    Name: "Xã Hà Tiến",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4893,
    Code: "15328",
    Name: "Xã Hà Bình",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4894,
    Code: "15331",
    Name: "Xã Hà Lai",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4895,
    Code: "15334",
    Name: "Xã Hà Châu",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4896,
    Code: "15340",
    Name: "Xã Hà Thái",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4897,
    Code: "15343",
    Name: "Xã Hà Hải",
    LevelUp: "Xã",
    DistrictCode: "2821",
    Active: -1,
  },
  {
    ID: 4898,
    Code: "15349",
    Name: "Thị trấn Vĩnh Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4899,
    Code: "15352",
    Name: "Xã Vĩnh Quang",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4900,
    Code: "15355",
    Name: "Xã Vĩnh Yên",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4901,
    Code: "15358",
    Name: "Xã Vĩnh Tiến",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4902,
    Code: "15361",
    Name: "Xã Vĩnh Long",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4903,
    Code: "15364",
    Name: "Xã Vĩnh Phúc",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4904,
    Code: "15367",
    Name: "Xã Vĩnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4905,
    Code: "15376",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4906,
    Code: "15379",
    Name: "Xã Vĩnh Hùng",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4907,
    Code: "15382",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4908,
    Code: "15385",
    Name: "Xã Ninh Khang",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4909,
    Code: "15388",
    Name: "Xã Vĩnh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4910,
    Code: "15391",
    Name: "Xã Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "2816",
    Active: -1,
  },
  {
    ID: 4911,
    Code: "15397",
    Name: "Thị trấn Thống Nhất",
    LevelUp: "Thị trấn",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4912,
    Code: "15403",
    Name: "Xã Yên Lâm",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4913,
    Code: "15406",
    Name: "Xã Yên Tâm",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4914,
    Code: "15409",
    Name: "Xã Yên Phú",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4915,
    Code: "15412",
    Name: "Xã Quí Lộc",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4916,
    Code: "15415",
    Name: "Xã Yên Thọ",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4917,
    Code: "15418",
    Name: "Xã Yên Trung",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4918,
    Code: "15421",
    Name: "Xã Yên Trường",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4919,
    Code: "15427",
    Name: "Xã Yên Phong",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4920,
    Code: "15430",
    Name: "Xã Yên Thái",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4921,
    Code: "15433",
    Name: "Xã Yên Hùng",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4922,
    Code: "15436",
    Name: "Xã Yên Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4923,
    Code: "15439",
    Name: "Xã Yên Ninh",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4924,
    Code: "15442",
    Name: "Xã Yên Lạc",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4925,
    Code: "15445",
    Name: "Xã Định Tăng",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4926,
    Code: "15448",
    Name: "Xã Định Hòa",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4927,
    Code: "15451",
    Name: "Xã Định Thành",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4928,
    Code: "15454",
    Name: "Xã Định Công",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4929,
    Code: "15457",
    Name: "Xã Định Tân",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4930,
    Code: "15460",
    Name: "Xã Định Tiến",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4931,
    Code: "15463",
    Name: "Xã Định Long",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4932,
    Code: "15466",
    Name: "Xã Định Liên",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4933,
    Code: "15469",
    Name: "Thị trấn Quán Lào",
    LevelUp: "Thị trấn",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4934,
    Code: "15472",
    Name: "Xã Định Hưng",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4935,
    Code: "15475",
    Name: "Xã Định Hải",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4936,
    Code: "15478",
    Name: "Xã Định Bình",
    LevelUp: "Xã",
    DistrictCode: "2827",
    Active: -1,
  },
  {
    ID: 4937,
    Code: "15493",
    Name: "Xã Xuân Hồng",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4938,
    Code: "15499",
    Name: "Thị trấn Thọ Xuân",
    LevelUp: "Thị trấn",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4939,
    Code: "15502",
    Name: "Xã Bắc Lương",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4941,
    Code: "15508",
    Name: "Xã Xuân Phong",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4942,
    Code: "15511",
    Name: "Xã Thọ Lộc",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4943,
    Code: "15514",
    Name: "Xã Xuân Trường",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4944,
    Code: "15517",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4945,
    Code: "15520",
    Name: "Xã Thọ Hải",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4946,
    Code: "15523",
    Name: "Xã Tây Hồ",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4947,
    Code: "15526",
    Name: "Xã Xuân Giang",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4948,
    Code: "15532",
    Name: "Xã Xuân Sinh",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4949,
    Code: "15535",
    Name: "Xã Xuân Hưng",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4950,
    Code: "15538",
    Name: "Xã Thọ Diên",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4951,
    Code: "15541",
    Name: "Xã Thọ Lâm",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4952,
    Code: "15544",
    Name: "Xã Thọ Xương",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4953,
    Code: "15547",
    Name: "Xã Xuân Bái",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4954,
    Code: "15550",
    Name: "Xã Xuân Phú",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4955,
    Code: "15553",
    Name: "Thị trấn Sao Vàng",
    LevelUp: "Thị trấn",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4956,
    Code: "15556",
    Name: "Thị trấn Lam Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4957,
    Code: "15559",
    Name: "Xã Xuân Thiên",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4958,
    Code: "15565",
    Name: "Xã Thuận Minh",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4959,
    Code: "15568",
    Name: "Xã Thọ Lập",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4960,
    Code: "15571",
    Name: "Xã Quảng Phú",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4961,
    Code: "15574",
    Name: "Xã Xuân Tín",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4962,
    Code: "15577",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4963,
    Code: "15583",
    Name: "Xã Xuân Lai",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4964,
    Code: "15586",
    Name: "Xã Xuân Lập",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4965,
    Code: "15592",
    Name: "Xã Xuân Minh",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4966,
    Code: "15598",
    Name: "Xã Trường Xuân",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 4967,
    Code: "15607",
    Name: "Xã Bát Mọt",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4968,
    Code: "15610",
    Name: "Xã Yên Nhân",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4969,
    Code: "15619",
    Name: "Xã Xuân Lẹ",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4970,
    Code: "15622",
    Name: "Xã Vạn Xuân",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4971,
    Code: "15628",
    Name: "Xã Lương Sơn",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4972,
    Code: "15631",
    Name: "Xã Xuân Cao",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4973,
    Code: "15634",
    Name: "Xã Luận Thành",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4974,
    Code: "15637",
    Name: "Xã Luận Khê",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4975,
    Code: "15640",
    Name: "Xã Xuân Thắng",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4976,
    Code: "15643",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4977,
    Code: "15646",
    Name: "Thị trấn Thường Xuân",
    LevelUp: "Thị trấn",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4978,
    Code: "15649",
    Name: "Xã Xuân Dương",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4979,
    Code: "15652",
    Name: "Xã Thọ Thanh",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4980,
    Code: "15655",
    Name: "Xã Ngọc Phụng",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4981,
    Code: "15658",
    Name: "Xã Xuân Chinh",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4982,
    Code: "15661",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "2808",
    Active: -1,
  },
  {
    ID: 4983,
    Code: "15664",
    Name: "Thị trấn Triệu Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4984,
    Code: "15667",
    Name: "Xã Thọ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4985,
    Code: "15670",
    Name: "Xã Thọ Bình",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4986,
    Code: "15673",
    Name: "Xã Thọ Tiến",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4987,
    Code: "15676",
    Name: "Xã Hợp Lý",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4988,
    Code: "15679",
    Name: "Xã Hợp Tiến",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4989,
    Code: "15682",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4990,
    Code: "15685",
    Name: "Xã Triệu Thành",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4991,
    Code: "15688",
    Name: "Xã Hợp Thắng",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4992,
    Code: "15691",
    Name: "Xã Minh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4993,
    Code: "15700",
    Name: "Xã Dân Lực",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4994,
    Code: "15703",
    Name: "Xã Dân Lý",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4995,
    Code: "15706",
    Name: "Xã Dân Quyền",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4996,
    Code: "15709",
    Name: "Xã An Nông",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4997,
    Code: "15712",
    Name: "Xã Văn Sơn",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4998,
    Code: "15715",
    Name: "Xã Thái Hòa",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 4999,
    Code: "15718",
    Name: "Thị trấn Nưa",
    LevelUp: "Thị trấn",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5000,
    Code: "15721",
    Name: "Xã Đồng Lợi",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5001,
    Code: "15724",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5002,
    Code: "15727",
    Name: "Xã Đồng Thắng",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5003,
    Code: "15730",
    Name: "Xã Tiến Nông",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5004,
    Code: "15733",
    Name: "Xã Khuyến Nông",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5005,
    Code: "15736",
    Name: "Xã Xuân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5006,
    Code: "15739",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5007,
    Code: "15742",
    Name: "Xã Thọ Dân",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5008,
    Code: "15745",
    Name: "Xã Xuân Thọ",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5009,
    Code: "15748",
    Name: "Xã Thọ Tân",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5010,
    Code: "15751",
    Name: "Xã Thọ Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5011,
    Code: "15754",
    Name: "Xã Thọ Cường",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5012,
    Code: "15757",
    Name: "Xã Thọ Phú",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5013,
    Code: "15760",
    Name: "Xã Thọ Vực",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5014,
    Code: "15763",
    Name: "Xã Thọ Thế",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5015,
    Code: "15766",
    Name: "Xã Nông Trường",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5016,
    Code: "15769",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 5017,
    Code: "15772",
    Name: "Thị trấn Thiệu Hóa",
    LevelUp: "Thị trấn",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5018,
    Code: "15775",
    Name: "Xã Thiệu Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5019,
    Code: "15778",
    Name: "Xã Thiệu Vũ",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5020,
    Code: "15781",
    Name: "Xã Thiệu Phúc",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5021,
    Code: "15784",
    Name: "Xã Thiệu Tiến",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5022,
    Code: "15787",
    Name: "Xã Thiệu Công",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5023,
    Code: "15790",
    Name: "Xã Thiệu Phú",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5024,
    Code: "15793",
    Name: "Xã Thiệu Long",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5025,
    Code: "15796",
    Name: "Xã Thiệu Giang",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5026,
    Code: "15799",
    Name: "Xã Thiệu Duy",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5027,
    Code: "15802",
    Name: "Xã Thiệu Nguyên",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5028,
    Code: "15805",
    Name: "Xã Thiệu Hợp",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5029,
    Code: "15808",
    Name: "Xã Thiệu Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5030,
    Code: "15811",
    Name: "Xã Thiệu Quang",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5031,
    Code: "15814",
    Name: "Xã Thiệu Thành",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5032,
    Code: "15817",
    Name: "Xã Thiệu Toán",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5033,
    Code: "15820",
    Name: "Xã Thiệu Chính",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5034,
    Code: "15823",
    Name: "Xã Thiệu Hòa",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5035,
    Code: "15829",
    Name: "Xã Minh Tâm",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5036,
    Code: "15832",
    Name: "Xã Thiệu Viên",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5037,
    Code: "15835",
    Name: "Xã Thiệu Lý",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5038,
    Code: "15838",
    Name: "Xã Thiệu Vận",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5039,
    Code: "15841",
    Name: "Xã Thiệu Trung",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5040,
    Code: "15847",
    Name: "Xã Tân Châu",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5041,
    Code: "15853",
    Name: "Xã Thiệu Giao",
    LevelUp: "Xã",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 5042,
    Code: "15865",
    Name: "Thị trấn Bút Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5043,
    Code: "15871",
    Name: "Xã Hoằng Giang",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5044,
    Code: "15877",
    Name: "Xã Hoằng Xuân",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5045,
    Code: "15880",
    Name: "Xã Hoằng Phượng",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5046,
    Code: "15883",
    Name: "Xã Hoằng Phú",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5047,
    Code: "15886",
    Name: "Xã Hoằng Quỳ",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5048,
    Code: "15889",
    Name: "Xã Hoằng Kim",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5049,
    Code: "15892",
    Name: "Xã Hoằng Trung",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5050,
    Code: "15895",
    Name: "Xã Hoằng Trinh",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5051,
    Code: "15901",
    Name: "Xã Hoằng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5052,
    Code: "15907",
    Name: "Xã Hoằng Cát",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5053,
    Code: "15910",
    Name: "Xã Hoằng Xuyên",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5054,
    Code: "15916",
    Name: "Xã Hoằng Quý",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5055,
    Code: "15919",
    Name: "Xã Hoằng Hợp",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5056,
    Code: "15928",
    Name: "Xã Hoằng Đức",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5057,
    Code: "15937",
    Name: "Xã Hoằng Hà",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5058,
    Code: "15940",
    Name: "Xã Hoằng Đạt",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5059,
    Code: "15946",
    Name: "Xã Hoằng Đạo",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5060,
    Code: "15949",
    Name: "Xã Hoằng Thắng",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5061,
    Code: "15952",
    Name: "Xã Hoằng Đồng",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5062,
    Code: "15955",
    Name: "Xã Hoằng Thái",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5063,
    Code: "15958",
    Name: "Xã Hoằng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5064,
    Code: "15961",
    Name: "Xã Hoằng Thành",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5065,
    Code: "15964",
    Name: "Xã Hoằng Lộc",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5066,
    Code: "15967",
    Name: "Xã Hoằng Trạch",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5067,
    Code: "15973",
    Name: "Xã Hoằng Phong",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5068,
    Code: "15976",
    Name: "Xã Hoằng Lưu",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5069,
    Code: "15979",
    Name: "Xã Hoằng Châu",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5070,
    Code: "15982",
    Name: "Xã Hoằng Tân",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5071,
    Code: "15985",
    Name: "Xã Hoằng Yến",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5072,
    Code: "15988",
    Name: "Xã Hoằng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5073,
    Code: "15991",
    Name: "Xã Hoằng Hải",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5074,
    Code: "15994",
    Name: "Xã Hoằng Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5075,
    Code: "15997",
    Name: "Xã Hoằng Đông",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5076,
    Code: "16000",
    Name: "Xã Hoằng Thanh",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5077,
    Code: "16003",
    Name: "Xã Hoằng Phụ",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5078,
    Code: "16006",
    Name: "Xã Hoằng Trường",
    LevelUp: "Xã",
    DistrictCode: "2822",
    Active: -1,
  },
  {
    ID: 5079,
    Code: "16012",
    Name: "Thị trấn Hậu Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5080,
    Code: "16015",
    Name: "Xã Đồng Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5081,
    Code: "16018",
    Name: "Xã Đại Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5082,
    Code: "16021",
    Name: "Xã Triệu Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5083,
    Code: "16027",
    Name: "Xã Tiến Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5084,
    Code: "16030",
    Name: "Xã Lộc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5085,
    Code: "16033",
    Name: "Xã Cầu Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5086,
    Code: "16036",
    Name: "Xã Thành Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5087,
    Code: "16039",
    Name: "Xã Tuy Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5088,
    Code: "16042",
    Name: "Xã Phong Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5089,
    Code: "16045",
    Name: "Xã Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5090,
    Code: "16048",
    Name: "Xã Thuần Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5091,
    Code: "16057",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5092,
    Code: "16063",
    Name: "Xã Hoa Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5093,
    Code: "16066",
    Name: "Xã Liên Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5094,
    Code: "16069",
    Name: "Xã Quang Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5095,
    Code: "16072",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5096,
    Code: "16075",
    Name: "Xã Hòa Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5097,
    Code: "16078",
    Name: "Xã Minh Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5098,
    Code: "16081",
    Name: "Xã Hưng Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5099,
    Code: "16084",
    Name: "Xã Hải Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5100,
    Code: "16087",
    Name: "Xã Đa Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5101,
    Code: "16090",
    Name: "Xã Ngư Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 5102,
    Code: "16093",
    Name: "Thị trấn Nga Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5103,
    Code: "16096",
    Name: "Xã Ba Đình",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5104,
    Code: "16099",
    Name: "Xã Nga Vịnh",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5105,
    Code: "16102",
    Name: "Xã Nga Văn",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5106,
    Code: "16105",
    Name: "Xã Nga Thiện",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5107,
    Code: "16108",
    Name: "Xã Nga Tiến",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5108,
    Code: "16114",
    Name: "Xã Nga Phượng",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5109,
    Code: "16117",
    Name: "Xã Nga Trung",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5110,
    Code: "16120",
    Name: "Xã Nga Bạch",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5111,
    Code: "16123",
    Name: "Xã Nga Thanh",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5112,
    Code: "16132",
    Name: "Xã Nga Yên",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5113,
    Code: "16135",
    Name: "Xã Nga Giáp",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5114,
    Code: "16138",
    Name: "Xã Nga Hải",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5115,
    Code: "16141",
    Name: "Xã Nga Thành",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5116,
    Code: "16144",
    Name: "Xã Nga An",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5117,
    Code: "16147",
    Name: "Xã Nga Phú",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5118,
    Code: "16150",
    Name: "Xã Nga Điền",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5119,
    Code: "16153",
    Name: "Xã Nga Tân",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5120,
    Code: "16156",
    Name: "Xã Nga Thủy",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5121,
    Code: "16159",
    Name: "Xã Nga Liên",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5122,
    Code: "16162",
    Name: "Xã Nga Thái",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5123,
    Code: "16165",
    Name: "Xã Nga Thạch",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5124,
    Code: "16168",
    Name: "Xã Nga Thắng",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5125,
    Code: "16171",
    Name: "Xã Nga Trường",
    LevelUp: "Xã",
    DistrictCode: "2823",
    Active: -1,
  },
  {
    ID: 5126,
    Code: "16174",
    Name: "Thị trấn Yên Cát",
    LevelUp: "Thị trấn",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5127,
    Code: "16177",
    Name: "Xã Bãi Trành",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5128,
    Code: "16180",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5129,
    Code: "16183",
    Name: "Xã Xuân Bình",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5130,
    Code: "16186",
    Name: "Xã Hóa Quỳ",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5131,
    Code: "16195",
    Name: "Xã Cát Vân",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5132,
    Code: "16198",
    Name: "Xã Cát Tân",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5133,
    Code: "16201",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5134,
    Code: "16204",
    Name: "Xã Bình Lương",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5135,
    Code: "16207",
    Name: "Xã Thanh Quân",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5136,
    Code: "16210",
    Name: "Xã Thanh Xuân",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5137,
    Code: "16213",
    Name: "Xã Thanh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5138,
    Code: "16216",
    Name: "Xã Thanh Phong",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5139,
    Code: "16219",
    Name: "Xã Thanh Lâm",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5140,
    Code: "16222",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5141,
    Code: "16225",
    Name: "Xã Thượng Ninh",
    LevelUp: "Xã",
    DistrictCode: "2809",
    Active: -1,
  },
  {
    ID: 5142,
    Code: "16228",
    Name: "Thị trấn Bến Sung",
    LevelUp: "Thị trấn",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5143,
    Code: "16231",
    Name: "Xã Cán Khê",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5144,
    Code: "16234",
    Name: "Xã Xuân Du",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5145,
    Code: "16240",
    Name: "Xã Phượng Nghi",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5146,
    Code: "16243",
    Name: "Xã Mậu Lâm",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5147,
    Code: "16246",
    Name: "Xã Xuân Khang",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5148,
    Code: "16249",
    Name: "Xã Phú Nhuận",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5149,
    Code: "16252",
    Name: "Xã Hải Long",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5150,
    Code: "16258",
    Name: "Xã Xuân Thái",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5151,
    Code: "16261",
    Name: "Xã Xuân Phúc",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5152,
    Code: "16264",
    Name: "Xã Yên Thọ",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5153,
    Code: "16267",
    Name: "Xã Yên Lạc",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5154,
    Code: "16273",
    Name: "Xã Thanh Tân",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5155,
    Code: "16276",
    Name: "Xã Thanh Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2810",
    Active: -1,
  },
  {
    ID: 5156,
    Code: "16279",
    Name: "Thị trấn Nông Cống",
    LevelUp: "Thị trấn",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5157,
    Code: "16282",
    Name: "Xã Tân Phúc",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5158,
    Code: "16285",
    Name: "Xã Tân Thọ",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5159,
    Code: "16288",
    Name: "Xã Hoàng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5160,
    Code: "16291",
    Name: "Xã Tân Khang",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5161,
    Code: "16294",
    Name: "Xã Hoàng Giang",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5162,
    Code: "16297",
    Name: "Xã Trung Chính",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5163,
    Code: "16303",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5164,
    Code: "16309",
    Name: "Xã Tế Thắng",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5165,
    Code: "16315",
    Name: "Xã Tế Lợi",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5166,
    Code: "16318",
    Name: "Xã Tế Nông",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5167,
    Code: "16321",
    Name: "Xã Minh Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5168,
    Code: "16324",
    Name: "Xã Minh Khôi",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5169,
    Code: "16327",
    Name: "Xã Vạn Hòa",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5170,
    Code: "16330",
    Name: "Xã Trường Trung",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5171,
    Code: "16333",
    Name: "Xã Vạn Thắng",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5172,
    Code: "16336",
    Name: "Xã Trường Giang",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5173,
    Code: "16339",
    Name: "Xã Vạn Thiện",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5174,
    Code: "16342",
    Name: "Xã Thăng Long",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5175,
    Code: "16345",
    Name: "Xã Trường Minh",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5176,
    Code: "16348",
    Name: "Xã Trường Sơn",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5177,
    Code: "16351",
    Name: "Xã Thăng Bình",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5178,
    Code: "16354",
    Name: "Xã Công Liêm",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5179,
    Code: "16357",
    Name: "Xã Tượng Văn",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5180,
    Code: "16360",
    Name: "Xã Thăng Thọ",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5181,
    Code: "16363",
    Name: "Xã Tượng Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5182,
    Code: "16366",
    Name: "Xã Tượng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5183,
    Code: "16369",
    Name: "Xã Công Chính",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5184,
    Code: "16375",
    Name: "Xã Yên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2819",
    Active: -1,
  },
  {
    ID: 5185,
    Code: "16378",
    Name: "Thị trấn Rừng Thông",
    LevelUp: "Thị trấn",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5186,
    Code: "16381",
    Name: "Xã Đông Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5187,
    Code: "16384",
    Name: "Xã Đông Ninh",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5188,
    Code: "16390",
    Name: "Xã Đông Hòa",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5189,
    Code: "16393",
    Name: "Xã Đông Yên",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5190,
    Code: "16399",
    Name: "Xã Đông Minh",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5191,
    Code: "16402",
    Name: "Xã Đông Thanh",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5192,
    Code: "16405",
    Name: "Xã Đông Tiến",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5193,
    Code: "16408",
    Name: "Xã Đông Khê",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5194,
    Code: "16414",
    Name: "Xã Đông Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5195,
    Code: "16417",
    Name: "Xã Đông Văn",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5196,
    Code: "16420",
    Name: "Xã Đông Phú",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5197,
    Code: "16423",
    Name: "Xã Đông Nam",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5198,
    Code: "16426",
    Name: "Xã Đông Quang",
    LevelUp: "Xã",
    DistrictCode: "2820",
    Active: -1,
  },
  {
    ID: 5199,
    Code: "16438",
    Name: "Thị trấn Tân Phong",
    LevelUp: "Thị trấn",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5200,
    Code: "16447",
    Name: "Xã Quảng Trạch",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5201,
    Code: "16453",
    Name: "Xã Quảng Đức",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5202,
    Code: "16456",
    Name: "Xã Quảng Định",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5203,
    Code: "16462",
    Name: "Xã Quảng Nhân",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5204,
    Code: "16465",
    Name: "Xã Quảng Ninh",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5205,
    Code: "16468",
    Name: "Xã Quảng Bình",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5206,
    Code: "16471",
    Name: "Xã Quảng Hợp",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5207,
    Code: "16474",
    Name: "Xã Quảng Văn",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5208,
    Code: "16477",
    Name: "Xã Quảng Long",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5209,
    Code: "16480",
    Name: "Xã Quảng Yên",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5210,
    Code: "16483",
    Name: "Xã Quảng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5211,
    Code: "16489",
    Name: "Xã Quảng Khê",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5212,
    Code: "16492",
    Name: "Xã Quảng Trung",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5213,
    Code: "16495",
    Name: "Xã Quảng Chính",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5214,
    Code: "16498",
    Name: "Xã Quảng Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5215,
    Code: "16501",
    Name: "Xã Quảng Trường",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5216,
    Code: "16510",
    Name: "Xã Quảng Phúc",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5217,
    Code: "16519",
    Name: "Xã Quảng Giao",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5218,
    Code: "16540",
    Name: "Xã Quảng Hải",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5219,
    Code: "16543",
    Name: "Xã Quảng Lưu",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5220,
    Code: "16546",
    Name: "Xã Quảng Lộc",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5221,
    Code: "16549",
    Name: "Xã Tiên Trang",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5222,
    Code: "16552",
    Name: "Xã Quảng Nham",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5223,
    Code: "16555",
    Name: "Xã Quảng Thạch",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5224,
    Code: "16558",
    Name: "Xã Quảng Thái",
    LevelUp: "Xã",
    DistrictCode: "2825",
    Active: -1,
  },
  {
    ID: 5225,
    Code: "16561",
    Name: "Phường Hải Hòa",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5226,
    Code: "16564",
    Name: "Phường Hải Châu",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5227,
    Code: "16567",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5228,
    Code: "16570",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5229,
    Code: "16576",
    Name: "Phường Hải Ninh",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5230,
    Code: "16579",
    Name: "Xã Anh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5231,
    Code: "16582",
    Name: "Xã Ngọc Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5232,
    Code: "16585",
    Name: "Phường Hải An",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5233,
    Code: "16591",
    Name: "Xã Các Sơn",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5234,
    Code: "16594",
    Name: "Phường Tân Dân",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5235,
    Code: "16597",
    Name: "Phường Hải Lĩnh",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5236,
    Code: "16600",
    Name: "Xã Định Hải",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5237,
    Code: "16603",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5238,
    Code: "16606",
    Name: "Phường Ninh Hải",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5239,
    Code: "16609",
    Name: "Phường Nguyên Bình",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5240,
    Code: "16612",
    Name: "Xã Hải Nhân",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5241,
    Code: "16618",
    Name: "Phường Bình Minh",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5242,
    Code: "16621",
    Name: "Phường Hải Thanh",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5243,
    Code: "16624",
    Name: "Xã Phú Lâm",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5244,
    Code: "16627",
    Name: "Phường Xuân Lâm",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5245,
    Code: "16630",
    Name: "Phường Trúc Lâm",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5246,
    Code: "16633",
    Name: "Phường Hải Bình",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5247,
    Code: "16636",
    Name: "Xã Tân Trường",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5248,
    Code: "16639",
    Name: "Xã Tùng Lâm",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5249,
    Code: "16642",
    Name: "Phường Tĩnh Hải",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5250,
    Code: "16645",
    Name: "Phường Mai Lâm",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5251,
    Code: "16648",
    Name: "Xã Trường Lâm",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5252,
    Code: "16651",
    Name: "Xã Hải Yến",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5253,
    Code: "16654",
    Name: "Phường Hải Thượng",
    LevelUp: "Phường",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5254,
    Code: "16657",
    Name: "Xã Nghi Sơn",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5255,
    Code: "16660",
    Name: "Xã Hải Hà",
    LevelUp: "Xã",
    DistrictCode: "2828",
    Active: -1,
  },
  {
    ID: 5256,
    Code: "16663",
    Name: "Phường Đông Vĩnh",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5257,
    Code: "16666",
    Name: "Phường Hà Huy Tập",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5258,
    Code: "16669",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5259,
    Code: "16670",
    Name: "Phường Quán Bàu",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5260,
    Code: "16672",
    Name: "Phường Hưng Bình",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5261,
    Code: "16673",
    Name: "Phường Hưng Phúc",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5262,
    Code: "16675",
    Name: "Phường Hưng Dũng",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5263,
    Code: "16678",
    Name: "Phường Cửa Nam",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5264,
    Code: "16681",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5265,
    Code: "16684",
    Name: "Phường Đội Cung",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5266,
    Code: "16687",
    Name: "Phường Lê Mao",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5267,
    Code: "16690",
    Name: "Phường Trường Thi",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5268,
    Code: "16693",
    Name: "Phường Bến Thủy",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5269,
    Code: "16696",
    Name: "Phường Hồng Sơn",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5270,
    Code: "16699",
    Name: "Phường Trung Đô",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5271,
    Code: "16702",
    Name: "Xã Nghi Phú",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5272,
    Code: "16705",
    Name: "Xã Hưng Đông",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5273,
    Code: "16708",
    Name: "Xã Hưng Lộc",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5274,
    Code: "16711",
    Name: "Xã Hưng Hòa",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5275,
    Code: "16714",
    Name: "Phường Vinh Tân",
    LevelUp: "Phường",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5276,
    Code: "17908",
    Name: "Xã Nghi Liên",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5277,
    Code: "17914",
    Name: "Xã Nghi Ân",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5278,
    Code: "17920",
    Name: "Xã Nghi Kim",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5279,
    Code: "17923",
    Name: "Xã Nghi Đức",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5280,
    Code: "18013",
    Name: "Xã Hưng Chính",
    LevelUp: "Xã",
    DistrictCode: "2901",
    Active: -1,
  },
  {
    ID: 5281,
    Code: "16717",
    Name: "Phường Nghi Thuỷ",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5282,
    Code: "16720",
    Name: "Phường Nghi Tân",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5283,
    Code: "16723",
    Name: "Phường Thu Thuỷ",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5284,
    Code: "16726",
    Name: "Phường Nghi Hòa",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5285,
    Code: "16729",
    Name: "Phường Nghi Hải",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5286,
    Code: "16732",
    Name: "Phường Nghi Hương",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5287,
    Code: "16735",
    Name: "Phường Nghi Thu",
    LevelUp: "Phường",
    DistrictCode: "2902",
    Active: -1,
  },
  {
    ID: 5288,
    Code: "16939",
    Name: "Phường Hoà Hiếu",
    LevelUp: "Phường",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5289,
    Code: "16993",
    Name: "Phường Quang Phong",
    LevelUp: "Phường",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5290,
    Code: "16994",
    Name: "Phường Quang Tiến",
    LevelUp: "Phường",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5291,
    Code: "17003",
    Name: "Phường Long Sơn",
    LevelUp: "Phường",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5292,
    Code: "17005",
    Name: "Xã Nghĩa Tiến",
    LevelUp: "Xã",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5293,
    Code: "17008",
    Name: "Xã Nghĩa Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5294,
    Code: "17011",
    Name: "Xã Tây Hiếu",
    LevelUp: "Xã",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5295,
    Code: "17014",
    Name: "Xã Nghĩa Thuận",
    LevelUp: "Xã",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5296,
    Code: "17017",
    Name: "Xã Đông Hiếu",
    LevelUp: "Xã",
    DistrictCode: "2920",
    Active: -1,
  },
  {
    ID: 5297,
    Code: "16738",
    Name: "Thị trấn Kim Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5298,
    Code: "16741",
    Name: "Xã Thông Thụ",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5299,
    Code: "16744",
    Name: "Xã Đồng Văn",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5300,
    Code: "16747",
    Name: "Xã Hạnh Dịch",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5301,
    Code: "16750",
    Name: "Xã Tiền Phong",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5302,
    Code: "16753",
    Name: "Xã Nậm Giải",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5303,
    Code: "16756",
    Name: "Xã Tri Lễ",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5304,
    Code: "16759",
    Name: "Xã Châu Kim",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5305,
    Code: "16763",
    Name: "Xã Mường Nọc",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5306,
    Code: "16765",
    Name: "Xã Châu Thôn",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5307,
    Code: "16768",
    Name: "Xã Nậm Nhoóng",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5308,
    Code: "16771",
    Name: "Xã Quang Phong",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5309,
    Code: "16774",
    Name: "Xã Căm Muộn",
    LevelUp: "Xã",
    DistrictCode: "2919",
    Active: -1,
  },
  {
    ID: 5310,
    Code: "16777",
    Name: "Thị trấn Tân Lạc",
    LevelUp: "Thị trấn",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5311,
    Code: "16780",
    Name: "Xã Châu Bính",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5312,
    Code: "16783",
    Name: "Xã Châu Thuận",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5313,
    Code: "16786",
    Name: "Xã Châu Hội",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5314,
    Code: "16789",
    Name: "Xã Châu Nga",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5315,
    Code: "16792",
    Name: "Xã Châu Tiến",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5316,
    Code: "16795",
    Name: "Xã Châu Hạnh",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5317,
    Code: "16798",
    Name: "Xã Châu Thắng",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5318,
    Code: "16801",
    Name: "Xã Châu Phong",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5319,
    Code: "16804",
    Name: "Xã Châu Bình",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5320,
    Code: "16807",
    Name: "Xã Châu Hoàn",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5321,
    Code: "16810",
    Name: "Xã Diên Lãm",
    LevelUp: "Xã",
    DistrictCode: "2903",
    Active: -1,
  },
  {
    ID: 5322,
    Code: "16813",
    Name: "Thị trấn Mường Xén",
    LevelUp: "Thị trấn",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5323,
    Code: "16816",
    Name: "Xã Mỹ Lý",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5324,
    Code: "16819",
    Name: "Xã Bắc Lý",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5325,
    Code: "16822",
    Name: "Xã Keng Đu",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5326,
    Code: "16825",
    Name: "Xã Đoọc Mạy",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5327,
    Code: "16828",
    Name: "Xã Huồi Tụ",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5328,
    Code: "16831",
    Name: "Xã Mường Lống",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5329,
    Code: "16834",
    Name: "Xã Na Loi",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5330,
    Code: "16837",
    Name: "Xã Nậm Cắn",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5331,
    Code: "16840",
    Name: "Xã Bảo Nam",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5332,
    Code: "16843",
    Name: "Xã Phà Đánh",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5333,
    Code: "16846",
    Name: "Xã Bảo Thắng",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5334,
    Code: "16849",
    Name: "Xã Hữu Lập",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5335,
    Code: "16852",
    Name: "Xã Tà Cạ",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5336,
    Code: "16855",
    Name: "Xã Chiêu Lưu",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5337,
    Code: "16858",
    Name: "Xã Mường Típ",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5338,
    Code: "16861",
    Name: "Xã Hữu Kiệm",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5339,
    Code: "16864",
    Name: "Xã Tây Sơn",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5340,
    Code: "16867",
    Name: "Xã Mường Ải",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5341,
    Code: "16870",
    Name: "Xã Na Ngoi",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5342,
    Code: "16873",
    Name: "Xã Nậm Càn",
    LevelUp: "Xã",
    DistrictCode: "2306",
    Active: -1,
  },
  {
    ID: 5343,
    Code: "16876",
    Name: "Thị trấn Thạch Giám",
    LevelUp: "Thị trấn",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5344,
    Code: "16879",
    Name: "Xã Mai Sơn",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5345,
    Code: "16882",
    Name: "Xã Nhôn Mai",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5346,
    Code: "16885",
    Name: "Xã Hữu Khuông",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5347,
    Code: "16900",
    Name: "Xã Yên Tĩnh",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5348,
    Code: "16903",
    Name: "Xã Nga My",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5349,
    Code: "16904",
    Name: "Xã Xiêng My",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5350,
    Code: "16906",
    Name: "Xã Lưỡng Minh",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5351,
    Code: "16909",
    Name: "Xã Yên Hòa",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5352,
    Code: "16912",
    Name: "Xã Yên Na",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5353,
    Code: "16915",
    Name: "Xã Lưu Kiền",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5354,
    Code: "16921",
    Name: "Xã Xá Lượng",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5355,
    Code: "16924",
    Name: "Xã Tam Thái",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5356,
    Code: "16927",
    Name: "Xã Tam Đình",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5357,
    Code: "16930",
    Name: "Xã Yên Thắng",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5358,
    Code: "16933",
    Name: "Xã Tam Quang",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5359,
    Code: "16936",
    Name: "Xã Tam Hợp",
    LevelUp: "Xã",
    DistrictCode: "2908",
    Active: -1,
  },
  {
    ID: 5360,
    Code: "16941",
    Name: "Thị trấn Nghĩa Đàn",
    LevelUp: "Thị trấn",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5361,
    Code: "16942",
    Name: "Xã Nghĩa Mai",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5362,
    Code: "16945",
    Name: "Xã Nghĩa Yên",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5363,
    Code: "16948",
    Name: "Xã Nghĩa Lạc",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5364,
    Code: "16951",
    Name: "Xã Nghĩa Lâm",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5365,
    Code: "16954",
    Name: "Xã Nghĩa Sơn",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5366,
    Code: "16957",
    Name: "Xã Nghĩa Lợi",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5367,
    Code: "16960",
    Name: "Xã Nghĩa Bình",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5368,
    Code: "16963",
    Name: "Xã Nghĩa Thọ",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5369,
    Code: "16966",
    Name: "Xã Nghĩa Minh",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5370,
    Code: "16969",
    Name: "Xã Nghĩa Phú",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5371,
    Code: "16972",
    Name: "Xã Nghĩa Hưng",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5372,
    Code: "16975",
    Name: "Xã Nghĩa Hồng",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5373,
    Code: "16978",
    Name: "Xã Nghĩa Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5374,
    Code: "16981",
    Name: "Xã Nghĩa Trung",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5375,
    Code: "16984",
    Name: "Xã Nghĩa Hội",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5376,
    Code: "16987",
    Name: "Xã Nghĩa Thành",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5377,
    Code: "16996",
    Name: "Xã Nghĩa Hiếu",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5378,
    Code: "17020",
    Name: "Xã Nghĩa Đức",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5379,
    Code: "17023",
    Name: "Xã Nghĩa An",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5380,
    Code: "17026",
    Name: "Xã Nghĩa Long",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5381,
    Code: "17029",
    Name: "Xã Nghĩa Lộc",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5382,
    Code: "17032",
    Name: "Xã Nghĩa Khánh",
    LevelUp: "Xã",
    DistrictCode: "2905",
    Active: -1,
  },
  {
    ID: 5383,
    Code: "17035",
    Name: "Thị trấn Quỳ Hợp",
    LevelUp: "Thị trấn",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5384,
    Code: "17038",
    Name: "Xã Yên Hợp",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5385,
    Code: "17041",
    Name: "Xã Châu Tiến",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5386,
    Code: "17044",
    Name: "Xã Châu Hồng",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5387,
    Code: "17047",
    Name: "Xã Đồng Hợp",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5388,
    Code: "17050",
    Name: "Xã Châu Thành",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5389,
    Code: "17053",
    Name: "Xã Liên Hợp",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5390,
    Code: "17056",
    Name: "Xã Châu Lộc",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5391,
    Code: "17059",
    Name: "Xã Tam Hợp",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5392,
    Code: "17062",
    Name: "Xã Châu Cường",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5393,
    Code: "17065",
    Name: "Xã Châu Quang",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5394,
    Code: "17068",
    Name: "Xã Thọ Hợp",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5395,
    Code: "17071",
    Name: "Xã Minh Hợp",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5396,
    Code: "17074",
    Name: "Xã Nghĩa Xuân",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5397,
    Code: "17077",
    Name: "Xã Châu Thái",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5398,
    Code: "17080",
    Name: "Xã Châu Đình",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5399,
    Code: "17083",
    Name: "Xã Văn Lợi",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5400,
    Code: "17086",
    Name: "Xã Nam Sơn",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5401,
    Code: "17089",
    Name: "Xã Châu Lý",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5402,
    Code: "17092",
    Name: "Xã Hạ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5403,
    Code: "17095",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2904",
    Active: -1,
  },
  {
    ID: 5404,
    Code: "17098",
    Name: "Thị trấn Cầu Giát",
    LevelUp: "Thị trấn",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5405,
    Code: "17101",
    Name: "Xã Quỳnh Thắng",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5406,
    Code: "17119",
    Name: "Xã Quỳnh Tân",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5407,
    Code: "17122",
    Name: "Xã Quỳnh Châu",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5408,
    Code: "17140",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5409,
    Code: "17143",
    Name: "Xã Quỳnh Văn",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5410,
    Code: "17146",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5411,
    Code: "17149",
    Name: "Xã Quỳnh Tam",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5412,
    Code: "17152",
    Name: "Xã Quỳnh Hoa",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5413,
    Code: "17155",
    Name: "Xã Quỳnh Thạch",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5414,
    Code: "17158",
    Name: "Xã Quỳnh Bảng",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5415,
    Code: "17161",
    Name: "Xã Quỳnh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5416,
    Code: "17164",
    Name: "Xã Quỳnh Thanh",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5417,
    Code: "17167",
    Name: "Xã Quỳnh Hậu",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5418,
    Code: "17170",
    Name: "Xã Quỳnh Lâm",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5419,
    Code: "17173",
    Name: "Xã Quỳnh Đôi",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5420,
    Code: "17176",
    Name: "Xã Quỳnh Lương",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5421,
    Code: "17179",
    Name: "Xã Quỳnh Hồng",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5422,
    Code: "17182",
    Name: "Xã Quỳnh Yên",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5423,
    Code: "17185",
    Name: "Xã Quỳnh Bá",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5424,
    Code: "17188",
    Name: "Xã Quỳnh Minh",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5425,
    Code: "17191",
    Name: "Xã Quỳnh Diễn",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5426,
    Code: "17194",
    Name: "Xã Quỳnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5427,
    Code: "17197",
    Name: "Xã Quỳnh Giang",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5428,
    Code: "17200",
    Name: "Xã Quỳnh Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5429,
    Code: "17203",
    Name: "Xã Quỳnh Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5430,
    Code: "17206",
    Name: "Xã An Hòa",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5431,
    Code: "17209",
    Name: "Xã Tiến Thủy",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5432,
    Code: "17212",
    Name: "Xã Sơn Hải",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5433,
    Code: "17215",
    Name: "Xã Quỳnh Thọ",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5434,
    Code: "17218",
    Name: "Xã Quỳnh Thuận",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5435,
    Code: "17221",
    Name: "Xã Quỳnh Long",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5436,
    Code: "17224",
    Name: "Xã Tân Thắng",
    LevelUp: "Xã",
    DistrictCode: "2906",
    Active: -1,
  },
  {
    ID: 5437,
    Code: "17227",
    Name: "Thị trấn Con Cuông",
    LevelUp: "Thị trấn",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5438,
    Code: "17230",
    Name: "Xã Bình Chuẩn",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5439,
    Code: "17233",
    Name: "Xã Lạng Khê",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5440,
    Code: "17236",
    Name: "Xã Cam Lâm",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5441,
    Code: "17239",
    Name: "Xã Thạch Ngàn",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5442,
    Code: "17242",
    Name: "Xã Đôn Phục",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5443,
    Code: "17245",
    Name: "Xã Mậu Đức",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5444,
    Code: "17248",
    Name: "Xã Châu Khê",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5445,
    Code: "17251",
    Name: "Xã Chi Khê",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5446,
    Code: "17254",
    Name: "Xã Bồng Khê",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5447,
    Code: "17257",
    Name: "Xã Yên Khê",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5448,
    Code: "17260",
    Name: "Xã Lục Dạ",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5449,
    Code: "17263",
    Name: "Xã Môn Sơn",
    LevelUp: "Xã",
    DistrictCode: "2909",
    Active: -1,
  },
  {
    ID: 5450,
    Code: "17266",
    Name: "Thị trấn Tân Kỳ",
    LevelUp: "Thị trấn",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5451,
    Code: "17269",
    Name: "Xã Tân Hợp",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5452,
    Code: "17272",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5453,
    Code: "17275",
    Name: "Xã Tân Xuân",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5454,
    Code: "17278",
    Name: "Xã Giai Xuân",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5455,
    Code: "17281",
    Name: "Xã Nghĩa Bình",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5456,
    Code: "17284",
    Name: "Xã Nghĩa Đồng",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5457,
    Code: "17287",
    Name: "Xã Đồng Văn",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5458,
    Code: "17290",
    Name: "Xã Nghĩa Thái",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5459,
    Code: "17293",
    Name: "Xã Nghĩa Hợp",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5460,
    Code: "17296",
    Name: "Xã Nghĩa Hoàn",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5461,
    Code: "17299",
    Name: "Xã Nghĩa Phúc",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5462,
    Code: "17302",
    Name: "Xã Tiên Kỳ",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5463,
    Code: "17305",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5464,
    Code: "17308",
    Name: "Xã Nghĩa Dũng",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5465,
    Code: "17311",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5466,
    Code: "17314",
    Name: "Xã Kỳ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5467,
    Code: "17317",
    Name: "Xã Hương Sơn",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5468,
    Code: "17320",
    Name: "Xã Kỳ Tân",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5469,
    Code: "17323",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5470,
    Code: "17325",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5471,
    Code: "17326",
    Name: "Xã Nghĩa Hành",
    LevelUp: "Xã",
    DistrictCode: "2910",
    Active: -1,
  },
  {
    ID: 5472,
    Code: "17329",
    Name: "Thị trấn Anh Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5473,
    Code: "17332",
    Name: "Xã Thọ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5474,
    Code: "17335",
    Name: "Xã Thành Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5475,
    Code: "17338",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5476,
    Code: "17341",
    Name: "Xã Tam Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5477,
    Code: "17344",
    Name: "Xã Đỉnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5478,
    Code: "17347",
    Name: "Xã Hùng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5479,
    Code: "17350",
    Name: "Xã Cẩm Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5480,
    Code: "17353",
    Name: "Xã Đức Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5481,
    Code: "17356",
    Name: "Xã Tường Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5482,
    Code: "17357",
    Name: "Xã Hoa Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5483,
    Code: "17359",
    Name: "Xã Tào Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5484,
    Code: "17362",
    Name: "Xã Vĩnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5485,
    Code: "17365",
    Name: "Xã Lạng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5486,
    Code: "17368",
    Name: "Xã Hội Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5487,
    Code: "17371",
    Name: "Xã Thạch Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5488,
    Code: "17374",
    Name: "Xã Phúc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5489,
    Code: "17377",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5490,
    Code: "17380",
    Name: "Xã Khai Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5491,
    Code: "17383",
    Name: "Xã Lĩnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5492,
    Code: "17386",
    Name: "Xã Cao Sơn",
    LevelUp: "Xã",
    DistrictCode: "2913",
    Active: -1,
  },
  {
    ID: 5493,
    Code: "17389",
    Name: "Thị trấn Diễn Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5494,
    Code: "17392",
    Name: "Xã Diễn Lâm",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5495,
    Code: "17395",
    Name: "Xã Diễn Đoài",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5496,
    Code: "17398",
    Name: "Xã Diễn Trường",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5497,
    Code: "17401",
    Name: "Xã Diễn Yên",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5498,
    Code: "17404",
    Name: "Xã Diễn Hoàng",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5499,
    Code: "17407",
    Name: "Xã Diễn Hùng",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5500,
    Code: "17410",
    Name: "Xã Diễn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5501,
    Code: "17413",
    Name: "Xã Diễn Hồng",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5502,
    Code: "17416",
    Name: "Xã Diễn Phong",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5503,
    Code: "17419",
    Name: "Xã Diễn Hải",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5504,
    Code: "17422",
    Name: "Xã Diễn Tháp",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5505,
    Code: "17425",
    Name: "Xã Diễn Liên",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5506,
    Code: "17428",
    Name: "Xã Diễn Vạn",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5507,
    Code: "17431",
    Name: "Xã Diễn Kim",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5508,
    Code: "17434",
    Name: "Xã Diễn Kỷ",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5509,
    Code: "17437",
    Name: "Xã Diễn Xuân",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5510,
    Code: "17440",
    Name: "Xã Diễn Thái",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5511,
    Code: "17443",
    Name: "Xã Diễn Đồng",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5512,
    Code: "17446",
    Name: "Xã Diễn Bích",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5513,
    Code: "17449",
    Name: "Xã Diễn Hạnh",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5514,
    Code: "17452",
    Name: "Xã Diễn Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5515,
    Code: "17455",
    Name: "Xã Diễn Quảng",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5516,
    Code: "17458",
    Name: "Xã Diễn Nguyên",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5517,
    Code: "17461",
    Name: "Xã Diễn Hoa",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5518,
    Code: "17464",
    Name: "Xã Diễn Thành",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5519,
    Code: "17467",
    Name: "Xã Diễn Phúc",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5520,
    Code: "17476",
    Name: "Xã Diễn Cát",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5521,
    Code: "17479",
    Name: "Xã Diễn Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5522,
    Code: "17482",
    Name: "Xã Diễn Tân",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5523,
    Code: "17485",
    Name: "Xã Minh Châu",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5524,
    Code: "17488",
    Name: "Xã Diễn Thọ",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5525,
    Code: "17491",
    Name: "Xã Diễn Lợi",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5526,
    Code: "17494",
    Name: "Xã Diễn Lộc",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5527,
    Code: "17497",
    Name: "Xã Diễn Trung",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5528,
    Code: "17500",
    Name: "Xã Diễn An",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5529,
    Code: "17503",
    Name: "Xã Diễn Phú",
    LevelUp: "Xã",
    DistrictCode: "2912",
    Active: -1,
  },
  {
    ID: 5530,
    Code: "17506",
    Name: "Thị trấn Yên Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5531,
    Code: "17509",
    Name: "Xã Mã Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5532,
    Code: "17510",
    Name: "Xã Tiến Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5533,
    Code: "17512",
    Name: "Xã Lăng Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5534,
    Code: "17515",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5535,
    Code: "17518",
    Name: "Xã Đức Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5536,
    Code: "17521",
    Name: "Xã Kim Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5537,
    Code: "17524",
    Name: "Xã Hậu Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5538,
    Code: "17525",
    Name: "Xã Hùng Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5539,
    Code: "17527",
    Name: "Xã Đô Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5540,
    Code: "17530",
    Name: "Xã Thọ Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5541,
    Code: "17533",
    Name: "Xã Quang Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5542,
    Code: "17536",
    Name: "Xã Tây Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5543,
    Code: "17539",
    Name: "Xã Phúc Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5544,
    Code: "17542",
    Name: "Xã Hồng Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5545,
    Code: "17545",
    Name: "Xã Đồng Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5546,
    Code: "17548",
    Name: "Xã Phú Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5547,
    Code: "17551",
    Name: "Xã Hoa Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5548,
    Code: "17554",
    Name: "Xã Tăng Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5549,
    Code: "17557",
    Name: "Xã Văn Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5550,
    Code: "17560",
    Name: "Xã Thịnh Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5551,
    Code: "17563",
    Name: "Xã Hợp Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5552,
    Code: "17566",
    Name: "Xã Xuân Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5553,
    Code: "17569",
    Name: "Xã Bắc Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5554,
    Code: "17572",
    Name: "Xã Nhân Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5555,
    Code: "17575",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5556,
    Code: "17578",
    Name: "Xã Long Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5557,
    Code: "17581",
    Name: "Xã Minh Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5558,
    Code: "17584",
    Name: "Xã Nam Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5559,
    Code: "17587",
    Name: "Xã Vĩnh Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5560,
    Code: "17590",
    Name: "Xã Lý Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5561,
    Code: "17593",
    Name: "Xã Khánh Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5562,
    Code: "17596",
    Name: "Xã Viên Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5563,
    Code: "17599",
    Name: "Xã Đại Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5564,
    Code: "17602",
    Name: "Xã Liên Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5565,
    Code: "17605",
    Name: "Xã Bảo Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5566,
    Code: "17608",
    Name: "Xã Mỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5567,
    Code: "17611",
    Name: "Xã Công Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5568,
    Code: "17614",
    Name: "Xã Sơn Thành",
    LevelUp: "Xã",
    DistrictCode: "2911",
    Active: -1,
  },
  {
    ID: 5569,
    Code: "17617",
    Name: "Thị trấn Đô Lương",
    LevelUp: "Thị trấn",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5570,
    Code: "17619",
    Name: "Xã Giang Sơn Đông",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5571,
    Code: "17620",
    Name: "Xã Giang Sơn Tây",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5572,
    Code: "17623",
    Name: "Xã Lam Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5573,
    Code: "17626",
    Name: "Xã Bồi Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5574,
    Code: "17629",
    Name: "Xã Hồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5575,
    Code: "17632",
    Name: "Xã Bài Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5576,
    Code: "17635",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5577,
    Code: "17638",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5578,
    Code: "17641",
    Name: "Xã Tràng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5579,
    Code: "17644",
    Name: "Xã Thượng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5580,
    Code: "17647",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5581,
    Code: "17650",
    Name: "Xã Đặng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5582,
    Code: "17653",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5583,
    Code: "17656",
    Name: "Xã Nam Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5584,
    Code: "17659",
    Name: "Xã Lưu Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5585,
    Code: "17662",
    Name: "Xã Yên Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5586,
    Code: "17665",
    Name: "Xã Văn Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5587,
    Code: "17668",
    Name: "Xã Đà Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5588,
    Code: "17671",
    Name: "Xã Lạc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5589,
    Code: "17674",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5590,
    Code: "17677",
    Name: "Xã Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5591,
    Code: "17680",
    Name: "Xã Quang Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5592,
    Code: "17683",
    Name: "Xã Thịnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5593,
    Code: "17686",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5594,
    Code: "17689",
    Name: "Xã Xuân Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5595,
    Code: "17692",
    Name: "Xã Minh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5596,
    Code: "17695",
    Name: "Xã Thuận Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5597,
    Code: "17698",
    Name: "Xã Nhân Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5598,
    Code: "17701",
    Name: "Xã Hiến Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5599,
    Code: "17704",
    Name: "Xã Mỹ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5600,
    Code: "17707",
    Name: "Xã Trù Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5601,
    Code: "17710",
    Name: "Xã Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "2914",
    Active: -1,
  },
  {
    ID: 5602,
    Code: "17713",
    Name: "Thị trấn Thanh Chương",
    LevelUp: "Thị trấn",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5603,
    Code: "17716",
    Name: "Xã Cát Văn",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5604,
    Code: "17719",
    Name: "Xã Thanh Nho",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5605,
    Code: "17722",
    Name: "Xã Hạnh Lâm",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5606,
    Code: "17723",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5607,
    Code: "17725",
    Name: "Xã Thanh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5608,
    Code: "17728",
    Name: "Xã Phong Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5609,
    Code: "17731",
    Name: "Xã Thanh Phong",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5610,
    Code: "17734",
    Name: "Xã Thanh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5611,
    Code: "17737",
    Name: "Xã Thanh Tiên",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5612,
    Code: "17743",
    Name: "Xã Thanh Liên",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5613,
    Code: "17749",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5614,
    Code: "17752",
    Name: "Xã Thanh Đồng",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5615,
    Code: "17755",
    Name: "Xã Thanh Ngọc",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5616,
    Code: "17758",
    Name: "Xã Thanh Hương",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5617,
    Code: "17759",
    Name: "Xã Ngọc Lâm",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5618,
    Code: "17761",
    Name: "Xã Thanh Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5619,
    Code: "17764",
    Name: "Xã Đồng Văn",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5620,
    Code: "17767",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5621,
    Code: "17770",
    Name: "Xã Thanh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5622,
    Code: "17773",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5623,
    Code: "17776",
    Name: "Xã Thanh Chi",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5624,
    Code: "17779",
    Name: "Xã Xuân Tường",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5625,
    Code: "17782",
    Name: "Xã Thanh Dương",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5626,
    Code: "17785",
    Name: "Xã Thanh Lương",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5627,
    Code: "17788",
    Name: "Xã Thanh Khê",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5628,
    Code: "17791",
    Name: "Xã Võ Liệt",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5629,
    Code: "17794",
    Name: "Xã Thanh Long",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5630,
    Code: "17797",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5631,
    Code: "17800",
    Name: "Xã Thanh Khai",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5632,
    Code: "17803",
    Name: "Xã Thanh Yên",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5633,
    Code: "17806",
    Name: "Xã Thanh Hà",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5634,
    Code: "17809",
    Name: "Xã Thanh Giang",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5635,
    Code: "17812",
    Name: "Xã Thanh Tùng",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5636,
    Code: "17815",
    Name: "Xã Thanh Lâm",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5637,
    Code: "17818",
    Name: "Xã Thanh Mai",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5638,
    Code: "17821",
    Name: "Xã Thanh Xuân",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5639,
    Code: "17824",
    Name: "Xã Thanh Đức",
    LevelUp: "Xã",
    DistrictCode: "2915",
    Active: -1,
  },
  {
    ID: 5640,
    Code: "17827",
    Name: "Thị trấn Quán Hành",
    LevelUp: "Thị trấn",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5641,
    Code: "17830",
    Name: "Xã Nghi Văn",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5642,
    Code: "17833",
    Name: "Xã Nghi Yên",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5643,
    Code: "17836",
    Name: "Xã Nghi Tiến",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5644,
    Code: "17839",
    Name: "Xã Nghi Hưng",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5645,
    Code: "17842",
    Name: "Xã Nghi Đồng",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5646,
    Code: "17845",
    Name: "Xã Nghi Thiết",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5647,
    Code: "17848",
    Name: "Xã Nghi Lâm",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5648,
    Code: "17851",
    Name: "Xã Nghi Quang",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5649,
    Code: "17854",
    Name: "Xã Nghi Kiều",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5650,
    Code: "17857",
    Name: "Xã Nghi Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5651,
    Code: "17860",
    Name: "Xã Nghi Phương",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5652,
    Code: "17863",
    Name: "Xã Nghi Thuận",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5653,
    Code: "17866",
    Name: "Xã Nghi Long",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5654,
    Code: "17869",
    Name: "Xã Nghi Xá",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5655,
    Code: "17875",
    Name: "Xã Nghi Hoa",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5656,
    Code: "17878",
    Name: "Xã Khánh Hợp",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5657,
    Code: "17881",
    Name: "Xã Nghi Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5658,
    Code: "17884",
    Name: "Xã Nghi Công Bắc",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5659,
    Code: "17887",
    Name: "Xã Nghi Công Nam",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5660,
    Code: "17890",
    Name: "Xã Nghi Thạch",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5661,
    Code: "17893",
    Name: "Xã Nghi Trung",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5662,
    Code: "17896",
    Name: "Xã Nghi Trường",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5663,
    Code: "17899",
    Name: "Xã Nghi Diên",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5664,
    Code: "17902",
    Name: "Xã Nghi Phong",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5665,
    Code: "17905",
    Name: "Xã Nghi Xuân",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5666,
    Code: "17911",
    Name: "Xã Nghi Vạn",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5667,
    Code: "17917",
    Name: "Xã Phúc Thọ",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5668,
    Code: "17926",
    Name: "Xã Nghi Thái",
    LevelUp: "Xã",
    DistrictCode: "2916",
    Active: -1,
  },
  {
    ID: 5669,
    Code: "17932",
    Name: "Xã Nam Hưng",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5670,
    Code: "17935",
    Name: "Xã Nam Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5671,
    Code: "17938",
    Name: "Xã Nam Thanh",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5672,
    Code: "17941",
    Name: "Xã Nam Anh",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5673,
    Code: "17944",
    Name: "Xã Nam Xuân",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5674,
    Code: "17947",
    Name: "Xã Nam Thái",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5675,
    Code: "17950",
    Name: "Thị trấn Nam Đàn",
    LevelUp: "Thị trấn",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5676,
    Code: "17953",
    Name: "Xã Nam Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5677,
    Code: "17956",
    Name: "Xã Nam Giang",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5678,
    Code: "17959",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5679,
    Code: "17962",
    Name: "Xã Hùng Tiến",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5680,
    Code: "17968",
    Name: "Xã Thượng Tân Lộc",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5681,
    Code: "17971",
    Name: "Xã Kim Liên",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5682,
    Code: "17977",
    Name: "Xã Hồng Long",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5683,
    Code: "17980",
    Name: "Xã Xuân Lâm",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5684,
    Code: "17983",
    Name: "Xã Nam Cát",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5685,
    Code: "17986",
    Name: "Xã Khánh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5686,
    Code: "17989",
    Name: "Xã Trung Phúc Cường",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5687,
    Code: "17998",
    Name: "Xã Nam Kim",
    LevelUp: "Xã",
    DistrictCode: "2917",
    Active: -1,
  },
  {
    ID: 5688,
    Code: "18001",
    Name: "Thị trấn Hưng Nguyên",
    LevelUp: "Thị trấn",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5689,
    Code: "18004",
    Name: "Xã Hưng Trung",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5690,
    Code: "18007",
    Name: "Xã Hưng Yên",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5691,
    Code: "18008",
    Name: "Xã Hưng Yên Bắc",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5692,
    Code: "18010",
    Name: "Xã Hưng Tây",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5693,
    Code: "18016",
    Name: "Xã Hưng Đạo",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5694,
    Code: "18019",
    Name: "Xã Hưng Mỹ",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5695,
    Code: "18022",
    Name: "Xã Hưng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5696,
    Code: "18025",
    Name: "Xã Hưng Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5697,
    Code: "18028",
    Name: "Xã Hưng Thông",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5698,
    Code: "18031",
    Name: "Xã Hưng Tân",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5699,
    Code: "18034",
    Name: "Xã Hưng Lợi",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5700,
    Code: "18037",
    Name: "Xã Hưng Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5701,
    Code: "18040",
    Name: "Xã Hưng Phúc",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5702,
    Code: "18043",
    Name: "Xã Long Xá",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5703,
    Code: "18052",
    Name: "Xã Châu Nhân",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5704,
    Code: "18055",
    Name: "Xã Xuân Lam",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5705,
    Code: "18064",
    Name: "Xã Hưng Thành",
    LevelUp: "Xã",
    DistrictCode: "2918",
    Active: -1,
  },
  {
    ID: 5706,
    Code: "17104",
    Name: "Xã Quỳnh Vinh",
    LevelUp: "Xã",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5707,
    Code: "17107",
    Name: "Xã Quỳnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5708,
    Code: "17110",
    Name: "Phường Quỳnh Thiện",
    LevelUp: "Phường",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5709,
    Code: "17113",
    Name: "Xã Quỳnh Lập",
    LevelUp: "Xã",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5710,
    Code: "17116",
    Name: "Xã Quỳnh Trang",
    LevelUp: "Xã",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5711,
    Code: "17125",
    Name: "Phường Mai Hùng",
    LevelUp: "Phường",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5712,
    Code: "17128",
    Name: "Phường Quỳnh Dị",
    LevelUp: "Phường",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5713,
    Code: "17131",
    Name: "Phường Quỳnh Xuân",
    LevelUp: "Phường",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5714,
    Code: "17134",
    Name: "Phường Quỳnh Phương",
    LevelUp: "Phường",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5715,
    Code: "17137",
    Name: "Xã Quỳnh Liên",
    LevelUp: "Xã",
    DistrictCode: "2921",
    Active: -1,
  },
  {
    ID: 5716,
    Code: "18070",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5717,
    Code: "18073",
    Name: "Phường Nam Hà",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5718,
    Code: "18076",
    Name: "Phường Bắc Hà",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5719,
    Code: "18077",
    Name: "Phường Nguyễn Du",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5720,
    Code: "18079",
    Name: "Phường Tân Giang",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5721,
    Code: "18082",
    Name: "Phường Đại Nài",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5722,
    Code: "18085",
    Name: "Phường Hà Huy Tập",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5723,
    Code: "18088",
    Name: "Xã Thạch Trung",
    LevelUp: "Xã",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5724,
    Code: "18091",
    Name: "Phường Thạch Quý",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5725,
    Code: "18094",
    Name: "Phường Thạch Linh",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5726,
    Code: "18097",
    Name: "Phường Văn Yên",
    LevelUp: "Phường",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5727,
    Code: "18100",
    Name: "Xã Thạch Hạ",
    LevelUp: "Xã",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5728,
    Code: "18103",
    Name: "Xã Đồng Môn",
    LevelUp: "Xã",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5729,
    Code: "18109",
    Name: "Xã Thạch Hưng",
    LevelUp: "Xã",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5730,
    Code: "18112",
    Name: "Xã Thạch Bình",
    LevelUp: "Xã",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 5731,
    Code: "18115",
    Name: "Phường Bắc Hồng",
    LevelUp: "Phường",
    DistrictCode: "3002",
    Active: -1,
  },
  {
    ID: 5732,
    Code: "18118",
    Name: "Phường Nam Hồng",
    LevelUp: "Phường",
    DistrictCode: "3002",
    Active: -1,
  },
  {
    ID: 5733,
    Code: "18121",
    Name: "Phường Trung Lương",
    LevelUp: "Phường",
    DistrictCode: "3002",
    Active: -1,
  },
  {
    ID: 5734,
    Code: "18124",
    Name: "Phường Đức Thuận",
    LevelUp: "Phường",
    DistrictCode: "3002",
    Active: -1,
  },
  {
    ID: 5735,
    Code: "18127",
    Name: "Phường Đậu Liêu",
    LevelUp: "Phường",
    DistrictCode: "3002",
    Active: -1,
  },
  {
    ID: 5736,
    Code: "18130",
    Name: "Xã Thuận Lộc",
    LevelUp: "Xã",
    DistrictCode: "3002",
    Active: -1,
  },
  {
    ID: 5737,
    Code: "18133",
    Name: "Thị trấn Phố Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5738,
    Code: "18136",
    Name: "Thị trấn  Tây Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5739,
    Code: "18139",
    Name: "Xã Sơn Hồng",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5740,
    Code: "18142",
    Name: "Xã Sơn Tiến",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5741,
    Code: "18145",
    Name: "Xã Sơn Lâm",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5742,
    Code: "18148",
    Name: "Xã Sơn Lễ",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5743,
    Code: "18157",
    Name: "Xã Sơn Giang",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5744,
    Code: "18160",
    Name: "Xã Sơn Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5745,
    Code: "18163",
    Name: "Xã An Hòa Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5746,
    Code: "18172",
    Name: "Xã Sơn Tây",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5747,
    Code: "18175",
    Name: "Xã Sơn Ninh",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5748,
    Code: "18178",
    Name: "Xã Sơn Châu",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5749,
    Code: "18181",
    Name: "Xã Tân Mỹ Hà",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5750,
    Code: "18184",
    Name: "Xã Quang Diệm",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5751,
    Code: "18187",
    Name: "Xã Sơn Trung",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5752,
    Code: "18190",
    Name: "Xã Sơn Bằng",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5753,
    Code: "18193",
    Name: "Xã Sơn Bình",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5754,
    Code: "18196",
    Name: "Xã Sơn Kim 1",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5755,
    Code: "18199",
    Name: "Xã Sơn Kim 2",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5756,
    Code: "18202",
    Name: "Xã Sơn Trà",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5757,
    Code: "18205",
    Name: "Xã Sơn Long",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5758,
    Code: "18211",
    Name: "Xã Kim Hoa",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5759,
    Code: "18214",
    Name: "Xã Sơn Hàm",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5760,
    Code: "18217",
    Name: "Xã Sơn Phú",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5761,
    Code: "18223",
    Name: "Xã Sơn Trường",
    LevelUp: "Xã",
    DistrictCode: "3003",
    Active: -1,
  },
  {
    ID: 5762,
    Code: "18229",
    Name: "Thị trấn Đức Thọ",
    LevelUp: "Thị trấn",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5763,
    Code: "18235",
    Name: "Xã Quang Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5764,
    Code: "18241",
    Name: "Xã Tùng Châu",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5765,
    Code: "18244",
    Name: "Xã Trường Sơn",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5766,
    Code: "18247",
    Name: "Xã Liên Minh",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5767,
    Code: "18253",
    Name: "Xã Yên Hồ",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5768,
    Code: "18259",
    Name: "Xã Tùng Ảnh",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5769,
    Code: "18262",
    Name: "Xã Bùi La Nhân",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5770,
    Code: "18274",
    Name: "Xã Thanh Bình Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5771,
    Code: "18277",
    Name: "Xã Lâm Trung Thủy",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5772,
    Code: "18280",
    Name: "Xã Hòa Lạc",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5773,
    Code: "18283",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5774,
    Code: "18298",
    Name: "Xã An Dũng",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5775,
    Code: "18304",
    Name: "Xã Đức Đồng",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5776,
    Code: "18307",
    Name: "Xã Đức Lạng",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5777,
    Code: "18310",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 5778,
    Code: "18313",
    Name: "Thị trấn Vũ Quang",
    LevelUp: "Thị trấn",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5779,
    Code: "18316",
    Name: "Xã Ân Phú",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5780,
    Code: "18319",
    Name: "Xã Đức Giang",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5781,
    Code: "18322",
    Name: "Xã Đức Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5782,
    Code: "18325",
    Name: "Xã Thọ Điền",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5783,
    Code: "18328",
    Name: "Xã Đức Hương",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5784,
    Code: "18331",
    Name: "Xã Đức Bồng",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5785,
    Code: "18334",
    Name: "Xã Đức Liên",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5786,
    Code: "18340",
    Name: "Xã Hương Minh",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5787,
    Code: "18343",
    Name: "Xã Quang Thọ",
    LevelUp: "Xã",
    DistrictCode: "3011",
    Active: -1,
  },
  {
    ID: 5788,
    Code: "18352",
    Name: "Thị trấn Xuân An",
    LevelUp: "Thị trấn",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5789,
    Code: "18355",
    Name: "Xã Xuân Hội",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5790,
    Code: "18358",
    Name: "Xã Đan Trường",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5791,
    Code: "18364",
    Name: "Xã Xuân Phổ",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5792,
    Code: "18367",
    Name: "Xã Xuân Hải",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5793,
    Code: "18370",
    Name: "Xã Xuân Giang",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5794,
    Code: "18373",
    Name: "Thị trấn Tiên Điền",
    LevelUp: "Thị trấn",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5795,
    Code: "18376",
    Name: "Xã Xuân Yên",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5796,
    Code: "18379",
    Name: "Xã Xuân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5797,
    Code: "18382",
    Name: "Xã Xuân Thành",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5798,
    Code: "18385",
    Name: "Xã Xuân Viên",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5799,
    Code: "18388",
    Name: "Xã Xuân Hồng",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5800,
    Code: "18391",
    Name: "Xã Cỗ Đạm",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5801,
    Code: "18394",
    Name: "Xã Xuân Liên",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5802,
    Code: "18397",
    Name: "Xã Xuân Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5803,
    Code: "18400",
    Name: "Xã Xuân Lam",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5804,
    Code: "18403",
    Name: "Xã Cương Gián",
    LevelUp: "Xã",
    DistrictCode: "3005",
    Active: -1,
  },
  {
    ID: 5805,
    Code: "18406",
    Name: "Thị trấn Nghèn",
    LevelUp: "Thị trấn",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5806,
    Code: "18415",
    Name: "Xã Thiên Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5807,
    Code: "18418",
    Name: "Xã Thuần Thiện",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5808,
    Code: "18427",
    Name: "Xã Vượng Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5809,
    Code: "18433",
    Name: "Xã Thanh Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5810,
    Code: "18436",
    Name: "Xã Kim Song Trường",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5811,
    Code: "18439",
    Name: "Xã Thường Nga",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5812,
    Code: "18445",
    Name: "Xã Tùng Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5813,
    Code: "18454",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5814,
    Code: "18463",
    Name: "Xã Gia Hanh",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5815,
    Code: "18466",
    Name: "Xã Khánh Vĩnh Yên",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5816,
    Code: "18472",
    Name: "Xã Trung Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5817,
    Code: "18475",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5818,
    Code: "18478",
    Name: "Xã Thượng Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5819,
    Code: "18481",
    Name: "Xã Quang Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5820,
    Code: "18484",
    Name: "Thị trấn Đồng Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5821,
    Code: "18487",
    Name: "Xã Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5822,
    Code: "18490",
    Name: "Xã Sơn Lộc",
    LevelUp: "Xã",
    DistrictCode: "3006",
    Active: -1,
  },
  {
    ID: 5823,
    Code: "18496",
    Name: "Thị trấn Hương Khê",
    LevelUp: "Thị trấn",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5824,
    Code: "18499",
    Name: "Xã Điền Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5825,
    Code: "18502",
    Name: "Xã Hà Linh",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5826,
    Code: "18505",
    Name: "Xã Hương Thủy",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5827,
    Code: "18508",
    Name: "Xã Hòa Hải",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5828,
    Code: "18514",
    Name: "Xã Phúc Đồng",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5829,
    Code: "18517",
    Name: "Xã Hương Giang",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5830,
    Code: "18520",
    Name: "Xã Lộc Yên",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5831,
    Code: "18523",
    Name: "Xã Hương Bình",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5832,
    Code: "18526",
    Name: "Xã Hương Long",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5833,
    Code: "18529",
    Name: "Xã Phú Gia",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5834,
    Code: "18532",
    Name: "Xã Gia Phố",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5835,
    Code: "18535",
    Name: "Xã Phú Phong",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5836,
    Code: "18538",
    Name: "Xã Hương Đô",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5837,
    Code: "18541",
    Name: "Xã Hương Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5838,
    Code: "18544",
    Name: "Xã Hương Xuân",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5839,
    Code: "18547",
    Name: "Xã Phúc Trạch",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5840,
    Code: "18550",
    Name: "Xã Hương Trà",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5841,
    Code: "18553",
    Name: "Xã Hương Trạch",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5842,
    Code: "18556",
    Name: "Xã Hương Lâm",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5843,
    Code: "18559",
    Name: "Xã Hương Liên",
    LevelUp: "Xã",
    DistrictCode: "3007",
    Active: -1,
  },
  {
    ID: 5844,
    Code: "18562",
    Name: "Thị trấn Thạch Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5845,
    Code: "18565",
    Name: "Xã Ngọc Sơn",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5846,
    Code: "18571",
    Name: "Xã Thạch Hải",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5847,
    Code: "18586",
    Name: "Xã Thạch Kênh",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5848,
    Code: "18589",
    Name: "Xã Thạch Sơn",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5849,
    Code: "18592",
    Name: "Xã Thạch Liên",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5850,
    Code: "18595",
    Name: "Xã Đỉnh Bàn",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5851,
    Code: "18601",
    Name: "Xã Việt Tiến",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5852,
    Code: "18604",
    Name: "Xã Thạch Khê",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5853,
    Code: "18607",
    Name: "Xã Thạch Long",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5854,
    Code: "18619",
    Name: "Xã Thạch Trị",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5855,
    Code: "18622",
    Name: "Xã Thạch Lạc",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5856,
    Code: "18625",
    Name: "Xã Thạch Ngọc",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5857,
    Code: "18628",
    Name: "Xã Tượng Sơn",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5858,
    Code: "18631",
    Name: "Xã Thạch Văn",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5859,
    Code: "18634",
    Name: "Xã Lưu Vĩnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5860,
    Code: "18637",
    Name: "Xã Thạch Thắng",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5861,
    Code: "18643",
    Name: "Xã Thạch Đài",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5862,
    Code: "18649",
    Name: "Xã Thạch Hội",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5863,
    Code: "18652",
    Name: "Xã Tân Lâm Hương",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5864,
    Code: "18658",
    Name: "Xã Thạch Xuân",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5865,
    Code: "18667",
    Name: "Xã Nam Điền",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 5866,
    Code: "18673",
    Name: "Thị trấn Cẩm Xuyên",
    LevelUp: "Thị trấn",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5867,
    Code: "18676",
    Name: "Thị trấn Thiên Cầm",
    LevelUp: "Thị trấn",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5868,
    Code: "18679",
    Name: "Xã Yên Hòa",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5869,
    Code: "18682",
    Name: "Xã Cẩm Dương",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5870,
    Code: "18685",
    Name: "Xã Cẩm Bình",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5871,
    Code: "18691",
    Name: "Xã Cẩm Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5872,
    Code: "18694",
    Name: "Xã Cẩm Thành",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5873,
    Code: "18697",
    Name: "Xã Cẩm Quang",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5874,
    Code: "18706",
    Name: "Xã Cẩm Thạch",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5875,
    Code: "18709",
    Name: "Xã Cẩm Nhượng",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5876,
    Code: "18712",
    Name: "Xã Nam Phúc Thăng",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5877,
    Code: "18715",
    Name: "Xã Cẩm Duệ",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5878,
    Code: "18721",
    Name: "Xã Cẩm Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5879,
    Code: "18724",
    Name: "Xã Cẩm Quan",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5880,
    Code: "18727",
    Name: "Xã Cẩm Hà",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5881,
    Code: "18730",
    Name: "Xã Cẩm Lộc",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5882,
    Code: "18733",
    Name: "Xã Cẩm Hưng",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5883,
    Code: "18736",
    Name: "Xã Cẩm Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5884,
    Code: "18739",
    Name: "Xã Cẩm Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5885,
    Code: "18742",
    Name: "Xã Cẩm Trung",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5886,
    Code: "18745",
    Name: "Xã Cẩm Sơn",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5887,
    Code: "18748",
    Name: "Xã Cẩm Lạc",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5888,
    Code: "18751",
    Name: "Xã Cẩm Minh",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 5889,
    Code: "18757",
    Name: "Xã Kỳ Xuân",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5891,
    Code: "18763",
    Name: "Xã Kỳ Phú",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5892,
    Code: "18766",
    Name: "Xã Kỳ Phong",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5893,
    Code: "18769",
    Name: "Xã Kỳ Tiến",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5894,
    Code: "18772",
    Name: "Xã Kỳ Giang",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5895,
    Code: "18775",
    Name: "Xã Kỳ Đồng",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5896,
    Code: "18778",
    Name: "Xã Kỳ Khang",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5897,
    Code: "18784",
    Name: "Xã Kỳ Văn",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5898,
    Code: "18787",
    Name: "Xã Kỳ Trung",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5899,
    Code: "18790",
    Name: "Xã Kỳ Thọ",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5900,
    Code: "18793",
    Name: "Xã Kỳ Tây",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 5906,
    Code: "18838",
    Name: "Xã Lâm Hợp",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 5909,
    Code: "18409",
    Name: "Xã Tân Lộc",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5910,
    Code: "18412",
    Name: "Xã Hồng Lộc",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5911,
    Code: "18421",
    Name: "Xã Thịnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5912,
    Code: "18430",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5913,
    Code: "18457",
    Name: "Xã Ích Hậu",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5914,
    Code: "18493",
    Name: "Xã Phù Lưu",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5915,
    Code: "18568",
    Name: "Thị trấn Lộc Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5916,
    Code: "18577",
    Name: "Xã Thạch Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5917,
    Code: "18580",
    Name: "Xã Thạch Kim",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5918,
    Code: "18583",
    Name: "Xã Thạch Châu",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5919,
    Code: "18598",
    Name: "Xã Hộ Độ",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5920,
    Code: "18670",
    Name: "Xã Mai Phụ",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
  {
    ID: 5921,
    Code: "18754",
    Name: "Phường Hưng Trí",
    LevelUp: "Phường",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5922,
    Code: "18781",
    Name: "Xã Kỳ Ninh",
    LevelUp: "Xã",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5923,
    Code: "18796",
    Name: "Xã Kỳ Lợi",
    LevelUp: "Xã",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5924,
    Code: "18808",
    Name: "Xã Kỳ Hà",
    LevelUp: "Xã",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5925,
    Code: "18820",
    Name: "Phường Kỳ Trinh",
    LevelUp: "Phường",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5926,
    Code: "18823",
    Name: "Phường Kỳ Thịnh",
    LevelUp: "Phường",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5927,
    Code: "18829",
    Name: "Xã Kỳ Hoa",
    LevelUp: "Xã",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5928,
    Code: "18832",
    Name: "Phường Kỳ Phương",
    LevelUp: "Phường",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5929,
    Code: "18835",
    Name: "Phường Kỳ Long",
    LevelUp: "Phường",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5930,
    Code: "18841",
    Name: "Phường Kỳ Liên",
    LevelUp: "Phường",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5931,
    Code: "18847",
    Name: "Xã Kỳ Nam",
    LevelUp: "Xã",
    DistrictCode: "3010",
    Active: -1,
  },
  {
    ID: 5932,
    Code: "18853",
    Name: "Phường Hải Thành",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5933,
    Code: "18856",
    Name: "Phường Đồng Phú",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5934,
    Code: "18859",
    Name: "Phường Bắc Lý",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5935,
    Code: "18865",
    Name: "Phường Nam Lý",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5936,
    Code: "18868",
    Name: "Phường Đồng Hải",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5937,
    Code: "18871",
    Name: "Phường Đồng Sơn",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5938,
    Code: "18874",
    Name: "Phường Phú Hải",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5939,
    Code: "18877",
    Name: "Phường Bắc Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5940,
    Code: "18880",
    Name: "Phường Đức Ninh Đông",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5941,
    Code: "18883",
    Name: "Xã Quang Phú",
    LevelUp: "Xã",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5942,
    Code: "18886",
    Name: "Xã Lộc Ninh",
    LevelUp: "Xã",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5943,
    Code: "18889",
    Name: "Xã Bảo Ninh",
    LevelUp: "Xã",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5944,
    Code: "18892",
    Name: "Xã Nghĩa Ninh",
    LevelUp: "Xã",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5945,
    Code: "18895",
    Name: "Xã Thuận Đức",
    LevelUp: "Xã",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5946,
    Code: "18898",
    Name: "Xã Đức Ninh",
    LevelUp: "Xã",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 5947,
    Code: "18901",
    Name: "Thị trấn Quy Đạt",
    LevelUp: "Thị trấn",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5948,
    Code: "18904",
    Name: "Xã Dân Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5949,
    Code: "18907",
    Name: "Xã Trọng Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5950,
    Code: "18910",
    Name: "Xã Hóa Phúc",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5951,
    Code: "18913",
    Name: "Xã Hồng Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5952,
    Code: "18916",
    Name: "Xã Hóa Thanh",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5953,
    Code: "18919",
    Name: "Xã Hóa Tiến",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5954,
    Code: "18922",
    Name: "Xã Hóa Hợp",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5955,
    Code: "18925",
    Name: "Xã Xuân Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5956,
    Code: "18928",
    Name: "Xã Yên Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5957,
    Code: "18931",
    Name: "Xã Minh Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5958,
    Code: "18934",
    Name: "Xã Tân Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5959,
    Code: "18937",
    Name: "Xã Hóa Sơn",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5960,
    Code: "18943",
    Name: "Xã Trung Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5961,
    Code: "18946",
    Name: "Xã Thượng Hóa",
    LevelUp: "Xã",
    DistrictCode: "3103",
    Active: -1,
  },
  {
    ID: 5962,
    Code: "18949",
    Name: "Thị trấn Đồng Lê",
    LevelUp: "Thị trấn",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5963,
    Code: "18952",
    Name: "Xã Hương Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5964,
    Code: "18955",
    Name: "Xã Kim Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5965,
    Code: "18958",
    Name: "Xã Thanh Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5966,
    Code: "18961",
    Name: "Xã Thanh Thạch",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5967,
    Code: "18964",
    Name: "Xã Thuận Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5968,
    Code: "18967",
    Name: "Xã Lâm Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5969,
    Code: "18970",
    Name: "Xã Lê Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5970,
    Code: "18973",
    Name: "Xã Sơn Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5971,
    Code: "18976",
    Name: "Xã Đồng Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5972,
    Code: "18979",
    Name: "Xã Ngư Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5973,
    Code: "18985",
    Name: "Xã Thạch Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5974,
    Code: "18988",
    Name: "Xã Đức Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5975,
    Code: "18991",
    Name: "Xã Phong Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5976,
    Code: "18994",
    Name: "Xã Mai Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5977,
    Code: "18997",
    Name: "Xã Tiến Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5978,
    Code: "19000",
    Name: "Xã Châu Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5979,
    Code: "19003",
    Name: "Xã Cao Quảng",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5980,
    Code: "19006",
    Name: "Xã Văn Hóa",
    LevelUp: "Xã",
    DistrictCode: "3108",
    Active: -1,
  },
  {
    ID: 5981,
    Code: "19012",
    Name: "Xã Quảng Hợp",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5982,
    Code: "19015",
    Name: "Xã Quảng Kim",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5983,
    Code: "19018",
    Name: "Xã Quảng Đông",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5984,
    Code: "19021",
    Name: "Xã Quảng Phú",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5985,
    Code: "19024",
    Name: "Xã Quảng Châu",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5986,
    Code: "19027",
    Name: "Xã Quảng Thạch",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5987,
    Code: "19030",
    Name: "Xã Quảng Lưu",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5988,
    Code: "19033",
    Name: "Xã Quảng Tùng",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5989,
    Code: "19036",
    Name: "Xã Cảnh Dương",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5990,
    Code: "19039",
    Name: "Xã Quảng Tiến",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5991,
    Code: "19042",
    Name: "Xã Quảng Hưng",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5992,
    Code: "19045",
    Name: "Xã Quảng Xuân",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5993,
    Code: "19048",
    Name: "Xã Cảnh Hóa",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5994,
    Code: "19051",
    Name: "Xã Liên Trường",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5995,
    Code: "19057",
    Name: "Xã Quảng Phương",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5996,
    Code: "19063",
    Name: "Xã Phù Hóa",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5997,
    Code: "19072",
    Name: "Xã Quảng Thanh",
    LevelUp: "Xã",
    DistrictCode: "3104",
    Active: -1,
  },
  {
    ID: 5998,
    Code: "19111",
    Name: "Thị trấn Hoàn Lão",
    LevelUp: "Thị trấn",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 5999,
    Code: "19114",
    Name: "Thị trấn NT Việt Trung",
    LevelUp: "Thị trấn",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6000,
    Code: "19117",
    Name: "Xã Xuân Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6001,
    Code: "19120",
    Name: "Xã Mỹ Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6002,
    Code: "19123",
    Name: "Xã Hạ Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6003,
    Code: "19126",
    Name: "Xã Bắc Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6004,
    Code: "19129",
    Name: "Xã Lâm Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6005,
    Code: "19132",
    Name: "Xã Thanh Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6006,
    Code: "19135",
    Name: "Xã Liên Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6007,
    Code: "19138",
    Name: "Xã Phúc Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6008,
    Code: "19141",
    Name: "Xã Cự Nẫm",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6009,
    Code: "19144",
    Name: "Xã Hải Phú",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6010,
    Code: "19147",
    Name: "Xã Thượng Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6011,
    Code: "19150",
    Name: "Xã Sơn Lộc",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6012,
    Code: "19156",
    Name: "Xã Hưng Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6013,
    Code: "19159",
    Name: "Xã Đồng Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6014,
    Code: "19162",
    Name: "Xã Đức Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6015,
    Code: "19165",
    Name: "Thị trấn Phong Nha",
    LevelUp: "Thị trấn",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6016,
    Code: "19168",
    Name: "Xã Vạn Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6017,
    Code: "19174",
    Name: "Xã Phú Định",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6018,
    Code: "19177",
    Name: "Xã Trung Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6019,
    Code: "19180",
    Name: "Xã Tây Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6020,
    Code: "19183",
    Name: "Xã Hòa Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6021,
    Code: "19186",
    Name: "Xã Đại Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6022,
    Code: "19189",
    Name: "Xã Nhân Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6023,
    Code: "19192",
    Name: "Xã Tân Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6024,
    Code: "19195",
    Name: "Xã Nam Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6025,
    Code: "19198",
    Name: "Xã Lý Trạch",
    LevelUp: "Xã",
    DistrictCode: "3105",
    Active: -1,
  },
  {
    ID: 6026,
    Code: "19201",
    Name: "Thị trấn Quán Hàu",
    LevelUp: "Thị trấn",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6027,
    Code: "19204",
    Name: "Xã Trường Sơn",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6028,
    Code: "19207",
    Name: "Xã Lương Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6029,
    Code: "19210",
    Name: "Xã Vĩnh Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6030,
    Code: "19213",
    Name: "Xã Võ Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6031,
    Code: "19216",
    Name: "Xã Hải Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6032,
    Code: "19219",
    Name: "Xã Hàm Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6033,
    Code: "19222",
    Name: "Xã Duy Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6034,
    Code: "19225",
    Name: "Xã Gia Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6035,
    Code: "19228",
    Name: "Xã Trường Xuân",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6036,
    Code: "19231",
    Name: "Xã Hiền Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6037,
    Code: "19234",
    Name: "Xã Tân Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6038,
    Code: "19237",
    Name: "Xã Xuân Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6039,
    Code: "19240",
    Name: "Xã An Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6040,
    Code: "19243",
    Name: "Xã Vạn Ninh",
    LevelUp: "Xã",
    DistrictCode: "3106",
    Active: -1,
  },
  {
    ID: 6041,
    Code: "19246",
    Name: "Thị trấn NT Lệ Ninh",
    LevelUp: "Thị trấn",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6042,
    Code: "19249",
    Name: "Thị trấn Kiến Giang",
    LevelUp: "Thị trấn",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6043,
    Code: "19252",
    Name: "Xã Hồng Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6044,
    Code: "19255",
    Name: "Xã Ngư Thủy Bắc",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6045,
    Code: "19258",
    Name: "Xã Hoa Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6046,
    Code: "19261",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6047,
    Code: "19264",
    Name: "Xã An Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6048,
    Code: "19267",
    Name: "Xã Phong Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6049,
    Code: "19270",
    Name: "Xã Cam Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6050,
    Code: "19273",
    Name: "Xã Ngân Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6051,
    Code: "19276",
    Name: "Xã Sơn Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6052,
    Code: "19279",
    Name: "Xã Lộc Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6053,
    Code: "19285",
    Name: "Xã Liên Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6054,
    Code: "19288",
    Name: "Xã Hưng Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6055,
    Code: "19291",
    Name: "Xã Dương Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6056,
    Code: "19294",
    Name: "Xã Tân Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6057,
    Code: "19297",
    Name: "Xã Phú Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6058,
    Code: "19300",
    Name: "Xã Xuân Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6059,
    Code: "19303",
    Name: "Xã Mỹ Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6060,
    Code: "19306",
    Name: "Xã Ngư Thủy ",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6061,
    Code: "19309",
    Name: "Xã Mai Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6062,
    Code: "19312",
    Name: "Xã Sen Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6063,
    Code: "19315",
    Name: "Xã Thái Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6064,
    Code: "19318",
    Name: "Xã Kim Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6065,
    Code: "19321",
    Name: "Xã Trường Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6066,
    Code: "19327",
    Name: "Xã Lâm Thủy",
    LevelUp: "Xã",
    DistrictCode: "3107",
    Active: -1,
  },
  {
    ID: 6067,
    Code: "19009",
    Name: "Phường Ba Đồn",
    LevelUp: "Phường",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6068,
    Code: "19060",
    Name: "Phường Quảng Long",
    LevelUp: "Phường",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6069,
    Code: "19066",
    Name: "Phường Quảng Thọ",
    LevelUp: "Phường",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6070,
    Code: "19069",
    Name: "Xã Quảng Tiên",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6071,
    Code: "19075",
    Name: "Xã Quảng Trung",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6072,
    Code: "19078",
    Name: "Phường Quảng Phong",
    LevelUp: "Phường",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6073,
    Code: "19081",
    Name: "Phường Quảng Thuận",
    LevelUp: "Phường",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6074,
    Code: "19084",
    Name: "Xã Quảng Tân",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6075,
    Code: "19087",
    Name: "Xã Quảng Hải",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6076,
    Code: "19090",
    Name: "Xã Quảng Sơn",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6077,
    Code: "19093",
    Name: "Xã Quảng Lộc",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6078,
    Code: "19096",
    Name: "Xã Quảng Thủy",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6079,
    Code: "19099",
    Name: "Xã Quảng Văn",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6080,
    Code: "19102",
    Name: "Phường Quảng Phúc",
    LevelUp: "Phường",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6081,
    Code: "19105",
    Name: "Xã Quảng Hòa",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6082,
    Code: "19108",
    Name: "Xã Quảng Minh",
    LevelUp: "Xã",
    DistrictCode: "3109",
    Active: -1,
  },
  {
    ID: 6083,
    Code: "19330",
    Name: "Phường Đông Giang",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6084,
    Code: "19333",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6085,
    Code: "19336",
    Name: "Phường Đông Lễ",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6086,
    Code: "19339",
    Name: "Phường Đông Thanh",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6087,
    Code: "19342",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6088,
    Code: "19345",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6089,
    Code: "19348",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6090,
    Code: "19351",
    Name: "Phường Đông Lương",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6091,
    Code: "19354",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "3201",
    Active: -1,
  },
  {
    ID: 6092,
    Code: "19357",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "3202",
    Active: -1,
  },
  {
    ID: 6093,
    Code: "19358",
    Name: "Phường An Đôn",
    LevelUp: "Phường",
    DistrictCode: "3202",
    Active: -1,
  },
  {
    ID: 6094,
    Code: "19360",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "3202",
    Active: -1,
  },
  {
    ID: 6095,
    Code: "19361",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "3202",
    Active: -1,
  },
  {
    ID: 6096,
    Code: "19705",
    Name: "Xã Hải Lệ",
    LevelUp: "Xã",
    DistrictCode: "3202",
    Active: -1,
  },
  {
    ID: 6097,
    Code: "19363",
    Name: "Thị trấn Hồ Xá",
    LevelUp: "Thị trấn",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6098,
    Code: "19366",
    Name: "Thị trấn Bến Quan",
    LevelUp: "Thị trấn",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6099,
    Code: "19369",
    Name: "Xã Vĩnh Thái",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6100,
    Code: "19372",
    Name: "Xã Vĩnh Tú",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6101,
    Code: "19375",
    Name: "Xã Vĩnh Chấp",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6102,
    Code: "19378",
    Name: "Xã Trung Nam",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6103,
    Code: "19384",
    Name: "Xã Kim Thạch",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6104,
    Code: "19387",
    Name: "Xã Vĩnh Long",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6105,
    Code: "19393",
    Name: "Xã Vĩnh Khê",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6106,
    Code: "19396",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6107,
    Code: "19402",
    Name: "Xã Vĩnh Thủy",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6108,
    Code: "19405",
    Name: "Xã Vĩnh Lâm",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6109,
    Code: "19408",
    Name: "Xã Hiền Thành",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6110,
    Code: "19414",
    Name: "Thị trấn Cửa Tùng",
    LevelUp: "Thị trấn",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6111,
    Code: "19417",
    Name: "Xã Vĩnh Hà",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6112,
    Code: "19420",
    Name: "Xã Vĩnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6113,
    Code: "19423",
    Name: "Xã Vĩnh Giang",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6114,
    Code: "19426",
    Name: "Xã Vĩnh Ô",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 6115,
    Code: "19429",
    Name: "Thị trấn Khe Sanh",
    LevelUp: "Thị trấn",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6116,
    Code: "19432",
    Name: "Thị trấn Lao Bảo",
    LevelUp: "Thị trấn",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6117,
    Code: "19435",
    Name: "Xã Hướng Lập",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6118,
    Code: "19438",
    Name: "Xã Hướng Việt",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6119,
    Code: "19441",
    Name: "Xã Hướng Phùng",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6120,
    Code: "19444",
    Name: "Xã Hướng Sơn",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6121,
    Code: "19447",
    Name: "Xã Hướng Linh",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6122,
    Code: "19450",
    Name: "Xã Tân Hợp",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6123,
    Code: "19453",
    Name: "Xã Hướng Tân",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6124,
    Code: "19456",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6125,
    Code: "19459",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6126,
    Code: "19462",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6127,
    Code: "19465",
    Name: "Xã Tân Liên",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6128,
    Code: "19468",
    Name: "Xã Húc",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6129,
    Code: "19471",
    Name: "Xã Thuận",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6130,
    Code: "19474",
    Name: "Xã Hướng Lộc",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6131,
    Code: "19477",
    Name: "Xã Ba Tầng",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6132,
    Code: "19480",
    Name: "Xã Thanh",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6133,
    Code: "19483",
    Name: "Xã  A Dơi",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6134,
    Code: "19489",
    Name: "Xã Lìa",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6135,
    Code: "19492",
    Name: "Xã Xy",
    LevelUp: "Xã",
    DistrictCode: "3208",
    Active: -1,
  },
  {
    ID: 6136,
    Code: "19495",
    Name: "Thị trấn Gio Linh",
    LevelUp: "Thị trấn",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6137,
    Code: "19496",
    Name: "Thị trấn Cửa Việt",
    LevelUp: "Thị trấn",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6138,
    Code: "19498",
    Name: "Xã Trung Giang",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6139,
    Code: "19501",
    Name: "Xã Trung Hải",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6140,
    Code: "19504",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6141,
    Code: "19507",
    Name: "Xã Phong Bình",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6142,
    Code: "19510",
    Name: "Xã Gio Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6143,
    Code: "19519",
    Name: "Xã Gio Hải",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6144,
    Code: "19522",
    Name: "Xã Gio An",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6145,
    Code: "19525",
    Name: "Xã Gio Châu",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6146,
    Code: "19531",
    Name: "Xã Gio Việt",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6147,
    Code: "19534",
    Name: "Xã Linh Trường",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6148,
    Code: "19537",
    Name: "Xã Gio Sơn",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6149,
    Code: "19543",
    Name: "Xã Gio Mai",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6150,
    Code: "19546",
    Name: "Xã Hải Thái",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6151,
    Code: "19549",
    Name: "Xã Linh Hải",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6152,
    Code: "19552",
    Name: "Xã Gio Quang",
    LevelUp: "Xã",
    DistrictCode: "3204",
    Active: -1,
  },
  {
    ID: 6153,
    Code: "19555",
    Name: "Thị trấn Krông Klang",
    LevelUp: "Thị trấn",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6154,
    Code: "19558",
    Name: "Xã Mò Ó",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6155,
    Code: "19561",
    Name: "Xã Hướng Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6156,
    Code: "19564",
    Name: "Xã Đa Krông",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6157,
    Code: "19567",
    Name: "Xã Triệu Nguyên",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6158,
    Code: "19570",
    Name: "Xã Ba Lòng",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6159,
    Code: "19576",
    Name: "Xã Ba Nang",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6160,
    Code: "19579",
    Name: "Xã Tà Long",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6161,
    Code: "19582",
    Name: "Xã Húc Nghì",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6162,
    Code: "19585",
    Name: "Xã A Vao",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6163,
    Code: "19588",
    Name: "Xã Tà Rụt",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6164,
    Code: "19591",
    Name: "Xã A Bung",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6165,
    Code: "19594",
    Name: "Xã A Ngo",
    LevelUp: "Xã",
    DistrictCode: "3209",
    Active: -1,
  },
  {
    ID: 6166,
    Code: "19597",
    Name: "Thị trấn Cam Lộ",
    LevelUp: "Thị trấn",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6167,
    Code: "19600",
    Name: "Xã Cam Tuyền",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6168,
    Code: "19603",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6169,
    Code: "19606",
    Name: "Xã Cam Thủy",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6170,
    Code: "19612",
    Name: "Xã Cam Thành",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6171,
    Code: "19615",
    Name: "Xã Cam Hiếu",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6172,
    Code: "19618",
    Name: "Xã Cam Chính",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6173,
    Code: "19621",
    Name: "Xã Cam Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3205",
    Active: -1,
  },
  {
    ID: 6174,
    Code: "19624",
    Name: "Thị Trấn Ái Tử",
    LevelUp: "Thị trấn",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6175,
    Code: "19627",
    Name: "Xã Triệu An",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6176,
    Code: "19630",
    Name: "Xã Triệu Vân",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6177,
    Code: "19633",
    Name: "Xã Triệu Phước",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6178,
    Code: "19636",
    Name: "Xã Triệu Độ",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6179,
    Code: "19639",
    Name: "Xã Triệu Trạch",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6180,
    Code: "19642",
    Name: "Xã Triệu Thuận",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6181,
    Code: "19645",
    Name: "Xã Triệu Đại",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6182,
    Code: "19648",
    Name: "Xã Triệu Hòa",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6183,
    Code: "19651",
    Name: "Xã Triệu Lăng",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6184,
    Code: "19654",
    Name: "Xã Triệu Sơn",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6185,
    Code: "19657",
    Name: "Xã Triệu Long",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6186,
    Code: "19660",
    Name: "Xã Triệu Tài",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6187,
    Code: "19666",
    Name: "Xã Triệu Trung",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6188,
    Code: "19669",
    Name: "Xã Triệu Ái",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6189,
    Code: "19672",
    Name: "Xã Triệu Thượng",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6190,
    Code: "19675",
    Name: "Xã Triệu Giang",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6191,
    Code: "19678",
    Name: "Xã Triệu Thành",
    LevelUp: "Xã",
    DistrictCode: "3206",
    Active: -1,
  },
  {
    ID: 6192,
    Code: "19681",
    Name: "Thị trấn Diên Sanh",
    LevelUp: "Thị trấn",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6193,
    Code: "19684",
    Name: "Xã Hải An",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6194,
    Code: "19687",
    Name: "Xã Hải Ba",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6195,
    Code: "19693",
    Name: "Xã Hải Quy",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6196,
    Code: "19696",
    Name: "Xã Hải Quế",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6197,
    Code: "19699",
    Name: "Xã Hải Hưng",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6198,
    Code: "19702",
    Name: "Xã Hải Phú",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6199,
    Code: "19708",
    Name: "Xã Hải Thượng",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6200,
    Code: "19711",
    Name: "Xã Hải Dương",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6201,
    Code: "19714",
    Name: "Xã Hải Định",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6202,
    Code: "19717",
    Name: "Xã Hải Lâm",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6203,
    Code: "19726",
    Name: "Xã Hải Phong",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6204,
    Code: "19729",
    Name: "Xã Hải Trường",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6205,
    Code: "19735",
    Name: "Xã Hải Sơn",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6206,
    Code: "19738",
    Name: "Xã Hải Chánh",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6207,
    Code: "19741",
    Name: "Xã Hải Khê",
    LevelUp: "Xã",
    DistrictCode: "3207",
    Active: -1,
  },
  {
    ID: 6208,
    Code: "19744",
    Name: "Phường Phú Thuận",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6209,
    Code: "19747",
    Name: "Phường Phú Bình",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6210,
    Code: "19750",
    Name: "Phường Tây Lộc",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6211,
    Code: "19753",
    Name: "Phường Thuận Lộc",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6212,
    Code: "19756",
    Name: "Phường Phú Hiệp",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6213,
    Code: "19759",
    Name: "Phường Phú Hậu",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6214,
    Code: "19762",
    Name: "Phường Thuận Hòa",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6215,
    Code: "19765",
    Name: "Phường Thuận Thành",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6216,
    Code: "19768",
    Name: "Phường Phú Hòa",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6217,
    Code: "19771",
    Name: "Phường Phú Cát",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6218,
    Code: "19774",
    Name: "Phường Kim Long",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6219,
    Code: "19777",
    Name: "Phường Vĩ Dạ",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6220,
    Code: "19780",
    Name: "Phường Phường Đúc",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6221,
    Code: "19783",
    Name: "Phường Vĩnh Ninh",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6222,
    Code: "19786",
    Name: "Phường Phú Hội",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6223,
    Code: "19789",
    Name: "Phường Phú Nhuận",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6224,
    Code: "19792",
    Name: "Phường Xuân Phú",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6225,
    Code: "19795",
    Name: "Phường Trường An",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6226,
    Code: "19798",
    Name: "Phường Phước Vĩnh",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6227,
    Code: "19801",
    Name: "Phường An Cựu",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6228,
    Code: "19803",
    Name: "Phường An Hòa",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6229,
    Code: "19804",
    Name: "Phường Hương Sơ",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6230,
    Code: "19807",
    Name: "Phường Thuỷ Biều",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6231,
    Code: "19810",
    Name: "Phường Hương Long",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6232,
    Code: "19813",
    Name: "Phường Thuỷ Xuân",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6233,
    Code: "19815",
    Name: "Phường An Đông",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6234,
    Code: "19816",
    Name: "Phường An Tây",
    LevelUp: "Phường",
    DistrictCode: "3301",
    Active: -1,
  },
  {
    ID: 6235,
    Code: "19819",
    Name: "Thị trấn Phong Điền",
    LevelUp: "Thị trấn",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6236,
    Code: "19822",
    Name: "Xã Điền Hương",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6237,
    Code: "19825",
    Name: "Xã Điền Môn",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6238,
    Code: "19828",
    Name: "Xã Điền Lộc",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6239,
    Code: "19831",
    Name: "Xã Phong Bình",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6240,
    Code: "19834",
    Name: "Xã Điền Hòa",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6241,
    Code: "19837",
    Name: "Xã Phong Chương",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6242,
    Code: "19840",
    Name: "Xã Phong Hải",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6243,
    Code: "19843",
    Name: "Xã Điền Hải",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6244,
    Code: "19846",
    Name: "Xã Phong Hòa",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6245,
    Code: "19849",
    Name: "Xã Phong Thu",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6246,
    Code: "19852",
    Name: "Xã Phong Hiền",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6247,
    Code: "19855",
    Name: "Xã Phong Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6248,
    Code: "19858",
    Name: "Xã Phong An",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6249,
    Code: "19861",
    Name: "Xã Phong Xuân",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6250,
    Code: "19864",
    Name: "Xã Phong Sơn",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 6251,
    Code: "19867",
    Name: "Thị trấn Sịa",
    LevelUp: "Thị trấn",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6252,
    Code: "19870",
    Name: "Xã Quảng Thái",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6253,
    Code: "19873",
    Name: "Xã Quảng Ngạn",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6254,
    Code: "19876",
    Name: "Xã Quảng Lợi",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6255,
    Code: "19879",
    Name: "Xã Quảng Công",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6256,
    Code: "19882",
    Name: "Xã Quảng Phước",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6257,
    Code: "19885",
    Name: "Xã Quảng Vinh",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6258,
    Code: "19888",
    Name: "Xã Quảng An",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6259,
    Code: "19891",
    Name: "Xã Quảng Thành",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6260,
    Code: "19894",
    Name: "Xã Quảng Thọ",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6261,
    Code: "19897",
    Name: "Xã Quảng Phú",
    LevelUp: "Xã",
    DistrictCode: "3303",
    Active: -1,
  },
  {
    ID: 6262,
    Code: "19900",
    Name: "Thị trấn Thuận An",
    LevelUp: "Thị trấn",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6263,
    Code: "19903",
    Name: "Xã Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6264,
    Code: "19906",
    Name: "Xã Phú Dương",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6265,
    Code: "19909",
    Name: "Xã Phú Mậu",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6266,
    Code: "19912",
    Name: "Xã Phú An",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6267,
    Code: "19915",
    Name: "Xã Phú Hải",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6268,
    Code: "19918",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6269,
    Code: "19921",
    Name: "Xã Phú Diên",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6270,
    Code: "19924",
    Name: "Xã Phú Thanh",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6271,
    Code: "19927",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6272,
    Code: "19930",
    Name: "Xã Phú Thượng",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6273,
    Code: "19933",
    Name: "Xã Phú Hồ",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6274,
    Code: "19936",
    Name: "Xã Vinh Xuân",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6275,
    Code: "19939",
    Name: "Xã Phú Lương",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6276,
    Code: "19942",
    Name: "Thị trấn Phú Đa",
    LevelUp: "Thị trấn",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6277,
    Code: "19945",
    Name: "Xã Vinh Thanh",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6278,
    Code: "19948",
    Name: "Xã Vinh An",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6279,
    Code: "19954",
    Name: "Xã Phú Gia",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6280,
    Code: "19957",
    Name: "Xã Vinh Hà",
    LevelUp: "Xã",
    DistrictCode: "3305",
    Active: -1,
  },
  {
    ID: 6281,
    Code: "19960",
    Name: "Phường Phú Bài",
    LevelUp: "Phường",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6282,
    Code: "19963",
    Name: "Xã Thủy Vân",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6283,
    Code: "19966",
    Name: "Xã Thủy Thanh",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6284,
    Code: "19969",
    Name: "Phường Thủy Dương",
    LevelUp: "Phường",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6285,
    Code: "19972",
    Name: "Phường Thủy Phương",
    LevelUp: "Phường",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6286,
    Code: "19975",
    Name: "Phường Thủy Châu",
    LevelUp: "Phường",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6287,
    Code: "19978",
    Name: "Phường Thủy Lương",
    LevelUp: "Phường",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6288,
    Code: "19981",
    Name: "Xã Thủy Bằng",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6289,
    Code: "19984",
    Name: "Xã Thủy Tân",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6290,
    Code: "19987",
    Name: "Xã Thủy Phù",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6291,
    Code: "19990",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6292,
    Code: "19993",
    Name: "Xã Dương Hòa",
    LevelUp: "Xã",
    DistrictCode: "3306",
    Active: -1,
  },
  {
    ID: 6293,
    Code: "19996",
    Name: "Phường Tứ Hạ",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6294,
    Code: "19999",
    Name: "Xã Hải Dương",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6295,
    Code: "20002",
    Name: "Xã Hương Phong",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6296,
    Code: "20005",
    Name: "Xã Hương Toàn",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6297,
    Code: "20008",
    Name: "Phường Hương Vân",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6298,
    Code: "20011",
    Name: "Phường Hương Văn",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6299,
    Code: "20014",
    Name: "Xã Hương Vinh",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6300,
    Code: "20017",
    Name: "Phường Hương Xuân",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6301,
    Code: "20020",
    Name: "Phường Hương Chữ",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6302,
    Code: "20023",
    Name: "Phường Hương An",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6303,
    Code: "20026",
    Name: "Xã Hương Bình",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6304,
    Code: "20029",
    Name: "Phường Hương Hồ",
    LevelUp: "Phường",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6305,
    Code: "20032",
    Name: "Xã Hương Thọ",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6306,
    Code: "20035",
    Name: "Xã Bình Tiến",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6307,
    Code: "20041",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "3304",
    Active: -1,
  },
  {
    ID: 6308,
    Code: "20044",
    Name: "Thị trấn A Lưới",
    LevelUp: "Thị trấn",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6309,
    Code: "20047",
    Name: "Xã Hồng Vân",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6310,
    Code: "20050",
    Name: "Xã Hồng Hạ",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6311,
    Code: "20053",
    Name: "Xã Hồng Kim",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6312,
    Code: "20056",
    Name: "Xã Trung Sơn",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6313,
    Code: "20059",
    Name: "Xã Hương Nguyên",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6314,
    Code: "20065",
    Name: "Xã Hồng Bắc",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6315,
    Code: "20068",
    Name: "Xã A Ngo",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6316,
    Code: "20071",
    Name: "Xã Sơn Thủy",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6317,
    Code: "20074",
    Name: "Xã Phú Vinh",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6318,
    Code: "20080",
    Name: "Xã Hương Phong",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6319,
    Code: "20083",
    Name: "Xã Quảng Nhâm",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6320,
    Code: "20086",
    Name: "Xã Hồng Thượng",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6321,
    Code: "20089",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6322,
    Code: "20095",
    Name: "Xã A Roằng",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6323,
    Code: "20098",
    Name: "Xã Đông Sơn",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6324,
    Code: "20101",
    Name: "Xã Lâm Đớt",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6325,
    Code: "20104",
    Name: "Xã Hồng Thủy",
    LevelUp: "Xã",
    DistrictCode: "3309",
    Active: -1,
  },
  {
    ID: 6326,
    Code: "20107",
    Name: "Thị trấn Phú Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6327,
    Code: "20110",
    Name: "Thị trấn Lăng Cô",
    LevelUp: "Thị trấn",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6328,
    Code: "20113",
    Name: "Xã Vinh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6329,
    Code: "20116",
    Name: "Xã Vinh Hưng",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6330,
    Code: "20122",
    Name: "Xã Giang Hải",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6331,
    Code: "20125",
    Name: "Xã Vinh Hiền",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6332,
    Code: "20128",
    Name: "Xã Lộc Bổn",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6333,
    Code: "20131",
    Name: "Xã Lộc Sơn",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6334,
    Code: "20134",
    Name: "Xã Lộc Bình",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6335,
    Code: "20137",
    Name: "Xã Lộc Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6336,
    Code: "20140",
    Name: "Xã Lộc An",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6337,
    Code: "20143",
    Name: "Xã Lộc Điền",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6338,
    Code: "20146",
    Name: "Xã Lộc Thủy",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6339,
    Code: "20149",
    Name: "Xã Lộc Trì",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6340,
    Code: "20152",
    Name: "Xã Lộc Tiến",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6341,
    Code: "20155",
    Name: "Xã Lộc Hòa",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6342,
    Code: "20158",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "3307",
    Active: -1,
  },
  {
    ID: 6343,
    Code: "20161",
    Name: "Thị trấn Khe Tre",
    LevelUp: "Thị trấn",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6344,
    Code: "20164",
    Name: "Xã Hương Phú",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6345,
    Code: "20167",
    Name: "Xã Hương Sơn",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6346,
    Code: "20170",
    Name: "Xã Hương Lộc",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6347,
    Code: "20173",
    Name: "Xã Thượng Quảng",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6348,
    Code: "20179",
    Name: "Xã Hương Xuân",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6349,
    Code: "20182",
    Name: "Xã Hương Hữu",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6350,
    Code: "20185",
    Name: "Xã Thượng Lộ",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6351,
    Code: "20188",
    Name: "Xã Thượng Long",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6352,
    Code: "20191",
    Name: "Xã Thượng Nhật",
    LevelUp: "Xã",
    DistrictCode: "3308",
    Active: -1,
  },
  {
    ID: 6353,
    Code: "20194",
    Name: "Phường Hòa Hiệp Bắc",
    LevelUp: "Phường",
    DistrictCode: "0405",
    Active: -1,
  },
  {
    ID: 6354,
    Code: "20195",
    Name: "Phường Hòa Hiệp Nam",
    LevelUp: "Phường",
    DistrictCode: "0405",
    Active: -1,
  },
  {
    ID: 6355,
    Code: "20197",
    Name: "Phường Hòa Khánh Bắc",
    LevelUp: "Phường",
    DistrictCode: "0405",
    Active: -1,
  },
  {
    ID: 6356,
    Code: "20198",
    Name: "Phường Hòa Khánh Nam",
    LevelUp: "Phường",
    DistrictCode: "0405",
    Active: -1,
  },
  {
    ID: 6357,
    Code: "20200",
    Name: "Phường Hòa Minh",
    LevelUp: "Phường",
    DistrictCode: "0405",
    Active: -1,
  },
  {
    ID: 6358,
    Code: "20203",
    Name: "Phường Tam Thuận",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6359,
    Code: "20206",
    Name: "Phường Thanh Khê Tây",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6360,
    Code: "20207",
    Name: "Phường Thanh Khê Đông",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6361,
    Code: "20209",
    Name: "Phường Xuân Hà",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6362,
    Code: "20212",
    Name: "Phường Tân Chính",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6363,
    Code: "20215",
    Name: "Phường Chính Gián",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6364,
    Code: "20218",
    Name: "Phường Vĩnh Trung",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6365,
    Code: "20221",
    Name: "Phường Thạc Gián",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6366,
    Code: "20224",
    Name: "Phường An Khê",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6367,
    Code: "20225",
    Name: "Phường Hòa Khê",
    LevelUp: "Phường",
    DistrictCode: "0402",
    Active: -1,
  },
  {
    ID: 6368,
    Code: "20227",
    Name: "Phường Thanh Bình",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6369,
    Code: "20230",
    Name: "Phường Thuận Phước",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6370,
    Code: "20233",
    Name: "Phường Thạch Thang",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6371,
    Code: "20236",
    Name: "Phường Hải Châu  I",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6372,
    Code: "20239",
    Name: "Phường Hải Châu II",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6373,
    Code: "20242",
    Name: "Phường Phước Ninh",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6374,
    Code: "20245",
    Name: "Phường Hòa Thuận Tây",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6375,
    Code: "20246",
    Name: "Phường Hòa Thuận Đông",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6376,
    Code: "20248",
    Name: "Phường Nam Dương",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6377,
    Code: "20251",
    Name: "Phường Bình Hiên",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6378,
    Code: "20254",
    Name: "Phường Bình Thuận",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6379,
    Code: "20257",
    Name: "Phường Hòa Cường Bắc",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6380,
    Code: "20258",
    Name: "Phường Hòa Cường Nam",
    LevelUp: "Phường",
    DistrictCode: "0401",
    Active: -1,
  },
  {
    ID: 6381,
    Code: "20263",
    Name: "Phường Thọ Quang",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6382,
    Code: "20266",
    Name: "Phường Nại Hiên Đông",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6383,
    Code: "20269",
    Name: "Phường Mân Thái",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6384,
    Code: "20272",
    Name: "Phường An Hải Bắc",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6385,
    Code: "20275",
    Name: "Phường Phước Mỹ",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6386,
    Code: "20278",
    Name: "Phường An Hải Tây",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6387,
    Code: "20281",
    Name: "Phường An Hải Đông",
    LevelUp: "Phường",
    DistrictCode: "0403",
    Active: -1,
  },
  {
    ID: 6388,
    Code: "20284",
    Name: "Phường Mỹ An",
    LevelUp: "Phường",
    DistrictCode: "0404",
    Active: -1,
  },
  {
    ID: 6389,
    Code: "20285",
    Name: "Phường Khuê Mỹ",
    LevelUp: "Phường",
    DistrictCode: "0404",
    Active: -1,
  },
  {
    ID: 6390,
    Code: "20287",
    Name: "Phường Hoà Quý",
    LevelUp: "Phường",
    DistrictCode: "0404",
    Active: -1,
  },
  {
    ID: 6391,
    Code: "20290",
    Name: "Phường Hoà Hải",
    LevelUp: "Phường",
    DistrictCode: "0404",
    Active: -1,
  },
  {
    ID: 6392,
    Code: "20260",
    Name: "Phường Khuê Trung",
    LevelUp: "Phường",
    DistrictCode: "0407",
    Active: -1,
  },
  {
    ID: 6393,
    Code: "20305",
    Name: "Phường Hòa Phát",
    LevelUp: "Phường",
    DistrictCode: "0407",
    Active: -1,
  },
  {
    ID: 6394,
    Code: "20306",
    Name: "Phường Hòa An",
    LevelUp: "Phường",
    DistrictCode: "0407",
    Active: -1,
  },
  {
    ID: 6395,
    Code: "20311",
    Name: "Phường Hòa Thọ Tây",
    LevelUp: "Phường",
    DistrictCode: "0407",
    Active: -1,
  },
  {
    ID: 6396,
    Code: "20312",
    Name: "Phường Hòa Thọ Đông",
    LevelUp: "Phường",
    DistrictCode: "0407",
    Active: -1,
  },
  {
    ID: 6397,
    Code: "20314",
    Name: "Phường Hòa Xuân",
    LevelUp: "Phường",
    DistrictCode: "0407",
    Active: -1,
  },
  {
    ID: 6398,
    Code: "20293",
    Name: "Xã Hòa Bắc",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6399,
    Code: "20296",
    Name: "Xã Hòa Liên",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6400,
    Code: "20299",
    Name: "Xã Hòa Ninh",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6401,
    Code: "20302",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6402,
    Code: "20308",
    Name: "Xã Hòa Nhơn",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6403,
    Code: "20317",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6404,
    Code: "20320",
    Name: "Xã Hòa Phong",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6405,
    Code: "20323",
    Name: "Xã Hòa Châu",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6406,
    Code: "20326",
    Name: "Xã Hòa Tiến",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6407,
    Code: "20329",
    Name: "Xã Hòa Phước",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6408,
    Code: "20332",
    Name: "Xã Hòa Khương",
    LevelUp: "Xã",
    DistrictCode: "0406",
    Active: -1,
  },
  {
    ID: 6409,
    Code: "20335",
    Name: "Phường Tân Thạnh",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6410,
    Code: "20338",
    Name: "Phường Phước Hòa",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6411,
    Code: "20341",
    Name: "Phường An Mỹ",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6412,
    Code: "20344",
    Name: "Phường Hòa Hương",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6413,
    Code: "20347",
    Name: "Phường An Xuân",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6414,
    Code: "20350",
    Name: "Phường An Sơn",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6415,
    Code: "20353",
    Name: "Phường Trường Xuân",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6416,
    Code: "20356",
    Name: "Phường An Phú",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6417,
    Code: "20359",
    Name: "Xã Tam Thanh",
    LevelUp: "Xã",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6418,
    Code: "20362",
    Name: "Xã Tam Thăng",
    LevelUp: "Xã",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6419,
    Code: "20371",
    Name: "Xã Tam Phú",
    LevelUp: "Xã",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6420,
    Code: "20375",
    Name: "Phường Hoà Thuận",
    LevelUp: "Phường",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6421,
    Code: "20389",
    Name: "Xã Tam Ngọc",
    LevelUp: "Xã",
    DistrictCode: "3401",
    Active: -1,
  },
  {
    ID: 6422,
    Code: "20398",
    Name: "Phường Minh An",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6423,
    Code: "20401",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6424,
    Code: "20404",
    Name: "Phường Cẩm Phô",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6425,
    Code: "20407",
    Name: "Phường Thanh Hà",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6426,
    Code: "20410",
    Name: "Phường Sơn Phong",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6427,
    Code: "20413",
    Name: "Phường Cẩm Châu",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6428,
    Code: "20416",
    Name: "Phường Cửa Đại",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6429,
    Code: "20419",
    Name: "Phường Cẩm An",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6430,
    Code: "20422",
    Name: "Xã Cẩm Hà",
    LevelUp: "Xã",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6431,
    Code: "20425",
    Name: "Xã Cẩm Kim",
    LevelUp: "Xã",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6432,
    Code: "20428",
    Name: "Phường Cẩm Nam",
    LevelUp: "Phường",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6433,
    Code: "20431",
    Name: "Xã Cẩm Thanh",
    LevelUp: "Xã",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6434,
    Code: "20434",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3402",
    Active: -1,
  },
  {
    ID: 6435,
    Code: "20437",
    Name: "Xã Ch'ơm",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6436,
    Code: "20440",
    Name: "Xã Ga Ri",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6437,
    Code: "20443",
    Name: "Xã A Xan",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6438,
    Code: "20446",
    Name: "Xã Tr'Hy",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6439,
    Code: "20449",
    Name: "Xã Lăng",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6440,
    Code: "20452",
    Name: "Xã A Nông",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6441,
    Code: "20455",
    Name: "Xã A Tiêng",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6442,
    Code: "20458",
    Name: "Xã Bha Lê",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6443,
    Code: "20461",
    Name: "Xã A Vương",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6444,
    Code: "20464",
    Name: "Xã Dang",
    LevelUp: "Xã",
    DistrictCode: "3416",
    Active: -1,
  },
  {
    ID: 6445,
    Code: "20467",
    Name: "Thị trấn P Rao",
    LevelUp: "Thị trấn",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6446,
    Code: "20470",
    Name: "Xã Tà Lu",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6447,
    Code: "20473",
    Name: "Xã Sông Kôn",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6448,
    Code: "20476",
    Name: "Xã Jơ Ngây",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6449,
    Code: "20479",
    Name: "Xã A Ting",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6450,
    Code: "20482",
    Name: "Xã  Tư",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6451,
    Code: "20485",
    Name: "Xã Ba",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6452,
    Code: "20488",
    Name: "Xã A Rooi",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6453,
    Code: "20491",
    Name: "Xã Za Hung",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6454,
    Code: "20494",
    Name: "Xã Mà Cooi",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6455,
    Code: "20497",
    Name: "Xã Ka Dăng",
    LevelUp: "Xã",
    DistrictCode: "3412",
    Active: -1,
  },
  {
    ID: 6456,
    Code: "20500",
    Name: "Thị Trấn Ái Nghĩa",
    LevelUp: "Thị trấn",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6457,
    Code: "20503",
    Name: "Xã Đại Sơn",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6458,
    Code: "20506",
    Name: "Xã Đại Lãnh",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6459,
    Code: "20509",
    Name: "Xã Đại Hưng",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6460,
    Code: "20512",
    Name: "Xã Đại Hồng",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6461,
    Code: "20515",
    Name: "Xã Đại Đồng",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6462,
    Code: "20518",
    Name: "Xã Đại Quang",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6463,
    Code: "20521",
    Name: "Xã Đại Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6464,
    Code: "20524",
    Name: "Xã Đại Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6465,
    Code: "20527",
    Name: "Xã Đại Thạnh",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6466,
    Code: "20530",
    Name: "Xã Đại Chánh",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6467,
    Code: "20533",
    Name: "Xã Đại Tân",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6468,
    Code: "20536",
    Name: "Xã Đại Phong",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6469,
    Code: "20539",
    Name: "Xã Đại Minh",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6470,
    Code: "20542",
    Name: "Xã Đại Thắng",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6471,
    Code: "20545",
    Name: "Xã Đại Cường",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6472,
    Code: "20547",
    Name: "Xã Đại An",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6473,
    Code: "20548",
    Name: "Xã Đại Hòa",
    LevelUp: "Xã",
    DistrictCode: "3405",
    Active: -1,
  },
  {
    ID: 6474,
    Code: "20551",
    Name: "Phường Vĩnh Điện",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6475,
    Code: "20554",
    Name: "Xã Điện Tiến",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6476,
    Code: "20557",
    Name: "Xã Điện Hòa",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6477,
    Code: "20560",
    Name: "Xã Điện Thắng Bắc",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6478,
    Code: "20561",
    Name: "Xã Điện Thắng Trung",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6479,
    Code: "20562",
    Name: "Xã Điện Thắng Nam",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6480,
    Code: "20563",
    Name: "Phường Điện Ngọc",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6481,
    Code: "20566",
    Name: "Xã Điện Hồng",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6482,
    Code: "20569",
    Name: "Xã Điện Thọ",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6483,
    Code: "20572",
    Name: "Xã Điện Phước",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6484,
    Code: "20575",
    Name: "Phường Điện An",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6485,
    Code: "20578",
    Name: "Phường Điện Nam Bắc",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6486,
    Code: "20579",
    Name: "Phường Điện Nam Trung",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6487,
    Code: "20580",
    Name: "Phường Điện Nam Đông",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6488,
    Code: "20581",
    Name: "Phường Điện Dương",
    LevelUp: "Phường",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6489,
    Code: "20584",
    Name: "Xã Điện Quang",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6490,
    Code: "20587",
    Name: "Xã Điện Trung",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6491,
    Code: "20590",
    Name: "Xã Điện Phong",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6492,
    Code: "20593",
    Name: "Xã Điện Minh",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6493,
    Code: "20596",
    Name: "Xã Điện Phương",
    LevelUp: "Xã",
    DistrictCode: "3404",
    Active: -1,
  },
  {
    ID: 6494,
    Code: "20599",
    Name: "Thị trấn Nam Phước",
    LevelUp: "Thị trấn",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6495,
    Code: "20602",
    Name: "Xã Duy Thu",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6496,
    Code: "20605",
    Name: "Xã Duy Phú",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6497,
    Code: "20608",
    Name: "Xã Duy Tân",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6498,
    Code: "20611",
    Name: "Xã Duy Hòa",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6499,
    Code: "20614",
    Name: "Xã Duy Châu",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6500,
    Code: "20617",
    Name: "Xã Duy Trinh",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6501,
    Code: "20620",
    Name: "Xã Duy Sơn",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6502,
    Code: "20623",
    Name: "Xã Duy Trung",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6503,
    Code: "20626",
    Name: "Xã Duy Phước",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6504,
    Code: "20629",
    Name: "Xã Duy Thành",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6505,
    Code: "20632",
    Name: "Xã Duy Vinh",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6506,
    Code: "20635",
    Name: "Xã Duy Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6507,
    Code: "20638",
    Name: "Xã Duy Hải",
    LevelUp: "Xã",
    DistrictCode: "3403",
    Active: -1,
  },
  {
    ID: 6508,
    Code: "20641",
    Name: "Thị trấn Đông Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6509,
    Code: "20644",
    Name: "Xã Quế Xuân 1",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6510,
    Code: "20647",
    Name: "Xã Quế Xuân 2",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6511,
    Code: "20650",
    Name: "Xã Quế Phú",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6512,
    Code: "20651",
    Name: "Thị trấn Hương An",
    LevelUp: "Thị trấn",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6513,
    Code: "20659",
    Name: "Xã Quế Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6514,
    Code: "20662",
    Name: "Xã Quế Thuận",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6515,
    Code: "20665",
    Name: "Xã Quế Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6516,
    Code: "20677",
    Name: "Xã Quế Long",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6517,
    Code: "20680",
    Name: "Xã Quế Châu",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6518,
    Code: "20683",
    Name: "Xã Quế Phong",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6519,
    Code: "20686",
    Name: "Xã Quế An",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6520,
    Code: "20689",
    Name: "Xã Quế Minh",
    LevelUp: "Xã",
    DistrictCode: "3406",
    Active: -1,
  },
  {
    ID: 6521,
    Code: "20695",
    Name: "Thị trấn Thạnh Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6522,
    Code: "20698",
    Name: "Xã Laêê",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6523,
    Code: "20699",
    Name: "Xã Chơ Chun",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6524,
    Code: "20701",
    Name: "Xã Zuôich",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6525,
    Code: "20702",
    Name: "Xã Tà Pơơ",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6526,
    Code: "20704",
    Name: "Xã La Dêê",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6527,
    Code: "20705",
    Name: "Xã Đắc Tôi",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6528,
    Code: "20707",
    Name: "Xã Chà Vàl",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6529,
    Code: "20710",
    Name: "Xã Tà Bhinh",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6530,
    Code: "20713",
    Name: "Xã Cà Dy",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6531,
    Code: "20716",
    Name: "Xã Đắc Pre",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6532,
    Code: "20719",
    Name: "Xã Đắc Pring",
    LevelUp: "Xã",
    DistrictCode: "3413",
    Active: -1,
  },
  {
    ID: 6533,
    Code: "20722",
    Name: "Thị trấn Khâm Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6534,
    Code: "20725",
    Name: "Xã Phước Xuân",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6535,
    Code: "20728",
    Name: "Xã Phước Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6536,
    Code: "20729",
    Name: "Xã Phước Hoà",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6537,
    Code: "20731",
    Name: "Xã Phước Đức",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6538,
    Code: "20734",
    Name: "Xã Phước Năng",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6539,
    Code: "20737",
    Name: "Xã Phước Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6540,
    Code: "20740",
    Name: "Xã Phước Chánh",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6541,
    Code: "20743",
    Name: "Xã Phước Công",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6542,
    Code: "20746",
    Name: "Xã Phước Kim",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6543,
    Code: "20749",
    Name: "Xã Phước Lộc",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6544,
    Code: "20752",
    Name: "Xã Phước Thành",
    LevelUp: "Xã",
    DistrictCode: "3414",
    Active: -1,
  },
  {
    ID: 6545,
    Code: "20758",
    Name: "Xã Hiệp Hòa",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6546,
    Code: "20761",
    Name: "Xã Hiệp Thuận",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6547,
    Code: "20764",
    Name: "Xã Quế Thọ",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6548,
    Code: "20767",
    Name: "Xã Bình Lâm",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6549,
    Code: "20770",
    Name: "Xã Sông Trà",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6550,
    Code: "20773",
    Name: "Xã Phước Trà",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6551,
    Code: "20776",
    Name: "Xã Phước Gia",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6552,
    Code: "20779",
    Name: "Thị trấn Tân Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6553,
    Code: "20782",
    Name: "Xã Quế Lưu",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6554,
    Code: "20785",
    Name: "Xã Thăng Phước",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6555,
    Code: "20788",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "3407",
    Active: -1,
  },
  {
    ID: 6556,
    Code: "20791",
    Name: "Thị trấn Hà Lam",
    LevelUp: "Thị trấn",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6557,
    Code: "20794",
    Name: "Xã Bình Dương",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6558,
    Code: "20797",
    Name: "Xã Bình Giang",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6559,
    Code: "20800",
    Name: "Xã Bình Nguyên",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6560,
    Code: "20803",
    Name: "Xã Bình Phục",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6561,
    Code: "20806",
    Name: "Xã Bình Triều",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6562,
    Code: "20809",
    Name: "Xã Bình Đào",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6563,
    Code: "20812",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6564,
    Code: "20815",
    Name: "Xã Bình Lãnh",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6565,
    Code: "20818",
    Name: "Xã Bình Trị",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6566,
    Code: "20821",
    Name: "Xã Bình Định Bắc",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6567,
    Code: "20822",
    Name: "Xã Bình Định Nam",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6568,
    Code: "20824",
    Name: "Xã Bình Quý",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6569,
    Code: "20827",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6570,
    Code: "20830",
    Name: "Xã Bình Chánh",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6571,
    Code: "20833",
    Name: "Xã Bình Tú",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6572,
    Code: "20836",
    Name: "Xã Bình Sa",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6573,
    Code: "20839",
    Name: "Xã Bình Hải",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6574,
    Code: "20842",
    Name: "Xã Bình Quế",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6575,
    Code: "20845",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6576,
    Code: "20848",
    Name: "Xã Bình Trung",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6577,
    Code: "20851",
    Name: "Xã Bình Nam",
    LevelUp: "Xã",
    DistrictCode: "3408",
    Active: -1,
  },
  {
    ID: 6578,
    Code: "20854",
    Name: "Thị trấn Tiên Kỳ",
    LevelUp: "Thị trấn",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6579,
    Code: "20857",
    Name: "Xã Tiên Sơn",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6580,
    Code: "20860",
    Name: "Xã Tiên Hà",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6581,
    Code: "20863",
    Name: "Xã Tiên Cẩm",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6582,
    Code: "20866",
    Name: "Xã Tiên Châu",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6583,
    Code: "20869",
    Name: "Xã Tiên Lãnh",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6584,
    Code: "20872",
    Name: "Xã Tiên Ngọc",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6585,
    Code: "20875",
    Name: "Xã Tiên Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6586,
    Code: "20878",
    Name: "Xã Tiên Cảnh",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6587,
    Code: "20881",
    Name: "Xã Tiên Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6588,
    Code: "20884",
    Name: "Xã Tiên Phong",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6589,
    Code: "20887",
    Name: "Xã Tiên Thọ",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6590,
    Code: "20890",
    Name: "Xã Tiên An",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6591,
    Code: "20893",
    Name: "Xã Tiên Lộc",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6592,
    Code: "20896",
    Name: "Xã Tiên Lập",
    LevelUp: "Xã",
    DistrictCode: "3410",
    Active: -1,
  },
  {
    ID: 6593,
    Code: "20899",
    Name: "Thị trấn Trà My",
    LevelUp: "Thị trấn",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6594,
    Code: "20900",
    Name: "Xã Trà Sơn",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6595,
    Code: "20902",
    Name: "Xã Trà Kót",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6596,
    Code: "20905",
    Name: "Xã Trà Nú",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6597,
    Code: "20908",
    Name: "Xã Trà Đông",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6598,
    Code: "20911",
    Name: "Xã Trà Dương",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6599,
    Code: "20914",
    Name: "Xã Trà Giang",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6600,
    Code: "20917",
    Name: "Xã Trà Bui",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6601,
    Code: "20920",
    Name: "Xã Trà Đốc",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6602,
    Code: "20923",
    Name: "Xã Trà Tân",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6603,
    Code: "20926",
    Name: "Xã Trà Giác",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6604,
    Code: "20929",
    Name: "Xã Trà Giáp",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6605,
    Code: "20932",
    Name: "Xã Trà Ka",
    LevelUp: "Xã",
    DistrictCode: "3411",
    Active: -1,
  },
  {
    ID: 6606,
    Code: "20935",
    Name: "Xã Trà Leng",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6607,
    Code: "20938",
    Name: "Xã Trà Dơn",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6608,
    Code: "20941",
    Name: "Xã Trà Tập",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6609,
    Code: "20944",
    Name: "Xã Trà Mai",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6610,
    Code: "20947",
    Name: "Xã Trà Cang",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6611,
    Code: "20950",
    Name: "Xã Trà Linh",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6612,
    Code: "20953",
    Name: "Xã Trà Nam",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6613,
    Code: "20956",
    Name: "Xã Trà Don",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6614,
    Code: "20959",
    Name: "Xã Trà Vân",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6615,
    Code: "20962",
    Name: "Xã Trà Vinh",
    LevelUp: "Xã",
    DistrictCode: "3415",
    Active: -1,
  },
  {
    ID: 6616,
    Code: "20965",
    Name: "Thị trấn Núi Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6617,
    Code: "20968",
    Name: "Xã Tam Xuân I",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6618,
    Code: "20971",
    Name: "Xã Tam Xuân II",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6619,
    Code: "20974",
    Name: "Xã Tam Tiến",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6620,
    Code: "20977",
    Name: "Xã Tam Sơn",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6621,
    Code: "20980",
    Name: "Xã Tam Thạnh",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6622,
    Code: "20983",
    Name: "Xã Tam Anh Bắc",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6623,
    Code: "20984",
    Name: "Xã Tam Anh Nam",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6624,
    Code: "20986",
    Name: "Xã Tam Hòa",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6625,
    Code: "20989",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6626,
    Code: "20992",
    Name: "Xã Tam Hải",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6627,
    Code: "20995",
    Name: "Xã Tam Giang",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6628,
    Code: "20998",
    Name: "Xã Tam Quang",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6629,
    Code: "21001",
    Name: "Xã Tam Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6630,
    Code: "21004",
    Name: "Xã Tam Mỹ Tây",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6631,
    Code: "21005",
    Name: "Xã Tam Mỹ Đông",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6632,
    Code: "21007",
    Name: "Xã Tam Trà",
    LevelUp: "Xã",
    DistrictCode: "3409",
    Active: -1,
  },
  {
    ID: 6633,
    Code: "20364",
    Name: "Thị trấn Phú Thịnh",
    LevelUp: "Thị trấn",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6634,
    Code: "20365",
    Name: "Xã Tam Thành",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6635,
    Code: "20368",
    Name: "Xã Tam An",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6636,
    Code: "20374",
    Name: "Xã Tam Đàn",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6637,
    Code: "20377",
    Name: "Xã Tam Lộc",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6638,
    Code: "20380",
    Name: "Xã Tam Phước",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6639,
    Code: "20383",
    Name: "Xã Tam Vinh",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6640,
    Code: "20386",
    Name: "Xã Tam Thái",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6641,
    Code: "20387",
    Name: "Xã Tam Đại",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6642,
    Code: "20392",
    Name: "Xã Tam Dân",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6643,
    Code: "20395",
    Name: "Xã Tam Lãnh",
    LevelUp: "Xã",
    DistrictCode: "3417",
    Active: -1,
  },
  {
    ID: 6644,
    Code: "20656",
    Name: "Xã Quế Trung",
    LevelUp: "Xã",
    DistrictCode: "3418",
    Active: -1,
  },
  {
    ID: 6645,
    Code: "20668",
    Name: "Xã Ninh Phước",
    LevelUp: "Xã",
    DistrictCode: "3418",
    Active: -1,
  },
  {
    ID: 6646,
    Code: "20669",
    Name: "Xã Phước Ninh",
    LevelUp: "Xã",
    DistrictCode: "3418",
    Active: -1,
  },
  {
    ID: 6647,
    Code: "20671",
    Name: "Xã Quế Lộc",
    LevelUp: "Xã",
    DistrictCode: "3418",
    Active: -1,
  },
  {
    ID: 6648,
    Code: "20672",
    Name: "Xã Sơn Viên",
    LevelUp: "Xã",
    DistrictCode: "3418",
    Active: -1,
  },
  {
    ID: 6649,
    Code: "20692",
    Name: "Xã Quế Lâm",
    LevelUp: "Xã",
    DistrictCode: "3418",
    Active: -1,
  },
  {
    ID: 6650,
    Code: "21010",
    Name: "Phường Lê Hồng Phong",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6651,
    Code: "21013",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6652,
    Code: "21016",
    Name: "Phường Quảng Phú",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6653,
    Code: "21019",
    Name: "Phường Nghĩa Chánh",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6654,
    Code: "21022",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6655,
    Code: "21025",
    Name: "Phường Nguyễn Nghiêm",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6656,
    Code: "21028",
    Name: "Phường Nghĩa Lộ",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6657,
    Code: "21031",
    Name: "Phường Chánh Lộ",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6658,
    Code: "21034",
    Name: "Xã Nghĩa Dũng",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6659,
    Code: "21037",
    Name: "Xã Nghĩa Dõng",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6660,
    Code: "21172",
    Name: "Phường Trương Quang Trọng",
    LevelUp: "Phường",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6661,
    Code: "21187",
    Name: "Xã Tịnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6662,
    Code: "21190",
    Name: "Xã Tịnh Kỳ",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6663,
    Code: "21199",
    Name: "Xã Tịnh Thiện",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6664,
    Code: "21202",
    Name: "Xã Tịnh Ấn Đông",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6665,
    Code: "21208",
    Name: "Xã Tịnh Châu",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6666,
    Code: "21211",
    Name: "Xã Tịnh Khê",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6667,
    Code: "21214",
    Name: "Xã Tịnh Long",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6668,
    Code: "21223",
    Name: "Xã Tịnh Ấn Tây",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6669,
    Code: "21232",
    Name: "Xã Tịnh An",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6670,
    Code: "21253",
    Name: "Xã Nghĩa Phú",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6671,
    Code: "21256",
    Name: "Xã Nghĩa Hà",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6672,
    Code: "21262",
    Name: "Xã Nghĩa An",
    LevelUp: "Xã",
    DistrictCode: "3501",
    Active: -1,
  },
  {
    ID: 6673,
    Code: "21040",
    Name: "Thị Trấn Châu Ổ",
    LevelUp: "Thị trấn",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6674,
    Code: "21043",
    Name: "Xã Bình Thuận",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6675,
    Code: "21046",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6676,
    Code: "21049",
    Name: "Xã Bình Đông",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6677,
    Code: "21052",
    Name: "Xã Bình Chánh",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6678,
    Code: "21055",
    Name: "Xã Bình Nguyên",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6679,
    Code: "21058",
    Name: "Xã Bình Khương",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6680,
    Code: "21061",
    Name: "Xã Bình Trị",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6681,
    Code: "21064",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6682,
    Code: "21067",
    Name: "Xã Bình Hải",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6683,
    Code: "21070",
    Name: "Xã Bình Dương",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6684,
    Code: "21073",
    Name: "Xã Bình Phước",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6685,
    Code: "21079",
    Name: "Xã Bình Hòa",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6686,
    Code: "21082",
    Name: "Xã Bình Trung",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6687,
    Code: "21085",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6688,
    Code: "21088",
    Name: "Xã Bình Long",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6689,
    Code: "21091",
    Name: "Xã Bình Thanh ",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6690,
    Code: "21100",
    Name: "Xã Bình Chương",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6691,
    Code: "21103",
    Name: "Xã Bình Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6692,
    Code: "21106",
    Name: "Xã Bình Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6693,
    Code: "21109",
    Name: "Xã Bình Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6694,
    Code: "21112",
    Name: "Xã Bình Châu",
    LevelUp: "Xã",
    DistrictCode: "3503",
    Active: -1,
  },
  {
    ID: 6695,
    Code: "21115",
    Name: "Thị trấn Trà Xuân",
    LevelUp: "Thị trấn",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6696,
    Code: "21118",
    Name: "Xã Trà Giang",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6697,
    Code: "21121",
    Name: "Xã Trà Thủy",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6698,
    Code: "21124",
    Name: "Xã Trà Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6699,
    Code: "21127",
    Name: "Xã Trà Bình",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6700,
    Code: "21130",
    Name: "Xã Trà Phú",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6701,
    Code: "21133",
    Name: "Xã Trà Lâm",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6702,
    Code: "21136",
    Name: "Xã Trà Tân",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6703,
    Code: "21139",
    Name: "Xã Trà Sơn",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6704,
    Code: "21142",
    Name: "Xã Trà Bùi",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6705,
    Code: "21145",
    Name: "Xã Trà Thanh",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6706,
    Code: "21148",
    Name: "Xã Sơn Trà",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6707,
    Code: "21154",
    Name: "Xã Trà Phong",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6708,
    Code: "21157",
    Name: "Xã Hương Trà",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6709,
    Code: "21163",
    Name: "Xã Trà Xinh",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6710,
    Code: "21166",
    Name: "Xã Trà Tây",
    LevelUp: "Xã",
    DistrictCode: "3504",
    Active: -1,
  },
  {
    ID: 6711,
    Code: "21175",
    Name: "Xã Tịnh Thọ",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6712,
    Code: "21178",
    Name: "Xã Tịnh Trà",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6713,
    Code: "21181",
    Name: "Xã Tịnh Phong",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6714,
    Code: "21184",
    Name: "Xã Tịnh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6715,
    Code: "21193",
    Name: "Xã Tịnh Bình",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6716,
    Code: "21196",
    Name: "Xã Tịnh Đông",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6717,
    Code: "21205",
    Name: "Xã Tịnh Bắc",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6718,
    Code: "21217",
    Name: "Xã Tịnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6719,
    Code: "21220",
    Name: "Xã Tịnh Hà",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6720,
    Code: "21226",
    Name: "Xã Tịnh Giang",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6721,
    Code: "21229",
    Name: "Xã Tịnh Minh",
    LevelUp: "Xã",
    DistrictCode: "3505",
    Active: -1,
  },
  {
    ID: 6722,
    Code: "21235",
    Name: "Thị trấn La Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6723,
    Code: "21238",
    Name: "Thị trấn Sông Vệ",
    LevelUp: "Thị trấn",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6724,
    Code: "21241",
    Name: "Xã Nghĩa Lâm",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6725,
    Code: "21244",
    Name: "Xã Nghĩa Thắng",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6726,
    Code: "21247",
    Name: "Xã Nghĩa Thuận",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6727,
    Code: "21250",
    Name: "Xã Nghĩa Kỳ",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6728,
    Code: "21259",
    Name: "Xã Nghĩa Sơn",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6729,
    Code: "21268",
    Name: "Xã Nghĩa Hòa",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6730,
    Code: "21271",
    Name: "Xã Nghĩa Điền",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6731,
    Code: "21274",
    Name: "Xã Nghĩa Thương",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6732,
    Code: "21277",
    Name: "Xã Nghĩa Trung",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6733,
    Code: "21280",
    Name: "Xã Nghĩa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6734,
    Code: "21283",
    Name: "Xã Nghĩa Phương",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6735,
    Code: "21286",
    Name: "Xã Nghĩa Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3507",
    Active: -1,
  },
  {
    ID: 6736,
    Code: "21289",
    Name: "Thị trấn Di Lăng",
    LevelUp: "Thị trấn",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6737,
    Code: "21292",
    Name: "Xã Sơn Hạ",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6738,
    Code: "21295",
    Name: "Xã Sơn Thành",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6739,
    Code: "21298",
    Name: "Xã Sơn Nham",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6740,
    Code: "21301",
    Name: "Xã Sơn Bao",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6741,
    Code: "21304",
    Name: "Xã Sơn Linh",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6742,
    Code: "21307",
    Name: "Xã Sơn Giang",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6743,
    Code: "21310",
    Name: "Xã Sơn Trung",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6744,
    Code: "21313",
    Name: "Xã Sơn Thượng",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6745,
    Code: "21316",
    Name: "Xã Sơn Cao",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6746,
    Code: "21319",
    Name: "Xã Sơn Hải",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6747,
    Code: "21322",
    Name: "Xã Sơn Thủy",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6748,
    Code: "21325",
    Name: "Xã Sơn Kỳ",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6749,
    Code: "21328",
    Name: "Xã Sơn Ba",
    LevelUp: "Xã",
    DistrictCode: "3506",
    Active: -1,
  },
  {
    ID: 6750,
    Code: "21331",
    Name: "Xã Sơn Bua",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6751,
    Code: "21334",
    Name: "Xã Sơn Mùa",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6752,
    Code: "21335",
    Name: "Xã Sơn Liên",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6753,
    Code: "21337",
    Name: "Xã Sơn Tân",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6754,
    Code: "21338",
    Name: "Xã Sơn Màu",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6755,
    Code: "21340",
    Name: "Xã Sơn Dung",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6756,
    Code: "21341",
    Name: "Xã Sơn Long",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6757,
    Code: "21343",
    Name: "Xã Sơn Tinh",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6758,
    Code: "21346",
    Name: "Xã Sơn Lập",
    LevelUp: "Xã",
    DistrictCode: "3513",
    Active: -1,
  },
  {
    ID: 6759,
    Code: "21349",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "3509",
    Active: -1,
  },
  {
    ID: 6760,
    Code: "21352",
    Name: "Xã Long Mai",
    LevelUp: "Xã",
    DistrictCode: "3509",
    Active: -1,
  },
  {
    ID: 6761,
    Code: "21355",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "3509",
    Active: -1,
  },
  {
    ID: 6762,
    Code: "21358",
    Name: "Xã Long Môn",
    LevelUp: "Xã",
    DistrictCode: "3509",
    Active: -1,
  },
  {
    ID: 6763,
    Code: "21361",
    Name: "Xã Long Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3509",
    Active: -1,
  },
  {
    ID: 6764,
    Code: "21364",
    Name: "Thị trấn Chợ Chùa",
    LevelUp: "Thị trấn",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6765,
    Code: "21367",
    Name: "Xã Hành Thuận",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6766,
    Code: "21370",
    Name: "Xã Hành Dũng",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6767,
    Code: "21373",
    Name: "Xã Hành Trung",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6768,
    Code: "21376",
    Name: "Xã Hành Nhân",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6769,
    Code: "21379",
    Name: "Xã Hành Đức",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6770,
    Code: "21382",
    Name: "Xã Hành Minh",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6771,
    Code: "21385",
    Name: "Xã Hành Phước",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6772,
    Code: "21388",
    Name: "Xã Hành Thiện",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6773,
    Code: "21391",
    Name: "Xã Hành Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6774,
    Code: "21394",
    Name: "Xã Hành Tín Tây",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6775,
    Code: "21397",
    Name: "Xã Hành Tín  Đông",
    LevelUp: "Xã",
    DistrictCode: "3508",
    Active: -1,
  },
  {
    ID: 6776,
    Code: "21400",
    Name: "Thị trấn Mộ Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6777,
    Code: "21403",
    Name: "Xã Đức Lợi",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6778,
    Code: "21406",
    Name: "Xã Đức Thắng",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6779,
    Code: "21409",
    Name: "Xã Đức Nhuận",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6780,
    Code: "21412",
    Name: "Xã Đức Chánh",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6781,
    Code: "21415",
    Name: "Xã Đức Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6782,
    Code: "21418",
    Name: "Xã Đức Minh",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6783,
    Code: "21421",
    Name: "Xã Đức Thạnh",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6784,
    Code: "21424",
    Name: "Xã Đức Hòa",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6785,
    Code: "21427",
    Name: "Xã Đức Tân",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6786,
    Code: "21430",
    Name: "Xã Đức Phú",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6787,
    Code: "21433",
    Name: "Xã Đức Phong",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6788,
    Code: "21436",
    Name: "Xã Đức Lân",
    LevelUp: "Xã",
    DistrictCode: "3510",
    Active: -1,
  },
  {
    ID: 6789,
    Code: "21439",
    Name: "Phường Nguyễn Nghiêm",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6790,
    Code: "21442",
    Name: "Xã Phổ An",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6791,
    Code: "21445",
    Name: "Xã Phổ Phong",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6792,
    Code: "21448",
    Name: "Xã Phổ Thuận",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6793,
    Code: "21451",
    Name: "Phường Phổ Văn",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6794,
    Code: "21454",
    Name: "Phường Phổ Quang",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6795,
    Code: "21457",
    Name: "Xã Phổ Nhơn",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6796,
    Code: "21460",
    Name: "Phường Phổ Ninh",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6797,
    Code: "21463",
    Name: "Phường Phổ Minh",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6798,
    Code: "21466",
    Name: "Phường Phổ Vinh",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6799,
    Code: "21469",
    Name: "Phường Phổ Hòa",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6800,
    Code: "21472",
    Name: "Xã Phổ Cường",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6801,
    Code: "21475",
    Name: "Xã Phổ Khánh",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6802,
    Code: "21478",
    Name: "Phường Phổ Thạnh",
    LevelUp: "Phường",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6803,
    Code: "21481",
    Name: "Xã Phổ Châu",
    LevelUp: "Xã",
    DistrictCode: "3511",
    Active: -1,
  },
  {
    ID: 6804,
    Code: "21484",
    Name: "Thị trấn Ba Tơ",
    LevelUp: "Thị trấn",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6805,
    Code: "21487",
    Name: "Xã Ba Điền",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6806,
    Code: "21490",
    Name: "Xã Ba Vinh",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6807,
    Code: "21493",
    Name: "Xã Ba Thành",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6808,
    Code: "21496",
    Name: "Xã Ba Động",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6809,
    Code: "21499",
    Name: "Xã Ba Dinh",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6810,
    Code: "21500",
    Name: "Xã Ba Giang",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6811,
    Code: "21502",
    Name: "Xã Ba Liên",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6812,
    Code: "21505",
    Name: "Xã Ba Ngạc",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6813,
    Code: "21508",
    Name: "Xã Ba Khâm",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6814,
    Code: "21511",
    Name: "Xã Ba Cung",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6815,
    Code: "21517",
    Name: "Xã Ba Tiêu",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6816,
    Code: "21520",
    Name: "Xã Ba Trang",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6817,
    Code: "21523",
    Name: "Xã Ba Tô",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6818,
    Code: "21526",
    Name: "Xã Ba Bích",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6819,
    Code: "21529",
    Name: "Xã Ba Vì",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6820,
    Code: "21532",
    Name: "Xã Ba Lế",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6821,
    Code: "21535",
    Name: "Xã Ba Nam",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6822,
    Code: "21538",
    Name: "Xã Ba Xa",
    LevelUp: "Xã",
    DistrictCode: "3512",
    Active: -1,
  },
  {
    ID: 6823,
    Code: "21550",
    Name: "Phường Nhơn Bình",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6824,
    Code: "21553",
    Name: "Phường Nhơn Phú",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6825,
    Code: "21556",
    Name: "Phường Đống Đa",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6826,
    Code: "21559",
    Name: "Phường Trần Quang Diệu",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6827,
    Code: "21562",
    Name: "Phường Hải Cảng",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6828,
    Code: "21565",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6829,
    Code: "21568",
    Name: "Phường Thị Nại",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6830,
    Code: "21571",
    Name: "Phường Lê Hồng Phong",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6831,
    Code: "21574",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6832,
    Code: "21577",
    Name: "Phường Ngô Mây",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6833,
    Code: "21580",
    Name: "Phường Lý Thường Kiệt",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6834,
    Code: "21583",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6835,
    Code: "21586",
    Name: "Phường Trần Phú",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6836,
    Code: "21589",
    Name: "Phường Bùi Thị Xuân",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6837,
    Code: "21592",
    Name: "Phường Nguyễn Văn Cừ",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6838,
    Code: "21595",
    Name: "Phường Ghềnh Ráng",
    LevelUp: "Phường",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6839,
    Code: "21598",
    Name: "Xã Nhơn Lý",
    LevelUp: "Xã",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6840,
    Code: "21601",
    Name: "Xã Nhơn Hội",
    LevelUp: "Xã",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6841,
    Code: "21604",
    Name: "Xã Nhơn Hải",
    LevelUp: "Xã",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6842,
    Code: "21607",
    Name: "Xã Nhơn Châu",
    LevelUp: "Xã",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6843,
    Code: "21991",
    Name: "Xã Phước Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3701",
    Active: -1,
  },
  {
    ID: 6844,
    Code: "21609",
    Name: "Thị trấn An Lão",
    LevelUp: "Thị trấn",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6845,
    Code: "21610",
    Name: "Xã An Hưng",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6846,
    Code: "21613",
    Name: "Xã An Trung",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6847,
    Code: "21616",
    Name: "Xã An Dũng",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6848,
    Code: "21619",
    Name: "Xã An Vinh",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6849,
    Code: "21622",
    Name: "Xã An Toàn",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6850,
    Code: "21625",
    Name: "Xã An Tân",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6851,
    Code: "21628",
    Name: "Xã An Hòa",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6852,
    Code: "21631",
    Name: "Xã An Quang",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6853,
    Code: "21634",
    Name: "Xã An Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3702",
    Active: -1,
  },
  {
    ID: 6854,
    Code: "21637",
    Name: "Phường Tam Quan",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6855,
    Code: "21640",
    Name: "Phường Bồng Sơn",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6856,
    Code: "21643",
    Name: "Xã Hoài Sơn",
    LevelUp: "Xã",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6857,
    Code: "21646",
    Name: "Xã Hoài Châu Bắc",
    LevelUp: "Xã",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6858,
    Code: "21649",
    Name: "Xã Hoài Châu",
    LevelUp: "Xã",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6859,
    Code: "21652",
    Name: "Xã Hoài Phú",
    LevelUp: "Xã",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6860,
    Code: "21655",
    Name: "Phường Tam Quan Bắc",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6861,
    Code: "21658",
    Name: "Phường Tam Quan Nam",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6862,
    Code: "21661",
    Name: "Phường Hoài Hảo",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6863,
    Code: "21664",
    Name: "Phường Hoài Thanh Tây",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6864,
    Code: "21667",
    Name: "Phường Hoài Thanh",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6865,
    Code: "21670",
    Name: "Phường Hoài Hương",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6866,
    Code: "21673",
    Name: "Phường Hoài Tân",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6867,
    Code: "21676",
    Name: "Xã Hoài Hải",
    LevelUp: "Xã",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6868,
    Code: "21679",
    Name: "Phường Hoài Xuân",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6869,
    Code: "21682",
    Name: "Xã Hoài Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6870,
    Code: "21685",
    Name: "Phường Hoài Đức",
    LevelUp: "Phường",
    DistrictCode: "3704",
    Active: -1,
  },
  {
    ID: 6871,
    Code: "21688",
    Name: "Thị trấn Tăng Bạt Hổ",
    LevelUp: "Thị trấn",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6872,
    Code: "21690",
    Name: "Xã Ân Hảo Tây",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6873,
    Code: "21691",
    Name: "Xã Ân Hảo Đông",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6874,
    Code: "21694",
    Name: "Xã Ân Sơn",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6875,
    Code: "21697",
    Name: "Xã Ân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6876,
    Code: "21700",
    Name: "Xã Dak Mang",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6877,
    Code: "21703",
    Name: "Xã Ân Tín",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6878,
    Code: "21706",
    Name: "Xã Ân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6879,
    Code: "21709",
    Name: "Xã Ân Phong",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6880,
    Code: "21712",
    Name: "Xã Ân Đức",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6881,
    Code: "21715",
    Name: "Xã Ân Hữu",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6882,
    Code: "21718",
    Name: "Xã Bok Tới",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6883,
    Code: "21721",
    Name: "Xã Ân Tường Tây",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6884,
    Code: "21724",
    Name: "Xã Ân Tường Đông",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6885,
    Code: "21727",
    Name: "Xã Ân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3703",
    Active: -1,
  },
  {
    ID: 6886,
    Code: "21730",
    Name: "Thị trấn Phù Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6887,
    Code: "21733",
    Name: "Thị trấn Bình Dương",
    LevelUp: "Thị trấn",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6888,
    Code: "21736",
    Name: "Xã Mỹ Đức",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6889,
    Code: "21739",
    Name: "Xã Mỹ Châu",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6890,
    Code: "21742",
    Name: "Xã Mỹ Thắng",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6891,
    Code: "21745",
    Name: "Xã Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6892,
    Code: "21748",
    Name: "Xã Mỹ Lợi",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6893,
    Code: "21751",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6894,
    Code: "21754",
    Name: "Xã Mỹ Phong",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6895,
    Code: "21757",
    Name: "Xã Mỹ Trinh",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6896,
    Code: "21760",
    Name: "Xã Mỹ Thọ",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6897,
    Code: "21763",
    Name: "Xã Mỹ Hòa",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6898,
    Code: "21766",
    Name: "Xã Mỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6899,
    Code: "21769",
    Name: "Xã Mỹ Chánh",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6900,
    Code: "21772",
    Name: "Xã Mỹ Quang",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6901,
    Code: "21775",
    Name: "Xã Mỹ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6902,
    Code: "21778",
    Name: "Xã Mỹ Tài",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6903,
    Code: "21781",
    Name: "Xã Mỹ Cát",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6904,
    Code: "21784",
    Name: "Xã Mỹ Chánh Tây",
    LevelUp: "Xã",
    DistrictCode: "3705",
    Active: -1,
  },
  {
    ID: 6905,
    Code: "21786",
    Name: "Thị trấn Vĩnh Thạnh",
    LevelUp: "Thị trấn",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6906,
    Code: "21787",
    Name: "Xã Vĩnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6907,
    Code: "21790",
    Name: "Xã Vĩnh Kim",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6908,
    Code: "21796",
    Name: "Xã Vĩnh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6909,
    Code: "21799",
    Name: "Xã Vĩnh Hảo",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6910,
    Code: "21801",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6911,
    Code: "21802",
    Name: "Xã Vĩnh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6912,
    Code: "21804",
    Name: "Xã Vĩnh Thuận",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6913,
    Code: "21805",
    Name: "Xã Vĩnh Quang",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 6914,
    Code: "21808",
    Name: "Thị trấn Phú Phong",
    LevelUp: "Thị trấn",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6915,
    Code: "21811",
    Name: "Xã Bình Tân",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6916,
    Code: "21814",
    Name: "Xã Tây Thuận",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6917,
    Code: "21817",
    Name: "Xã Bình Thuận",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6918,
    Code: "21820",
    Name: "Xã Tây Giang",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6919,
    Code: "21823",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6920,
    Code: "21826",
    Name: "Xã Tây An",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6921,
    Code: "21829",
    Name: "Xã Bình Hòa",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6922,
    Code: "21832",
    Name: "Xã Tây Bình",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6923,
    Code: "21835",
    Name: "Xã Bình Tường",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6924,
    Code: "21838",
    Name: "Xã Tây Vinh",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6925,
    Code: "21841",
    Name: "Xã Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6926,
    Code: "21844",
    Name: "Xã Tây Xuân",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6927,
    Code: "21847",
    Name: "Xã Bình Nghi",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6928,
    Code: "21850",
    Name: "Xã Tây Phú",
    LevelUp: "Xã",
    DistrictCode: "3708",
    Active: -1,
  },
  {
    ID: 6929,
    Code: "21853",
    Name: "Thị trấn Ngô Mây",
    LevelUp: "Thị trấn",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6930,
    Code: "21856",
    Name: "Xã Cát Sơn",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6931,
    Code: "21859",
    Name: "Xã Cát Minh",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6932,
    Code: "21862",
    Name: "Xã Cát Khánh",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6933,
    Code: "21865",
    Name: "Xã Cát Tài",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6934,
    Code: "21868",
    Name: "Xã Cát Lâm",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6935,
    Code: "21871",
    Name: "Xã Cát Hanh",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6936,
    Code: "21874",
    Name: "Xã Cát Thành",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6937,
    Code: "21877",
    Name: "Xã Cát Trinh",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6938,
    Code: "21880",
    Name: "Xã Cát Hải",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6939,
    Code: "21883",
    Name: "Xã Cát Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6940,
    Code: "21886",
    Name: "Xã Cát Nhơn",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6941,
    Code: "21889",
    Name: "Xã Cát Hưng",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6942,
    Code: "21892",
    Name: "Xã Cát Tường",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6943,
    Code: "21895",
    Name: "Xã Cát Tân",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6944,
    Code: "21898",
    Name: "Xã Cát Tiến",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6945,
    Code: "21901",
    Name: "Xã Cát Thắng",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6946,
    Code: "21904",
    Name: "Xã Cát Chánh",
    LevelUp: "Xã",
    DistrictCode: "3706",
    Active: -1,
  },
  {
    ID: 6947,
    Code: "21907",
    Name: "Phường Bình Định",
    LevelUp: "Phường",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6948,
    Code: "21910",
    Name: "Phường Đập Đá",
    LevelUp: "Phường",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6949,
    Code: "21913",
    Name: "Xã Nhơn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6950,
    Code: "21916",
    Name: "Phường Nhơn Thành",
    LevelUp: "Phường",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6951,
    Code: "21919",
    Name: "Xã Nhơn Hạnh",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6952,
    Code: "21922",
    Name: "Xã Nhơn Hậu",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6953,
    Code: "21925",
    Name: "Xã Nhơn Phong",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6954,
    Code: "21928",
    Name: "Xã Nhơn An",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6955,
    Code: "21931",
    Name: "Xã Nhơn Phúc",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6956,
    Code: "21934",
    Name: "Phường Nhơn Hưng",
    LevelUp: "Phường",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6957,
    Code: "21937",
    Name: "Xã Nhơn Khánh",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6958,
    Code: "21940",
    Name: "Xã Nhơn Lộc",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6959,
    Code: "21943",
    Name: "Phường Nhơn Hoà",
    LevelUp: "Phường",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6960,
    Code: "21946",
    Name: "Xã Nhơn Tân",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6961,
    Code: "21949",
    Name: "Xã Nhơn Thọ",
    LevelUp: "Xã",
    DistrictCode: "3710",
    Active: -1,
  },
  {
    ID: 6962,
    Code: "21952",
    Name: "Thị trấn Tuy Phước",
    LevelUp: "Thị trấn",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6963,
    Code: "21955",
    Name: "Thị trấn Diêu Trì",
    LevelUp: "Thị trấn",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6964,
    Code: "21958",
    Name: "Xã Phước Thắng",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6965,
    Code: "21961",
    Name: "Xã Phước Hưng",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6966,
    Code: "21964",
    Name: "Xã Phước Quang",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6967,
    Code: "21967",
    Name: "Xã Phước Hòa",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6968,
    Code: "21970",
    Name: "Xã Phước Sơn",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6969,
    Code: "21973",
    Name: "Xã Phước Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6970,
    Code: "21976",
    Name: "Xã Phước Lộc",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6971,
    Code: "21979",
    Name: "Xã Phước Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6972,
    Code: "21982",
    Name: "Xã Phước Thuận",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6973,
    Code: "21985",
    Name: "Xã Phước An",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6974,
    Code: "21988",
    Name: "Xã Phước Thành",
    LevelUp: "Xã",
    DistrictCode: "3711",
    Active: -1,
  },
  {
    ID: 6975,
    Code: "21994",
    Name: "Thị trấn Vân Canh",
    LevelUp: "Thị trấn",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6976,
    Code: "21997",
    Name: "Xã Canh Liên",
    LevelUp: "Xã",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6977,
    Code: "22000",
    Name: "Xã Canh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6978,
    Code: "22003",
    Name: "Xã Canh Vinh",
    LevelUp: "Xã",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6979,
    Code: "22006",
    Name: "Xã Canh Hiển",
    LevelUp: "Xã",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6980,
    Code: "22009",
    Name: "Xã Canh Thuận",
    LevelUp: "Xã",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6981,
    Code: "22012",
    Name: "Xã Canh Hòa",
    LevelUp: "Xã",
    DistrictCode: "3709",
    Active: -1,
  },
  {
    ID: 6982,
    Code: "22015",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6983,
    Code: "22018",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6984,
    Code: "22021",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6985,
    Code: "22024",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6986,
    Code: "22027",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6987,
    Code: "22030",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6988,
    Code: "22033",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6989,
    Code: "22036",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6990,
    Code: "22039",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6991,
    Code: "22040",
    Name: "Phường Phú Thạnh",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6992,
    Code: "22041",
    Name: "Phường Phú Đông",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6993,
    Code: "22042",
    Name: "Xã Hòa Kiến",
    LevelUp: "Xã",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6994,
    Code: "22045",
    Name: "Xã Bình Kiến",
    LevelUp: "Xã",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6995,
    Code: "22048",
    Name: "Xã Bình Ngọc",
    LevelUp: "Xã",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6996,
    Code: "22162",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6997,
    Code: "22240",
    Name: "Phường Phú Lâm",
    LevelUp: "Phường",
    DistrictCode: "3901",
    Active: -1,
  },
  {
    ID: 6998,
    Code: "22051",
    Name: "Phường Xuân Phú",
    LevelUp: "Phường",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 6999,
    Code: "22052",
    Name: "Xã Xuân Lâm",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7000,
    Code: "22053",
    Name: "Phường Xuân Thành",
    LevelUp: "Phường",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7001,
    Code: "22054",
    Name: "Xã Xuân Hải",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7002,
    Code: "22057",
    Name: "Xã Xuân Lộc",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7003,
    Code: "22060",
    Name: "Xã Xuân Bình",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7004,
    Code: "22066",
    Name: "Xã Xuân Cảnh",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7005,
    Code: "22069",
    Name: "Xã Xuân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7006,
    Code: "22072",
    Name: "Xã Xuân Phương",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7007,
    Code: "22073",
    Name: "Phường Xuân Yên",
    LevelUp: "Phường",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7008,
    Code: "22075",
    Name: "Xã Xuân Thọ 1",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7009,
    Code: "22076",
    Name: "Phường Xuân Đài",
    LevelUp: "Phường",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7010,
    Code: "22078",
    Name: "Xã Xuân Thọ 2",
    LevelUp: "Xã",
    DistrictCode: "3903",
    Active: -1,
  },
  {
    ID: 7011,
    Code: "22081",
    Name: "Thị trấn La Hai",
    LevelUp: "Thị trấn",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7012,
    Code: "22084",
    Name: "Xã Đa Lộc",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7013,
    Code: "22087",
    Name: "Xã Phú Mỡ",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7014,
    Code: "22090",
    Name: "Xã Xuân Lãnh",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7015,
    Code: "22093",
    Name: "Xã Xuân Long",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7016,
    Code: "22096",
    Name: "Xã Xuân Quang 1",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7017,
    Code: "22099",
    Name: "Xã Xuân Sơn Bắc",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7018,
    Code: "22102",
    Name: "Xã Xuân Quang 2",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7019,
    Code: "22105",
    Name: "Xã Xuân Sơn Nam",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7020,
    Code: "22108",
    Name: "Xã Xuân Quang 3",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7021,
    Code: "22111",
    Name: "Xã Xuân Phước",
    LevelUp: "Xã",
    DistrictCode: "3902",
    Active: -1,
  },
  {
    ID: 7022,
    Code: "22114",
    Name: "Thị trấn Chí Thạnh",
    LevelUp: "Thị trấn",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7023,
    Code: "22117",
    Name: "Xã An Dân",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7024,
    Code: "22120",
    Name: "Xã An Ninh Tây",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7025,
    Code: "22123",
    Name: "Xã An Ninh Đông",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7026,
    Code: "22126",
    Name: "Xã An Thạch",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7027,
    Code: "22129",
    Name: "Xã An Định",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7028,
    Code: "22132",
    Name: "Xã An Nghiệp",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7029,
    Code: "22138",
    Name: "Xã An Cư",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7030,
    Code: "22141",
    Name: "Xã An Xuân",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7031,
    Code: "22144",
    Name: "Xã An Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7032,
    Code: "22147",
    Name: "Xã An Hòa Hải",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7033,
    Code: "22150",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7034,
    Code: "22153",
    Name: "Xã An Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7035,
    Code: "22156",
    Name: "Xã An Chấn",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7036,
    Code: "22159",
    Name: "Xã An Thọ",
    LevelUp: "Xã",
    DistrictCode: "3904",
    Active: -1,
  },
  {
    ID: 7037,
    Code: "22165",
    Name: "Thị trấn Củng Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7038,
    Code: "22168",
    Name: "Xã Phước Tân",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7039,
    Code: "22171",
    Name: "Xã Sơn Hội",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7040,
    Code: "22174",
    Name: "Xã Sơn Định",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7041,
    Code: "22177",
    Name: "Xã Sơn Long",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7042,
    Code: "22180",
    Name: "Xã Cà Lúi",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7043,
    Code: "22183",
    Name: "Xã Sơn Phước",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7044,
    Code: "22186",
    Name: "Xã Sơn Xuân",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7045,
    Code: "22189",
    Name: "Xã Sơn Nguyên",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7046,
    Code: "22192",
    Name: "Xã Eachà Rang",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7047,
    Code: "22195",
    Name: "Xã Krông Pa",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7048,
    Code: "22198",
    Name: "Xã Suối Bạc",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7049,
    Code: "22201",
    Name: "Xã Sơn Hà",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7050,
    Code: "22204",
    Name: "Xã Suối Trai",
    LevelUp: "Xã",
    DistrictCode: "3905",
    Active: -1,
  },
  {
    ID: 7051,
    Code: "22207",
    Name: "Thị trấn Hai Riêng",
    LevelUp: "Thị trấn",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7052,
    Code: "22210",
    Name: "Xã Ea Lâm",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7053,
    Code: "22213",
    Name: "Xã Đức Bình Tây",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7054,
    Code: "22216",
    Name: "Xã Ea Bá",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7055,
    Code: "22219",
    Name: "Xã Sơn Giang",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7056,
    Code: "22222",
    Name: "Xã Đức Bình Đông",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7057,
    Code: "22225",
    Name: "Xã EaBar",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7058,
    Code: "22228",
    Name: "Xã EaBia",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7059,
    Code: "22231",
    Name: "Xã EaTrol",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7060,
    Code: "22234",
    Name: "Xã Sông Hinh",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7061,
    Code: "22237",
    Name: "Xã Ealy",
    LevelUp: "Xã",
    DistrictCode: "3906",
    Active: -1,
  },
  {
    ID: 7062,
    Code: "22249",
    Name: "Xã Sơn Thành Tây",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7063,
    Code: "22250",
    Name: "Xã Sơn Thành Đông",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7064,
    Code: "22252",
    Name: "Xã Hòa Bình 1",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7065,
    Code: "22255",
    Name: "Thị trấn Phú Thứ",
    LevelUp: "Thị trấn",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7066,
    Code: "22264",
    Name: "Xã Hòa Phong",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7067,
    Code: "22270",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7068,
    Code: "22273",
    Name: "Xã Hòa Tân Tây",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7069,
    Code: "22276",
    Name: "Xã Hòa Đồng",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7070,
    Code: "22285",
    Name: "Xã Hòa Mỹ Đông",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7071,
    Code: "22288",
    Name: "Xã Hòa Mỹ Tây",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7072,
    Code: "22294",
    Name: "Xã Hòa Thịnh",
    LevelUp: "Xã",
    DistrictCode: "3909",
    Active: -1,
  },
  {
    ID: 7073,
    Code: "22303",
    Name: "Xã Hòa Quang Bắc",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7074,
    Code: "22306",
    Name: "Xã Hòa Quang Nam",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7075,
    Code: "22309",
    Name: "Xã Hòa Hội",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7076,
    Code: "22312",
    Name: "Xã Hòa Trị",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7077,
    Code: "22315",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7078,
    Code: "22318",
    Name: "Xã Hòa Định Đông",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7079,
    Code: "22319",
    Name: "Thị Trấn Phú Hoà",
    LevelUp: "Thị trấn",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7080,
    Code: "22321",
    Name: "Xã Hòa Định Tây",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7081,
    Code: "22324",
    Name: "Xã Hòa Thắng",
    LevelUp: "Xã",
    DistrictCode: "3908",
    Active: -1,
  },
  {
    ID: 7082,
    Code: "22243",
    Name: "Xã Hòa Thành",
    LevelUp: "Xã",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7083,
    Code: "22246",
    Name: "Phường Hòa Hiệp Bắc",
    LevelUp: "Phường",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7084,
    Code: "22258",
    Name: "Phường Hoà Vinh",
    LevelUp: "Phường",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7085,
    Code: "22261",
    Name: "Phường Hoà Hiệp Trung",
    LevelUp: "Phường",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7086,
    Code: "22267",
    Name: "Xã Hòa Tân Đông",
    LevelUp: "Xã",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7087,
    Code: "22279",
    Name: "Phường Hòa Xuân Tây",
    LevelUp: "Phường",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7088,
    Code: "22282",
    Name: "Phường Hòa Hiệp Nam",
    LevelUp: "Phường",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7089,
    Code: "22291",
    Name: "Xã Hòa Xuân Đông",
    LevelUp: "Xã",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7090,
    Code: "22297",
    Name: "Xã Hòa Tâm",
    LevelUp: "Xã",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7091,
    Code: "22300",
    Name: "Xã Hòa Xuân Nam",
    LevelUp: "Xã",
    DistrictCode: "3907",
    Active: -1,
  },
  {
    ID: 7092,
    Code: "22327",
    Name: "Phường Vĩnh Hòa",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7093,
    Code: "22330",
    Name: "Phường Vĩnh Hải",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7094,
    Code: "22333",
    Name: "Phường Vĩnh Phước",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7095,
    Code: "22336",
    Name: "Phường Ngọc Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7096,
    Code: "22339",
    Name: "Phường Vĩnh Thọ",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7097,
    Code: "22342",
    Name: "Phường Xương Huân",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7098,
    Code: "22345",
    Name: "Phường Vạn Thắng",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7099,
    Code: "22348",
    Name: "Phường Vạn Thạnh",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7100,
    Code: "22351",
    Name: "Phường Phương Sài",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7101,
    Code: "22354",
    Name: "Phường Phương Sơn",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7102,
    Code: "22357",
    Name: "Phường Phước Hải",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7103,
    Code: "22360",
    Name: "Phường Phước Tân",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7104,
    Code: "22363",
    Name: "Phường Lộc Thọ",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7105,
    Code: "22366",
    Name: "Phường Phước Tiến",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7106,
    Code: "22369",
    Name: "Phường Tân Lập",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7107,
    Code: "22372",
    Name: "Phường Phước Hòa",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7108,
    Code: "22375",
    Name: "Phường Vĩnh Nguyên",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7109,
    Code: "22378",
    Name: "Phường Phước Long",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7110,
    Code: "22381",
    Name: "Phường Vĩnh Trường",
    LevelUp: "Phường",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7111,
    Code: "22384",
    Name: "Xã Vĩnh Lương",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7112,
    Code: "22387",
    Name: "Xã Vĩnh Phương",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7113,
    Code: "22390",
    Name: "Xã Vĩnh Ngọc",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7114,
    Code: "22393",
    Name: "Xã Vĩnh Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7115,
    Code: "22396",
    Name: "Xã Vĩnh Trung",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7116,
    Code: "22399",
    Name: "Xã Vĩnh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7117,
    Code: "22402",
    Name: "Xã Vĩnh Thái",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7118,
    Code: "22405",
    Name: "Xã Phước Đồng",
    LevelUp: "Xã",
    DistrictCode: "4101",
    Active: -1,
  },
  {
    ID: 7119,
    Code: "22408",
    Name: "Phường Cam Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7120,
    Code: "22411",
    Name: "Phường Cam Phúc Bắc",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7121,
    Code: "22414",
    Name: "Phường Cam Phúc Nam",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7122,
    Code: "22417",
    Name: "Phường Cam Lộc",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7123,
    Code: "22420",
    Name: "Phường Cam Phú",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7124,
    Code: "22423",
    Name: "Phường Ba Ngòi",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7125,
    Code: "22426",
    Name: "Phường Cam Thuận",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7126,
    Code: "22429",
    Name: "Phường Cam Lợi",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7127,
    Code: "22432",
    Name: "Phường Cam Linh",
    LevelUp: "Phường",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7128,
    Code: "22468",
    Name: "Xã Cam Thành Nam",
    LevelUp: "Xã",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7129,
    Code: "22474",
    Name: "Xã Cam Phước Đông",
    LevelUp: "Xã",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7130,
    Code: "22477",
    Name: "Xã Cam Thịnh Tây",
    LevelUp: "Xã",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7131,
    Code: "22480",
    Name: "Xã Cam Thịnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7132,
    Code: "22483",
    Name: "Xã Cam Lập",
    LevelUp: "Xã",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7133,
    Code: "22486",
    Name: "Xã Cam Bình",
    LevelUp: "Xã",
    DistrictCode: "4106",
    Active: -1,
  },
  {
    ID: 7134,
    Code: "22435",
    Name: "Xã Cam Tân",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7135,
    Code: "22438",
    Name: "Xã Cam Hòa",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7136,
    Code: "22441",
    Name: "Xã Cam Hải Đông",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7137,
    Code: "22444",
    Name: "Xã Cam Hải Tây",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7138,
    Code: "22447",
    Name: "Xã Sơn Tân",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7139,
    Code: "22450",
    Name: "Xã Cam Hiệp Bắc",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7140,
    Code: "22453",
    Name: "Thị trấn Cam Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7141,
    Code: "22456",
    Name: "Xã Cam Hiệp Nam",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7142,
    Code: "22459",
    Name: "Xã Cam Phước Tây",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7143,
    Code: "22462",
    Name: "Xã Cam Thành Bắc",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7144,
    Code: "22465",
    Name: "Xã Cam An Bắc",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7145,
    Code: "22471",
    Name: "Xã Cam An Nam",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7146,
    Code: "22708",
    Name: "Xã Suối Cát",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7147,
    Code: "22711",
    Name: "Xã Suối Tân",
    LevelUp: "Xã",
    DistrictCode: "4109",
    Active: -1,
  },
  {
    ID: 7148,
    Code: "22489",
    Name: "Thị trấn Vạn Giã",
    LevelUp: "Thị trấn",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7149,
    Code: "22492",
    Name: "Xã Đại Lãnh",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7150,
    Code: "22495",
    Name: "Xã Vạn Phước",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7151,
    Code: "22498",
    Name: "Xã Vạn Long",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7152,
    Code: "22501",
    Name: "Xã Vạn Bình",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7153,
    Code: "22504",
    Name: "Xã Vạn Thọ",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7154,
    Code: "22507",
    Name: "Xã Vạn Khánh",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7155,
    Code: "22510",
    Name: "Xã Vạn Phú",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7156,
    Code: "22513",
    Name: "Xã Vạn Lương",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7157,
    Code: "22516",
    Name: "Xã Vạn Thắng",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7158,
    Code: "22519",
    Name: "Xã Vạn Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7159,
    Code: "22522",
    Name: "Xã Xuân Sơn",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7160,
    Code: "22525",
    Name: "Xã Vạn Hưng",
    LevelUp: "Xã",
    DistrictCode: "4102",
    Active: -1,
  },
  {
    ID: 7161,
    Code: "22528",
    Name: "Phường Ninh Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7162,
    Code: "22531",
    Name: "Xã Ninh Sơn",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7163,
    Code: "22534",
    Name: "Xã Ninh Tây",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7164,
    Code: "22537",
    Name: "Xã Ninh Thượng",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7165,
    Code: "22540",
    Name: "Xã Ninh An",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7166,
    Code: "22543",
    Name: "Phường Ninh Hải",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7167,
    Code: "22546",
    Name: "Xã Ninh Thọ",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7168,
    Code: "22549",
    Name: "Xã Ninh Trung",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7169,
    Code: "22552",
    Name: "Xã Ninh Sim",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7170,
    Code: "22555",
    Name: "Xã Ninh Xuân",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7171,
    Code: "22558",
    Name: "Xã Ninh Thân",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7172,
    Code: "22561",
    Name: "Phường Ninh Diêm",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7173,
    Code: "22564",
    Name: "Xã Ninh Đông",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7174,
    Code: "22567",
    Name: "Phường Ninh Thủy",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7175,
    Code: "22570",
    Name: "Phường Ninh Đa",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7176,
    Code: "22573",
    Name: "Xã Ninh Phụng",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7177,
    Code: "22576",
    Name: "Xã Ninh Bình",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7178,
    Code: "22579",
    Name: "Xã Ninh Phước",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7179,
    Code: "22582",
    Name: "Xã Ninh Phú",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7180,
    Code: "22585",
    Name: "Xã Ninh Tân",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7181,
    Code: "22588",
    Name: "Xã Ninh Quang",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7182,
    Code: "22591",
    Name: "Phường Ninh Giang",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7183,
    Code: "22594",
    Name: "Phường Ninh Hà",
    LevelUp: "Phường",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7184,
    Code: "22597",
    Name: "Xã Ninh Hưng",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7185,
    Code: "22600",
    Name: "Xã Ninh Lộc",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7186,
    Code: "22603",
    Name: "Xã Ninh Ích",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7187,
    Code: "22606",
    Name: "Xã Ninh Vân",
    LevelUp: "Xã",
    DistrictCode: "4103",
    Active: -1,
  },
  {
    ID: 7188,
    Code: "22609",
    Name: "Thị trấn Khánh Vĩnh",
    LevelUp: "Thị trấn",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7189,
    Code: "22612",
    Name: "Xã Khánh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7190,
    Code: "22615",
    Name: "Xã Khánh Bình",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7191,
    Code: "22618",
    Name: "Xã Khánh Trung",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7192,
    Code: "22621",
    Name: "Xã Khánh Đông",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7193,
    Code: "22624",
    Name: "Xã Khánh Thượng",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7194,
    Code: "22627",
    Name: "Xã Khánh Nam",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7195,
    Code: "22630",
    Name: "Xã Sông Cầu",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7196,
    Code: "22633",
    Name: "Xã Giang Ly",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7197,
    Code: "22636",
    Name: "Xã Cầu Bà",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7198,
    Code: "22639",
    Name: "Xã Liên Sang",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7199,
    Code: "22642",
    Name: "Xã Khánh Thành",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7200,
    Code: "22645",
    Name: "Xã Khánh Phú",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7201,
    Code: "22648",
    Name: "Xã Sơn Thái",
    LevelUp: "Xã",
    DistrictCode: "4105",
    Active: -1,
  },
  {
    ID: 7202,
    Code: "22651",
    Name: "Thị trấn Diên Khánh",
    LevelUp: "Thị trấn",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7203,
    Code: "22654",
    Name: "Xã Diên Lâm",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7204,
    Code: "22657",
    Name: "Xã Diên Điền",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7205,
    Code: "22660",
    Name: "Xã Diên Xuân",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7206,
    Code: "22663",
    Name: "Xã Diên Sơn",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7207,
    Code: "22666",
    Name: "Xã Diên Đồng",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7208,
    Code: "22669",
    Name: "Xã Diên Phú",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7209,
    Code: "22672",
    Name: "Xã Diên Thọ",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7210,
    Code: "22675",
    Name: "Xã Diên Phước",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7211,
    Code: "22678",
    Name: "Xã Diên Lạc",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7212,
    Code: "22681",
    Name: "Xã Diên Tân",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7213,
    Code: "22684",
    Name: "Xã Diên Hòa",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7214,
    Code: "22687",
    Name: "Xã Diên Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7215,
    Code: "22690",
    Name: "Xã Diên Toàn",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7216,
    Code: "22693",
    Name: "Xã Diên An",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7217,
    Code: "22696",
    Name: "Xã Bình Lộc",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7218,
    Code: "22702",
    Name: "Xã Suối Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7219,
    Code: "22705",
    Name: "Xã Suối Tiên",
    LevelUp: "Xã",
    DistrictCode: "4104",
    Active: -1,
  },
  {
    ID: 7220,
    Code: "22714",
    Name: "Thị trấn Tô Hạp",
    LevelUp: "Thị trấn",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7221,
    Code: "22717",
    Name: "Xã Thành Sơn",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7222,
    Code: "22720",
    Name: "Xã Sơn Lâm",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7223,
    Code: "22723",
    Name: "Xã Sơn Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7224,
    Code: "22726",
    Name: "Xã Sơn Bình",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7225,
    Code: "22729",
    Name: "Xã Sơn Trung",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7226,
    Code: "22732",
    Name: "Xã Ba Cụm Bắc",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7227,
    Code: "22735",
    Name: "Xã Ba Cụm Nam",
    LevelUp: "Xã",
    DistrictCode: "4107",
    Active: -1,
  },
  {
    ID: 7228,
    Code: "22736",
    Name: "Thị trấn Trường Sa",
    LevelUp: "Thị trấn",
    DistrictCode: "4108",
    Active: -1,
  },
  {
    ID: 7229,
    Code: "22737",
    Name: "Xã Song Tử Tây",
    LevelUp: "Xã",
    DistrictCode: "4108",
    Active: -1,
  },
  {
    ID: 7230,
    Code: "22739",
    Name: "Xã Sinh Tồn",
    LevelUp: "Xã",
    DistrictCode: "4108",
    Active: -1,
  },
  {
    ID: 7231,
    Code: "22738",
    Name: "Phường Đô Vinh",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7232,
    Code: "22741",
    Name: "Phường Phước Mỹ",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7233,
    Code: "22744",
    Name: "Phường Bảo An",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7234,
    Code: "22747",
    Name: "Phường Phủ Hà",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7235,
    Code: "22750",
    Name: "Phường Thanh Sơn",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7236,
    Code: "22753",
    Name: "Phường Mỹ Hương",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7237,
    Code: "22756",
    Name: "Phường Tấn Tài",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7238,
    Code: "22759",
    Name: "Phường Kinh Dinh",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7239,
    Code: "22762",
    Name: "Phường Đạo Long",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7240,
    Code: "22765",
    Name: "Phường Đài Sơn",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7241,
    Code: "22768",
    Name: "Phường Đông Hải",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7242,
    Code: "22771",
    Name: "Phường Mỹ Đông",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7243,
    Code: "22774",
    Name: "Xã Thành Hải",
    LevelUp: "Xã",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7244,
    Code: "22777",
    Name: "Phường Văn Hải",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7245,
    Code: "22779",
    Name: "Phường Mỹ Bình",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7246,
    Code: "22780",
    Name: "Phường Mỹ Hải",
    LevelUp: "Phường",
    DistrictCode: "4501",
    Active: -1,
  },
  {
    ID: 7247,
    Code: "22783",
    Name: "Xã Phước Bình",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7248,
    Code: "22786",
    Name: "Xã Phước Hòa",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7249,
    Code: "22789",
    Name: "Xã Phước Tân",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7250,
    Code: "22792",
    Name: "Xã Phước Tiến",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7251,
    Code: "22795",
    Name: "Xã Phước Thắng",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7252,
    Code: "22798",
    Name: "Xã Phước Thành",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7253,
    Code: "22801",
    Name: "Xã Phước Đại",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7254,
    Code: "22804",
    Name: "Xã Phước Chính",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7255,
    Code: "22807",
    Name: "Xã Phước Trung",
    LevelUp: "Xã",
    DistrictCode: "4505",
    Active: -1,
  },
  {
    ID: 7256,
    Code: "22810",
    Name: "Thị trấn Tân Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7257,
    Code: "22813",
    Name: "Xã Lâm Sơn",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7258,
    Code: "22816",
    Name: "Xã Lương Sơn",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7259,
    Code: "22819",
    Name: "Xã Quảng Sơn",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7260,
    Code: "22822",
    Name: "Xã Mỹ Sơn",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7261,
    Code: "22825",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7262,
    Code: "22828",
    Name: "Xã Ma Nới",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7263,
    Code: "22831",
    Name: "Xã Nhơn Sơn",
    LevelUp: "Xã",
    DistrictCode: "4502",
    Active: -1,
  },
  {
    ID: 7264,
    Code: "22834",
    Name: "Thị trấn Khánh Hải",
    LevelUp: "Thị trấn",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7265,
    Code: "22846",
    Name: "Xã Vĩnh Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7266,
    Code: "22852",
    Name: "Xã Phương Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7267,
    Code: "22855",
    Name: "Xã Tân Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7268,
    Code: "22858",
    Name: "Xã Xuân Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7269,
    Code: "22861",
    Name: "Xã Hộ Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7270,
    Code: "22864",
    Name: "Xã Tri Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7271,
    Code: "22867",
    Name: "Xã Nhơn Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7272,
    Code: "22868",
    Name: "Xã Thanh Hải",
    LevelUp: "Xã",
    DistrictCode: "4503",
    Active: -1,
  },
  {
    ID: 7273,
    Code: "22870",
    Name: "Thị trấn Phước Dân",
    LevelUp: "Thị trấn",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7274,
    Code: "22873",
    Name: "Xã Phước Sơn",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7275,
    Code: "22876",
    Name: "Xã Phước Thái",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7276,
    Code: "22879",
    Name: "Xã Phước Hậu",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7277,
    Code: "22882",
    Name: "Xã Phước Thuận",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7278,
    Code: "22888",
    Name: "Xã An Hải",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7279,
    Code: "22891",
    Name: "Xã Phước Hữu",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7280,
    Code: "22894",
    Name: "Xã Phước Hải",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7281,
    Code: "22912",
    Name: "Xã Phước Vinh",
    LevelUp: "Xã",
    DistrictCode: "4504",
    Active: -1,
  },
  {
    ID: 7282,
    Code: "22837",
    Name: "Xã Phước Chiến",
    LevelUp: "Xã",
    DistrictCode: "4506",
    Active: -1,
  },
  {
    ID: 7283,
    Code: "22840",
    Name: "Xã Công Hải",
    LevelUp: "Xã",
    DistrictCode: "4506",
    Active: -1,
  },
  {
    ID: 7284,
    Code: "22843",
    Name: "Xã Phước Kháng",
    LevelUp: "Xã",
    DistrictCode: "4506",
    Active: -1,
  },
  {
    ID: 7285,
    Code: "22849",
    Name: "Xã Lợi Hải",
    LevelUp: "Xã",
    DistrictCode: "4506",
    Active: -1,
  },
  {
    ID: 7286,
    Code: "22853",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "4506",
    Active: -1,
  },
  {
    ID: 7287,
    Code: "22856",
    Name: "Xã Bắc Phong",
    LevelUp: "Xã",
    DistrictCode: "4506",
    Active: -1,
  },
  {
    ID: 7288,
    Code: "22885",
    Name: "Xã Phước Hà",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7289,
    Code: "22897",
    Name: "Xã Phước Nam",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7290,
    Code: "22898",
    Name: "Xã Phước Ninh",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7291,
    Code: "22900",
    Name: "Xã Nhị Hà",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7292,
    Code: "22903",
    Name: "Xã Phước Dinh",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7293,
    Code: "22906",
    Name: "Xã Phước Minh",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7294,
    Code: "22909",
    Name: "Xã Phước Diêm",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7295,
    Code: "22910",
    Name: "Xã Cà Ná",
    LevelUp: "Xã",
    DistrictCode: "4507",
    Active: -1,
  },
  {
    ID: 7296,
    Code: "22915",
    Name: "Phường Mũi Né",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7297,
    Code: "22918",
    Name: "Phường Hàm Tiến",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7298,
    Code: "22921",
    Name: "Phường Phú Hài",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7299,
    Code: "22924",
    Name: "Phường Phú Thủy",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7300,
    Code: "22927",
    Name: "Phường Phú Tài",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7301,
    Code: "22930",
    Name: "Phường Phú Trinh",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7302,
    Code: "22933",
    Name: "Phường Xuân An",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7303,
    Code: "22936",
    Name: "Phường Thanh Hải",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7304,
    Code: "22939",
    Name: "Phường Bình Hưng",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7305,
    Code: "22942",
    Name: "Phường Đức Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7306,
    Code: "22945",
    Name: "Phường Lạc Đạo",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7307,
    Code: "22948",
    Name: "Phường Đức Thắng",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7308,
    Code: "22951",
    Name: "Phường Hưng Long",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7309,
    Code: "22954",
    Name: "Phường Đức Long",
    LevelUp: "Phường",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7310,
    Code: "22957",
    Name: "Xã Thiện Nghiệp",
    LevelUp: "Xã",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7311,
    Code: "22960",
    Name: "Xã Phong Nẫm",
    LevelUp: "Xã",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7312,
    Code: "22963",
    Name: "Xã Tiến Lợi",
    LevelUp: "Xã",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7313,
    Code: "22966",
    Name: "Xã Tiến Thành",
    LevelUp: "Xã",
    DistrictCode: "4701",
    Active: -1,
  },
  {
    ID: 7314,
    Code: "23231",
    Name: "Phường Phước Hội",
    LevelUp: "Phường",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7315,
    Code: "23232",
    Name: "Phường Phước Lộc",
    LevelUp: "Phường",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7316,
    Code: "23234",
    Name: "Phường Tân Thiện",
    LevelUp: "Phường",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7317,
    Code: "23235",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7318,
    Code: "23237",
    Name: "Phường Bình Tân",
    LevelUp: "Phường",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7319,
    Code: "23245",
    Name: "Xã Tân Hải",
    LevelUp: "Xã",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7320,
    Code: "23246",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7321,
    Code: "23248",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7322,
    Code: "23268",
    Name: "Xã Tân Phước",
    LevelUp: "Xã",
    DistrictCode: "4710",
    Active: -1,
  },
  {
    ID: 7323,
    Code: "22969",
    Name: "Thị trấn Liên Hương",
    LevelUp: "Thị trấn",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7324,
    Code: "22972",
    Name: "Thị trấn Phan Rí Cửa",
    LevelUp: "Thị trấn",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7325,
    Code: "22975",
    Name: "Xã Phan Dũng",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7326,
    Code: "22978",
    Name: "Xã Phong Phú",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7327,
    Code: "22981",
    Name: "Xã Vĩnh Hảo",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7328,
    Code: "22984",
    Name: "Xã Vĩnh Tân",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7329,
    Code: "22987",
    Name: "Xã Phú Lạc",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7330,
    Code: "22990",
    Name: "Xã Phước Thể",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7331,
    Code: "22993",
    Name: "Xã Hòa Minh",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7332,
    Code: "22996",
    Name: "Xã Chí Công",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7333,
    Code: "22999",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4702",
    Active: -1,
  },
  {
    ID: 7334,
    Code: "23005",
    Name: "Thị trấn Chợ Lầu",
    LevelUp: "Thị trấn",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7335,
    Code: "23008",
    Name: "Xã Phan Sơn",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7336,
    Code: "23011",
    Name: "Xã Phan Lâm",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7337,
    Code: "23014",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7338,
    Code: "23017",
    Name: "Xã Phan Điền",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7339,
    Code: "23020",
    Name: "Xã Hải Ninh",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7340,
    Code: "23023",
    Name: "Xã Sông Lũy",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7341,
    Code: "23026",
    Name: "Xã Phan Tiến",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7342,
    Code: "23029",
    Name: "Xã Sông Bình",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7343,
    Code: "23032",
    Name: "Thị trấn Lương Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7344,
    Code: "23035",
    Name: "Xã Phan Hòa",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7345,
    Code: "23038",
    Name: "Xã Phan Thanh",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7346,
    Code: "23041",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7347,
    Code: "23044",
    Name: "Xã Phan Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7348,
    Code: "23047",
    Name: "Xã Bình Tân",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7349,
    Code: "23050",
    Name: "Xã Phan Rí Thành",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7350,
    Code: "23053",
    Name: "Xã Hòa Thắng",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7351,
    Code: "23056",
    Name: "Xã Hồng Phong",
    LevelUp: "Xã",
    DistrictCode: "4703",
    Active: -1,
  },
  {
    ID: 7352,
    Code: "23059",
    Name: "Thị trấn Ma Lâm",
    LevelUp: "Thị trấn",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7353,
    Code: "23062",
    Name: "Thị trấn Phú Long",
    LevelUp: "Thị trấn",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7354,
    Code: "23065",
    Name: "Xã La Dạ",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7355,
    Code: "23068",
    Name: "Xã Đông Tiến",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7356,
    Code: "23071",
    Name: "Xã Thuận Hòa",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7357,
    Code: "23074",
    Name: "Xã Đông Giang",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7358,
    Code: "23077",
    Name: "Xã Hàm Phú",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7359,
    Code: "23080",
    Name: "Xã Hồng Liêm",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7360,
    Code: "23083",
    Name: "Xã Thuận Minh",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7361,
    Code: "23086",
    Name: "Xã Hồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7362,
    Code: "23089",
    Name: "Xã Hàm Trí",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7363,
    Code: "23092",
    Name: "Xã Hàm Đức",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7364,
    Code: "23095",
    Name: "Xã Hàm Liêm",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7365,
    Code: "23098",
    Name: "Xã Hàm Chính",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7366,
    Code: "23101",
    Name: "Xã Hàm Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7367,
    Code: "23104",
    Name: "Xã Hàm Thắng",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7368,
    Code: "23107",
    Name: "Xã Đa Mi",
    LevelUp: "Xã",
    DistrictCode: "4704",
    Active: -1,
  },
  {
    ID: 7369,
    Code: "23110",
    Name: "Thị trấn Thuận Nam",
    LevelUp: "Thị trấn",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7370,
    Code: "23113",
    Name: "Xã Mỹ Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7371,
    Code: "23116",
    Name: "Xã Hàm Cần",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7372,
    Code: "23119",
    Name: "Xã Mương Mán",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7373,
    Code: "23122",
    Name: "Xã Hàm Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7374,
    Code: "23125",
    Name: "Xã Hàm Kiệm",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7375,
    Code: "23128",
    Name: "Xã Hàm Cường",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7376,
    Code: "23131",
    Name: "Xã Hàm Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7377,
    Code: "23134",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7378,
    Code: "23137",
    Name: "Xã Hàm Minh",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7379,
    Code: "23140",
    Name: "Xã Thuận Quí",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7380,
    Code: "23143",
    Name: "Xã Tân Thuận",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7381,
    Code: "23146",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4705",
    Active: -1,
  },
  {
    ID: 7382,
    Code: "23149",
    Name: "Thị trấn Lạc Tánh",
    LevelUp: "Thị trấn",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7383,
    Code: "23152",
    Name: "Xã Bắc Ruộng",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7384,
    Code: "23158",
    Name: "Xã Nghị Đức",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7385,
    Code: "23161",
    Name: "Xã La Ngâu",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7386,
    Code: "23164",
    Name: "Xã Huy Khiêm",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7387,
    Code: "23167",
    Name: "Xã Măng Tố",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7388,
    Code: "23170",
    Name: "Xã Đức Phú",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7389,
    Code: "23173",
    Name: "Xã Đồng Kho",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7390,
    Code: "23176",
    Name: "Xã Gia An",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7391,
    Code: "23179",
    Name: "Xã Đức Bình",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7392,
    Code: "23182",
    Name: "Xã Gia Huynh",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7393,
    Code: "23185",
    Name: "Xã Đức Thuận",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7394,
    Code: "23188",
    Name: "Xã Suối Kiết",
    LevelUp: "Xã",
    DistrictCode: "4708",
    Active: -1,
  },
  {
    ID: 7395,
    Code: "23191",
    Name: "Thị trấn Võ Xu",
    LevelUp: "Thị trấn",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7396,
    Code: "23194",
    Name: "Thị trấn Đức Tài",
    LevelUp: "Thị trấn",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7397,
    Code: "23197",
    Name: "Xã Đa Kai",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7398,
    Code: "23200",
    Name: "Xã Sùng Nhơn",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7399,
    Code: "23203",
    Name: "Xã Mê Pu",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7400,
    Code: "23206",
    Name: "Xã Nam Chính",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7401,
    Code: "23212",
    Name: "Xã Đức Hạnh",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7402,
    Code: "23215",
    Name: "Xã Đức Tín",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7403,
    Code: "23218",
    Name: "Xã Vũ Hoà",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7404,
    Code: "23221",
    Name: "Xã Tân Hà",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7405,
    Code: "23224",
    Name: "Xã Đông Hà",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7406,
    Code: "23227",
    Name: "Xã Trà Tân",
    LevelUp: "Xã",
    DistrictCode: "4707",
    Active: -1,
  },
  {
    ID: 7407,
    Code: "23230",
    Name: "Thị trấn Tân Minh",
    LevelUp: "Thị trấn",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7408,
    Code: "23236",
    Name: "Thị trấn Tân Nghĩa",
    LevelUp: "Thị trấn",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7409,
    Code: "23239",
    Name: "Xã Sông Phan",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7410,
    Code: "23242",
    Name: "Xã Tân Phúc",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7411,
    Code: "23251",
    Name: "Xã Tân Đức",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7412,
    Code: "23254",
    Name: "Xã Tân Thắng",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7413,
    Code: "23255",
    Name: "Xã Thắng Hải",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7414,
    Code: "23257",
    Name: "Xã Tân Hà",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7415,
    Code: "23260",
    Name: "Xã Tân Xuân",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7416,
    Code: "23266",
    Name: "Xã Sơn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4706",
    Active: -1,
  },
  {
    ID: 7417,
    Code: "23272",
    Name: "Xã Ngũ Phụng",
    LevelUp: "Xã",
    DistrictCode: "4709",
    Active: -1,
  },
  {
    ID: 7418,
    Code: "23275",
    Name: "Xã Long Hải",
    LevelUp: "Xã",
    DistrictCode: "4709",
    Active: -1,
  },
  {
    ID: 7419,
    Code: "23278",
    Name: "Xã Tam Thanh",
    LevelUp: "Xã",
    DistrictCode: "4709",
    Active: -1,
  },
  {
    ID: 7420,
    Code: "23281",
    Name: "Phường Quang Trung",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7421,
    Code: "23284",
    Name: "Phường Duy Tân",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7422,
    Code: "23287",
    Name: "Phường Quyết Thắng",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7423,
    Code: "23290",
    Name: "Phường Trường Chinh",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7424,
    Code: "23293",
    Name: "Phường Thắng Lợi",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7425,
    Code: "23296",
    Name: "Phường Ngô Mây",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7426,
    Code: "23299",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7427,
    Code: "23302",
    Name: "Phường Lê Lợi",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7428,
    Code: "23305",
    Name: "Phường Nguyễn Trãi",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7429,
    Code: "23308",
    Name: "Phường Trần Hưng Đạo",
    LevelUp: "Phường",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7430,
    Code: "23311",
    Name: "Xã Đắk Cấm",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7431,
    Code: "23314",
    Name: "Xã Kroong",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7432,
    Code: "23317",
    Name: "Xã Ngọk Bay",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7433,
    Code: "23320",
    Name: "Xã Vinh Quang",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7434,
    Code: "23323",
    Name: "Xã Đắk Blà",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7435,
    Code: "23326",
    Name: "Xã Ia Chim",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7436,
    Code: "23327",
    Name: "Xã Đăk Năng",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7437,
    Code: "23329",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7438,
    Code: "23332",
    Name: "Xã Chư Hreng",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7439,
    Code: "23335",
    Name: "Xã Đắk Rơ Wa",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7440,
    Code: "23338",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "3601",
    Active: -1,
  },
  {
    ID: 7441,
    Code: "23341",
    Name: "Thị trấn Đắk Glei",
    LevelUp: "Thị trấn",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7442,
    Code: "23344",
    Name: "Xã Đắk Blô",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7443,
    Code: "23347",
    Name: "Xã Đắk Man",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7444,
    Code: "23350",
    Name: "Xã Đắk Nhoong",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7445,
    Code: "23353",
    Name: "Xã Đắk Pék",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7446,
    Code: "23356",
    Name: "Xã Đắk Choong",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7447,
    Code: "23359",
    Name: "Xã Xốp",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7448,
    Code: "23362",
    Name: "Xã Mường Hoong",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7449,
    Code: "23365",
    Name: "Xã Ngọc Linh",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7450,
    Code: "23368",
    Name: "Xã Đắk Long",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7451,
    Code: "23371",
    Name: "Xã Đắk KRoong",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7452,
    Code: "23374",
    Name: "Xã Đắk Môn",
    LevelUp: "Xã",
    DistrictCode: "3602",
    Active: -1,
  },
  {
    ID: 7453,
    Code: "23377",
    Name: "Thị trấn Plei Kần",
    LevelUp: "Thị trấn",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7454,
    Code: "23380",
    Name: "Xã Đắk Ang",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7455,
    Code: "23383",
    Name: "Xã Đắk Dục",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7456,
    Code: "23386",
    Name: "Xã Đắk Nông",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7457,
    Code: "23389",
    Name: "Xã Đắk Xú",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7458,
    Code: "23392",
    Name: "Xã Đắk Kan",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7459,
    Code: "23395",
    Name: "Xã Bờ Y",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7460,
    Code: "23398",
    Name: "Xã Sa Loong",
    LevelUp: "Xã",
    DistrictCode: "3603",
    Active: -1,
  },
  {
    ID: 7461,
    Code: "23401",
    Name: "Thị trấn Đắk Tô",
    LevelUp: "Thị trấn",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7462,
    Code: "23427",
    Name: "Xã Đắk Rơ Nga",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7463,
    Code: "23428",
    Name: "Xã Ngọk Tụ",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7464,
    Code: "23430",
    Name: "Xã Đắk Trăm",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7465,
    Code: "23431",
    Name: "Xã Văn Lem",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7466,
    Code: "23434",
    Name: "Xã Kon Đào",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7467,
    Code: "23437",
    Name: "Xã Tân Cảnh",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7468,
    Code: "23440",
    Name: "Xã Diên Bình",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7469,
    Code: "23443",
    Name: "Xã Pô Kô",
    LevelUp: "Xã",
    DistrictCode: "3604",
    Active: -1,
  },
  {
    ID: 7470,
    Code: "23452",
    Name: "Xã Đắk Nên",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7471,
    Code: "23455",
    Name: "Xã Đắk Ring",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7472,
    Code: "23458",
    Name: "Xã Măng Buk",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7473,
    Code: "23461",
    Name: "Xã Đắk Tăng",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7474,
    Code: "23464",
    Name: "Xã Ngok Tem",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7475,
    Code: "23467",
    Name: "Xã Pờ Ê",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7476,
    Code: "23470",
    Name: "Xã Măng Cành",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7477,
    Code: "23473",
    Name: "Thị trấn Măng Đen",
    LevelUp: "Thị trấn",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7478,
    Code: "23476",
    Name: "Xã Hiếu",
    LevelUp: "Xã",
    DistrictCode: "3606",
    Active: -1,
  },
  {
    ID: 7479,
    Code: "23479",
    Name: "Thị trấn Đắk Rve",
    LevelUp: "Thị trấn",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7480,
    Code: "23482",
    Name: "Xã Đắk Kôi",
    LevelUp: "Xã",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7481,
    Code: "23485",
    Name: "Xã Đắk Tơ Lung",
    LevelUp: "Xã",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7482,
    Code: "23488",
    Name: "Xã Đắk Ruồng",
    LevelUp: "Xã",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7483,
    Code: "23491",
    Name: "Xã Đắk Pne",
    LevelUp: "Xã",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7484,
    Code: "23494",
    Name: "Xã Đắk Tờ Re",
    LevelUp: "Xã",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7485,
    Code: "23497",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "3608",
    Active: -1,
  },
  {
    ID: 7486,
    Code: "23500",
    Name: "Thị trấn Đắk Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7487,
    Code: "23503",
    Name: "Xã Đắk PXi",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7488,
    Code: "23504",
    Name: "Xã Đăk Long",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7489,
    Code: "23506",
    Name: "Xã Đắk HRing",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7490,
    Code: "23509",
    Name: "Xã Đắk Ui",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7491,
    Code: "23510",
    Name: "Xã Đăk Ngọk",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7492,
    Code: "23512",
    Name: "Xã Đắk Mar",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7493,
    Code: "23515",
    Name: "Xã Ngok Wang",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7494,
    Code: "23518",
    Name: "Xã Ngok Réo",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7495,
    Code: "23521",
    Name: "Xã Hà Mòn",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7496,
    Code: "23524",
    Name: "Xã Đắk La",
    LevelUp: "Xã",
    DistrictCode: "3607",
    Active: -1,
  },
  {
    ID: 7497,
    Code: "23527",
    Name: "Thị trấn Sa Thầy",
    LevelUp: "Thị trấn",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7498,
    Code: "23530",
    Name: "Xã Rơ Kơi",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7499,
    Code: "23533",
    Name: "Xã Sa Nhơn",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7500,
    Code: "23534",
    Name: "Xã Hơ Moong",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7501,
    Code: "23536",
    Name: "Xã Mô Rai",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7502,
    Code: "23539",
    Name: "Xã Sa Sơn",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7503,
    Code: "23542",
    Name: "Xã Sa Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7504,
    Code: "23545",
    Name: "Xã Sa Bình",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7505,
    Code: "23548",
    Name: "Xã Ya Xiêr",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7506,
    Code: "23551",
    Name: "Xã Ya Tăng",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7507,
    Code: "23554",
    Name: "Xã Ya ly",
    LevelUp: "Xã",
    DistrictCode: "3605",
    Active: -1,
  },
  {
    ID: 7508,
    Code: "23404",
    Name: "Xã Ngọc Lây",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7509,
    Code: "23407",
    Name: "Xã Đắk Na",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7510,
    Code: "23410",
    Name: "Xã Măng Ri",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7511,
    Code: "23413",
    Name: "Xã Ngọc Yêu",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7512,
    Code: "23416",
    Name: "Xã Đắk Sao",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7513,
    Code: "23417",
    Name: "Xã Đắk Rơ Ông",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7514,
    Code: "23419",
    Name: "Xã Đắk Tờ Kan",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7515,
    Code: "23422",
    Name: "Xã Tu Mơ Rông",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7516,
    Code: "23425",
    Name: "Xã Đắk Hà",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7517,
    Code: "23446",
    Name: "Xã Tê Xăng",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7518,
    Code: "23449",
    Name: "Xã Văn Xuôi",
    LevelUp: "Xã",
    DistrictCode: "3609",
    Active: -1,
  },
  {
    ID: 7519,
    Code: "23535",
    Name: "Xã Ia Đal",
    LevelUp: "Xã",
    DistrictCode: "3610",
    Active: -1,
  },
  {
    ID: 7520,
    Code: "23537",
    Name: "Xã Ia Dom",
    LevelUp: "Xã",
    DistrictCode: "3610",
    Active: -1,
  },
  {
    ID: 7521,
    Code: "23538",
    Name: "Xã Ia Tơi",
    LevelUp: "Xã",
    DistrictCode: "3610",
    Active: -1,
  },
  {
    ID: 7522,
    Code: "23557",
    Name: "Phường Yên Đỗ",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7523,
    Code: "23560",
    Name: "Phường Diên Hồng",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7524,
    Code: "23563",
    Name: "Phường Ia Kring",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7525,
    Code: "23566",
    Name: "Phường Hội Thương",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7526,
    Code: "23569",
    Name: "Phường Hội Phú",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7527,
    Code: "23570",
    Name: "Phường Phù Đổng",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7528,
    Code: "23572",
    Name: "Phường Hoa Lư",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7529,
    Code: "23575",
    Name: "Phường Tây Sơn",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7530,
    Code: "23578",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7531,
    Code: "23579",
    Name: "Phường Đống Đa",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7532,
    Code: "23581",
    Name: "Phường Trà Bá",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7533,
    Code: "23582",
    Name: "Phường Thắng Lợi",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7534,
    Code: "23584",
    Name: "Phường Yên Thế",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7535,
    Code: "23586",
    Name: "Phường Chi Lăng",
    LevelUp: "Phường",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7536,
    Code: "23590",
    Name: "Xã Biển Hồ",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7537,
    Code: "23593",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7538,
    Code: "23596",
    Name: "Xã Trà Đa",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7539,
    Code: "23599",
    Name: "Xã Chư Á",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7540,
    Code: "23602",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7541,
    Code: "23605",
    Name: "Xã Diên Phú",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7542,
    Code: "23608",
    Name: "Xã Ia Kênh",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7543,
    Code: "23611",
    Name: "Xã Gào",
    LevelUp: "Xã",
    DistrictCode: "3801",
    Active: -1,
  },
  {
    ID: 7544,
    Code: "23614",
    Name: "Phường An Bình",
    LevelUp: "Phường",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7545,
    Code: "23617",
    Name: "Phường Tây Sơn",
    LevelUp: "Phường",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7546,
    Code: "23620",
    Name: "Phường An Phú",
    LevelUp: "Phường",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7547,
    Code: "23623",
    Name: "Phường An Tân",
    LevelUp: "Phường",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7548,
    Code: "23626",
    Name: "Xã Tú An",
    LevelUp: "Xã",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7549,
    Code: "23627",
    Name: "Xã Xuân An",
    LevelUp: "Xã",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7550,
    Code: "23629",
    Name: "Xã Cửu An",
    LevelUp: "Xã",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7551,
    Code: "23630",
    Name: "Phường An Phước",
    LevelUp: "Phường",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7552,
    Code: "23632",
    Name: "Xã Song An",
    LevelUp: "Xã",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7553,
    Code: "23633",
    Name: "Phường Ngô Mây",
    LevelUp: "Phường",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7554,
    Code: "23635",
    Name: "Xã Thành An",
    LevelUp: "Xã",
    DistrictCode: "3805",
    Active: -1,
  },
  {
    ID: 7555,
    Code: "24041",
    Name: "Phường Cheo Reo",
    LevelUp: "Phường",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7556,
    Code: "24042",
    Name: "Phường Hòa Bình",
    LevelUp: "Phường",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7557,
    Code: "24044",
    Name: "Phường Đoàn Kết",
    LevelUp: "Phường",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7558,
    Code: "24045",
    Name: "Phường Sông Bờ",
    LevelUp: "Phường",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7559,
    Code: "24064",
    Name: "Xã Ia RBol",
    LevelUp: "Xã",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7560,
    Code: "24065",
    Name: "Xã Chư Băh",
    LevelUp: "Xã",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7561,
    Code: "24070",
    Name: "Xã Ia RTô",
    LevelUp: "Xã",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7562,
    Code: "24073",
    Name: "Xã Ia Sao",
    LevelUp: "Xã",
    DistrictCode: "3810",
    Active: -1,
  },
  {
    ID: 7563,
    Code: "23638",
    Name: "Thị trấn KBang",
    LevelUp: "Thị trấn",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7564,
    Code: "23641",
    Name: "Xã Kon Pne",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7565,
    Code: "23644",
    Name: "Xã Đăk Roong",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7566,
    Code: "23647",
    Name: "Xã Sơn Lang",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7567,
    Code: "23650",
    Name: "Xã KRong",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7568,
    Code: "23653",
    Name: "Xã Sơ Pai",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7569,
    Code: "23656",
    Name: "Xã Lơ Ku",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7570,
    Code: "23659",
    Name: "Xã Đông",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7571,
    Code: "23660",
    Name: "Xã Đak SMar",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7572,
    Code: "23662",
    Name: "Xã Nghĩa An",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7573,
    Code: "23665",
    Name: "Xã Tơ Tung",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7574,
    Code: "23668",
    Name: "Xã Kông Lơng Khơng",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7575,
    Code: "23671",
    Name: "Xã Kông Pla",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7576,
    Code: "23674",
    Name: "Xã Đăk HLơ",
    LevelUp: "Xã",
    DistrictCode: "3804",
    Active: -1,
  },
  {
    ID: 7577,
    Code: "23677",
    Name: "Thị trấn Đăk Đoa",
    LevelUp: "Thị trấn",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7578,
    Code: "23680",
    Name: "Xã Hà Đông",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7579,
    Code: "23683",
    Name: "Xã Đăk Sơmei",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7580,
    Code: "23684",
    Name: "Xã Đăk Krong",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7581,
    Code: "23686",
    Name: "Xã Hải Yang",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7582,
    Code: "23689",
    Name: "Xã Kon Gang",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7583,
    Code: "23692",
    Name: "Xã Hà Bầu",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7584,
    Code: "23695",
    Name: "Xã Nam Yang",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7585,
    Code: "23698",
    Name: "Xã K' Dang",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7586,
    Code: "23701",
    Name: "Xã H' Neng",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7587,
    Code: "23704",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7588,
    Code: "23707",
    Name: "Xã Glar",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7589,
    Code: "23710",
    Name: "Xã A Dơk",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7590,
    Code: "23713",
    Name: "Xã Trang",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7591,
    Code: "23714",
    Name: "Xã HNol",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7592,
    Code: "23716",
    Name: "Xã Ia Pết",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7593,
    Code: "23719",
    Name: "Xã Ia Băng",
    LevelUp: "Xã",
    DistrictCode: "3813",
    Active: -1,
  },
  {
    ID: 7594,
    Code: "23722",
    Name: "Thị trấn Phú Hòa",
    LevelUp: "Thị trấn",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7595,
    Code: "23725",
    Name: "Xã Hà Tây",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7596,
    Code: "23728",
    Name: "Xã Ia Khươl",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7597,
    Code: "23731",
    Name: "Xã Ia Phí",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7598,
    Code: "23734",
    Name: "Thị trấn Ia Ly",
    LevelUp: "Thị trấn",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7599,
    Code: "23737",
    Name: "Xã Ia Mơ Nông",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7600,
    Code: "23738",
    Name: "Xã Ia Kreng",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7601,
    Code: "23740",
    Name: "Xã Đăk Tơ Ver",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7602,
    Code: "23743",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7603,
    Code: "23746",
    Name: "Xã Chư Đăng Ya",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7604,
    Code: "23749",
    Name: "Xã Ia Ka",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7605,
    Code: "23752",
    Name: "Xã Ia Nhin",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7606,
    Code: "23755",
    Name: "Xã Nghĩa Hòa",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7607,
    Code: "23761",
    Name: "Xã Nghĩa Hưng",
    LevelUp: "Xã",
    DistrictCode: "3802",
    Active: -1,
  },
  {
    ID: 7608,
    Code: "23764",
    Name: "Thị trấn Ia Kha",
    LevelUp: "Thị trấn",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7609,
    Code: "23767",
    Name: "Xã Ia Sao",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7610,
    Code: "23768",
    Name: "Xã Ia Yok",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7611,
    Code: "23770",
    Name: "Xã Ia Hrung",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7612,
    Code: "23771",
    Name: "Xã Ia Bă",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7613,
    Code: "23773",
    Name: "Xã Ia Khai",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7614,
    Code: "23776",
    Name: "Xã Ia KRai",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7615,
    Code: "23778",
    Name: "Xã Ia Grăng",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7616,
    Code: "23779",
    Name: "Xã Ia Tô",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7617,
    Code: "23782",
    Name: "Xã Ia O",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7618,
    Code: "23785",
    Name: "Xã Ia Dêr",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7619,
    Code: "23788",
    Name: "Xã Ia Chia",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7620,
    Code: "23791",
    Name: "Xã Ia Pếch",
    LevelUp: "Xã",
    DistrictCode: "3812",
    Active: -1,
  },
  {
    ID: 7621,
    Code: "23794",
    Name: "Thị trấn Kon Dơng",
    LevelUp: "Thị trấn",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7622,
    Code: "23797",
    Name: "Xã Ayun",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7623,
    Code: "23798",
    Name: "Xã Đak Jơ Ta",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7624,
    Code: "23799",
    Name: "Xã Đak Ta Ley",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7625,
    Code: "23800",
    Name: "Xã Hra",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7626,
    Code: "23803",
    Name: "Xã Đăk Yă",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7627,
    Code: "23806",
    Name: "Xã Đăk Djrăng",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7628,
    Code: "23809",
    Name: "Xã Lơ Pang",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7629,
    Code: "23812",
    Name: "Xã Kon Thụp",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7630,
    Code: "23815",
    Name: "Xã Đê Ar",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7631,
    Code: "23818",
    Name: "Xã Kon Chiêng",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7632,
    Code: "23821",
    Name: "Xã Đăk Trôi",
    LevelUp: "Xã",
    DistrictCode: "3803",
    Active: -1,
  },
  {
    ID: 7633,
    Code: "23824",
    Name: "Thị trấn Kông Chro",
    LevelUp: "Thị trấn",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7634,
    Code: "23827",
    Name: "Xã Chư Krêy",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7635,
    Code: "23830",
    Name: "Xã An Trung",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7636,
    Code: "23833",
    Name: "Xã Kông Yang",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7637,
    Code: "23836",
    Name: "Xã Đăk Tơ Pang",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7638,
    Code: "23839",
    Name: "Xã SRó",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7639,
    Code: "23840",
    Name: "Xã Đắk Kơ Ning",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7640,
    Code: "23842",
    Name: "Xã Đăk Song",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7641,
    Code: "23843",
    Name: "Xã Đăk Pling",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7642,
    Code: "23845",
    Name: "Xã Yang Trung",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7643,
    Code: "23846",
    Name: "Xã Đăk Pơ Pho",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7644,
    Code: "23848",
    Name: "Xã Ya Ma",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7645,
    Code: "23851",
    Name: "Xã Chơ Long",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7646,
    Code: "23854",
    Name: "Xã Yang Nam",
    LevelUp: "Xã",
    DistrictCode: "3806",
    Active: -1,
  },
  {
    ID: 7647,
    Code: "23857",
    Name: "Thị trấn Chư Ty",
    LevelUp: "Thị trấn",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7648,
    Code: "23860",
    Name: "Xã Ia Dơk",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7649,
    Code: "23863",
    Name: "Xã Ia Krêl",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7650,
    Code: "23866",
    Name: "Xã Ia Din",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7651,
    Code: "23869",
    Name: "Xã Ia Kla",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7652,
    Code: "23872",
    Name: "Xã Ia Dom",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7653,
    Code: "23875",
    Name: "Xã Ia Lang",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7654,
    Code: "23878",
    Name: "Xã Ia Kriêng",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7655,
    Code: "23881",
    Name: "Xã Ia Pnôn",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7656,
    Code: "23884",
    Name: "Xã Ia Nan",
    LevelUp: "Xã",
    DistrictCode: "3807",
    Active: -1,
  },
  {
    ID: 7657,
    Code: "23887",
    Name: "Thị trấn Chư Prông",
    LevelUp: "Thị trấn",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7658,
    Code: "23888",
    Name: "Xã Ia Kly",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7659,
    Code: "23890",
    Name: "Xã Bình Giáo",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7660,
    Code: "23893",
    Name: "Xã Ia Drăng",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7661,
    Code: "23896",
    Name: "Xã Thăng Hưng",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7662,
    Code: "23899",
    Name: "Xã Bàu Cạn",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7663,
    Code: "23902",
    Name: "Xã Ia Phìn",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7664,
    Code: "23905",
    Name: "Xã Ia Băng",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7665,
    Code: "23908",
    Name: "Xã Ia Tôr",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7666,
    Code: "23911",
    Name: "Xã Ia Boòng",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7667,
    Code: "23914",
    Name: "Xã Ia O",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7668,
    Code: "23917",
    Name: "Xã Ia Púch",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7669,
    Code: "23920",
    Name: "Xã Ia Me",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7670,
    Code: "23923",
    Name: "Xã Ia Vê",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7671,
    Code: "23924",
    Name: "Xã Ia Bang",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7672,
    Code: "23926",
    Name: "Xã Ia Pia",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7673,
    Code: "23929",
    Name: "Xã Ia Ga",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7674,
    Code: "23932",
    Name: "Xã Ia Lâu",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7675,
    Code: "23935",
    Name: "Xã Ia Piơr",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7676,
    Code: "23938",
    Name: "Xã Ia Mơ",
    LevelUp: "Xã",
    DistrictCode: "3808",
    Active: -1,
  },
  {
    ID: 7677,
    Code: "23941",
    Name: "Thị trấn Chư Sê",
    LevelUp: "Thị trấn",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7678,
    Code: "23944",
    Name: "Xã Ia Tiêm",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7679,
    Code: "23945",
    Name: "Xã Chư Pơng",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7680,
    Code: "23946",
    Name: "Xã Bar Măih",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7681,
    Code: "23947",
    Name: "Xã Bờ Ngoong",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7682,
    Code: "23950",
    Name: "Xã Ia Glai",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7683,
    Code: "23953",
    Name: "Xã AL Bá",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7684,
    Code: "23954",
    Name: "Xã Kông HTok",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7685,
    Code: "23956",
    Name: "Xã AYun",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7686,
    Code: "23959",
    Name: "Xã Ia HLốp",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7687,
    Code: "23962",
    Name: "Xã Ia Blang",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7688,
    Code: "23965",
    Name: "Xã Dun",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7689,
    Code: "23966",
    Name: "Xã Ia Pal",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7690,
    Code: "23968",
    Name: "Xã H Bông",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7691,
    Code: "23977",
    Name: "Xã Ia Ko",
    LevelUp: "Xã",
    DistrictCode: "3809",
    Active: -1,
  },
  {
    ID: 7692,
    Code: "23989",
    Name: "Xã Hà Tam",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7693,
    Code: "23992",
    Name: "Xã An Thành",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7694,
    Code: "23995",
    Name: "Thị trấn Đak Pơ",
    LevelUp: "Thị trấn",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7695,
    Code: "23998",
    Name: "Xã Yang Bắc",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7696,
    Code: "24001",
    Name: "Xã Cư An",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7697,
    Code: "24004",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7698,
    Code: "24007",
    Name: "Xã Phú An",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7699,
    Code: "24010",
    Name: "Xã Ya Hội",
    LevelUp: "Xã",
    DistrictCode: "3815",
    Active: -1,
  },
  {
    ID: 7700,
    Code: "24013",
    Name: "Xã Pờ Tó",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7701,
    Code: "24016",
    Name: "Xã Chư Răng",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7702,
    Code: "24019",
    Name: "Xã Ia KDăm",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7703,
    Code: "24022",
    Name: "Xã Kim Tân",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7704,
    Code: "24025",
    Name: "Xã Chư Mố",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7705,
    Code: "24028",
    Name: "Xã Ia Tul",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7706,
    Code: "24031",
    Name: "Xã Ia Ma Rơn",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7707,
    Code: "24034",
    Name: "Xã Ia Broăi",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7708,
    Code: "24037",
    Name: "Xã Ia Trok",
    LevelUp: "Xã",
    DistrictCode: "3814",
    Active: -1,
  },
  {
    ID: 7709,
    Code: "24076",
    Name: "Thị trấn Phú Túc",
    LevelUp: "Thị trấn",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7710,
    Code: "24079",
    Name: "Xã Ia RSai",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7711,
    Code: "24082",
    Name: "Xã Ia RSươm",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7712,
    Code: "24085",
    Name: "Xã Chư Gu",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7713,
    Code: "24088",
    Name: "Xã Đất Bằng",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7714,
    Code: "24091",
    Name: "Xã Ia Mláh",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7715,
    Code: "24094",
    Name: "Xã Chư Drăng",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7716,
    Code: "24097",
    Name: "Xã Phú Cần",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7717,
    Code: "24100",
    Name: "Xã Ia HDreh",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7718,
    Code: "24103",
    Name: "Xã Ia RMok",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7719,
    Code: "24106",
    Name: "Xã Chư Ngọc",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7720,
    Code: "24109",
    Name: "Xã Uar",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7721,
    Code: "24112",
    Name: "Xã Chư Rcăm",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7722,
    Code: "24115",
    Name: "Xã Krông Năng",
    LevelUp: "Xã",
    DistrictCode: "3811",
    Active: -1,
  },
  {
    ID: 7723,
    Code: "24043",
    Name: "Thị trấn Phú Thiện",
    LevelUp: "Thị trấn",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7724,
    Code: "24046",
    Name: "Xã Chư A Thai",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7725,
    Code: "24048",
    Name: "Xã Ayun Hạ",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7726,
    Code: "24049",
    Name: "Xã Ia Ake",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7727,
    Code: "24052",
    Name: "Xã Ia Sol",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7728,
    Code: "24055",
    Name: "Xã Ia Piar",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7729,
    Code: "24058",
    Name: "Xã Ia Peng",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7730,
    Code: "24060",
    Name: "Xã Chrôh Pơnan",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7731,
    Code: "24061",
    Name: "Xã Ia Hiao",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7732,
    Code: "24067",
    Name: "Xã Ia Yeng",
    LevelUp: "Xã",
    DistrictCode: "3816",
    Active: -1,
  },
  {
    ID: 7733,
    Code: "23942",
    Name: "Thị trấn Nhơn Hoà",
    LevelUp: "Thị trấn",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7734,
    Code: "23971",
    Name: "Xã Ia Hrú",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7735,
    Code: "23972",
    Name: "Xã Ia Rong",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7736,
    Code: "23974",
    Name: "Xã Ia Dreng",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7737,
    Code: "23978",
    Name: "Xã Ia Hla",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7738,
    Code: "23980",
    Name: "Xã Chư Don",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7739,
    Code: "23983",
    Name: "Xã Ia Phang",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7740,
    Code: "23986",
    Name: "Xã Ia Le",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7741,
    Code: "23987",
    Name: "Xã Ia BLứ",
    LevelUp: "Xã",
    DistrictCode: "3817",
    Active: -1,
  },
  {
    ID: 7742,
    Code: "24118",
    Name: "Phường Tân Lập",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7743,
    Code: "24121",
    Name: "Phường Tân Hòa",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7744,
    Code: "24124",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7745,
    Code: "24127",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7746,
    Code: "24130",
    Name: "Phường Thành Nhất",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7747,
    Code: "24133",
    Name: "Phường Thắng Lợi",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7748,
    Code: "24136",
    Name: "Phường Tân Lợi",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7749,
    Code: "24139",
    Name: "Phường Thành Công",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7750,
    Code: "24142",
    Name: "Phường Tân Thành",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7751,
    Code: "24145",
    Name: "Phường Tân Tiến",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7752,
    Code: "24148",
    Name: "Phường Tự An",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7753,
    Code: "24151",
    Name: "Phường Ea Tam",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7754,
    Code: "24154",
    Name: "Phường Khánh Xuân",
    LevelUp: "Phường",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7755,
    Code: "24157",
    Name: "Xã Hòa Thuận",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7756,
    Code: "24160",
    Name: "Xã Cư ÊBur",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7757,
    Code: "24163",
    Name: "Xã Ea Tu",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7758,
    Code: "24166",
    Name: "Xã Hòa Thắng",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7759,
    Code: "24169",
    Name: "Xã Ea Kao",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7760,
    Code: "24172",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7761,
    Code: "24175",
    Name: "Xã Hòa Khánh",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7762,
    Code: "24178",
    Name: "Xã Hòa Xuân",
    LevelUp: "Xã",
    DistrictCode: "4001",
    Active: -1,
  },
  {
    ID: 7763,
    Code: "24305",
    Name: "Phường An Lạc",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7764,
    Code: "24308",
    Name: "Phường An Bình",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7765,
    Code: "24311",
    Name: "Phường Thiện An",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7766,
    Code: "24318",
    Name: "Phường Đạt Hiếu",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7767,
    Code: "24322",
    Name: "Phường Đoàn Kết",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7768,
    Code: "24325",
    Name: "Xã Ea Blang",
    LevelUp: "Xã",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7769,
    Code: "24328",
    Name: "Xã Ea Drông",
    LevelUp: "Xã",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7770,
    Code: "24331",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7771,
    Code: "24332",
    Name: "Phường Bình Tân",
    LevelUp: "Phường",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7772,
    Code: "24334",
    Name: "Xã Ea Siên",
    LevelUp: "Xã",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7773,
    Code: "24337",
    Name: "Xã Bình Thuận",
    LevelUp: "Xã",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7774,
    Code: "24340",
    Name: "Xã Cư Bao",
    LevelUp: "Xã",
    DistrictCode: "4015",
    Active: -1,
  },
  {
    ID: 7775,
    Code: "24181",
    Name: "Thị trấn Ea Drăng",
    LevelUp: "Thị trấn",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7776,
    Code: "24184",
    Name: "Xã Ea H'leo",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7777,
    Code: "24187",
    Name: "Xã Ea Sol",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7778,
    Code: "24190",
    Name: "Xã Ea Ral",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7779,
    Code: "24193",
    Name: "Xã Ea Wy",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7780,
    Code: "24194",
    Name: "Xã Cư A Mung",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7781,
    Code: "24196",
    Name: "Xã Cư Mốt",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7782,
    Code: "24199",
    Name: "Xã Ea Hiao",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7783,
    Code: "24202",
    Name: "Xã Ea Khal",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7784,
    Code: "24205",
    Name: "Xã Dliê Yang",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7785,
    Code: "24207",
    Name: "Xã Ea Tir",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7786,
    Code: "24208",
    Name: "Xã Ea Nam",
    LevelUp: "Xã",
    DistrictCode: "4002",
    Active: -1,
  },
  {
    ID: 7787,
    Code: "24211",
    Name: "Thị trấn Ea Súp",
    LevelUp: "Thị trấn",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7788,
    Code: "24214",
    Name: "Xã Ia Lốp",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7789,
    Code: "24215",
    Name: "Xã Ia JLơi",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7790,
    Code: "24217",
    Name: "Xã Ea Rốk",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7791,
    Code: "24220",
    Name: "Xã Ya Tờ Mốt",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7792,
    Code: "24221",
    Name: "Xã Ia RVê",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7793,
    Code: "24223",
    Name: "Xã Ea Lê",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7794,
    Code: "24226",
    Name: "Xã Cư KBang",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7795,
    Code: "24229",
    Name: "Xã Ea Bung",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7796,
    Code: "24232",
    Name: "Xã Cư M'Lan",
    LevelUp: "Xã",
    DistrictCode: "4005",
    Active: -1,
  },
  {
    ID: 7797,
    Code: "24235",
    Name: "Xã Krông Na",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7798,
    Code: "24238",
    Name: "Xã Ea Huar",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7799,
    Code: "24241",
    Name: "Xã Ea Wer",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7800,
    Code: "24244",
    Name: "Xã Tân Hoà",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7801,
    Code: "24247",
    Name: "Xã Cuôr KNia",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7802,
    Code: "24250",
    Name: "Xã Ea Bar",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7803,
    Code: "24253",
    Name: "Xã Ea Nuôl",
    LevelUp: "Xã",
    DistrictCode: "4013",
    Active: -1,
  },
  {
    ID: 7804,
    Code: "24256",
    Name: "Thị trấn Ea Pốk",
    LevelUp: "Thị trấn",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7805,
    Code: "24259",
    Name: "Thị trấn Quảng Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7806,
    Code: "24262",
    Name: "Xã Quảng Tiến",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7807,
    Code: "24264",
    Name: "Xã Ea Kuêh",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7808,
    Code: "24265",
    Name: "Xã Ea Kiết",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7809,
    Code: "24268",
    Name: "Xã Ea Tar",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7810,
    Code: "24271",
    Name: "Xã Cư Dliê M'nông",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7811,
    Code: "24274",
    Name: "Xã Ea H'đinh",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7812,
    Code: "24277",
    Name: "Xã Ea Tul",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7813,
    Code: "24280",
    Name: "Xã Ea KPam",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7814,
    Code: "24283",
    Name: "Xã Ea M'DRóh",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7815,
    Code: "24286",
    Name: "Xã Quảng Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7816,
    Code: "24289",
    Name: "Xã Cư M'gar",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7817,
    Code: "24292",
    Name: "Xã Ea D'Rơng",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7818,
    Code: "24295",
    Name: "Xã Ea M'nang",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7819,
    Code: "24298",
    Name: "Xã Cư Suê",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7820,
    Code: "24301",
    Name: "Xã Cuor Đăng",
    LevelUp: "Xã",
    DistrictCode: "4006",
    Active: -1,
  },
  {
    ID: 7821,
    Code: "24307",
    Name: "Xã Cư Né",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7822,
    Code: "24310",
    Name: "Xã Chư KBô",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7823,
    Code: "24313",
    Name: "Xã Cư Pơng",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7824,
    Code: "24314",
    Name: "Xã Ea Sin",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7825,
    Code: "24316",
    Name: "Xã Pơng Drang",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7826,
    Code: "24317",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7827,
    Code: "24319",
    Name: "Xã Ea Ngai",
    LevelUp: "Xã",
    DistrictCode: "4003",
    Active: -1,
  },
  {
    ID: 7828,
    Code: "24343",
    Name: "Thị trấn Krông Năng",
    LevelUp: "Thị trấn",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7829,
    Code: "24346",
    Name: "Xã ĐLiê Ya",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7830,
    Code: "24349",
    Name: "Xã Ea Tóh",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7831,
    Code: "24352",
    Name: "Xã Ea Tam",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7832,
    Code: "24355",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7833,
    Code: "24358",
    Name: "Xã Tam Giang",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7834,
    Code: "24359",
    Name: "Xã Ea Puk",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7835,
    Code: "24360",
    Name: "Xã Ea Dăh",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7836,
    Code: "24361",
    Name: "Xã Ea Hồ",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7837,
    Code: "24364",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7838,
    Code: "24367",
    Name: "Xã Cư Klông",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7839,
    Code: "24370",
    Name: "Xã Ea Tân",
    LevelUp: "Xã",
    DistrictCode: "4004",
    Active: -1,
  },
  {
    ID: 7840,
    Code: "24373",
    Name: "Thị trấn Ea Kar",
    LevelUp: "Thị trấn",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7841,
    Code: "24376",
    Name: "Thị trấn Ea Knốp",
    LevelUp: "Thị trấn",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7842,
    Code: "24379",
    Name: "Xã Ea Sô",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7843,
    Code: "24380",
    Name: "Xã Ea Sar",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7844,
    Code: "24382",
    Name: "Xã Xuân Phú",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7845,
    Code: "24385",
    Name: "Xã Cư Huê",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7846,
    Code: "24388",
    Name: "Xã Ea Tih",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7847,
    Code: "24391",
    Name: "Xã Ea Đar",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7848,
    Code: "24394",
    Name: "Xã Ea Kmút",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7849,
    Code: "24397",
    Name: "Xã Cư Ni",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7850,
    Code: "24400",
    Name: "Xã Ea Păl",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7851,
    Code: "24401",
    Name: "Xã Cư Prông",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7852,
    Code: "24403",
    Name: "Xã Ea Ô",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7853,
    Code: "24404",
    Name: "Xã Cư ELang",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7854,
    Code: "24406",
    Name: "Xã Cư Bông",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7855,
    Code: "24409",
    Name: "Xã Cư Jang",
    LevelUp: "Xã",
    DistrictCode: "4008",
    Active: -1,
  },
  {
    ID: 7856,
    Code: "24412",
    Name: "Thị trấn M'Đrắk",
    LevelUp: "Thị trấn",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7857,
    Code: "24415",
    Name: "Xã Cư Prao",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7858,
    Code: "24418",
    Name: "Xã Ea Pil",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7859,
    Code: "24421",
    Name: "Xã Ea Lai",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7860,
    Code: "24424",
    Name: "Xã Ea H'MLay",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7861,
    Code: "24427",
    Name: "Xã Krông Jing",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7862,
    Code: "24430",
    Name: "Xã Ea M' Doal",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7863,
    Code: "24433",
    Name: "Xã Ea Riêng",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7864,
    Code: "24436",
    Name: "Xã Cư M'ta",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7865,
    Code: "24439",
    Name: "Xã Cư K Róa",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7866,
    Code: "24442",
    Name: "Xã Krông Á",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7867,
    Code: "24444",
    Name: "Xã Cư San",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7868,
    Code: "24445",
    Name: "Xã Ea Trang",
    LevelUp: "Xã",
    DistrictCode: "4009",
    Active: -1,
  },
  {
    ID: 7869,
    Code: "24448",
    Name: "Thị trấn Krông Kmar",
    LevelUp: "Thị trấn",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7870,
    Code: "24451",
    Name: "Xã Dang Kang",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7871,
    Code: "24454",
    Name: "Xã Cư KTy",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7872,
    Code: "24457",
    Name: "Xã Hòa Thành",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7873,
    Code: "24460",
    Name: "Xã Hòa Tân",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7874,
    Code: "24463",
    Name: "Xã Hòa Phong",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7875,
    Code: "24466",
    Name: "Xã Hòa Lễ",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7876,
    Code: "24469",
    Name: "Xã Yang Reh",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7877,
    Code: "24472",
    Name: "Xã Ea Trul",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7878,
    Code: "24475",
    Name: "Xã Khuê Ngọc Điền",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7879,
    Code: "24478",
    Name: "Xã Cư Pui",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7880,
    Code: "24481",
    Name: "Xã Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7881,
    Code: "24484",
    Name: "Xã Cư Drăm",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7882,
    Code: "24487",
    Name: "Xã Yang Mao",
    LevelUp: "Xã",
    DistrictCode: "4011",
    Active: -1,
  },
  {
    ID: 7883,
    Code: "24490",
    Name: "Thị trấn Phước An",
    LevelUp: "Thị trấn",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7884,
    Code: "24493",
    Name: "Xã KRông Búk",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7885,
    Code: "24496",
    Name: "Xã Ea Kly",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7886,
    Code: "24499",
    Name: "Xã Ea Kênh",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7887,
    Code: "24502",
    Name: "Xã Ea Phê",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7888,
    Code: "24505",
    Name: "Xã Ea KNuec",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7889,
    Code: "24508",
    Name: "Xã Ea Yông",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7890,
    Code: "24511",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7891,
    Code: "24514",
    Name: "Xã Ea Kuăng",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7892,
    Code: "24517",
    Name: "Xã Hòa Đông",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7893,
    Code: "24520",
    Name: "Xã Ea Hiu",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7894,
    Code: "24523",
    Name: "Xã Hòa Tiến",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7895,
    Code: "24526",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7896,
    Code: "24529",
    Name: "Xã Vụ Bổn",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7897,
    Code: "24532",
    Name: "Xã Ea Uy",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7898,
    Code: "24535",
    Name: "Xã Ea Yiêng",
    LevelUp: "Xã",
    DistrictCode: "4007",
    Active: -1,
  },
  {
    ID: 7899,
    Code: "24538",
    Name: "Thị trấn Buôn Trấp",
    LevelUp: "Thị trấn",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7900,
    Code: "24556",
    Name: "Xã Dray Sáp",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7901,
    Code: "24559",
    Name: "Xã Ea Na",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7902,
    Code: "24565",
    Name: "Xã Ea Bông",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7903,
    Code: "24568",
    Name: "Xã Băng A Drênh",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7904,
    Code: "24571",
    Name: "Xã Dur KMăl",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7905,
    Code: "24574",
    Name: "Xã Bình Hòa",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7906,
    Code: "24577",
    Name: "Xã Quảng Điền",
    LevelUp: "Xã",
    DistrictCode: "4010",
    Active: -1,
  },
  {
    ID: 7907,
    Code: "24580",
    Name: "Thị trấn Liên Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7908,
    Code: "24583",
    Name: "Xã Yang Tao",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7909,
    Code: "24586",
    Name: "Xã Bông Krang",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7910,
    Code: "24589",
    Name: "Xã Đắk Liêng",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7911,
    Code: "24592",
    Name: "Xã Buôn Triết",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7912,
    Code: "24595",
    Name: "Xã Buôn Tría",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7913,
    Code: "24598",
    Name: "Xã Đắk Phơi",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7914,
    Code: "24601",
    Name: "Xã Đắk Nuê",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7915,
    Code: "24604",
    Name: "Xã Krông Nô",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7916,
    Code: "24607",
    Name: "Xã Nam Ka",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7917,
    Code: "24610",
    Name: "Xã Ea R'Bin",
    LevelUp: "Xã",
    DistrictCode: "4012",
    Active: -1,
  },
  {
    ID: 7918,
    Code: "24540",
    Name: "Xã Ea Ning",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7919,
    Code: "24541",
    Name: "Xã Cư Ê Wi",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7920,
    Code: "24544",
    Name: "Xã Ea Ktur",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7921,
    Code: "24547",
    Name: "Xã Ea Tiêu",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7922,
    Code: "24550",
    Name: "Xã Ea BHốk",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7923,
    Code: "24553",
    Name: "Xã Ea Hu",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7924,
    Code: "24561",
    Name: "Xã Dray Bhăng",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7925,
    Code: "24562",
    Name: "Xã Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4014",
    Active: -1,
  },
  {
    ID: 7926,
    Code: "24611",
    Name: "Phường Nghĩa Đức",
    LevelUp: "Phường",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7927,
    Code: "24612",
    Name: "Phường Nghĩa Thành",
    LevelUp: "Phường",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7928,
    Code: "24614",
    Name: "Phường Nghĩa Phú",
    LevelUp: "Phường",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7929,
    Code: "24615",
    Name: "Phường Nghĩa Tân",
    LevelUp: "Phường",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7930,
    Code: "24617",
    Name: "Phường Nghĩa Trung",
    LevelUp: "Phường",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7931,
    Code: "24618",
    Name: "Xã Đăk R'Moan",
    LevelUp: "Xã",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7932,
    Code: "24619",
    Name: "Phường Quảng Thành",
    LevelUp: "Phường",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7933,
    Code: "24628",
    Name: "Xã Đắk Nia",
    LevelUp: "Xã",
    DistrictCode: "6301",
    Active: -1,
  },
  {
    ID: 7934,
    Code: "24616",
    Name: "Xã Quảng Sơn",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7935,
    Code: "24620",
    Name: "Xã Quảng Hoà",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7936,
    Code: "24622",
    Name: "Xã Đắk Ha",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7937,
    Code: "24625",
    Name: "Xã Đắk R'Măng",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7938,
    Code: "24631",
    Name: "Xã Quảng Khê",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7939,
    Code: "24634",
    Name: "Xã Đắk Plao",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7940,
    Code: "24637",
    Name: "Xã Đắk Som",
    LevelUp: "Xã",
    DistrictCode: "6307",
    Active: -1,
  },
  {
    ID: 7941,
    Code: "24640",
    Name: "Thị trấn Ea T'Ling",
    LevelUp: "Thị trấn",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7942,
    Code: "24643",
    Name: "Xã Đắk Wil",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7943,
    Code: "24646",
    Name: "Xã Ea Pô",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7944,
    Code: "24649",
    Name: "Xã Nam Dong",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7945,
    Code: "24652",
    Name: "Xã Đắk DRông",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7946,
    Code: "24655",
    Name: "Xã Tâm Thắng",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7947,
    Code: "24658",
    Name: "Xã Cư Knia",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7948,
    Code: "24661",
    Name: "Xã Trúc Sơn",
    LevelUp: "Xã",
    DistrictCode: "6304",
    Active: -1,
  },
  {
    ID: 7949,
    Code: "24664",
    Name: "Thị trấn Đắk Mil",
    LevelUp: "Thị trấn",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7950,
    Code: "24667",
    Name: "Xã  Đắk Lao",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7951,
    Code: "24670",
    Name: "Xã Đắk R'La",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7952,
    Code: "24673",
    Name: "Xã Đắk Gằn",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7953,
    Code: "24676",
    Name: "Xã Đức Mạnh",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7954,
    Code: "24677",
    Name: "Xã Đắk N'Drót",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7955,
    Code: "24678",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7956,
    Code: "24679",
    Name: "Xã Đắk Sắk",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7957,
    Code: "24682",
    Name: "Xã Thuận An",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7958,
    Code: "24685",
    Name: "Xã Đức Minh",
    LevelUp: "Xã",
    DistrictCode: "6303",
    Active: -1,
  },
  {
    ID: 7959,
    Code: "24688",
    Name: "Thị trấn Đắk Mâm",
    LevelUp: "Thị trấn",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7960,
    Code: "24691",
    Name: "Xã Đắk Sôr",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7961,
    Code: "24692",
    Name: "Xã Nam Xuân",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7962,
    Code: "24694",
    Name: "Xã Buôn Choah",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7963,
    Code: "24697",
    Name: "Xã Nam Đà",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7964,
    Code: "24699",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7965,
    Code: "24700",
    Name: "Xã Đắk Drô",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7966,
    Code: "24703",
    Name: "Xã Nâm Nung",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7967,
    Code: "24706",
    Name: "Xã Đức Xuyên",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7968,
    Code: "24709",
    Name: "Xã Đắk Nang",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7969,
    Code: "24712",
    Name: "Xã Quảng Phú",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7970,
    Code: "24715",
    Name: "Xã Nâm N'Đir",
    LevelUp: "Xã",
    DistrictCode: "6306",
    Active: -1,
  },
  {
    ID: 7971,
    Code: "24717",
    Name: "Thị trấn Đức An",
    LevelUp: "Thị trấn",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7972,
    Code: "24718",
    Name: "Xã Đắk Môl",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7973,
    Code: "24719",
    Name: "Xã Đắk Hòa",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7974,
    Code: "24721",
    Name: "Xã Nam Bình",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7975,
    Code: "24722",
    Name: "Xã Thuận Hà",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7976,
    Code: "24724",
    Name: "Xã Thuận Hạnh",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7977,
    Code: "24727",
    Name: "Xã Đắk N'Dung",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7978,
    Code: "24728",
    Name: "Xã Nâm N'Jang",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7979,
    Code: "24730",
    Name: "Xã Trường Xuân",
    LevelUp: "Xã",
    DistrictCode: "6305",
    Active: -1,
  },
  {
    ID: 7980,
    Code: "24733",
    Name: "Thị trấn Kiến Đức",
    LevelUp: "Thị trấn",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7981,
    Code: "24745",
    Name: "Xã Quảng Tín",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7982,
    Code: "24750",
    Name: "Xã Đắk Wer",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7983,
    Code: "24751",
    Name: "Xã Nhân Cơ",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7984,
    Code: "24754",
    Name: "Xã Kiến Thành",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7985,
    Code: "24756",
    Name: "Xã Nghĩa Thắng",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7986,
    Code: "24757",
    Name: "Xã Đạo Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7987,
    Code: "24760",
    Name: "Xã Đắk Sin",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7988,
    Code: "24761",
    Name: "Xã Hưng Bình",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7989,
    Code: "24763",
    Name: "Xã Đắk Ru",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7990,
    Code: "24766",
    Name: "Xã Nhân Đạo",
    LevelUp: "Xã",
    DistrictCode: "6302",
    Active: -1,
  },
  {
    ID: 7991,
    Code: "24736",
    Name: "Xã Quảng Trực",
    LevelUp: "Xã",
    DistrictCode: "6308",
    Active: -1,
  },
  {
    ID: 7992,
    Code: "24739",
    Name: "Xã Đắk Búk So",
    LevelUp: "Xã",
    DistrictCode: "6308",
    Active: -1,
  },
  {
    ID: 7993,
    Code: "24740",
    Name: "Xã Quảng Tâm",
    LevelUp: "Xã",
    DistrictCode: "6308",
    Active: -1,
  },
  {
    ID: 7994,
    Code: "24742",
    Name: "Xã Đắk R'Tíh",
    LevelUp: "Xã",
    DistrictCode: "6308",
    Active: -1,
  },
  {
    ID: 7995,
    Code: "24746",
    Name: "Xã Đắk Ngo",
    LevelUp: "Xã",
    DistrictCode: "6308",
    Active: -1,
  },
  {
    ID: 7996,
    Code: "24748",
    Name: "Xã Quảng Tân",
    LevelUp: "Xã",
    DistrictCode: "6308",
    Active: -1,
  },
  {
    ID: 7997,
    Code: "24769",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 7998,
    Code: "24772",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 7999,
    Code: "24775",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8000,
    Code: "24778",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8001,
    Code: "24781",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8002,
    Code: "24784",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8003,
    Code: "24787",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8004,
    Code: "24790",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8005,
    Code: "24793",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8006,
    Code: "24796",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8007,
    Code: "24799",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8008,
    Code: "24802",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8009,
    Code: "24805",
    Name: "Xã Xuân Thọ",
    LevelUp: "Xã",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8010,
    Code: "24808",
    Name: "Xã Tà Nung",
    LevelUp: "Xã",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8011,
    Code: "24810",
    Name: "Xã Trạm Hành",
    LevelUp: "Xã",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8012,
    Code: "24811",
    Name: "Xã Xuân Trường",
    LevelUp: "Xã",
    DistrictCode: "4201",
    Active: -1,
  },
  {
    ID: 8013,
    Code: "24814",
    Name: "Phường Lộc Phát",
    LevelUp: "Phường",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8014,
    Code: "24817",
    Name: "Phường Lộc Tiến",
    LevelUp: "Phường",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8015,
    Code: "24820",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8016,
    Code: "24823",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8017,
    Code: "24826",
    Name: "Phường B'lao",
    LevelUp: "Phường",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8018,
    Code: "24829",
    Name: "Phường Lộc Sơn",
    LevelUp: "Phường",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8019,
    Code: "24832",
    Name: "Xã Đạm Bri",
    LevelUp: "Xã",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8020,
    Code: "24835",
    Name: "Xã Lộc Thanh",
    LevelUp: "Xã",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8021,
    Code: "24838",
    Name: "Xã Lộc Nga",
    LevelUp: "Xã",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8022,
    Code: "24841",
    Name: "Xã Lộc Châu",
    LevelUp: "Xã",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8023,
    Code: "24844",
    Name: "Xã Đại Lào",
    LevelUp: "Xã",
    DistrictCode: "4202",
    Active: -1,
  },
  {
    ID: 8024,
    Code: "24853",
    Name: "Xã Đạ Tông",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8025,
    Code: "24856",
    Name: "Xã Đạ Long",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8026,
    Code: "24859",
    Name: "Xã Đạ M' Rong",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8027,
    Code: "24874",
    Name: "Xã Liêng Srônh",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8028,
    Code: "24875",
    Name: "Xã Đạ Rsal",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8029,
    Code: "24877",
    Name: "Xã Rô Men",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8030,
    Code: "24886",
    Name: "Xã Phi Liêng",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8031,
    Code: "24889",
    Name: "Xã Đạ K' Nàng",
    LevelUp: "Xã",
    DistrictCode: "4212",
    Active: -1,
  },
  {
    ID: 8032,
    Code: "24846",
    Name: "Thị trấn Lạc Dương",
    LevelUp: "Thị trấn",
    DistrictCode: "4206",
    Active: -1,
  },
  {
    ID: 8033,
    Code: "24847",
    Name: "Xã Đạ Chais",
    LevelUp: "Xã",
    DistrictCode: "4206",
    Active: -1,
  },
  {
    ID: 8034,
    Code: "24848",
    Name: "Xã Đạ Nhim",
    LevelUp: "Xã",
    DistrictCode: "4206",
    Active: -1,
  },
  {
    ID: 8035,
    Code: "24850",
    Name: "Xã Đưng KNớ",
    LevelUp: "Xã",
    DistrictCode: "4206",
    Active: -1,
  },
  {
    ID: 8036,
    Code: "24862",
    Name: "Xã Lát",
    LevelUp: "Xã",
    DistrictCode: "4206",
    Active: -1,
  },
  {
    ID: 8037,
    Code: "24865",
    Name: "Xã Đạ Sar",
    LevelUp: "Xã",
    DistrictCode: "4206",
    Active: -1,
  },
  {
    ID: 8038,
    Code: "24868",
    Name: "Thị trấn Nam Ban",
    LevelUp: "Thị trấn",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8039,
    Code: "24871",
    Name: "Thị trấn Đinh Văn",
    LevelUp: "Thị trấn",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8040,
    Code: "24880",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8041,
    Code: "24883",
    Name: "Xã Phi Tô",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8042,
    Code: "24892",
    Name: "Xã Mê Linh",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8043,
    Code: "24895",
    Name: "Xã Đạ Đờn",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8044,
    Code: "24898",
    Name: "Xã Phúc Thọ",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8045,
    Code: "24901",
    Name: "Xã Đông Thanh",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8046,
    Code: "24904",
    Name: "Xã Gia Lâm",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8047,
    Code: "24907",
    Name: "Xã Tân Thanh",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8048,
    Code: "24910",
    Name: "Xã Tân Văn",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8049,
    Code: "24913",
    Name: "Xã Hoài Đức",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8050,
    Code: "24916",
    Name: "Xã Tân Hà",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8051,
    Code: "24919",
    Name: "Xã Liên Hà",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8052,
    Code: "24922",
    Name: "Xã Đan Phượng",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8053,
    Code: "24925",
    Name: "Xã Nam Hà",
    LevelUp: "Xã",
    DistrictCode: "4210",
    Active: -1,
  },
  {
    ID: 8054,
    Code: "24928",
    Name: "Thị trấn D'Ran",
    LevelUp: "Thị trấn",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8055,
    Code: "24931",
    Name: "Thị trấn Thạnh Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8056,
    Code: "24934",
    Name: "Xã Lạc Xuân",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8057,
    Code: "24937",
    Name: "Xã Đạ Ròn",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8058,
    Code: "24940",
    Name: "Xã Lạc Lâm",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8059,
    Code: "24943",
    Name: "Xã Ka Đô",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8060,
    Code: "24946",
    Name: "Xã Quảng Lập",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8061,
    Code: "24949",
    Name: "Xã Ka Đơn",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8062,
    Code: "24952",
    Name: "Xã Tu Tra",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8063,
    Code: "24955",
    Name: "Xã Pró",
    LevelUp: "Xã",
    DistrictCode: "4205",
    Active: -1,
  },
  {
    ID: 8064,
    Code: "24958",
    Name: "Thị trấn Liên Nghĩa",
    LevelUp: "Thị trấn",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8065,
    Code: "24961",
    Name: "Xã Hiệp An",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8066,
    Code: "24964",
    Name: "Xã Liên Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8067,
    Code: "24967",
    Name: "Xã Hiệp Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8068,
    Code: "24970",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8069,
    Code: "24973",
    Name: "Xã N'Thol Hạ",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8070,
    Code: "24976",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8071,
    Code: "24979",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8072,
    Code: "24982",
    Name: "Xã Phú Hội",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8073,
    Code: "24985",
    Name: "Xã Ninh Gia",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8074,
    Code: "24988",
    Name: "Xã Tà Năng",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8075,
    Code: "24989",
    Name: "Xã Đa Quyn",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8076,
    Code: "24991",
    Name: "Xã Tà Hine",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8077,
    Code: "24994",
    Name: "Xã Đà Loan",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8078,
    Code: "24997",
    Name: "Xã Ninh Loan",
    LevelUp: "Xã",
    DistrictCode: "4203",
    Active: -1,
  },
  {
    ID: 8079,
    Code: "25000",
    Name: "Thị trấn Di Linh",
    LevelUp: "Thị trấn",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8080,
    Code: "25003",
    Name: "Xã Đinh Trang Thượng",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8081,
    Code: "25006",
    Name: "Xã Tân Thượng",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8082,
    Code: "25007",
    Name: "Xã Tân Lâm",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8083,
    Code: "25009",
    Name: "Xã Tân Châu",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8084,
    Code: "25012",
    Name: "Xã Tân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8085,
    Code: "25015",
    Name: "Xã Gia Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8086,
    Code: "25018",
    Name: "Xã Đinh Lạc",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8087,
    Code: "25021",
    Name: "Xã Tam Bố",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8088,
    Code: "25024",
    Name: "Xã Đinh Trang Hòa",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8089,
    Code: "25027",
    Name: "Xã Liên Đầm",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8090,
    Code: "25030",
    Name: "Xã Gung Ré",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8091,
    Code: "25033",
    Name: "Xã Bảo Thuận",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8092,
    Code: "25036",
    Name: "Xã Hòa Ninh",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8093,
    Code: "25039",
    Name: "Xã Hòa Trung",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8094,
    Code: "25042",
    Name: "Xã Hòa Nam",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8095,
    Code: "25045",
    Name: "Xã Hòa Bắc",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8096,
    Code: "25048",
    Name: "Xã Sơn Điền",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8097,
    Code: "25051",
    Name: "Xã Gia Bắc",
    LevelUp: "Xã",
    DistrictCode: "4204",
    Active: -1,
  },
  {
    ID: 8098,
    Code: "25054",
    Name: "Thị trấn Lộc Thắng",
    LevelUp: "Thị trấn",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8099,
    Code: "25057",
    Name: "Xã Lộc Bảo",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8100,
    Code: "25060",
    Name: "Xã Lộc Lâm",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8101,
    Code: "25063",
    Name: "Xã Lộc Phú",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8102,
    Code: "25066",
    Name: "Xã Lộc Bắc",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8103,
    Code: "25069",
    Name: "Xã B' Lá",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8104,
    Code: "25072",
    Name: "Xã Lộc Ngãi",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8105,
    Code: "25075",
    Name: "Xã Lộc Quảng",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8106,
    Code: "25078",
    Name: "Xã Lộc Tân",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8107,
    Code: "25081",
    Name: "Xã Lộc Đức",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8108,
    Code: "25084",
    Name: "Xã Lộc An",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8109,
    Code: "25087",
    Name: "Xã Tân Lạc",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8110,
    Code: "25090",
    Name: "Xã Lộc Thành",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8111,
    Code: "25093",
    Name: "Xã Lộc Nam",
    LevelUp: "Xã",
    DistrictCode: "4211",
    Active: -1,
  },
  {
    ID: 8112,
    Code: "25096",
    Name: "Thị trấn Đạ M'ri",
    LevelUp: "Thị trấn",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8113,
    Code: "25099",
    Name: "Thị trấn Ma Đa Guôi",
    LevelUp: "Thị trấn",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8114,
    Code: "25105",
    Name: "Xã Hà Lâm",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8115,
    Code: "25108",
    Name: "Xã Đạ Tồn",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8116,
    Code: "25111",
    Name: "Xã Đạ Oai",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8117,
    Code: "25114",
    Name: "Xã Đạ Ploa",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8118,
    Code: "25117",
    Name: "Xã Ma Đa Guôi",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8119,
    Code: "25120",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8120,
    Code: "25123",
    Name: "Xã Phước Lộc",
    LevelUp: "Xã",
    DistrictCode: "4207",
    Active: -1,
  },
  {
    ID: 8121,
    Code: "25126",
    Name: "Thị trấn Đạ Tẻh",
    LevelUp: "Thị trấn",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8122,
    Code: "25129",
    Name: "Xã An Nhơn",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8123,
    Code: "25132",
    Name: "Xã Quốc Oai",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8124,
    Code: "25135",
    Name: "Xã Mỹ Đức",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8125,
    Code: "25138",
    Name: "Xã Quảng Trị",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8126,
    Code: "25141",
    Name: "Xã Đạ Lây",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8127,
    Code: "25147",
    Name: "Xã Triệu Hải",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8128,
    Code: "25153",
    Name: "Xã Đạ Kho",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8129,
    Code: "25156",
    Name: "Xã Đạ Pal",
    LevelUp: "Xã",
    DistrictCode: "4208",
    Active: -1,
  },
  {
    ID: 8130,
    Code: "25159",
    Name: "Thị trấn Cát Tiên",
    LevelUp: "Thị trấn",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8131,
    Code: "25162",
    Name: "Xã Tiên Hoàng",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8132,
    Code: "25165",
    Name: "Xã Phước Cát 2",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8133,
    Code: "25168",
    Name: "Xã Gia Viễn",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8134,
    Code: "25171",
    Name: "Xã Nam Ninh",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8135,
    Code: "25180",
    Name: "Thị trấn Phước Cát ",
    LevelUp: "Thị trấn",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8136,
    Code: "25183",
    Name: "Xã Đức Phổ",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8137,
    Code: "25189",
    Name: "Xã Quảng Ngãi",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8138,
    Code: "25192",
    Name: "Xã Đồng Nai Thượng",
    LevelUp: "Xã",
    DistrictCode: "4209",
    Active: -1,
  },
  {
    ID: 8139,
    Code: "25216",
    Name: "Phường Thác Mơ",
    LevelUp: "Phường",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8140,
    Code: "25217",
    Name: "Phường Long Thủy",
    LevelUp: "Phường",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8141,
    Code: "25219",
    Name: "Phường Phước Bình",
    LevelUp: "Phường",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8142,
    Code: "25220",
    Name: "Phường Long Phước",
    LevelUp: "Phường",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8143,
    Code: "25237",
    Name: "Phường Sơn Giang",
    LevelUp: "Phường",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8144,
    Code: "25245",
    Name: "Xã Long Giang",
    LevelUp: "Xã",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8145,
    Code: "25249",
    Name: "Xã Phước Tín",
    LevelUp: "Xã",
    DistrictCode: "4307",
    Active: -1,
  },
  {
    ID: 8146,
    Code: "25195",
    Name: "Phường Tân Phú",
    LevelUp: "Phường",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8147,
    Code: "25198",
    Name: "Phường Tân Đồng",
    LevelUp: "Phường",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8148,
    Code: "25201",
    Name: "Phường Tân Bình",
    LevelUp: "Phường",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8149,
    Code: "25204",
    Name: "Phường Tân Xuân",
    LevelUp: "Phường",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8150,
    Code: "25205",
    Name: "Phường Tân Thiện",
    LevelUp: "Phường",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8151,
    Code: "25207",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8152,
    Code: "25210",
    Name: "Phường Tiến Thành",
    LevelUp: "Phường",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8153,
    Code: "25213",
    Name: "Xã Tiến Hưng",
    LevelUp: "Xã",
    DistrictCode: "4301",
    Active: -1,
  },
  {
    ID: 8154,
    Code: "25320",
    Name: "Phường Hưng Chiến",
    LevelUp: "Phường",
    DistrictCode: "4304",
    Active: -1,
  },
  {
    ID: 8155,
    Code: "25324",
    Name: "Phường An Lộc",
    LevelUp: "Phường",
    DistrictCode: "4304",
    Active: -1,
  },
  {
    ID: 8156,
    Code: "25325",
    Name: "Phường Phú Thịnh",
    LevelUp: "Phường",
    DistrictCode: "4304",
    Active: -1,
  },
  {
    ID: 8157,
    Code: "25326",
    Name: "Phường Phú Đức",
    LevelUp: "Phường",
    DistrictCode: "4304",
    Active: -1,
  },
  {
    ID: 8158,
    Code: "25333",
    Name: "Xã Thanh Lương",
    LevelUp: "Xã",
    DistrictCode: "4304",
    Active: -1,
  },
  {
    ID: 8159,
    Code: "25336",
    Name: "Xã Thanh Phú",
    LevelUp: "Xã",
    DistrictCode: "4304",
    Active: -1,
  },
  {
    ID: 8160,
    Code: "25222",
    Name: "Xã Bù Gia Mập",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8161,
    Code: "25225",
    Name: "Xã Đak Ơ",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8162,
    Code: "25228",
    Name: "Xã Đức Hạnh",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8163,
    Code: "25229",
    Name: "Xã Phú Văn",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8164,
    Code: "25231",
    Name: "Xã Đa Kia",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8165,
    Code: "25232",
    Name: "Xã Phước Minh",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8166,
    Code: "25234",
    Name: "Xã Bình Thắng",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8167,
    Code: "25267",
    Name: "Xã Phú Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "4310",
    Active: -1,
  },
  {
    ID: 8168,
    Code: "25270",
    Name: "Thị trấn Lộc Ninh",
    LevelUp: "Thị trấn",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8169,
    Code: "25273",
    Name: "Xã Lộc Hòa",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8170,
    Code: "25276",
    Name: "Xã Lộc An",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8171,
    Code: "25279",
    Name: "Xã Lộc Tấn",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8172,
    Code: "25280",
    Name: "Xã Lộc Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8173,
    Code: "25282",
    Name: "Xã Lộc Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8174,
    Code: "25285",
    Name: "Xã Lộc Thiện",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8175,
    Code: "25288",
    Name: "Xã Lộc Thuận",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8176,
    Code: "25291",
    Name: "Xã Lộc Quang",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8177,
    Code: "25292",
    Name: "Xã Lộc Phú",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8178,
    Code: "25294",
    Name: "Xã Lộc Thành",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8179,
    Code: "25297",
    Name: "Xã Lộc Thái",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8180,
    Code: "25300",
    Name: "Xã Lộc Điền",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8181,
    Code: "25303",
    Name: "Xã Lộc Hưng",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8182,
    Code: "25305",
    Name: "Xã Lộc Thịnh",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8183,
    Code: "25306",
    Name: "Xã Lộc Khánh",
    LevelUp: "Xã",
    DistrictCode: "4305",
    Active: -1,
  },
  {
    ID: 8184,
    Code: "25308",
    Name: "Thị trấn Thanh Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8185,
    Code: "25309",
    Name: "Xã Hưng Phước",
    LevelUp: "Xã",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8186,
    Code: "25310",
    Name: "Xã Phước Thiện",
    LevelUp: "Xã",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8187,
    Code: "25312",
    Name: "Xã Thiện Hưng",
    LevelUp: "Xã",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8188,
    Code: "25315",
    Name: "Xã Thanh Hòa",
    LevelUp: "Xã",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8189,
    Code: "25318",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8190,
    Code: "25321",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "4306",
    Active: -1,
  },
  {
    ID: 8191,
    Code: "25327",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8192,
    Code: "25330",
    Name: "Xã An Khương",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8193,
    Code: "25339",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8194,
    Code: "25342",
    Name: "Xã Tân Lợi",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8195,
    Code: "25345",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8196,
    Code: "25348",
    Name: "Xã Minh Đức",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8197,
    Code: "25349",
    Name: "Xã Minh Tâm",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8198,
    Code: "25351",
    Name: "Xã Phước An",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8199,
    Code: "25354",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8200,
    Code: "25357",
    Name: "Thị trấn Tân Khai",
    LevelUp: "Thị trấn",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8201,
    Code: "25360",
    Name: "Xã Đồng Nơ",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8202,
    Code: "25361",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8203,
    Code: "25438",
    Name: "Xã Tân Quan",
    LevelUp: "Xã",
    DistrictCode: "4309",
    Active: -1,
  },
  {
    ID: 8204,
    Code: "25363",
    Name: "Thị trấn Tân Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8205,
    Code: "25366",
    Name: "Xã Thuận Lợi",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8206,
    Code: "25369",
    Name: "Xã Đồng Tâm",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8207,
    Code: "25372",
    Name: "Xã Tân Phước",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8208,
    Code: "25375",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8209,
    Code: "25378",
    Name: "Xã Tân Lợi",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8210,
    Code: "25381",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8211,
    Code: "25384",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8212,
    Code: "25387",
    Name: "Xã Thuận Phú",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8213,
    Code: "25390",
    Name: "Xã Đồng Tiến",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8214,
    Code: "25393",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "4302",
    Active: -1,
  },
  {
    ID: 8215,
    Code: "25396",
    Name: "Thị trấn Đức Phong",
    LevelUp: "Thị trấn",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8216,
    Code: "25398",
    Name: "Xã Đường 10",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8217,
    Code: "25399",
    Name: "Xã Đak Nhau",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8218,
    Code: "25400",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8219,
    Code: "25402",
    Name: "Xã Thọ Sơn",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8220,
    Code: "25404",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8221,
    Code: "25405",
    Name: "Xã Bom Bo",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8222,
    Code: "25408",
    Name: "Xã Minh Hưng",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8223,
    Code: "25411",
    Name: "Xã Đoàn Kết",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8224,
    Code: "25414",
    Name: "Xã Đồng Nai",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8225,
    Code: "25417",
    Name: "Xã Đức Liễu",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8226,
    Code: "25420",
    Name: "Xã Thống Nhất",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8227,
    Code: "25423",
    Name: "Xã Nghĩa Trung",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8228,
    Code: "25424",
    Name: "Xã Nghĩa Bình",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8229,
    Code: "25426",
    Name: "Xã Đăng Hà",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8230,
    Code: "25429",
    Name: "Xã Phước Sơn",
    LevelUp: "Xã",
    DistrictCode: "4308",
    Active: -1,
  },
  {
    ID: 8231,
    Code: "25432",
    Name: "Thị trấn Chơn Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8232,
    Code: "25433",
    Name: "Xã Thành Tâm",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8233,
    Code: "25435",
    Name: "Xã Minh Lập",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8234,
    Code: "25439",
    Name: "Xã Quang Minh",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8235,
    Code: "25441",
    Name: "Xã Minh Hưng",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8236,
    Code: "25444",
    Name: "Xã Minh Long",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8237,
    Code: "25447",
    Name: "Xã Minh Thành",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8238,
    Code: "25450",
    Name: "Xã Nha Bích",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8239,
    Code: "25453",
    Name: "Xã Minh Thắng",
    LevelUp: "Xã",
    DistrictCode: "4303",
    Active: -1,
  },
  {
    ID: 8240,
    Code: "25240",
    Name: "Xã Long Bình",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8241,
    Code: "25243",
    Name: "Xã Bình Tân",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8242,
    Code: "25244",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8243,
    Code: "25246",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8244,
    Code: "25250",
    Name: "Xã Phước Tân",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8245,
    Code: "25252",
    Name: "Xã Bù Nho",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8246,
    Code: "25255",
    Name: "Xã Long Hà",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8247,
    Code: "25258",
    Name: "Xã Long Tân",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8248,
    Code: "25261",
    Name: "Xã Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8249,
    Code: "25264",
    Name: "Xã Phú Riềng",
    LevelUp: "Xã",
    DistrictCode: "4311",
    Active: -1,
  },
  {
    ID: 8250,
    Code: "25456",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8251,
    Code: "25459",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8252,
    Code: "25462",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8253,
    Code: "25465",
    Name: "Phường Hiệp Ninh",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8254,
    Code: "25468",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8255,
    Code: "25471",
    Name: "Xã Thạnh Tân",
    LevelUp: "Xã",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8256,
    Code: "25474",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8257,
    Code: "25477",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8258,
    Code: "25480",
    Name: "Phường Ninh Sơn",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8259,
    Code: "25483",
    Name: "Phường Ninh Thạnh",
    LevelUp: "Phường",
    DistrictCode: "4601",
    Active: -1,
  },
  {
    ID: 8260,
    Code: "25486",
    Name: "Thị trấn Tân Biên",
    LevelUp: "Thị trấn",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8261,
    Code: "25489",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8262,
    Code: "25492",
    Name: "Xã Thạnh Bắc",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8263,
    Code: "25495",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8264,
    Code: "25498",
    Name: "Xã Thạnh Bình",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8265,
    Code: "25501",
    Name: "Xã Thạnh Tây",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8266,
    Code: "25504",
    Name: "Xã Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8267,
    Code: "25507",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8268,
    Code: "25510",
    Name: "Xã Mỏ Công",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8269,
    Code: "25513",
    Name: "Xã Trà Vong",
    LevelUp: "Xã",
    DistrictCode: "4602",
    Active: -1,
  },
  {
    ID: 8270,
    Code: "25516",
    Name: "Thị trấn Tân Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8271,
    Code: "25519",
    Name: "Xã Tân Hà",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8272,
    Code: "25522",
    Name: "Xã Tân Đông",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8273,
    Code: "25525",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8274,
    Code: "25528",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8275,
    Code: "25531",
    Name: "Xã Suối Ngô",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8276,
    Code: "25534",
    Name: "Xã Suối Dây",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8277,
    Code: "25537",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8278,
    Code: "25540",
    Name: "Xã Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8279,
    Code: "25543",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8280,
    Code: "25546",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8281,
    Code: "25549",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4603",
    Active: -1,
  },
  {
    ID: 8282,
    Code: "25552",
    Name: "Thị trấn Dương Minh Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8283,
    Code: "25555",
    Name: "Xã Suối Đá",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8284,
    Code: "25558",
    Name: "Xã Phan",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8285,
    Code: "25561",
    Name: "Xã Phước Ninh",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8286,
    Code: "25564",
    Name: "Xã Phước Minh",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8287,
    Code: "25567",
    Name: "Xã Bàu Năng",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8288,
    Code: "25570",
    Name: "Xã Chà Là",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8289,
    Code: "25573",
    Name: "Xã Cầu Khởi",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8290,
    Code: "25576",
    Name: "Xã Bến Củi",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8291,
    Code: "25579",
    Name: "Xã Lộc Ninh",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8292,
    Code: "25582",
    Name: "Xã Truông Mít",
    LevelUp: "Xã",
    DistrictCode: "4604",
    Active: -1,
  },
  {
    ID: 8293,
    Code: "25585",
    Name: "Thị trấn Châu Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8294,
    Code: "25588",
    Name: "Xã Hảo Đước",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8295,
    Code: "25591",
    Name: "Xã Phước Vinh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8296,
    Code: "25594",
    Name: "Xã Đồng Khởi",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8297,
    Code: "25597",
    Name: "Xã Thái Bình",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8298,
    Code: "25600",
    Name: "Xã An Cơ",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8299,
    Code: "25603",
    Name: "Xã Biên Giới",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8300,
    Code: "25606",
    Name: "Xã Hòa Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8301,
    Code: "25609",
    Name: "Xã Trí Bình",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8302,
    Code: "25612",
    Name: "Xã Hòa Hội",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8303,
    Code: "25615",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8304,
    Code: "25618",
    Name: "Xã Thanh Điền",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8305,
    Code: "25621",
    Name: "Xã Thành Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8306,
    Code: "25624",
    Name: "Xã Ninh Điền",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8307,
    Code: "25627",
    Name: "Xã Long Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 8308,
    Code: "25630",
    Name: "Phường Long Hoa",
    LevelUp: "Phường",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8309,
    Code: "25633",
    Name: "Phường Hiệp Tân",
    LevelUp: "Phường",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8310,
    Code: "25636",
    Name: "Phường Long Thành Bắc",
    LevelUp: "Phường",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8311,
    Code: "25639",
    Name: "Xã Trường Hòa",
    LevelUp: "Xã",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8312,
    Code: "25642",
    Name: "Xã Trường Đông",
    LevelUp: "Xã",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8313,
    Code: "25645",
    Name: "Phường Long Thành Trung",
    LevelUp: "Phường",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8314,
    Code: "25648",
    Name: "Xã Trường Tây",
    LevelUp: "Xã",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8315,
    Code: "25651",
    Name: "Xã Long Thành Nam",
    LevelUp: "Xã",
    DistrictCode: "4606",
    Active: -1,
  },
  {
    ID: 8316,
    Code: "25654",
    Name: "Thị trấn Gò Dầu",
    LevelUp: "Thị trấn",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8317,
    Code: "25657",
    Name: "Xã Thạnh Đức",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8318,
    Code: "25660",
    Name: "Xã Cẩm Giang",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8319,
    Code: "25663",
    Name: "Xã Hiệp Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8320,
    Code: "25666",
    Name: "Xã Bàu Đồn",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8321,
    Code: "25669",
    Name: "Xã Phước Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8322,
    Code: "25672",
    Name: "Xã Phước Đông",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8323,
    Code: "25675",
    Name: "Xã Phước Trạch",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8324,
    Code: "25678",
    Name: "Xã Thanh Phước",
    LevelUp: "Xã",
    DistrictCode: "4608",
    Active: -1,
  },
  {
    ID: 8325,
    Code: "25681",
    Name: "Thị trấn Bến Cầu",
    LevelUp: "Thị trấn",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8326,
    Code: "25684",
    Name: "Xã Long Chữ",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8327,
    Code: "25687",
    Name: "Xã Long Phước",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8328,
    Code: "25690",
    Name: "Xã Long Giang",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8329,
    Code: "25693",
    Name: "Xã Tiên Thuận",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8330,
    Code: "25696",
    Name: "Xã Long Khánh",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8331,
    Code: "25699",
    Name: "Xã Lợi Thuận",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8332,
    Code: "25702",
    Name: "Xã Long Thuận",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8333,
    Code: "25705",
    Name: "Xã An Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4607",
    Active: -1,
  },
  {
    ID: 8334,
    Code: "25708",
    Name: "Phường Trảng Bàng",
    LevelUp: "Phường",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8335,
    Code: "25711",
    Name: "Xã Đôn Thuận",
    LevelUp: "Xã",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8336,
    Code: "25714",
    Name: "Xã Hưng Thuận",
    LevelUp: "Xã",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8337,
    Code: "25717",
    Name: "Phường Lộc Hưng",
    LevelUp: "Phường",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8338,
    Code: "25720",
    Name: "Phường Gia Lộc",
    LevelUp: "Phường",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8339,
    Code: "25723",
    Name: "Phường Gia Bình",
    LevelUp: "Phường",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8340,
    Code: "25729",
    Name: "Xã Phước Bình",
    LevelUp: "Xã",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8341,
    Code: "25732",
    Name: "Phường An Tịnh",
    LevelUp: "Phường",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8342,
    Code: "25735",
    Name: "Phường An Hòa",
    LevelUp: "Phường",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8343,
    Code: "25738",
    Name: "Xã Phước Chỉ",
    LevelUp: "Xã",
    DistrictCode: "4609",
    Active: -1,
  },
  {
    ID: 8344,
    Code: "25741",
    Name: "Phường Hiệp Thành",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8345,
    Code: "25744",
    Name: "Phường Phú Lợi",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8346,
    Code: "25747",
    Name: "Phường Phú Cường",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8347,
    Code: "25750",
    Name: "Phường Phú Hòa",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8348,
    Code: "25753",
    Name: "Phường Phú Thọ",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8349,
    Code: "25756",
    Name: "Phường Chánh Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8350,
    Code: "25759",
    Name: "Phường Định Hoà",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8351,
    Code: "25760",
    Name: "Phường Hoà Phú",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8352,
    Code: "25762",
    Name: "Phường Phú Mỹ",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8353,
    Code: "25763",
    Name: "Phường Phú Tân",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8354,
    Code: "25765",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8355,
    Code: "25768",
    Name: "Phường Hiệp An",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8356,
    Code: "25771",
    Name: "Phường Tương Bình Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8357,
    Code: "25774",
    Name: "Phường Chánh Mỹ",
    LevelUp: "Phường",
    DistrictCode: "4401",
    Active: -1,
  },
  {
    ID: 8358,
    Code: "25816",
    Name: "Xã Trừ Văn Thố",
    LevelUp: "Xã",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8359,
    Code: "25819",
    Name: "Xã Cây Trường II",
    LevelUp: "Xã",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8360,
    Code: "25822",
    Name: "Thị trấn Lai Uyên",
    LevelUp: "Thị trấn",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8361,
    Code: "25825",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8362,
    Code: "25828",
    Name: "Xã Long Nguyên",
    LevelUp: "Xã",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8363,
    Code: "25831",
    Name: "Xã Hưng Hòa",
    LevelUp: "Xã",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8364,
    Code: "25834",
    Name: "Xã Lai Hưng",
    LevelUp: "Xã",
    DistrictCode: "4408",
    Active: -1,
  },
  {
    ID: 8365,
    Code: "25777",
    Name: "Thị trấn Dầu Tiếng",
    LevelUp: "Thị trấn",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8366,
    Code: "25780",
    Name: "Xã Minh Hoà",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8367,
    Code: "25783",
    Name: "Xã Minh Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8368,
    Code: "25786",
    Name: "Xã Minh Tân",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8369,
    Code: "25789",
    Name: "Xã Định An",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8370,
    Code: "25792",
    Name: "Xã Long Hoà",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8371,
    Code: "25795",
    Name: "Xã Định Thành",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8372,
    Code: "25798",
    Name: "Xã Định Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8373,
    Code: "25801",
    Name: "Xã An Lập",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8374,
    Code: "25804",
    Name: "Xã Long Tân",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8375,
    Code: "25807",
    Name: "Xã Thanh An",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8376,
    Code: "25810",
    Name: "Xã Thanh Tuyền",
    LevelUp: "Xã",
    DistrictCode: "4407",
    Active: -1,
  },
  {
    ID: 8377,
    Code: "25813",
    Name: "Phường Mỹ Phước",
    LevelUp: "Phường",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8378,
    Code: "25837",
    Name: "Phường Chánh Phú Hòa",
    LevelUp: "Phường",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8379,
    Code: "25840",
    Name: "Xã An Điền",
    LevelUp: "Xã",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8380,
    Code: "25843",
    Name: "Xã An Tây",
    LevelUp: "Xã",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8381,
    Code: "25846",
    Name: "Phường Thới Hòa",
    LevelUp: "Phường",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8382,
    Code: "25849",
    Name: "Phường Hòa Lợi",
    LevelUp: "Phường",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8383,
    Code: "25852",
    Name: "Phường Tân Định",
    LevelUp: "Phường",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8384,
    Code: "25855",
    Name: "Xã Phú An",
    LevelUp: "Xã",
    DistrictCode: "4402",
    Active: -1,
  },
  {
    ID: 8385,
    Code: "25858",
    Name: "Thị trấn Phước Vĩnh",
    LevelUp: "Thị trấn",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8386,
    Code: "25861",
    Name: "Xã An Linh",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8387,
    Code: "25864",
    Name: "Xã Phước Sang",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8388,
    Code: "25865",
    Name: "Xã An Thái",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8389,
    Code: "25867",
    Name: "Xã An Long",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8390,
    Code: "25870",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8391,
    Code: "25873",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8392,
    Code: "25876",
    Name: "Xã Tam Lập",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8393,
    Code: "25879",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8394,
    Code: "25882",
    Name: "Xã Vĩnh Hoà",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8395,
    Code: "25885",
    Name: "Xã Phước Hoà",
    LevelUp: "Xã",
    DistrictCode: "4406",
    Active: -1,
  },
  {
    ID: 8396,
    Code: "25888",
    Name: "Phường Uyên Hưng",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8397,
    Code: "25891",
    Name: "Phường Tân Phước Khánh",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8398,
    Code: "25912",
    Name: "Phường Vĩnh Tân",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8399,
    Code: "25915",
    Name: "Phường Hội Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8400,
    Code: "25920",
    Name: "Phường Tân Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8401,
    Code: "25921",
    Name: "Phường Khánh Bình",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8402,
    Code: "25924",
    Name: "Phường Phú Chánh",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8403,
    Code: "25930",
    Name: "Xã Bạch Đằng",
    LevelUp: "Xã",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8404,
    Code: "25933",
    Name: "Phường Tân Vĩnh Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8405,
    Code: "25936",
    Name: "Phường Thạnh Phước",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8406,
    Code: "25937",
    Name: "Xã Thạnh Hội",
    LevelUp: "Xã",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8407,
    Code: "25939",
    Name: "Phường Thái Hòa",
    LevelUp: "Phường",
    DistrictCode: "4403",
    Active: -1,
  },
  {
    ID: 8408,
    Code: "25942",
    Name: "Phường Dĩ An",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8409,
    Code: "25945",
    Name: "Phường Tân Bình",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8410,
    Code: "25948",
    Name: "Phường Tân Đông Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8411,
    Code: "25951",
    Name: "Phường Bình An",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8412,
    Code: "25954",
    Name: "Phường Bình Thắng",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8413,
    Code: "25957",
    Name: "Phường Đông Hòa",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8414,
    Code: "25960",
    Name: "Phường An Bình",
    LevelUp: "Phường",
    DistrictCode: "4405",
    Active: -1,
  },
  {
    ID: 8415,
    Code: "25963",
    Name: "Phường An Thạnh",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8416,
    Code: "25966",
    Name: "Phường Lái Thiêu",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8417,
    Code: "25969",
    Name: "Phường Bình Chuẩn",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8418,
    Code: "25972",
    Name: "Phường Thuận Giao",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8419,
    Code: "25975",
    Name: "Phường An Phú",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8420,
    Code: "25978",
    Name: "Phường Hưng Định",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8421,
    Code: "25981",
    Name: "Xã An Sơn",
    LevelUp: "Xã",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8422,
    Code: "25984",
    Name: "Phường Bình Nhâm",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8423,
    Code: "25987",
    Name: "Phường Bình Hòa",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8424,
    Code: "25990",
    Name: "Phường Vĩnh Phú",
    LevelUp: "Phường",
    DistrictCode: "4404",
    Active: -1,
  },
  {
    ID: 8425,
    Code: "25894",
    Name: "Xã Tân Định",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8426,
    Code: "25897",
    Name: "Xã Bình Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8427,
    Code: "25900",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8428,
    Code: "25903",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8429,
    Code: "25906",
    Name: "Thị trấn Tân Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8430,
    Code: "25907",
    Name: "Xã Đất Cuốc",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8431,
    Code: "25908",
    Name: "Xã Hiếu Liêm",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8432,
    Code: "25909",
    Name: "Xã Lạc An",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8433,
    Code: "25918",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8434,
    Code: "25927",
    Name: "Xã Thường Tân",
    LevelUp: "Xã",
    DistrictCode: "4409",
    Active: -1,
  },
  {
    ID: 8435,
    Code: "25993",
    Name: "Phường Trảng Dài",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8436,
    Code: "25996",
    Name: "Phường Tân Phong",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8437,
    Code: "25999",
    Name: "Phường Tân Biên",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8438,
    Code: "26002",
    Name: "Phường Hố Nai",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8439,
    Code: "26005",
    Name: "Phường Tân Hòa",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8440,
    Code: "26008",
    Name: "Phường Tân Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8441,
    Code: "26011",
    Name: "Phường Bửu Long",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8442,
    Code: "26014",
    Name: "Phường Tân Tiến",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8443,
    Code: "26017",
    Name: "Phường Tam Hiệp",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8444,
    Code: "26020",
    Name: "Phường Long Bình",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8445,
    Code: "26023",
    Name: "Phường Quang Vinh",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8446,
    Code: "26026",
    Name: "Phường Tân Mai",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8447,
    Code: "26029",
    Name: "Phường Thống Nhất",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8448,
    Code: "26032",
    Name: "Phường Trung Dũng",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8449,
    Code: "26035",
    Name: "Phường Tam Hòa",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8450,
    Code: "26038",
    Name: "Phường Hòa Bình",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8451,
    Code: "26041",
    Name: "Phường Quyết Thắng",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8452,
    Code: "26044",
    Name: "Phường Thanh Bình",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8453,
    Code: "26047",
    Name: "Phường Bình Đa",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8454,
    Code: "26050",
    Name: "Phường An Bình",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8455,
    Code: "26053",
    Name: "Phường Bửu Hòa",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8456,
    Code: "26056",
    Name: "Phường Long Bình Tân",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8457,
    Code: "26059",
    Name: "Phường Tân Vạn",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8458,
    Code: "26062",
    Name: "Phường Tân Hạnh",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8459,
    Code: "26065",
    Name: "Phường Hiệp Hòa",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8460,
    Code: "26068",
    Name: "Phường Hóa An",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8461,
    Code: "26371",
    Name: "Phường An Hòa",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8462,
    Code: "26374",
    Name: "Phường Tam Phước",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8463,
    Code: "26377",
    Name: "Phường Phước Tân",
    LevelUp: "Phường",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8464,
    Code: "26380",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "4801",
    Active: -1,
  },
  {
    ID: 8465,
    Code: "26071",
    Name: "Phường Xuân Trung",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8466,
    Code: "26074",
    Name: "Phường Xuân Thanh",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8467,
    Code: "26077",
    Name: "Phường Xuân Bình",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8468,
    Code: "26080",
    Name: "Phường Xuân An",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8469,
    Code: "26083",
    Name: "Phường Xuân Hoà",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8470,
    Code: "26086",
    Name: "Phường Phú Bình",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8471,
    Code: "26089",
    Name: "Xã Bình Lộc",
    LevelUp: "Xã",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8472,
    Code: "26092",
    Name: "Xã Bảo Quang",
    LevelUp: "Xã",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8473,
    Code: "26095",
    Name: "Phường Suối Tre",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8474,
    Code: "26098",
    Name: "Phường Bảo Vinh",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8475,
    Code: "26101",
    Name: "Phường Xuân Lập",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8476,
    Code: "26104",
    Name: "Phường Bàu Sen",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8477,
    Code: "26107",
    Name: "Xã Bàu Trâm",
    LevelUp: "Xã",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8478,
    Code: "26110",
    Name: "Phường Xuân Tân",
    LevelUp: "Phường",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8479,
    Code: "26113",
    Name: "Xã Hàng Gòn",
    LevelUp: "Xã",
    DistrictCode: "4806",
    Active: -1,
  },
  {
    ID: 8480,
    Code: "26116",
    Name: "Thị trấn Tân Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8481,
    Code: "26119",
    Name: "Xã Dak Lua",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8482,
    Code: "26122",
    Name: "Xã Nam Cát Tiên",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8483,
    Code: "26125",
    Name: "Xã Phú An",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8484,
    Code: "26128",
    Name: "Xã Núi Tượng",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8485,
    Code: "26131",
    Name: "Xã Tà Lài",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8486,
    Code: "26134",
    Name: "Xã Phú Lập",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8487,
    Code: "26137",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8488,
    Code: "26140",
    Name: "Xã Phú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8489,
    Code: "26143",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8490,
    Code: "26146",
    Name: "Xã Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8491,
    Code: "26149",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8492,
    Code: "26152",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8493,
    Code: "26155",
    Name: "Xã Phú Lâm",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8494,
    Code: "26158",
    Name: "Xã Phú Bình",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8495,
    Code: "26161",
    Name: "Xã Phú Thanh",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8496,
    Code: "26164",
    Name: "Xã Trà Cổ",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8497,
    Code: "26167",
    Name: "Xã Phú Điền",
    LevelUp: "Xã",
    DistrictCode: "4803",
    Active: -1,
  },
  {
    ID: 8498,
    Code: "26170",
    Name: "Thị trấn Vĩnh An",
    LevelUp: "Thị trấn",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8499,
    Code: "26173",
    Name: "Xã Phú Lý",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8500,
    Code: "26176",
    Name: "Xã Trị An",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8501,
    Code: "26179",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8502,
    Code: "26182",
    Name: "Xã Vĩnh Tân",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8503,
    Code: "26185",
    Name: "Xã Bình Lợi",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8504,
    Code: "26188",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8505,
    Code: "26191",
    Name: "Xã Thiện Tân",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8506,
    Code: "26194",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8507,
    Code: "26197",
    Name: "Xã Bình Hòa",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8508,
    Code: "26200",
    Name: "Xã Mã Đà",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8509,
    Code: "26203",
    Name: "Xã Hiếu Liêm",
    LevelUp: "Xã",
    DistrictCode: "4802",
    Active: -1,
  },
  {
    ID: 8510,
    Code: "26206",
    Name: "Thị trấn Định Quán",
    LevelUp: "Thị trấn",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8511,
    Code: "26209",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8512,
    Code: "26212",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8513,
    Code: "26215",
    Name: "Xã Phú Vinh",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8514,
    Code: "26218",
    Name: "Xã Phú Lợi",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8515,
    Code: "26221",
    Name: "Xã Phú Hòa",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8516,
    Code: "26224",
    Name: "Xã Ngọc Định",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8517,
    Code: "26227",
    Name: "Xã La Ngà",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8518,
    Code: "26230",
    Name: "Xã Gia Canh",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8519,
    Code: "26233",
    Name: "Xã Phú Ngọc",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8520,
    Code: "26236",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8521,
    Code: "26239",
    Name: "Xã Túc Trưng",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8522,
    Code: "26242",
    Name: "Xã Phú Túc",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8523,
    Code: "26245",
    Name: "Xã Suối Nho",
    LevelUp: "Xã",
    DistrictCode: "4804",
    Active: -1,
  },
  {
    ID: 8524,
    Code: "26248",
    Name: "Thị trấn Trảng Bom",
    LevelUp: "Thị trấn",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8525,
    Code: "26251",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8526,
    Code: "26254",
    Name: "Xã Cây Gáo",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8527,
    Code: "26257",
    Name: "Xã Bàu Hàm",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8528,
    Code: "26260",
    Name: "Xã Sông Thao",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8529,
    Code: "26263",
    Name: "Xã Sông Trầu",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8530,
    Code: "26266",
    Name: "Xã Đông Hoà",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8531,
    Code: "26269",
    Name: "Xã Bắc Sơn",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8532,
    Code: "26272",
    Name: "Xã Hố Nai 3",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8533,
    Code: "26275",
    Name: "Xã Tây Hoà",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8534,
    Code: "26278",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8535,
    Code: "26281",
    Name: "Xã Trung Hoà",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8536,
    Code: "26284",
    Name: "Xã Đồi 61",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8537,
    Code: "26287",
    Name: "Xã Hưng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8538,
    Code: "26290",
    Name: "Xã Quảng Tiến",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8539,
    Code: "26293",
    Name: "Xã Giang Điền",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8540,
    Code: "26296",
    Name: "Xã An Viễn",
    LevelUp: "Xã",
    DistrictCode: "4810",
    Active: -1,
  },
  {
    ID: 8541,
    Code: "26299",
    Name: "Xã Gia Tân 1",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8542,
    Code: "26302",
    Name: "Xã Gia Tân 2",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8543,
    Code: "26305",
    Name: "Xã Gia Tân 3",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8544,
    Code: "26308",
    Name: "Xã Gia Kiệm",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8545,
    Code: "26311",
    Name: "Xã Quang Trung",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8546,
    Code: "26314",
    Name: "Xã Bàu Hàm 2",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8547,
    Code: "26317",
    Name: "Xã Hưng Lộc",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8548,
    Code: "26320",
    Name: "Xã Lộ 25",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8549,
    Code: "26323",
    Name: "Xã Xuân Thiện",
    LevelUp: "Xã",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8550,
    Code: "26326",
    Name: "Thị trấn Dầu Giây",
    LevelUp: "Thị trấn",
    DistrictCode: "4805",
    Active: -1,
  },
  {
    ID: 8551,
    Code: "26329",
    Name: "Xã Sông Nhạn",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8552,
    Code: "26332",
    Name: "Xã Xuân Quế",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8553,
    Code: "26335",
    Name: "Xã Nhân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8554,
    Code: "26338",
    Name: "Xã Xuân Đường",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8555,
    Code: "26341",
    Name: "Xã Long Giao",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8556,
    Code: "26344",
    Name: "Xã Xuân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8557,
    Code: "26347",
    Name: "Xã Thừa Đức",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8558,
    Code: "26350",
    Name: "Xã Bảo Bình",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8559,
    Code: "26353",
    Name: "Xã Xuân Bảo",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8560,
    Code: "26356",
    Name: "Xã Xuân Tây",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8561,
    Code: "26359",
    Name: "Xã Xuân Đông",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8562,
    Code: "26362",
    Name: "Xã Sông Ray",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8563,
    Code: "26365",
    Name: "Xã Lâm San",
    LevelUp: "Xã",
    DistrictCode: "4811",
    Active: -1,
  },
  {
    ID: 8564,
    Code: "26368",
    Name: "Thị trấn Long Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8565,
    Code: "26383",
    Name: "Xã An Phước",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8566,
    Code: "26386",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8567,
    Code: "26389",
    Name: "Xã Long Đức",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8568,
    Code: "26392",
    Name: "Xã Lộc An",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8569,
    Code: "26395",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8570,
    Code: "26398",
    Name: "Xã Tam An",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8571,
    Code: "26401",
    Name: "Xã Cẩm Đường",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8572,
    Code: "26404",
    Name: "Xã Long An",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8573,
    Code: "26410",
    Name: "Xã Bàu Cạn",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8574,
    Code: "26413",
    Name: "Xã Long Phước",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8575,
    Code: "26416",
    Name: "Xã Phước Bình",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8576,
    Code: "26419",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8577,
    Code: "26422",
    Name: "Xã Phước Thái",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 8578,
    Code: "26425",
    Name: "Thị trấn Gia Ray",
    LevelUp: "Thị trấn",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8579,
    Code: "26428",
    Name: "Xã Xuân Bắc",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8580,
    Code: "26431",
    Name: "Xã Suối Cao",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8581,
    Code: "26434",
    Name: "Xã Xuân Thành",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8582,
    Code: "26437",
    Name: "Xã Xuân Thọ",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8583,
    Code: "26440",
    Name: "Xã Xuân Trường",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8584,
    Code: "26443",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8585,
    Code: "26446",
    Name: "Xã Xuân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8586,
    Code: "26449",
    Name: "Xã Xuân Tâm",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8587,
    Code: "26452",
    Name: "Xã Suối Cát",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8588,
    Code: "26455",
    Name: "Xã Xuân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8589,
    Code: "26458",
    Name: "Xã Xuân Phú",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8590,
    Code: "26461",
    Name: "Xã Xuân Định",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8591,
    Code: "26464",
    Name: "Xã Bảo Hoà",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8592,
    Code: "26467",
    Name: "Xã Lang Minh",
    LevelUp: "Xã",
    DistrictCode: "4807",
    Active: -1,
  },
  {
    ID: 8593,
    Code: "26470",
    Name: "Xã Phước Thiền",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8594,
    Code: "26473",
    Name: "Xã Long Tân",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8595,
    Code: "26476",
    Name: "Xã Đại Phước",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8596,
    Code: "26479",
    Name: "Thị trấn Hiệp Phước",
    LevelUp: "Thị trấn",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8597,
    Code: "26482",
    Name: "Xã Phú Hữu",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8598,
    Code: "26485",
    Name: "Xã Phú Hội",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8599,
    Code: "26488",
    Name: "Xã Phú Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8600,
    Code: "26491",
    Name: "Xã Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8601,
    Code: "26494",
    Name: "Xã Long Thọ",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8602,
    Code: "26497",
    Name: "Xã Vĩnh Thanh",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8603,
    Code: "26500",
    Name: "Xã Phước Khánh",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8604,
    Code: "26503",
    Name: "Xã Phước An",
    LevelUp: "Xã",
    DistrictCode: "4809",
    Active: -1,
  },
  {
    ID: 8605,
    Code: "26506",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8606,
    Code: "26508",
    Name: "Phường Thắng Tam",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8607,
    Code: "26509",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8608,
    Code: "26512",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8609,
    Code: "26515",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8610,
    Code: "26518",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8611,
    Code: "26521",
    Name: "Phường Thắng Nhì",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8612,
    Code: "26524",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8613,
    Code: "26526",
    Name: "Phường Nguyễn An Ninh",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8614,
    Code: "26527",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8615,
    Code: "26530",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8616,
    Code: "26533",
    Name: "Phường Thắng Nhất",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8617,
    Code: "26535",
    Name: "Phường Rạch Dừa",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8618,
    Code: "26536",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8619,
    Code: "26539",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8620,
    Code: "26542",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8621,
    Code: "26545",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "5201",
    Active: -1,
  },
  {
    ID: 8622,
    Code: "26548",
    Name: "Phường Phước Hưng",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8623,
    Code: "26551",
    Name: "Phường Phước Hiệp",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8624,
    Code: "26554",
    Name: "Phường Phước Nguyên",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8625,
    Code: "26557",
    Name: "Phường Long Toàn",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8626,
    Code: "26558",
    Name: "Phường Long Tâm",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8627,
    Code: "26560",
    Name: "Phường Phước Trung",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8628,
    Code: "26563",
    Name: "Phường Long Hương",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8629,
    Code: "26566",
    Name: "Phường Kim Dinh",
    LevelUp: "Phường",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8630,
    Code: "26567",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8631,
    Code: "26569",
    Name: "Xã Long Phước",
    LevelUp: "Xã",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8632,
    Code: "26572",
    Name: "Xã Hoà Long",
    LevelUp: "Xã",
    DistrictCode: "5202",
    Active: -1,
  },
  {
    ID: 8633,
    Code: "26574",
    Name: "Xã Bàu Chinh",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8634,
    Code: "26575",
    Name: "Thị trấn Ngãi Giao",
    LevelUp: "Thị trấn",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8635,
    Code: "26578",
    Name: "Xã Bình Ba",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8636,
    Code: "26581",
    Name: "Xã Suối Nghệ",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8637,
    Code: "26584",
    Name: "Xã Xuân Sơn",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8638,
    Code: "26587",
    Name: "Xã Sơn Bình",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8639,
    Code: "26590",
    Name: "Xã Bình Giã",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8640,
    Code: "26593",
    Name: "Xã Bình Trung",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8641,
    Code: "26596",
    Name: "Xã Xà Bang",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8642,
    Code: "26599",
    Name: "Xã Cù Bị",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8643,
    Code: "26602",
    Name: "Xã Láng Lớn",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8644,
    Code: "26605",
    Name: "Xã Quảng Thành",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8645,
    Code: "26608",
    Name: "Xã Kim Long",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8646,
    Code: "26611",
    Name: "Xã Suối Rao",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8647,
    Code: "26614",
    Name: "Xã Đá Bạc",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8648,
    Code: "26617",
    Name: "Xã Nghĩa Thành",
    LevelUp: "Xã",
    DistrictCode: "5207",
    Active: -1,
  },
  {
    ID: 8649,
    Code: "26620",
    Name: "Thị trấn Phước Bửu",
    LevelUp: "Thị trấn",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8650,
    Code: "26623",
    Name: "Xã Phước Thuận",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8651,
    Code: "26626",
    Name: "Xã Phước Tân",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8652,
    Code: "26629",
    Name: "Xã Xuyên Mộc",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8653,
    Code: "26632",
    Name: "Xã Bông Trang",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8654,
    Code: "26635",
    Name: "Xã Tân Lâm",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8655,
    Code: "26638",
    Name: "Xã Bàu Lâm",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8656,
    Code: "26641",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8657,
    Code: "26644",
    Name: "Xã Hòa Hưng",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8658,
    Code: "26647",
    Name: "Xã Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8659,
    Code: "26650",
    Name: "Xã Hòa Hội",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8660,
    Code: "26653",
    Name: "Xã Bưng Riềng",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8661,
    Code: "26656",
    Name: "Xã Bình Châu",
    LevelUp: "Xã",
    DistrictCode: "5203",
    Active: -1,
  },
  {
    ID: 8662,
    Code: "26659",
    Name: "Thị trấn Long Điền",
    LevelUp: "Thị trấn",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8663,
    Code: "26662",
    Name: "Thị trấn Long Hải",
    LevelUp: "Thị trấn",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8664,
    Code: "26665",
    Name: "Xã An Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8665,
    Code: "26668",
    Name: "Xã Tam Phước",
    LevelUp: "Xã",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8666,
    Code: "26671",
    Name: "Xã An Nhứt",
    LevelUp: "Xã",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8667,
    Code: "26674",
    Name: "Xã Phước Tỉnh",
    LevelUp: "Xã",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8668,
    Code: "26677",
    Name: "Xã Phước Hưng",
    LevelUp: "Xã",
    DistrictCode: "5204",
    Active: -1,
  },
  {
    ID: 8669,
    Code: "26680",
    Name: "Thị trấn Đất Đỏ",
    LevelUp: "Thị trấn",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8670,
    Code: "26683",
    Name: "Xã Phước Long Thọ",
    LevelUp: "Xã",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8671,
    Code: "26686",
    Name: "Xã Phước Hội",
    LevelUp: "Xã",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8672,
    Code: "26689",
    Name: "Xã Long Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8673,
    Code: "26692",
    Name: "Thị trấn Phước Hải",
    LevelUp: "Thị trấn",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8674,
    Code: "26695",
    Name: "Xã Long Tân",
    LevelUp: "Xã",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8675,
    Code: "26698",
    Name: "Xã Láng Dài",
    LevelUp: "Xã",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8676,
    Code: "26701",
    Name: "Xã Lộc An",
    LevelUp: "Xã",
    DistrictCode: "5208",
    Active: -1,
  },
  {
    ID: 8677,
    Code: "26704",
    Name: "Phường Phú Mỹ",
    LevelUp: "Phường",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8678,
    Code: "26707",
    Name: "Xã Tân Hoà",
    LevelUp: "Xã",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8679,
    Code: "26710",
    Name: "Xã Tân Hải",
    LevelUp: "Xã",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8680,
    Code: "26713",
    Name: "Phường Phước Hoà",
    LevelUp: "Phường",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8681,
    Code: "26716",
    Name: "Phường Tân Phước",
    LevelUp: "Phường",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8682,
    Code: "26719",
    Name: "Phường Mỹ Xuân",
    LevelUp: "Phường",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8683,
    Code: "26722",
    Name: "Xã Sông Xoài",
    LevelUp: "Xã",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8684,
    Code: "26725",
    Name: "Phường Hắc Dịch",
    LevelUp: "Phường",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8685,
    Code: "26728",
    Name: "Xã Châu Pha",
    LevelUp: "Xã",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8686,
    Code: "26731",
    Name: "Xã Tóc Tiên",
    LevelUp: "Xã",
    DistrictCode: "5209",
    Active: -1,
  },
  {
    ID: 8687,
    Code: "26734",
    Name: "Phường Tân Định",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8688,
    Code: "26737",
    Name: "Phường Đa Kao",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8689,
    Code: "26740",
    Name: "Phường Bến Nghé",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8690,
    Code: "26743",
    Name: "Phường Bến Thành",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8691,
    Code: "26746",
    Name: "Phường Nguyễn Thái Bình",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8692,
    Code: "26749",
    Name: "Phường Phạm Ngũ Lão",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8693,
    Code: "26752",
    Name: "Phường Cầu Ông Lãnh",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8694,
    Code: "26755",
    Name: "Phường Cô Giang",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8695,
    Code: "26758",
    Name: "Phường Nguyễn Cư Trinh",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8696,
    Code: "26761",
    Name: "Phường Cầu Kho",
    LevelUp: "Phường",
    DistrictCode: "0201",
    Active: -1,
  },
  {
    ID: 8697,
    Code: "26764",
    Name: "Phường Thạnh Xuân",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8698,
    Code: "26767",
    Name: "Phường Thạnh Lộc",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8699,
    Code: "26770",
    Name: "Phường Hiệp Thành",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8700,
    Code: "26773",
    Name: "Phường Thới An",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8701,
    Code: "26776",
    Name: "Phường Tân Chánh Hiệp",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8702,
    Code: "26779",
    Name: "Phường An Phú Đông",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8703,
    Code: "26782",
    Name: "Phường Tân Thới Hiệp",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8704,
    Code: "26785",
    Name: "Phường Trung Mỹ Tây",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8705,
    Code: "26787",
    Name: "Phường Tân Hưng Thuận",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8706,
    Code: "26788",
    Name: "Phường Đông Hưng Thuận",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8707,
    Code: "26791",
    Name: "Phường Tân Thới Nhất",
    LevelUp: "Phường",
    DistrictCode: "0212",
    Active: -1,
  },
  {
    ID: 8708,
    Code: "26794",
    Name: "Phường Linh Xuân",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8709,
    Code: "26797",
    Name: "Phường Bình Chiểu",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8710,
    Code: "26800",
    Name: "Phường Linh Trung",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8711,
    Code: "26803",
    Name: "Phường Tam Bình",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8712,
    Code: "26806",
    Name: "Phường Tam Phú",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8713,
    Code: "26809",
    Name: "Phường Hiệp Bình Phước",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8714,
    Code: "26812",
    Name: "Phường Hiệp Bình Chánh",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8715,
    Code: "26815",
    Name: "Phường Linh Chiểu",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8716,
    Code: "26818",
    Name: "Phường Linh Tây",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8717,
    Code: "26821",
    Name: "Phường Linh Đông",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8718,
    Code: "26824",
    Name: "Phường Bình Thọ",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8719,
    Code: "26827",
    Name: "Phường Trường Thọ",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8720,
    Code: "26830",
    Name: "Phường Long Bình",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8721,
    Code: "26833",
    Name: "Phường Long Thạnh Mỹ",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8722,
    Code: "26836",
    Name: "Phường Tân Phú",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8723,
    Code: "26839",
    Name: "Phường Hiệp Phú",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8724,
    Code: "26842",
    Name: "Phường Tăng Nhơn Phú A",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8725,
    Code: "26845",
    Name: "Phường Tăng Nhơn Phú B",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8726,
    Code: "26848",
    Name: "Phường Phước Long B",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8727,
    Code: "26851",
    Name: "Phường Phước Long A",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8728,
    Code: "26854",
    Name: "Phường Trường Thạnh",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8729,
    Code: "26857",
    Name: "Phường Long Phước",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8730,
    Code: "26860",
    Name: "Phường Long Trường",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8731,
    Code: "26863",
    Name: "Phường Phước Bình",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8732,
    Code: "26866",
    Name: "Phường Phú Hữu",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8733,
    Code: "26869",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8734,
    Code: "26872",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8735,
    Code: "26875",
    Name: "Phường 17",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8736,
    Code: "26876",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8737,
    Code: "26878",
    Name: "Phường 16",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8738,
    Code: "26881",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8739,
    Code: "26882",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8740,
    Code: "26884",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8741,
    Code: "26887",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8742,
    Code: "26890",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8743,
    Code: "26893",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8744,
    Code: "26896",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8745,
    Code: "26897",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8746,
    Code: "26898",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8747,
    Code: "26899",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8748,
    Code: "26902",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0213",
    Active: -1,
  },
  {
    ID: 8749,
    Code: "26905",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8750,
    Code: "26908",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8751,
    Code: "26911",
    Name: "Phường 27",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8752,
    Code: "26914",
    Name: "Phường 26",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8753,
    Code: "26917",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8754,
    Code: "26920",
    Name: "Phường 25",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8755,
    Code: "26923",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8756,
    Code: "26926",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8757,
    Code: "26929",
    Name: "Phường 24",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8758,
    Code: "26932",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8759,
    Code: "26935",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8760,
    Code: "26938",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8761,
    Code: "26941",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8762,
    Code: "26944",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8763,
    Code: "26947",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8764,
    Code: "26950",
    Name: "Phường 17",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8765,
    Code: "26953",
    Name: "Phường 21",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8766,
    Code: "26956",
    Name: "Phường 22",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8767,
    Code: "26959",
    Name: "Phường 19",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8768,
    Code: "26962",
    Name: "Phường 28",
    LevelUp: "Phường",
    DistrictCode: "0216",
    Active: -1,
  },
  {
    ID: 8769,
    Code: "26965",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8770,
    Code: "26968",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8771,
    Code: "26971",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8772,
    Code: "26974",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8773,
    Code: "26977",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8774,
    Code: "26980",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8775,
    Code: "26983",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8776,
    Code: "26986",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8777,
    Code: "26989",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8778,
    Code: "26992",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8779,
    Code: "26995",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8780,
    Code: "26998",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8781,
    Code: "27001",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8782,
    Code: "27004",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8783,
    Code: "27007",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0214",
    Active: -1,
  },
  {
    ID: 8784,
    Code: "27010",
    Name: "Phường Tân Sơn Nhì",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8785,
    Code: "27013",
    Name: "Phường Tây Thạnh",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8786,
    Code: "27016",
    Name: "Phường Sơn Kỳ",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8787,
    Code: "27019",
    Name: "Phường Tân Quý",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8788,
    Code: "27022",
    Name: "Phường Tân Thành",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8789,
    Code: "27025",
    Name: "Phường Phú Thọ Hòa",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8790,
    Code: "27028",
    Name: "Phường Phú Thạnh",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8791,
    Code: "27031",
    Name: "Phường Phú Trung",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8792,
    Code: "27034",
    Name: "Phường Hòa Thạnh",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8793,
    Code: "27037",
    Name: "Phường Hiệp Tân",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8794,
    Code: "27040",
    Name: "Phường Tân Thới Hòa",
    LevelUp: "Phường",
    DistrictCode: "0215",
    Active: -1,
  },
  {
    ID: 8795,
    Code: "27043",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8796,
    Code: "27046",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8797,
    Code: "27049",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8798,
    Code: "27052",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8799,
    Code: "27055",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8800,
    Code: "27058",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8801,
    Code: "27061",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8802,
    Code: "27064",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8803,
    Code: "27067",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8804,
    Code: "27070",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8805,
    Code: "27073",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8806,
    Code: "27076",
    Name: "Phường 17",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8807,
    Code: "27079",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8808,
    Code: "27082",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8809,
    Code: "27085",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0217",
    Active: -1,
  },
  {
    ID: 8810,
    Code: "27088",
    Name: "Phường Thảo Điền",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8811,
    Code: "27091",
    Name: "Phường An Phú",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8812,
    Code: "27094",
    Name: "Phường Bình An",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8813,
    Code: "27097",
    Name: "Phường Bình Trưng Đông",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8814,
    Code: "27100",
    Name: "Phường Bình Trưng Tây",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8815,
    Code: "27103",
    Name: "Phường Bình Khánh",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8816,
    Code: "27106",
    Name: "Phường An Khánh",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8817,
    Code: "27109",
    Name: "Phường Cát Lái",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8818,
    Code: "27112",
    Name: "Phường Thạnh Mỹ Lợi",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8819,
    Code: "27115",
    Name: "Phường An Lợi Đông",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8820,
    Code: "27118",
    Name: "Phường Thủ Thiêm",
    LevelUp: "Phường",
    DistrictCode: "0218",
    Active: -1,
  },
  {
    ID: 8821,
    Code: "27121",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8822,
    Code: "27124",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8823,
    Code: "27127",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8824,
    Code: "27130",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8825,
    Code: "27133",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8826,
    Code: "27136",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8827,
    Code: "27139",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8828,
    Code: "27142",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8829,
    Code: "27145",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8830,
    Code: "27148",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8831,
    Code: "27151",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8832,
    Code: "27154",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8833,
    Code: "27157",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8834,
    Code: "27160",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0203",
    Active: -1,
  },
  {
    ID: 8835,
    Code: "27163",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8836,
    Code: "27166",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8837,
    Code: "27169",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8838,
    Code: "27172",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8839,
    Code: "27175",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8840,
    Code: "27178",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8841,
    Code: "27181",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8842,
    Code: "27184",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8843,
    Code: "27187",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8844,
    Code: "27190",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8845,
    Code: "27193",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8846,
    Code: "27196",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8847,
    Code: "27199",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8848,
    Code: "27202",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8849,
    Code: "27205",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0210",
    Active: -1,
  },
  {
    ID: 8850,
    Code: "27208",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8851,
    Code: "27211",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8852,
    Code: "27214",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8853,
    Code: "27217",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8854,
    Code: "27220",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8855,
    Code: "27223",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8856,
    Code: "27226",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8857,
    Code: "27229",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8858,
    Code: "27232",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8859,
    Code: "27235",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8860,
    Code: "27238",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8861,
    Code: "27241",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8862,
    Code: "27244",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8863,
    Code: "27247",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8864,
    Code: "27250",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8865,
    Code: "27253",
    Name: "Phường 16",
    LevelUp: "Phường",
    DistrictCode: "0211",
    Active: -1,
  },
  {
    ID: 8866,
    Code: "27256",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8867,
    Code: "27259",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8868,
    Code: "27262",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8869,
    Code: "27265",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8870,
    Code: "27268",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8871,
    Code: "27271",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8872,
    Code: "27274",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8873,
    Code: "27277",
    Name: "Phường 18",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8874,
    Code: "27280",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8875,
    Code: "27283",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8876,
    Code: "27286",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8877,
    Code: "27289",
    Name: "Phường 16",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8878,
    Code: "27292",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8879,
    Code: "27295",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8880,
    Code: "27298",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0204",
    Active: -1,
  },
  {
    ID: 8881,
    Code: "27301",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8882,
    Code: "27304",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8883,
    Code: "27307",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8884,
    Code: "27310",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8885,
    Code: "27313",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8886,
    Code: "27316",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8887,
    Code: "27319",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8888,
    Code: "27322",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8889,
    Code: "27325",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8890,
    Code: "27328",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8891,
    Code: "27331",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8892,
    Code: "27334",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8893,
    Code: "27337",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8894,
    Code: "27340",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8895,
    Code: "27343",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0205",
    Active: -1,
  },
  {
    ID: 8896,
    Code: "27346",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8897,
    Code: "27349",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8898,
    Code: "27352",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8899,
    Code: "27355",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8900,
    Code: "27358",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8901,
    Code: "27361",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8902,
    Code: "27364",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8903,
    Code: "27367",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8904,
    Code: "27370",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8905,
    Code: "27373",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8906,
    Code: "27376",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8907,
    Code: "27379",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8908,
    Code: "27382",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8909,
    Code: "27385",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0206",
    Active: -1,
  },
  {
    ID: 8910,
    Code: "27388",
    Name: "Phường 08",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8911,
    Code: "27391",
    Name: "Phường 02",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8912,
    Code: "27394",
    Name: "Phường 01",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8913,
    Code: "27397",
    Name: "Phường 03",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8914,
    Code: "27400",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8915,
    Code: "27403",
    Name: "Phường 09",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8916,
    Code: "27406",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8917,
    Code: "27409",
    Name: "Phường 04",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8918,
    Code: "27412",
    Name: "Phường 13",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8919,
    Code: "27415",
    Name: "Phường 12",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8920,
    Code: "27418",
    Name: "Phường 05",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8921,
    Code: "27421",
    Name: "Phường 14",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8922,
    Code: "27424",
    Name: "Phường 06",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8923,
    Code: "27427",
    Name: "Phường 15",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8924,
    Code: "27430",
    Name: "Phường 16",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8925,
    Code: "27433",
    Name: "Phường 07",
    LevelUp: "Phường",
    DistrictCode: "0208",
    Active: -1,
  },
  {
    ID: 8926,
    Code: "27436",
    Name: "Phường Bình Hưng Hòa",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8927,
    Code: "27439",
    Name: "Phường Bình Hưng Hoà A",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8928,
    Code: "27442",
    Name: "Phường Bình Hưng Hoà B",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8929,
    Code: "27445",
    Name: "Phường Bình Trị Đông",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8930,
    Code: "27448",
    Name: "Phường Bình Trị Đông A",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8931,
    Code: "27451",
    Name: "Phường Bình Trị Đông B",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8932,
    Code: "27454",
    Name: "Phường Tân Tạo",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8933,
    Code: "27457",
    Name: "Phường Tân Tạo A",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8934,
    Code: "27460",
    Name: "Phường  An Lạc",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8935,
    Code: "27463",
    Name: "Phường An Lạc A",
    LevelUp: "Phường",
    DistrictCode: "0219",
    Active: -1,
  },
  {
    ID: 8936,
    Code: "27466",
    Name: "Phường Tân Thuận Đông",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8937,
    Code: "27469",
    Name: "Phường Tân Thuận Tây",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8938,
    Code: "27472",
    Name: "Phường Tân Kiểng",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8939,
    Code: "27475",
    Name: "Phường Tân Hưng",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8940,
    Code: "27478",
    Name: "Phường Bình Thuận",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8941,
    Code: "27481",
    Name: "Phường Tân Quy",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8942,
    Code: "27484",
    Name: "Phường Phú Thuận",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8943,
    Code: "27487",
    Name: "Phường Tân Phú",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8944,
    Code: "27490",
    Name: "Phường Tân Phong",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8945,
    Code: "27493",
    Name: "Phường Phú Mỹ",
    LevelUp: "Phường",
    DistrictCode: "0207",
    Active: -1,
  },
  {
    ID: 8946,
    Code: "27496",
    Name: "Thị trấn Củ Chi",
    LevelUp: "Thị trấn",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8947,
    Code: "27499",
    Name: "Xã Phú Mỹ Hưng",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8948,
    Code: "27502",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8949,
    Code: "27505",
    Name: "Xã Trung Lập Thượng",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8950,
    Code: "27508",
    Name: "Xã An Nhơn Tây",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8951,
    Code: "27511",
    Name: "Xã Nhuận Đức",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8952,
    Code: "27514",
    Name: "Xã Phạm Văn Cội",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8953,
    Code: "27517",
    Name: "Xã Phú Hòa Đông",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8954,
    Code: "27520",
    Name: "Xã Trung Lập Hạ",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8955,
    Code: "27523",
    Name: "Xã Trung An",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8956,
    Code: "27526",
    Name: "Xã Phước Thạnh",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8957,
    Code: "27529",
    Name: "Xã Phước Hiệp",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8958,
    Code: "27532",
    Name: "Xã Tân An Hội",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8959,
    Code: "27535",
    Name: "Xã Phước Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8960,
    Code: "27538",
    Name: "Xã Thái Mỹ",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8961,
    Code: "27541",
    Name: "Xã Tân Thạnh Tây",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8962,
    Code: "27544",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8963,
    Code: "27547",
    Name: "Xã Tân Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8964,
    Code: "27550",
    Name: "Xã Bình Mỹ",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8965,
    Code: "27553",
    Name: "Xã Tân Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8966,
    Code: "27556",
    Name: "Xã Tân Thông Hội",
    LevelUp: "Xã",
    DistrictCode: "0221",
    Active: -1,
  },
  {
    ID: 8967,
    Code: "27559",
    Name: "Thị trấn Hóc Môn",
    LevelUp: "Thị trấn",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8968,
    Code: "27562",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8969,
    Code: "27565",
    Name: "Xã Nhị Bình",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8970,
    Code: "27568",
    Name: "Xã Đông Thạnh",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8971,
    Code: "27571",
    Name: "Xã Tân Thới Nhì",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8972,
    Code: "27574",
    Name: "Xã Thới Tam Thôn",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8973,
    Code: "27577",
    Name: "Xã Xuân Thới Sơn",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8974,
    Code: "27580",
    Name: "Xã Tân Xuân",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8975,
    Code: "27583",
    Name: "Xã Xuân Thới Đông",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8976,
    Code: "27586",
    Name: "Xã Trung Chánh",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8977,
    Code: "27589",
    Name: "Xã Xuân Thới Thượng",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8978,
    Code: "27592",
    Name: "Xã Bà Điểm",
    LevelUp: "Xã",
    DistrictCode: "0222",
    Active: -1,
  },
  {
    ID: 8979,
    Code: "27595",
    Name: "Thị trấn Tân Túc",
    LevelUp: "Thị trấn",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8980,
    Code: "27598",
    Name: "Xã Phạm Văn Hai",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8981,
    Code: "27601",
    Name: "Xã Vĩnh Lộc A",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8982,
    Code: "27604",
    Name: "Xã Vĩnh Lộc B",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8983,
    Code: "27607",
    Name: "Xã Bình Lợi",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8984,
    Code: "27610",
    Name: "Xã Lê Minh Xuân",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8985,
    Code: "27613",
    Name: "Xã Tân Nhựt",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8986,
    Code: "27616",
    Name: "Xã Tân Kiên",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8987,
    Code: "27619",
    Name: "Xã Bình Hưng",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8988,
    Code: "27622",
    Name: "Xã Phong Phú",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8989,
    Code: "27625",
    Name: "Xã An Phú Tây",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8990,
    Code: "27628",
    Name: "Xã Hưng Long",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8991,
    Code: "27631",
    Name: "Xã Đa Phước",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8992,
    Code: "27634",
    Name: "Xã Tân Quý Tây",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8993,
    Code: "27637",
    Name: "Xã Bình Chánh",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8994,
    Code: "27640",
    Name: "Xã Quy Đức",
    LevelUp: "Xã",
    DistrictCode: "0220",
    Active: -1,
  },
  {
    ID: 8995,
    Code: "27643",
    Name: "Thị trấn Nhà Bè",
    LevelUp: "Thị trấn",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 8996,
    Code: "27646",
    Name: "Xã Phước Kiển",
    LevelUp: "Xã",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 8997,
    Code: "27649",
    Name: "Xã Phước Lộc",
    LevelUp: "Xã",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 8998,
    Code: "27652",
    Name: "Xã Nhơn Đức",
    LevelUp: "Xã",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 8999,
    Code: "27655",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 9000,
    Code: "27658",
    Name: "Xã Long Thới",
    LevelUp: "Xã",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 9001,
    Code: "27661",
    Name: "Xã Hiệp Phước",
    LevelUp: "Xã",
    DistrictCode: "0223",
    Active: -1,
  },
  {
    ID: 9002,
    Code: "27664",
    Name: "Thị trấn Cần Thạnh",
    LevelUp: "Thị trấn",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9003,
    Code: "27667",
    Name: "Xã Bình Khánh",
    LevelUp: "Xã",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9004,
    Code: "27670",
    Name: "Xã Tam Thôn Hiệp",
    LevelUp: "Xã",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9005,
    Code: "27673",
    Name: "Xã An Thới Đông",
    LevelUp: "Xã",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9006,
    Code: "27676",
    Name: "Xã Thạnh An",
    LevelUp: "Xã",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9007,
    Code: "27679",
    Name: "Xã Long Hòa",
    LevelUp: "Xã",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9008,
    Code: "27682",
    Name: "Xã Lý Nhơn",
    LevelUp: "Xã",
    DistrictCode: "0224",
    Active: -1,
  },
  {
    ID: 9009,
    Code: "27685",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9010,
    Code: "27688",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9011,
    Code: "27691",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9012,
    Code: "27692",
    Name: "Phường Tân Khánh",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9013,
    Code: "27694",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9014,
    Code: "27697",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9015,
    Code: "27698",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9016,
    Code: "27700",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9017,
    Code: "27703",
    Name: "Xã Hướng Thọ Phú",
    LevelUp: "Xã",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9018,
    Code: "27706",
    Name: "Xã Nhơn Thạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9019,
    Code: "27709",
    Name: "Xã Lợi Bình Nhơn",
    LevelUp: "Xã",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9020,
    Code: "27712",
    Name: "Xã Bình Tâm",
    LevelUp: "Xã",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9021,
    Code: "27715",
    Name: "Phường Khánh Hậu",
    LevelUp: "Phường",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9022,
    Code: "27718",
    Name: "Xã An Vĩnh Ngãi",
    LevelUp: "Xã",
    DistrictCode: "4901",
    Active: -1,
  },
  {
    ID: 9023,
    Code: "27787",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9024,
    Code: "27788",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9025,
    Code: "27790",
    Name: "Xã Thạnh Trị",
    LevelUp: "Xã",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9026,
    Code: "27793",
    Name: "Xã Bình Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9027,
    Code: "27799",
    Name: "Xã Bình Tân",
    LevelUp: "Xã",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9028,
    Code: "27805",
    Name: "Xã Tuyên Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9029,
    Code: "27806",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9030,
    Code: "27817",
    Name: "Xã Thạnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "4915",
    Active: -1,
  },
  {
    ID: 9031,
    Code: "27721",
    Name: "Thị trấn Tân Hưng",
    LevelUp: "Thị trấn",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9032,
    Code: "27724",
    Name: "Xã Hưng Hà",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9033,
    Code: "27727",
    Name: "Xã Hưng Điền B",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9034,
    Code: "27730",
    Name: "Xã Hưng Điền",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9035,
    Code: "27733",
    Name: "Xã Thạnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9036,
    Code: "27736",
    Name: "Xã Hưng Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9037,
    Code: "27739",
    Name: "Xã Vĩnh Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9038,
    Code: "27742",
    Name: "Xã Vĩnh Châu B",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9039,
    Code: "27745",
    Name: "Xã Vĩnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9040,
    Code: "27748",
    Name: "Xã Vĩnh Đại",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9041,
    Code: "27751",
    Name: "Xã Vĩnh Châu A",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9042,
    Code: "27754",
    Name: "Xã Vĩnh Bửu",
    LevelUp: "Xã",
    DistrictCode: "4914",
    Active: -1,
  },
  {
    ID: 9043,
    Code: "27757",
    Name: "Thị trấn Vĩnh Hưng",
    LevelUp: "Thị trấn",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9044,
    Code: "27760",
    Name: "Xã Hưng Điền A",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9045,
    Code: "27763",
    Name: "Xã Khánh Hưng",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9046,
    Code: "27766",
    Name: "Xã Thái Trị",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9047,
    Code: "27769",
    Name: "Xã Vĩnh Trị",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9048,
    Code: "27772",
    Name: "Xã Thái Bình Trung",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9049,
    Code: "27775",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9050,
    Code: "27778",
    Name: "Xã Vĩnh Thuận",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9051,
    Code: "27781",
    Name: "Xã Tuyên Bình",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9052,
    Code: "27784",
    Name: "Xã Tuyên Bình Tây",
    LevelUp: "Xã",
    DistrictCode: "4902",
    Active: -1,
  },
  {
    ID: 9053,
    Code: "27796",
    Name: "Xã Bình Hòa Tây",
    LevelUp: "Xã",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9054,
    Code: "27802",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9055,
    Code: "27808",
    Name: "Xã Bình Hòa Trung",
    LevelUp: "Xã",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9056,
    Code: "27811",
    Name: "Xã Bình Hòa Đông",
    LevelUp: "Xã",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9057,
    Code: "27814",
    Name: "Thị trấn Bình Phong Thạnh",
    LevelUp: "Thị trấn",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9058,
    Code: "27820",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9059,
    Code: "27823",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4903",
    Active: -1,
  },
  {
    ID: 9060,
    Code: "27826",
    Name: "Thị trấn Tân Thạnh",
    LevelUp: "Thị trấn",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9061,
    Code: "27829",
    Name: "Xã Bắc Hòa",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9062,
    Code: "27832",
    Name: "Xã Hậu Thạnh Tây",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9063,
    Code: "27835",
    Name: "Xã Nhơn Hòa Lập",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9064,
    Code: "27838",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9065,
    Code: "27841",
    Name: "Xã Hậu Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9066,
    Code: "27844",
    Name: "Xã Nhơn Hoà",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9067,
    Code: "27847",
    Name: "Xã Kiến Bình",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9068,
    Code: "27850",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9069,
    Code: "27853",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9070,
    Code: "27856",
    Name: "Xã Tân Ninh",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9071,
    Code: "27859",
    Name: "Xã Nhơn Ninh",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9072,
    Code: "27862",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "4904",
    Active: -1,
  },
  {
    ID: 9073,
    Code: "27865",
    Name: "Thị trấn Thạnh Hóa",
    LevelUp: "Thị trấn",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9074,
    Code: "27868",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9075,
    Code: "27871",
    Name: "Xã Thuận Bình",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9076,
    Code: "27874",
    Name: "Xã Thạnh Phước",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9077,
    Code: "27877",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9078,
    Code: "27880",
    Name: "Xã Thuận Nghĩa Hòa",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9079,
    Code: "27883",
    Name: "Xã Thủy Đông",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9080,
    Code: "27886",
    Name: "Xã Thủy Tây",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9081,
    Code: "27889",
    Name: "Xã Tân Tây",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9082,
    Code: "27892",
    Name: "Xã Tân Đông",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9083,
    Code: "27895",
    Name: "Xã Thạnh An",
    LevelUp: "Xã",
    DistrictCode: "4905",
    Active: -1,
  },
  {
    ID: 9084,
    Code: "27898",
    Name: "Thị trấn Đông Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9085,
    Code: "27901",
    Name: "Xã Mỹ Quý Đông",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9086,
    Code: "27904",
    Name: "Xã Mỹ Thạnh Bắc",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9087,
    Code: "27907",
    Name: "Xã Mỹ Quý Tây",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9088,
    Code: "27910",
    Name: "Xã Mỹ Thạnh Tây",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9089,
    Code: "27913",
    Name: "Xã Mỹ Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9090,
    Code: "27916",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9091,
    Code: "27919",
    Name: "Xã Bình Hòa Bắc",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9092,
    Code: "27922",
    Name: "Xã Bình Hòa Hưng",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9093,
    Code: "27925",
    Name: "Xã Bình Hòa Nam",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9094,
    Code: "27928",
    Name: "Xã Mỹ Bình",
    LevelUp: "Xã",
    DistrictCode: "4906",
    Active: -1,
  },
  {
    ID: 9095,
    Code: "27931",
    Name: "Thị trấn Hậu Nghĩa",
    LevelUp: "Thị trấn",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9096,
    Code: "27934",
    Name: "Thị trấn Hiệp Hòa",
    LevelUp: "Thị trấn",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9097,
    Code: "27937",
    Name: "Thị trấn Đức Hòa",
    LevelUp: "Thị trấn",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9098,
    Code: "27940",
    Name: "Xã Lộc Giang",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9099,
    Code: "27943",
    Name: "Xã An Ninh Đông",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9100,
    Code: "27946",
    Name: "Xã An Ninh Tây",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9101,
    Code: "27949",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9102,
    Code: "27952",
    Name: "Xã Hiệp Hòa",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9103,
    Code: "27955",
    Name: "Xã Đức Lập Thượng",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9104,
    Code: "27958",
    Name: "Xã Đức Lập Hạ",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9105,
    Code: "27961",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9106,
    Code: "27964",
    Name: "Xã Mỹ Hạnh Bắc",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9107,
    Code: "27967",
    Name: "Xã Đức Hòa Thượng",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9108,
    Code: "27970",
    Name: "Xã Hòa Khánh Tây",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9109,
    Code: "27973",
    Name: "Xã Hòa Khánh Đông",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9110,
    Code: "27976",
    Name: "Xã Mỹ Hạnh Nam",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9111,
    Code: "27979",
    Name: "Xã Hòa Khánh Nam",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9112,
    Code: "27982",
    Name: "Xã Đức Hòa Đông",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9113,
    Code: "27985",
    Name: "Xã Đức Hòa Hạ",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9114,
    Code: "27988",
    Name: "Xã Hựu Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4907",
    Active: -1,
  },
  {
    ID: 9115,
    Code: "27991",
    Name: "Thị trấn Bến Lức",
    LevelUp: "Thị trấn",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9116,
    Code: "27994",
    Name: "Xã Thạnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9117,
    Code: "27997",
    Name: "Xã Lương Bình",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9118,
    Code: "28000",
    Name: "Xã Thạnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9119,
    Code: "28003",
    Name: "Xã Lương Hòa",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9120,
    Code: "28006",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9121,
    Code: "28009",
    Name: "Xã Tân Bửu",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9122,
    Code: "28012",
    Name: "Xã An Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9123,
    Code: "28015",
    Name: "Xã Bình Đức",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9124,
    Code: "28018",
    Name: "Xã Mỹ Yên",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9125,
    Code: "28021",
    Name: "Xã Thanh Phú",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9126,
    Code: "28024",
    Name: "Xã Long Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9127,
    Code: "28027",
    Name: "Xã Thạnh Đức",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9128,
    Code: "28030",
    Name: "Xã Phước Lợi",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9129,
    Code: "28033",
    Name: "Xã Nhựt Chánh",
    LevelUp: "Xã",
    DistrictCode: "4908",
    Active: -1,
  },
  {
    ID: 9130,
    Code: "28036",
    Name: "Thị trấn Thủ Thừa",
    LevelUp: "Thị trấn",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9131,
    Code: "28039",
    Name: "Xã Long Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9132,
    Code: "28042",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9133,
    Code: "28045",
    Name: "Xã Long Thuận",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9134,
    Code: "28048",
    Name: "Xã Mỹ Lạc",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9135,
    Code: "28051",
    Name: "Xã Mỹ Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9136,
    Code: "28054",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9137,
    Code: "28057",
    Name: "Xã Nhị Thành",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9138,
    Code: "28060",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9139,
    Code: "28063",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9140,
    Code: "28066",
    Name: "Xã Mỹ Phú",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9141,
    Code: "28072",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "4909",
    Active: -1,
  },
  {
    ID: 9142,
    Code: "28075",
    Name: "Thị trấn Tân Trụ",
    LevelUp: "Thị trấn",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9143,
    Code: "28078",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9144,
    Code: "28084",
    Name: "Xã Quê Mỹ Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9145,
    Code: "28087",
    Name: "Xã Lạc Tấn",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9146,
    Code: "28090",
    Name: "Xã Bình Trinh Đông",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9147,
    Code: "28093",
    Name: "Xã Tân Phước Tây",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9148,
    Code: "28096",
    Name: "Xã Bình Lãng",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9149,
    Code: "28099",
    Name: "Xã Bình Tịnh",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9150,
    Code: "28102",
    Name: "Xã Đức Tân",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9151,
    Code: "28105",
    Name: "Xã Nhựt Ninh",
    LevelUp: "Xã",
    DistrictCode: "4911",
    Active: -1,
  },
  {
    ID: 9152,
    Code: "28108",
    Name: "Thị trấn Cần Đước",
    LevelUp: "Thị trấn",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9153,
    Code: "28111",
    Name: "Xã Long Trạch",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9154,
    Code: "28114",
    Name: "Xã Long Khê",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9155,
    Code: "28117",
    Name: "Xã Long Định",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9156,
    Code: "28120",
    Name: "Xã Phước Vân",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9157,
    Code: "28123",
    Name: "Xã Long Hòa",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9158,
    Code: "28126",
    Name: "Xã Long Cang",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9159,
    Code: "28129",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9160,
    Code: "28132",
    Name: "Xã Tân Trạch",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9161,
    Code: "28135",
    Name: "Xã Mỹ Lệ",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9162,
    Code: "28138",
    Name: "Xã Tân Lân",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9163,
    Code: "28141",
    Name: "Xã Phước Tuy",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9164,
    Code: "28144",
    Name: "Xã Long Hựu Đông",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9165,
    Code: "28147",
    Name: "Xã Tân Ân",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9166,
    Code: "28150",
    Name: "Xã Phước Đông",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9167,
    Code: "28153",
    Name: "Xã Long Hựu Tây",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9168,
    Code: "28156",
    Name: "Xã Tân Chánh",
    LevelUp: "Xã",
    DistrictCode: "4912",
    Active: -1,
  },
  {
    ID: 9169,
    Code: "28159",
    Name: "Thị trấn Cần Giuộc",
    LevelUp: "Thị trấn",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9170,
    Code: "28162",
    Name: "Xã Phước Lý",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9171,
    Code: "28165",
    Name: "Xã Long Thượng",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9172,
    Code: "28168",
    Name: "Xã Long Hậu",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9173,
    Code: "28174",
    Name: "Xã Phước Hậu",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9174,
    Code: "28177",
    Name: "Xã Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9175,
    Code: "28180",
    Name: "Xã Phước Lại",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9176,
    Code: "28183",
    Name: "Xã Phước Lâm",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9177,
    Code: "28189",
    Name: "Xã Thuận Thành",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9178,
    Code: "28192",
    Name: "Xã Phước Vĩnh Tây",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9179,
    Code: "28195",
    Name: "Xã Phước Vĩnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9180,
    Code: "28198",
    Name: "Xã Long An",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9181,
    Code: "28201",
    Name: "Xã Long Phụng",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9182,
    Code: "28204",
    Name: "Xã Đông Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9183,
    Code: "28207",
    Name: "Xã Tân Tập",
    LevelUp: "Xã",
    DistrictCode: "4913",
    Active: -1,
  },
  {
    ID: 9184,
    Code: "28210",
    Name: "Thị trấn Tầm Vu",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9185,
    Code: "28213",
    Name: "Xã Bình Quới",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9186,
    Code: "28216",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9187,
    Code: "28219",
    Name: "Xã Phú Ngãi Trị",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9188,
    Code: "28222",
    Name: "Xã Vĩnh Công",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9189,
    Code: "28225",
    Name: "Xã Thuận Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9190,
    Code: "28228",
    Name: "Xã Hiệp Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9191,
    Code: "28231",
    Name: "Xã Phước Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9192,
    Code: "28234",
    Name: "Xã Thanh Phú Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9193,
    Code: "28237",
    Name: "Xã Dương Xuân Hội",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9194,
    Code: "28240",
    Name: "Xã An Lục Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9195,
    Code: "28243",
    Name: "Xã Long Trì",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9196,
    Code: "28246",
    Name: "Xã Thanh Vĩnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9197,
    Code: "28249",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9198,
    Code: "28252",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9199,
    Code: "28255",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9200,
    Code: "28258",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9201,
    Code: "28261",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9202,
    Code: "28264",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9203,
    Code: "28267",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9204,
    Code: "28270",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9205,
    Code: "28273",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9206,
    Code: "28276",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9207,
    Code: "28279",
    Name: "Phường Tân Long",
    LevelUp: "Phường",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9208,
    Code: "28282",
    Name: "Xã Đạo Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9209,
    Code: "28285",
    Name: "Xã Trung An",
    LevelUp: "Xã",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9210,
    Code: "28288",
    Name: "Xã Mỹ Phong",
    LevelUp: "Xã",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9211,
    Code: "28291",
    Name: "Xã Tân Mỹ Chánh",
    LevelUp: "Xã",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9212,
    Code: "28567",
    Name: "Xã Phước Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9213,
    Code: "28591",
    Name: "Xã Thới Sơn",
    LevelUp: "Xã",
    DistrictCode: "5301",
    Active: -1,
  },
  {
    ID: 9214,
    Code: "28294",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9215,
    Code: "28297",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9216,
    Code: "28300",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9217,
    Code: "28303",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9218,
    Code: "28306",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9219,
    Code: "28309",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9220,
    Code: "28312",
    Name: "Xã Long Thuận",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9221,
    Code: "28315",
    Name: "Xã Long Chánh",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9222,
    Code: "28318",
    Name: "Xã Long Hòa",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9223,
    Code: "28708",
    Name: "Xã Bình Đông",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9224,
    Code: "28717",
    Name: "Xã Bình Xuân",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9225,
    Code: "28729",
    Name: "Xã Tân Trung",
    LevelUp: "Xã",
    DistrictCode: "5302",
    Active: -1,
  },
  {
    ID: 9226,
    Code: "28435",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9227,
    Code: "28436",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9228,
    Code: "28437",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9229,
    Code: "28439",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9230,
    Code: "28440",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9231,
    Code: "28447",
    Name: "Xã Mỹ Phước Tây",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9232,
    Code: "28450",
    Name: "Xã Mỹ Hạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9233,
    Code: "28453",
    Name: "Xã Mỹ Hạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9234,
    Code: "28459",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9235,
    Code: "28462",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9236,
    Code: "28468",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9237,
    Code: "28474",
    Name: "Phường Nhị Mỹ",
    LevelUp: "Phường",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9238,
    Code: "28477",
    Name: "Xã Nhị Quý",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9239,
    Code: "28480",
    Name: "Xã Thanh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9240,
    Code: "28483",
    Name: "Xã Phú Quý",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9241,
    Code: "28486",
    Name: "Xã Long Khánh",
    LevelUp: "Xã",
    DistrictCode: "5311",
    Active: -1,
  },
  {
    ID: 9242,
    Code: "28321",
    Name: "Thị trấn Mỹ Phước",
    LevelUp: "Thị trấn",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9243,
    Code: "28324",
    Name: "Xã Tân Hòa Đông",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9244,
    Code: "28327",
    Name: "Xã Thạnh Tân",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9245,
    Code: "28330",
    Name: "Xã Thạnh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9246,
    Code: "28333",
    Name: "Xã Thạnh Hoà",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9247,
    Code: "28336",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9248,
    Code: "28339",
    Name: "Xã Tân Hòa Thành",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9249,
    Code: "28342",
    Name: "Xã Hưng Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9250,
    Code: "28345",
    Name: "Xã Tân Lập 1",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9251,
    Code: "28348",
    Name: "Xã Tân Hòa Tây",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9252,
    Code: "28354",
    Name: "Xã Tân Lập 2",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9253,
    Code: "28357",
    Name: "Xã Phước Lập",
    LevelUp: "Xã",
    DistrictCode: "5309",
    Active: -1,
  },
  {
    ID: 9254,
    Code: "28360",
    Name: "Thị trấn Cái Bè",
    LevelUp: "Thị trấn",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9255,
    Code: "28363",
    Name: "Xã Hậu Mỹ Bắc B",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9256,
    Code: "28366",
    Name: "Xã Hậu Mỹ Bắc A",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9257,
    Code: "28369",
    Name: "Xã Mỹ Trung",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9258,
    Code: "28372",
    Name: "Xã Hậu Mỹ Trinh",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9259,
    Code: "28375",
    Name: "Xã Hậu Mỹ Phú",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9260,
    Code: "28378",
    Name: "Xã Mỹ Tân",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9261,
    Code: "28381",
    Name: "Xã Mỹ Lợi B",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9262,
    Code: "28384",
    Name: "Xã Thiện Trung",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9263,
    Code: "28387",
    Name: "Xã Mỹ Hội",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9264,
    Code: "28390",
    Name: "Xã An Cư",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9265,
    Code: "28393",
    Name: "Xã Hậu Thành",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9266,
    Code: "28396",
    Name: "Xã Mỹ Lợi A",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9267,
    Code: "28399",
    Name: "Xã Hòa Khánh",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9268,
    Code: "28402",
    Name: "Xã Thiện Trí",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9269,
    Code: "28405",
    Name: "Xã Mỹ Đức Đông",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9270,
    Code: "28408",
    Name: "Xã Mỹ Đức Tây",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9271,
    Code: "28411",
    Name: "Xã Đông Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9272,
    Code: "28414",
    Name: "Xã An Thái Đông",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9273,
    Code: "28417",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9274,
    Code: "28420",
    Name: "Xã Mỹ Lương",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9275,
    Code: "28423",
    Name: "Xã Tân Thanh",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9276,
    Code: "28426",
    Name: "Xã An Thái Trung",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9277,
    Code: "28429",
    Name: "Xã An Hữu",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9278,
    Code: "28432",
    Name: "Xã Hòa Hưng",
    LevelUp: "Xã",
    DistrictCode: "5303",
    Active: -1,
  },
  {
    ID: 9279,
    Code: "28438",
    Name: "Xã Thạnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9280,
    Code: "28441",
    Name: "Xã Mỹ Thành Bắc",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9281,
    Code: "28444",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9282,
    Code: "28456",
    Name: "Xã Mỹ Thành Nam",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9283,
    Code: "28465",
    Name: "Xã Phú Nhuận",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9284,
    Code: "28471",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9285,
    Code: "28489",
    Name: "Xã Cẩm Sơn",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9286,
    Code: "28492",
    Name: "Xã Phú An",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9287,
    Code: "28495",
    Name: "Xã Mỹ Long",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9288,
    Code: "28498",
    Name: "Xã Long Tiên",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9289,
    Code: "28501",
    Name: "Xã Hiệp Đức",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9290,
    Code: "28504",
    Name: "Xã Long Trung",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9291,
    Code: "28507",
    Name: "Xã Hội Xuân",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9292,
    Code: "28510",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9293,
    Code: "28513",
    Name: "Xã Tam Bình",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9294,
    Code: "28516",
    Name: "Xã Ngũ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5304",
    Active: -1,
  },
  {
    ID: 9295,
    Code: "28519",
    Name: "Thị trấn Tân Hiệp",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9296,
    Code: "28522",
    Name: "Xã Tân Hội Đông",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9297,
    Code: "28525",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9298,
    Code: "28528",
    Name: "Xã Tân Lý Đông",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9299,
    Code: "28531",
    Name: "Xã Tân Lý Tây",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9300,
    Code: "28534",
    Name: "Xã Thân Cửu Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9301,
    Code: "28537",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9302,
    Code: "28540",
    Name: "Xã Điềm Hy",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9303,
    Code: "28543",
    Name: "Xã Nhị Bình",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9304,
    Code: "28546",
    Name: "Xã Dưỡng Điềm",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9305,
    Code: "28549",
    Name: "Xã Đông Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9306,
    Code: "28552",
    Name: "Xã Long Định",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9307,
    Code: "28555",
    Name: "Xã Hữu Đạo",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9308,
    Code: "28558",
    Name: "Xã Long An",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9309,
    Code: "28561",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9310,
    Code: "28564",
    Name: "Xã Bình Trưng",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9311,
    Code: "28570",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9312,
    Code: "28573",
    Name: "Xã Bàn Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9313,
    Code: "28576",
    Name: "Xã Vĩnh Kim",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9314,
    Code: "28579",
    Name: "Xã Bình Đức",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9315,
    Code: "28582",
    Name: "Xã Song Thuận",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9316,
    Code: "28585",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9317,
    Code: "28588",
    Name: "Xã Phú Phong",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9318,
    Code: "28594",
    Name: "Thị trấn Chợ Gạo",
    LevelUp: "Thị trấn",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9319,
    Code: "28597",
    Name: "Xã Trung Hòa",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9320,
    Code: "28600",
    Name: "Xã Hòa Tịnh",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9321,
    Code: "28603",
    Name: "Xã Mỹ Tịnh An",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9322,
    Code: "28606",
    Name: "Xã Tân Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9323,
    Code: "28609",
    Name: "Xã Phú Kiết",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9324,
    Code: "28612",
    Name: "Xã Lương Hòa Lạc",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9325,
    Code: "28615",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9326,
    Code: "28618",
    Name: "Xã Quơn Long",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9327,
    Code: "28621",
    Name: "Xã Bình Phục Nhứt",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9328,
    Code: "28624",
    Name: "Xã Đăng Hưng Phước",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9329,
    Code: "28627",
    Name: "Xã Tân Thuận Bình",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9330,
    Code: "28630",
    Name: "Xã Song Bình",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9331,
    Code: "28633",
    Name: "Xã Bình Phan",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9332,
    Code: "28636",
    Name: "Xã Long Bình Điền",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9333,
    Code: "28639",
    Name: "Xã An Thạnh Thủy",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9334,
    Code: "28642",
    Name: "Xã Xuân Đông",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9335,
    Code: "28645",
    Name: "Xã Hòa Định",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9336,
    Code: "28648",
    Name: "Xã Bình Ninh",
    LevelUp: "Xã",
    DistrictCode: "5306",
    Active: -1,
  },
  {
    ID: 9337,
    Code: "28651",
    Name: "Thị trấn Vĩnh Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9338,
    Code: "28654",
    Name: "Xã Đồng Sơn",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9339,
    Code: "28657",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9340,
    Code: "28660",
    Name: "Xã Đồng Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9341,
    Code: "28663",
    Name: "Xã Thành Công",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9342,
    Code: "28666",
    Name: "Xã Bình Nhì",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9343,
    Code: "28669",
    Name: "Xã Yên Luông",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9344,
    Code: "28672",
    Name: "Xã Thạnh Trị",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9345,
    Code: "28675",
    Name: "Xã Thạnh Nhựt",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9346,
    Code: "28678",
    Name: "Xã Long Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9347,
    Code: "28681",
    Name: "Xã Bình Tân",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9348,
    Code: "28684",
    Name: "Xã Vĩnh Hựu",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9349,
    Code: "28687",
    Name: "Xã Long Bình",
    LevelUp: "Xã",
    DistrictCode: "5307",
    Active: -1,
  },
  {
    ID: 9350,
    Code: "28702",
    Name: "Thị trấn Tân Hòa",
    LevelUp: "Thị trấn",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9351,
    Code: "28705",
    Name: "Xã Tăng Hoà",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9352,
    Code: "28711",
    Name: "Xã Tân Phước",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9353,
    Code: "28714",
    Name: "Xã Gia Thuận",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9354,
    Code: "28720",
    Name: "Thị trấn Vàm Láng",
    LevelUp: "Thị trấn",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9355,
    Code: "28723",
    Name: "Xã Tân Tây",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9356,
    Code: "28726",
    Name: "Xã Kiểng Phước",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9357,
    Code: "28732",
    Name: "Xã Tân Đông",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9358,
    Code: "28735",
    Name: "Xã Bình Ân",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9359,
    Code: "28738",
    Name: "Xã Tân Điền",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9360,
    Code: "28741",
    Name: "Xã Bình Nghị",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9361,
    Code: "28744",
    Name: "Xã Phước Trung",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9362,
    Code: "28747",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "5308",
    Active: -1,
  },
  {
    ID: 9363,
    Code: "28690",
    Name: "Xã Tân Thới",
    LevelUp: "Xã",
    DistrictCode: "5310",
    Active: -1,
  },
  {
    ID: 9364,
    Code: "28693",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5310",
    Active: -1,
  },
  {
    ID: 9365,
    Code: "28696",
    Name: "Xã Phú Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5310",
    Active: -1,
  },
  {
    ID: 9366,
    Code: "28699",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5310",
    Active: -1,
  },
  {
    ID: 9367,
    Code: "28750",
    Name: "Xã Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "5310",
    Active: -1,
  },
  {
    ID: 9368,
    Code: "28753",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "5310",
    Active: -1,
  },
  {
    ID: 9369,
    Code: "28756",
    Name: "Phường Phú Khương",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9370,
    Code: "28757",
    Name: "Phường Phú Tân",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9371,
    Code: "28759",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9372,
    Code: "28762",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9373,
    Code: "28765",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9374,
    Code: "28768",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9375,
    Code: "28777",
    Name: "Phường An Hội",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9376,
    Code: "28780",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9377,
    Code: "28783",
    Name: "Xã Sơn Đông",
    LevelUp: "Xã",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9378,
    Code: "28786",
    Name: "Xã Phú Hưng",
    LevelUp: "Xã",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9379,
    Code: "28789",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9380,
    Code: "28792",
    Name: "Xã Mỹ Thạnh An",
    LevelUp: "Xã",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9381,
    Code: "28795",
    Name: "Xã Nhơn Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9382,
    Code: "28798",
    Name: "Xã Phú Nhuận",
    LevelUp: "Xã",
    DistrictCode: "5601",
    Active: -1,
  },
  {
    ID: 9383,
    Code: "28801",
    Name: "Thị trấn Châu Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9384,
    Code: "28804",
    Name: "Xã Tân Thạch",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9385,
    Code: "28807",
    Name: "Xã Qưới Sơn",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9386,
    Code: "28810",
    Name: "Xã An Khánh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9387,
    Code: "28813",
    Name: "Xã Giao Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9388,
    Code: "28819",
    Name: "Xã Phú Túc",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9389,
    Code: "28822",
    Name: "Xã Phú Đức",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9390,
    Code: "28825",
    Name: "Xã Phú An Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9391,
    Code: "28828",
    Name: "Xã An Phước",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9392,
    Code: "28831",
    Name: "Xã Tam Phước",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9393,
    Code: "28834",
    Name: "Xã Thành Triệu",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9394,
    Code: "28837",
    Name: "Xã Tường Đa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9395,
    Code: "28840",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9396,
    Code: "28843",
    Name: "Xã Quới Thành",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9397,
    Code: "28846",
    Name: "Xã Phước Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9398,
    Code: "28849",
    Name: "Xã An Hóa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9399,
    Code: "28852",
    Name: "Xã Tiên Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9400,
    Code: "28855",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9401,
    Code: "28858",
    Name: "Xã Hữu Định",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9402,
    Code: "28861",
    Name: "Xã Tiên Thủy",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9403,
    Code: "28864",
    Name: "Xã Sơn Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9404,
    Code: "28870",
    Name: "Thị trấn Chợ Lách",
    LevelUp: "Thị trấn",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9405,
    Code: "28873",
    Name: "Xã Phú Phụng",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9406,
    Code: "28876",
    Name: "Xã Sơn Định",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9407,
    Code: "28879",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9408,
    Code: "28882",
    Name: "Xã Hòa Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9409,
    Code: "28885",
    Name: "Xã Long Thới",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9410,
    Code: "28888",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9411,
    Code: "28891",
    Name: "Xã Tân Thiềng",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9412,
    Code: "28894",
    Name: "Xã Vĩnh Thành",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9413,
    Code: "28897",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9414,
    Code: "28900",
    Name: "Xã Hưng Khánh Trung B",
    LevelUp: "Xã",
    DistrictCode: "5603",
    Active: -1,
  },
  {
    ID: 9415,
    Code: "28903",
    Name: "Thị trấn Mỏ Cày",
    LevelUp: "Thị trấn",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9416,
    Code: "28930",
    Name: "Xã Định Thủy",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9417,
    Code: "28939",
    Name: "Xã Đa Phước Hội",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9418,
    Code: "28940",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9419,
    Code: "28942",
    Name: "Xã Phước Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9420,
    Code: "28945",
    Name: "Xã Bình Khánh ",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9421,
    Code: "28951",
    Name: "Xã An Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9422,
    Code: "28957",
    Name: "Xã An Định",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9423,
    Code: "28960",
    Name: "Xã Thành Thới B",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9424,
    Code: "28963",
    Name: "Xã Tân Trung",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9425,
    Code: "28966",
    Name: "Xã An Thới",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9426,
    Code: "28969",
    Name: "Xã Thành Thới A",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9427,
    Code: "28972",
    Name: "Xã Minh Đức",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9428,
    Code: "28975",
    Name: "Xã Ngãi Đăng",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9429,
    Code: "28978",
    Name: "Xã Cẩm Sơn",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9430,
    Code: "28981",
    Name: "Xã Hương Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5609",
    Active: -1,
  },
  {
    ID: 9431,
    Code: "28984",
    Name: "Thị trấn Giồng Trôm",
    LevelUp: "Thị trấn",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9432,
    Code: "28987",
    Name: "Xã Phong Nẫm",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9433,
    Code: "28993",
    Name: "Xã Mỹ Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9434,
    Code: "28996",
    Name: "Xã Châu Hòa",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9435,
    Code: "28999",
    Name: "Xã Lương Hòa",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9436,
    Code: "29002",
    Name: "Xã Lương Quới",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9437,
    Code: "29005",
    Name: "Xã Lương Phú",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9438,
    Code: "29008",
    Name: "Xã Châu Bình",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9439,
    Code: "29011",
    Name: "Xã Thuận Điền",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9440,
    Code: "29014",
    Name: "Xã Sơn Phú",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9441,
    Code: "29017",
    Name: "Xã Bình Hoà",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9442,
    Code: "29020",
    Name: "Xã Phước Long",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9443,
    Code: "29023",
    Name: "Xã Hưng Phong",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9444,
    Code: "29026",
    Name: "Xã Long Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9445,
    Code: "29029",
    Name: "Xã Tân Hào",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9446,
    Code: "29032",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9447,
    Code: "29035",
    Name: "Xã Tân Thanh",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9448,
    Code: "29038",
    Name: "Xã Tân Lợi Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9449,
    Code: "29041",
    Name: "Xã Thạnh Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9450,
    Code: "29044",
    Name: "Xã Hưng Nhượng",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9451,
    Code: "29047",
    Name: "Xã Hưng Lễ",
    LevelUp: "Xã",
    DistrictCode: "5605",
    Active: -1,
  },
  {
    ID: 9452,
    Code: "29050",
    Name: "Thị trấn Bình Đại",
    LevelUp: "Thị trấn",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9453,
    Code: "29053",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9454,
    Code: "29056",
    Name: "Xã Long Định",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9455,
    Code: "29059",
    Name: "Xã Long Hòa",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9456,
    Code: "29062",
    Name: "Xã Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9457,
    Code: "29065",
    Name: "Xã Vang Quới Tây",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9458,
    Code: "29068",
    Name: "Xã Vang Quới Đông",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9459,
    Code: "29071",
    Name: "Xã Châu Hưng",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9460,
    Code: "29074",
    Name: "Xã Phú Vang",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9461,
    Code: "29077",
    Name: "Xã Lộc Thuận",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9462,
    Code: "29080",
    Name: "Xã Định Trung",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9463,
    Code: "29083",
    Name: "Xã Thới Lai",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9464,
    Code: "29086",
    Name: "Xã Bình Thới",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9465,
    Code: "29089",
    Name: "Xã Phú Long",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9466,
    Code: "29092",
    Name: "Xã Bình Thắng",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9467,
    Code: "29095",
    Name: "Xã Thạnh Trị",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9468,
    Code: "29098",
    Name: "Xã Đại Hòa Lộc",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9469,
    Code: "29101",
    Name: "Xã Thừa Đức",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9470,
    Code: "29104",
    Name: "Xã Thạnh Phước",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9471,
    Code: "29107",
    Name: "Xã Thới Thuận",
    LevelUp: "Xã",
    DistrictCode: "5606",
    Active: -1,
  },
  {
    ID: 9472,
    Code: "29110",
    Name: "Thị trấn Ba Tri",
    LevelUp: "Thị trấn",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9473,
    Code: "29113",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9474,
    Code: "29116",
    Name: "Xã Mỹ Hòa",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9475,
    Code: "29119",
    Name: "Xã Tân Xuân",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9476,
    Code: "29122",
    Name: "Xã Mỹ Chánh",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9477,
    Code: "29125",
    Name: "Xã Bảo Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9478,
    Code: "29128",
    Name: "Xã An Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9479,
    Code: "29131",
    Name: "Xã Mỹ Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9480,
    Code: "29134",
    Name: "Xã Mỹ Nhơn",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9481,
    Code: "29137",
    Name: "Xã Phước Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9482,
    Code: "29143",
    Name: "Xã An Ngãi Trung",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9483,
    Code: "29146",
    Name: "Xã Phú Lễ",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9484,
    Code: "29149",
    Name: "Xã An Bình Tây",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9485,
    Code: "29152",
    Name: "Xã Bảo Thuận",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9486,
    Code: "29155",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9487,
    Code: "29158",
    Name: "Xã An Ngãi Tây",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9488,
    Code: "29161",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9489,
    Code: "29164",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9490,
    Code: "29167",
    Name: "Xã Tân Thủy",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9491,
    Code: "29170",
    Name: "Xã Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9492,
    Code: "29173",
    Name: "Xã An Đức",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9493,
    Code: "29176",
    Name: "Xã An Hòa Tây",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9494,
    Code: "29179",
    Name: "Xã An Thủy",
    LevelUp: "Xã",
    DistrictCode: "5607",
    Active: -1,
  },
  {
    ID: 9495,
    Code: "29182",
    Name: "Thị trấn Thạnh Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9496,
    Code: "29185",
    Name: "Xã Phú Khánh",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9497,
    Code: "29188",
    Name: "Xã Đại Điền",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9498,
    Code: "29191",
    Name: "Xã Quới Điền",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9499,
    Code: "29194",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9500,
    Code: "29197",
    Name: "Xã Mỹ Hưng",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9501,
    Code: "29200",
    Name: "Xã An Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9502,
    Code: "29203",
    Name: "Xã Thới Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9503,
    Code: "29206",
    Name: "Xã Hòa Lợi",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9504,
    Code: "29209",
    Name: "Xã An Điền",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9505,
    Code: "29212",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9506,
    Code: "29215",
    Name: "Xã An Thuận",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9507,
    Code: "29218",
    Name: "Xã An Quy",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9508,
    Code: "29221",
    Name: "Xã Thạnh Hải",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9509,
    Code: "29224",
    Name: "Xã An Nhơn",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9510,
    Code: "29227",
    Name: "Xã Giao Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9511,
    Code: "29230",
    Name: "Xã Thạnh Phong",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9512,
    Code: "29233",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "5608",
    Active: -1,
  },
  {
    ID: 9513,
    Code: "28889",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9514,
    Code: "28901",
    Name: "Xã Hưng Khánh Trung A",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9515,
    Code: "28906",
    Name: "Xã Thanh Tân",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9516,
    Code: "28909",
    Name: "Xã Thạnh Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9517,
    Code: "28912",
    Name: "Xã Tân Phú Tây",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9518,
    Code: "28915",
    Name: "Xã Phước Mỹ Trung",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9519,
    Code: "28918",
    Name: "Xã Tân Thành Bình",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9520,
    Code: "28921",
    Name: "Xã Thành An",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9521,
    Code: "28924",
    Name: "Xã Hòa Lộc",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9522,
    Code: "28927",
    Name: "Xã Tân Thanh Tây",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9523,
    Code: "28933",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9524,
    Code: "28936",
    Name: "Xã Nhuận Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9525,
    Code: "28948",
    Name: "Xã Khánh Thạnh Tân",
    LevelUp: "Xã",
    DistrictCode: "5604",
    Active: -1,
  },
  {
    ID: 9526,
    Code: "29236",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9527,
    Code: "29239",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9528,
    Code: "29242",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9529,
    Code: "29245",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9530,
    Code: "29248",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9531,
    Code: "29251",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9532,
    Code: "29254",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9533,
    Code: "29257",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9534,
    Code: "29260",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9535,
    Code: "29263",
    Name: "Xã Long Đức",
    LevelUp: "Xã",
    DistrictCode: "5801",
    Active: -1,
  },
  {
    ID: 9536,
    Code: "29266",
    Name: "Thị trấn Càng Long",
    LevelUp: "Thị trấn",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9537,
    Code: "29269",
    Name: "Xã Mỹ Cẩm",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9538,
    Code: "29272",
    Name: "Xã An Trường A",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9539,
    Code: "29275",
    Name: "Xã An Trường",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9540,
    Code: "29278",
    Name: "Xã Huyền Hội",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9541,
    Code: "29281",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9542,
    Code: "29284",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9543,
    Code: "29287",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9544,
    Code: "29290",
    Name: "Xã Phương Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9545,
    Code: "29293",
    Name: "Xã Đại Phúc",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9546,
    Code: "29296",
    Name: "Xã Đại Phước",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9547,
    Code: "29299",
    Name: "Xã Nhị Long Phú",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9548,
    Code: "29302",
    Name: "Xã Nhị Long",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9549,
    Code: "29305",
    Name: "Xã Đức Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5802",
    Active: -1,
  },
  {
    ID: 9550,
    Code: "29308",
    Name: "Thị trấn Cầu Kè",
    LevelUp: "Thị trấn",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9551,
    Code: "29311",
    Name: "Xã Hòa Ân",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9552,
    Code: "29314",
    Name: "Xã Châu Điền",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9553,
    Code: "29317",
    Name: "Xã An Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9554,
    Code: "29320",
    Name: "Xã Hoà Tân",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9555,
    Code: "29323",
    Name: "Xã Ninh Thới",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9556,
    Code: "29326",
    Name: "Xã Phong Phú",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9557,
    Code: "29329",
    Name: "Xã Phong Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9558,
    Code: "29332",
    Name: "Xã Tam Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9559,
    Code: "29335",
    Name: "Xã Thông Hòa",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9560,
    Code: "29338",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5803",
    Active: -1,
  },
  {
    ID: 9561,
    Code: "29341",
    Name: "Thị trấn Tiểu Cần",
    LevelUp: "Thị trấn",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9562,
    Code: "29344",
    Name: "Thị trấn Cầu Quan",
    LevelUp: "Thị trấn",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9563,
    Code: "29347",
    Name: "Xã Phú Cần",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9564,
    Code: "29350",
    Name: "Xã Hiếu Tử",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9565,
    Code: "29353",
    Name: "Xã Hiếu Trung",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9566,
    Code: "29356",
    Name: "Xã Long Thới",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9567,
    Code: "29359",
    Name: "Xã Hùng Hòa",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9568,
    Code: "29362",
    Name: "Xã Tân Hùng",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9569,
    Code: "29365",
    Name: "Xã Tập Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9570,
    Code: "29368",
    Name: "Xã Ngãi Hùng",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9571,
    Code: "29371",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "5804",
    Active: -1,
  },
  {
    ID: 9572,
    Code: "29374",
    Name: "Thị trấn Châu Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9573,
    Code: "29377",
    Name: "Xã Đa Lộc",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9574,
    Code: "29380",
    Name: "Xã Mỹ Chánh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9575,
    Code: "29383",
    Name: "Xã Thanh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9576,
    Code: "29386",
    Name: "Xã Lương Hoà A",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9577,
    Code: "29389",
    Name: "Xã Lương Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9578,
    Code: "29392",
    Name: "Xã Song Lộc",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9579,
    Code: "29395",
    Name: "Xã Nguyệt Hóa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9580,
    Code: "29398",
    Name: "Xã Hòa Thuận",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9581,
    Code: "29401",
    Name: "Xã Hòa Lợi",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9582,
    Code: "29404",
    Name: "Xã Phước Hảo",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9583,
    Code: "29407",
    Name: "Xã Hưng Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9584,
    Code: "29410",
    Name: "Xã Hòa Minh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9585,
    Code: "29413",
    Name: "Xã Long Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9586,
    Code: "29416",
    Name: "Thị trấn Cầu Ngang",
    LevelUp: "Thị trấn",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9587,
    Code: "29419",
    Name: "Thị trấn Mỹ Long",
    LevelUp: "Thị trấn",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9588,
    Code: "29422",
    Name: "Xã Mỹ Long Bắc",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9589,
    Code: "29425",
    Name: "Xã Mỹ Long Nam",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9590,
    Code: "29428",
    Name: "Xã Mỹ Hòa",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9591,
    Code: "29431",
    Name: "Xã Vĩnh Kim",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9592,
    Code: "29434",
    Name: "Xã Kim Hòa",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9593,
    Code: "29437",
    Name: "Xã Hiệp Hòa",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9594,
    Code: "29440",
    Name: "Xã Thuận Hòa",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9595,
    Code: "29443",
    Name: "Xã Long Sơn",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9596,
    Code: "29446",
    Name: "Xã Nhị Trường",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9597,
    Code: "29449",
    Name: "Xã Trường Thọ",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9598,
    Code: "29452",
    Name: "Xã Hiệp Mỹ Đông",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9599,
    Code: "29455",
    Name: "Xã Hiệp Mỹ Tây",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9600,
    Code: "29458",
    Name: "Xã Thạnh Hòa Sơn",
    LevelUp: "Xã",
    DistrictCode: "5807",
    Active: -1,
  },
  {
    ID: 9601,
    Code: "29461",
    Name: "Thị trấn Trà Cú",
    LevelUp: "Thị trấn",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9602,
    Code: "29462",
    Name: "Thị trấn Định An",
    LevelUp: "Thị trấn",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9603,
    Code: "29464",
    Name: "Xã Phước Hưng",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9604,
    Code: "29467",
    Name: "Xã Tập Sơn",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9605,
    Code: "29470",
    Name: "Xã Tân Sơn",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9606,
    Code: "29473",
    Name: "Xã An Quảng Hữu",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9607,
    Code: "29476",
    Name: "Xã Lưu Nghiệp Anh",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9608,
    Code: "29479",
    Name: "Xã Ngãi Xuyên",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9609,
    Code: "29482",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9610,
    Code: "29485",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9611,
    Code: "29488",
    Name: "Xã Hàm Giang",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9612,
    Code: "29489",
    Name: "Xã Hàm Tân",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9613,
    Code: "29491",
    Name: "Xã Đại An",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9614,
    Code: "29494",
    Name: "Xã Định An",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9615,
    Code: "29503",
    Name: "Xã Ngọc Biên",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9616,
    Code: "29506",
    Name: "Xã Long Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9617,
    Code: "29509",
    Name: "Xã Tân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5806",
    Active: -1,
  },
  {
    ID: 9618,
    Code: "29497",
    Name: "Xã Đôn Xuân",
    LevelUp: "Xã",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9619,
    Code: "29500",
    Name: "Xã Đôn Châu",
    LevelUp: "Xã",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9620,
    Code: "29513",
    Name: "Thị trấn Long Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9621,
    Code: "29521",
    Name: "Xã Long Khánh",
    LevelUp: "Xã",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9622,
    Code: "29530",
    Name: "Xã Ngũ Lạc",
    LevelUp: "Xã",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9623,
    Code: "29533",
    Name: "Xã Long Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9624,
    Code: "29536",
    Name: "Xã Đông Hải",
    LevelUp: "Xã",
    DistrictCode: "5808",
    Active: -1,
  },
  {
    ID: 9625,
    Code: "29512",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9626,
    Code: "29515",
    Name: "Xã Long Toàn",
    LevelUp: "Xã",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9627,
    Code: "29516",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9628,
    Code: "29518",
    Name: "Xã Long Hữu",
    LevelUp: "Xã",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9629,
    Code: "29524",
    Name: "Xã Dân Thành",
    LevelUp: "Xã",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9630,
    Code: "29527",
    Name: "Xã Trường Long Hòa",
    LevelUp: "Xã",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9631,
    Code: "29539",
    Name: "Xã Hiệp Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 9632,
    Code: "29542",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9633,
    Code: "29545",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9634,
    Code: "29548",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9635,
    Code: "29551",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9636,
    Code: "29554",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9637,
    Code: "29557",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9638,
    Code: "29560",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9639,
    Code: "29563",
    Name: "Phường Tân Ngãi",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9640,
    Code: "29566",
    Name: "Phường Tân Hòa",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9641,
    Code: "29569",
    Name: "Phường Tân Hội",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9642,
    Code: "29572",
    Name: "Phường Trường An",
    LevelUp: "Phường",
    DistrictCode: "5701",
    Active: -1,
  },
  {
    ID: 9643,
    Code: "29575",
    Name: "Thị trấn Long Hồ",
    LevelUp: "Thị trấn",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9644,
    Code: "29578",
    Name: "Xã Đồng Phú",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9645,
    Code: "29581",
    Name: "Xã Bình Hòa Phước",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9646,
    Code: "29584",
    Name: "Xã Hòa Ninh",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9647,
    Code: "29587",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9648,
    Code: "29590",
    Name: "Xã Thanh Đức",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9649,
    Code: "29593",
    Name: "Xã Tân Hạnh",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9650,
    Code: "29596",
    Name: "Xã Phước Hậu",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9651,
    Code: "29599",
    Name: "Xã Long Phước",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9652,
    Code: "29602",
    Name: "Xã Phú Đức",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9653,
    Code: "29605",
    Name: "Xã Lộc Hòa",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9654,
    Code: "29608",
    Name: "Xã Long An",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9655,
    Code: "29611",
    Name: "Xã Phú Quới",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9656,
    Code: "29614",
    Name: "Xã Thạnh Quới",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9657,
    Code: "29617",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "5702",
    Active: -1,
  },
  {
    ID: 9658,
    Code: "29623",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9659,
    Code: "29626",
    Name: "Xã Mỹ Phước",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9660,
    Code: "29629",
    Name: "Xã An Phước",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9661,
    Code: "29632",
    Name: "Xã Nhơn Phú",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9662,
    Code: "29635",
    Name: "Xã Long Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9663,
    Code: "29638",
    Name: "Xã Hòa Tịnh",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9664,
    Code: "29641",
    Name: "Thị trấn Cái Nhum",
    LevelUp: "Thị trấn",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9665,
    Code: "29644",
    Name: "Xã Bình Phước",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9666,
    Code: "29647",
    Name: "Xã Chánh An",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9667,
    Code: "29650",
    Name: "Xã Tân An Hội",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9668,
    Code: "29653",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9669,
    Code: "29656",
    Name: "Xã Tân Long Hội",
    LevelUp: "Xã",
    DistrictCode: "5703",
    Active: -1,
  },
  {
    ID: 9670,
    Code: "29659",
    Name: "Thị trấn Vũng Liêm",
    LevelUp: "Thị trấn",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9671,
    Code: "29662",
    Name: "Xã Tân Quới Trung",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9672,
    Code: "29665",
    Name: "Xã Quới Thiện",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9673,
    Code: "29668",
    Name: "Xã Quới An",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9674,
    Code: "29671",
    Name: "Xã Trung Chánh",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9675,
    Code: "29674",
    Name: "Xã Tân An Luông",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9676,
    Code: "29677",
    Name: "Xã Thanh Bình",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9677,
    Code: "29680",
    Name: "Xã Trung Thành Tây",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9678,
    Code: "29683",
    Name: "Xã Trung Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9679,
    Code: "29686",
    Name: "Xã Hiếu Phụng",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9680,
    Code: "29689",
    Name: "Xã Trung Thành Đông",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9681,
    Code: "29692",
    Name: "Xã Trung Thành",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9682,
    Code: "29695",
    Name: "Xã Trung Hiếu",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9683,
    Code: "29698",
    Name: "Xã Trung Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9684,
    Code: "29701",
    Name: "Xã Hiếu Thuận",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9685,
    Code: "29704",
    Name: "Xã Trung Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9686,
    Code: "29707",
    Name: "Xã Trung An",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9687,
    Code: "29710",
    Name: "Xã Hiếu Nhơn",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9688,
    Code: "29713",
    Name: "Xã Hiếu Thành",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9689,
    Code: "29716",
    Name: "Xã Hiếu Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "5707",
    Active: -1,
  },
  {
    ID: 9690,
    Code: "29719",
    Name: "Thị trấn Tam Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9691,
    Code: "29722",
    Name: "Xã Tân Lộc",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9692,
    Code: "29725",
    Name: "Xã Phú Thịnh",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9693,
    Code: "29728",
    Name: "Xã Hậu Lộc",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9694,
    Code: "29731",
    Name: "Xã Hòa Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9695,
    Code: "29734",
    Name: "Xã Hoà Lộc",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9696,
    Code: "29737",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9697,
    Code: "29740",
    Name: "Xã Song Phú",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9698,
    Code: "29743",
    Name: "Xã Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9699,
    Code: "29746",
    Name: "Xã Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9700,
    Code: "29749",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9701,
    Code: "29752",
    Name: "Xã Long Phú",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9702,
    Code: "29755",
    Name: "Xã Mỹ Thạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9703,
    Code: "29758",
    Name: "Xã Tường Lộc",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9704,
    Code: "29761",
    Name: "Xã Loan Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9705,
    Code: "29764",
    Name: "Xã Ngãi Tứ",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9706,
    Code: "29767",
    Name: "Xã Bình Ninh",
    LevelUp: "Xã",
    DistrictCode: "5705",
    Active: -1,
  },
  {
    ID: 9707,
    Code: "29770",
    Name: "Phường Cái Vồn",
    LevelUp: "Phường",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9708,
    Code: "29771",
    Name: "Phường Thành Phước",
    LevelUp: "Phường",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9709,
    Code: "29806",
    Name: "Xã Thuận An",
    LevelUp: "Xã",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9710,
    Code: "29809",
    Name: "Xã Đông Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9711,
    Code: "29812",
    Name: "Xã Đông Bình",
    LevelUp: "Xã",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9712,
    Code: "29813",
    Name: "Phường Đông Thuận",
    LevelUp: "Phường",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9713,
    Code: "29815",
    Name: "Xã Mỹ Hòa",
    LevelUp: "Xã",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9714,
    Code: "29818",
    Name: "Xã Đông Thành",
    LevelUp: "Xã",
    DistrictCode: "5704",
    Active: -1,
  },
  {
    ID: 9715,
    Code: "29821",
    Name: "Thị trấn Trà Ôn",
    LevelUp: "Thị trấn",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9716,
    Code: "29824",
    Name: "Xã Xuân Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9717,
    Code: "29827",
    Name: "Xã Nhơn Bình",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9718,
    Code: "29830",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9719,
    Code: "29833",
    Name: "Xã Thới Hòa",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9720,
    Code: "29836",
    Name: "Xã Trà Côn",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9721,
    Code: "29839",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9722,
    Code: "29842",
    Name: "Xã Hựu Thành",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9723,
    Code: "29845",
    Name: "Xã Vĩnh Xuân",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9724,
    Code: "29848",
    Name: "Xã Thuận Thới",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9725,
    Code: "29851",
    Name: "Xã Phú Thành",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9726,
    Code: "29854",
    Name: "Xã Thiện Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9727,
    Code: "29857",
    Name: "Xã Lục Sỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9728,
    Code: "29860",
    Name: "Xã Tích Thiện",
    LevelUp: "Xã",
    DistrictCode: "5706",
    Active: -1,
  },
  {
    ID: 9729,
    Code: "29773",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9730,
    Code: "29776",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9731,
    Code: "29779",
    Name: "Xã Thành Trung",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9732,
    Code: "29782",
    Name: "Xã Tân An Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9733,
    Code: "29785",
    Name: "Xã Tân Lược",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9734,
    Code: "29788",
    Name: "Xã Nguyễn Văn Thảnh",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9735,
    Code: "29791",
    Name: "Xã Thành Lợi",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9736,
    Code: "29794",
    Name: "Xã Mỹ Thuận",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9737,
    Code: "29797",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9738,
    Code: "29800",
    Name: "Thị trấn Tân Quới",
    LevelUp: "Thị trấn",
    DistrictCode: "5708",
    Active: -1,
  },
  {
    ID: 9739,
    Code: "29863",
    Name: "Phường 11",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9740,
    Code: "29866",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9741,
    Code: "29869",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9742,
    Code: "29872",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9743,
    Code: "29875",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9744,
    Code: "29878",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9745,
    Code: "29881",
    Name: "Xã Mỹ Ngãi",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9746,
    Code: "29884",
    Name: "Xã Mỹ Tân",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9747,
    Code: "29887",
    Name: "Xã Mỹ Trà",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9748,
    Code: "29888",
    Name: "Phường Mỹ Phú",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9749,
    Code: "29890",
    Name: "Xã Tân Thuận Tây",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9750,
    Code: "29892",
    Name: "Phường Hoà Thuận",
    LevelUp: "Phường",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9751,
    Code: "29893",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9752,
    Code: "29896",
    Name: "Xã Tân Thuận Đông",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9753,
    Code: "29899",
    Name: "Xã Tịnh Thới",
    LevelUp: "Xã",
    DistrictCode: "5001",
    Active: -1,
  },
  {
    ID: 9754,
    Code: "29902",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9755,
    Code: "29905",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9756,
    Code: "29908",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9757,
    Code: "29911",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9758,
    Code: "29914",
    Name: "Xã Tân Khánh Đông",
    LevelUp: "Xã",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9759,
    Code: "29917",
    Name: "Phường Tân Quy Đông",
    LevelUp: "Phường",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9760,
    Code: "29919",
    Name: "Phường An Hoà",
    LevelUp: "Phường",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9761,
    Code: "29920",
    Name: "Xã Tân Quy Tây",
    LevelUp: "Xã",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9762,
    Code: "29923",
    Name: "Xã Tân Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "5002",
    Active: -1,
  },
  {
    ID: 9763,
    Code: "29954",
    Name: "Phường An Lộc",
    LevelUp: "Phường",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9764,
    Code: "29955",
    Name: "Phường An Thạnh",
    LevelUp: "Phường",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9765,
    Code: "29959",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9766,
    Code: "29965",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9767,
    Code: "29978",
    Name: "Phường An Lạc",
    LevelUp: "Phường",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9768,
    Code: "29986",
    Name: "Xã An Bình B",
    LevelUp: "Xã",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9769,
    Code: "29989",
    Name: "Xã An Bình A",
    LevelUp: "Xã",
    DistrictCode: "5012",
    Active: -1,
  },
  {
    ID: 9770,
    Code: "29926",
    Name: "Thị trấn Sa Rài",
    LevelUp: "Thị trấn",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9771,
    Code: "29929",
    Name: "Xã Tân Hộ Cơ",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9772,
    Code: "29932",
    Name: "Xã Thông Bình",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9773,
    Code: "29935",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9774,
    Code: "29938",
    Name: "Xã Tân Thành A",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9775,
    Code: "29941",
    Name: "Xã Tân Thành B",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9776,
    Code: "29944",
    Name: "Xã Tân Phước",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9777,
    Code: "29947",
    Name: "Xã Tân Công Chí",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9778,
    Code: "29950",
    Name: "Xã An Phước",
    LevelUp: "Xã",
    DistrictCode: "5003",
    Active: -1,
  },
  {
    ID: 9779,
    Code: "29956",
    Name: "Xã Thường Phước 1",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9780,
    Code: "29962",
    Name: "Xã Thường Thới Hậu A",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9781,
    Code: "29971",
    Name: "Thị trấn Thường Thới Tiền",
    LevelUp: "Thị trấn",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9782,
    Code: "29974",
    Name: "Xã Thường Phước 2",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9783,
    Code: "29977",
    Name: "Xã Thường Lạc",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9784,
    Code: "29980",
    Name: "Xã Long Khánh A",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9785,
    Code: "29983",
    Name: "Xã Long Khánh B",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9786,
    Code: "29992",
    Name: "Xã Long Thuận",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9787,
    Code: "29995",
    Name: "Xã Phú Thuận B",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9788,
    Code: "29998",
    Name: "Xã Phú Thuận A",
    LevelUp: "Xã",
    DistrictCode: "5004",
    Active: -1,
  },
  {
    ID: 9789,
    Code: "30001",
    Name: "Thị trấn Tràm Chim",
    LevelUp: "Thị trấn",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9790,
    Code: "30004",
    Name: "Xã Hoà Bình",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9791,
    Code: "30007",
    Name: "Xã Tân Công Sính",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9792,
    Code: "30010",
    Name: "Xã Phú Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9793,
    Code: "30013",
    Name: "Xã Phú Đức",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9794,
    Code: "30016",
    Name: "Xã Phú Thành B",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9795,
    Code: "30019",
    Name: "Xã An Hòa",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9796,
    Code: "30022",
    Name: "Xã An Long",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9797,
    Code: "30025",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9798,
    Code: "30028",
    Name: "Xã Phú Ninh",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9799,
    Code: "30031",
    Name: "Xã Phú Thọ",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9800,
    Code: "30034",
    Name: "Xã Phú Thành A",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 9801,
    Code: "30037",
    Name: "Thị trấn Mỹ An",
    LevelUp: "Thị trấn",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9802,
    Code: "30040",
    Name: "Xã Thạnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9803,
    Code: "30043",
    Name: "Xã Hưng Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9804,
    Code: "30046",
    Name: "Xã Trường Xuân",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9805,
    Code: "30049",
    Name: "Xã Tân Kiều",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9806,
    Code: "30052",
    Name: "Xã Mỹ Hòa",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9807,
    Code: "30055",
    Name: "Xã Mỹ Quý",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9808,
    Code: "30058",
    Name: "Xã Mỹ Đông",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9809,
    Code: "30061",
    Name: "Xã Đốc Binh Kiều",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9810,
    Code: "30064",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9811,
    Code: "30067",
    Name: "Xã Phú Điền",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9812,
    Code: "30070",
    Name: "Xã Láng Biển",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9813,
    Code: "30073",
    Name: "Xã Thanh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5009",
    Active: -1,
  },
  {
    ID: 9814,
    Code: "30076",
    Name: "Thị trấn Mỹ Thọ",
    LevelUp: "Thị trấn",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9815,
    Code: "30079",
    Name: "Xã Gáo Giồng",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9816,
    Code: "30082",
    Name: "Xã Phương Thịnh",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9817,
    Code: "30085",
    Name: "Xã Ba Sao",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9818,
    Code: "30088",
    Name: "Xã Phong Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9819,
    Code: "30091",
    Name: "Xã Tân Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9820,
    Code: "30094",
    Name: "Xã Phương Trà",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9821,
    Code: "30097",
    Name: "Xã Nhị Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9822,
    Code: "30100",
    Name: "Xã Mỹ Thọ",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9823,
    Code: "30103",
    Name: "Xã Tân Hội Trung",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9824,
    Code: "30106",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9825,
    Code: "30109",
    Name: "Xã Mỹ Hội",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9826,
    Code: "30112",
    Name: "Xã Mỹ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9827,
    Code: "30115",
    Name: "Xã Mỹ Long",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9828,
    Code: "30118",
    Name: "Xã Bình Hàng Trung",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9829,
    Code: "30121",
    Name: "Xã Mỹ Xương",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9830,
    Code: "30124",
    Name: "Xã Bình Hàng Tây",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9831,
    Code: "30127",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 9832,
    Code: "30130",
    Name: "Thị trấn Thanh Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9833,
    Code: "30133",
    Name: "Xã Tân Quới",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9834,
    Code: "30136",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9835,
    Code: "30139",
    Name: "Xã An Phong",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9836,
    Code: "30142",
    Name: "Xã Phú Lợi",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9837,
    Code: "30145",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9838,
    Code: "30148",
    Name: "Xã Bình Tấn",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9839,
    Code: "30151",
    Name: "Xã Tân Huề",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9840,
    Code: "30154",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9841,
    Code: "30157",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9842,
    Code: "30160",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9843,
    Code: "30163",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9844,
    Code: "30166",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "5006",
    Active: -1,
  },
  {
    ID: 9845,
    Code: "30169",
    Name: "Thị trấn Lấp Vò",
    LevelUp: "Thị trấn",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9846,
    Code: "30172",
    Name: "Xã Mỹ An Hưng A",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9847,
    Code: "30175",
    Name: "Xã Tân Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9848,
    Code: "30178",
    Name: "Xã Mỹ An Hưng B",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9849,
    Code: "30181",
    Name: "Xã Tân  Khánh Trung",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9850,
    Code: "30184",
    Name: "Xã Long Hưng A",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9851,
    Code: "30187",
    Name: "Xã Vĩnh Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9852,
    Code: "30190",
    Name: "Xã Long Hưng B",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9853,
    Code: "30193",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9854,
    Code: "30196",
    Name: "Xã Định An",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9855,
    Code: "30199",
    Name: "Xã Định Yên",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9856,
    Code: "30202",
    Name: "Xã Hội An Đông",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9857,
    Code: "30205",
    Name: "Xã Bình Thạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "5008",
    Active: -1,
  },
  {
    ID: 9858,
    Code: "30208",
    Name: "Thị trấn Lai Vung",
    LevelUp: "Thị trấn",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9859,
    Code: "30211",
    Name: "Xã Tân Dương",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9860,
    Code: "30214",
    Name: "Xã Hòa Thành",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9861,
    Code: "30217",
    Name: "Xã Long Hậu",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9862,
    Code: "30220",
    Name: "Xã Tân Phước",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9863,
    Code: "30223",
    Name: "Xã Hòa Long",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9864,
    Code: "30226",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9865,
    Code: "30229",
    Name: "Xã Long Thắng",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9866,
    Code: "30232",
    Name: "Xã Vĩnh Thới",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9867,
    Code: "30235",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9868,
    Code: "30238",
    Name: "Xã Định Hòa",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9869,
    Code: "30241",
    Name: "Xã Phong Hòa",
    LevelUp: "Xã",
    DistrictCode: "5010",
    Active: -1,
  },
  {
    ID: 9870,
    Code: "30244",
    Name: "Thị trấn Cái Tàu Hạ",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9871,
    Code: "30247",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9872,
    Code: "30250",
    Name: "Xã An Nhơn",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9873,
    Code: "30253",
    Name: "Xã Tân Nhuận Đông",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9874,
    Code: "30256",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9875,
    Code: "30259",
    Name: "Xã Tân Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9876,
    Code: "30262",
    Name: "Xã Phú Long",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9877,
    Code: "30265",
    Name: "Xã An Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9878,
    Code: "30268",
    Name: "Xã Phú Hựu",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9879,
    Code: "30271",
    Name: "Xã An Khánh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9880,
    Code: "30274",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9881,
    Code: "30277",
    Name: "Xã Hòa Tân",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9882,
    Code: "30280",
    Name: "Phường Mỹ Bình",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9883,
    Code: "30283",
    Name: "Phường Mỹ Long",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9884,
    Code: "30285",
    Name: "Phường Đông Xuyên",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9885,
    Code: "30286",
    Name: "Phường Mỹ Xuyên",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9886,
    Code: "30289",
    Name: "Phường Bình Đức",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9887,
    Code: "30292",
    Name: "Phường Bình Khánh",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9888,
    Code: "30295",
    Name: "Phường Mỹ Phước",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9889,
    Code: "30298",
    Name: "Phường Mỹ Quý",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9890,
    Code: "30301",
    Name: "Phường Mỹ Thới",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9891,
    Code: "30304",
    Name: "Phường Mỹ Thạnh",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9892,
    Code: "30307",
    Name: "Phường Mỹ Hòa",
    LevelUp: "Phường",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9893,
    Code: "30310",
    Name: "Xã Mỹ Khánh",
    LevelUp: "Xã",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9894,
    Code: "30313",
    Name: "Xã Mỹ Hoà Hưng",
    LevelUp: "Xã",
    DistrictCode: "5101",
    Active: -1,
  },
  {
    ID: 9895,
    Code: "30316",
    Name: "Phường Châu Phú B",
    LevelUp: "Phường",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9896,
    Code: "30319",
    Name: "Phường Châu Phú A",
    LevelUp: "Phường",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9897,
    Code: "30322",
    Name: "Phường Vĩnh Mỹ",
    LevelUp: "Phường",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9898,
    Code: "30325",
    Name: "Phường Núi Sam",
    LevelUp: "Phường",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9899,
    Code: "30328",
    Name: "Phường Vĩnh Ngươn",
    LevelUp: "Phường",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9900,
    Code: "30331",
    Name: "Xã Vĩnh Tế",
    LevelUp: "Xã",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9901,
    Code: "30334",
    Name: "Xã Vĩnh Châu",
    LevelUp: "Xã",
    DistrictCode: "5102",
    Active: -1,
  },
  {
    ID: 9902,
    Code: "30337",
    Name: "Thị trấn An Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9903,
    Code: "30340",
    Name: "Xã Khánh An",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9904,
    Code: "30341",
    Name: "Thị Trấn Long Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9905,
    Code: "30343",
    Name: "Xã Khánh Bình",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9906,
    Code: "30346",
    Name: "Xã Quốc Thái",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9907,
    Code: "30349",
    Name: "Xã Nhơn Hội",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9908,
    Code: "30352",
    Name: "Xã Phú Hữu",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9909,
    Code: "30355",
    Name: "Xã Phú Hội",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9910,
    Code: "30358",
    Name: "Xã Phước Hưng",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9911,
    Code: "30361",
    Name: "Xã Vĩnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9912,
    Code: "30364",
    Name: "Xã Vĩnh Hậu",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9913,
    Code: "30367",
    Name: "Xã Vĩnh Trường",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9914,
    Code: "30370",
    Name: "Xã Vĩnh Hội Đông",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9915,
    Code: "30373",
    Name: "Xã Đa Phước",
    LevelUp: "Xã",
    DistrictCode: "5103",
    Active: -1,
  },
  {
    ID: 9916,
    Code: "30376",
    Name: "Phường Long Thạnh",
    LevelUp: "Phường",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9917,
    Code: "30377",
    Name: "Phường Long Hưng",
    LevelUp: "Phường",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9918,
    Code: "30378",
    Name: "Phường Long Châu",
    LevelUp: "Phường",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9919,
    Code: "30379",
    Name: "Xã Phú Lộc",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9920,
    Code: "30382",
    Name: "Xã Vĩnh Xương",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9921,
    Code: "30385",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9922,
    Code: "30387",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9923,
    Code: "30388",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9924,
    Code: "30391",
    Name: "Xã Long An",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9925,
    Code: "30394",
    Name: "Phường Long Phú",
    LevelUp: "Phường",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9926,
    Code: "30397",
    Name: "Xã Châu Phong",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9927,
    Code: "30400",
    Name: "Xã Phú Vĩnh",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9928,
    Code: "30403",
    Name: "Xã Lê Chánh",
    LevelUp: "Xã",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9929,
    Code: "30412",
    Name: "Phường Long Sơn",
    LevelUp: "Phường",
    DistrictCode: "5104",
    Active: -1,
  },
  {
    ID: 9930,
    Code: "30406",
    Name: "Thị trấn Phú Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9931,
    Code: "30409",
    Name: "Thị trấn Chợ Vàm",
    LevelUp: "Thị trấn",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9932,
    Code: "30415",
    Name: "Xã Long Hoà",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9933,
    Code: "30418",
    Name: "Xã Phú Long",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9934,
    Code: "30421",
    Name: "Xã Phú Lâm",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9935,
    Code: "30424",
    Name: "Xã Phú Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9936,
    Code: "30427",
    Name: "Xã Phú Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9937,
    Code: "30430",
    Name: "Xã Hoà Lạc",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9938,
    Code: "30433",
    Name: "Xã Phú Thành",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9939,
    Code: "30436",
    Name: "Xã Phú An",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9940,
    Code: "30439",
    Name: "Xã Phú Xuân",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9941,
    Code: "30442",
    Name: "Xã Hiệp Xương",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9942,
    Code: "30445",
    Name: "Xã Phú Bình",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9943,
    Code: "30448",
    Name: "Xã Phú Thọ",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9944,
    Code: "30451",
    Name: "Xã Phú Hưng",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9945,
    Code: "30454",
    Name: "Xã Bình Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9946,
    Code: "30457",
    Name: "Xã Tân Hòa",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9947,
    Code: "30460",
    Name: "Xã Tân Trung",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 9948,
    Code: "30463",
    Name: "Thị trấn Cái Dầu",
    LevelUp: "Thị trấn",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9949,
    Code: "30466",
    Name: "Xã Khánh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9950,
    Code: "30469",
    Name: "Xã Mỹ Đức",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9951,
    Code: "30472",
    Name: "Xã Mỹ Phú",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9952,
    Code: "30475",
    Name: "Xã Ô Long Vỹ",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9953,
    Code: "30478",
    Name: "Xã Vĩnh Thạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9954,
    Code: "30481",
    Name: "Xã Thạnh Mỹ Tây",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9955,
    Code: "30484",
    Name: "Xã Bình Long",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9956,
    Code: "30487",
    Name: "Xã Bình Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9957,
    Code: "30490",
    Name: "Xã Bình Thủy",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9958,
    Code: "30493",
    Name: "Xã Đào Hữu Cảnh",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9959,
    Code: "30496",
    Name: "Xã Bình Phú",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9960,
    Code: "30499",
    Name: "Xã Bình Chánh",
    LevelUp: "Xã",
    DistrictCode: "5108",
    Active: -1,
  },
  {
    ID: 9961,
    Code: "30502",
    Name: "Thị trấn Nhà Bàng",
    LevelUp: "Thị trấn",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9962,
    Code: "30505",
    Name: "Thị trấn Chi Lăng",
    LevelUp: "Thị trấn",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9963,
    Code: "30508",
    Name: "Xã Núi Voi",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9964,
    Code: "30511",
    Name: "Xã Nhơn Hưng",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9965,
    Code: "30514",
    Name: "Xã An Phú",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9966,
    Code: "30517",
    Name: "Xã Thới Sơn",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9967,
    Code: "30520",
    Name: "Thị trấn Tịnh Biên",
    LevelUp: "Thị trấn",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9968,
    Code: "30523",
    Name: "Xã Văn Giáo",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9969,
    Code: "30526",
    Name: "Xã An Cư",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9970,
    Code: "30529",
    Name: "Xã An Nông",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9971,
    Code: "30532",
    Name: "Xã Vĩnh Trung",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9972,
    Code: "30535",
    Name: "Xã Tân Lợi",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9973,
    Code: "30538",
    Name: "Xã An Hảo",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9974,
    Code: "30541",
    Name: "Xã Tân Lập",
    LevelUp: "Xã",
    DistrictCode: "5106",
    Active: -1,
  },
  {
    ID: 9975,
    Code: "30544",
    Name: "Thị trấn Tri Tôn",
    LevelUp: "Thị trấn",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9976,
    Code: "30547",
    Name: "Thị trấn Ba Chúc",
    LevelUp: "Thị trấn",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9977,
    Code: "30550",
    Name: "Xã Lạc Quới",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9978,
    Code: "30553",
    Name: "Xã Lê Trì",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9979,
    Code: "30556",
    Name: "Xã Vĩnh Gia",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9980,
    Code: "30559",
    Name: "Xã Vĩnh Phước",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9981,
    Code: "30562",
    Name: "Xã Châu Lăng",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9982,
    Code: "30565",
    Name: "Xã Lương Phi",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9983,
    Code: "30568",
    Name: "Xã Lương An Trà",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9984,
    Code: "30571",
    Name: "Xã Tà Đảnh",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9985,
    Code: "30574",
    Name: "Xã Núi Tô",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9986,
    Code: "30577",
    Name: "Xã An Tức",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9987,
    Code: "30580",
    Name: "Xã Cô Tô",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9988,
    Code: "30583",
    Name: "Xã Tân Tuyến",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9989,
    Code: "30586",
    Name: "Xã Ô Lâm",
    LevelUp: "Xã",
    DistrictCode: "5107",
    Active: -1,
  },
  {
    ID: 9990,
    Code: "30589",
    Name: "Thị trấn An Châu",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9991,
    Code: "30592",
    Name: "Xã An Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9992,
    Code: "30595",
    Name: "Xã Cần Đăng",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9993,
    Code: "30598",
    Name: "Xã Vĩnh Hanh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9994,
    Code: "30601",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9995,
    Code: "30604",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9996,
    Code: "30607",
    Name: "Xã Bình Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9997,
    Code: "30610",
    Name: "Xã Vĩnh An",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9998,
    Code: "30613",
    Name: "Xã Hòa Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 9999,
    Code: "30616",
    Name: "Xã Vĩnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10000,
    Code: "30619",
    Name: "Xã Vĩnh Nhuận",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10001,
    Code: "30622",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10002,
    Code: "30625",
    Name: "Xã Vĩnh Thành",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10003,
    Code: "30628",
    Name: "Thị trấn Chợ Mới",
    LevelUp: "Thị trấn",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10004,
    Code: "30631",
    Name: "Thị trấn Mỹ Luông",
    LevelUp: "Thị trấn",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10005,
    Code: "30634",
    Name: "Xã Kiến An",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10006,
    Code: "30637",
    Name: "Xã Mỹ Hội Đông",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10007,
    Code: "30640",
    Name: "Xã Long Điền A",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10008,
    Code: "30643",
    Name: "Xã Tấn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10009,
    Code: "30646",
    Name: "Xã Long Điền B",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10010,
    Code: "30649",
    Name: "Xã Kiến Thành",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10011,
    Code: "30652",
    Name: "Xã Mỹ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10012,
    Code: "30655",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10013,
    Code: "30658",
    Name: "Xã Nhơn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10014,
    Code: "30661",
    Name: "Xã Long Giang",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10015,
    Code: "30664",
    Name: "Xã Long Kiến",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10016,
    Code: "30667",
    Name: "Xã Bình Phước Xuân",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10017,
    Code: "30670",
    Name: "Xã An Thạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10018,
    Code: "30673",
    Name: "Xã Hội An",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10019,
    Code: "30676",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10020,
    Code: "30679",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "1107",
    Active: -1,
  },
  {
    ID: 10021,
    Code: "30682",
    Name: "Thị trấn Núi Sập",
    LevelUp: "Thị trấn",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10022,
    Code: "30685",
    Name: "Thị trấn Phú Hoà",
    LevelUp: "Thị trấn",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10023,
    Code: "30688",
    Name: "Thị Trấn Óc Eo",
    LevelUp: "Thị trấn",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10024,
    Code: "30691",
    Name: "Xã Tây Phú",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10025,
    Code: "30692",
    Name: "Xã An Bình",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10026,
    Code: "30694",
    Name: "Xã Vĩnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10027,
    Code: "30697",
    Name: "Xã Vĩnh Trạch",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10028,
    Code: "30700",
    Name: "Xã Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10029,
    Code: "30703",
    Name: "Xã Vĩnh Chánh",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10030,
    Code: "30706",
    Name: "Xã Định Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10031,
    Code: "30709",
    Name: "Xã Định Thành",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10032,
    Code: "30712",
    Name: "Xã Mỹ Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10033,
    Code: "30715",
    Name: "Xã Vọng Đông",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10034,
    Code: "30718",
    Name: "Xã Vĩnh Khánh",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10035,
    Code: "30721",
    Name: "Xã Thoại Giang",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10036,
    Code: "30724",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10037,
    Code: "30727",
    Name: "Xã Vọng Thê",
    LevelUp: "Xã",
    DistrictCode: "5111",
    Active: -1,
  },
  {
    ID: 10038,
    Code: "30730",
    Name: "Phường Vĩnh Thanh Vân",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10039,
    Code: "30733",
    Name: "Phường Vĩnh Thanh",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10040,
    Code: "30736",
    Name: "Phường Vĩnh Quang",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10041,
    Code: "30739",
    Name: "Phường Vĩnh Hiệp",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10042,
    Code: "30742",
    Name: "Phường Vĩnh Bảo",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10043,
    Code: "30745",
    Name: "Phường Vĩnh Lạc",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10044,
    Code: "30748",
    Name: "Phường An Hòa",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10045,
    Code: "30751",
    Name: "Phường An Bình",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10046,
    Code: "30754",
    Name: "Phường Rạch Sỏi",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10047,
    Code: "30757",
    Name: "Phường Vĩnh Lợi",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10048,
    Code: "30760",
    Name: "Phường Vĩnh Thông",
    LevelUp: "Phường",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10049,
    Code: "30763",
    Name: "Xã Phi Thông",
    LevelUp: "Xã",
    DistrictCode: "5401",
    Active: -1,
  },
  {
    ID: 10050,
    Code: "30766",
    Name: "Phường Tô Châu",
    LevelUp: "Phường",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10051,
    Code: "30769",
    Name: "Phường Đông Hồ",
    LevelUp: "Phường",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10052,
    Code: "30772",
    Name: "Phường Bình San",
    LevelUp: "Phường",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10053,
    Code: "30775",
    Name: "Phường Pháo Đài",
    LevelUp: "Phường",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10054,
    Code: "30778",
    Name: "Phường Mỹ Đức",
    LevelUp: "Phường",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10055,
    Code: "30781",
    Name: "Xã Tiên Hải",
    LevelUp: "Xã",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10056,
    Code: "30784",
    Name: "Xã Thuận Yên",
    LevelUp: "Xã",
    DistrictCode: "5402",
    Active: -1,
  },
  {
    ID: 10057,
    Code: "30787",
    Name: "Thị trấn Kiên Lương",
    LevelUp: "Thị trấn",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10058,
    Code: "30790",
    Name: "Xã Kiên Bình",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10059,
    Code: "30802",
    Name: "Xã Hòa Điền",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10060,
    Code: "30805",
    Name: "Xã Dương Hòa",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10061,
    Code: "30808",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10062,
    Code: "30809",
    Name: "Xã Bình Trị",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10063,
    Code: "30811",
    Name: "Xã Sơn Hải",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10064,
    Code: "30814",
    Name: "Xã Hòn Nghệ",
    LevelUp: "Xã",
    DistrictCode: "5403",
    Active: -1,
  },
  {
    ID: 10065,
    Code: "30817",
    Name: "Thị trấn Hòn Đất",
    LevelUp: "Thị trấn",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10066,
    Code: "30820",
    Name: "Thị trấn Sóc Sơn",
    LevelUp: "Thị trấn",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10067,
    Code: "30823",
    Name: "Xã Bình Sơn",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10068,
    Code: "30826",
    Name: "Xã Bình Giang",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10069,
    Code: "30828",
    Name: "Xã Mỹ Thái",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10070,
    Code: "30829",
    Name: "Xã Nam Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10071,
    Code: "30832",
    Name: "Xã Mỹ Hiệp Sơn",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10072,
    Code: "30835",
    Name: "Xã Sơn Kiên",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10073,
    Code: "30836",
    Name: "Xã Sơn Bình",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10074,
    Code: "30838",
    Name: "Xã Mỹ Thuận",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10075,
    Code: "30840",
    Name: "Xã Lình Huỳnh",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10076,
    Code: "30841",
    Name: "Xã Thổ Sơn",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10077,
    Code: "30844",
    Name: "Xã Mỹ Lâm",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10078,
    Code: "30847",
    Name: "Xã Mỹ Phước",
    LevelUp: "Xã",
    DistrictCode: "5404",
    Active: -1,
  },
  {
    ID: 10079,
    Code: "30850",
    Name: "Thị trấn Tân Hiệp",
    LevelUp: "Thị trấn",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10080,
    Code: "30853",
    Name: "Xã Tân Hội",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10081,
    Code: "30856",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10082,
    Code: "30859",
    Name: "Xã Tân Hiệp B",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10083,
    Code: "30860",
    Name: "Xã Tân Hoà",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10084,
    Code: "30862",
    Name: "Xã Thạnh Đông B",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10085,
    Code: "30865",
    Name: "Xã Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10086,
    Code: "30868",
    Name: "Xã Tân Hiệp A",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10087,
    Code: "30871",
    Name: "Xã Tân An",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10088,
    Code: "30874",
    Name: "Xã Thạnh Đông A",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10089,
    Code: "30877",
    Name: "Xã Thạnh Trị",
    LevelUp: "Xã",
    DistrictCode: "5405",
    Active: -1,
  },
  {
    ID: 10090,
    Code: "30880",
    Name: "Thị trấn Minh Lương",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10091,
    Code: "30883",
    Name: "Xã Mong Thọ A",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10092,
    Code: "30886",
    Name: "Xã Mong Thọ B",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10093,
    Code: "30887",
    Name: "Xã Mong Thọ",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10094,
    Code: "30889",
    Name: "Xã Giục Tượng",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10095,
    Code: "30892",
    Name: "Xã Vĩnh Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10096,
    Code: "30893",
    Name: "Xã Vĩnh Hoà Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10097,
    Code: "30895",
    Name: "Xã Minh Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10098,
    Code: "30898",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10099,
    Code: "30901",
    Name: "Xã Thạnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10100,
    Code: "30904",
    Name: "Thị Trấn Giồng Riềng",
    LevelUp: "Thị trấn",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10101,
    Code: "30907",
    Name: "Xã Thạnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10102,
    Code: "30910",
    Name: "Xã Thạnh Phước",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10103,
    Code: "30913",
    Name: "Xã Thạnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10104,
    Code: "30916",
    Name: "Xã Thạnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10105,
    Code: "30917",
    Name: "Xã Thạnh Bình",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10106,
    Code: "30919",
    Name: "Xã Bàn Thạch",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10107,
    Code: "30922",
    Name: "Xã Bàn Tân Định",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10108,
    Code: "30925",
    Name: "Xã Ngọc Thành",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10109,
    Code: "30928",
    Name: "Xã Ngọc Chúc",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10110,
    Code: "30931",
    Name: "Xã Ngọc Thuận",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10111,
    Code: "30934",
    Name: "Xã Hòa Hưng",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10112,
    Code: "30937",
    Name: "Xã Hoà Lợi",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10113,
    Code: "30940",
    Name: "Xã Hoà An",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10114,
    Code: "30943",
    Name: "Xã Long Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10115,
    Code: "30946",
    Name: "Xã Vĩnh Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10116,
    Code: "30947",
    Name: "Xã Vĩnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10117,
    Code: "30949",
    Name: "Xã  Hòa Thuận",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10118,
    Code: "30950",
    Name: "Xã Ngọc Hoà",
    LevelUp: "Xã",
    DistrictCode: "5407",
    Active: -1,
  },
  {
    ID: 10119,
    Code: "30952",
    Name: "Thị trấn Gò Quao",
    LevelUp: "Thị trấn",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10120,
    Code: "30955",
    Name: "Xã Vĩnh Hòa Hưng Bắc",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10121,
    Code: "30958",
    Name: "Xã Định Hòa",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10122,
    Code: "30961",
    Name: "Xã Thới Quản",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10123,
    Code: "30964",
    Name: "Xã Định An",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10124,
    Code: "30967",
    Name: "Xã Thủy Liễu",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10125,
    Code: "30970",
    Name: "Xã Vĩnh Hòa Hưng Nam",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10126,
    Code: "30973",
    Name: "Xã Vĩnh Phước A",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10127,
    Code: "30976",
    Name: "Xã Vĩnh Phước B",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10128,
    Code: "30979",
    Name: "Xã Vĩnh Tuy",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10129,
    Code: "30982",
    Name: "Xã Vĩnh Thắng",
    LevelUp: "Xã",
    DistrictCode: "5408",
    Active: -1,
  },
  {
    ID: 10130,
    Code: "30985",
    Name: "Thị trấn Thứ Ba",
    LevelUp: "Thị trấn",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10131,
    Code: "30988",
    Name: "Xã Tây Yên",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10132,
    Code: "30991",
    Name: "Xã Tây Yên A",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10133,
    Code: "30994",
    Name: "Xã Nam Yên",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10134,
    Code: "30997",
    Name: "Xã Hưng Yên",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10135,
    Code: "31000",
    Name: "Xã Nam Thái",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10136,
    Code: "31003",
    Name: "Xã Nam Thái A",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10137,
    Code: "31006",
    Name: "Xã Đông Thái",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10138,
    Code: "31009",
    Name: "Xã Đông Yên",
    LevelUp: "Xã",
    DistrictCode: "5409",
    Active: -1,
  },
  {
    ID: 10139,
    Code: "31018",
    Name: "Thị trấn Thứ Mười Một",
    LevelUp: "Thị trấn",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10140,
    Code: "31021",
    Name: "Xã Thuận Hoà",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10141,
    Code: "31024",
    Name: "Xã Đông Hòa",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10142,
    Code: "31030",
    Name: "Xã Đông Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10143,
    Code: "31031",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10144,
    Code: "31033",
    Name: "Xã Đông Hưng",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10145,
    Code: "31036",
    Name: "Xã Đông Hưng A",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10146,
    Code: "31039",
    Name: "Xã Đông Hưng B",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10147,
    Code: "31042",
    Name: "Xã Vân Khánh",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10148,
    Code: "31045",
    Name: "Xã Vân Khánh Đông",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10149,
    Code: "31048",
    Name: "Xã Vân Khánh Tây",
    LevelUp: "Xã",
    DistrictCode: "5410",
    Active: -1,
  },
  {
    ID: 10150,
    Code: "31051",
    Name: "Thị trấn Vĩnh Thuận",
    LevelUp: "Thị trấn",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10151,
    Code: "31060",
    Name: "Xã Vĩnh Bình Bắc",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10152,
    Code: "31063",
    Name: "Xã Vĩnh Bình Nam",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10153,
    Code: "31064",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10154,
    Code: "31069",
    Name: "Xã Vĩnh Thuận",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10155,
    Code: "31072",
    Name: "Xã Tân Thuận",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10156,
    Code: "31074",
    Name: "Xã Phong Đông",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10157,
    Code: "31075",
    Name: "Xã Vĩnh Phong",
    LevelUp: "Xã",
    DistrictCode: "5411",
    Active: -1,
  },
  {
    ID: 10158,
    Code: "31078",
    Name: "Thị trấn Dương Đông",
    LevelUp: "Thị trấn",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10159,
    Code: "31081",
    Name: "Thị trấn An Thới",
    LevelUp: "Thị trấn",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10160,
    Code: "31084",
    Name: "Xã Cửa Cạn",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10161,
    Code: "31087",
    Name: "Xã Gành Dầu",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10162,
    Code: "31090",
    Name: "Xã Cửa Dương",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10163,
    Code: "31093",
    Name: "Xã Hàm Ninh",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10164,
    Code: "31096",
    Name: "Xã Dương Tơ",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10165,
    Code: "31099",
    Name: "Xã Hòn Thơm",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10166,
    Code: "31102",
    Name: "Xã Bãi Thơm",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10167,
    Code: "31105",
    Name: "Xã Thổ Châu",
    LevelUp: "Xã",
    DistrictCode: "5412",
    Active: -1,
  },
  {
    ID: 10168,
    Code: "31108",
    Name: "Xã Hòn Tre",
    LevelUp: "Xã",
    DistrictCode: "5413",
    Active: -1,
  },
  {
    ID: 10169,
    Code: "31111",
    Name: "Xã Lại Sơn",
    LevelUp: "Xã",
    DistrictCode: "5413",
    Active: -1,
  },
  {
    ID: 10170,
    Code: "31114",
    Name: "Xã An Sơn",
    LevelUp: "Xã",
    DistrictCode: "5413",
    Active: -1,
  },
  {
    ID: 10171,
    Code: "31115",
    Name: "Xã Nam Du",
    LevelUp: "Xã",
    DistrictCode: "5413",
    Active: -1,
  },
  {
    ID: 10172,
    Code: "31012",
    Name: "Xã Thạnh Yên",
    LevelUp: "Xã",
    DistrictCode: "5414",
    Active: -1,
  },
  {
    ID: 10173,
    Code: "31015",
    Name: "Xã Thạnh Yên A",
    LevelUp: "Xã",
    DistrictCode: "5414",
    Active: -1,
  },
  {
    ID: 10174,
    Code: "31027",
    Name: "Xã An Minh Bắc",
    LevelUp: "Xã",
    DistrictCode: "5414",
    Active: -1,
  },
  {
    ID: 10175,
    Code: "31054",
    Name: "Xã Vĩnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5414",
    Active: -1,
  },
  {
    ID: 10176,
    Code: "31057",
    Name: "Xã Hoà Chánh",
    LevelUp: "Xã",
    DistrictCode: "5414",
    Active: -1,
  },
  {
    ID: 10177,
    Code: "31066",
    Name: "Xã Minh Thuận",
    LevelUp: "Xã",
    DistrictCode: "5414",
    Active: -1,
  },
  {
    ID: 10178,
    Code: "30791",
    Name: "Xã Vĩnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5415",
    Active: -1,
  },
  {
    ID: 10179,
    Code: "30793",
    Name: "Xã Vĩnh Điều",
    LevelUp: "Xã",
    DistrictCode: "5415",
    Active: -1,
  },
  {
    ID: 10180,
    Code: "30796",
    Name: "Xã Tân Khánh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5415",
    Active: -1,
  },
  {
    ID: 10181,
    Code: "30797",
    Name: "Xã Phú Lợi",
    LevelUp: "Xã",
    DistrictCode: "5415",
    Active: -1,
  },
  {
    ID: 10182,
    Code: "30799",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5415",
    Active: -1,
  },
  {
    ID: 10183,
    Code: "31117",
    Name: "Phường Cái Khế",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10184,
    Code: "31120",
    Name: "Phường An Hòa",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10185,
    Code: "31123",
    Name: "Phường Thới Bình",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10186,
    Code: "31126",
    Name: "Phường An Nghiệp",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10187,
    Code: "31129",
    Name: "Phường An Cư",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10188,
    Code: "31135",
    Name: "Phường Tân An",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10189,
    Code: "31141",
    Name: "Phường An Phú",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10190,
    Code: "31144",
    Name: "Phường Xuân Khánh",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10191,
    Code: "31147",
    Name: "Phường Hưng Lợi",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10192,
    Code: "31149",
    Name: "Phường An Khánh",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10193,
    Code: "31150",
    Name: "Phường An Bình",
    LevelUp: "Phường",
    DistrictCode: "5501",
    Active: -1,
  },
  {
    ID: 10194,
    Code: "31153",
    Name: "Phường Châu Văn Liêm",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10195,
    Code: "31154",
    Name: "Phường Thới Hòa",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10196,
    Code: "31156",
    Name: "Phường Thới Long",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10197,
    Code: "31157",
    Name: "Phường Long Hưng",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10198,
    Code: "31159",
    Name: "Phường Thới An",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10199,
    Code: "31162",
    Name: "Phường Phước Thới",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10200,
    Code: "31165",
    Name: "Phường Trường Lạc",
    LevelUp: "Phường",
    DistrictCode: "5504",
    Active: -1,
  },
  {
    ID: 10201,
    Code: "31168",
    Name: "Phường Bình Thủy",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10202,
    Code: "31169",
    Name: "Phường Trà An",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10203,
    Code: "31171",
    Name: "Phường Trà Nóc",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10204,
    Code: "31174",
    Name: "Phường Thới An Đông",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10205,
    Code: "31177",
    Name: "Phường An Thới",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10206,
    Code: "31178",
    Name: "Phường Bùi Hữu Nghĩa",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10207,
    Code: "31180",
    Name: "Phường Long Hòa",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10208,
    Code: "31183",
    Name: "Phường Long Tuyền",
    LevelUp: "Phường",
    DistrictCode: "5502",
    Active: -1,
  },
  {
    ID: 10209,
    Code: "31186",
    Name: "Phường Lê Bình",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10210,
    Code: "31189",
    Name: "Phường Hưng Phú",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10211,
    Code: "31192",
    Name: "Phường Hưng Thạnh",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10212,
    Code: "31195",
    Name: "Phường Ba Láng",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10213,
    Code: "31198",
    Name: "Phường Thường Thạnh",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10214,
    Code: "31201",
    Name: "Phường Phú Thứ",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10215,
    Code: "31204",
    Name: "Phường Tân Phú",
    LevelUp: "Phường",
    DistrictCode: "5503",
    Active: -1,
  },
  {
    ID: 10216,
    Code: "31207",
    Name: "Phường Thốt Nốt",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10217,
    Code: "31210",
    Name: "Phường Thới Thuận",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10218,
    Code: "31212",
    Name: "Phường Thuận An",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10219,
    Code: "31213",
    Name: "Phường Tân Lộc",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10220,
    Code: "31216",
    Name: "Phường Trung Nhứt",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10221,
    Code: "31217",
    Name: "Phường Thạnh Hoà",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10222,
    Code: "31219",
    Name: "Phường Trung Kiên",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10223,
    Code: "31227",
    Name: "Phường Tân Hưng",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10224,
    Code: "31228",
    Name: "Phường Thuận Hưng",
    LevelUp: "Phường",
    DistrictCode: "5508",
    Active: -1,
  },
  {
    ID: 10225,
    Code: "31211",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10226,
    Code: "31231",
    Name: "Thị trấn Thanh An",
    LevelUp: "Thị trấn",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10227,
    Code: "31232",
    Name: "Thị trấn Vĩnh Thạnh",
    LevelUp: "Thị trấn",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10228,
    Code: "31234",
    Name: "Xã Thạnh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10229,
    Code: "31237",
    Name: "Xã Vĩnh Trinh",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10230,
    Code: "31240",
    Name: "Xã Thạnh An",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10231,
    Code: "31241",
    Name: "Xã Thạnh Tiến",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10232,
    Code: "31243",
    Name: "Xã Thạnh Thắng",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10233,
    Code: "31244",
    Name: "Xã Thạnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10234,
    Code: "31246",
    Name: "Xã Thạnh Qưới",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10235,
    Code: "31252",
    Name: "Xã Thạnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "3707",
    Active: -1,
  },
  {
    ID: 10236,
    Code: "31222",
    Name: "Xã Trung An",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10237,
    Code: "31225",
    Name: "Xã Trung Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10238,
    Code: "31249",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10239,
    Code: "31255",
    Name: "Xã Trung Hưng",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10240,
    Code: "31261",
    Name: "Thị trấn Cờ Đỏ",
    LevelUp: "Thị trấn",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10241,
    Code: "31264",
    Name: "Xã Thới Hưng",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10242,
    Code: "31273",
    Name: "Xã Đông Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10243,
    Code: "31274",
    Name: "Xã Đông Thắng",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10244,
    Code: "31276",
    Name: "Xã Thới Đông",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10245,
    Code: "31277",
    Name: "Xã Thới Xuân",
    LevelUp: "Xã",
    DistrictCode: "5506",
    Active: -1,
  },
  {
    ID: 10246,
    Code: "31299",
    Name: "Thị trấn Phong Điền",
    LevelUp: "Thị trấn",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 10249,
    Code: "31306",
    Name: "Xã Tân Thới",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 10250,
    Code: "31309",
    Name: "Xã Trường Long",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 10251,
    Code: "31312",
    Name: "Xã Mỹ Khánh",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 10252,
    Code: "31315",
    Name: "Xã Nhơn Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "3302",
    Active: -1,
  },
  {
    ID: 10253,
    Code: "31258",
    Name: "Thị trấn Thới Lai",
    LevelUp: "Thị trấn",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10254,
    Code: "31267",
    Name: "Xã Thới Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10255,
    Code: "31268",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10256,
    Code: "31270",
    Name: "Xã Xuân Thắng",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10257,
    Code: "31279",
    Name: "Xã Đông Bình",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10258,
    Code: "31282",
    Name: "Xã Đông Thuận",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10259,
    Code: "31285",
    Name: "Xã Thới Tân",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10260,
    Code: "31286",
    Name: "Xã Trường Thắng",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10261,
    Code: "31288",
    Name: "Xã Định Môn",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10262,
    Code: "31291",
    Name: "Xã Trường Thành",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10263,
    Code: "31294",
    Name: "Xã Trường Xuân",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10264,
    Code: "31297",
    Name: "Xã Trường Xuân A",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10265,
    Code: "31298",
    Name: "Xã Trường Xuân B",
    LevelUp: "Xã",
    DistrictCode: "5509",
    Active: -1,
  },
  {
    ID: 10266,
    Code: "31318",
    Name: "Phường I",
    LevelUp: "Phường",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10267,
    Code: "31321",
    Name: "Phường III",
    LevelUp: "Phường",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10268,
    Code: "31324",
    Name: "Phường IV",
    LevelUp: "Phường",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10269,
    Code: "31327",
    Name: "Phường V",
    LevelUp: "Phường",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10270,
    Code: "31330",
    Name: "Phường VII",
    LevelUp: "Phường",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10271,
    Code: "31333",
    Name: "Xã Vị Tân",
    LevelUp: "Xã",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10272,
    Code: "31336",
    Name: "Xã Hoả Lựu",
    LevelUp: "Xã",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10273,
    Code: "31338",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10274,
    Code: "31339",
    Name: "Xã Hoả Tiến",
    LevelUp: "Xã",
    DistrictCode: "6401",
    Active: -1,
  },
  {
    ID: 10275,
    Code: "31340",
    Name: "Phường Ngã Bảy",
    LevelUp: "Phường",
    DistrictCode: "6407",
    Active: -1,
  },
  {
    ID: 10276,
    Code: "31341",
    Name: "Phường Lái Hiếu",
    LevelUp: "Phường",
    DistrictCode: "6407",
    Active: -1,
  },
  {
    ID: 10277,
    Code: "31343",
    Name: "Phường Hiệp Thành",
    LevelUp: "Phường",
    DistrictCode: "6407",
    Active: -1,
  },
  {
    ID: 10278,
    Code: "31344",
    Name: "Phường Hiệp Lợi",
    LevelUp: "Phường",
    DistrictCode: "6407",
    Active: -1,
  },
  {
    ID: 10279,
    Code: "31411",
    Name: "Xã Đại Thành",
    LevelUp: "Xã",
    DistrictCode: "6407",
    Active: -1,
  },
  {
    ID: 10280,
    Code: "31414",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "6407",
    Active: -1,
  },
  {
    ID: 10281,
    Code: "31342",
    Name: "Thị trấn Một Ngàn",
    LevelUp: "Thị trấn",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10282,
    Code: "31345",
    Name: "Xã Tân Hoà",
    LevelUp: "Xã",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10283,
    Code: "31346",
    Name: "Thị trấn Bảy Ngàn",
    LevelUp: "Thị trấn",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10284,
    Code: "31348",
    Name: "Xã Trường Long Tây",
    LevelUp: "Xã",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10285,
    Code: "31351",
    Name: "Xã Trường Long A",
    LevelUp: "Xã",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10286,
    Code: "31357",
    Name: "Xã Nhơn Nghĩa A",
    LevelUp: "Xã",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10287,
    Code: "31359",
    Name: "Thị trấn Rạch Gòi",
    LevelUp: "Thị trấn",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10288,
    Code: "31360",
    Name: "Xã Thạnh Xuân",
    LevelUp: "Xã",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10289,
    Code: "31362",
    Name: "Thị trấn Cái Tắc",
    LevelUp: "Thị trấn",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10290,
    Code: "31363",
    Name: "Xã Tân Phú Thạnh",
    LevelUp: "Xã",
    DistrictCode: "6406",
    Active: -1,
  },
  {
    ID: 10291,
    Code: "31366",
    Name: "Thị Trấn Ngã Sáu",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10292,
    Code: "31369",
    Name: "Xã Đông Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10293,
    Code: "31375",
    Name: "Xã Đông Phú",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10294,
    Code: "31378",
    Name: "Xã Phú Hữu A",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10295,
    Code: "31379",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10297,
    Code: "31384",
    Name: "Xã Đông Phước",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10298,
    Code: "31387",
    Name: "Xã Đông Phước A",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10299,
    Code: "31393",
    Name: "Thị trấn Kinh Cùng",
    LevelUp: "Thị trấn",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10300,
    Code: "31396",
    Name: "Thị trấn Cây Dương",
    LevelUp: "Thị trấn",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10301,
    Code: "31399",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10302,
    Code: "31402",
    Name: "Xã Bình Thành",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10303,
    Code: "31405",
    Name: "Xã Thạnh Hòa",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10304,
    Code: "31408",
    Name: "Xã Long Thạnh",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10305,
    Code: "31417",
    Name: "Xã Phụng Hiệp",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10306,
    Code: "31420",
    Name: "Xã Hòa Mỹ",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10307,
    Code: "31423",
    Name: "Xã Hòa An",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10308,
    Code: "31426",
    Name: "Xã Phương Bình",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10309,
    Code: "31429",
    Name: "Xã Hiệp Hưng",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10310,
    Code: "31432",
    Name: "Xã Tân Phước Hưng",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10311,
    Code: "31433",
    Name: "Thị trấn Búng Tàu",
    LevelUp: "Thị trấn",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10312,
    Code: "31435",
    Name: "Xã Phương Phú",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10313,
    Code: "31438",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "6404",
    Active: -1,
  },
  {
    ID: 10314,
    Code: "31441",
    Name: "Thị trấn Nàng Mau",
    LevelUp: "Thị trấn",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10315,
    Code: "31444",
    Name: "Xã Vị Trung",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10316,
    Code: "31447",
    Name: "Xã Vị Thuỷ",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10317,
    Code: "31450",
    Name: "Xã Vị Thắng",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10318,
    Code: "31453",
    Name: "Xã Vĩnh Thuận Tây",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10319,
    Code: "31456",
    Name: "Xã Vĩnh Trung",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10320,
    Code: "31459",
    Name: "Xã Vĩnh Tường",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10321,
    Code: "31462",
    Name: "Xã Vị Đông",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10322,
    Code: "31465",
    Name: "Xã Vị Thanh",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10323,
    Code: "31468",
    Name: "Xã Vị Bình",
    LevelUp: "Xã",
    DistrictCode: "6402",
    Active: -1,
  },
  {
    ID: 10324,
    Code: "31483",
    Name: "Xã Thuận Hưng",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10325,
    Code: "31484",
    Name: "Xã Thuận Hòa",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10326,
    Code: "31486",
    Name: "Xã Vĩnh Thuận Đông",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10327,
    Code: "31489",
    Name: "Thị trấn Vĩnh Viễn",
    LevelUp: "Thị trấn",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10328,
    Code: "31490",
    Name: "Xã Vĩnh Viễn A",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10329,
    Code: "31492",
    Name: "Xã Lương Tâm",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10330,
    Code: "31493",
    Name: "Xã Lương Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10331,
    Code: "31495",
    Name: "Xã Xà Phiên",
    LevelUp: "Xã",
    DistrictCode: "NULL",
    Active: -1,
  },
  {
    ID: 10332,
    Code: "31471",
    Name: "Thị Trấn Long Mỹ",
    LevelUp: "Phường",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10333,
    Code: "31472",
    Name: "Xã Tân Phú",
    LevelUp: "Phường",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10334,
    Code: "31473",
    Name: "Xã Vĩnh Thuận Đông",
    LevelUp: "Phường",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10335,
    Code: "31474",
    Name: "Xã Thuận Hòa",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10336,
    Code: "31475",
    Name: "Xã Thuận Hưng",
    LevelUp: "Phường",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10337,
    Code: "31477",
    Name: "Xã Lương Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10338,
    Code: "31478",
    Name: "Xã Vĩnh Viễn A",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10339,
    Code: "31480",
    Name: "Phường Trà Lồng",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10340,
    Code: "31481",
    Name: "Xã Long Bình",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10341,
    Code: "31498",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10342,
    Code: "31501",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10343,
    Code: "31504",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10344,
    Code: "31507",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10345,
    Code: "31510",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10346,
    Code: "31513",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10347,
    Code: "31516",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10348,
    Code: "31519",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10349,
    Code: "31522",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10350,
    Code: "31525",
    Name: "Phường 10",
    LevelUp: "Phường",
    DistrictCode: "5901",
    Active: -1,
  },
  {
    ID: 10351,
    Code: "31569",
    Name: "Thị trấn Châu Thành",
    LevelUp: "Thị trấn",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10352,
    Code: "31570",
    Name: "Xã Hồ Đắc Kiện",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10353,
    Code: "31573",
    Name: "Xã Phú Tâm",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10354,
    Code: "31576",
    Name: "Xã Thuận Hòa",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10355,
    Code: "31582",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10356,
    Code: "31585",
    Name: "Xã Thiện Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10357,
    Code: "31594",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10358,
    Code: "31600",
    Name: "Xã An Ninh",
    LevelUp: "Xã",
    DistrictCode: "4605",
    Active: -1,
  },
  {
    ID: 10359,
    Code: "31528",
    Name: "Thị trấn Kế Sách",
    LevelUp: "Thị trấn",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10360,
    Code: "31531",
    Name: "Thị trấn An Lạc Thôn",
    LevelUp: "Thị trấn",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10361,
    Code: "31534",
    Name: "Xã Xuân Hòa",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10362,
    Code: "31537",
    Name: "Xã Phong Nẫm",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10363,
    Code: "31540",
    Name: "Xã An Lạc Tây",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10364,
    Code: "31543",
    Name: "Xã Trinh Phú",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10365,
    Code: "31546",
    Name: "Xã Ba Trinh",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10366,
    Code: "31549",
    Name: "Xã Thới An Hội",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10367,
    Code: "31552",
    Name: "Xã Nhơn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10368,
    Code: "31555",
    Name: "Xã Kế Thành",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10369,
    Code: "31558",
    Name: "Xã Kế An",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10370,
    Code: "31561",
    Name: "Xã Đại Hải",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10371,
    Code: "31564",
    Name: "Xã An Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5902",
    Active: -1,
  },
  {
    ID: 10372,
    Code: "31567",
    Name: "Thị trấn Huỳnh Hữu Nghĩa",
    LevelUp: "Thị trấn",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10373,
    Code: "31579",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10374,
    Code: "31588",
    Name: "Xã Hưng Phú",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10375,
    Code: "31591",
    Name: "Xã Mỹ Hương",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10376,
    Code: "31597",
    Name: "Xã Mỹ Tú",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10377,
    Code: "31603",
    Name: "Xã Mỹ Phước",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10378,
    Code: "31606",
    Name: "Xã Thuận Hưng",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10379,
    Code: "31609",
    Name: "Xã Mỹ Thuận",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10380,
    Code: "31612",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5903",
    Active: -1,
  },
  {
    ID: 10381,
    Code: "31615",
    Name: "Thị trấn Cù Lao Dung",
    LevelUp: "Thị trấn",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10382,
    Code: "31618",
    Name: "Xã An Thạnh 1",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10383,
    Code: "31621",
    Name: "Xã An Thạnh Tây",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10384,
    Code: "31624",
    Name: "Xã An Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10385,
    Code: "31627",
    Name: "Xã Đại Ân 1",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10386,
    Code: "31630",
    Name: "Xã An Thạnh 2",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10387,
    Code: "31633",
    Name: "Xã An Thạnh 3",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10388,
    Code: "31636",
    Name: "Xã An Thạnh Nam",
    LevelUp: "Xã",
    DistrictCode: "5908",
    Active: -1,
  },
  {
    ID: 10389,
    Code: "31639",
    Name: "Thị trấn Long Phú",
    LevelUp: "Thị trấn",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10390,
    Code: "31642",
    Name: "Xã Song Phụng",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10391,
    Code: "31645",
    Name: "Thị trấn Đại Ngãi",
    LevelUp: "Thị trấn",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10392,
    Code: "31648",
    Name: "Xã Hậu Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10393,
    Code: "31651",
    Name: "Xã Long Đức",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10394,
    Code: "31654",
    Name: "Xã Trường Khánh",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10395,
    Code: "31657",
    Name: "Xã Phú Hữu",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10396,
    Code: "31660",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10397,
    Code: "31663",
    Name: "Xã Châu Khánh",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10398,
    Code: "31666",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10399,
    Code: "31669",
    Name: "Xã Long Phú",
    LevelUp: "Xã",
    DistrictCode: "5906",
    Active: -1,
  },
  {
    ID: 10400,
    Code: "31684",
    Name: "Thị trấn Mỹ Xuyên",
    LevelUp: "Thị trấn",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10401,
    Code: "31690",
    Name: "Xã Đại Tâm",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10402,
    Code: "31693",
    Name: "Xã Tham Đôn",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10403,
    Code: "31708",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10404,
    Code: "31711",
    Name: "Xã Ngọc Đông",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10405,
    Code: "31714",
    Name: "Xã Thạnh Quới",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10406,
    Code: "31717",
    Name: "Xã Hòa Tú 1",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10407,
    Code: "31720",
    Name: "Xã Gia Hòa 1",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10408,
    Code: "31723",
    Name: "Xã Ngọc Tố",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10409,
    Code: "31726",
    Name: "Xã Gia Hòa 2",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10410,
    Code: "31729",
    Name: "Xã Hòa Tú II",
    LevelUp: "Xã",
    DistrictCode: "5904",
    Active: -1,
  },
  {
    ID: 10411,
    Code: "31732",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10412,
    Code: "31735",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10413,
    Code: "31738",
    Name: "Xã Vĩnh Quới",
    LevelUp: "Xã",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10414,
    Code: "31741",
    Name: "Xã Tân Long",
    LevelUp: "Xã",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10415,
    Code: "31744",
    Name: "Xã Long Bình",
    LevelUp: "Xã",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10416,
    Code: "31747",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10417,
    Code: "31750",
    Name: "Xã Mỹ Bình",
    LevelUp: "Xã",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10418,
    Code: "31753",
    Name: "Xã Mỹ Quới",
    LevelUp: "Xã",
    DistrictCode: "5909",
    Active: -1,
  },
  {
    ID: 10419,
    Code: "31756",
    Name: "Thị trấn Phú Lộc",
    LevelUp: "Thị trấn",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10420,
    Code: "31757",
    Name: "Thị trấn Hưng Lợi",
    LevelUp: "Thị trấn",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10421,
    Code: "31759",
    Name: "Xã Lâm Tân",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10422,
    Code: "31762",
    Name: "Xã Thạnh Tân",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10423,
    Code: "31765",
    Name: "Xã Lâm Kiết",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10424,
    Code: "31768",
    Name: "Xã Tuân Tức",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10425,
    Code: "31771",
    Name: "Xã Vĩnh Thành",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10426,
    Code: "31774",
    Name: "Xã Thạnh Trị",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10427,
    Code: "31777",
    Name: "Xã Vĩnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10428,
    Code: "31780",
    Name: "Xã Châu Hưng",
    LevelUp: "Xã",
    DistrictCode: "5905",
    Active: -1,
  },
  {
    ID: 10429,
    Code: "31783",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10430,
    Code: "31786",
    Name: "Xã Hòa Đông",
    LevelUp: "Xã",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10431,
    Code: "31789",
    Name: "Phường Khánh Hòa",
    LevelUp: "Phường",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10432,
    Code: "31792",
    Name: "Xã Vĩnh Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10433,
    Code: "31795",
    Name: "Xã Vĩnh Hải",
    LevelUp: "Xã",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10434,
    Code: "31798",
    Name: "Xã Lạc Hòa",
    LevelUp: "Xã",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10435,
    Code: "31801",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10436,
    Code: "31804",
    Name: "Phường Vĩnh Phước",
    LevelUp: "Phường",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10437,
    Code: "31807",
    Name: "Xã Vĩnh Tân",
    LevelUp: "Xã",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10438,
    Code: "31810",
    Name: "Xã Lai Hòa",
    LevelUp: "Xã",
    DistrictCode: "5907",
    Active: -1,
  },
  {
    ID: 10439,
    Code: "31672",
    Name: "Xã Đại Ân  2",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10440,
    Code: "31673",
    Name: "Thị trấn Trần Đề",
    LevelUp: "Thị trấn",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10441,
    Code: "31675",
    Name: "Xã Liêu Tú",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10442,
    Code: "31678",
    Name: "Xã Lịch Hội Thượng",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10443,
    Code: "31679",
    Name: "Thị trấn Lịch Hội Thượng",
    LevelUp: "Thị trấn",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10444,
    Code: "31681",
    Name: "Xã Trung Bình",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10445,
    Code: "31687",
    Name: "Xã Tài Văn",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10446,
    Code: "31696",
    Name: "Xã Viên An",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10447,
    Code: "31699",
    Name: "Xã Thạnh Thới An",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10448,
    Code: "31702",
    Name: "Xã Thạnh Thới Thuận",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10449,
    Code: "31705",
    Name: "Xã Viên Bình",
    LevelUp: "Xã",
    DistrictCode: "5911",
    Active: -1,
  },
  {
    ID: 10450,
    Code: "31813",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10451,
    Code: "31816",
    Name: "Phường 3",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10452,
    Code: "31819",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10453,
    Code: "31822",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10454,
    Code: "31825",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10455,
    Code: "31828",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10456,
    Code: "31831",
    Name: "Phường Nhà Mát",
    LevelUp: "Phường",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10457,
    Code: "31834",
    Name: "Xã Vĩnh Trạch",
    LevelUp: "Xã",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10458,
    Code: "31837",
    Name: "Xã Vĩnh Trạch Đông",
    LevelUp: "Xã",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10459,
    Code: "31840",
    Name: "Xã Hiệp Thành",
    LevelUp: "Xã",
    DistrictCode: "6001",
    Active: -1,
  },
  {
    ID: 10460,
    Code: "31843",
    Name: "Thị trấn Ngan Dừa",
    LevelUp: "Thị trấn",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10461,
    Code: "31846",
    Name: "Xã Ninh Quới",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10462,
    Code: "31849",
    Name: "Xã Ninh Quới A",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10463,
    Code: "31852",
    Name: "Xã Ninh Hòa",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10464,
    Code: "31855",
    Name: "Xã Lộc Ninh",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10465,
    Code: "31858",
    Name: "Xã Vĩnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10466,
    Code: "31861",
    Name: "Xã Vĩnh Lộc A",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10467,
    Code: "31863",
    Name: "Xã Ninh Thạnh Lợi A",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10468,
    Code: "31864",
    Name: "Xã Ninh Thạnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "6003",
    Active: -1,
  },
  {
    ID: 10469,
    Code: "31867",
    Name: "Thị trấn Phước Long",
    LevelUp: "Thị trấn",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10470,
    Code: "31870",
    Name: "Xã Vĩnh Phú Đông",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10471,
    Code: "31873",
    Name: "Xã Vĩnh Phú Tây",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10472,
    Code: "31876",
    Name: "Xã Phước Long",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10473,
    Code: "31879",
    Name: "Xã Hưng Phú",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10474,
    Code: "31882",
    Name: "Xã Vĩnh Thanh",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10475,
    Code: "31885",
    Name: "Xã Phong Thạnh Tây A",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10476,
    Code: "31888",
    Name: "Xã Phong Thạnh Tây B",
    LevelUp: "Xã",
    DistrictCode: "6005",
    Active: -1,
  },
  {
    ID: 10477,
    Code: "31894",
    Name: "Xã Vĩnh Hưng",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10478,
    Code: "31897",
    Name: "Xã Vĩnh Hưng A",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10479,
    Code: "31900",
    Name: "Thị trấn Châu Hưng",
    LevelUp: "Thị trấn",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10480,
    Code: "31903",
    Name: "Xã Châu Hưng A",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10481,
    Code: "31906",
    Name: "Xã Hưng Thành",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10482,
    Code: "31909",
    Name: "Xã Hưng Hội",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10483,
    Code: "31912",
    Name: "Xã Châu Thới",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10484,
    Code: "31921",
    Name: "Xã Long Thạnh",
    LevelUp: "Xã",
    DistrictCode: "6002",
    Active: -1,
  },
  {
    ID: 10485,
    Code: "31942",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10486,
    Code: "31945",
    Name: "Phường Hộ Phòng",
    LevelUp: "Phường",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10487,
    Code: "31948",
    Name: "Xã Phong Thạnh Đông",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10488,
    Code: "31951",
    Name: "Phường Láng Tròn",
    LevelUp: "Phường",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10489,
    Code: "31954",
    Name: "Xã Phong Tân",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10490,
    Code: "31957",
    Name: "Xã Tân Phong",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10491,
    Code: "31960",
    Name: "Xã Phong Thạnh",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10492,
    Code: "31963",
    Name: "Xã Phong Thạnh A",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10493,
    Code: "31966",
    Name: "Xã Phong Thạnh Tây",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10494,
    Code: "31969",
    Name: "Xã Tân Thạnh",
    LevelUp: "Xã",
    DistrictCode: "6004",
    Active: -1,
  },
  {
    ID: 10495,
    Code: "31972",
    Name: "Thị trấn Gành Hào",
    LevelUp: "Thị trấn",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10496,
    Code: "31975",
    Name: "Xã Long Điền Đông",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10497,
    Code: "31978",
    Name: "Xã Long Điền Đông A",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10498,
    Code: "31981",
    Name: "Xã Long Điền",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10499,
    Code: "31984",
    Name: "Xã Long Điền Tây",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10500,
    Code: "31985",
    Name: "Xã Điền Hải",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10501,
    Code: "31987",
    Name: "Xã An Trạch",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10502,
    Code: "31988",
    Name: "Xã An Trạch A",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10503,
    Code: "31990",
    Name: "Xã An Phúc",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10504,
    Code: "31993",
    Name: "Xã Định Thành",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10505,
    Code: "31996",
    Name: "Xã Định Thành A",
    LevelUp: "Xã",
    DistrictCode: "6006",
    Active: -1,
  },
  {
    ID: 10506,
    Code: "31891",
    Name: "Thị trấn Hòa Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10507,
    Code: "31915",
    Name: "Xã Minh Diệu",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10508,
    Code: "31918",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10509,
    Code: "31924",
    Name: "Xã Vĩnh Mỹ B",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10510,
    Code: "31927",
    Name: "Xã Vĩnh Hậu",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10511,
    Code: "31930",
    Name: "Xã Vĩnh Hậu A",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10512,
    Code: "31933",
    Name: "Xã Vĩnh Mỹ A",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10513,
    Code: "31936",
    Name: "Xã Vĩnh Thịnh",
    LevelUp: "Xã",
    DistrictCode: "6007",
    Active: -1,
  },
  {
    ID: 10514,
    Code: "31999",
    Name: "Phường 9",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10515,
    Code: "32002",
    Name: "Phường 4",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10516,
    Code: "32005",
    Name: "Phường 1",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10517,
    Code: "32008",
    Name: "Phường 5",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10518,
    Code: "32011",
    Name: "Phường 2",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10519,
    Code: "32014",
    Name: "Phường 8",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10520,
    Code: "32017",
    Name: "Phường 6",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10521,
    Code: "32020",
    Name: "Phường 7",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10522,
    Code: "32022",
    Name: "Phường Tân Xuyên",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10523,
    Code: "32023",
    Name: "Xã An Xuyên",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10524,
    Code: "32025",
    Name: "Phường Tân Thành",
    LevelUp: "Phường",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10525,
    Code: "32026",
    Name: "Xã Tân Thành",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10526,
    Code: "32029",
    Name: "Xã Tắc Vân",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10527,
    Code: "32032",
    Name: "Xã Lý Văn Lâm",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10528,
    Code: "32035",
    Name: "Xã Định Bình",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10529,
    Code: "32038",
    Name: "Xã Hòa Thành",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10530,
    Code: "32041",
    Name: "Xã Hòa Tân",
    LevelUp: "Xã",
    DistrictCode: "6101",
    Active: -1,
  },
  {
    ID: 10531,
    Code: "32044",
    Name: "Thị trấn U Minh",
    LevelUp: "Thị trấn",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10532,
    Code: "32047",
    Name: "Xã Khánh Hòa",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10533,
    Code: "32048",
    Name: "Xã Khánh Thuận",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10534,
    Code: "32050",
    Name: "Xã Khánh Tiến",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10535,
    Code: "32053",
    Name: "Xã Nguyễn Phích",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10536,
    Code: "32056",
    Name: "Xã Khánh Lâm",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10537,
    Code: "32059",
    Name: "Xã Khánh An",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10538,
    Code: "32062",
    Name: "Xã Khánh Hội",
    LevelUp: "Xã",
    DistrictCode: "6103",
    Active: -1,
  },
  {
    ID: 10539,
    Code: "32065",
    Name: "Thị trấn Thới Bình",
    LevelUp: "Thị trấn",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10540,
    Code: "32068",
    Name: "Xã Biển Bạch",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10541,
    Code: "32069",
    Name: "Xã Tân Bằng",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10542,
    Code: "32071",
    Name: "Xã Trí Phải",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10543,
    Code: "32072",
    Name: "Xã Trí Lực",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10544,
    Code: "32074",
    Name: "Xã Biển Bạch Đông",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10545,
    Code: "32077",
    Name: "Xã Thới Bình",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10546,
    Code: "32080",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10547,
    Code: "32083",
    Name: "Xã Tân Lộc Bắc",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10548,
    Code: "32086",
    Name: "Xã Tân Lộc",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10549,
    Code: "32089",
    Name: "Xã Tân Lộc Đông",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10550,
    Code: "32092",
    Name: "Xã Hồ Thị Kỷ",
    LevelUp: "Xã",
    DistrictCode: "6102",
    Active: -1,
  },
  {
    ID: 10551,
    Code: "32095",
    Name: "Thị trấn Trần Văn Thời",
    LevelUp: "Thị trấn",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10552,
    Code: "32098",
    Name: "Thị trấn Sông Đốc",
    LevelUp: "Thị trấn",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10553,
    Code: "32101",
    Name: "Xã Khánh Bình Tây Bắc",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10554,
    Code: "32104",
    Name: "Xã Khánh Bình Tây",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10555,
    Code: "32107",
    Name: "Xã Trần Hợi",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10556,
    Code: "32108",
    Name: "Xã Khánh Lộc",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10557,
    Code: "32110",
    Name: "Xã Khánh Bình",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10558,
    Code: "32113",
    Name: "Xã Khánh Hưng",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10559,
    Code: "32116",
    Name: "Xã Khánh Bình Đông",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10560,
    Code: "32119",
    Name: "Xã Khánh Hải",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10561,
    Code: "32122",
    Name: "Xã Lợi An",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10562,
    Code: "32124",
    Name: "Xã Phong Điền",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10563,
    Code: "32125",
    Name: "Xã Phong Lạc",
    LevelUp: "Xã",
    DistrictCode: "6104",
    Active: -1,
  },
  {
    ID: 10564,
    Code: "32128",
    Name: "Thị trấn Cái Nước",
    LevelUp: "Thị trấn",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10565,
    Code: "32130",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10566,
    Code: "32131",
    Name: "Xã Lương Thế Trân",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10567,
    Code: "32134",
    Name: "Xã Phú Hưng",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10568,
    Code: "32137",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10569,
    Code: "32140",
    Name: "Xã Hưng Mỹ",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10570,
    Code: "32141",
    Name: "Xã Hoà Mỹ",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10571,
    Code: "32142",
    Name: "Xã Đông Hưng",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10572,
    Code: "32143",
    Name: "Xã Đông Thới",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10573,
    Code: "32146",
    Name: "Xã Tân Hưng Đông",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10574,
    Code: "32149",
    Name: "Xã Trần Thới",
    LevelUp: "Xã",
    DistrictCode: "6105",
    Active: -1,
  },
  {
    ID: 10575,
    Code: "32152",
    Name: "Thị trấn Đầm Dơi",
    LevelUp: "Thị trấn",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10576,
    Code: "32155",
    Name: "Xã Tạ An Khương",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10577,
    Code: "32158",
    Name: "Xã Tạ An Khương  Đông",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10578,
    Code: "32161",
    Name: "Xã Trần Phán",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10579,
    Code: "32162",
    Name: "Xã Tân Trung",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10580,
    Code: "32164",
    Name: "Xã Tân Đức",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10581,
    Code: "32167",
    Name: "Xã Tân Thuận",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10582,
    Code: "32170",
    Name: "Xã Tạ An Khương  Nam",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10583,
    Code: "32173",
    Name: "Xã Tân Duyệt",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10584,
    Code: "32174",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10585,
    Code: "32176",
    Name: "Xã Tân Tiến",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10586,
    Code: "32179",
    Name: "Xã Quách Phẩm Bắc",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10587,
    Code: "32182",
    Name: "Xã Quách Phẩm",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10588,
    Code: "32185",
    Name: "Xã Thanh Tùng",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10589,
    Code: "32186",
    Name: "Xã Ngọc Chánh",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10590,
    Code: "32188",
    Name: "Xã Nguyễn Huân",
    LevelUp: "Xã",
    DistrictCode: "6106",
    Active: -1,
  },
  {
    ID: 10591,
    Code: "32191",
    Name: "Thị Trấn Năm Căn",
    LevelUp: "Thị trấn",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10592,
    Code: "32194",
    Name: "Xã Hàm Rồng",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10593,
    Code: "32197",
    Name: "Xã Hiệp Tùng",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10594,
    Code: "32200",
    Name: "Xã Đất Mới",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10595,
    Code: "32201",
    Name: "Xã Lâm Hải",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10596,
    Code: "32203",
    Name: "Xã Hàng Vịnh",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10597,
    Code: "32206",
    Name: "Xã Tam Giang",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10598,
    Code: "32209",
    Name: "Xã Tam Giang Đông",
    LevelUp: "Xã",
    DistrictCode: "6108",
    Active: -1,
  },
  {
    ID: 10600,
    Code: "32214",
    Name: "Xã Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10601,
    Code: "32215",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10602,
    Code: "32218",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10603,
    Code: "32221",
    Name: "Xã Tân Hải",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10604,
    Code: "32224",
    Name: "Xã Việt Thắng",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10605,
    Code: "32227",
    Name: "Xã Tân Hưng Tây",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10606,
    Code: "32228",
    Name: "Xã Rạch Chèo",
    LevelUp: "Xã",
    DistrictCode: "5105",
    Active: -1,
  },
  {
    ID: 10608,
    Code: "32233",
    Name: "Xã Tam Giang Tây",
    LevelUp: "Xã",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10609,
    Code: "32236",
    Name: "Xã Tân Ân Tây",
    LevelUp: "Xã",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10610,
    Code: "32239",
    Name: "Xã Viên An Đông",
    LevelUp: "Xã",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10611,
    Code: "32242",
    Name: "Xã Viên An",
    LevelUp: "Xã",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10612,
    Code: "32244",
    Name: "Thị trấn Rạch Gốc",
    LevelUp: "Thị trấn",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10613,
    Code: "32245",
    Name: "Xã Tân Ân",
    LevelUp: "Xã",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10614,
    Code: "32248",
    Name: "Xã Đất Mũi",
    LevelUp: "Xã",
    DistrictCode: "6107",
    Active: -1,
  },
  {
    ID: 10615,
    Code: "32249",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10616,
    Code: "32250",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "1B28",
    Active: -1,
  },
  {
    ID: 10617,
    Code: "32251",
    Name: "Thị Trấn Châu Thành",
    LevelUp: "Thị Trấn",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10618,
    Code: "32252",
    Name: "Xã Phú Tâm",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10619,
    Code: "32253",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10620,
    Code: "32254",
    Name: "Xã Thuận Hòa",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10621,
    Code: "32255",
    Name: "Xã Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10622,
    Code: "32256",
    Name: "Xã Thiện Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10623,
    Code: "32257",
    Name: "Xã Hồ Đắc Kiện",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10624,
    Code: "32258",
    Name: "Thị Trấn An Châu",
    LevelUp: "Thị Trấn",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10625,
    Code: "32259",
    Name: "Xã An Hoà",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10626,
    Code: "32260",
    Name: "Xã Cần Đăng",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10627,
    Code: "32261",
    Name: "Xã Vĩnh Hanh",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10628,
    Code: "32262",
    Name: "Xã Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10629,
    Code: "32263",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10630,
    Code: "32264",
    Name: "Xã Bình Hoà",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10631,
    Code: "32265",
    Name: "Xã Vình An",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10632,
    Code: "32266",
    Name: "Xã Hoà Bình Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10633,
    Code: "32267",
    Name: "Xã Vĩnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10634,
    Code: "32268",
    Name: "Xã Vĩnh Nhuận",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10635,
    Code: "32269",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10636,
    Code: "32270",
    Name: "Xã Vĩnh Thành",
    LevelUp: "Xã",
    DistrictCode: "5110",
    Active: -1,
  },
  {
    ID: 10637,
    Code: "32271",
    Name: "Thị Trấn Chợ Mới",
    LevelUp: "Thị Trấn",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10638,
    Code: "32272",
    Name: "Xã Bình Phước Xuân",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10639,
    Code: "32273",
    Name: "Xã An Thạnh Trung",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10640,
    Code: "32274",
    Name: "Xã Hội An",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10641,
    Code: "32275",
    Name: "Xã Hoà Bình",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10642,
    Code: "32276",
    Name: "Xã Hoà An",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10643,
    Code: "32277",
    Name: "Thị Trấn Mỹ Luông",
    LevelUp: "Thị Trấn",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10644,
    Code: "32278",
    Name: "Xã Kiến An",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10645,
    Code: "32279",
    Name: "Xã Mỹ Hội Đông",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10646,
    Code: "32280",
    Name: "Xã Long Điền A",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10647,
    Code: "32281",
    Name: "Xã Tấn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10648,
    Code: "32282",
    Name: "Xã Long Điền B",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10649,
    Code: "32283",
    Name: "Xã Kiến Thành",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10650,
    Code: "32284",
    Name: "Xã Mỹ Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10651,
    Code: "32285",
    Name: "Xã Mỹ An",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10652,
    Code: "32286",
    Name: "Xã Nhơn Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10653,
    Code: "32287",
    Name: "Xã Long Giang",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10654,
    Code: "32288",
    Name: "Xã Long Kiến",
    LevelUp: "Xã",
    DistrictCode: "5109",
    Active: -1,
  },
  {
    ID: 10655,
    Code: "32289",
    Name: "Xã Quảng Thắng",
    LevelUp: "Xã",
    DistrictCode: "1707",
    Active: -1,
  },
  {
    ID: 10656,
    Code: "32290",
    Name: "Phường Bùi Thị Xuân",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 10657,
    Code: "32291",
    Name: "Xã Nghĩa Hồ",
    LevelUp: "Xã",
    DistrictCode: "1803",
    Active: -1,
  },
  {
    ID: 10658,
    Code: "32292",
    Name: "Thị Trấn Vĩnh Thạnh",
    LevelUp: "Thị Trấn",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10659,
    Code: "32293",
    Name: "Xã Thạnh Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10660,
    Code: "32294",
    Name: "Xã Thạnh Quới",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10661,
    Code: "32295",
    Name: "Xã Thạnh An",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10662,
    Code: "32296",
    Name: "Xã Thạnh Lợi",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10663,
    Code: "32297",
    Name: "Xã Thạnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10664,
    Code: "32298",
    Name: "Thị Trấn Thạnh An",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10665,
    Code: "32299",
    Name: "Xã Vĩnh Trinh",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10666,
    Code: "32300",
    Name: "Xã Vĩnh Bình",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10667,
    Code: "32301",
    Name: "Xã Thạnh Tiến",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10668,
    Code: "32302",
    Name: "Xã Thạnh Thắng",
    LevelUp: "Xã",
    DistrictCode: "5507",
    Active: -1,
  },
  {
    ID: 10669,
    Code: "32303",
    Name: "Xã Thái Tân",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10670,
    Code: "32304",
    Name: "Xã Thái Thành",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10671,
    Code: "32305",
    Name: "Xã Thái Thuần",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10672,
    Code: "32306",
    Name: "Xã Thái Thủy",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10673,
    Code: "32307",
    Name: "Xã Thụy An",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10674,
    Code: "32308",
    Name: "Xã Thụy Dũng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10675,
    Code: "32309",
    Name: "Xã Thụy Dương",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10676,
    Code: "32310",
    Name: "Xã Thụy Hà",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10677,
    Code: "32311",
    Name: "Xã Hồng Quỳnh",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10678,
    Code: "32312",
    Name: "Xã Mỹ Lộc",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10679,
    Code: "32313",
    Name: "Xã Thái An",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10680,
    Code: "32314",
    Name: "Xã Thái Dương",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10681,
    Code: "32315",
    Name: "Xã Thái Hà",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10682,
    Code: "32316",
    Name: "Xã Thái Học",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10683,
    Code: "32317",
    Name: "Xã Thái Hồng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10684,
    Code: "32318",
    Name: "Xã Thái Sơn",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10685,
    Code: "32319",
    Name: "Xã Thụy Hồng",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10686,
    Code: "32320",
    Name: "Xã Thụy Lương",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10687,
    Code: "32321",
    Name: "Xã Thụy Phúc",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10688,
    Code: "32322",
    Name: "Xã Thụy Tân",
    LevelUp: "Xã",
    DistrictCode: "2608",
    Active: -1,
  },
  {
    ID: 10689,
    Code: "32323",
    Name: "Phường Ngọc Hải",
    LevelUp: "Phường",
    DistrictCode: "0306",
    Active: -1,
  },
  {
    ID: 10690,
    Code: "32325",
    Name: "Thị Trấn Côn Đảo",
    LevelUp: "Thị trấn",
    DistrictCode: "5205",
    Active: -1,
  },
  {
    ID: 10691,
    Code: "32326",
    Name: "Thị Trấn Tĩnh Gia",
    LevelUp: "Thị trấn",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10692,
    Code: "32327",
    Name: "Xã Hải An",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10693,
    Code: "32328",
    Name: "Xã Nimh Hải",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10694,
    Code: "32329",
    Name: "Xã Hải Ninh",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10695,
    Code: "32330",
    Name: "Xã Trúc Lâm",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10696,
    Code: "32331",
    Name: "Xã Xuân Lâm",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10697,
    Code: "32332",
    Name: "Thị Trấn Còng",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10698,
    Code: "32333",
    Name: "Xã Tùng Lâm",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10699,
    Code: "32334",
    Name: "Xã Hùng Sơn",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10700,
    Code: "32335",
    Name: "Xã Tân Dân",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10701,
    Code: "32336",
    Name: "Xã Nghi Sơn",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10702,
    Code: "32337",
    Name: "Xã Hải Thượng",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10703,
    Code: "32338",
    Name: "Xã Định Hải",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10704,
    Code: "32339",
    Name: "Xã Hải Nhân",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10705,
    Code: "32340",
    Name: "Xã Nguyên Bình",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10706,
    Code: "32341",
    Name: "Xã Phú Sơn",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10707,
    Code: "32342",
    Name: "Xã Phú Lâm",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10708,
    Code: "32343",
    Name: "Xã Tân Trường",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10709,
    Code: "32344",
    Name: "Xã Trường Lâm",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10710,
    Code: "32345",
    Name: "Xã Mai Lâm",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10711,
    Code: "32346",
    Name: "Xã Hải Hà",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10712,
    Code: "32347",
    Name: "Xã Hải Yến",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10713,
    Code: "32348",
    Name: "Xã Hải Bình",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10714,
    Code: "32349",
    Name: "Xã Tĩnh Hải",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10715,
    Code: "32350",
    Name: "Xã Thanh Thủy",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10716,
    Code: "32351",
    Name: "Xã Hải Châu",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10717,
    Code: "32352",
    Name: "Xã Thanh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10718,
    Code: "32353",
    Name: "Xã Anh Sơn",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10719,
    Code: "32354",
    Name: "Xã Các Sơn",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10720,
    Code: "32355",
    Name: "Xã Ngọc Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10721,
    Code: "32356",
    Name: "Xã Triệu Dương",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10722,
    Code: "32357",
    Name: "Xã Hải Lĩnh",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10723,
    Code: "32358",
    Name: "Xã Hải Hòa",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10724,
    Code: "32359",
    Name: "Xã Bình Minh",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10725,
    Code: "32360",
    Name: "Xã Hải Thanh",
    LevelUp: "Xã",
    DistrictCode: "2826",
    Active: -1,
  },
  {
    ID: 10726,
    Code: "32361",
    Name: "Thị Trấn Châu Thành",
    LevelUp: "Thị Trấn",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10727,
    Code: "32362",
    Name: "Xã An Khánh",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10728,
    Code: "32363",
    Name: "Xã Thành Triệu",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10729,
    Code: "32364",
    Name: "Xã Phú Đức",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10730,
    Code: "32365",
    Name: "Xã Phú Túc",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10731,
    Code: "32366",
    Name: "Xã Tường Đa",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10732,
    Code: "32367",
    Name: "Xã Tam Phước",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10733,
    Code: "32368",
    Name: "Xã Phước Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10734,
    Code: "32369",
    Name: "Xã Hữu Định",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10735,
    Code: "32370",
    Name: "Xã Tân Thạch",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10736,
    Code: "32371",
    Name: "Xã An Phước",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10737,
    Code: "32372",
    Name: "Xã Giao Long",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10738,
    Code: "32373",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10739,
    Code: "32374",
    Name: "Xã Quới Sơn",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10740,
    Code: "32375",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10741,
    Code: "32376",
    Name: "Xã Mỹ Thành",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10742,
    Code: "32377",
    Name: "Xã Phú An Hòa",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10743,
    Code: "32378",
    Name: "Xã An Hóa",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10744,
    Code: "32379",
    Name: "Xã Giao Hòa",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10745,
    Code: "32380",
    Name: "Xã Tiên Long",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10746,
    Code: "32381",
    Name: "Xã Tiên Thủy",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10747,
    Code: "32382",
    Name: "Xã Quới Thành",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10748,
    Code: "32383",
    Name: "Xã Sơn Hòa",
    LevelUp: "Xã",
    DistrictCode: "5602",
    Active: -1,
  },
  {
    ID: 10749,
    Code: "32383",
    Name: "Xã Long Trị",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10750,
    Code: "32384",
    Name: "Xã Long Trị A",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10751,
    Code: "32385",
    Name: "Xã Vĩnh Viễn",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10752,
    Code: "32386",
    Name: "Xã Xà Phiên",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10753,
    Code: "32387",
    Name: "Xã Lương Tâm",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10754,
    Code: "32388",
    Name: "Xã Long Phú",
    LevelUp: "Xã",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10755,
    Code: "32389",
    Name: "Thị Trấn Thanh Nê",
    LevelUp: "Thị Trấn",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10756,
    Code: "32390",
    Name: "Xã An Bồi",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10757,
    Code: "32391",
    Name: "Xã Minh Hưng",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10758,
    Code: "32392",
    Name: "Xã Nữ Trung",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10759,
    Code: "32393",
    Name: "Xã Quang Hưng",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10760,
    Code: "32394",
    Name: "Xã Quyết Tiến",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10761,
    Code: "32395",
    Name: "Xã Tán Thuật",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10762,
    Code: "32396",
    Name: "Xã Vũ Đông",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10763,
    Code: "32397",
    Name: "Xã Vũ Lạc",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10764,
    Code: "32398",
    Name: "Xã Vũ Sơn",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10765,
    Code: "32399",
    Name: "Xã Vũ Tây",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10766,
    Code: "32400",
    Name: "Xã Vũ Thúy",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10767,
    Code: "32401",
    Name: "Xã Ngọc Tiến",
    LevelUp: "Xã",
    DistrictCode: "1B25",
    Active: -1,
  },
  {
    ID: 10768,
    Code: "32402",
    Name: "Xã Bình Lãng",
    LevelUp: "Xã",
    DistrictCode: "2106",
    Active: -1,
  },
  {
    ID: 10769,
    Code: "32403",
    Name: "Xã Tây Sơn",
    LevelUp: "Xã",
    DistrictCode: "2606",
    Active: -1,
  },
  {
    ID: 10770,
    Code: "32404",
    Name: "Thị Trấn Cái Tàu Hạ",
    LevelUp: "Thị trấn",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10771,
    Code: "32405",
    Name: "Xã An Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10772,
    Code: "32406",
    Name: "Xã An Nhơn",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10773,
    Code: "32407",
    Name: "Xã Tân Nhuận Đông",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10774,
    Code: "32408",
    Name: "Xã Tân Bình",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10775,
    Code: "32409",
    Name: "Xã Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10776,
    Code: "32410",
    Name: "Xã Phú Long",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10777,
    Code: "32411",
    Name: "Xã Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10778,
    Code: "32412",
    Name: "Xã Phú Hựu",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10779,
    Code: "32413",
    Name: "Xã An Khánh",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10780,
    Code: "32414",
    Name: "Xã Tân Phú",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10781,
    Code: "32415",
    Name: "Xã Hòa Tân",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10782,
    Code: "32416",
    Name: "Xã Tân Công Sính",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10783,
    Code: "32417",
    Name: "Xã Phú Đức",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10784,
    Code: "32418",
    Name: "Xã Phú Thành B",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10785,
    Code: "32419",
    Name: "Xã Phú Thành A",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10786,
    Code: "32420",
    Name: "Xã An Hòa",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10787,
    Code: "32421",
    Name: "Xã An Long",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10788,
    Code: "32422",
    Name: "Xã Phú Cường",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10789,
    Code: "32423",
    Name: "Xã Phú Ninh",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10790,
    Code: "32424",
    Name: "Xã Phú Thọ",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10791,
    Code: "32425",
    Name: "Xã Phú Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10792,
    Code: "32426",
    Name: "Thị trấn Tràm Chim",
    LevelUp: "Thị trấn",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10793,
    Code: "32427",
    Name: "Xã Hòa Bình",
    LevelUp: "Xã",
    DistrictCode: "5005",
    Active: -1,
  },
  {
    ID: 10794,
    Code: "32428",
    Name: "Xã Hoàng Hanh",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10795,
    Code: "32429",
    Name: "Xã Hồng Thái",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10796,
    Code: "32430",
    Name: "Xã Hưng Thái",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10797,
    Code: "32431",
    Name: "Xã Ninh Hòa",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10798,
    Code: "32432",
    Name: "Xã Ninh Thành",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10799,
    Code: "32433",
    Name: "Xã Ninh Thọ",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10800,
    Code: "32434",
    Name: "Xã Quang Hưng",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10801,
    Code: "32435",
    Name: "Xã Quyết Thắng",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10802,
    Code: "32436",
    Name: "Xã Văn Giang",
    LevelUp: "Xã",
    DistrictCode: "2108",
    Active: -1,
  },
  {
    ID: 10803,
    Code: "32437",
    Name: "Xã Đồng Gia",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10804,
    Code: "32438",
    Name: "Xã Kim Khê",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10805,
    Code: "32439",
    Name: "Xã Kim Lương",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10806,
    Code: "32440",
    Name: "Xã Kỳ Côi",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10807,
    Code: "32441",
    Name: "Xã Tuấn Hưng",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10808,
    Code: "32442",
    Name: "Xã Việt Hưng",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10809,
    Code: "32443",
    Name: "Xã Cẩm La",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10810,
    Code: "32444",
    Name: "Xã Phạm Xá",
    LevelUp: "Xã",
    DistrictCode: "2111",
    Active: -1,
  },
  {
    ID: 10811,
    Code: "32445",
    Name: "Xã Mong Thọ A",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10812,
    Code: "32446",
    Name: "Xã Thạnh Lộc",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10813,
    Code: "32447",
    Name: "Xã Mong Thọ B",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10814,
    Code: "32448",
    Name: "Tt Minh Lương",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10815,
    Code: "32449",
    Name: "Xã Bình An",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10816,
    Code: "32450",
    Name: "Xã Minh Hòa",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10817,
    Code: "32451",
    Name: "Xã Vĩnh Hòa Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10818,
    Code: "32452",
    Name: "Kcn Cảng Cá Tắc Cậu",
    LevelUp: "KCN",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10819,
    Code: "32453",
    Name: "Xã Giục Tượng",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10820,
    Code: "32454",
    Name: "Xã Vĩnh Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10821,
    Code: "32455",
    Name: "Xã Mong Thọ",
    LevelUp: "Xã",
    DistrictCode: "5406",
    Active: -1,
  },
  {
    ID: 10822,
    Code: "32456",
    Name: "Xã Hiệp Thạnh",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10823,
    Code: "32457",
    Name: "Xã Phước Tân Hưng",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10824,
    Code: "32458",
    Name: "Xã Dương Xuân Hội",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10825,
    Code: "32459",
    Name: "Xã Long Trì",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10826,
    Code: "32460",
    Name: "Xã An Lục Long",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10827,
    Code: "32461",
    Name: "Xã Thanh Phú Long",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10828,
    Code: "32462",
    Name: "Xã Thanh Vĩnh Đông",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10829,
    Code: "32463",
    Name: "Xã Thuận Mỹ",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10830,
    Code: "32464",
    Name: "Thị Trấn Tầm Vu",
    LevelUp: "Thị trấn",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10831,
    Code: "32465",
    Name: "Xã Bình Quới",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10832,
    Code: "32466",
    Name: "Xã Hòa Phú",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10833,
    Code: "32467",
    Name: "Xã Vĩnh Công",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10834,
    Code: "32468",
    Name: "Xã Phú Ngãi Trị",
    LevelUp: "Xã",
    DistrictCode: "4910",
    Active: -1,
  },
  {
    ID: 10835,
    Code: "32469",
    Name: "Thị Trấn Tân Hiệp",
    LevelUp: "Thị trấn",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10836,
    Code: "32470",
    Name: "Xã Dưỡng Điểm",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10837,
    Code: "32471",
    Name: "Xã Vĩnh Kim",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10838,
    Code: "32472",
    Name: "Xã Kim Sơn",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10839,
    Code: "32473",
    Name: "Xã Điềm Hy",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10840,
    Code: "32474",
    Name: "Xã Nhị Bình",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10841,
    Code: "32475",
    Name: "Xã Tam Hiệp",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10842,
    Code: "32476",
    Name: "Xã Thân Cửu Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10843,
    Code: "32477",
    Name: "Xã Long Định",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10844,
    Code: "32478",
    Name: "Xã Thới Sơn",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10845,
    Code: "32479",
    Name: "Xã Bàn Long",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10846,
    Code: "32480",
    Name: "Xã Song Thuận",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10847,
    Code: "32481",
    Name: "Xã Tân Lý Đông",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10848,
    Code: "32482",
    Name: "Xã Tân Lý Tây",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10849,
    Code: "32483",
    Name: "Xã Long An",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10850,
    Code: "32484",
    Name: "Xã Tân Hương",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10851,
    Code: "32485",
    Name: "Xã Thạnh Phú",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10852,
    Code: "32486",
    Name: "Xã Bình Đức",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10853,
    Code: "32487",
    Name: "Xã Đông Hòa",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10854,
    Code: "32488",
    Name: "Xã Phước Thạnh",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10855,
    Code: "32489",
    Name: "Xã Bình Trưng",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10856,
    Code: "32490",
    Name: "Xã Tân Hội Đông",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10857,
    Code: "32491",
    Name: "Xã Hữu Đạo",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10858,
    Code: "32492",
    Name: "Xã Long Hưng",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10859,
    Code: "32493",
    Name: "Xã Phú Phong",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10860,
    Code: "32494",
    Name: "Phường Đồng Mỹ",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 10861,
    Code: "32495",
    Name: "Phường Đồng Sơn (Tiểu Khu 1 - 7)",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 10862,
    Code: "32496",
    Name: "Phường Hải Đình",
    LevelUp: "Phường",
    DistrictCode: "3101",
    Active: -1,
  },
  {
    ID: 10863,
    Code: "32497",
    Name: "Xã Thạch Môn",
    LevelUp: "Xã",
    DistrictCode: "3001",
    Active: -1,
  },
  {
    ID: 10864,
    Code: "32498",
    Name: "Xã Đồng Lý",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 10865,
    Code: "32499",
    Name: "Xã Nhân Đạo",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 10866,
    Code: "32500",
    Name: "Xã Nhân Hưng",
    LevelUp: "Xã",
    DistrictCode: "2404",
    Active: -1,
  },
  {
    ID: 10870,
    Code: "32502",
    Name: "Thị trấn Vạn Hà",
    LevelUp: "Thị trấn",
    DistrictCode: "2817",
    Active: -1,
  },
  {
    ID: 10871,
    Code: "32503",
    Name: "Xã Thạch Điền",
    LevelUp: "Xã",
    DistrictCode: "3008",
    Active: -1,
  },
  {
    ID: 10872,
    Code: "32504",
    Name: "Xã Giai Xuân",
    LevelUp: "Xã",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10873,
    Code: "32505",
    Name: "Thị trấn Mái Dầm",
    LevelUp: "Thị trấn",
    DistrictCode: "6405",
    Active: -1,
  },
  {
    ID: 10874,
    Code: "32506",
    Name: "Xã Đông Giang",
    LevelUp: "Xã",
    DistrictCode: "2604",
    Active: -1,
  },
  {
    ID: 10875,
    Code: "32507",
    Name: "Phường Thuận An",
    LevelUp: "Phường",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10877,
    Code: "32509",
    Name: "Xã Cẩm Vịnh",
    LevelUp: "Xã",
    DistrictCode: "3009",
    Active: -1,
  },
  {
    ID: 10878,
    Code: "32510",
    Name: "Xã Cổ Tiết",
    LevelUp: "Xã",
    DistrictCode: "1511",
    Active: -1,
  },
  {
    ID: 10879,
    Code: "32511",
    Name: "Xã Đông Phước A",
    LevelUp: "Xã",
    DistrictCode: "5305",
    Active: -1,
  },
  {
    ID: 10880,
    Code: "32512",
    Name: "Thị trấn Sông Thao",
    LevelUp: "Thị trấn",
    DistrictCode: "1506",
    Active: -1,
  },
  {
    ID: 10881,
    Code: "32513",
    Name: "Xã Phú Hữu",
    LevelUp: "Xã",
    DistrictCode: "6405",
    Active: -1,
  },
  {
    ID: 10883,
    Code: "32514",
    Name: "Xã Vĩnh Thạch",
    LevelUp: "Xã",
    DistrictCode: "3203",
    Active: -1,
  },
  {
    ID: 10884,
    Code: "32515",
    Name: "Xã Nhơn Ái",
    LevelUp: "Xã",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10886,
    Code: "32517",
    Name: "Xã Tân Thịnh",
    LevelUp: "Xã",
    DistrictCode: "1808",
    Active: -1,
  },
  {
    ID: 10887,
    Code: "32518",
    Name: "Xã Hưng Thịnh",
    LevelUp: "Xã",
    DistrictCode: "2112",
    Active: -1,
  },
  {
    ID: 10888,
    Code: "32519",
    Name: "Xã Minh Châu",
    LevelUp: "Xã",
    DistrictCode: "2818",
    Active: -1,
  },
  {
    ID: 10889,
    Code: "32520",
    Name: "Xã Tân Thới",
    LevelUp: "Xã",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10890,
    Code: "32521",
    Name: "Xã Nguyệt Hóa",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10892,
    Code: "32522",
    Name: "Xã Hưng Mỹ",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10893,
    Code: "32523",
    Name: "Xã Việt Thắng",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10894,
    Code: "32524",
    Name: "Xã Nguyễn Việt Khái",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10896,
    Code: "32526",
    Name: "Thị trấn Phong Điền",
    LevelUp: "Thị trấn",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10897,
    Code: "32527",
    Name: "Xã Cổ Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "2309",
    Active: -1,
  },
  {
    ID: 10899,
    Code: "32529",
    Name: "Xã Hòa Lợi",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10900,
    Code: "32530",
    Name: "Thị trấn Trới",
    LevelUp: "Thị trấn",
    DistrictCode: "1712",
    Active: -1,
  },
  {
    ID: 10901,
    Code: "32531",
    Name: "Thị trấn Cái Đôi Vàm",
    LevelUp: "Thị trấn",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10902,
    Code: "32532",
    Name: "Thị trấn Phồn Xương",
    LevelUp: "Thị trấn",
    DistrictCode: "1802",
    Active: -1,
  },
  {
    ID: 10903,
    Code: "32533",
    Name: "Phường Phạm Thái",
    LevelUp: "Phường",
    DistrictCode: "2104",
    Active: -1,
  },
  {
    ID: 10904,
    Code: "32534",
    Name: "Xã Quan Hồ Thẩn",
    LevelUp: "Xã",
    DistrictCode: "0802",
    Active: -1,
  },
  {
    ID: 10905,
    Code: "32535",
    Name: "Phường Trường Thịnh",
    LevelUp: "Phường",
    DistrictCode: "1502",
    Active: -1,
  },
  {
    ID: 10940,
    Code: "32536",
    Name: "Xã Kỳ Bắc",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10941,
    Code: "32537",
    Name: "Xã Kỳ Châu",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10942,
    Code: "32538",
    Name: "Xã Kỳ Hải",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10943,
    Code: "32539",
    Name: "Xã Kỳ Lạc",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10944,
    Code: "32540",
    Name: "Xã Kỳ Sơn",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10945,
    Code: "32541",
    Name: "Xã Kỳ Tân",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10946,
    Code: "32542",
    Name: "Xã Kỳ Thư",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10947,
    Code: "32543",
    Name: "Xã Kỳ Thượng",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10948,
    Code: "32544",
    Name: "Xã Lâm Hợp",
    LevelUp: "Xã",
    DistrictCode: "3013",
    Active: -1,
  },
  {
    ID: 10949,
    Code: "32545",
    Name: "Xã Mỹ Khánh",
    LevelUp: "Xã",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10950,
    Code: "32546",
    Name: "Xã Nhơn Nghĩa",
    LevelUp: "Xã",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10951,
    Code: "32547",
    Name: "Xã Trường Long",
    LevelUp: "Xã",
    DistrictCode: "5505",
    Active: -1,
  },
  {
    ID: 10952,
    Code: "32548",
    Name: "Xã Đông Thạnh",
    LevelUp: "Xã",
    DistrictCode: "6405",
    Active: -1,
  },
  {
    ID: 10953,
    Code: "32549",
    Name: "Xã Phú Mỹ",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10954,
    Code: "32550",
    Name: "Xã Phước Tân",
    LevelUp: "Xã",
    DistrictCode: "4808",
    Active: -1,
  },
  {
    ID: 10955,
    Code: "32551",
    Name: "Xã Đông Phước A",
    LevelUp: "Xã",
    DistrictCode: "6405",
    Active: -1,
  },
  {
    ID: 10956,
    Code: "32552",
    Name: "Thị trấn Phú Mỹ",
    LevelUp: "Thị trấn",
    DistrictCode: "5206",
    Active: -1,
  },
  {
    ID: 10957,
    Code: "32553",
    Name: "Xã Quỳnh Sơn",
    LevelUp: "Xã",
    DistrictCode: "1005",
    Active: -1,
  },
  {
    ID: 10958,
    Code: "32554",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "6405",
    Active: -1,
  },
  {
    ID: 10959,
    Code: "32555",
    Name: "Xã Mỹ Chánh",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10960,
    Code: "32556",
    Name: "Xã Phước Hảo",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10961,
    Code: "32557",
    Name: "Xã An Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10962,
    Code: "32558",
    Name: "Xã An Phú Thuận",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10963,
    Code: "32559",
    Name: "Xã Văn Lộc",
    LevelUp: "Xã",
    DistrictCode: "2824",
    Active: -1,
  },
  {
    ID: 10964,
    Code: "32560",
    Name: "Xã Thái Yên",
    LevelUp: "Xã",
    DistrictCode: "3004",
    Active: -1,
  },
  {
    ID: 10965,
    Code: "32561",
    Name: "Xã Phú Tân",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10966,
    Code: "32562",
    Name: "Xã An Ninh",
    LevelUp: "Xã",
    DistrictCode: "5910",
    Active: -1,
  },
  {
    ID: 10967,
    Code: "32563",
    Name: "Xã Rạch Chèo",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10968,
    Code: "32564",
    Name: "Xã Tân Hưng Tây",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10969,
    Code: "32565",
    Name: "Xã Nam Giang",
    LevelUp: "Xã",
    DistrictCode: "2815",
    Active: -1,
  },
  {
    ID: 10970,
    Code: "32566",
    Name: "Phường Ngô Thì Nhậm",
    LevelUp: "Phường",
    DistrictCode: "1A03",
    Active: -1,
  },
  {
    ID: 10971,
    Code: "32567",
    Name: "Xã Hòa Minh",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10972,
    Code: "32568",
    Name: "Xã Hòa Thuận",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10973,
    Code: "32569",
    Name: "Xã Tân Phú Trung",
    LevelUp: "Xã",
    DistrictCode: "5011",
    Active: -1,
  },
  {
    ID: 10974,
    Code: "32570",
    Name: "Phường  Vĩnh Tường",
    LevelUp: "Phường",
    DistrictCode: "6403",
    Active: -1,
  },
  {
    ID: 10975,
    Code: "32571",
    Name: "Xã Đa Lộc",
    LevelUp: "Xã",
    DistrictCode: "5805",
    Active: -1,
  },
  {
    ID: 10976,
    Code: "32572",
    Name: "Xã Hắc Dịch",
    LevelUp: "Xã",
    DistrictCode: "5206",
    Active: -1,
  },
  {
    ID: 10977,
    Code: "32573",
    Name: "Xã Tân Hải",
    LevelUp: "Xã",
    DistrictCode: "6109",
    Active: -1,
  },
  {
    ID: 10978,
    Code: "32574",
    Name: "Xã Mỹ Long",
    LevelUp: "Xã",
    DistrictCode: "5007",
    Active: -1,
  },
  {
    ID: 10979,
    Code: "32575",
    Name: "Xã Thắng Cương",
    LevelUp: "Xã",
    DistrictCode: "1810",
    Active: -1,
  },
  {
    ID: 10980,
    Code: "32576",
    Name: "Xã Long Toàn",
    LevelUp: "Xã",
    DistrictCode: "5809",
    Active: -1,
  },
  {
    ID: 10982,
    Code: "32577",
    Name: "Xã Thạch Bằng",
    LevelUp: "Xã",
    DistrictCode: "3012",
    Active: -1,
  },
];
export { DataWard };
