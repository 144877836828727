const DataProvince = [
  {
    ID: 1,
    Code: "01",
    Name: "Hà Nội",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "024",
    Active: -1,
  },
  {
    ID: 2,
    Code: "02",
    Name: "Hồ Chí Minh",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "028",
    Active: -1,
  },
  {
    ID: 3,
    Code: "03",
    Name: "Hải Phòng",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0225",
    Active: -1,
  },
  {
    ID: 4,
    Code: "04",
    Name: "Đà Nẵng",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0236",
    Active: -1,
  },
  {
    ID: 5,
    Code: "05",
    Name: "Hà Giang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0219",
    Active: -1,
  },
  {
    ID: 6,
    Code: "06",
    Name: "Cao Bằng",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0206",
    Active: -1,
  },
  {
    ID: 7,
    Code: "07",
    Name: "Lai Châu",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0213",
    Active: -1,
  },
  {
    ID: 8,
    Code: "08",
    Name: "Lào Cai",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0214",
    Active: -1,
  },
  {
    ID: 9,
    Code: "09",
    Name: "Tuyên Quang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0207",
    Active: -1,
  },
  {
    ID: 10,
    Code: "10",
    Name: "Lạng Sơn",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0205",
    Active: -1,
  },
  {
    ID: 11,
    Code: "11",
    Name: "Bắc Cạn",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0209",
    Active: -1,
  },
  {
    ID: 12,
    Code: "12",
    Name: "Thái Nguyên",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0208",
    Active: -1,
  },
  {
    ID: 13,
    Code: "13",
    Name: "Yên Bái",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0216",
    Active: -1,
  },
  {
    ID: 14,
    Code: "14",
    Name: "Sơn La",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0212",
    Active: -1,
  },
  {
    ID: 15,
    Code: "15",
    Name: "Phú Thọ",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0210",
    Active: -1,
  },
  {
    ID: 16,
    Code: "16",
    Name: "Vĩnh Phúc",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0211",
    Active: -1,
  },
  {
    ID: 17,
    Code: "17",
    Name: "Quảng Ninh",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0203",
    Active: -1,
  },
  {
    ID: 18,
    Code: "18",
    Name: "Bắc Giang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0204",
    Active: -1,
  },
  {
    ID: 19,
    Code: "19",
    Name: "Bắc Ninh",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0222",
    Active: -1,
  },
  {
    ID: 20,
    Code: "21",
    Name: "Hải Dương",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0220",
    Active: -1,
  },
  {
    ID: 21,
    Code: "22",
    Name: "Hưng Yên",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0221",
    Active: -1,
  },
  {
    ID: 22,
    Code: "23",
    Name: "Hòa Bình",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0218",
    Active: -1,
  },
  {
    ID: 23,
    Code: "24",
    Name: "Hà Nam",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0226",
    Active: -1,
  },
  {
    ID: 24,
    Code: "25",
    Name: "Nam Định",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0228",
    Active: -1,
  },
  {
    ID: 25,
    Code: "26",
    Name: "Thái Bình",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0227",
    Active: -1,
  },
  {
    ID: 26,
    Code: "27",
    Name: "Ninh Bình",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0229",
    Active: -1,
  },
  {
    ID: 27,
    Code: "28",
    Name: "Thanh Hóa",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0237",
    Active: -1,
  },
  {
    ID: 28,
    Code: "29",
    Name: "Nghệ An",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0238",
    Active: -1,
  },
  {
    ID: 29,
    Code: "30",
    Name: "Hà Tĩnh",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0239",
    Active: -1,
  },
  {
    ID: 30,
    Code: "31",
    Name: "Quảng Bình",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0232",
    Active: -1,
  },
  {
    ID: 31,
    Code: "32",
    Name: "Quảng Trị",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0233",
    Active: -1,
  },
  {
    ID: 32,
    Code: "33",
    Name: "Thừa Thiên Huế",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0234",
    Active: -1,
  },
  {
    ID: 33,
    Code: "34",
    Name: "Quảng Nam",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0235",
    Active: -1,
  },
  {
    ID: 34,
    Code: "35",
    Name: "Quảng Ngãi",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0255",
    Active: -1,
  },
  {
    ID: 35,
    Code: "36",
    Name: "Kom Tum",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0260",
    Active: -1,
  },
  {
    ID: 36,
    Code: "37",
    Name: "Bình Định",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0256",
    Active: -1,
  },
  {
    ID: 37,
    Code: "38",
    Name: "Gia Lai",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0269",
    Active: -1,
  },
  {
    ID: 38,
    Code: "39",
    Name: "Phú Yên",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0257",
    Active: -1,
  },
  {
    ID: 39,
    Code: "40",
    Name: "Đắc Lắc",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0262",
    Active: -1,
  },
  {
    ID: 40,
    Code: "41",
    Name: "Khánh Hòa",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0258",
    Active: -1,
  },
  {
    ID: 41,
    Code: "42",
    Name: "Lâm Đồng",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0263",
    Active: -1,
  },
  {
    ID: 42,
    Code: "43",
    Name: "Bình Phước",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0271",
    Active: -1,
  },
  {
    ID: 43,
    Code: "44",
    Name: "Bình Dương",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0274",
    Active: -1,
  },
  {
    ID: 44,
    Code: "45",
    Name: "Ninh Thuận",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0259",
    Active: -1,
  },
  {
    ID: 45,
    Code: "46",
    Name: "Tây Ninh",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0276",
    Active: -1,
  },
  {
    ID: 46,
    Code: "47",
    Name: "Bình Thuận",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0252",
    Active: -1,
  },
  {
    ID: 47,
    Code: "48",
    Name: "Đồng Nai",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0251",
    Active: -1,
  },
  {
    ID: 48,
    Code: "49",
    Name: "Long An",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0272",
    Active: -1,
  },
  {
    ID: 49,
    Code: "50",
    Name: "Đồng Tháp",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0277",
    Active: -1,
  },
  {
    ID: 50,
    Code: "51",
    Name: "An Giang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0296",
    Active: -1,
  },
  {
    ID: 51,
    Code: "52",
    Name: "Bà Rịa Vũng Tàu",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0254",
    Active: -1,
  },
  {
    ID: 52,
    Code: "53",
    Name: "Tiền Giang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0273",
    Active: -1,
  },
  {
    ID: 53,
    Code: "54",
    Name: "Kiên Giang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0297",
    Active: -1,
  },
  {
    ID: 54,
    Code: "55",
    Name: "Cần Thơ",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0292",
    Active: -1,
  },
  {
    ID: 55,
    Code: "56",
    Name: "Bến Tre",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0275",
    Active: -1,
  },
  {
    ID: 56,
    Code: "57",
    Name: "Vĩnh Long",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0270",
    Active: -1,
  },
  {
    ID: 57,
    Code: "58",
    Name: "Trà Vinh",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0294",
    Active: -1,
  },
  {
    ID: 58,
    Code: "59",
    Name: "Sóc Trăng",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0299",
    Active: -1,
  },
  {
    ID: 59,
    Code: "60",
    Name: "Bạc Liêu",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0291",
    Active: -1,
  },
  {
    ID: 60,
    Code: "61",
    Name: "Cà Mau",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0290",
    Active: -1,
  },
  {
    ID: 61,
    Code: "62",
    Name: "Điện Biên",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0215",
    Active: -1,
  },
  {
    ID: 62,
    Code: "63",
    Name: "Đắc Nông",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0261",
    Active: -1,
  },
  {
    ID: 63,
    Code: "64",
    Name: "Hậu Giang",
    Region: null,
    NationallityCode: "VN",
    PostalCode: "0293",
    Active: -1,
  },
];
export { DataProvince };
