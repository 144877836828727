const path = {
  login: "/api/login-zalo-miniapp",
  logout: "/api/logout",
  home: "/",
  shop: "/shop",
  milestone: "/milestone",
  profile: "/profile",
  community: "/community",
  detailProduct: ":id",
  cart: "/cart",
  category: "/category",
  askDoctor: "/askDoctor",
  searchAsk: "/searchAsk",
  historyAsk: "/historyAsk",
  askQuestions: "/askQuestions",
  healthRecord: "/healthRecord",
  voucher: "/voucher",
  babyHealth: "/babyHealth",
  brandPoin: "/brandPoin",
  brandGift: "/brandGift",
  listPresent: "/listPresent",
  handBook: "/handBook",
  listProduct: "/listProduct",
  listAddress: "/listAddress",
  addAdress: "/addAdress",
  detailQuestion: "/detailQuestion/:id",
  categoryHandBook: "/categoryHandBook",
  detailHandbook: "/detailHandbook/:id",
  detailProblem: "/detailProblem",
  playVideo: "/playVideo",
  editBaby: "/editBaby",
  detailLessonHome: "/detailLessonHome",
  myVoucher: "/myVoucher",
  programVoucher: "/programVoucher",
  problemByAge: "/problemByAge",
  allProblemQues: "/allProblemQues",
  receiveGiftInformationScreen: "/receiveGiftInformationScreen",
  Parameters: "/parameters",
  myOrder: "/myOrder",
  searchProduct: "/searchProduct",
  topProduct: "/topProduct",
  listCategory: "/listCategory",
  listBuyAgain: "/listBuyAgain",
  uploadPost: "/uploadPost",
  uploadVideo: "/uploadVideo",
  listProductBuyAgain: "/listProductBuyAgain",
  listProblemByMonth: "/listProblemByMonth",
  listBlog: "/listBlog",
  detailBlog: "/detailBlog",
  lisPostWithProblem: "/lisPostWithProblem",
  detailPostProblem: "/detailPostProblem",
} as const;
export default path;
