const DataDistrict = [
  {
    ID: 1,
    ProvinceCode: "01",
    Code: "1A01",
    Name: "Quận Ba Đình",
    Active: -1,
  },
  {
    ID: 2,
    ProvinceCode: "01",
    Code: "1A02",
    Name: "Quận Hoàn Kiếm",
    Active: -1,
  },
  {
    ID: 3,
    ProvinceCode: "01",
    Code: "1A03",
    Name: "Quận Hai Bà Trưng",
    Active: -1,
  },
  {
    ID: 4,
    ProvinceCode: "01",
    Code: "1A04",
    Name: "Quận Đống Đa",
    Active: -1,
  },
  {
    ID: 5,
    ProvinceCode: "01",
    Code: "1A05",
    Name: "Quận Tây Hồ",
    Active: -1,
  },
  {
    ID: 6,
    ProvinceCode: "01",
    Code: "1A06",
    Name: "Quận Cầu Giấy",
    Active: -1,
  },
  {
    ID: 7,
    ProvinceCode: "01",
    Code: "1A07",
    Name: "Quận Thanh Xuân",
    Active: -1,
  },
  {
    ID: 8,
    ProvinceCode: "01",
    Code: "1A08",
    Name: "Quận Hoàng Mai",
    Active: -1,
  },
  {
    ID: 9,
    ProvinceCode: "01",
    Code: "1A09",
    Name: "Quận Long Biên",
    Active: -1,
  },
  {
    ID: 10,
    ProvinceCode: "01",
    Code: "1A10",
    Name: "Quận Nam Từ Liêm",
    Active: -1,
  },
  {
    ID: 11,
    ProvinceCode: "01",
    Code: "1A11",
    Name: "Huyện Thanh Trì",
    Active: -1,
  },
  {
    ID: 12,
    ProvinceCode: "01",
    Code: "1A12",
    Name: "Huyện Gia Lâm",
    Active: -1,
  },
  {
    ID: 13,
    ProvinceCode: "01",
    Code: "1A13",
    Name: "Huyện Đông Anh",
    Active: -1,
  },
  {
    ID: 14,
    ProvinceCode: "01",
    Code: "1A14",
    Name: "Huyện Sóc Sơn",
    Active: -1,
  },
  {
    ID: 15,
    ProvinceCode: "01",
    Code: "1B15",
    Name: "Quận Hà Đông",
    Active: -1,
  },
  {
    ID: 16,
    ProvinceCode: "01",
    Code: "1B16",
    Name: "Thị xã Sơn Tây",
    Active: -1,
  },
  {
    ID: 17,
    ProvinceCode: "01",
    Code: "1B17",
    Name: "Huyện Ba Vì",
    Active: -1,
  },
  {
    ID: 18,
    ProvinceCode: "01",
    Code: "1B18",
    Name: "Huyện Phúc Thọ",
    Active: -1,
  },
  {
    ID: 19,
    ProvinceCode: "01",
    Code: "1B19",
    Name: "Huyện Thạch Thất",
    Active: -1,
  },
  {
    ID: 20,
    ProvinceCode: "01",
    Code: "1B20",
    Name: "Huyện Quốc Oai",
    Active: -1,
  },
  {
    ID: 21,
    ProvinceCode: "01",
    Code: "1B21",
    Name: "Huyện Chương Mỹ",
    Active: -1,
  },
  {
    ID: 22,
    ProvinceCode: "01",
    Code: "1B22",
    Name: "Huyện Đan Phượng",
    Active: -1,
  },
  {
    ID: 23,
    ProvinceCode: "01",
    Code: "1B23",
    Name: "Huyện Hoài Đức",
    Active: -1,
  },
  {
    ID: 24,
    ProvinceCode: "01",
    Code: "1B24",
    Name: "Huyện Thanh Oai",
    Active: -1,
  },
  {
    ID: 25,
    ProvinceCode: "01",
    Code: "1B25",
    Name: "Huyện Mỹ Đức",
    Active: -1,
  },
  {
    ID: 26,
    ProvinceCode: "01",
    Code: "1B26",
    Name: "Huyện Ứng Hoà",
    Active: -1,
  },
  {
    ID: 27,
    ProvinceCode: "01",
    Code: "1B27",
    Name: "Huyện Thường Tín",
    Active: -1,
  },
  {
    ID: 28,
    ProvinceCode: "01",
    Code: "1B28",
    Name: "Huyện Phú Xuyên",
    Active: -1,
  },
  {
    ID: 29,
    ProvinceCode: "01",
    Code: "1B29",
    Name: "Huyện Mê Linh",
    Active: -1,
  },
  {
    ID: 30,
    ProvinceCode: "01",
    Code: "1B30",
    Name: "Quận Bắc Từ Liêm",
    Active: -1,
  },
  {
    ID: 31,
    ProvinceCode: "02",
    Code: "0201",
    Name: "Quận 1",
    Active: -1,
  },
  {
    ID: 32,
    ProvinceCode: "02",
    Code: "0202",
    Name: "Quận 2",
    Active: 0,
  },
  {
    ID: 33,
    ProvinceCode: "02",
    Code: "0203",
    Name: "Quận 3",
    Active: -1,
  },
  {
    ID: 34,
    ProvinceCode: "02",
    Code: "0204",
    Name: "Quận 4",
    Active: -1,
  },
  {
    ID: 35,
    ProvinceCode: "02",
    Code: "0205",
    Name: "Quận 5",
    Active: -1,
  },
  {
    ID: 36,
    ProvinceCode: "02",
    Code: "0206",
    Name: "Quận 6",
    Active: -1,
  },
  {
    ID: 37,
    ProvinceCode: "02",
    Code: "0207",
    Name: "Quận 7",
    Active: -1,
  },
  {
    ID: 38,
    ProvinceCode: "02",
    Code: "0208",
    Name: "Quận 8",
    Active: -1,
  },
  {
    ID: 39,
    ProvinceCode: "02",
    Code: "0209",
    Name: "Quận 9",
    Active: 0,
  },
  {
    ID: 40,
    ProvinceCode: "02",
    Code: "0210",
    Name: "Quận 10",
    Active: -1,
  },
  {
    ID: 41,
    ProvinceCode: "02",
    Code: "0211",
    Name: "Quận 11",
    Active: -1,
  },
  {
    ID: 42,
    ProvinceCode: "02",
    Code: "0212",
    Name: "Quận 12",
    Active: -1,
  },
  {
    ID: 43,
    ProvinceCode: "02",
    Code: "0213",
    Name: "Quận Gò Vấp",
    Active: -1,
  },
  {
    ID: 44,
    ProvinceCode: "02",
    Code: "0214",
    Name: "Quận Tân Bình",
    Active: -1,
  },
  {
    ID: 45,
    ProvinceCode: "02",
    Code: "0215",
    Name: "Quận Tân Phú",
    Active: -1,
  },
  {
    ID: 46,
    ProvinceCode: "02",
    Code: "0216",
    Name: "Quận Bình Thạnh",
    Active: -1,
  },
  {
    ID: 47,
    ProvinceCode: "02",
    Code: "0217",
    Name: "Quận Phú Nhuận",
    Active: -1,
  },
  {
    ID: 48,
    ProvinceCode: "02",
    Code: "0218",
    Name: "Thành phố Thủ Đức",
    Active: -1,
  },
  {
    ID: 49,
    ProvinceCode: "02",
    Code: "0219",
    Name: "Quận Bình Tân",
    Active: -1,
  },
  {
    ID: 50,
    ProvinceCode: "02",
    Code: "0220",
    Name: "Huyện Bình Chánh",
    Active: -1,
  },
  {
    ID: 51,
    ProvinceCode: "02",
    Code: "0221",
    Name: "Huyện Củ Chi",
    Active: -1,
  },
  {
    ID: 52,
    ProvinceCode: "02",
    Code: "0222",
    Name: "Huyện Hóc Môn",
    Active: -1,
  },
  {
    ID: 53,
    ProvinceCode: "02",
    Code: "0223",
    Name: "Huyện Nhà Bè",
    Active: -1,
  },
  {
    ID: 54,
    ProvinceCode: "02",
    Code: "0224",
    Name: "Huyện Cần Giờ",
    Active: -1,
  },
  {
    ID: 55,
    ProvinceCode: "03",
    Code: "0301",
    Name: "Quận Hồng Bàng",
    Active: -1,
  },
  {
    ID: 56,
    ProvinceCode: "03",
    Code: "0302",
    Name: "Quận Lê Chân",
    Active: -1,
  },
  {
    ID: 57,
    ProvinceCode: "03",
    Code: "0303",
    Name: "Quận Ngô Quyền",
    Active: -1,
  },
  {
    ID: 58,
    ProvinceCode: "03",
    Code: "0304",
    Name: "Quận Kiến An",
    Active: -1,
  },
  {
    ID: 59,
    ProvinceCode: "03",
    Code: "0305",
    Name: "Quận Hải An",
    Active: -1,
  },
  {
    ID: 60,
    ProvinceCode: "03",
    Code: "0306",
    Name: "Quận Đồ Sơn",
    Active: -1,
  },
  {
    ID: 61,
    ProvinceCode: "03",
    Code: "0307",
    Name: "Huyện An Lão",
    Active: -1,
  },
  {
    ID: 62,
    ProvinceCode: "03",
    Code: "0308",
    Name: "Huyện Kiến Thụy",
    Active: -1,
  },
  {
    ID: 63,
    ProvinceCode: "03",
    Code: "0310",
    Name: "Huyện An Dương",
    Active: -1,
  },
  {
    ID: 64,
    ProvinceCode: "03",
    Code: "0311",
    Name: "Huyện Tiên Lãng",
    Active: -1,
  },
  {
    ID: 65,
    ProvinceCode: "03",
    Code: "0312",
    Name: "Huyện Vĩnh Bảo",
    Active: -1,
  },
  {
    ID: 66,
    ProvinceCode: "03",
    Code: "0313",
    Name: "Huyện Cát Hải",
    Active: -1,
  },
  {
    ID: 67,
    ProvinceCode: "03",
    Code: "0309",
    Name: "Huyện Thủy Nguyên",
    Active: -1,
  },
  {
    ID: 68,
    ProvinceCode: "03",
    Code: "0314",
    Name: "Huyện Bạch Long Vĩ",
    Active: -1,
  },
  {
    ID: 69,
    ProvinceCode: "03",
    Code: "0315",
    Name: "Quận Dương Kinh",
    Active: -1,
  },
  {
    ID: 70,
    ProvinceCode: "04",
    Code: "0401",
    Name: "Quận Hải Châu",
    Active: -1,
  },
  {
    ID: 71,
    ProvinceCode: "04",
    Code: "0402",
    Name: "Quận Thanh Khê",
    Active: -1,
  },
  {
    ID: 72,
    ProvinceCode: "04",
    Code: "0403",
    Name: "Quận Sơn Trà",
    Active: -1,
  },
  {
    ID: 73,
    ProvinceCode: "04",
    Code: "0404",
    Name: "Quận Ngũ Hành Sơn",
    Active: -1,
  },
  {
    ID: 74,
    ProvinceCode: "04",
    Code: "0405",
    Name: "Quận Liên Chiểu",
    Active: -1,
  },
  {
    ID: 75,
    ProvinceCode: "04",
    Code: "0406",
    Name: "Huyện Hoà Vang",
    Active: -1,
  },
  {
    ID: 76,
    ProvinceCode: "04",
    Code: "0407",
    Name: "Quận Cẩm Lệ",
    Active: -1,
  },
  {
    ID: 77,
    ProvinceCode: "05",
    Code: "0501",
    Name: "Thành phố Hà Giang",
    Active: -1,
  },
  {
    ID: 78,
    ProvinceCode: "05",
    Code: "0502",
    Name: "Huyện Đồng Văn",
    Active: -1,
  },
  {
    ID: 79,
    ProvinceCode: "05",
    Code: "0503",
    Name: "Huyện Mèo Vạc",
    Active: -1,
  },
  {
    ID: 80,
    ProvinceCode: "05",
    Code: "0504",
    Name: "Huyện Yên Minh",
    Active: -1,
  },
  {
    ID: 81,
    ProvinceCode: "05",
    Code: "0505",
    Name: "Huyện Quản Bạ",
    Active: -1,
  },
  {
    ID: 82,
    ProvinceCode: "05",
    Code: "0506",
    Name: "Huyện Vị Xuyên",
    Active: -1,
  },
  {
    ID: 83,
    ProvinceCode: "05",
    Code: "0507",
    Name: "Huyện Bắc Mê",
    Active: -1,
  },
  {
    ID: 84,
    ProvinceCode: "05",
    Code: "0508",
    Name: "Huyện Hoàng Su Phì",
    Active: -1,
  },
  {
    ID: 85,
    ProvinceCode: "05",
    Code: "0509",
    Name: "Huyện Xín Mần",
    Active: -1,
  },
  {
    ID: 86,
    ProvinceCode: "05",
    Code: "0510",
    Name: "Huyện Bắc Quang",
    Active: -1,
  },
  {
    ID: 87,
    ProvinceCode: "05",
    Code: "0511",
    Name: "Huyện Quang Bình",
    Active: -1,
  },
  {
    ID: 88,
    ProvinceCode: "06",
    Code: "0601",
    Name: "Thành phố Cao Bằng",
    Active: -1,
  },
  {
    ID: 89,
    ProvinceCode: "06",
    Code: "0602",
    Name: "Huyện Bảo Lạc",
    Active: -1,
  },
  {
    ID: 90,
    ProvinceCode: "06",
    Code: "0603",
    Name: "Huyện Thông Nông",
    Active: -1,
  },
  {
    ID: 91,
    ProvinceCode: "06",
    Code: "0604",
    Name: "Huyện Hà Quảng",
    Active: -1,
  },
  {
    ID: 92,
    ProvinceCode: "06",
    Code: "0605",
    Name: "Huyện Trà Lĩnh",
    Active: -1,
  },
  {
    ID: 93,
    ProvinceCode: "06",
    Code: "0606",
    Name: "Huyện Trùng Khánh",
    Active: -1,
  },
  {
    ID: 94,
    ProvinceCode: "06",
    Code: "0607",
    Name: "Huyện Nguyên Bình",
    Active: -1,
  },
  {
    ID: 95,
    ProvinceCode: "06",
    Code: "0608",
    Name: "Huyện Hoà An",
    Active: -1,
  },
  {
    ID: 96,
    ProvinceCode: "06",
    Code: "0609",
    Name: "Huyện Quảng Uyên",
    Active: -1,
  },
  {
    ID: 97,
    ProvinceCode: "06",
    Code: "0610",
    Name: "Huyện Thạch An",
    Active: -1,
  },
  {
    ID: 98,
    ProvinceCode: "06",
    Code: "0611",
    Name: "Huyện Hạ Lang",
    Active: -1,
  },
  {
    ID: 99,
    ProvinceCode: "06",
    Code: "0612",
    Name: "Huyện Bảo Lâm",
    Active: -1,
  },
  {
    ID: 100,
    ProvinceCode: "06",
    Code: "0613",
    Name: "Huyện Phục Hoà",
    Active: -1,
  },
  {
    ID: 101,
    ProvinceCode: "07",
    Code: "0701",
    Name: "Thành phố Lai Châu",
    Active: -1,
  },
  {
    ID: 102,
    ProvinceCode: "07",
    Code: "0702",
    Name: "Huyện Tam Đường",
    Active: -1,
  },
  {
    ID: 103,
    ProvinceCode: "07",
    Code: "0703",
    Name: "Huyện Phong Thổ",
    Active: -1,
  },
  {
    ID: 104,
    ProvinceCode: "07",
    Code: "0704",
    Name: "Huyện Sìn Hồ",
    Active: -1,
  },
  {
    ID: 105,
    ProvinceCode: "07",
    Code: "0705",
    Name: "Huyện Mường Tè",
    Active: -1,
  },
  {
    ID: 106,
    ProvinceCode: "07",
    Code: "0706",
    Name: "Huyện Than Uyên",
    Active: -1,
  },
  {
    ID: 107,
    ProvinceCode: "07",
    Code: "0707",
    Name: "Huyện Tân Uyên",
    Active: -1,
  },
  {
    ID: 108,
    ProvinceCode: "08",
    Code: "0801",
    Name: "Thành phố Lào Cai",
    Active: -1,
  },
  {
    ID: 109,
    ProvinceCode: "08",
    Code: "0802",
    Name: "Huyện Si Ma Cai",
    Active: -1,
  },
  {
    ID: 110,
    ProvinceCode: "08",
    Code: "0803",
    Name: "Huyện Bát Xát",
    Active: -1,
  },
  {
    ID: 111,
    ProvinceCode: "08",
    Code: "0808",
    Name: "Huyện Bắc Hà",
    Active: -1,
  },
  {
    ID: 112,
    ProvinceCode: "08",
    Code: "0809",
    Name: "Huyện Mường Khương",
    Active: -1,
  },
  {
    ID: 113,
    ProvinceCode: "08",
    Code: "0804",
    Name: "Huyện Bảo Thắng",
    Active: -1,
  },
  {
    ID: 114,
    ProvinceCode: "08",
    Code: "0805",
    Name: "Thị xã Sa Pa",
    Active: -1,
  },
  {
    ID: 115,
    ProvinceCode: "08",
    Code: "0806",
    Name: "Huyện Văn Bàn",
    Active: -1,
  },
  {
    ID: 116,
    ProvinceCode: "08",
    Code: "0807",
    Name: "Huyện Bảo Yên",
    Active: -1,
  },
  {
    ID: 117,
    ProvinceCode: "09",
    Code: "0901",
    Name: "Th. phố Tuyên Quang",
    Active: -1,
  },
  {
    ID: 118,
    ProvinceCode: "09",
    Code: "0902",
    Name: "Huyện Lâm Bình",
    Active: -1,
  },
  {
    ID: 119,
    ProvinceCode: "09",
    Code: "0903",
    Name: "Huyện Na Hang",
    Active: -1,
  },
  {
    ID: 120,
    ProvinceCode: "09",
    Code: "0904",
    Name: "Huyện Chiêm Hoá",
    Active: -1,
  },
  {
    ID: 121,
    ProvinceCode: "09",
    Code: "0905",
    Name: "Huyện Hàm Yên",
    Active: -1,
  },
  {
    ID: 122,
    ProvinceCode: "09",
    Code: "0906",
    Name: "Huyện Yên Sơn",
    Active: -1,
  },
  {
    ID: 123,
    ProvinceCode: "09",
    Code: "0907",
    Name: "Huyện Sơn Dương",
    Active: -1,
  },
  {
    ID: 124,
    ProvinceCode: "10",
    Code: "1001",
    Name: "Thành phố Lạng Sơn",
    Active: -1,
  },
  {
    ID: 125,
    ProvinceCode: "10",
    Code: "1002",
    Name: "Huyện Tràng Định",
    Active: -1,
  },
  {
    ID: 126,
    ProvinceCode: "10",
    Code: "1003",
    Name: "Huyện Bình Gia",
    Active: -1,
  },
  {
    ID: 127,
    ProvinceCode: "10",
    Code: "1004",
    Name: "Huyện Văn Lãng",
    Active: -1,
  },
  {
    ID: 128,
    ProvinceCode: "10",
    Code: "1005",
    Name: "Huyện Bắc Sơn",
    Active: -1,
  },
  {
    ID: 129,
    ProvinceCode: "10",
    Code: "1006",
    Name: "Huyện Văn Quan",
    Active: -1,
  },
  {
    ID: 130,
    ProvinceCode: "10",
    Code: "1007",
    Name: "Huyện Cao Lộc",
    Active: -1,
  },
  {
    ID: 131,
    ProvinceCode: "10",
    Code: "1008",
    Name: "Huyện Lộc Bình",
    Active: -1,
  },
  {
    ID: 132,
    ProvinceCode: "10",
    Code: "1009",
    Name: "Huyện Chi Lăng",
    Active: -1,
  },
  {
    ID: 133,
    ProvinceCode: "10",
    Code: "1010",
    Name: "Huyện Đình Lập",
    Active: -1,
  },
  {
    ID: 134,
    ProvinceCode: "10",
    Code: "1011",
    Name: "Huyện Hữu Lũng",
    Active: -1,
  },
  {
    ID: 135,
    ProvinceCode: "11",
    Code: "1101",
    Name: "Thành phố Bắc Kạn",
    Active: -1,
  },
  {
    ID: 136,
    ProvinceCode: "11",
    Code: "1102",
    Name: "Huyện Chợ Đồn",
    Active: -1,
  },
  {
    ID: 137,
    ProvinceCode: "11",
    Code: "1103",
    Name: "Huyện Bạch Thông",
    Active: -1,
  },
  {
    ID: 138,
    ProvinceCode: "11",
    Code: "1104",
    Name: "Huyện Na Rì",
    Active: -1,
  },
  {
    ID: 139,
    ProvinceCode: "11",
    Code: "1105",
    Name: "Huyện Ngân Sơn",
    Active: -1,
  },
  {
    ID: 140,
    ProvinceCode: "11",
    Code: "1106",
    Name: "Huyện Ba Bể",
    Active: -1,
  },
  {
    ID: 141,
    ProvinceCode: "11",
    Code: "1107",
    Name: "Huyện Chợ Mới",
    Active: -1,
  },
  {
    ID: 142,
    ProvinceCode: "11",
    Code: "1108",
    Name: "Huyện Pác Nặm",
    Active: -1,
  },
  {
    ID: 143,
    ProvinceCode: "12",
    Code: "1201",
    Name: "TP.Thái Nguyên",
    Active: -1,
  },
  {
    ID: 144,
    ProvinceCode: "12",
    Code: "1202",
    Name: "Thành phố Sông Công",
    Active: -1,
  },
  {
    ID: 145,
    ProvinceCode: "12",
    Code: "1203",
    Name: "Huyện Định Hoá",
    Active: -1,
  },
  {
    ID: 146,
    ProvinceCode: "12",
    Code: "1204",
    Name: "Huyện Phú Lương",
    Active: -1,
  },
  {
    ID: 147,
    ProvinceCode: "12",
    Code: "1205",
    Name: "Huyện Võ Nhai",
    Active: -1,
  },
  {
    ID: 148,
    ProvinceCode: "12",
    Code: "1206",
    Name: "Huyện Đại Từ",
    Active: -1,
  },
  {
    ID: 149,
    ProvinceCode: "12",
    Code: "1207",
    Name: "Huyện Đồng Hỷ",
    Active: -1,
  },
  {
    ID: 150,
    ProvinceCode: "12",
    Code: "1208",
    Name: "Huyện Phú Bình",
    Active: -1,
  },
  {
    ID: 151,
    ProvinceCode: "12",
    Code: "1209",
    Name: "Thị xã Phổ Yên",
    Active: -1,
  },
  {
    ID: 152,
    ProvinceCode: "13",
    Code: "1301",
    Name: "Thành phố Yên Bái",
    Active: -1,
  },
  {
    ID: 153,
    ProvinceCode: "13",
    Code: "1302",
    Name: "Thị xã Nghĩa Lộ",
    Active: -1,
  },
  {
    ID: 154,
    ProvinceCode: "13",
    Code: "1303",
    Name: "Huyện Văn Yên",
    Active: -1,
  },
  {
    ID: 155,
    ProvinceCode: "13",
    Code: "1304",
    Name: "Huyện Yên Bình",
    Active: -1,
  },
  {
    ID: 156,
    ProvinceCode: "13",
    Code: "1305",
    Name: "Huyện Mù Căng Chải",
    Active: -1,
  },
  {
    ID: 157,
    ProvinceCode: "13",
    Code: "1306",
    Name: "Huyện Văn Chấn",
    Active: -1,
  },
  {
    ID: 158,
    ProvinceCode: "13",
    Code: "1307",
    Name: "Huyện Trấn Yên",
    Active: -1,
  },
  {
    ID: 159,
    ProvinceCode: "13",
    Code: "1308",
    Name: "Huyện Trạm Tấu",
    Active: -1,
  },
  {
    ID: 160,
    ProvinceCode: "13",
    Code: "1309",
    Name: "Huyện Lục Yên",
    Active: -1,
  },
  {
    ID: 161,
    ProvinceCode: "14",
    Code: "1401",
    Name: "Thành phố Sơn La",
    Active: -1,
  },
  {
    ID: 162,
    ProvinceCode: "14",
    Code: "1402",
    Name: "Huyện Quỳnh Nhai",
    Active: -1,
  },
  {
    ID: 163,
    ProvinceCode: "14",
    Code: "1403",
    Name: "Huyện Mường La",
    Active: -1,
  },
  {
    ID: 164,
    ProvinceCode: "14",
    Code: "1404",
    Name: "Huyện Thuận Châu",
    Active: -1,
  },
  {
    ID: 165,
    ProvinceCode: "14",
    Code: "1405",
    Name: "Huyện Bắc Yên",
    Active: -1,
  },
  {
    ID: 166,
    ProvinceCode: "14",
    Code: "1406",
    Name: "Huyện Phù Yên",
    Active: -1,
  },
  {
    ID: 167,
    ProvinceCode: "14",
    Code: "1407",
    Name: "Huyện Mai Sơn",
    Active: -1,
  },
  {
    ID: 168,
    ProvinceCode: "14",
    Code: "1408",
    Name: "Huyện Yên Châu",
    Active: -1,
  },
  {
    ID: 169,
    ProvinceCode: "14",
    Code: "1409",
    Name: "Huyện Sông Mã",
    Active: -1,
  },
  {
    ID: 170,
    ProvinceCode: "14",
    Code: "1410",
    Name: "Huyện Mộc Châu",
    Active: -1,
  },
  {
    ID: 171,
    ProvinceCode: "14",
    Code: "1411",
    Name: "Huyện Sốp Cộp",
    Active: -1,
  },
  {
    ID: 172,
    ProvinceCode: "15",
    Code: "1501",
    Name: "TP. Việt Trì",
    Active: -1,
  },
  {
    ID: 173,
    ProvinceCode: "15",
    Code: "1502",
    Name: "Thị xã Phú Thọ",
    Active: -1,
  },
  {
    ID: 174,
    ProvinceCode: "15",
    Code: "1503",
    Name: "Huyện Đoan Hùng",
    Active: -1,
  },
  {
    ID: 175,
    ProvinceCode: "15",
    Code: "1504",
    Name: "Huyện Thanh Ba",
    Active: -1,
  },
  {
    ID: 176,
    ProvinceCode: "15",
    Code: "1505",
    Name: "Huyện Hạ Hoà",
    Active: -1,
  },
  {
    ID: 177,
    ProvinceCode: "15",
    Code: "1506",
    Name: "Huyện Cẩm Khê",
    Active: -1,
  },
  {
    ID: 178,
    ProvinceCode: "15",
    Code: "1507",
    Name: "Huyện Yên Lập",
    Active: -1,
  },
  {
    ID: 179,
    ProvinceCode: "15",
    Code: "1508",
    Name: "Huyện Thanh Sơn",
    Active: -1,
  },
  {
    ID: 180,
    ProvinceCode: "15",
    Code: "1509",
    Name: "Huyện Phù Ninh",
    Active: -1,
  },
  {
    ID: 181,
    ProvinceCode: "15",
    Code: "1510",
    Name: "Huyện Lâm Thao",
    Active: -1,
  },
  {
    ID: 182,
    ProvinceCode: "15",
    Code: "1511",
    Name: "Huyện Tam Nông",
    Active: -1,
  },
  {
    ID: 183,
    ProvinceCode: "15",
    Code: "1512",
    Name: "Huyện Thanh Thủy",
    Active: -1,
  },
  {
    ID: 184,
    ProvinceCode: "15",
    Code: "1513",
    Name: "Huyện Tân Sơn",
    Active: -1,
  },
  {
    ID: 185,
    ProvinceCode: "16",
    Code: "1601",
    Name: "Thành phố Vĩnh Yên",
    Active: -1,
  },
  {
    ID: 186,
    ProvinceCode: "16",
    Code: "1602",
    Name: "Huyện Tam Dương",
    Active: -1,
  },
  {
    ID: 187,
    ProvinceCode: "16",
    Code: "1603",
    Name: "Huyện Lập Thạch",
    Active: -1,
  },
  {
    ID: 188,
    ProvinceCode: "16",
    Code: "1604",
    Name: "Huyện Vĩnh Tường",
    Active: -1,
  },
  {
    ID: 189,
    ProvinceCode: "16",
    Code: "1605",
    Name: "Huyện Yên Lạc",
    Active: -1,
  },
  {
    ID: 190,
    ProvinceCode: "16",
    Code: "1606",
    Name: "Huyện Bình Xuyên",
    Active: -1,
  },
  {
    ID: 191,
    ProvinceCode: "16",
    Code: "1607",
    Name: "Huyện Sông Lô",
    Active: -1,
  },
  {
    ID: 192,
    ProvinceCode: "16",
    Code: "1608",
    Name: "Thành phố Phúc Yên",
    Active: -1,
  },
  {
    ID: 193,
    ProvinceCode: "16",
    Code: "1609",
    Name: "Huyện Tam Đảo",
    Active: -1,
  },
  {
    ID: 194,
    ProvinceCode: "17",
    Code: "1701",
    Name: "Thành phố Hạ Long",
    Active: -1,
  },
  {
    ID: 195,
    ProvinceCode: "17",
    Code: "1702",
    Name: "Thành phố Cẩm Phả",
    Active: -1,
  },
  {
    ID: 196,
    ProvinceCode: "17",
    Code: "1703",
    Name: "Thành phố Uông Bí",
    Active: -1,
  },
  {
    ID: 197,
    ProvinceCode: "17",
    Code: "1704",
    Name: "Thành phố Móng Cái",
    Active: -1,
  },
  {
    ID: 198,
    ProvinceCode: "17",
    Code: "1705",
    Name: "Huyện Bình Liêu",
    Active: -1,
  },
  {
    ID: 199,
    ProvinceCode: "17",
    Code: "1706",
    Name: "Huyện Đầm Hà",
    Active: -1,
  },
  {
    ID: 200,
    ProvinceCode: "17",
    Code: "1707",
    Name: "Huyện Hải Hà",
    Active: -1,
  },
  {
    ID: 201,
    ProvinceCode: "17",
    Code: "1708",
    Name: "Huyện Tiên Yên",
    Active: -1,
  },
  {
    ID: 202,
    ProvinceCode: "17",
    Code: "1709",
    Name: "Huyện Ba Chẽ",
    Active: -1,
  },
  {
    ID: 203,
    ProvinceCode: "17",
    Code: "1710",
    Name: "Thị xã Đông Triều",
    Active: -1,
  },
  {
    ID: 204,
    ProvinceCode: "17",
    Code: "1711",
    Name: "Huyện Yên Hưng",
    Active: -1,
  },
  {
    ID: 205,
    ProvinceCode: "17",
    Code: "1712",
    Name: "Huyện Hoành Bồ",
    Active: -1,
  },
  {
    ID: 206,
    ProvinceCode: "17",
    Code: "1713",
    Name: "Huyện Vân Đồn",
    Active: -1,
  },
  {
    ID: 207,
    ProvinceCode: "17",
    Code: "1714",
    Name: "Huyện Cô Tô",
    Active: -1,
  },
  {
    ID: 208,
    ProvinceCode: "18",
    Code: "1801",
    Name: "Thành phố Bắc Giang",
    Active: -1,
  },
  {
    ID: 209,
    ProvinceCode: "18",
    Code: "1802",
    Name: "Huyện Yên Thế",
    Active: -1,
  },
  {
    ID: 210,
    ProvinceCode: "18",
    Code: "1803",
    Name: "Huyện Lục Ngạn",
    Active: -1,
  },
  {
    ID: 211,
    ProvinceCode: "18",
    Code: "1804",
    Name: "Huyện Sơn Động",
    Active: -1,
  },
  {
    ID: 212,
    ProvinceCode: "18",
    Code: "1805",
    Name: "Huyện Lục Nam",
    Active: -1,
  },
  {
    ID: 213,
    ProvinceCode: "18",
    Code: "1806",
    Name: "Huyện Tân Yên",
    Active: -1,
  },
  {
    ID: 214,
    ProvinceCode: "18",
    Code: "1807",
    Name: "Huyện Hiệp Hoà",
    Active: -1,
  },
  {
    ID: 215,
    ProvinceCode: "18",
    Code: "1808",
    Name: "Huyện Lạng Giang",
    Active: -1,
  },
  {
    ID: 216,
    ProvinceCode: "18",
    Code: "1809",
    Name: "Huyện Việt Yên",
    Active: -1,
  },
  {
    ID: 217,
    ProvinceCode: "18",
    Code: "1810",
    Name: "Huyện Yên Dũng",
    Active: -1,
  },
  {
    ID: 218,
    ProvinceCode: "19",
    Code: "1901",
    Name: "Thành phố Bắc Ninh",
    Active: -1,
  },
  {
    ID: 219,
    ProvinceCode: "19",
    Code: "1902",
    Name: "Huyện Yên Phong",
    Active: -1,
  },
  {
    ID: 220,
    ProvinceCode: "19",
    Code: "1903",
    Name: "Huyện Quế Võ",
    Active: -1,
  },
  {
    ID: 221,
    ProvinceCode: "19",
    Code: "1904",
    Name: "Huyện Tiên Du",
    Active: -1,
  },
  {
    ID: 222,
    ProvinceCode: "19",
    Code: "1905",
    Name: "Thị xã Từ Sơn",
    Active: -1,
  },
  {
    ID: 223,
    ProvinceCode: "19",
    Code: "1906",
    Name: "Huyện Thuận Thành",
    Active: -1,
  },
  {
    ID: 224,
    ProvinceCode: "19",
    Code: "1907",
    Name: "Huyện Gia Bình",
    Active: -1,
  },
  {
    ID: 225,
    ProvinceCode: "19",
    Code: "1908",
    Name: "Huyện Lương Tài",
    Active: -1,
  },
  {
    ID: 226,
    ProvinceCode: "21",
    Code: "2101",
    Name: "Thành phố Hải Dương",
    Active: -1,
  },
  {
    ID: 227,
    ProvinceCode: "21",
    Code: "2102",
    Name: "Thành phố Chí Linh",
    Active: -1,
  },
  {
    ID: 228,
    ProvinceCode: "21",
    Code: "2103",
    Name: "Huyện Nam Sách",
    Active: -1,
  },
  {
    ID: 229,
    ProvinceCode: "21",
    Code: "2104",
    Name: "Thị xã Kinh Môn",
    Active: -1,
  },
  {
    ID: 230,
    ProvinceCode: "21",
    Code: "2105",
    Name: "Huyện Gia Lộc",
    Active: -1,
  },
  {
    ID: 231,
    ProvinceCode: "21",
    Code: "2106",
    Name: "Huyện Tứ Kỳ",
    Active: -1,
  },
  {
    ID: 232,
    ProvinceCode: "21",
    Code: "2107",
    Name: "Huyện Thanh Miện",
    Active: -1,
  },
  {
    ID: 233,
    ProvinceCode: "21",
    Code: "2108",
    Name: "Huyện Ninh Giang",
    Active: -1,
  },
  {
    ID: 234,
    ProvinceCode: "21",
    Code: "2109",
    Name: "Huyện Cẩm Giàng",
    Active: -1,
  },
  {
    ID: 235,
    ProvinceCode: "21",
    Code: "2110",
    Name: "Huyện Thanh Hà",
    Active: -1,
  },
  {
    ID: 236,
    ProvinceCode: "21",
    Code: "2111",
    Name: "Huyện Kim Thành",
    Active: -1,
  },
  {
    ID: 237,
    ProvinceCode: "21",
    Code: "2112",
    Name: "Huyện Bình Giang",
    Active: -1,
  },
  {
    ID: 238,
    ProvinceCode: "22",
    Code: "2201",
    Name: "Thành phố Hưng Yên",
    Active: -1,
  },
  {
    ID: 239,
    ProvinceCode: "22",
    Code: "2202",
    Name: "Huyện Kim Động",
    Active: -1,
  },
  {
    ID: 240,
    ProvinceCode: "22",
    Code: "2203",
    Name: "Huyện Ân Thi",
    Active: -1,
  },
  {
    ID: 241,
    ProvinceCode: "22",
    Code: "2204",
    Name: "Huyện Khoái Châu",
    Active: -1,
  },
  {
    ID: 242,
    ProvinceCode: "22",
    Code: "2205",
    Name: "Huyện Yên Mỹ",
    Active: -1,
  },
  {
    ID: 243,
    ProvinceCode: "22",
    Code: "2206",
    Name: "Huyện Tiên Lữ",
    Active: -1,
  },
  {
    ID: 244,
    ProvinceCode: "22",
    Code: "2207",
    Name: "Huyện Phù Cừ",
    Active: -1,
  },
  {
    ID: 245,
    ProvinceCode: "22",
    Code: "2208",
    Name: "Thị xã Mỹ Hào",
    Active: -1,
  },
  {
    ID: 246,
    ProvinceCode: "22",
    Code: "2209",
    Name: "Huyện Văn Lâm",
    Active: -1,
  },
  {
    ID: 247,
    ProvinceCode: "22",
    Code: "2210",
    Name: "Huyện Văn Giang",
    Active: -1,
  },
  {
    ID: 248,
    ProvinceCode: "23",
    Code: "2301",
    Name: "Thành phố Hoà Bình",
    Active: -1,
  },
  {
    ID: 249,
    ProvinceCode: "23",
    Code: "2302",
    Name: "Huyện Đà Bắc",
    Active: -1,
  },
  {
    ID: 250,
    ProvinceCode: "23",
    Code: "2303",
    Name: "Huyện Mai Châu",
    Active: -1,
  },
  {
    ID: 251,
    ProvinceCode: "23",
    Code: "2304",
    Name: "Huyện Tân Lạc",
    Active: -1,
  },
  {
    ID: 252,
    ProvinceCode: "23",
    Code: "2305",
    Name: "Huyện Lạc Sơn",
    Active: -1,
  },
  {
    ID: 253,
    ProvinceCode: "23",
    Code: "2306",
    Name: "Huyện Kỳ Sơn",
    Active: -1,
  },
  {
    ID: 254,
    ProvinceCode: "23",
    Code: "2307",
    Name: "Huyện Lương Sơn",
    Active: -1,
  },
  {
    ID: 255,
    ProvinceCode: "23",
    Code: "2308",
    Name: "Huyện Kim Bôi",
    Active: -1,
  },
  {
    ID: 256,
    ProvinceCode: "23",
    Code: "2309",
    Name: "Huyện Lạc Thuỷ",
    Active: -1,
  },
  {
    ID: 257,
    ProvinceCode: "23",
    Code: "2310",
    Name: "Huyện Yên Thuỷ",
    Active: -1,
  },
  {
    ID: 258,
    ProvinceCode: "23",
    Code: "2311",
    Name: "Huyện Cao Phong",
    Active: -1,
  },
  {
    ID: 259,
    ProvinceCode: "24",
    Code: "2401",
    Name: "Thành phố Phủ Lý",
    Active: -1,
  },
  {
    ID: 260,
    ProvinceCode: "24",
    Code: "2402",
    Name: "Thị xã Duy Tiên",
    Active: -1,
  },
  {
    ID: 261,
    ProvinceCode: "24",
    Code: "2403",
    Name: "Huyện Kim Bảng",
    Active: -1,
  },
  {
    ID: 262,
    ProvinceCode: "24",
    Code: "2404",
    Name: "Huyện Lý Nhân",
    Active: -1,
  },
  {
    ID: 263,
    ProvinceCode: "24",
    Code: "2405",
    Name: "Huyện Thanh Liêm",
    Active: -1,
  },
  {
    ID: 264,
    ProvinceCode: "24",
    Code: "2406",
    Name: "Huyện Bình Lục",
    Active: -1,
  },
  {
    ID: 265,
    ProvinceCode: "25",
    Code: "2501",
    Name: "Thành phố Nam Định",
    Active: -1,
  },
  {
    ID: 266,
    ProvinceCode: "25",
    Code: "2502",
    Name: "Huyện Mỹ Lộc",
    Active: -1,
  },
  {
    ID: 267,
    ProvinceCode: "25",
    Code: "2503",
    Name: "Huyện Xuân Trường",
    Active: -1,
  },
  {
    ID: 268,
    ProvinceCode: "25",
    Code: "2504",
    Name: "Huyện Giao Thủy",
    Active: -1,
  },
  {
    ID: 269,
    ProvinceCode: "25",
    Code: "2505",
    Name: "Huyện Ý Yên",
    Active: -1,
  },
  {
    ID: 270,
    ProvinceCode: "25",
    Code: "2506",
    Name: "Huyện Vụ Bản",
    Active: -1,
  },
  {
    ID: 271,
    ProvinceCode: "25",
    Code: "2507",
    Name: "Huyện Nam Trực",
    Active: -1,
  },
  {
    ID: 272,
    ProvinceCode: "25",
    Code: "2508",
    Name: "Huyện Trực Ninh",
    Active: -1,
  },
  {
    ID: 273,
    ProvinceCode: "25",
    Code: "2509",
    Name: "Huyện Nghĩa Hưng",
    Active: -1,
  },
  {
    ID: 274,
    ProvinceCode: "25",
    Code: "2510",
    Name: "Huyện Hải Hậu",
    Active: -1,
  },
  {
    ID: 275,
    ProvinceCode: "26",
    Code: "2601",
    Name: "Thành phố Thái Bình",
    Active: -1,
  },
  {
    ID: 276,
    ProvinceCode: "26",
    Code: "2602",
    Name: "Huyện Quỳnh Phụ",
    Active: -1,
  },
  {
    ID: 277,
    ProvinceCode: "26",
    Code: "2603",
    Name: "Huyện Hưng Hà",
    Active: -1,
  },
  {
    ID: 278,
    ProvinceCode: "26",
    Code: "2604",
    Name: "Huyện Đông Hưng",
    Active: -1,
  },
  {
    ID: 279,
    ProvinceCode: "26",
    Code: "2605",
    Name: "Huyện Vũ Thư",
    Active: -1,
  },
  {
    ID: 280,
    ProvinceCode: "26",
    Code: "2606",
    Name: "Huyện Kiến Xương",
    Active: -1,
  },
  {
    ID: 281,
    ProvinceCode: "26",
    Code: "2607",
    Name: "Huyện Tiền Hải",
    Active: -1,
  },
  {
    ID: 282,
    ProvinceCode: "26",
    Code: "2608",
    Name: "Huyện Thái Thuỵ",
    Active: -1,
  },
  {
    ID: 283,
    ProvinceCode: "27",
    Code: "2701",
    Name: "Thành phố Ninh Bình",
    Active: -1,
  },
  {
    ID: 284,
    ProvinceCode: "27",
    Code: "2702",
    Name: "Thành phố Tam Điệp",
    Active: -1,
  },
  {
    ID: 285,
    ProvinceCode: "27",
    Code: "2703",
    Name: "Huyện Nho Quan",
    Active: -1,
  },
  {
    ID: 286,
    ProvinceCode: "27",
    Code: "2704",
    Name: "Huyện Gia Viễn",
    Active: -1,
  },
  {
    ID: 287,
    ProvinceCode: "27",
    Code: "2705",
    Name: "Huyện Hoa Lư",
    Active: -1,
  },
  {
    ID: 288,
    ProvinceCode: "27",
    Code: "2706",
    Name: "Huyện Yên Mô",
    Active: -1,
  },
  {
    ID: 289,
    ProvinceCode: "27",
    Code: "2707",
    Name: "Huyện Kim Sơn",
    Active: -1,
  },
  {
    ID: 290,
    ProvinceCode: "27",
    Code: "2708",
    Name: "Huyện Yên Khánh",
    Active: -1,
  },
  {
    ID: 291,
    ProvinceCode: "28",
    Code: "2801",
    Name: "Thành phố Thanh Hoá",
    Active: -1,
  },
  {
    ID: 292,
    ProvinceCode: "28",
    Code: "2802",
    Name: "Thị xã Bỉm Sơn",
    Active: -1,
  },
  {
    ID: 293,
    ProvinceCode: "28",
    Code: "2803",
    Name: "Thành phố Sầm Sơn",
    Active: -1,
  },
  {
    ID: 294,
    ProvinceCode: "28",
    Code: "2804",
    Name: "Huyện Quan Hoá",
    Active: -1,
  },
  {
    ID: 295,
    ProvinceCode: "28",
    Code: "2805",
    Name: "Huyện Quan Sơn",
    Active: -1,
  },
  {
    ID: 296,
    ProvinceCode: "28",
    Code: "2806",
    Name: "Huyện Mường Lát",
    Active: -1,
  },
  {
    ID: 297,
    ProvinceCode: "28",
    Code: "2807",
    Name: "Huyện Bá Thước",
    Active: -1,
  },
  {
    ID: 298,
    ProvinceCode: "28",
    Code: "2808",
    Name: "Huyện Thường Xuân",
    Active: -1,
  },
  {
    ID: 299,
    ProvinceCode: "28",
    Code: "2809",
    Name: "Huyện Như Xuân",
    Active: -1,
  },
  {
    ID: 300,
    ProvinceCode: "28",
    Code: "2810",
    Name: "Huyện Như Thanh",
    Active: -1,
  },
  {
    ID: 301,
    ProvinceCode: "28",
    Code: "2811",
    Name: "Huyện Lang Chánh",
    Active: -1,
  },
  {
    ID: 302,
    ProvinceCode: "28",
    Code: "2812",
    Name: "Huyện Ngọc Lặc",
    Active: -1,
  },
  {
    ID: 303,
    ProvinceCode: "28",
    Code: "2813",
    Name: "Huyện Thạch Thành",
    Active: -1,
  },
  {
    ID: 304,
    ProvinceCode: "28",
    Code: "2814",
    Name: "Huyện Cẩm Thủy",
    Active: -1,
  },
  {
    ID: 305,
    ProvinceCode: "28",
    Code: "2815",
    Name: "Huyện Thọ Xuân",
    Active: -1,
  },
  {
    ID: 306,
    ProvinceCode: "28",
    Code: "2816",
    Name: "Huyện Vĩnh Lộc",
    Active: -1,
  },
  {
    ID: 307,
    ProvinceCode: "28",
    Code: "2817",
    Name: "Huyện Thiệu Hoá",
    Active: -1,
  },
  {
    ID: 308,
    ProvinceCode: "28",
    Code: "2818",
    Name: "Huyện Triệu Sơn",
    Active: -1,
  },
  {
    ID: 309,
    ProvinceCode: "28",
    Code: "2819",
    Name: "Huyện Nông Cống",
    Active: -1,
  },
  {
    ID: 310,
    ProvinceCode: "28",
    Code: "2820",
    Name: "Huyện Đông Sơn",
    Active: -1,
  },
  {
    ID: 311,
    ProvinceCode: "28",
    Code: "2821",
    Name: "Huyện Hà Trung",
    Active: -1,
  },
  {
    ID: 312,
    ProvinceCode: "28",
    Code: "2822",
    Name: "Huyện Hoằng Hoá",
    Active: -1,
  },
  {
    ID: 313,
    ProvinceCode: "28",
    Code: "2823",
    Name: "Huyện Nga Sơn",
    Active: -1,
  },
  {
    ID: 314,
    ProvinceCode: "28",
    Code: "2824",
    Name: "Huyện Hậu Lộc",
    Active: -1,
  },
  {
    ID: 315,
    ProvinceCode: "28",
    Code: "2825",
    Name: "Huyện Quảng Xương",
    Active: -1,
  },
  {
    ID: 316,
    ProvinceCode: "28",
    Code: "2826",
    Name: "Huyện Tĩnh Gia",
    Active: -1,
  },
  {
    ID: 317,
    ProvinceCode: "28",
    Code: "2827",
    Name: "Huyện Yên Định",
    Active: -1,
  },
  {
    ID: 318,
    ProvinceCode: "29",
    Code: "2901",
    Name: "Thành phố Vinh",
    Active: -1,
  },
  {
    ID: 319,
    ProvinceCode: "29",
    Code: "2902",
    Name: "Thị xã Cửa Lò",
    Active: -1,
  },
  {
    ID: 320,
    ProvinceCode: "29",
    Code: "2903",
    Name: "Huyện Quỳ Châu",
    Active: -1,
  },
  {
    ID: 321,
    ProvinceCode: "29",
    Code: "2904",
    Name: "Huyện Quỳ Hợp",
    Active: -1,
  },
  {
    ID: 322,
    ProvinceCode: "29",
    Code: "2905",
    Name: "Huyện Nghĩa Đàn",
    Active: -1,
  },
  {
    ID: 323,
    ProvinceCode: "29",
    Code: "2906",
    Name: "Huyện Quỳnh Lưu",
    Active: -1,
  },
  {
    ID: 324,
    ProvinceCode: "29",
    Code: "2907",
    Name: "Huyện Kỳ Sơn",
    Active: -1,
  },
  {
    ID: 325,
    ProvinceCode: "29",
    Code: "2908",
    Name: "Huyện Tương Dương",
    Active: -1,
  },
  {
    ID: 326,
    ProvinceCode: "29",
    Code: "2909",
    Name: "Huyện Con Cuông",
    Active: -1,
  },
  {
    ID: 327,
    ProvinceCode: "29",
    Code: "2910",
    Name: "Huyện Tân Kỳ",
    Active: -1,
  },
  {
    ID: 328,
    ProvinceCode: "29",
    Code: "2911",
    Name: "Huyện Yên Thành",
    Active: -1,
  },
  {
    ID: 329,
    ProvinceCode: "29",
    Code: "2912",
    Name: "Huyện Diễn Châu",
    Active: -1,
  },
  {
    ID: 330,
    ProvinceCode: "29",
    Code: "2913",
    Name: "Huyện Anh Sơn",
    Active: -1,
  },
  {
    ID: 331,
    ProvinceCode: "29",
    Code: "2914",
    Name: "Huyện Đô Lương",
    Active: -1,
  },
  {
    ID: 332,
    ProvinceCode: "29",
    Code: "2915",
    Name: "Huyện Thanh Chương",
    Active: -1,
  },
  {
    ID: 333,
    ProvinceCode: "29",
    Code: "2916",
    Name: "Huyện Nghi Lộc",
    Active: -1,
  },
  {
    ID: 334,
    ProvinceCode: "29",
    Code: "2917",
    Name: "Huyện Nam Đàn",
    Active: -1,
  },
  {
    ID: 335,
    ProvinceCode: "29",
    Code: "2918",
    Name: "Huyện Hưng Nguyên",
    Active: -1,
  },
  {
    ID: 336,
    ProvinceCode: "29",
    Code: "2919",
    Name: "Huyện Quế Phong",
    Active: -1,
  },
  {
    ID: 337,
    ProvinceCode: "29",
    Code: "2920",
    Name: "Thị Xã Thái Hòa",
    Active: -1,
  },
  {
    ID: 338,
    ProvinceCode: "30",
    Code: "3001",
    Name: "Thành phố Hà Tĩnh",
    Active: -1,
  },
  {
    ID: 339,
    ProvinceCode: "30",
    Code: "3002",
    Name: "Thị xã Hồng Lĩnh",
    Active: -1,
  },
  {
    ID: 340,
    ProvinceCode: "30",
    Code: "3003",
    Name: "Huyện Hương Sơn",
    Active: -1,
  },
  {
    ID: 341,
    ProvinceCode: "30",
    Code: "3004",
    Name: "Huyện Đức Thọ",
    Active: -1,
  },
  {
    ID: 342,
    ProvinceCode: "30",
    Code: "3005",
    Name: "Huyện Nghi Xuân",
    Active: -1,
  },
  {
    ID: 343,
    ProvinceCode: "30",
    Code: "3006",
    Name: "Huyện Can Lộc",
    Active: -1,
  },
  {
    ID: 344,
    ProvinceCode: "30",
    Code: "3007",
    Name: "Huyện Hương Khê",
    Active: -1,
  },
  {
    ID: 345,
    ProvinceCode: "30",
    Code: "3008",
    Name: "Huyện Thạch Hà",
    Active: -1,
  },
  {
    ID: 346,
    ProvinceCode: "30",
    Code: "3009",
    Name: "Huyện Cẩm Xuyên",
    Active: -1,
  },
  {
    ID: 347,
    ProvinceCode: "30",
    Code: "3010",
    Name: "Thị xã Kỳ Anh",
    Active: -1,
  },
  {
    ID: 348,
    ProvinceCode: "30",
    Code: "3011",
    Name: "Huyện Vũ Quang",
    Active: -1,
  },
  {
    ID: 349,
    ProvinceCode: "30",
    Code: "3012",
    Name: "Huyện Lộc Hà",
    Active: -1,
  },
  {
    ID: 350,
    ProvinceCode: "31",
    Code: "3101",
    Name: "Thành phố Đồng Hới",
    Active: -1,
  },
  {
    ID: 351,
    ProvinceCode: "31",
    Code: "3102",
    Name: "Huyện Tuyên Hoá",
    Active: -1,
  },
  {
    ID: 352,
    ProvinceCode: "31",
    Code: "3103",
    Name: "Huyện Minh Hoá",
    Active: -1,
  },
  {
    ID: 353,
    ProvinceCode: "31",
    Code: "3104",
    Name: "Huyện Quảng Trạch",
    Active: -1,
  },
  {
    ID: 354,
    ProvinceCode: "31",
    Code: "3105",
    Name: "Huyện Bố Trạch",
    Active: -1,
  },
  {
    ID: 355,
    ProvinceCode: "31",
    Code: "3106",
    Name: "Huyện Quảng Ninh",
    Active: -1,
  },
  {
    ID: 356,
    ProvinceCode: "31",
    Code: "3107",
    Name: "Huyện Lệ Thuỷ",
    Active: -1,
  },
  {
    ID: 357,
    ProvinceCode: "32",
    Code: "3201",
    Name: "Thành phố Đông Hà",
    Active: -1,
  },
  {
    ID: 358,
    ProvinceCode: "32",
    Code: "3202",
    Name: "Thị xã Quảng Trị",
    Active: -1,
  },
  {
    ID: 359,
    ProvinceCode: "32",
    Code: "3203",
    Name: "Huyện Vĩnh Linh",
    Active: -1,
  },
  {
    ID: 360,
    ProvinceCode: "32",
    Code: "3204",
    Name: "Huyện Gio Linh",
    Active: -1,
  },
  {
    ID: 361,
    ProvinceCode: "32",
    Code: "3205",
    Name: "Huyện Cam Lộ",
    Active: -1,
  },
  {
    ID: 362,
    ProvinceCode: "32",
    Code: "3206",
    Name: "Huyện Triệu Phong",
    Active: -1,
  },
  {
    ID: 363,
    ProvinceCode: "32",
    Code: "3207",
    Name: "Huyện Hải Lăng",
    Active: -1,
  },
  {
    ID: 364,
    ProvinceCode: "32",
    Code: "3208",
    Name: "Huyện Hướng Hóa",
    Active: -1,
  },
  {
    ID: 365,
    ProvinceCode: "32",
    Code: "3209",
    Name: "Huyện Đăk Rông",
    Active: -1,
  },
  {
    ID: 366,
    ProvinceCode: "32",
    Code: "3210",
    Name: "Huyện đảo Cồn Cỏ",
    Active: -1,
  },
  {
    ID: 367,
    ProvinceCode: "33",
    Code: "3301",
    Name: "Thành phố Huế",
    Active: -1,
  },
  {
    ID: 368,
    ProvinceCode: "33",
    Code: "3302",
    Name: "Huyện Phong Điền",
    Active: -1,
  },
  {
    ID: 369,
    ProvinceCode: "33",
    Code: "3303",
    Name: "Huyện Quảng Điền",
    Active: -1,
  },
  {
    ID: 370,
    ProvinceCode: "33",
    Code: "3304",
    Name: "Thị xã Hương Trà",
    Active: -1,
  },
  {
    ID: 371,
    ProvinceCode: "33",
    Code: "3305",
    Name: "Huyện Phú Vang",
    Active: -1,
  },
  {
    ID: 372,
    ProvinceCode: "33",
    Code: "3306",
    Name: "Thị xã Hương Thủy",
    Active: -1,
  },
  {
    ID: 373,
    ProvinceCode: "33",
    Code: "3307",
    Name: "Huyện Phú Lộc",
    Active: -1,
  },
  {
    ID: 374,
    ProvinceCode: "33",
    Code: "3308",
    Name: "Huyện Nam Đông",
    Active: -1,
  },
  {
    ID: 375,
    ProvinceCode: "33",
    Code: "3309",
    Name: "Huyện A Lưới",
    Active: -1,
  },
  {
    ID: 376,
    ProvinceCode: "34",
    Code: "3401",
    Name: "Thành phố Tam Kỳ",
    Active: -1,
  },
  {
    ID: 377,
    ProvinceCode: "34",
    Code: "3402",
    Name: "Thành phố Hội An",
    Active: -1,
  },
  {
    ID: 378,
    ProvinceCode: "34",
    Code: "3403",
    Name: "Huyện Duy Xuyên",
    Active: -1,
  },
  {
    ID: 379,
    ProvinceCode: "34",
    Code: "3404",
    Name: "Thị xã Điện Bàn",
    Active: -1,
  },
  {
    ID: 380,
    ProvinceCode: "34",
    Code: "3405",
    Name: "Huyện Đại Lộc",
    Active: -1,
  },
  {
    ID: 381,
    ProvinceCode: "34",
    Code: "3406",
    Name: "Huyện Quế Sơn",
    Active: -1,
  },
  {
    ID: 382,
    ProvinceCode: "34",
    Code: "3407",
    Name: "Huyện Hiệp Đức",
    Active: -1,
  },
  {
    ID: 383,
    ProvinceCode: "34",
    Code: "3408",
    Name: "Huyện Thăng Bình",
    Active: -1,
  },
  {
    ID: 384,
    ProvinceCode: "34",
    Code: "3409",
    Name: "Huyện Núi Thành",
    Active: -1,
  },
  {
    ID: 385,
    ProvinceCode: "34",
    Code: "3410",
    Name: "Huyện Tiên Phước",
    Active: -1,
  },
  {
    ID: 386,
    ProvinceCode: "34",
    Code: "3411",
    Name: "Huyện Bắc Trà My",
    Active: -1,
  },
  {
    ID: 387,
    ProvinceCode: "34",
    Code: "3412",
    Name: "Huyện Đông Giang",
    Active: -1,
  },
  {
    ID: 388,
    ProvinceCode: "34",
    Code: "3413",
    Name: "Huyện Nam Giang",
    Active: -1,
  },
  {
    ID: 389,
    ProvinceCode: "34",
    Code: "3414",
    Name: "Huyện Phước Sơn",
    Active: -1,
  },
  {
    ID: 390,
    ProvinceCode: "34",
    Code: "3415",
    Name: "Huyện Nam Trà My",
    Active: -1,
  },
  {
    ID: 391,
    ProvinceCode: "34",
    Code: "3416",
    Name: "Huyện Tây Giang",
    Active: -1,
  },
  {
    ID: 392,
    ProvinceCode: "34",
    Code: "3417",
    Name: "Huyện Phú Ninh",
    Active: -1,
  },
  {
    ID: 393,
    ProvinceCode: "34",
    Code: "3418",
    Name: "Huyện Nông Sơn",
    Active: -1,
  },
  {
    ID: 394,
    ProvinceCode: "35",
    Code: "3501",
    Name: "Thành phố Quảng Ngãi",
    Active: -1,
  },
  {
    ID: 395,
    ProvinceCode: "35",
    Code: "3502",
    Name: "Huyện Lý Sơn",
    Active: -1,
  },
  {
    ID: 396,
    ProvinceCode: "35",
    Code: "3503",
    Name: "Huyện Bình Sơn",
    Active: -1,
  },
  {
    ID: 397,
    ProvinceCode: "35",
    Code: "3504",
    Name: "Huyện Trà Bồng",
    Active: -1,
  },
  {
    ID: 398,
    ProvinceCode: "35",
    Code: "3505",
    Name: "Huyện Sơn Tịnh",
    Active: -1,
  },
  {
    ID: 399,
    ProvinceCode: "35",
    Code: "3506",
    Name: "Huyện Sơn Hà",
    Active: -1,
  },
  {
    ID: 400,
    ProvinceCode: "35",
    Code: "3507",
    Name: "Huyện Tư Nghĩa",
    Active: -1,
  },
  {
    ID: 401,
    ProvinceCode: "35",
    Code: "3508",
    Name: "Huyện Nghĩa Hành",
    Active: -1,
  },
  {
    ID: 402,
    ProvinceCode: "35",
    Code: "3509",
    Name: "Huyện Minh Long",
    Active: -1,
  },
  {
    ID: 403,
    ProvinceCode: "35",
    Code: "3510",
    Name: "Huyện Mộ Đức",
    Active: -1,
  },
  {
    ID: 404,
    ProvinceCode: "35",
    Code: "3511",
    Name: "Thị xã Đức Phổ",
    Active: -1,
  },
  {
    ID: 405,
    ProvinceCode: "35",
    Code: "3512",
    Name: "Huyện Ba Tơ",
    Active: -1,
  },
  {
    ID: 406,
    ProvinceCode: "35",
    Code: "3513",
    Name: "Huyện Sơn Tây",
    Active: -1,
  },
  {
    ID: 407,
    ProvinceCode: "35",
    Code: "3514",
    Name: "Huyện Tây Trà",
    Active: -1,
  },
  {
    ID: 408,
    ProvinceCode: "36",
    Code: "3601",
    Name: "Thành phố Kon Tum",
    Active: -1,
  },
  {
    ID: 409,
    ProvinceCode: "36",
    Code: "3602",
    Name: "Huyện Đắk Glei",
    Active: -1,
  },
  {
    ID: 410,
    ProvinceCode: "36",
    Code: "3603",
    Name: "Huyện Ngọc Hồi",
    Active: -1,
  },
  {
    ID: 411,
    ProvinceCode: "36",
    Code: "3604",
    Name: "Huyện Đắk Tô",
    Active: -1,
  },
  {
    ID: 412,
    ProvinceCode: "36",
    Code: "3605",
    Name: "Huyện Sa Thầy",
    Active: -1,
  },
  {
    ID: 413,
    ProvinceCode: "36",
    Code: "3606",
    Name: "Huyện Kon Plông",
    Active: -1,
  },
  {
    ID: 414,
    ProvinceCode: "36",
    Code: "3607",
    Name: "Huyện Đắk Hà",
    Active: -1,
  },
  {
    ID: 415,
    ProvinceCode: "36",
    Code: "3608",
    Name: "Huyện Kon Rẫy",
    Active: -1,
  },
  {
    ID: 416,
    ProvinceCode: "36",
    Code: "3609",
    Name: "Huyện Tu Mơ Rông",
    Active: -1,
  },
  {
    ID: 417,
    ProvinceCode: "37",
    Code: "3701",
    Name: "Thành phố Quy Nhơn",
    Active: -1,
  },
  {
    ID: 418,
    ProvinceCode: "37",
    Code: "3702",
    Name: "Huyện An Lão",
    Active: -1,
  },
  {
    ID: 419,
    ProvinceCode: "37",
    Code: "3703",
    Name: "Huyện Hoài Ân",
    Active: -1,
  },
  {
    ID: 420,
    ProvinceCode: "37",
    Code: "3704",
    Name: "Thị xã Hoài Nhơn",
    Active: -1,
  },
  {
    ID: 421,
    ProvinceCode: "37",
    Code: "3705",
    Name: "Huyện Phù Mỹ",
    Active: -1,
  },
  {
    ID: 422,
    ProvinceCode: "37",
    Code: "3706",
    Name: "Huyện Phù Cát",
    Active: -1,
  },
  {
    ID: 423,
    ProvinceCode: "37",
    Code: "3707",
    Name: "Huyện Vĩnh Thạnh",
    Active: -1,
  },
  {
    ID: 424,
    ProvinceCode: "37",
    Code: "3708",
    Name: "Huyện Tây Sơn",
    Active: -1,
  },
  {
    ID: 425,
    ProvinceCode: "37",
    Code: "3709",
    Name: "Huyện Vân Canh",
    Active: -1,
  },
  {
    ID: 426,
    ProvinceCode: "37",
    Code: "3710",
    Name: "Thị xã An Nhơn",
    Active: -1,
  },
  {
    ID: 427,
    ProvinceCode: "37",
    Code: "3711",
    Name: "Huyện Tuy Phước",
    Active: -1,
  },
  {
    ID: 428,
    ProvinceCode: "38",
    Code: "3801",
    Name: "Thành phố Pleiku",
    Active: -1,
  },
  {
    ID: 429,
    ProvinceCode: "38",
    Code: "3802",
    Name: "Huyện Chư Păh",
    Active: -1,
  },
  {
    ID: 430,
    ProvinceCode: "38",
    Code: "3803",
    Name: "Huyện Mang Yang",
    Active: -1,
  },
  {
    ID: 431,
    ProvinceCode: "38",
    Code: "3804",
    Name: "Huyện Kbang",
    Active: -1,
  },
  {
    ID: 432,
    ProvinceCode: "38",
    Code: "3805",
    Name: "Thị xã An Khê",
    Active: -1,
  },
  {
    ID: 433,
    ProvinceCode: "38",
    Code: "3806",
    Name: "Huyện Kông Chro",
    Active: -1,
  },
  {
    ID: 434,
    ProvinceCode: "38",
    Code: "3807",
    Name: "Huyện Đức Cơ",
    Active: -1,
  },
  {
    ID: 435,
    ProvinceCode: "38",
    Code: "3808",
    Name: "Huyện Chư Prông",
    Active: -1,
  },
  {
    ID: 436,
    ProvinceCode: "38",
    Code: "3809",
    Name: "Huyện Chư Sê",
    Active: -1,
  },
  {
    ID: 437,
    ProvinceCode: "38",
    Code: "3810",
    Name: "Thị xã Ayunpa",
    Active: -1,
  },
  {
    ID: 438,
    ProvinceCode: "38",
    Code: "3811",
    Name: "Huyện Krông Pa",
    Active: -1,
  },
  {
    ID: 439,
    ProvinceCode: "38",
    Code: "3812",
    Name: "Huyện Ia Grai",
    Active: -1,
  },
  {
    ID: 440,
    ProvinceCode: "38",
    Code: "3813",
    Name: "Huyện Đăk Đoa",
    Active: -1,
  },
  {
    ID: 441,
    ProvinceCode: "38",
    Code: "3814",
    Name: "Huyện Ia Pa",
    Active: -1,
  },
  {
    ID: 442,
    ProvinceCode: "38",
    Code: "3815",
    Name: "Huyện Đăk Pơ",
    Active: -1,
  },
  {
    ID: 443,
    ProvinceCode: "38",
    Code: "3816",
    Name: "Huyện Phú Thiện",
    Active: -1,
  },
  {
    ID: 444,
    ProvinceCode: "38",
    Code: "3817",
    Name: "Huyện Chư Pưh",
    Active: -1,
  },
  {
    ID: 445,
    ProvinceCode: "39",
    Code: "3900",
    Name: "Sở Giáo dục và Đào tạo",
    Active: -1,
  },
  {
    ID: 446,
    ProvinceCode: "39",
    Code: "3901",
    Name: "Thành phố Tuy Hoà",
    Active: -1,
  },
  {
    ID: 447,
    ProvinceCode: "39",
    Code: "3902",
    Name: "Huyện Đồng Xuân",
    Active: -1,
  },
  {
    ID: 448,
    ProvinceCode: "39",
    Code: "3903",
    Name: "Thị Xã Sông Cầu",
    Active: -1,
  },
  {
    ID: 449,
    ProvinceCode: "39",
    Code: "3904",
    Name: "Huyện Tuy An",
    Active: -1,
  },
  {
    ID: 450,
    ProvinceCode: "39",
    Code: "3905",
    Name: "Huyện Sơn Hoà",
    Active: -1,
  },
  {
    ID: 451,
    ProvinceCode: "39",
    Code: "3906",
    Name: "Huyện Sông Hinh",
    Active: -1,
  },
  {
    ID: 452,
    ProvinceCode: "39",
    Code: "3907",
    Name: "Thị xã Đông Hòa",
    Active: -1,
  },
  {
    ID: 453,
    ProvinceCode: "39",
    Code: "3908",
    Name: "Huyện Phú Hoà",
    Active: -1,
  },
  {
    ID: 454,
    ProvinceCode: "39",
    Code: "3909",
    Name: "Huyện Tây Hoà",
    Active: -1,
  },
  {
    ID: 455,
    ProvinceCode: "40",
    Code: "4001",
    Name: "Thành phố Buôn Ma Thuột",
    Active: -1,
  },
  {
    ID: 456,
    ProvinceCode: "40",
    Code: "4002",
    Name: "Huyện Ea H Leo",
    Active: -1,
  },
  {
    ID: 457,
    ProvinceCode: "40",
    Code: "4003",
    Name: "Huyện Krông Buk",
    Active: -1,
  },
  {
    ID: 458,
    ProvinceCode: "40",
    Code: "4004",
    Name: "Huyện Krông Năng",
    Active: -1,
  },
  {
    ID: 459,
    ProvinceCode: "40",
    Code: "4005",
    Name: "Huyện Ea Súp",
    Active: -1,
  },
  {
    ID: 460,
    ProvinceCode: "40",
    Code: "4006",
    Name: "Huyện Cư M’gar",
    Active: -1,
  },
  {
    ID: 461,
    ProvinceCode: "40",
    Code: "4007",
    Name: "Huyện Krông Pắc",
    Active: -1,
  },
  {
    ID: 462,
    ProvinceCode: "40",
    Code: "4008",
    Name: "Huyện Ea Kar",
    Active: -1,
  },
  {
    ID: 463,
    ProvinceCode: "40",
    Code: "4009",
    Name: "Huyện M'Đrăk",
    Active: -1,
  },
  {
    ID: 464,
    ProvinceCode: "40",
    Code: "4010",
    Name: "Huyện Krông Ana",
    Active: -1,
  },
  {
    ID: 465,
    ProvinceCode: "40",
    Code: "4011",
    Name: "Huyện Krông Bông",
    Active: -1,
  },
  {
    ID: 466,
    ProvinceCode: "40",
    Code: "4012",
    Name: "Huyện Lăk",
    Active: -1,
  },
  {
    ID: 467,
    ProvinceCode: "40",
    Code: "4013",
    Name: "Huyện Buôn Đôn",
    Active: -1,
  },
  {
    ID: 468,
    ProvinceCode: "40",
    Code: "4014",
    Name: "Huyện Cư Kuin",
    Active: -1,
  },
  {
    ID: 469,
    ProvinceCode: "40",
    Code: "4015",
    Name: "Thị Xã Buôn Hồ",
    Active: -1,
  },
  {
    ID: 470,
    ProvinceCode: "41",
    Code: "4101",
    Name: "Thành phố Nha Trang",
    Active: -1,
  },
  {
    ID: 471,
    ProvinceCode: "41",
    Code: "4102",
    Name: "Huyện Vạn Ninh",
    Active: -1,
  },
  {
    ID: 472,
    ProvinceCode: "41",
    Code: "4103",
    Name: "Thị xã Ninh Hòa",
    Active: -1,
  },
  {
    ID: 473,
    ProvinceCode: "41",
    Code: "4104",
    Name: "Huyện Diên Khánh",
    Active: -1,
  },
  {
    ID: 474,
    ProvinceCode: "41",
    Code: "4105",
    Name: "Huyện Khánh Vĩnh",
    Active: -1,
  },
  {
    ID: 475,
    ProvinceCode: "41",
    Code: "4106",
    Name: "Thành phố Cam Ranh",
    Active: -1,
  },
  {
    ID: 476,
    ProvinceCode: "41",
    Code: "4107",
    Name: "Huyện Khánh Sơn",
    Active: -1,
  },
  {
    ID: 477,
    ProvinceCode: "41",
    Code: "4108",
    Name: "Huyện đảo Trường Sa",
    Active: -1,
  },
  {
    ID: 478,
    ProvinceCode: "41",
    Code: "4109",
    Name: "Huyện Cam Lâm",
    Active: -1,
  },
  {
    ID: 479,
    ProvinceCode: "42",
    Code: "4201",
    Name: "Thành phố Đà Lạt",
    Active: -1,
  },
  {
    ID: 480,
    ProvinceCode: "42",
    Code: "4202",
    Name: "Thành phố Bảo Lộc",
    Active: -1,
  },
  {
    ID: 481,
    ProvinceCode: "42",
    Code: "4203",
    Name: "Huyện Đức Trọng",
    Active: -1,
  },
  {
    ID: 482,
    ProvinceCode: "42",
    Code: "4204",
    Name: "Huyện Di Linh",
    Active: -1,
  },
  {
    ID: 483,
    ProvinceCode: "42",
    Code: "4205",
    Name: "Huyện Đơn Dương",
    Active: -1,
  },
  {
    ID: 484,
    ProvinceCode: "42",
    Code: "4206",
    Name: "Huyện Lạc Dương",
    Active: -1,
  },
  {
    ID: 485,
    ProvinceCode: "42",
    Code: "4207",
    Name: "Huyện Đạ Huoai",
    Active: -1,
  },
  {
    ID: 486,
    ProvinceCode: "42",
    Code: "4208",
    Name: "Huyện Đạ Tẻh",
    Active: -1,
  },
  {
    ID: 487,
    ProvinceCode: "42",
    Code: "4209",
    Name: "Huyện Cát Tiên",
    Active: -1,
  },
  {
    ID: 488,
    ProvinceCode: "42",
    Code: "4210",
    Name: "Huyện Lâm Hà",
    Active: -1,
  },
  {
    ID: 489,
    ProvinceCode: "42",
    Code: "4211",
    Name: "Huyện Bảo Lâm",
    Active: -1,
  },
  {
    ID: 490,
    ProvinceCode: "42",
    Code: "4212",
    Name: "Huyện Đam Rông",
    Active: -1,
  },
  {
    ID: 491,
    ProvinceCode: "43",
    Code: "4301",
    Name: "Thành phố Đồng Xoài",
    Active: -1,
  },
  {
    ID: 492,
    ProvinceCode: "43",
    Code: "4302",
    Name: "Huyện Đồng Phú",
    Active: -1,
  },
  {
    ID: 493,
    ProvinceCode: "43",
    Code: "4303",
    Name: "Huyện Chơn Thành",
    Active: -1,
  },
  {
    ID: 494,
    ProvinceCode: "43",
    Code: "4304",
    Name: "Thị xã Bình Long",
    Active: -1,
  },
  {
    ID: 495,
    ProvinceCode: "43",
    Code: "4305",
    Name: "Huyện Lộc Ninh",
    Active: -1,
  },
  {
    ID: 496,
    ProvinceCode: "43",
    Code: "4306",
    Name: "Huyện Bù Đốp",
    Active: -1,
  },
  {
    ID: 497,
    ProvinceCode: "43",
    Code: "4307",
    Name: "Thị xã Phước Long",
    Active: -1,
  },
  {
    ID: 498,
    ProvinceCode: "43",
    Code: "4308",
    Name: "Huyện Bù Đăng",
    Active: -1,
  },
  {
    ID: 499,
    ProvinceCode: "43",
    Code: "4309",
    Name: "Huyện Hớn Quản",
    Active: -1,
  },
  {
    ID: 500,
    ProvinceCode: "43",
    Code: "4310",
    Name: "Huyện Bù Gia Mập",
    Active: -1,
  },
  {
    ID: 501,
    ProvinceCode: "44",
    Code: "4401",
    Name: "Thành phố Thủ Dầu Một",
    Active: -1,
  },
  {
    ID: 502,
    ProvinceCode: "44",
    Code: "4402",
    Name: "Thị xã Bến Cát",
    Active: -1,
  },
  {
    ID: 503,
    ProvinceCode: "44",
    Code: "4403",
    Name: "Thị xã Tân Uyên",
    Active: -1,
  },
  {
    ID: 504,
    ProvinceCode: "44",
    Code: "4404",
    Name: "Thành phố Thuận An",
    Active: -1,
  },
  {
    ID: 505,
    ProvinceCode: "44",
    Code: "4405",
    Name: "Thành phố Dĩ An",
    Active: -1,
  },
  {
    ID: 506,
    ProvinceCode: "44",
    Code: "4406",
    Name: "Huyện Phú Giáo",
    Active: -1,
  },
  {
    ID: 507,
    ProvinceCode: "44",
    Code: "4407",
    Name: "Huyện Dầu Tiếng",
    Active: -1,
  },
  {
    ID: 508,
    ProvinceCode: "45",
    Code: "4501",
    Name: "Thành phố Phan Rang -Tháp Chàm",
    Active: -1,
  },
  {
    ID: 509,
    ProvinceCode: "45",
    Code: "4502",
    Name: "Huyện Ninh Sơn",
    Active: -1,
  },
  {
    ID: 510,
    ProvinceCode: "45",
    Code: "4503",
    Name: "Huyện Ninh Hải",
    Active: -1,
  },
  {
    ID: 511,
    ProvinceCode: "45",
    Code: "4504",
    Name: "Huyện Ninh Phước",
    Active: -1,
  },
  {
    ID: 512,
    ProvinceCode: "45",
    Code: "4505",
    Name: "Huyện Bác Ái",
    Active: -1,
  },
  {
    ID: 513,
    ProvinceCode: "45",
    Code: "4506",
    Name: "Huyện Thuận Bắc",
    Active: -1,
  },
  {
    ID: 514,
    ProvinceCode: "45",
    Code: "4507",
    Name: "Huyện Thuận Nam",
    Active: -1,
  },
  {
    ID: 515,
    ProvinceCode: "46",
    Code: "4601",
    Name: "Thành phố Tây Ninh",
    Active: -1,
  },
  {
    ID: 516,
    ProvinceCode: "46",
    Code: "4602",
    Name: "Huyện Tân Biên",
    Active: -1,
  },
  {
    ID: 517,
    ProvinceCode: "46",
    Code: "4603",
    Name: "Huyện Tân Châu",
    Active: -1,
  },
  {
    ID: 518,
    ProvinceCode: "46",
    Code: "4604",
    Name: "Huyện Dương Minh Châu",
    Active: -1,
  },
  {
    ID: 519,
    ProvinceCode: "46",
    Code: "4605",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 520,
    ProvinceCode: "46",
    Code: "4606",
    Name: "Thị xã Hòa Thành",
    Active: -1,
  },
  {
    ID: 521,
    ProvinceCode: "46",
    Code: "4607",
    Name: "Huyện Bến Cầu",
    Active: -1,
  },
  {
    ID: 522,
    ProvinceCode: "46",
    Code: "4608",
    Name: "Huyện Gò Dầu",
    Active: -1,
  },
  {
    ID: 523,
    ProvinceCode: "46",
    Code: "4609",
    Name: "Thị xã Trảng Bàng",
    Active: -1,
  },
  {
    ID: 524,
    ProvinceCode: "47",
    Code: "4701",
    Name: "Thành phố Phan Thiết",
    Active: -1,
  },
  {
    ID: 525,
    ProvinceCode: "47",
    Code: "4702",
    Name: "Huyện Tuy Phong",
    Active: -1,
  },
  {
    ID: 526,
    ProvinceCode: "47",
    Code: "4703",
    Name: "Huyện Bắc Bình",
    Active: -1,
  },
  {
    ID: 527,
    ProvinceCode: "47",
    Code: "4704",
    Name: "Huyện Hàm Thuận Bắc",
    Active: -1,
  },
  {
    ID: 528,
    ProvinceCode: "47",
    Code: "4705",
    Name: "Huyện Hàm Thuận Nam",
    Active: -1,
  },
  {
    ID: 529,
    ProvinceCode: "47",
    Code: "4706",
    Name: "Huyện Hàm Tân",
    Active: -1,
  },
  {
    ID: 530,
    ProvinceCode: "47",
    Code: "4707",
    Name: "Huyện Đức Linh",
    Active: -1,
  },
  {
    ID: 531,
    ProvinceCode: "47",
    Code: "4708",
    Name: "Huyện Tánh Linh",
    Active: -1,
  },
  {
    ID: 532,
    ProvinceCode: "47",
    Code: "4709",
    Name: "Huyện đảo Phú Quý",
    Active: -1,
  },
  {
    ID: 533,
    ProvinceCode: "47",
    Code: "4710",
    Name: "Thị xã La Gi",
    Active: -1,
  },
  {
    ID: 534,
    ProvinceCode: "48",
    Code: "4801",
    Name: "Thành phố Biên Hoà",
    Active: -1,
  },
  {
    ID: 535,
    ProvinceCode: "48",
    Code: "4802",
    Name: "Huyện Vĩnh Cửu",
    Active: -1,
  },
  {
    ID: 536,
    ProvinceCode: "48",
    Code: "4803",
    Name: "Huyện Tân Phú",
    Active: -1,
  },
  {
    ID: 537,
    ProvinceCode: "48",
    Code: "4804",
    Name: "Huyện Định Quán",
    Active: -1,
  },
  {
    ID: 538,
    ProvinceCode: "48",
    Code: "4805",
    Name: "Huyện Thống Nhất",
    Active: -1,
  },
  {
    ID: 539,
    ProvinceCode: "48",
    Code: "4806",
    Name: "Thành phố Long Khánh",
    Active: -1,
  },
  {
    ID: 540,
    ProvinceCode: "48",
    Code: "4807",
    Name: "Huyện Xuân Lộc",
    Active: -1,
  },
  {
    ID: 541,
    ProvinceCode: "48",
    Code: "4808",
    Name: "Huyện Long Thành",
    Active: -1,
  },
  {
    ID: 542,
    ProvinceCode: "48",
    Code: "4809",
    Name: "Huyện Nhơn Trạch",
    Active: -1,
  },
  {
    ID: 543,
    ProvinceCode: "48",
    Code: "4810",
    Name: "Huyện Trảng Bom",
    Active: -1,
  },
  {
    ID: 544,
    ProvinceCode: "48",
    Code: "4811",
    Name: "Huyện Cẩm Mỹ",
    Active: -1,
  },
  {
    ID: 545,
    ProvinceCode: "49",
    Code: "4901",
    Name: "Thành phố Tân An",
    Active: -1,
  },
  {
    ID: 546,
    ProvinceCode: "49",
    Code: "4902",
    Name: "Huyện Vĩnh Hưng",
    Active: -1,
  },
  {
    ID: 547,
    ProvinceCode: "49",
    Code: "4903",
    Name: "Huyện Mộc Hoá",
    Active: -1,
  },
  {
    ID: 548,
    ProvinceCode: "49",
    Code: "4904",
    Name: "Huyện Tân Thạnh",
    Active: -1,
  },
  {
    ID: 549,
    ProvinceCode: "49",
    Code: "4905",
    Name: "Huyện Thạnh Hoá",
    Active: -1,
  },
  {
    ID: 550,
    ProvinceCode: "49",
    Code: "4906",
    Name: "Huyện Đức Huệ",
    Active: -1,
  },
  {
    ID: 551,
    ProvinceCode: "49",
    Code: "4907",
    Name: "Huyện Đức Hoà",
    Active: -1,
  },
  {
    ID: 552,
    ProvinceCode: "49",
    Code: "4908",
    Name: "Huyện Bến Lức",
    Active: -1,
  },
  {
    ID: 553,
    ProvinceCode: "49",
    Code: "4909",
    Name: "Huyện Thủ Thừa",
    Active: -1,
  },
  {
    ID: 554,
    ProvinceCode: "49",
    Code: "4910",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 555,
    ProvinceCode: "49",
    Code: "4911",
    Name: "Huyện Tân Trụ",
    Active: -1,
  },
  {
    ID: 556,
    ProvinceCode: "49",
    Code: "4912",
    Name: "Huyện Cần Đước",
    Active: -1,
  },
  {
    ID: 557,
    ProvinceCode: "49",
    Code: "4913",
    Name: "Huyện Cần Giuộc",
    Active: -1,
  },
  {
    ID: 558,
    ProvinceCode: "49",
    Code: "4914",
    Name: "Huyện Tân Hưng",
    Active: -1,
  },
  {
    ID: 559,
    ProvinceCode: "50",
    Code: "5001",
    Name: "Thành phố Cao Lãnh",
    Active: -1,
  },
  {
    ID: 560,
    ProvinceCode: "50",
    Code: "5002",
    Name: "Thành phố Sa Đéc",
    Active: -1,
  },
  {
    ID: 561,
    ProvinceCode: "50",
    Code: "5003",
    Name: "Huyện Tân Hồng",
    Active: -1,
  },
  {
    ID: 562,
    ProvinceCode: "50",
    Code: "5004",
    Name: "Huyện Hồng Ngự",
    Active: -1,
  },
  {
    ID: 563,
    ProvinceCode: "50",
    Code: "5005",
    Name: "Huyện Tam Nông",
    Active: -1,
  },
  {
    ID: 564,
    ProvinceCode: "50",
    Code: "5006",
    Name: "Huyện Thanh Bình",
    Active: -1,
  },
  {
    ID: 565,
    ProvinceCode: "50",
    Code: "5007",
    Name: "Huyện Cao Lãnh",
    Active: -1,
  },
  {
    ID: 566,
    ProvinceCode: "50",
    Code: "5008",
    Name: "Huyện Lấp Vò",
    Active: -1,
  },
  {
    ID: 567,
    ProvinceCode: "50",
    Code: "5009",
    Name: "Huyện Tháp Mười",
    Active: -1,
  },
  {
    ID: 568,
    ProvinceCode: "50",
    Code: "5010",
    Name: "Huyện Lai Vung",
    Active: -1,
  },
  {
    ID: 569,
    ProvinceCode: "50",
    Code: "5011",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 570,
    ProvinceCode: "50",
    Code: "5012",
    Name: "Thị Xã Hồng Ngự",
    Active: -1,
  },
  {
    ID: 571,
    ProvinceCode: "51",
    Code: "5101",
    Name: "Thành phố Long Xuyên",
    Active: -1,
  },
  {
    ID: 572,
    ProvinceCode: "51",
    Code: "5102",
    Name: "Thành phố Châu Đốc",
    Active: -1,
  },
  {
    ID: 573,
    ProvinceCode: "51",
    Code: "5103",
    Name: "Huyện An Phú",
    Active: -1,
  },
  {
    ID: 574,
    ProvinceCode: "51",
    Code: "5104",
    Name: "Thị xã Tân Châu",
    Active: -1,
  },
  {
    ID: 575,
    ProvinceCode: "51",
    Code: "5105",
    Name: "Huyện Phú Tân",
    Active: -1,
  },
  {
    ID: 576,
    ProvinceCode: "51",
    Code: "5106",
    Name: "Huyện Tịnh Biên",
    Active: -1,
  },
  {
    ID: 577,
    ProvinceCode: "51",
    Code: "5107",
    Name: "Huyện Tri Tôn",
    Active: -1,
  },
  {
    ID: 578,
    ProvinceCode: "51",
    Code: "5108",
    Name: "Huyện Châu Phú",
    Active: -1,
  },
  {
    ID: 579,
    ProvinceCode: "51",
    Code: "5109",
    Name: "Huyện Chợ Mới",
    Active: -1,
  },
  {
    ID: 580,
    ProvinceCode: "51",
    Code: "5110",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 581,
    ProvinceCode: "51",
    Code: "5111",
    Name: "Huyện Thoại Sơn",
    Active: -1,
  },
  {
    ID: 582,
    ProvinceCode: "52",
    Code: "5201",
    Name: "Thành phố Vũng Tàu",
    Active: -1,
  },
  {
    ID: 583,
    ProvinceCode: "52",
    Code: "5202",
    Name: "Thành phố Bà Rịa",
    Active: -1,
  },
  {
    ID: 584,
    ProvinceCode: "52",
    Code: "5203",
    Name: "Huyện Xuyên Mộc",
    Active: -1,
  },
  {
    ID: 585,
    ProvinceCode: "52",
    Code: "5204",
    Name: "Huyện Long Điền",
    Active: -1,
  },
  {
    ID: 586,
    ProvinceCode: "52",
    Code: "5205",
    Name: "Huyện Côn Đảo",
    Active: -1,
  },
  {
    ID: 587,
    ProvinceCode: "52",
    Code: "5206",
    Name: "Huyện Tân Thành",
    Active: -1,
  },
  {
    ID: 588,
    ProvinceCode: "52",
    Code: "5207",
    Name: "Huyện Châu Đức",
    Active: -1,
  },
  {
    ID: 589,
    ProvinceCode: "52",
    Code: "5208",
    Name: "Huyện Đất Đỏ",
    Active: -1,
  },
  {
    ID: 590,
    ProvinceCode: "53",
    Code: "5301",
    Name: "Thành phố Mỹ Tho",
    Active: -1,
  },
  {
    ID: 591,
    ProvinceCode: "53",
    Code: "5302",
    Name: "Thị xã Gò Công",
    Active: -1,
  },
  {
    ID: 592,
    ProvinceCode: "53",
    Code: "5303",
    Name: "Huyện Cái Bè",
    Active: -1,
  },
  {
    ID: 593,
    ProvinceCode: "53",
    Code: "5304",
    Name: "Huyện Cai Lậy",
    Active: -1,
  },
  {
    ID: 594,
    ProvinceCode: "53",
    Code: "5305",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 595,
    ProvinceCode: "53",
    Code: "5306",
    Name: "Huyện Chợ Gạo",
    Active: -1,
  },
  {
    ID: 596,
    ProvinceCode: "53",
    Code: "5307",
    Name: "Huyện Gò Công Tây",
    Active: -1,
  },
  {
    ID: 597,
    ProvinceCode: "53",
    Code: "5308",
    Name: "Huyện Gò Công Đông",
    Active: -1,
  },
  {
    ID: 598,
    ProvinceCode: "53",
    Code: "5309",
    Name: "Huyện Tân Phước",
    Active: -1,
  },
  {
    ID: 599,
    ProvinceCode: "53",
    Code: "5310",
    Name: "Huyện Tân Phú Đông",
    Active: -1,
  },
  {
    ID: 600,
    ProvinceCode: "54",
    Code: "5401",
    Name: "Thành phố Rạch Giá",
    Active: -1,
  },
  {
    ID: 601,
    ProvinceCode: "54",
    Code: "5402",
    Name: "Thành phố Hà Tiên",
    Active: -1,
  },
  {
    ID: 602,
    ProvinceCode: "54",
    Code: "5403",
    Name: "Huyện Kiên Lương",
    Active: -1,
  },
  {
    ID: 603,
    ProvinceCode: "54",
    Code: "5404",
    Name: "Huyện Hòn Đất",
    Active: -1,
  },
  {
    ID: 604,
    ProvinceCode: "54",
    Code: "5405",
    Name: "Huyện Tân Hiệp",
    Active: -1,
  },
  {
    ID: 605,
    ProvinceCode: "54",
    Code: "5406",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 606,
    ProvinceCode: "54",
    Code: "5407",
    Name: "Huyện Giồng Riềng",
    Active: -1,
  },
  {
    ID: 607,
    ProvinceCode: "54",
    Code: "5408",
    Name: "Huyện Gò Quao",
    Active: -1,
  },
  {
    ID: 608,
    ProvinceCode: "54",
    Code: "5409",
    Name: "Huyện An Biên",
    Active: -1,
  },
  {
    ID: 609,
    ProvinceCode: "54",
    Code: "5410",
    Name: "Huyện An Minh",
    Active: -1,
  },
  {
    ID: 610,
    ProvinceCode: "54",
    Code: "5411",
    Name: "Huyện Vĩnh Thuận",
    Active: -1,
  },
  {
    ID: 611,
    ProvinceCode: "54",
    Code: "5412",
    Name: "Huyện đảo Phú Quốc",
    Active: -1,
  },
  {
    ID: 612,
    ProvinceCode: "54",
    Code: "5413",
    Name: "Huyện Kiên Hải",
    Active: -1,
  },
  {
    ID: 613,
    ProvinceCode: "54",
    Code: "5414",
    Name: "Huyện U Minh Thượng",
    Active: -1,
  },
  {
    ID: 614,
    ProvinceCode: "54",
    Code: "5415",
    Name: "Huyện Giang Thành",
    Active: -1,
  },
  {
    ID: 615,
    ProvinceCode: "55",
    Code: "5501",
    Name: "Quận Ninh Kiều",
    Active: -1,
  },
  {
    ID: 616,
    ProvinceCode: "55",
    Code: "5502",
    Name: "Quận Bình Thuỷ",
    Active: -1,
  },
  {
    ID: 617,
    ProvinceCode: "55",
    Code: "5503",
    Name: "Quận Cái Răng",
    Active: -1,
  },
  {
    ID: 618,
    ProvinceCode: "55",
    Code: "5504",
    Name: "Quận Ô Môn",
    Active: -1,
  },
  {
    ID: 619,
    ProvinceCode: "55",
    Code: "5505",
    Name: "Huyện Phong Điền",
    Active: -1,
  },
  {
    ID: 620,
    ProvinceCode: "55",
    Code: "5506",
    Name: "Huyện Cờ Đỏ",
    Active: -1,
  },
  {
    ID: 621,
    ProvinceCode: "55",
    Code: "5507",
    Name: "Huyện Vĩnh Thạnh",
    Active: -1,
  },
  {
    ID: 622,
    ProvinceCode: "55",
    Code: "5508",
    Name: "Quận Thốt Nốt",
    Active: -1,
  },
  {
    ID: 623,
    ProvinceCode: "55",
    Code: "5509",
    Name: "Huyện Thới Lai",
    Active: -1,
  },
  {
    ID: 624,
    ProvinceCode: "56",
    Code: "5601",
    Name: "Thành phố Bến Tre",
    Active: -1,
  },
  {
    ID: 625,
    ProvinceCode: "56",
    Code: "5602",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 626,
    ProvinceCode: "56",
    Code: "5603",
    Name: "Huyện Chợ Lách",
    Active: -1,
  },
  {
    ID: 627,
    ProvinceCode: "56",
    Code: "5604",
    Name: "Huyện Mỏ Cày Bắc",
    Active: -1,
  },
  {
    ID: 628,
    ProvinceCode: "56",
    Code: "5605",
    Name: "Huyện Giồng Trôm",
    Active: -1,
  },
  {
    ID: 629,
    ProvinceCode: "56",
    Code: "5606",
    Name: "Huyện Bình Đại",
    Active: -1,
  },
  {
    ID: 630,
    ProvinceCode: "56",
    Code: "5607",
    Name: "Huyện Ba Tri",
    Active: -1,
  },
  {
    ID: 631,
    ProvinceCode: "56",
    Code: "5608",
    Name: "Huyện Thạnh Phú",
    Active: -1,
  },
  {
    ID: 632,
    ProvinceCode: "56",
    Code: "5609",
    Name: "Huyện Mỏ Cày Nam",
    Active: -1,
  },
  {
    ID: 633,
    ProvinceCode: "57",
    Code: "5701",
    Name: "Thành phố Vĩnh Long",
    Active: -1,
  },
  {
    ID: 634,
    ProvinceCode: "57",
    Code: "5702",
    Name: "Huyện Long Hồ",
    Active: -1,
  },
  {
    ID: 635,
    ProvinceCode: "57",
    Code: "5703",
    Name: "Huyện Mang Thít",
    Active: -1,
  },
  {
    ID: 636,
    ProvinceCode: "57",
    Code: "5704",
    Name: "Thị xã Bình Minh",
    Active: -1,
  },
  {
    ID: 637,
    ProvinceCode: "57",
    Code: "5705",
    Name: "Huyện Tam Bình",
    Active: -1,
  },
  {
    ID: 638,
    ProvinceCode: "57",
    Code: "5706",
    Name: "Huyện Trà Ôn",
    Active: -1,
  },
  {
    ID: 639,
    ProvinceCode: "57",
    Code: "5707",
    Name: "Huyện Vũng Liêm",
    Active: -1,
  },
  {
    ID: 640,
    ProvinceCode: "57",
    Code: "5708",
    Name: "Huyện Bình Tân",
    Active: -1,
  },
  {
    ID: 641,
    ProvinceCode: "58",
    Code: "5801",
    Name: "Thành phố Trà Vinh",
    Active: -1,
  },
  {
    ID: 642,
    ProvinceCode: "58",
    Code: "5802",
    Name: "Huyện Càng Long",
    Active: -1,
  },
  {
    ID: 643,
    ProvinceCode: "58",
    Code: "5803",
    Name: "Huyện Cầu Kè",
    Active: -1,
  },
  {
    ID: 644,
    ProvinceCode: "58",
    Code: "5804",
    Name: "Huyện Tiểu Cần",
    Active: -1,
  },
  {
    ID: 645,
    ProvinceCode: "58",
    Code: "5805",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 646,
    ProvinceCode: "58",
    Code: "5806",
    Name: "Huyện Trà Cú",
    Active: -1,
  },
  {
    ID: 647,
    ProvinceCode: "58",
    Code: "5807",
    Name: "Huyện Cầu Ngang",
    Active: -1,
  },
  {
    ID: 648,
    ProvinceCode: "58",
    Code: "5808",
    Name: "Huyện Duyên Hải",
    Active: -1,
  },
  {
    ID: 649,
    ProvinceCode: "59",
    Code: "5901",
    Name: "Thành phố Sóc Trăng",
    Active: -1,
  },
  {
    ID: 650,
    ProvinceCode: "59",
    Code: "5902",
    Name: "Huyện Kế Sách",
    Active: -1,
  },
  {
    ID: 651,
    ProvinceCode: "59",
    Code: "5903",
    Name: "Huyện Mỹ Tú",
    Active: -1,
  },
  {
    ID: 652,
    ProvinceCode: "59",
    Code: "5904",
    Name: "Huyện Mỹ Xuyên",
    Active: -1,
  },
  {
    ID: 653,
    ProvinceCode: "59",
    Code: "5905",
    Name: "Huyện Thạnh Trị",
    Active: -1,
  },
  {
    ID: 654,
    ProvinceCode: "59",
    Code: "5906",
    Name: "Huyện Long Phú",
    Active: -1,
  },
  {
    ID: 655,
    ProvinceCode: "59",
    Code: "5907",
    Name: "Thị xã Vĩnh Châu",
    Active: -1,
  },
  {
    ID: 656,
    ProvinceCode: "59",
    Code: "5908",
    Name: "Huyện Cù Lao Dung",
    Active: -1,
  },
  {
    ID: 657,
    ProvinceCode: "59",
    Code: "5909",
    Name: "Thị xã Ngã Năm",
    Active: -1,
  },
  {
    ID: 658,
    ProvinceCode: "59",
    Code: "5910",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 659,
    ProvinceCode: "59",
    Code: "5911",
    Name: "Huyện Trần Đề",
    Active: -1,
  },
  {
    ID: 660,
    ProvinceCode: "60",
    Code: "6001",
    Name: "Thành phố Bạc Liêu",
    Active: -1,
  },
  {
    ID: 661,
    ProvinceCode: "60",
    Code: "6002",
    Name: "Huyện Vĩnh Lợi",
    Active: -1,
  },
  {
    ID: 662,
    ProvinceCode: "60",
    Code: "6003",
    Name: "Huyện Hồng Dân",
    Active: -1,
  },
  {
    ID: 663,
    ProvinceCode: "60",
    Code: "6004",
    Name: "Thị xã Giá Rai",
    Active: -1,
  },
  {
    ID: 664,
    ProvinceCode: "60",
    Code: "6005",
    Name: "Huyện Phước Long",
    Active: -1,
  },
  {
    ID: 665,
    ProvinceCode: "60",
    Code: "6006",
    Name: "Huyện Đông Hải",
    Active: -1,
  },
  {
    ID: 666,
    ProvinceCode: "60",
    Code: "6007",
    Name: "Huyện Hoà Bình",
    Active: -1,
  },
  {
    ID: 667,
    ProvinceCode: "61",
    Code: "6101",
    Name: "Thành phố Cà Mau",
    Active: -1,
  },
  {
    ID: 668,
    ProvinceCode: "61",
    Code: "6102",
    Name: "Huyện Thới Bình",
    Active: -1,
  },
  {
    ID: 669,
    ProvinceCode: "61",
    Code: "6103",
    Name: "Huyện U Minh",
    Active: -1,
  },
  {
    ID: 670,
    ProvinceCode: "61",
    Code: "6104",
    Name: "Huyện Trần Văn Thời",
    Active: -1,
  },
  {
    ID: 671,
    ProvinceCode: "61",
    Code: "6105",
    Name: "Huyện Cái Nước",
    Active: -1,
  },
  {
    ID: 672,
    ProvinceCode: "61",
    Code: "6106",
    Name: "Huyện Đầm Dơi",
    Active: -1,
  },
  {
    ID: 673,
    ProvinceCode: "61",
    Code: "6107",
    Name: "Huyện Ngọc Hiển",
    Active: -1,
  },
  {
    ID: 674,
    ProvinceCode: "61",
    Code: "6108",
    Name: "Huyện Năm Căn",
    Active: -1,
  },
  {
    ID: 675,
    ProvinceCode: "61",
    Code: "6109",
    Name: "Huyện Phú Tân",
    Active: -1,
  },
  {
    ID: 676,
    ProvinceCode: "62",
    Code: "6201",
    Name: "Thành phố Điện Biên Phủ",
    Active: -1,
  },
  {
    ID: 677,
    ProvinceCode: "62",
    Code: "6202",
    Name: "Thị xã Mường Lay",
    Active: -1,
  },
  {
    ID: 678,
    ProvinceCode: "62",
    Code: "6203",
    Name: "Huyện Điện Biên",
    Active: -1,
  },
  {
    ID: 679,
    ProvinceCode: "62",
    Code: "6204",
    Name: "Huyện Tuần Giáo",
    Active: -1,
  },
  {
    ID: 680,
    ProvinceCode: "62",
    Code: "6205",
    Name: "Huyện Mường Chà",
    Active: -1,
  },
  {
    ID: 681,
    ProvinceCode: "62",
    Code: "6206",
    Name: "Huyện Tủa Chùa",
    Active: -1,
  },
  {
    ID: 682,
    ProvinceCode: "62",
    Code: "6207",
    Name: "Huyện Điện Biên Đông",
    Active: -1,
  },
  {
    ID: 683,
    ProvinceCode: "62",
    Code: "6208",
    Name: "Huyện Mường Nhé",
    Active: -1,
  },
  {
    ID: 684,
    ProvinceCode: "62",
    Code: "6209",
    Name: "Huyện Mường Ảng",
    Active: -1,
  },
  {
    ID: 685,
    ProvinceCode: "63",
    Code: "6301",
    Name: "Thành phố Gia Nghĩa",
    Active: -1,
  },
  {
    ID: 686,
    ProvinceCode: "63",
    Code: "6302",
    Name: "Huyện Đăk R’Lấp",
    Active: -1,
  },
  {
    ID: 687,
    ProvinceCode: "63",
    Code: "6303",
    Name: "Huyện Đăk Mil",
    Active: -1,
  },
  {
    ID: 688,
    ProvinceCode: "63",
    Code: "6304",
    Name: "Huyện Cư Jút",
    Active: -1,
  },
  {
    ID: 689,
    ProvinceCode: "63",
    Code: "6305",
    Name: "Huyện Đăk Song",
    Active: -1,
  },
  {
    ID: 690,
    ProvinceCode: "63",
    Code: "6306",
    Name: "Huyện Krông Nô",
    Active: -1,
  },
  {
    ID: 691,
    ProvinceCode: "63",
    Code: "6307",
    Name: "Huyện Đăk GLong",
    Active: -1,
  },
  {
    ID: 692,
    ProvinceCode: "63",
    Code: "6308",
    Name: "Huyện Tuy Đức",
    Active: -1,
  },
  {
    ID: 693,
    ProvinceCode: "64",
    Code: "6401",
    Name: "Thành phố Vị Thanh",
    Active: -1,
  },
  {
    ID: 694,
    ProvinceCode: "64",
    Code: "6402",
    Name: "Huyện Vị Thuỷ",
    Active: -1,
  },
  {
    ID: 695,
    ProvinceCode: "64",
    Code: "6403",
    Name: "Thị xã Long Mỹ",
    Active: -1,
  },
  {
    ID: 696,
    ProvinceCode: "64",
    Code: "6404",
    Name: "Huyện Phụng Hiệp",
    Active: -1,
  },
  {
    ID: 697,
    ProvinceCode: "64",
    Code: "6405",
    Name: "Huyện Châu Thành",
    Active: -1,
  },
  {
    ID: 698,
    ProvinceCode: "64",
    Code: "6406",
    Name: "Huyện Châu Thành A",
    Active: -1,
  },
  {
    ID: 699,
    ProvinceCode: "64",
    Code: "6407",
    Name: "Thành phố Ngã Bảy",
    Active: -1,
  },
  {
    ID: 700,
    ProvinceCode: "06",
    Code: "0614",
    Name: "Huyện Quảng Hòa",
    Active: -1,
  },
  {
    ID: 701,
    ProvinceCode: "62",
    Code: "6210",
    Name: "Huyện Nậm Pồ",
    Active: -1,
  },
  {
    ID: 702,
    ProvinceCode: "07",
    Code: "0708",
    Name: "Huyện Nậm Nhùn",
    Active: -1,
  },
  {
    ID: 703,
    ProvinceCode: "14",
    Code: "1412",
    Name: "Huyện Vân Hồ\r",
    Active: -1,
  },
  {
    ID: 704,
    ProvinceCode: "17",
    Code: "1715",
    Name: "Thị xã Quảng Yên",
    Active: -1,
  },
  {
    ID: 705,
    ProvinceCode: "28",
    Code: "2828",
    Name: "Thị xã Nghi Sơn",
    Active: -1,
  },
  {
    ID: 706,
    ProvinceCode: "29",
    Code: "2921",
    Name: "Thị xã Hoàng Mai",
    Active: -1,
  },
  {
    ID: 707,
    ProvinceCode: "31",
    Code: "3108",
    Name: "Huyện Tuyên Hóa",
    Active: -1,
  },
  {
    ID: 708,
    ProvinceCode: "31",
    Code: "3109",
    Name: "Thị xã Ba Đồn",
    Active: -1,
  },
  {
    ID: 709,
    ProvinceCode: "04",
    Code: "0408",
    Name: "Huyện Hoàng Sa",
    Active: -1,
  },
  {
    ID: 710,
    ProvinceCode: "36",
    Code: "3610",
    Name: "Huyện Ia H' Drai",
    Active: -1,
  },
  {
    ID: 711,
    ProvinceCode: "43",
    Code: "4311",
    Name: "Huyện Phú Riềng",
    Active: -1,
  },
  {
    ID: 712,
    ProvinceCode: "44",
    Code: "4408",
    Name: "Huyện Bàu Bàng",
    Active: -1,
  },
  {
    ID: 714,
    ProvinceCode: "44",
    Code: "4409",
    Name: "Huyện Bắc Tân Uyên",
    Active: -1,
  },
  {
    ID: 715,
    ProvinceCode: "52",
    Code: "5209",
    Name: "Thị xã Phú Mỹ",
    Active: -1,
  },
  {
    ID: 716,
    ProvinceCode: "49",
    Code: "4915",
    Name: "Thị xã Kiến Tường",
    Active: -1,
  },
  {
    ID: 717,
    ProvinceCode: "53",
    Code: "5311",
    Name: "Thị xã Cai Lậy",
    Active: -1,
  },
  {
    ID: 718,
    ProvinceCode: "58",
    Code: "5809",
    Name: "Thị xã Duyên Hải",
    Active: -1,
  },
  {
    ID: 719,
    ProvinceCode: "30",
    Code: "3013",
    Name: "Huyện Kỳ Anh",
    Active: -1,
  },
  {
    ID: 720,
    ProvinceCode: "02",
    Code: "0225",
    Name: "2",
    Active: -1,
  },
];
export { DataDistrict };
