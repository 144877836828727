import React from "react";

const iconHomeTab = (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M7.37217 19.6391V16.4539C7.37217 15.6409 8.03612 14.9817 8.85514 14.9817H11.8491C12.2424 14.9817 12.6196 15.1368 12.8977 15.4129C13.1758 15.689 13.3321 16.0635 13.3321 16.4539V19.6391C13.3296 19.9771 13.4631 20.3021 13.703 20.542C13.9429 20.7819 14.2693 20.9168 14.6098 20.9168H16.6524C17.6064 20.9193 18.5221 20.5448 19.1976 19.876C19.873 19.2072 20.2526 18.2991 20.2526 17.3521V8.27814C20.2526 7.51314 19.911 6.7875 19.3199 6.29669L12.3714 0.787526C11.1627 -0.178419 9.43085 -0.147231 8.25822 0.861599L1.46824 6.29669C0.849209 6.77303 0.479221 7.50083 0.460938 8.27814V17.3428C0.460938 19.3167 2.07279 20.9168 4.06112 20.9168H6.05707C6.7643 20.9168 7.33906 20.3504 7.34418 19.6483L7.37217 19.6391Z"
      fill="black"
      fillOpacity="0.25"
    />
  </svg>
);

const iconHomeTabActive = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    className="w-6 h-6 object-contain"
  >
    <path
      d="M7.37217 19.6391V16.4539C7.37217 15.6409 8.03612 14.9817 8.85514 14.9817H11.8491C12.2424 14.9817 12.6196 15.1368 12.8977 15.4129C13.1758 15.689 13.3321 16.0635 13.3321 16.4539V19.6391C13.3296 19.9771 13.4631 20.3021 13.703 20.542C13.9429 20.7819 14.2693 20.9168 14.6098 20.9168H16.6524C17.6064 20.9193 18.5221 20.5448 19.1976 19.876C19.873 19.2072 20.2526 18.2991 20.2526 17.3521V8.27814C20.2526 7.51314 19.911 6.7875 19.3199 6.29669L12.3714 0.787526C11.1627 -0.178419 9.43085 -0.147231 8.25822 0.861599L1.46824 6.29669C0.849209 6.77303 0.479221 7.50083 0.460938 8.27814V17.3428C0.460938 19.3167 2.07279 20.9168 4.06112 20.9168H6.05707C6.7643 20.9168 7.33906 20.3504 7.34418 19.6483L7.37217 19.6391Z"
      fill="#01B2FF"
    />
  </svg>
);

const iconShopTab = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M1.16389 7.5H14.2197C14.8586 6.72656 15.7346 6.25 16.6993 6.25C17.6639 6.25 18.5368 6.72656 19.1757 7.49609C19.7735 7.43359 20.2415 6.81641 20.2415 6.0625C20.2415 5.77734 20.172 5.5 20.0455 5.26562L17.6544 0.835938C17.3729 0.3125 16.9017 0 16.3925 0H3.84906C3.34302 0 2.86861 0.3125 2.58712 0.835938L0.19609 5.26172C0.0695803 5.5 0 5.77734 0 6.05859C0 6.85547 0.521852 7.5 1.16389 7.5ZM12.1449 8.75H10.1208V15H4.04831V8.75H2.02415V15V18.125C2.02415 19.1602 2.70414 20 3.54227 20H10.6268C11.4649 20 12.1449 19.1602 12.1449 18.125V15V13.75V8.75ZM16.6993 13.75C17.2591 13.75 17.7114 9.93359 17.7114 10.625L17.4 12H15.9986L15.6872 10.625C15.6872 9.93359 16.1395 13.75 16.6993 13.75ZM14.1691 10.625V12.5C13.6093 12.5 13.157 13.0586 13.157 13.75V18.75C13.157 19.4414 13.6093 20 14.1691 20H19.2295C19.7893 20 20.2415 19.4414 20.2415 18.75V13.75C20.2415 13.0586 19.7893 12.5 19.2295 12.5V10.625C19.2295 8.89844 17.7904 8.75 16.3925 8.75C14.9946 8.75 14.1691 8.89844 14.1691 10.625Z"
      fill="black"
      fillOpacity="0.25"
    />
  </svg>
);
const iconShopTabActive = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M1.16389 7.5H14.2197C14.8586 6.72656 15.7346 6.25 16.6993 6.25C17.6639 6.25 18.5368 6.72656 19.1757 7.49609C19.7735 7.43359 20.2415 6.81641 20.2415 6.0625C20.2415 5.77734 20.172 5.5 20.0455 5.26562L17.6544 0.835938C17.3729 0.3125 16.9017 0 16.3925 0H3.84906C3.34302 0 2.86861 0.3125 2.58712 0.835938L0.19609 5.26172C0.0695803 5.5 0 5.77734 0 6.05859C0 6.85547 0.521852 7.5 1.16389 7.5ZM12.1449 8.75H10.1208V15H4.04831V8.75H2.02415V15V18.125C2.02415 19.1602 2.70414 20 3.54227 20H10.6268C11.4649 20 12.1449 19.1602 12.1449 18.125V15V13.75V8.75ZM16.6993 13.75C17.2591 13.75 17.7114 9.93359 17.7114 10.625L17.4 12H15.9986L15.6872 10.625C15.6872 9.93359 16.1395 13.75 16.6993 13.75ZM14.1691 10.625V12.5C13.6093 12.5 13.157 13.0586 13.157 13.75V18.75C13.157 19.4414 13.6093 20 14.1691 20H19.2295C19.7893 20 20.2415 19.4414 20.2415 18.75V13.75C20.2415 13.0586 19.7893 12.5 19.2295 12.5V10.625C19.2295 8.89844 17.7904 8.75 16.3925 8.75C14.9946 8.75 14.1691 8.89844 14.1691 10.625Z"
      fill="#01B2FF"
    />
  </svg>
);
const iconCallTab = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M22.5405 18.33C22.5405 18.69 22.4615 19.06 22.2935 19.42C22.1255 19.78 21.9082 20.12 21.6216 20.44C21.1375 20.98 20.6039 21.37 20.0012 21.62C19.4084 21.87 18.7661 22 18.0745 22C17.0666 22 15.9897 21.76 14.8534 21.27C13.7171 20.78 12.5808 20.12 11.4544 19.29C10.3181 18.45 9.24115 17.52 8.21356 16.49C7.19585 15.45 6.27695 14.36 5.45686 13.22C4.64664 12.08 3.99452 10.94 3.52025 9.81C3.04597 8.67 2.80884 7.58 2.80884 6.54C2.80884 5.86 2.92741 5.21 3.16454 4.61C3.40168 4 3.77714 3.44 4.30082 2.94C4.93318 2.31 5.62483 2 6.356 2C6.63266 2 6.90932 2.06 7.15633 2.18C7.41323 2.3 7.64049 2.48 7.81834 2.74L10.1107 6.01C10.2885 6.26 10.417 6.49 10.5059 6.71C10.5948 6.92 10.6442 7.13 10.6442 7.32C10.6442 7.56 10.575 7.8 10.4367 8.03C10.3083 8.26 10.1205 8.5 9.8834 8.74L9.13247 9.53C9.02378 9.64 8.97438 9.77 8.97438 9.93C8.97438 10.01 8.98426 10.08 9.00402 10.16C9.03366 10.24 9.0633 10.3 9.08306 10.36C9.26092 10.69 9.56722 11.12 10.002 11.64C10.4466 12.16 10.9209 12.69 11.4347 13.22C11.9682 13.75 12.482 14.24 13.0057 14.69C13.5195 15.13 13.9444 15.43 14.2803 15.61C14.3297 15.63 14.389 15.66 14.4581 15.69C14.5372 15.72 14.6162 15.73 14.7052 15.73C14.8731 15.73 15.0016 15.67 15.1103 15.56L15.8612 14.81C16.1082 14.56 16.3454 14.37 16.5726 14.25C16.7999 14.11 17.0271 14.04 17.2741 14.04C17.4619 14.04 17.6595 14.08 17.8769 14.17C18.0942 14.26 18.3215 14.39 18.5685 14.56L21.839 16.91C22.0959 17.09 22.2738 17.3 22.3824 17.55C22.4812 17.8 22.5405 18.05 22.5405 18.33Z"
      stroke="#01B2FF"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M19.112 9C19.112 8.4 18.6477 7.48 17.956 6.73C17.3236 6.04 16.4838 5.5 15.6538 5.5"
      stroke="#01B2FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5703 9C22.5703 5.13 19.4776 2 15.6538 2"
      stroke="#01B2FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const iconMilestoneTab = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M7.00171 18.1501V16.0801"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.1218 18.1498V14.0098"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.2419 18.1502V11.9302"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.2417 5.8501L16.7817 6.3901C14.2317 9.3701 10.8117 11.4801 7.00171 12.4301"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14.3118 5.8501H17.2418V8.7701"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.12183 12.97V15C2.12183 20 4.12183 22 9.12183 22H15.1218C20.1218 22 22.1218 20 22.1218 15V9C22.1218 4 20.1218 2 15.1218 2H9.12183C4.12183 2 2.12183 4 2.12183 9"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const iconMilestoneTabActive = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M7.00171 18.1501V16.0801"
      stroke="#01B2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.1218 18.1498V14.0098"
      stroke="#01B2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.2419 18.1502V11.9302"
      stroke="#01B2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.2417 5.8501L16.7817 6.3901C14.2317 9.3701 10.8117 11.4801 7.00171 12.4301"
      stroke="#01B2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14.3118 5.8501H17.2418V8.7701"
      stroke="#01B2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.12183 12.97V15C2.12183 20 4.12183 22 9.12183 22H15.1218C20.1218 22 22.1218 20 22.1218 15V9C22.1218 4 20.1218 2 15.1218 2H9.12183C4.12183 2 2.12183 4 2.12183 9"
      stroke="#01B2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const iconProfileTab = (
  <svg width="19" height="21" viewBox="0 0 19 21" fill="none">
    <mask
      id="mask0_991_3378"
      maskUnits="userSpaceOnUse"
      x="0"
      y="13"
      width="19"
      height="8"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.654053 13.2065H18.322V20.9999H0.654053V13.2065Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_991_3378)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48923 14.7919C4.73649 14.7919 2.32721 15.5655 2.32721 17.0927C2.32721 18.6336 4.73649 19.4146 9.48923 19.4146C14.2409 19.4146 16.649 18.641 16.649 17.1138C16.649 15.5729 14.2409 14.7919 9.48923 14.7919ZM9.48918 20.9999C7.3041 20.9999 0.654053 20.9999 0.654053 17.0926C0.654053 13.6092 5.6968 13.2065 9.48918 13.2065C11.6743 13.2065 18.3221 13.2065 18.3221 17.1138C18.3221 20.5972 13.2804 20.9999 9.48918 20.9999Z"
        fill="#ADACB1"
      />
    </g>
    <mask
      id="mask1_991_3378"
      maskUnits="userSpaceOnUse"
      x="3"
      y="0"
      width="13"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56519 0H15.4108V11.2224H3.56519V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_991_3378)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4894 1.50867C7.10132 1.50867 5.15828 3.34867 5.15828 5.61142C5.15047 7.86677 7.07901 9.70571 9.45706 9.71416L9.4894 10.4688V9.71416C11.8764 9.71416 13.8183 7.87311 13.8183 5.61142C13.8183 3.34867 11.8764 1.50867 9.4894 1.50867ZM9.48913 11.2224H9.45343C6.19423 11.2128 3.55407 8.69434 3.56522 5.60829C3.56522 2.51696 6.22211 -0.000488281 9.48913 -0.000488281C12.755 -0.000488281 15.4108 2.51696 15.4108 5.61146C15.4108 8.70596 12.755 11.2224 9.48913 11.2224Z"
        fill="#ADACB1"
      />
    </g>
  </svg>
);
const iconProfileTabActive = (
  <svg width="19" height="21" viewBox="0 0 19 21" fill="none">
    <mask
      id="mask0_1205_3234"
      maskUnits="userSpaceOnUse"
      x="0"
      y="13"
      width="19"
      height="8"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.654053 13.2065H18.322V20.9999H0.654053V13.2065Z"
        fill="white"
      />
    </mask>
    \
    <g mask="url(#mask0_1205_3234)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48923 14.7919C4.73649 14.7919 2.32721 15.5655 2.32721 17.0927C2.32721 18.6336 4.73649 19.4146 9.48923 19.4146C14.2409 19.4146 16.649 18.641 16.649 17.1138C16.649 15.5729 14.2409 14.7919 9.48923 14.7919ZM9.48918 20.9999C7.3041 20.9999 0.654053 20.9999 0.654053 17.0926C0.654053 13.6092 5.6968 13.2065 9.48918 13.2065C11.6743 13.2065 18.3221 13.2065 18.3221 17.1138C18.3221 20.5972 13.2804 20.9999 9.48918 20.9999Z"
        fill="#01B2FF"
      />
    </g>
    <mask
      id="mask1_1205_3234"
      maskUnits="userSpaceOnUse"
      x="3"
      y="0"
      width="13"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56519 0H15.4108V11.2224H3.56519V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1205_3234)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4894 1.50867C7.10132 1.50867 5.15828 3.34867 5.15828 5.61142C5.15047 7.86677 7.07901 9.70571 9.45706 9.71416L9.4894 10.4688V9.71416C11.8764 9.71416 13.8183 7.87311 13.8183 5.61142C13.8183 3.34867 11.8764 1.50867 9.4894 1.50867ZM9.48913 11.2224H9.45343C6.19423 11.2128 3.55407 8.69434 3.56522 5.60829C3.56522 2.51696 6.22211 -0.000488281 9.48913 -0.000488281C12.755 -0.000488281 15.4108 2.51696 15.4108 5.61146C15.4108 8.70596 12.755 11.2224 9.48913 11.2224Z"
        fill="#01B2FF"
      />
    </g>
  </svg>
);
export {
  iconHomeTab,
  iconHomeTabActive,
  iconShopTab,
  iconShopTabActive,
  iconCallTab,
  iconMilestoneTabActive,
  iconMilestoneTab,
  iconProfileTab,
  iconProfileTabActive,
};
