export const dataFamily = [
  {
    id: "mom",
    value: "Mẹ",
  },
  {
    id: "dad",
    value: "Bố",
  },
  {
    id: "grandMom",
    value: "Bà",
  },
  {
    id: "grandDad",
    value: "Ông",
  },
  {
    id: "aunt",
    value: "Cô",
  },
  {
    id: "uncle",
    value: "Chú",
  },
  {
    id: "babysister",
    value: "Người trông trẻ",
  },
  {
    id: null,
    value: "Khác",
  },
];
